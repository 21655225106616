@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import "~bootstrap/scss/bootstrap";

:root {
  --red: #d8c2c5;
  --black: #000000;
  --green: #4bd93f;
  --white: #ffffff;
  --gray: #dcdcdc;
  --light-gray: #f0f0f0;
  --dark-gray: #363636;
  --blue: #4285F4;
  --yellow: #f0cd5c;
  --lightGreen: #70d469;
  --gray-easy: #dedede;
  --gray-info: #F1F3F4
}

*{scrollbar-width: thin;}
body {
  scroll-behavior: smooth;
  -webkit-font-smoothing: initial !important;
  -moz-osx-font-smoothing: initial !important;
  text-rendering: optimizeLegibility;
  top: 0px !important;
  position: inherit !important;
  min-height: auto !important;
}

html {
  max-width: 100vw;
  width: 100%;
  height: auto !important;
  margin: 0;
  padding: 0;
}

.mb-30 {
  margin-bottom: 30px;
}

*::selection {
  background: #ac3dfc;
  color: #ffffff;
}

a,
a:hover {
  text-decoration: none !important;
}

select {
  cursor: pointer !important;
}

button {
  &:hover {
    outline: none !important;
    box-shadow: none !important;
  }

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px transparent inset;
  -webkit-box-shadow: 0 0 0 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s
}

.MessageTextBox .form-control::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #ffffff;
}

.MessageTextBox .form-control::-webkit-scrollbar-track {
  border-radius: 4px;
  box-shadow: inset 0 0 5px white;
  background-color: #fff;
}

.MuiTouchRipple-root {
  opacity: 0;
}

body {
  margin: 0px;
  padding: 0px;
  font-family: 'Poppins', sans-serif !important;
  scrollbar-color: #1a78e5 rgb(255 255 255 / 12%);
  scrollbar-width: thin !important;
  background-color: #f0f0f0;

  &.modal-open {
    overflow: hidden !important;
  }
}

.spinner-border {
  width: 14px !important;
  height: 14px !important;
  border: 0.16em solid currentColor;
  margin-left: 6px !important;
  border-right-color: transparent;
}

ul {
  margin: 0px;
  padding: 0px;
}

.RedColor {
  background-color: #EC223C;
  border: 1px #EC223C solid;

  &:hover {
    background-color: #EC223C;
    border: 1px #EC223C solid;
  }

  &:focus {
    background-color: #EC223C;
    border: 1px #EC223C solid;
  }
}

.BlueColor {
  background-color: var(--blue);
  border: 1px var(--blue) solid
}

.WhiteColor {
  background-color: #ffffff;
}

.GreenColor {
  background-color: var(--green);
  color: #ffffff;

  &:hover {
    background-color: var(--green);
    border: 1px var(--green) solid;
  }

  &:focus {
    background-color: var(--green);
    border: 1px var(--green) solid;
  }
}

.GreenLtClor {
  background-color: var(--lightGreen);
}

.YellowClor {
  background-color: var(--yellow);
}

.GrayEasy {
  background-color: var(--gray-easy);
  color: var(--dark-gray) !important;
}

.BdrRdsFull {
  border-radius: 100px !important;
}

.form-group {
  margin-bottom: 1.5em;
}

.align-right {
  text-align: right;
}

::-webkit-scrollbar-track {
  border-radius: 4px;
  box-shadow: inset 0 0 5px grey;
  background-color: #333;
}

::-webkit-scrollbar {
  width: 4px;
  background-color: #e7e7e7;
  height: 7px;
  border-radius: 4px;
}

::-mozkit-scrollbar {
  width: 4px;
  background-color: #e7e7e7;
  height: 7px;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #1a78e5
}

.AllPlanSchedule::-webkit-scrollbar-track {
  border-radius: 80px;
  background-color: #999da2;
}

.AllPlanSchedule::-webkit-scrollbar {
  width: 10px;
  background-color: #999da2;
  height: 7px;
  border-radius: 80px;
}

.AllPlanSchedule::-webkit-scrollbar-thumb {
  border-radius: 80px;
  background-color: #1a78e5 !important;
}







.RoomScheduleTable::-webkit-scrollbar-track {
  border-radius: 80px;
  background-color: #999da2;
}

.RoomScheduleTable::-webkit-scrollbar {
  width: 10px;
  background-color: #999da2;
  height: 7px;
  border-radius: 80px;
}

.RoomScheduleTable::-webkit-scrollbar-thumb {
  border-radius: 80px;
  background-color: #1a78e5 !important;
}

.ProfileOverflow::-webkit-scrollbar-track,
.MyNewRoomMeeting ::-webkit-scrollbar-track {
  border-radius: 80px;
  background-color: #f2f2f2;
}

.ProfileOverflow::-webkit-scrollbar,
.MyNewRoomMeeting::-webkit-scrollbar {
  width: 8px !important;
  background-color: #999da2;
  height: 7px;
  border-radius: 80px;
}

.ProfileOverflow::-webkit-scrollbar-thumb,
.MyNewRoomMeeting::-webkit-scrollbar-thumb {
  border-radius: 80px;
  background-color: #dcdcdc !important;
}

.ProfileOverflow::-webkit-scrollbar-track,
.MyNewRoomMeeting::-webkit-scrollbar-track {
  border-radius: 4px;
  box-shadow: none;
  background-color: #f5f3f3;
}

.ZoneEnterDiscInfo::-webkit-scrollbar-track {
  border-radius: 80px;
  scrollbar-width: thin;
  background-color: #999da2;

}

.ZoneEnterDiscInfo::-webkit-scrollbar {
  width: 10px;
  background-color: #999da2;
  height: 7px;
  border-radius: 80px;
}

.ZoneEnterDiscInfo::-webkit-scrollbar-thumb {
  border-radius: 80px;
  background-color: #1a78e5 !important;
}

.RedButton {
  background-color: #eb354d;
  color: #ffffff;
  font-weight: 400;
  border-radius: 100px;
  padding: 10px 32px;
  width: 100%;
  border: 1px #eb354d solid;
  font-size: 16px;

  &:hover {
    box-shadow: none;
    outline: 0;
    color: #ffffff;
    background-color: #df354c;
    border: 1px #df354c solid;
  }

  &:focus {
    box-shadow: none;
    outline: 0;
    color: #ffffff;
    background-color: #df354c;
    border: 1px #df354c solid;
  }
}

.btmBlueIn {
  background-color: var(--blue);
  border: 1px var(--blue) solid;

  &:hover {
    box-shadow: none;
    outline: 0;
    color: #ffffff;
    background-color: #2b70e1;
    border: 1px #2b70e1 solid;
  }

  &:focus {
    box-shadow: none;
    outline: 0;
    color: #ffffff;
    background-color: #2b70e1;
    border: 1px #2b70e1 solid;
  }
}

.BlueinButton {
  background-color: var(--blue);
  color: #ffffff;
  font-weight: 400;
  border-radius: 100px;
  padding: 10px 14px;
  width: 100%;
  border: 1px var(--blue) solid;
  font-size: 14px;

  &:hover {
    box-shadow: none;
    outline: 0;
    color: #ffffff;
    background-color: #2b70e1;
    border: 1px #2b70e1 solid;
  }

  &:focus {
    box-shadow: none;
    outline: 0;
    color: #ffffff;
    background-color: #2b70e1;
    border: 1px #2b70e1 solid;
  }
}

.GrayButton {
  background-color: #DBDBDB;
  color: #333333;
  font-weight: 400;
  border-radius: 100px;
  padding: 10px 32px;
  width: 100%;
  border-color: #DBDBDB;
  font-size: 16px;

  &:hover {
    box-shadow: none;
    outline: 0;
    color: #333333;
    background-color: #DBDBDB;
    border-color: #DBDBDB;
  }

  &:focus {
    box-shadow: none;
    outline: 0;
    color: #333333;
    background-color: #DBDBDB;
    border-color: #DBDBDB;
  }
}

.DarkGrayButton {
  background-color: #333333;
  color: #ffffff;
  font-weight: 300;
  border-radius: 100px;
  padding: 8px 14px;
  width: 100%;
  border-color: #333333;
  font-size: 12px;

  &:hover {
    box-shadow: none;
    outline: 0;
    color: #ffffff;
    background-color: #333333;
    border-color: #333333;
  }

  &:focus {
    box-shadow: none;
    outline: 0;
    color: #ffffff;
    background-color: #333333;
    border-color: #333333;
  }
}

.leaveStreamDark {
  background-color: #333333;
  color: #ffffff;
  font-weight: 300;
  border-radius: 100px;
  padding: 8px 12px 8px 20px;
  width: 100%;
  border-color: #333333;
  font-size: 12px;

  &:hover {
    box-shadow: none;
    outline: 0;
    color: #ffffff;
    background-color: #333333;
    border-color: #333333;
  }

  &:focus {
    box-shadow: none;
    outline: 0;
    color: #ffffff;
    background-color: #333333;
    border-color: #333333;
  }
}

.WhiteButton {
  background-color: #ffffff;
  color: var(--dark-gray);
  font-weight: 600;
  border-radius: 100px;
  padding: 11px 32px;
  width: 100%;
  border-color: #ffffff;

  &:hover {
    box-shadow: none;
    outline: 0;
    color: var(--dark-gray);
    border-color: var(--dark-gray);
  }

  &:focus {
    box-shadow: none;
    outline: 0;
    color: var(--dark-gray);
    border-color: var(--dark-gray);
  }
}

.RegisterPageAll {
  display: flex;
  flex-flow: row wrap;
  height: 100vh;
  width: 100%;

  .LeftImgBlock {
    display: flex;
    flex-flow: column nowrap;
    // justify-content: space-between;
    justify-content: center;
    position: relative;
    overflow: hidden;
    height: 100vh;
    // width: calc(100% - 40%);
    width: calc(100% - 31%);
    float: left;

    img {
      width: 100%;
      object-fit: scale-down;
    }

    .scale-blur-fix {
      height: 100%;
      overflow: hidden;

      .LoginLeftHg {
        height: 100vh;
        width: 100%;
        background-size: cover;
        position: relative;
        filter: blur(12px);
        transform: scale(1.1);

        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: #00000080;
        }
      }
    }

    .LoginBigImg {
      top: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: auto;
        height: 100%;
        object-fit: scale-down;
      }
    }

    // .BlurLoginBg {
    //     -webkit-backdrop-filter: blur(20px);
    //     -moz-backdrop-filter: blur(20px);
    //     -o--backdrop-filter: blur(20px);
    //     -ms--backdrop-filter: blur(20px);
    //     backdrop-filter: blur(20px);
    // }
    .LoginEventLogo {
      position: absolute;
      top: 20px;
      left: 20px;
      width: 200px;

      /* display: flex; */
      /* align-items: center; */
      /* justify-content: center; */
      /* width: 100%; */
      /* height: 100%; */
      /* cursor: auto;*/
      .EventLogoFix {
        width: 100%;
        text-align: center;

        img {
          width: auto
        }
      }
    }
  }

  .LoginRegisterPage {
    // width: 39.6%;
    // min-width: 39.6%;
    width: 31%;
    min-width: 31%;
    display: flex;
    flex-flow: column;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    right: 0;
    height: 100%;
    text-align: center;
    padding-bottom: 40px;
    scrollbar-width: thin;

    .mixhubbLogo {
      padding: 0;
      width: 215px;
      margin: 80px auto 26px;
      height: 114px;

      padding: 0;
      width: 215px;
      margin: 95px auto 26px;
      height: 114px;

      img {
        height: 100%;
        width: 100%;
        object-fit: scale-down;
      }
    }

    .MixhubbExpDae {
      background-color: rgb(0 0 0 / 21%);
      padding: 18px 20px;

      h1 {
        color: #1a78e5;
        font-weight: 700;
        letter-spacing: 1px;
        font-size: 30px;
        margin: 0 0 6px;
      }

      p {
        color: #e70a0a;
        font-size: 26px;
        margin: 0;
        font-weight: 400;
      }

      .event-timezone {
        font-size: 18px;
      }

      .SocialMicro {
        width: 90px;
        padding: 5px 0;
        border-radius: 100px;
        margin: 10px auto 0px;
        border: 1px solid;
        font-size: 14px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 18px;
          height: 18px;
          margin-right: 5px;
          display: inline-flex;
        }
      }
    }


    .EnterEventAction {
      width: 360px;
      display: table;
      margin: 22px auto 0;
      padding-bottom: 20px;

      h2 {
        font-weight: 600;
        font-size: 26px;
        margin: 0 0 24px;
      }

      .SocialLoginType {
        ul {
          margin: 0px;
          padding: 0;

          li {
            display: inline-block;
            margin: 0 8px;
            cursor: pointer;

            &:first-child {
              margin-left: 0px;
            }

            &:last-child {
              margin-right: 0px;
            }

            .img-fluid {
              cursor: pointer;
              width: 46px;
              height: 46px;
            }

            svg {
              width: 46px;
              height: 46px;
            }

            button {
              border: 0;
              padding: 0;
              background-color: transparent;
            }
          }
        }
      }
    }
  }

  .EmbedLoginRegisterPage {
    width: 100%;
    min-width: 31%;
    display: flex;
    flex-flow: column;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    right: 0;
    height: 100%;
    text-align: center;
    padding-bottom: 40px;
    scrollbar-width: thin;

    .EnterEventAction {

      display: flex;
      margin: 21px auto;
      width: 500px;
      max-width: 100%;
      padding-top: 0vw;
      min-height: 100%;
      align-items: center;
      justify-content: center;

      .EventTypeActonIn {
        flex-grow: 1;
      }
    }
  }
}

.EventTypeActonIn {
  .form-group {
    margin-bottom: 1.5em;
    position: relative;

    label {
      width: 100%;
      text-align: left;
      /* text-transform: uppercase; */
      font-size: 14px;
      letter-spacing: 0.2px;
      margin: 0 0 4px;
      font-weight: 400;
    }

    .form-control {
      background-color: #ffffff;
      border-radius: 100px;
      border: 0px;
      padding: 16px 28px;
      color: #6c757d;
      height: 50px;

      &:hover {
        box-shadow: none;
        outline: 0;
      }

      &:focus {
        box-shadow: none;
        outline: 0;
      }
    }

    .EnterBtn {
      background-color: rgb(0 0 0 / 21%);
      border-radius: 100px;
      padding: 12px 28px;
      width: 100%;
      color: #fff;
      font-size: 18px;
      font-weight: 500;

      &:hover {
        box-shadow: none;
        outline: 0;
      }

      &:focus {
        box-shadow: none;
        outline: 0;
      }
    }

    p {
      color: #1a78e5;
    }

    .OrTxt {
      position: relative;
      text-align: center;

      &:after {
        content: "";
        position: absolute;
        background-color: #c7c7c7;
        width: 100%;
        height: 1px;
        left: 0;
        top: 11px;
      }

      span {
        /*background-color: #fff;*/
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: 1.5px;
        z-index: 5;
        position: relative;
        padding: 0 12px;
        font-size: 14px;
      }
    }

    .error {
      position: absolute;
      left: 0;
      width: 90%;
      bottom: -18px;
      font-size: 12px;
      margin: 0px auto;
      right: 0;
      text-align: left;
    }

    .EnterOtpValue {
      display: flex;
      align-items: center;
      justify-content: center;

      .OtpValue {
        input {
          margin: 0 6px;
          width: 44px !important;
          height: 44px !important;
          border-radius: 4px;
          box-shadow: none;
          outline: none;
          border: 1px rgb(0 0 0 / 40%) solid;
          font-size: 21px;
          font-weight: 600;

          &::-webkit-input-placeholder {
            color: #bbbbbb !important;
            font-weight: 500;
          }

          &::-ms-input-placeholder {
            color: #bbbbbb !important;
            font-weight: 500;
          }

          &::placeholder {
            color: #bbbbbb;
            font-weight: 500 !important;
          }
        }
      }
    }

    .SelectDropdown {
      .dropdown {
        .css-2b097c-container {

          .css-1pahdxg-control {
            height: 50px;
            border: 1px #a5a5a5 solid;
            box-shadow: none;
            padding: 0 12px;
            font-size: 14px;
            border-radius: 100px;
            cursor: pointer;

            div {
              padding: 0 0;
              padding-right: 15px;

              .css-1wy0on6 {
                .css-1okebmr-indicatorSeparator {
                  display: none;
                }

                .css-1okebmr-indicatorSeparator {
                  display: none;
                }

                .css-1gtu0rj-indicatorContainer {
                  display: none;
                }

                &::after {
                  content: "";
                  display: inline-block;
                  width: 13px;
                  height: 13px;
                  border-top: 2px solid var(--dark-gray);
                  border-right: 2px solid var(--dark-gray);
                  transform: rotate(135deg);
                  position: absolute;
                  right: 20px;
                  top: 15px;
                }

                .css-tlfecz-indicatorContainer {
                  display: none !important;
                }
              }
            }

            .css-1wy0on6 {
              .css-1okebmr-indicatorSeparator {
                display: none;
              }

              .css-1okebmr-indicatorSeparator {
                display: none;
              }

              .css-1gtu0rj-indicatorContainer {
                display: none;
              }

              &::after {
                content: "";
                display: inline-block;
                width: 13px;
                height: 13px;
                border-top: 2px solid var(--dark-gray);
                border-right: 2px solid var(--dark-gray);
                transform: rotate(135deg);
                position: absolute;
                right: 20px;
                top: 15px;
              }

              .css-tlfecz-indicatorContainer {
                display: none !important;
              }
            }
          }

          .css-yk16xz-control {
            width: 100%;
            height: 50px;
            border-radius: 100px;
            font-size: 14px;
            padding: 0 12px;
            border: 1px #a5a5a5 solid;
            color: var(--dark-gray);
            cursor: pointer;

            div {
              padding: 0 0;
              padding-right: 15px;


            }

            .css-1wy0on6 {
              .css-1okebmr-indicatorSeparator {
                display: none;
              }

              .css-1gtu0rj-indicatorContainer {
                display: none;
              }

              &::after {
                content: "";
                display: inline-block;
                width: 13px;
                height: 13px;
                border-top: 2px solid var(--dark-gray);
                border-right: 2px solid var(--dark-gray);
                transform: rotate(135deg);
                position: absolute;
                right: 20px;
                top: 15px;
              }

              .css-tlfecz-indicatorContainer {
                display: none !important;
              }
            }
          }
        }

        div {
          text-align: left !important;
        }
      }

      .css-1hb7zxy-IndicatorsContainer {
        div {
          display: none;
        }

        .css-tlfecz-indicatorContainer {
          display: none;
        }

        .css-1okebmr-indicatorSeparator {
          display: none;
        }

        &::after {
          content: "";
          display: inline-block;
          width: 13px;
          height: 13px;
          border-top: 2px solid var(--dark-gray);
          border-right: 2px solid var(--dark-gray);
          transform: rotate(135deg);
          position: absolute;
          right: 20px;
          top: 14px;
        }
      }

      &::after {
        display: none;
      }
    }

    .timer {
      p {
        font-size: 14px;
        margin: 16px 0 0;
        font-weight: 400;
      }
    }

    .resendOtp {
      p {
        font-size: 14px;
        margin: 16px 0 0;
        font-weight: 400;
        text-align: center;
      }

      a {
        font-size: 14px;
        margin: 12px 0 0;
        font-weight: 400;
        cursor: pointer
      }
    }
  }

  .SelectDropdown {
    .SeleftDDl {
      .dropdown-toggle {
        background-color: #eaeaea;
        height: 56px;
        color: #6c757d;
        border: 0;
      }
    }

    &:after {
      border-top: 2px solid #6b6b6b;
      border-right: 2px solid #6b6b6b;
      right: 26px;
      top: 18px;
    }
  }

  .OptMessaes {
    font-size: 15px;
    margin: 0px 0 20px;
    font-weight: 400;
  }
}

.MixhubbHeader {
  background-color: #000000;
  padding: 8px 0;
  height: 50px;
  position: fixed;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 0px;

  .HeaderMainTop {
    width: 100%;
    display: flex;
    padding: 0 10px;
    align-items: center;
    justify-content: space-between;

    .MixhubbLogo {
      max-width: 210px;
      flex: 0 0 210px;
      display: flex;
      align-items: center;
      height: 54px;
      padding: 6px 0;

      img {
        height: 36px;
        width: auto;
      }

      .EditEventFront {
        min-width: 98px;
        background-color: #fff;
        border-radius: 100px;
        padding: 4px 6px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: #333333;
        font-weight: 400;
        margin-left: 15px;
        padding-right: 8px !important;

        svg {
          width: 12px;
          height: 12px;
          margin-right: 6px;
        }
      }

      .YourBoothFront {
        min-width: 102px;
        background-color: #fff;
        border-radius: 100px;
        padding: 6px 10px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: #333333;
        font-weight: 400;
        margin-left: 14px;
        position: absolute;
        bottom: -45px;
        left: 0;
        box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
        border: 1px #EAEAEA solid;

        svg {
          width: 14px;
          height: 14px;
          margin-right: 6px;
        }
      }
    }

    .MixhubbMenu {
      display: flex;
      // max-width: 73%;
      // flex: 0 0 73%;
      justify-content: center;
      align-items: center;
      margin: -5px 0 0;

      ul {
        margin: 0px;
        padding: 0px;

        li {
          display: inline-block;
          margin: 0 22px;
          font-size: 11px;
          font-weight: 400;
          position: relative;

          .nav-mixhubb {
            // color: #fff;
            position: relative;
            text-decoration: none;
            position: relative;

            .IconStyle {
              width: 32px;
              height: 32px;
              display: inline-block;

              svg {
                width: 100%;
                height: 100%;
              }
            }

            .live-icon {
              position: relative;

              .RecordLiveStream {
                left: auto;
                right: 12px;
                margin: 0px auto;
                top: auto;
                bottom: -8px;

                .liveRecord {
                  width: 42px;
                  font-size: 11px;
                  padding: 2px 5px;
                  height: 15px;
                  justify-content: space-between;
                  text-decoration: blink;
                  animation: blinker 1s linear infinite;

                  .LiveIcon {
                    width: 8px;
                    height: 8px;
                    display: flex;
                    margin-right: 0px !important;
                    background-color: #fff;
                    border-radius: 100px;
                  }
                }
              }
            }

            &:hover {
              opacity: .7;
            }
          }

          &:first-child {
            margin-left: 0px;
          }

          &:last-child {
            margin-right: 0px;
            // &:hover {
            //     .nav-mixhubb {
            //         svg {
            //             circle {
            //                 fill: #ec223c;
            //             }
            //             path {
            //                 fill: #000000;
            //             }
            //         }
            //     }
            // }
          }

          span {
            margin-left: 12px;
            text-transform: uppercase;
          }

          // &:hover {
          //     .nav-mixhubb {
          //         color: #ec223c;
          //         svg {
          //             fill: #ec223c;
          //             path {
          //                 fill: #ec223c;
          //             }
          //         }
          //     }

          // }
        }
      }

      .MixhubbBottomMenu {
        display: none;
      }

      .mobMenuExt {
        display: none;
      }
    }

    .MixhubbRight {
      display: flex;
      // max-width: 12.6%;
      // flex: 0 0 12.6%;
      align-items: center;
      justify-content: flex-end;
      position: relative;
      align-content: space-between;
      padding: 0px;

      .dropdown {
        position: initial !important;
        margin-left: 4px;
      }

      ul {
        li {
          display: inline-block;
          margin: 0 10px;

          &:first-child {
            margin-left: 0px;
          }

          &:last-child {
            margin-right: 0px;
          }

          .IconStyle {
            width: 26px;
            height: 26px;
            display: inline-block;

            svg {
              width: 100%;
              height: 100%;
            }
          }

        }
      }

      .dropdown {
        .dropdown-toggle {
          background: transparent;
          padding: 0;
          border: 0px;
          width: 30px;
          height: 30px;
          border-radius: 100px;

          &:hover {
            outline: none;
            box-shadow: none;
            border: 0px;
          }

          &:focus {
            outline: none;
            box-shadow: none;
            border: 0px;
          }

          &:after {
            display: none;
          }

          .IconStyle {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #dcdcdc;
            border-radius: 100px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 100px;
              object-fit: cover;
            }
          }
        }

        .dropdown-menu {
          border: #dcd8d8 solid 1px;
          border-radius: 3px;
          top: 48px !important;
          padding: 0px 0px;
          right: -3px !important;
          min-width: 128px !important;
          transform: inherit !important;

          .ddlList {
            position: relative;

            &::before {
              content: "";
              height: 16px;
              width: 16px;
              position: absolute;
              background-color: white;
              top: -15px;
              right: 10%;
              border-top: #dcd8d8 solid 1px;
              border-left: #dcd8d8 solid 1px;
              transform: rotate(45deg);
              z-index: -1;
            }

            .dropdown-item {
              padding: 6px 8px;
              font-size: 12px;

              &:first-child {
                margin-top: 8px;
              }

              &:hover {
                outline: none;
                color: #212529;
                box-shadow: none;
                border: 0px;
                background: #f1f1f1 !important;
              }

              &:focus {
                outline: none;
                box-shadow: none;
                background: #f1f1f1 !important;
                border: 0px;
                color: #212529;
              }

              .MenuSvg {
                display: inline-block;
                height: 18px;
                margin-right: 5px;
                margin-bottom: 0px;
                width: 28px;
                text-align: center;

                svg {
                  width: 100%;
                  height: 100%;
                  margin: -4px 0 0;
                  padding: 1px;
                }

                .EnterBtn {
                  width: 26px;
                  height: 24px;
                  background: transparent;
                  padding: 3px;
                  border-radius: 100px;
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  border: 0px !important;

                  svg {
                    width: 100%;
                    height: 100%;
                  }

                  &:hover {
                    outline: none;
                    box-shadow: none;
                    border: 0px;
                  }

                  &:focus {
                    outline: none;
                    box-shadow: none;
                    border: 0px;
                  }
                }
              }
            }
          }
        }
      }

      .HereNotify {
        position: relative;
        margin-right: 12px;

        .dropdown {
          .dropdown-toggle {
            width: 28px;
            height: 28px;
          }

          .dropdown-menu {
            border: #a5a5a5 solid 1px;
            inset: initial !important;
            top: 42px !important;
            right: -20px !important;

            .ddlList {
              .dropdown-item {
                padding: 0px !important;
                margin: 0px !important;

                .real-notification {
                  //position: absolute;
                  //top: 48px;
                  background-color: #fff;
                  width: 260px;
                  right: 45px;

                  //z-index: 99;
                  .notification-dialog {
                    .notification-header {
                      background-color: #eb233c;

                      h5 {
                        color: #fff;
                        font-weight: 500;
                        font-size: 15px;
                        padding: 6px 13px;
                        text-transform: uppercase;
                        text-align: left;
                      }

                      .close {
                        position: absolute;
                        right: 5px;
                        top: 4px;
                        width: 24px;
                        height: 24px;
                        padding: 0;
                        background-color: transparent;
                        border: 0;
                        -webkit-appearance: none;

                        svg {
                          fill: #fff;
                          width: 100%;
                          height: 100%;
                          margin: 0;
                        }
                      }
                    }

                    .notification-body {
                      padding: 10px 12px;
                      overflow-y: auto;
                      scrollbar-width: thin;
                      max-height: 323px;
                      overflow-x: hidden;

                      .notification-box {
                        .notificationList {
                          border-bottom: 1px #e0e0e0 solid;
                          padding: 10px 0 10px;

                          h5 {
                            color: #313131;
                            font-weight: 500;
                            font-size: 13px;
                            margin: 0 0 4px;
                            width: 100%;
                            text-align: left;
                          }

                          p {
                            color: #4c4c4c;
                            text-align: left;
                            font-size: 12px;
                            line-height: 16px;
                            margin: 0 0 6px;
                            width: 100%;
                            font-weight: 400;
                          }

                          .action-list {
                            width: 100%;
                            text-align: right;

                            .btn-success {
                              background-color: #348b0b;
                              border: 0px;
                              color: #fff;
                              border-radius: 3px;
                              padding: 3px 6px;
                              box-shadow: none;
                              font-size: 12px;
                              font-weight: 400;
                              margin-right: 4px;
                            }

                            .removeNotification {
                              background-color: #eb233c;
                              width: 26px;
                              height: 26px;
                              padding: 5px;
                              border-radius: 3px;
                              box-shadow: none;
                              display: inline-flex;
                              align-items: center;
                              justify-content: center;

                              svg {
                                width: 100%;
                                height: 100%;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

              &:before {
                background-color: #eb233c;
                top: -8px;
              }
            }
          }
        }
      }

      .headIcon {
        position: relative;
        margin-right: 8px;

        //top: -4px;
        .chatConvIcon {
          width: 28px !important;
          height: 28px !important;
          cursor: pointer;

          svg {
            width: 100%;
            height: 100%;

            &:hover {
              opacity: .7;
            }
          }

          .NoticHere {
            background-color: #eb233c;
            padding: 0 2px;
            font-size: 10px;
            position: absolute;
            top: -7px;
            right: -5px;
            border-radius: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 18px !important;
            border: 2px #fff solid;
            color: #fff;
            height: 18px;
            font-weight: 500;
            letter-spacing: 0.1px;
          }
        }
      }

      .ToggleIcon {
        display: none;
      }

      .countdown-timer {
        color: #eb354d;
        margin: 0;
        font-size: 15px;
        letter-spacing: 0.3px;
        font-weight: 400;
        display: inline-block;
        width: 88px;
        text-align: left;

        span {
          width: 20px;
          height: 20px;
          display: inline-block;
          position: relative;
          top: 5px;
          margin-right: 4px;
          vertical-align: initial;

          svg {
            width: 100%;
            height: 100%;
            fill: #eb354d;
            vertical-align: inherit;
          }
        }
      }

    }
  }

}

.MixSessionHeader {
  background-color: #000000;
  padding: 8px 0;
  height: 50px;
  position: fixed;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  width: 100%;

  .HeaderMainTop {
    width: 100%;
    display: flex;
    padding: 0 30px;
    align-items: center;
    justify-content: space-between;

    .MixhubbLogoMix {
      max-width: 20%;
      flex: 0 0 20%;
      display: flex;
      align-items: center;
      height: 54px;
      padding: 6px 0;

      img {
        height: 40px;
        width: auto;
      }
    }

    .SessionHere {
      display: flex;
      max-width: 45%;
      flex: 0 0 45%;
      justify-content: center;
      align-items: center;

      .NameSessionGoes {
        text-align: center;

        p {
          color: var(--white);
          margin: 0 0 2px;
          font-size: 12px;
          letter-spacing: .2px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          line-height: inherit;
        }

        span {
          color: var(--white);
          font-size: 10px;
          letter-spacing: .2px;
          background-color: rgb(255 255 255 / 26%);
          padding: 1px 12px;
          border-radius: 100px;
          font-weight: 300;
        }
      }
    }

    .SessionInfoTime {
      display: flex;
      max-width: 30%;
      flex: 0 0 30%;
      align-items: center;
      justify-content: flex-end;
      position: relative;
      align-content: space-between;
      padding: 0px;

      .TimerSessionGoing {
        span {
          width: 18px;
          height: 18px;
          display: inline-block;
          position: relative;
          top: 5px;
          margin-right: 6px;
          vertical-align: initial;

          svg {
            width: 100%;
            height: 100%;
            fill: #fff;
            vertical-align: inherit;
          }
        }

        p {
          color: #fff;
          margin: 0;
          font-size: 14px;
          letter-spacing: 0.3px;
          font-weight: 400;
          display: inline-block;
          width: 65px;
          text-align: center;
        }
      }

      .TimerDownSession {
        margin-right: 10px;

        .countdown-timer {
          color: #eb354d;
          margin: 0;
          font-size: 15px;
          letter-spacing: 0.3px;
          font-weight: 400;
          display: inline-block;
          width: 88px;
          text-align: left;

          span {
            width: 20px;
            height: 20px;
            display: inline-block;
            position: relative;
            top: 5px;
            margin-right: 4px;
            vertical-align: initial;

            svg {
              width: 100%;
              height: 100%;
              fill: #eb354d;
              vertical-align: inherit;
            }
          }
        }
      }

      .SessionMixOngoing {
        margin-left: 20px;

        .BtnShape {
          border-radius: 100px;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: .2px;
          padding: 6px 16px;

          &:hover {
            color: #fff;
          }

          &:focus {
            color: #fff;
          }
        }
      }
    }
  }
}


// .BlurBkdropFilter {
//     -webkit-backdrop-filter: blur(10px);
//     -moz-backdrop-filter: blur(10px);
//     -o--backdrop-filter: blur(10px);
//     -ms--backdrop-filter: blur(10px);
//     backdrop-filter: blur(10px);
//     background-size: cover;
// }
// .DarkBlurBackground {
//     -webkit-backdrop-filter: blur(30px);
//     -moz-backdrop-filter: blur(30px);
//     -o--backdrop-filter: blur(30px);
//     -ms--backdrop-filter: blur(30px);
//     backdrop-filter: blur(30px);
//     background-size: cover;
// }
.maxScreenHeight {
  height: calc(100vh - 50px);
  position: relative;
  margin-top: 50px;

  .BlurEventImgs {
    width: 100%;
    height: calc(100vh - 50px);
  }

  .scale-blur-fix {
    height: calc(100vh - 50px);
    overflow: hidden;
    position: relative;

    .BlurFullImgs {
      width: 100%;
      height: calc(100vh - 50px);
      // filter: blur(12px);
      // transform: scale(1.1);
      background-size: 100% 100%;

      &::after {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        //background: rgb(0 0 0 / 40%);
      }
    }
  }

}

.MixhubbLobby {
  position: relative;

  .LobbySelImag {
    .BgLobbyFix {
      //height: 100%;
      height: auto;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      flex: 1 1;
      overflow: hidden;
      justify-content: space-between;
      background-size: cover !important;

      .img-fluid {
        // height: 100%;
        // width: auto;
        height: auto;
        width: 100%;
        position: relative;
        // object-fit: contain;
        z-index: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        display: -webkit-box;
      }

      .LobbyBgWidgetHg {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        position: relative;

        .imageFeatureWidget {
          position: relative;
          // width: auto;
          // height: 100%;
          width: 100%;
          height: auto;
          text-align: center;

          .DrageWidgetBox {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            z-index: 3;

            .carousel-root {
              width: 100% !important;
              height: 100% !important;

              .carousel-slider {
                width: 100% !important;
                height: 100% !important;

                .slider-wrapper {
                  width: 100% !important;
                  height: 100% !important;
                  background-color: var(--dark-gray);

                  .slide {
                    width: 100% !important;
                    height: 100% !important;

                    a {
                      width: 100%;
                      height: 100%;
                      display: block;
                    }

                    img {
                      width: 100% !important;
                      height: 100% !important;
                      object-fit: cover;
                    }
                  }

                  .slider {
                    width: 100% !important;
                    height: 100% !important;
                  }
                }
              }
            }
          }
        }
      }

      .skip-go-btn {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 30px;
        text-align: center;
        max-width: 150px;
        margin: 0px auto;
        width: auto !important;
        height: auto !important;
      }
    }

    .FullVideoFix {
      flex-flow: inherit;

      div {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}

.PeopleChatPopup {
  position: fixed;
  top: 54px;
  right: 4px;
  width: 320px;
  height: calc(100vh - 60px);
  background: var(--light-gray);
  z-index: 96;
  border-radius: 8px;
  padding: 10px 0 0;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 15%);

  .CloseButton {
    padding: 0 0;
    width: 26px;
    height: 26px;
    position: absolute;
    right: 9px;
    top: 5px;
    z-index: 9;

    svg {
      width: 100%;
      height: 100%;
    }

    &:hover {
      outline: none;
      box-shadow: none;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .nav-tabs {
    padding: 14px 26px;

    li {
      width: 50%;
      text-align: center;

      .nav {
        justify-content: center;

        .nav-link {
          padding: 0;
          background-color: transparent;
          border: 0;
          padding: 6px 45px;
          border-radius: 100px;
          font-weight: 500;
          font-size: 16px;
          color: #333;
          cursor: pointer;
          position: relative;

          &.active {
            padding: 6px 45px;
            background-color: var(--gray);
            border: 0px;
            border-radius: 100px;
          }

          &:hover {
            border: 0px;
            box-shadow: none;
            outline: none;
          }

          &:focus {
            border: 0px;
            box-shadow: none;
            outline: none;
          }

          .badge {
            display: block;
            padding: 1px 0 !important;
            width: 10px;
            height: 10px;
            border-radius: 100px;
            position: absolute;
            top: -8px;
            left: 0;
            right: 0;
            margin: 0px auto !important;
            text-decoration: blink;
            animation: blinker 1s linear infinite;
          }
        }
      }
    }
  }


  .GroupinfoDetails {
    padding: 0 !important;

    .ChatInfos {
      padding: 18px;
    }

    .peopleInfos {
      padding: 18px;
    }
  }

  .InfoPeopleChat {
    width: 100%;
    padding: 18px;
    height: calc(100vh - 158px);
    overflow-y: auto;
    position: relative;
    scrollbar-width: thin;

    .tab-content {
      .peopleInfos {
        .PeopleList {
          display: flex;
          padding: 10px 0;
          border-bottom: 1px #d4d4d4 solid;
          position: relative;

          .PeopleImg {
            width: 38px;
            height: 38px;
            border-radius: 100px;
            background-color: #dadada;
            position: relative;

            img {
              width: 100%;
              height: 100%;
              border-radius: 100px;
            }
          }

          .AboutPeople {
            margin-left: 10px;
            padding: 5px 0;
            display: flex;
            /* align-items: center; */
            justify-content: center;
            flex-direction: column;
            text-align: left;
            width: 85%;

            h4 {
              font-size: 14px;
              margin: 0 0 3px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }

            p {
              font-size: 12px;
              margin: 0;
              color: #383838;
            }
          }

          .PeopleOtrInfo {
            padding-right: 55px;

            .chatConvIcon {
              position: absolute;
              right: 0;
              top: auto;
              display: flex;
              align-items: center;
              cursor: pointer;

              .CameraIconRt {
                display: inline-flex;

                .on-call-expo {
                  font-size: 8px;
                  width: 38px !important;
                  height: 16px;
                  position: inherit !important;
                  display: inline-block;
                  line-height: 17px;
                  margin-right: 2px;
                  box-shadow: 0px 2px 0px rgb(0 0 0 / 17%);
                }
              }

              .ChatVideoInfo {
                width: 18px;
                height: 18px;
                display: inline-flex;
                margin: 0 4px;

                svg {
                  width: 100%;
                  height: 100%;
                  fill: #333;
                  color: #333;
                }

                &:last-child {
                  margin-right: 0px !important;
                }
              }
            }
          }

          .mx-active-user {
            position: absolute;
            width: 8px;
            height: 8px;
            right: 8px;
            background-color: #3fb148;
            border-radius: 100px;
            transform: translateY(-50%);
            top: 50%;
          }
        }

        .PeopleAttendList {
          height: calc(100vh - 258px);
          overflow-y: auto;
          margin-top: 14px;
          padding-right: 14px;
          scrollbar-width: thin;
        }
      }

      .ChatInfos {
        width: 100%;
        height: calc(100vh - 275px);
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 14px;
        align-items: stretch;
        scrollbar-width: thin;

        .ReceiverMassage {
          position: relative;
          width: 100%;
          float: left;
          margin: 0 0 14px;

          .receveMessage {
            float: left;
            background-color: #ffffff;
            padding: 12px 20px;
            border-radius: 16px 16px 16px 0px;
            min-width: 130px;

            p {
              margin: 0;
              font-size: 12px;
              font-weight: 500;
              margin-bottom: 2px;
              color: #696969;
            }

            h4 {
              margin: 0;
              font-size: 14px;
              color: #3c3c3c;
              line-height: 19px;
            }
          }

          .ReceiveTimeDate {
            display: block;
            font-size: 11px;
            width: 100%;
            float: left;
            margin: 6px 0 0;
            color: #717171;
            font-weight: 500;
            text-align: left;
          }
        }


        .SenderMassage {
          position: relative;
          width: 100%;
          float: left;
          margin: 0 0 14px;

          .SenderMessage {
            float: right;

            padding: 8px 18px 8px 8px;
            border-radius: 16px 16px 0px 16px;

            // min-width: 130px;
            p {
              margin: 0;
              font-size: 12px;
              font-weight: 400;
              margin-bottom: 2px;
              color: #515151;
              text-align: left;
            }

            h4 {
              margin: 0;
              font-size: 12px;
              color: #3c3c3c;
              line-height: 18px;
              word-break: break-word;
              text-align: left;
              font-weight: 400;
            }
          }

          .SenderTimeDate {
            display: block;
            font-size: 10px;
            width: 100%;
            float: left;
            margin: 6px 0 0;
            color: #717171;
            font-weight: 500;
            text-align: right;
          }
        }

        .align-left {
          width: 90% !important;
          float: left;

          .SenderMessage {
            float: left;
            display: flex;
            border-radius: 16px 16px 16px 0px !important;
            background-color: #fff !important;

            .chatPicsIn {
              display: inline-flex;
              width: 40px;
              height: 40px;
              margin-right: 12px !important;
              position: relative;
              background-color: #f0f0f0;
              border-radius: 12px 12px 12px 0px !important;

              img {
                width: 100%;
                border-radius: 12px 12px 12px 0px !important;
              }

              &:hover {
                .PersonInfoChat {
                  display: flex !important;

                }
              }
            }

            .chatAllin {
              display: inline-block;
              flex: 1;
            }
          }

          .SenderTimeDate {
            text-align: left;
          }
        }


        .align-right {
          width: 90% !important;
          float: right;

          .SenderMessage {
            padding: 6px 6px 6px 12px !important;
            border-radius: 16px 16px 0px 18px !important;
            background-color: var(--gray);
            display: flex;
            flex-direction: row-reverse !important;

            h4 {
              text-align: flex-end !important;
            }

            .chatPicsIn {
              display: inline-flex;
              width: 40px;
              height: 40px;
              margin-right: 0px !important;
              margin-left: 12px !important;
              position: relative;
              background-color: #f0f0f0;
              border-radius: 12px 12px 0px 12px !important;

              img {
                width: 100%;
                border-radius: 12px 12px 0px 12px !important;
              }

              .PersonInfoChat {
                display: none !important;
              }
            }

            .chatAllin {
              display: inline-block;
              flex: 1;
            }
          }
        }

      }

      .common-event-chat {
        height: calc(100vh - 372px) !important;
      }
    }
  }
}

.PepleSerchHere {
  position: relative;
  width: 100%;
  margin-bottom: 0px;

  .SerchHere {
    width: 100%;
    border: 0;
    height: 38px;
    border-radius: 100px;
    font-size: 14px;
    padding: 0 38px 0 16px;
    border: 1px #a5a5a5 solid;

    &:hover {
      outline: none;
    }

    &:focus {
      outline: none;
    }

    &::-webkit-input-placeholder {
      font-size: 12px;
    }

    &::-ms-input-placeholder {
      font-size: 12px;
    }

    &::placeholder {
      font-size: 12px;
    }

  }

  .SerchIcon {
    position: absolute;
    right: 12px;
    top: 6px;

    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.MessageTextBox {
  position: absolute;
  bottom: 0;
  width: 90%;
  left: 0;
  margin: 0px auto;
  right: 0;
  overflow: initial !important;

  p {
    position: absolute;
    top: -34px;
    left: 10px;
  }

  .form-control {
    height: 55px;
    border: 0;
    border-radius: 12px;
    padding: 16px 62px 16px 50px;
    font-size: 13px;
    resize: none;
    overflow-y: hidden;
    scrollbar-width: thin;

    &:hover {
      outline: none;
      box-shadow: none;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .MsgSendIcon {
    position: absolute;
    top: 4px;
    right: 5px;
    cursor: pointer;
    width: 45px;
    height: 45px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .MsgEmojis {
    position: absolute;
    left: 14px;
    top: 14px;
    width: 24px;
    height: 24px;
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 8;
    }

  }

}

.EmojiIconsIn {
  position: absolute;
  bottom: 80px;
  left: 6px;
  width: 304px;
  background-color: #fff;
  height: 190px;
  padding: 10px;
  border-radius: 12px;
  box-shadow: 0px 0px 15px rgb(0 0 0 / 14%);
  z-index: 999;
  display: block;

  .EmojiMainIco {
    overflow-y: auto;
    height: 100%;
    width: 100%;
    scrollbar-width: thin;

    ul {
      margin: 0px;
      padding: 0px;

      li {
        width: 46px;
        text-align: center;
        height: 56px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 26px;
      }
    }

    &::-webkit-scrollbar-track {
      border-radius: 80px;
      background-color: #999da2;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: #999da2;
      height: 7px;
      border-radius: 80px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 80px;
      background-color: #1a78e5 !important;
    }
  }

  &::after {
    content: "";
    position: absolute;
    left: 17px;
    bottom: -20px;
    width: 0;
    height: 0;
    border-top: 12px solid white;
    border-bottom: 8px solid transparent;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
  }
}

.RightTopStyle {
  position: fixed;
  right: 15px;
  top: 80px;
  display: block;
  text-align: center;
  background: #f0f0f0;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 6;
  padding: 0 8px;

  ul {
    display: flex;

    li {
      display: inline-block;
      margin: 0 6px;
      text-align: center;
      position: relative;
      text-decoration: none;
      padding: 6px 2px !important;
      text-decoration: none;

      &:first-child {
        margin-left: 0px;
      }

      &:last-child {
        margin-right: 0px
      }

      text-decoration: none;

      span {
        display: block;
        color: #000;
        font-size: 12px;
        font-weight: 500;
        margin: -2px 0 0;
        line-height: 14px;

        .badge {
          display: block;
          padding: 1px 0 !important;
          width: 10px;
          height: 10px;
          border-radius: 100px;
          position: absolute;
          top: -2px;
          right: -2px;
          animation: blinker 1s linear infinite;
        }
      }

      button {
        background: transparent !important;
        border: 0 !important;
        padding: 0 !important;
        position: relative;

        svg {
          width: 28px;
          height: 28px;
        }
      }
    }
  }
}

.SideRightMenu {
  position: fixed;
  right: 0;
  top: 58px;
  /* background-color: #f7f7f7; */
  z-index: 9;
  margin-right: 12px;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  //border: 1px #fff solid;
  height: calc(100% - 58px);
  align-items: center;
  justify-content: center;

  ul {
    //display: flex;
    margin: 0;
    padding: 0;

    li {
      display: block;
      margin: 14px 0;
      text-align: center;
      position: relative;
      text-decoration: none;
      background: #f0f0f0;
      padding: 8px 10px !important;
      border-radius: 5px;
      color: #000;
      font-size: 13px;
      font-weight: 600;
      line-height: 14px;
      text-decoration: none;

      &:first-child {
        margin-left: 0px;
      }

      &:last-child {
        margin-right: 0px
      }

      button {
        background: transparent !important;
        border: 0 !important;
        position: relative;
        padding: 0 !important;

        svg {
          width: 22px;
          height: 22px;
        }

        span {
          margin: 0 0 1px !important;
          font-size: 10px;
          font-weight: 400;
          line-height: 14px;
          text-decoration: none;
          display: block !important;

          .badge {
            display: block;
            padding: 1px 0 !important;
            width: 10px;
            height: 10px;
            border-radius: 100px;
            position: absolute;
            top: -2px;
            right: -2px;
            animation: blinker 1s linear infinite;
          }
        }
      }

      .RightTopStyle {
        background-color: transparent !important;
        position: initial !important;
        padding: 0px !important;
        height: auto !important;
        position: relative;

        ul {
          padding: 0;
          margin: 0;
          display: block !important;

          li {
            display: block !important;
            margin: 0px 0 !important;
            text-align: center;
            position: relative;
            text-decoration: none;
            background: transparent !important;
            padding: 0px !important;
            border-radius: 5px;
            color: #000;
            font-size: 13px;
            font-weight: 600;
            line-height: 14px;
            text-decoration: none;

            &:first-child {
              margin-left: 0px;
            }

            &:last-child {
              margin-right: 0px
            }

            button {
              svg {
                width: 30px;
                height: 30px;
              }

              span {
                margin: 0 0 1px !important;
                font-size: 12px;
                font-weight: 400;
                line-height: 14px;
                text-decoration: none;
                display: block !important;
              }
            }
          }
        }
      }
    }
  }
}

.SetUpMeeting {
  background-color: #fff;
  padding: 15px 0px 0;
  border-radius: 10px;

  .MeetingWithPeople {

    .FixMeetingHere {
      display: flex;
      position: relative;
      border-bottom: 1px #dcd5d5 solid;
      padding: 0 10px 12px 50px;
      padding-bottom: 12px;

      .BackAgain {
        position: absolute;
        left: 16px;
        top: 8px;
        z-index: 4;
        cursor: pointer;

        img {
          width: 18px;
          height: 18px;
        }
      }

      .MeetPersonInfo {
        width: 42px;
        height: 42px;
        border-radius: 100px;
        background-color: #dadada;
        margin-right: 10px;

        img {
          width: 42px;
          height: 42px;
          border-radius: 100px;
        }
      }

      .AboutPeopleInfo {
        padding: 4px 0;
        width: 84%;

        h4 {
          font-size: 14px;
          margin: 0;
        }

        p {
          font-size: 10px;
          margin: 0;
          color: #383838;
          cursor: pointer;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }

      .CameraIconRt {
        position: absolute;
        right: 14px;
        top: 0;
        width: 30px;
        height: 30px;
        cursor: pointer;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    .SettingMeeting {
      padding: 6px 18px;

      p {
        margin: 0;
        font-size: 14px;
      }
    }
  }
}

.on-call-expo {
  background: #eb354d;
  color: #ffffff;
  font-weight: 500;
  font-size: 11px;
  width: 49px !important;
  position: absolute;
  right: -5px;
  text-align: center;
  border-radius: 5px;
  line-height: 22px;
  top: 25px;
}

.DayScheduleTime {
  padding: 0 25px;

  label {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
  }

}

.btnFullBtn {
  width: 100%;
  border-radius: 100px;
  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  box-shadow: 0px 4px 5px rgb(0 0 0 / 7%);
  padding: 10px 32px;
  border-radius: 100px;

  &:hover {
    outline: none;
    color: #ffffff;
    box-shadow: 0px 4px 5px rgb(0 0 0 / 7%);
    background-color: #3a73d1;
  }

  &:focus {
    outline: none;
    color: #ffffff;
    box-shadow: 0px 4px 5px rgb(0 0 0 / 7%);
    background-color: #3a73d1;
  }
}

.WhitefullBtn {
  width: 100%;
  border-radius: 100px;
  color: #eb354d;
  font-weight: 500;
  font-size: 18px;
  box-shadow: 0px 4px 5px #00000012;

  &:hover {
    outline: none;
    color: #eb354d;
    box-shadow: 0px 4px 5px #00000012;
  }

  &:focus {
    outline: none;
    color: #eb354d;
    box-shadow: 0px 4px 5px #00000012;
  }
}

.btn {
  &:hover {
    outline: none;
  }

  &:focus {
    outline: none;
  }
}

.ScreenTopAlign {
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}


.MixhubbStage {
  overflow-y: inherit;
  scrollbar-width: thin;

  .StageMixhubbEvent {
    padding: 30px 0 20px;
    overflow: auto;
    scrollbar-width: thin;

    .row {
      margin: 0px auto;
      justify-content: center;
    }

    .SetEventInfo {
      background-color: #ffffff;
      border-radius: 20px;
      padding: 32px 30px 20px;
      position: relative;
      margin: 20px 0 30px;
      border: 3px #ffffff solid;
      min-height: 244px;

      .SetupStatus {
        position: absolute;
        top: -12px;
        left: 40px;

        span {
          color: var(--blue);
          border: 1px var(--blue) solid;
          padding: 2px 18px;
          border-radius: 100px;
          font-weight: 500;
          font-size: 12px;
          background-color: #ffffff;
        }
      }

      h3 {
        font-size: 18px;
        margin: 0;
      }

      .AboutSetupInfo {
        background-color: var(--light-gray);
        padding: 10px 12px;
        margin: 25px 0 22px;
        border: 1px #e4e4e4 solid;
        min-height: 64px;

        h4 {
          margin: 0 0 7px;
          font-weight: 500;
          font-size: 16px;
          color: #5d5e5d;
        }

        span {
          margin: 0;
          color: #333;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;

          a {
            color: #333;
            display: block;
          }
        }

        p {
          margin: 0;
          color: #333;
          font-size: 13px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;

          a {
            color: #333;
            display: block;
          }
        }
      }

      .StageListSession {
        display: flex;
        justify-content: space-between;
        position: relative;
        align-items: center;

        .StageSessionMix {
          position: relative;
          width: calc(100% - 168px);
          border-radius: 100px;
          background-color: #f2f4f5;
          font-weight: 400;
          font-size: 15px;
          cursor: pointer;
          height: 42px;

          .session-list {
            width: 100%;
            border-radius: 100px;
            background-color: transparent;
            border: 1px #7e7e7e solid;
            padding: 0 20px;
            font-weight: 400;
            font-size: 14px;
            -webkit-appearance: none;
            appearance: none;
            height: 100%;
            -mozkit-appearance: none;
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            padding-right: 42px;
            z-index: 4;
            position: relative;

            &:hover {
              box-shadow: none;
              outline: none;
            }

            &:focus {
              box-shadow: none;
              outline: none;
            }
          }

          &::after {
            content: "";
            width: 10px;
            height: 10px;
            border-top: 2px solid #5d5e5d;
            border-right: 2px solid #5d5e5d;
            position: absolute;
            right: 19px;
            top: 14px;
            background-image: none;
            transform: rotate(135deg);
          }
        }

        display: flex;
        justify-content: space-between;

        .NotFound {
          text-align: center;

          span {
            font-size: 14px;
          }
        }
      }

      // .ExpoSetupInfo {
      //   min-height: 110px;
      // }
      .EnterStageBtn {
        background-color: var(--blue);
        color: #ffffff;
        border: 1px var(--blue) solid;
        text-align: center;
        font-weight: 400;
        border-radius: 100px;
        padding: 8px 20px;
        text-decoration: none;
        display: inline-block;
        font-size: 14px;

        &:hover {
          box-shadow: none;
          outline: 0;
          color: #ffffff;
          background-color: #2b70e1;
          border: 1px var(--blue) solid;
          padding: 9px 20px;
        }

        &:focus {
          box-shadow: none;
          outline: 0;
          color: #ffffff;
          background-color: #2b70e1;
          border: 1px var(--blue)solid;
          padding: 9px 20px;
        }
      }

      &:hover {
        border: 3px var(--blue) solid;
      }

      .error {
        font-size: 14px;
      }
    }
  }

  .backstage-dashboard {
    padding: 0 !important;

    .MainViewBackStage {
      width: 100% !important;
      height: calc(100vh - 328px);
      margin: 4px 0 4px;
    }

    .RemoveAllDiv {
      width: auto !important;
      height: 100% !important;
      display: flex !important;
      aspect-ratio: 16/9;
      margin: 0px auto;

      .front-stage-stream-scroll {
        width: 100%;
        position: absolute;
        height: auto;
        left: 0;
        right: 0;
        top: auto !important;
        margin: 0px auto;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .alignAllDiv {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .oraganizer-backstage-dashboard {
    padding: 0 !important;

    .MainViewBackStage {
      height: calc(100vh - 328px);
    }
  }
}



.stageVideo {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;

  div {
    width: 100% !important;
    height: 100% !important;
  }

  .skip-go-btn {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 30px;
    text-align: center;
    max-width: 150px;
    margin: 0px auto;
    width: auto !important;
    height: auto !important;

    .btnFullBtn {
      width: auto !important;
      padding: 6px 35px !important;
      font-size: 16px;
    }
  }
}






.BackStageSessionInfo {
  position: absolute;
  top: 2px;
  left: 82px;
  width: 380px;
  height: calc(100vh - 62px);
  background: var(--light-gray);
  z-index: 9;
  border-radius: 8px;
  padding: 0px 0 10px;

  .CloseBar {
    display: none;
  }

  .responsive-tabs-container {
    .nav-tabs {
      padding: 14px 15px;

      li {
        width: 33%;
        text-align: center;

        .nav {
          justify-content: center;
          display: block;

          .nav-link {
            padding: 0;
            background-color: transparent;
            border: 0;
            padding: 6px 4px;
            border-radius: 100px;
            font-weight: 500;
            font-size: 14px;
            color: #333;
            cursor: pointer;

            &.active {
              padding: 6px 4px;
              background-color: var(--gray);
              border: 0px;
              border-radius: 100px;
            }

            &:hover {
              border: 0px;
              box-shadow: none;
              outline: none;
            }

            &:focus {
              border: 0px;
              box-shadow: none;
              outline: none;
            }
          }
        }

      }
    }
  }

  .InfoStageOrder {
    width: 100%;
    // padding: 18px 0;
    position: relative;
    height: calc(100vh - 144px);
    overflow-y: auto;
    scrollbar-width: thin;

    .tab-content {
      .StageInfoBar {
        padding: 12px 15px 0;

        .StatEventSchedule {
          background-color: #ffffff;
          border-radius: 10px;
          padding: 8px;

          h3 {
            margin: 0;
            font-size: 18px;
            padding: 6px 12px 6px;
            word-break: break-word;
          }

          .FixDateTimes {
            background-color: var(--light-gray);
            width: 100%;
            display: flex;
            padding: 12px 14px;
            align-items: center;
            justify-content: space-between;
            border-radius: 8px;

            .stageDateTimeAll {
              p {
                margin: 0;
                color: var(--dark-gray);
                font-size: 14px;
                font-weight: 500;
              }

              h4 {
                margin: 0;
                color: var(--dark-gray);
                font-size: 20px;
                font-weight: 500;
                text-transform: uppercase;
              }
            }

            .StageFromTO {
              p {
                margin: 0;
                color: var(--dark-gray);
                font-size: 14px;
                font-weight: 500;
                text-transform: uppercase;
              }
            }
          }
        }

        .PepleSerchHere {
          margin: 18px 0px 15px;
        }

        .StageRollCallInfo {
          .accordion {
            .accordion-item {
              margin-bottom: 20px;
              border: 0;
              border-radius: 8px;

              .accordion-header {
                position: relative;
                background-color: #ffffff;
                border: 0;
                border-radius: 8px;
                padding: 0px;

                .accordion-button {
                  padding: 12px 20px !important;
                  text-decoration: none;
                  color: var(--dark-gray);
                  font-weight: 500;
                  font-size: 16px;
                  width: 100%;
                  text-align: left;
                  border: 0;
                  background-color: #ffffff;
                  border-radius: 10px 10px;
                  box-shadow: none;

                  &:hover {
                    border: 0;
                    outline: none;
                    box-shadow: none;
                  }

                  &:focus {
                    border: 0;
                    outline: none;
                    box-shadow: none;
                  }

                  &::after {
                    width: 12px;
                    height: 12px;
                    border-top: 2px solid var(--dark-gray);
                    border-right: 2px solid var(--dark-gray);
                    position: absolute;
                    right: 20px;
                    top: 20px;
                    background-image: none;
                    transform: rotate(-45deg);
                  }
                }

                .collapsed {
                  &::after {
                    transform: rotate(135deg);
                    top: 14px;
                  }

                  &:hover {
                    border: 0;
                    outline: none;
                    box-shadow: none;
                    background-color: #ffffff;
                  }

                  &:focus {
                    border: 0;
                    outline: none;
                    box-shadow: none;
                    background-color: #ffffff;
                  }
                }
              }

              .active-add {
                &:after {
                  transform: rotate(-45deg) !important;
                  top: 22px !important;
                }
              }

              .accordion-body {
                padding: 0 8px 8px;

                .StageDetailAnalytics {
                  background-color: var(--light-gray);
                  padding: 0px 8px;
                  border-radius: 8px;
                  position: relative;

                  .NzCallSpeakerPx {
                    display: flex;
                    padding: 10px 0;
                    border-bottom: 1px #d4d4d4 solid;
                    justify-content: space-between;
                    align-items: center;

                    .StgRollSpCall {
                      width: calc(100% - 90px);
                      display: flex;
                      align-items: center;

                      .StageImgPic {
                        width: 35px;
                        height: 35px;
                        background-color: var(--dark-gray);
                        display: inline-block;
                        border-radius: 100px;
                        margin-right: 10px;

                        img {
                          width: 35px;
                          height: 35px;
                          border-radius: 100px;
                        }
                      }

                      .StageSpkrName {
                        display: inline-block;

                        h3 {
                          font-size: 14px;
                          font-weight: 500;
                          margin: 0;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          display: -webkit-box;
                          -webkit-line-clamp: 1;
                          -webkit-box-orient: vertical;
                          width: 160px;
                        }
                      }
                    }

                    .StageRemindBtn {
                      position: absolute;
                      right: 10px;
                    }

                    &:last-child {
                      border: 0;
                    }

                    .StageRemindBtn {
                      .RemindBtn {
                        background-color: #ffffff;
                        padding: 2px 10px;
                        font-size: 11.5px;
                        border-radius: 100px;

                        &:hover {
                          box-shadow: none;
                          outline: 0;
                        }

                        &:focus {
                          box-shadow: none;
                          outline: 0;
                        }
                      }
                    }
                  }
                }
              }

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }

      .StageLiveSession {
        padding: 16px 15px 0;
        height: calc(100vh - 272px);
        overflow-y: auto;
        scrollbar-width: thin;
      }

      .StageExtras {
        .accordion-body {
          padding: 0 12px 8px;

          .form-group {
            margin-top: 15px;
            margin-bottom: 8px;
          }

          .ExtrasLiveStream {
            span {
              font-size: 13px;
              font-weight: 400;
            }

            .switchBackupLink {
              margin: 0 0 10px;

              p {
                margin: 0;
                font-size: 13px;
                color: var(--blue);

                span {
                  margin-right: 6px;
                }
              }
            }
          }
        }

      }
    }

    .SendRequestNow {
      position: absolute;
      bottom: 5px;
      width: 100%;
      padding: 0 15px;
      left: 0;
      text-align: center;

      .BroadcastStart {
        button {
          margin-bottom: 12px;
        }
      }

      .RedButton {
        width: 80% !important;
      }
    }
  }
}

.LivetreamBtn {
  border-radius: 100px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  padding: 8px 20px;
  margin: 0px 0 10px;

  &:hover {
    box-shadow: none;
    outline: 0;
    color: #fff;
  }

  &:focus {
    box-shadow: none;
    outline: 0;
    color: #fff;
  }
}

.StageInfoBar {
  .accordion {
    .accordion-item {
      margin-bottom: 15px;
      border: 0;
      border-radius: 8px;

      .ImgVideoHeadOverlay {
        padding: 8px 8px;

        h4 {
          margin: 0;
          width: 100%;
          font-size: 15px;
          font-weight: 500;
          letter-spacing: .3px;
        }

        .accordion-header {
          position: relative;
          background-color: #ffffff;
          border: 0;
          border-radius: 8px;
          padding: 0px;

          .accordion-button {
            background-color: #ffffff;
            color: var(--dark-gray);
            text-align: left;
            padding: 8px 6px !important;
            width: 100%;
            font-weight: 500;
            border-radius: 100px;

            &::after {
              color: var(--dark-gray);
              width: 12px;
              height: 12px;
              border-top: 2px solid var(--dark-gray);
              border-right: 2px solid var(--dark-gray);
              position: absolute;
              right: 20px;
              top: 8px;
              background-image: none;
              transform: rotate(135deg) !important;
            }

            &:hover {
              outline: 0;
              box-shadow: none;
            }

            &:focus {
              outline: 0;
              box-shadow: none;
            }
          }

          .collapsed {
            &::after {
              top: 15px !important;
              transform: rotate(-45deg) !important;
            }
          }
        }
      }

      .active-add {
        &:after {
          transform: rotate(-45deg) !important;
          top: 22px !important;
        }
      }

      .card-body {
        padding: 0 8px 8px;
      }

      .accordion-body {
        padding: 0 8px 8px;

      }

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}

.css-2b097c-container {

  .css-yk16xz-control {
    width: 100%;
    height: 45px;
    border-radius: 6px;
    font-size: 14px;
    padding: 0 12px;
    border: 1px #929292 solid;
    color: var(--dark-gray);
    border-radius: 100px;
    cursor: pointer;

    div {
      padding: 0 0;
      padding-right: 15px;
    }

    &:hover {
      border: 1px #929292 solid;
    }

    .css-1wy0on6 {
      .css-1okebmr-indicatorSeparator {
        display: none;
      }

      .css-tlfecz-indicatorContainer {
        display: none;
      }

      .css-1okebmr-indicatorSeparator {
        display: none;
      }

      .css-1gtu0rj-indicatorContainer {
        display: none;
      }

      &::after {
        content: "";
        display: inline-block;
        width: 13px;
        height: 13px;
        border-top: 2px solid var(--dark-gray);
        border-right: 2px solid var(--dark-gray);
        transform: rotate(135deg);
        position: absolute;
        right: 18px;
        top: 12px;
      }

      .css-tlfecz-indicatorContainer {
        display: none !important;
      }
    }
  }

  .css-1hb7zxy-IndicatorsContainer {
    div {
      display: none;
    }

    .css-tlfecz-indicatorContainer {
      display: none;
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }

    &::after {
      content: "";
      display: inline-block;
      width: 13px;
      height: 13px;
      border-top: 2px solid var(--dark-gray);
      border-right: 2px solid var(--dark-gray);
      transform: rotate(135deg);
      position: absolute;
      right: 18px;
      top: 12px;
    }
  }

  .css-1pahdxg-control {
    height: 45px;
    border: 1px #929292 solid;
    box-shadow: none;
    padding: 0 12px;
    font-size: 14px;
    border-radius: 100px;
    cursor: pointer;

    div {
      padding: 0 0;
      padding-right: 15px;
    }

    &:hover {
      border: 1px #929292 solid;
    }

    &:focus {
      border: 1px #929292 solid;
    }

    .css-1wy0on6 {
      .css-1okebmr-indicatorSeparator {
        display: none;
      }

      .css-tlfecz-indicatorContainer {
        display: none;
      }

      .css-1okebmr-indicatorSeparator {
        display: none;
      }

      .css-1gtu0rj-indicatorContainer {
        display: none;
      }

      &::after {
        content: "";
        display: inline-block;
        width: 13px;
        height: 13px;
        border-top: 2px solid var(--dark-gray);
        border-right: 2px solid var(--dark-gray);
        transform: rotate(135deg);
        position: absolute;
        right: 18px;
        top: 12px;
      }

      .css-tlfecz-indicatorContainer {
        display: none !important;
      }
    }
  }
}

.BackstateOverview {
  display: flex;
  // width: 100%;
  width: calc(100% - 370px);
  flex-direction: column;
  text-align: center;
  height: calc(100vh - 160px);
  justify-content: center;
  align-items: center;
  float: right;

  h1 {
    color: #ffffff;
    font-weight: 700;
    font-size: calc(16px + 4vmin);
    margin: 0 0 38px;
  }

  h5 {
    color: #ffffff;
    font-size: 32px;
    margin: 0;
    line-height: 1.6;
    font-weight: 300;
  }

  .AttenddeScript {

    //position: absolute;
    h1 {
      color: #ffffff;
      font-weight: 700;
      font-size: 48px;
      margin: 0 0 38px;
    }

    h5 {
      color: #ffffff;
      font-size: calc(14px + 2vmin);
      margin: 0;
      line-height: 1.6;
      font-weight: 300;
    }
  }

  .RedCommonBtn {
    position: relative;
    z-index: 5;
  }
}

.StageBounded {
  height: calc(100vh - 135px) !important;
  margin: auto;

  // .RedCommonBtn {
  //     position: absolute;
  //     z-index: -1;
  //     margin: 0;
  // }
  .VideoBroadcastLive {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      width: 100% !important;
      height: 100% !important;
      aspect-ratio: 16/9;

      iframe {
        height: 100% !important;
      }
    }
  }

  .ImageBroadcastLive {
    width: 100%;
    height: 100%;

    .RatioDiv16 {
      aspect-ratio: 16/9;
      height: 100%;
      margin: 0px auto;
      width: 100%;

      img {
        //object-fit: scale-down;
        position: initial !important;
        border-radius: 10px;
      }
    }
  }

  .PDFBroadcastLive {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .RatioDiv16 {
      //aspect-ratio: 16/9;
      height: 99%;
      margin: 0px auto;
      width: auto;

      img {
        //object-fit: scale-down;
        position: initial !important;
        width: 100% !important;
        height: 100% !important;
        //border-radius: 10px;
      }
    }
  }
}

.StageExtras {
  .accordion {
    .accordion-item {
      .accordion-button {
        padding: 12px 40px 12px 14px !important;
        text-decoration: none;
        color: var(--dark-gray);
        font-weight: 500;
        font-size: 16px;
        width: 100%;
        text-align: left;
        border: 0;
        background-color: #ffffff;
        border-radius: 10px 10px;
        box-shadow: none;
        line-height: 22px;

        &:hover {
          border: 0;
          outline: none;
          box-shadow: none;
        }

        &:focus {
          border: 0;
          outline: none;
          box-shadow: none;
        }

        &::after {
          width: 12px;
          height: 12px;
          border-top: 2px solid var(--dark-gray);
          border-right: 2px solid var(--dark-gray);
          position: absolute;
          right: 20px;
          top: 20px;
          background-image: none;
          transform: rotate(-45deg);
        }
      }

      .collapsed {
        &::after {
          transform: rotate(135deg);
          top: 14px;
        }

        &:hover {
          border: 0;
          outline: none;
          box-shadow: none;
          background-color: #ffffff;
        }

        &:focus {
          border: 0;
          outline: none;
          box-shadow: none;
          background-color: #ffffff;
        }
      }
    }
  }
}

.SlideCommonPopup {
  padding: 20px 0 0;

  .CloseButton {
    right: 6px;
    top: 2px;
    width: 28px;
    height: 28px;
  }

  .nav-tabs {
    padding: 8px 10px 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    li {
      width: auto;
      display: inline-block;
      cursor: pointer;
      margin: 0 0 10px;

      .nav {
        .nav-link {
          font-size: 14px;
          padding: 2px 12px;
          position: relative;

          &.active {
            padding: 0 0;
            padding: 2px 12px;
          }

          svg {
            display: none;
          }
        }

        // span {
        //     display: none;
        // }
      }
    }
  }
}

.PollPublicDeiit {
  .btn {
    margin-top: 10px;
  }
}

.SlideBarTransform {
  .BackstageCommLt {
    .BsHeightFixLx {
      .StageInfoBar {
        .SelectDropdown {
          .SeleftDDl {
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}

.PollInfos {
  position: relative;
  height: calc(100vh - 152px);

  .PollMhAuto {
    height: calc(100vh - 150px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 18px 0;
    scrollbar-width: thin;

    .mh-poll-container {
      background: #ffffff;
      padding: 10px 10px;
      margin-top: 17px;
      border-radius: 8px;

      h4 {
        font-size: 14px;
        line-height: 22px;
        color: var(--dark-gray);
        font-weight: 500;
        margin: 4px 0 20px;
        padding: 0 8px;
        word-wrap: break-word;

      }

      .BalInfoAllProgress {
        background-color: #e9e9e9;
        padding: 14px 15px;
        margin-bottom: 15px;
        border-radius: 4px;
        cursor: pointer;

        .BarStatusInfo {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0px;

          h5 {
            font-size: 14px;
            color: #717171;
            margin: 0;
            font-weight: 500;
          }
        }

        .OrangeBarSm {
          margin: 0px 0 0;

          .progress {
            height: 5px !important;
            margin: 10px 0 0;
            background-color: #d7d7d7 !important;
          }
        }

        .progress {
          height: 5px;
          margin: 15px 0 0;
        }
      }

      .bg-danger {
        .BarStatusInfo {
          h5 {
            color: #fff;
          }
        }
      }

      .bg-success {
        .BarStatusInfo {
          h5 {
            color: #fff;
          }
        }

      }

      .PollReqBar {
        position: relative;

        .PollCreateInfo {
          width: 100%;

          .GrayButton {
            padding: 8px 12px;
            font-size: 14px;
            width: 83%;
          }

          .expoButton {
            width: 100% !important;
          }

          button[disabled] {
            cursor: pointer;
          }
        }

        .DotToggle {
          right: 0px;
          width: 40px;
          height: 40px;
          top: 3px;

          .ExpoPollEdit {
            position: absolute;
            right: 0;
            top: 0;

            .PopupButton {
              background-color: #5d5e5d;
              border: 0;
              width: 32px;
              height: 32px;
              border-radius: 100px;
              padding: 0;

              svg {
                fill: #fff !important;
                width: 14px !important;
                height: 14px !important;

                path {
                  fill: #fff !important;
                }
              }
            }
          }
        }
      }
    }

    .noPollSegment {
      background-color: var(--gray);
      padding: 10px;
      border-radius: 10px;
      text-align: center;
      margin: 12px 0 0 0;

      p {
        font-size: 15px;
        margin: 0 0 6px;
        text-align: center;

        &:last-child {
          margin: 0;
        }
      }
    }
  }

  .archieved {
    height: calc(100vh - 182px) !important;
  }

  .NowPollCreating {
    position: absolute;
    bottom: 12px;
    width: 90%;
    left: 0;
    right: 0;
    margin: 0px auto;

    .btn {
      margin-bottom: 12px;
      box-shadow: none;
    }

    .btn+.btn {
      margin-bottom: 0px;
    }
  }

  .MhBtnRemove {
    height: calc(100vh - 145px);

    .mh-poll-container {
      .BalInfoAllProgress {
        cursor: pointer;
      }
    }
  }
}

.QnaInfos {
  height: calc(100vh - 152px) !important;
  position: relative;
  padding-right: 6px !important;
  padding: 18px 18px 0px;

  .QnaInfosPols {
    height: calc(100vh - 242px);
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;

    .QuestionVote {
      border-bottom: 1px var(--gray) solid;
      margin: 0 0 18px;
      padding-bottom: 20px;
      position: relative;

      .ShowAnAQue {
        width: 100%;
        display: flex;
        align-items: center;
        margin: 0 0 12px;

        .QnAuserImg {
          width: 35px;
          height: 35px;
          background-color: #363636;
          background-color: var(--dark-gray);
          border-radius: 100px;
          margin-right: 10px;
          display: contents;

          img {
            border-radius: 100px;
            width: 35px;
            height: 35px;
          }
        }

        .NameQnAuser {
          margin-left: 10px;
          padding: 5px 0;
          display: flex;
          justify-content: center;
          flex-direction: column;
          text-align: left;
          width: 85%;

          h4 {
            font-size: 14px;
            font-weight: 500;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            width: 160px;
            color: #333;
          }
        }
      }

      p {
        font-size: 13px;
        line-height: 22px;
        color: var(--dark-gray);
        margin-bottom: 24px;
        letter-spacing: .2px;
        margin: 0 0 4px;
      }

      h4 {
        font-size: 14px;
        font-weight: 500;
        margin: 0;
      }

      .QnaVoteCount {
        display: inline-block;
        background: #fff;
        padding: 4px 12px;
        border-radius: 100px;
        margin: 8px 0 0;

        .ThumbUpBtn {
          border: 0;
          background-color: transparent;
          font-size: 12px;
          color: var(--blue);
          font-weight: 600;
          padding: 0 6px 0 2px;
          text-transform: uppercase;
          border-right: 1px var(--gray) solid;
          line-height: 16px;

          svg {
            margin-right: 2px;
          }
        }

        span {
          padding-left: 8px;
          font-weight: 500;
          font-size: 13px;
          color: var(--blue);
        }
      }

      .ClickBroadcast {
        margin: 20px 0 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .MessageTextBox {
    .form-control {
      padding: 18px 62px 18px 18px !important;
    }
  }
}

.JoinInfos {
  padding: 18px;

  .JoinStageRequest {
    .JoinStageAudi {
      h4 {
        font-size: 17px;
        font-weight: 500;
        margin-bottom: 12px;
      }

      p {
        font-size: 15px;
        letter-spacing: .2px;
      }
    }


    .RequestVideoNimage {
      margin-top: 20px;

      p {
        margin: 0;
        font-size: 18px;
        font-weight: 500;
      }

      .UpdateVideoNimage {
        margin-top: 10px;
      }

      .SendRequestNow {
        margin-top: 20px;
      }
    }

    .RequestVideoNimage {
      video {
        width: 100% !important;
        border-radius: 10px;
      }
    }
  }
}

.audience-front-stage-roll {
  .container-fluid {
    .BackstageInfoStream {
      .streamingUsers {
        .StreamVideoFrame {
          height: 184px;
        }
      }
    }
  }
}

.AllPlanSchedule {
  height: calc(100vh - 230px);
  overflow-y: auto;
  padding: 14px 52px 0;
  scrollbar-width: thin;

  .row {
    align-items: center;
    justify-content: center;
  }
}

.ScheduleEvInfo {
  margin-top: 20px;
}

.TagScheduleHght {
  height: calc(100vh - 172px);
  scrollbar-width: thin;
}

.RoomsSchedulePd {
  padding: 0px 52px 0 !important;
}

.SchedulePageEvent {
  overflow: hidden;

  .SetEventSchedule {
    margin: 22px 0 0px;

    .ScheduleDayStage {
      width: 100%;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 0;
      border-radius: 100px;
      padding-right: 10px;
      padding-left: 25px;

      h4 {
        color: var(--blue);
        font-weight: 500;
        font-size: 18px;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;

        svg {
          width: 24px;
          height: 24px;
          margin-right: 10px;
          color: var(--dark-gray);
        }
      }

      .SelectDayStage {
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;

        .SelectDays {
          margin-right: 10px;
        }

        .MyMeetingHere {
          color: #f0f0f0;

          .MymeetingBtn {
            background-color: #eb354d;
            color: #ffffff;
            padding: 10px 24px;
            border-radius: 100px;
            font-weight: 500;
            font-size: 15px;

            &:hover {
              outline: none;
              box-shadow: none;
            }

            &:focus {
              outline: none;
              box-shadow: none;
            }
          }
        }

        .SelectSchedule {
          margin-right: 0px !important;

          .PepleSerchHere {
            input {
              background-color: var(--light-gray) !important;
            }
          }
        }
      }

      .ScheduleTagsKey {
        margin-right: 10px;

        .TagsBtn {
          font-size: 15px;
          border-radius: 100px;
          height: 42px;
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;
          background-color: var(--light-gray);
          color: var(--dark-gray);
          font-weight: 500;
          border: 1px #8c8c8c solid;
          width: 100%;
          text-align: center;
          padding: 0 22px;
        }
      }

      .DayScheduleStages {
        width: 200px;
        margin-right: 10px;

        label {
          font-size: 15px;
          font-weight: 500;
          margin-bottom: 10px;
          height: 44px;
        }

        .form-control {
          font-size: 15px;
          padding-left: 20px;
          border-radius: 100px;
          height: 42px;
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;
          background-color: var(--light-gray);
          color: var(--dark-gray);
          font-weight: 500;
          border: 1px #8c8c8c solid;

          &:hover {
            outline: none;
            box-shadow: none;
            border: 1px #8c8c8c solid;
          }

          &:focus {
            outline: none;
            box-shadow: none;
            border: 1px #8c8c8c solid;
          }
        }

        .SelectDropdown {
          .SeleftDDl {
            .dropdown {
              .dropdown-toggle {
                &::before {
                  content: "";
                  width: 10px;
                  height: 10px;
                  border-top: 2px solid #363636;
                  border-right: 2px solid #363636;
                  position: absolute;
                  right: 20px;
                  top: 12px;
                  background-image: none;
                  transform: rotate(135deg);
                  -webkit-transform: rotate(135deg);
                  transition: all 0.5s ease;
                  -webkit-transition: all 0.5s ease;
                  -moz-transition: all 0.5s ease;
                  -o-transition: all 0.5s ease;
                }
              }
            }

            .show {
              .dropdown-toggle {
                &:before {
                  transform: rotate(-45deg);
                  top: 17px;
                  transition: all 0.5s ease;
                  -webkit-transition: all 0.5s ease;
                  -moz-transition: all 0.5s ease;
                  -o-transition: all 0.5s ease;
                }
              }
            }
          }

        }
      }

      .RoomsSerchHere {
        width: 280px;
        margin-right: 0px !important;
      }

      .SelectEnterZone {
        width: 280px;
      }
    }
  }
}

.ScheduleCalendarInfos {
  width: 100%;

  .StageFullInfoFix {
    background-color: #ffffff;
    border-radius: 20px;
    width: 100%;
    display: flex;
    align-items: inherit;
    justify-content: space-between;
    margin-bottom: 35px;
    position: relative;

    //height: 258px;
    .CalenderDateScheduleInof {
      background-color: var(--light-gray);
      height: auto;
      padding: 40px 12px 35px;
      border-radius: 20px 0px 0px 20px;
      text-align: center;
      position: relative;
      width: 220px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      //justify-content: center;
      flex-flow: column;

      .StageNo {
        /*position: absolute; */
        /* top: 15px; */
        border: 1px #eb354d solid;
        color: #eb354d;
        border-radius: 80px;
        padding: 2px 6px;
        left: 20px;
        font-size: 13px;
        font-weight: 500;
        background: #ffffff;
        left: 0;
        right: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        width: 130px;
        margin: 0px auto;
        min-height: 26px;
      }

      .DtStageEventDay {

        //margin-top: 18px;
        h4 {
          color: var(--dark-gray);
          font-weight: 500;
          line-height: 30px;
          margin: 0;
          font-size: 22px;

          strong {
            display: block;
            font-weight: 700;
          }
        }
      }

      .ScheduleTMFx {
        margin: 5px 0 22px;

        p {
          color: var(--blue);
          font-size: 20px;
          font-weight: 500;
          text-transform: uppercase;
          margin: 0;
        }
      }

      .ScheduleStatusOngoing {
        .EventStyle {
          color: var(--dark-gray);
          position: absolute;
          top: -10px;
          border: 0;
          border-radius: 80px;
          padding: 2px 6px;
          font-size: 12px;
          font-weight: 600;
          left: 0;
          right: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          width: 100px;
          margin: 0px auto;
        }

        .LiveSchedule {
          left: auto;
          top: -12px;
          right: 0;
          margin: 0px auto;
          width: 100%;

          .liveRecord {
            width: 80px;
            font-size: 14px;

            .LiveIcon {
              width: 12px;
              height: 12px;
              margin-right: 5px;
            }
          }
        }
      }

      .calenderInfoGoogle {
        background-color: #fff;
        padding: 8px 12px;
        border-radius: 100px;
        box-shadow: 0px 0px 6px rgb(0 0 0 / 28%);

        a {
          padding: 0 4px;
          cursor: pointer;

          svg {
            width: 18px;
            height: 18px;
          }
        }
      }
    }

    &:last-child {
      margin-bottom: 0px !important;
    }
  }

  .ScheduleInfoWithConts {
    width: calc(100% - 220px);
    padding: 18px;
    position: relative;

    h3 {
      color: #1A78E5;
      margin: 0 0 8px;
      font-size: 20px;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      line-height: inherit;
    }

    .ScheduleDiscribe {
      position: relative;

      p {
        margin: 0;
        //padding-right: 92px;
        font-size: 14px;
        font-weight: 400;
        word-break: break-word;

        // overflow: hidden;
        // text-overflow: ellipsis;
        // display: -webkit-box;
        // -webkit-line-clamp: 1;
        // -webkit-box-orient: vertical;
        .ReadMoreSec {
          //position: absolute;
          cursor: pointer;
          top: 0;
          right: 0;
          font-size: 14px;
          font-weight: 400;
          color: #1a78e5;
          margin-left: 10px;
        }
      }

    }

    .BackAgain {
      position: absolute;
      right: 0px;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      background-color: #f0f0f0;
      border-radius: 0px 10px 10px 0px;

      a {
        height: 100%;
        display: flex;
        padding: 0 14px;

        img {
          width: 15px !important;
        }
      }
    }
  }

  .SpeakerRowList {
    margin: 14px 0 0;
    //display: inline-flex;
    //align-items: center;
    //justify-content: flex-start;
    //overflow-x: auto;
    // overflow-y: hidden;
    width: 100%;
    margin-bottom: 0px;
    padding: 0px 0px 10px;
    height: 95px;

    //overflow: hidden;
    //position: relative;
    .EventSpeakerExp {
      background-color: var(--light-gray);
      display: inline-flex !important;
      padding: 10px 12px;
      border-radius: 8px;
      margin-right: 18px;
      //min-width: 216px;
      min-height: 86px;
      align-items: center;

      .ImgSpeakerBg {
        width: 45px;
        height: 45px;

        .img-fluid {
          border-radius: 100px;
          height: 100%;
          object-fit: cover !important;
          background-color: var(--light-gray);
        }
      }

      .SpeakerNamePos {
        width: 135px;
        margin-left: 12px;
        padding: 3px 0;

        .SpeakerDegDetail {
          h4 {
            font-size: 14px;
            margin: 0 0 3px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            line-height: initial;

            strong {
              font-weight: 600;
            }
          }

          .DetailPostMix {
            cursor: pointer;

            p {
              font-size: 13px;
              margin: 0;
              color: #383838;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              line-height: 16px;
            }

            span {
              font-size: 13px;
              margin: 0;
              color: #383838;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;

              b {
                font-weight: 500;
              }
            }
          }
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .slick-slider {
      position: initial;
      width: 100%;

      .slick-list {
        .slick-track {
          .slick-slide {
            padding: 0 6px;
          }
        }
      }

      .slick-arrow {
        bottom: 8px !important;
        top: auto !important;

        &::before {
          display: none;
        }
      }

      .slick-prev {
        left: auto;
        right: 46px;
      }

      .slick-next {
        right: 20px;
      }

    }
  }

  .slick-disabled {
    opacity: .3;
    cursor: not-allowed;
  }

  .PopUpImgSpeaker {
    display: none;

    .SpkInfoImgs {
      width: 50px;
      height: 50px;
      display: inline-block;
      margin-right: 10px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 100px;
        background-color: var(--light-gray);
      }
    }

    .SpkInfoCount {
      border: 2px #1A78E5 solid;
      color: #1A78E5;
      width: 46px;
      height: 46px;
      border-radius: 100px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 16px;
    }
  }

  .Watch-Session {
    display: inline-flex;
    margin-top: 6px;

    .RedButton {
      padding: 6px 16px;
      font-size: 15px;
    }

    .BlueinButton {
      padding: 6px 16px;
      font-size: 14px;
    }
  }
}

.RoomMeetingBoard {
  background-color: #ffffff;
  padding: 20px 6px;
  position: relative;
  border-radius: 15px;
  margin: 42px 0 8px;

  .RoomEventLogo {
    position: absolute;
    top: -18px;
    width: 100px;
    left: 22px;
    padding: 4px 6px;
    border-radius: 6px;
    border: 1px solid #cfcdcd;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    background-color: #fff;

    .img-fluid {
      /* width: 100%; */
      height: 100%;
      object-fit: scale-down;
    }
  }

  .RoomCountNo {
    margin: 18px 0px 0px;
    padding: 0 10px;

    p {
      color: var(--dark-gray);
      font-weight: 500;
      font-size: 15px;
      margin: 0;
      word-break: break-word;
    }
  }

  .roomChairBlock {
    margin: 18px 0px 30px;
    justify-content: center;
    display: grid;

    ul {
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;
      display: inline-grid;
      grid-template-columns: auto auto auto auto auto;

      li {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin: 8px;
        width: 42px;
        height: 42px;
        background-color: var(--light-gray);
        border-radius: 5px;
        border: 1px var(--dark-gray) dashed;
        cursor: pointer;
        position: relative;
      }

      .SelectSeat {
        // background-color: #eb354d;
        // border: 1px var(--light-gray) dashed;
        // font-weight: 500;
        // font-size: 16px;
        background-color: #0000002b;
        border: 1px #ff0000 dashed;
        font-weight: 500;
        font-size: 16px;

        .ActionIcon {
          text-decoration: none;
          color: #ffffff;
          font-size: 20px;
          font-weight: 500;
          text-transform: uppercase;
        }

        .initialsText {
          ul {
            li {
              display: inline-flex !important;
              align-items: center !important;
              justify-content: center !important;
              margin: 8px !important;
              width: 42px !important;
              height: 42px !important;
              background-color: var(--light-gray) !important;
              border-radius: 5px !important;
              border: 1px var(--dark-gray) dashed !important;
              cursor: pointer !important;
              position: relative !important;
            }
          }
        }

        .UserInfoDetail {
          display: none;
        }

        &:hover {
          .UserInfoDetail {
            display: flex;
          }
        }

        .ViewRoomDetail {
          height: 68px !important;
          top: -78px;

          button {
            background-color: #fff;
            padding: 2px 18px;
            font-size: 14px;
            text-transform: capitalize;
            color: #2A6CEF;
            font-weight: 400;
            border-radius: 100px;
          }
        }
      }
    }
  }

  .Available2Seats {
    ul {
      grid-template-columns: auto;
    }
  }

  .Available3Seats {
    ul {
      grid-template-columns: auto auto;
      width: 160px;
      display: inline-flex;
    }
  }

  .Available4Seats {
    ul {
      grid-template-columns: auto auto;
    }
  }

  .Available5Seats {
    ul {
      grid-template-columns: auto auto auto;
      width: 210px;
      display: inline-flex;
    }
  }

  .Available6Seats {
    ul {
      grid-template-columns: auto auto auto;
    }
  }

  .Available7Seats {
    ul {
      grid-template-columns: auto auto auto;
      width: 280px;
      display: inline-flex;
    }
  }

  .Available8Seats {
    ul {
      grid-template-columns: auto auto auto auto;
    }
  }

  .Available9Seats {
    ul {
      grid-template-columns: auto auto auto auto auto;
      width: 100%;
      display: inline-flex;
    }
  }

  .Available10Seats {
    ul {
      grid-template-columns: auto auto auto auto auto;
    }
  }

  .RoomTotalSeat {
    text-align: center;

    // margin-top: 20px;
    span {
      background-color: var(--light-gray);
      font-weight: 400;
      font-size: 1px;
      padding: 6px 35px;
      color: var(--blue);
      font-size: 14px;
      border-radius: 100px;
    }
  }
}

.ZoneEnterDiscInfo {
  height: calc(100vh - 170px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0px 40px 0px 55px;
  margin: 20px 0 0;
  scrollbar-width: thin;

  .EnterZoneBooth {
    background-color: #ffffff;
    border-radius: 15px;
    padding: 14px 20px;
    margin-bottom: 20px;

    .LargeBoothHeight {
      padding-right: 10px;
    }

    .maxHeightBooth {
      h3 {
        color: var(--dark-gray);
        font-size: 22px;
        font-weight: 500;
        margin: 0 0 3px;
      }

      p {
        font-size: 14px;
        font-weight: 400;
        margin: 0;
        word-break: break-word;
      }
    }

    .BoothTypeLIstZn {
      background-color: #f2f3f5;
      border: 1px var(--dark-gray) solid;
      border-radius: 8px;
      padding: 10px;
      margin: 12px 0 0;
      height: 170px;
      cursor: pointer;

      img {
        height: 100%;
        object-fit: scale-down;
      }
    }
  }

  .row {
    margin: 0px auto;
    justify-content: center;
  }
}

.ZoneListExpoList {
  height: calc(100vh - 160px);
  //padding: 12px 0 0;
}

.TagLengthInfo {
  height: calc(100vh - 224px);
}

.TagLengthInfo.ZoneListExpoList {
  height: calc(100vh - 230px);
}

.ZoneEnterDiscInfo {
  .MidBoothHeight {
    .maxHeightBooth {
      height: 88px;
    }
  }

  .SmallBoothHeight {
    .maxHeightBooth {
      h3 {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      height: 116px;
    }
  }
}

.ModalCommonInfo {
  .modal-dialog {
    max-width: 650px;
    height: calc(100vh - 50px);
    margin: 0 auto !important;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.0s ease-out !important;
    transform: translate(0, 0px) !important;

    .modal-content {
      border-radius: 20px;

      .modal-header {
        padding: 1rem 1rem;

        .modal-title {
          h4 {
            font-size: 18px;
            color: var(--blue);
            font-weight: 500;
            margin: 0;
          }
        }

        .MyMeeting {
          background-color: var(--blue);
          border-radius: 80px;
          color: #ffffff;
          font-size: 14px;
          font-weight: 500;
          padding: 8px 13px 8px 30px;
          border: 0;
          position: relative;

          &:hover {
            outline: none;
            box-shadow: none;
            border: 0;
          }

          &:focus {
            outline: none;
            box-shadow: none;
            border: 0;
          }

          svg {
            width: 21px;
            height: 21px;
            position: absolute;
            left: 8px;
            top: 8px;
          }
        }
      }

      .modal-body {
        .MeetingNewSetup {
          width: 320px;
          margin: 0px auto;
          padding: 24px 0;

          .DayMeetingTimeSetup {
            padding: 0 0;

            label {
              font-weight: 500;
            }

            .PepleSerchHere {
              .PepleSerchHere {
                .SerchHere {
                  height: 42px;
                }
              }
            }

            .btnFullBtn {
              margin: 18px 0 0;
              font-size: 16px;
            }
          }
        }
      }

      .ModalClose {
        position: absolute;
        right: 0;
        left: 0;
        display: table;
        margin: 0px auto;
        bottom: -80px;
        width: 40px;
        height: 40px;

        .CloseModal {
          background-color: transparent;
          border: 0px;
          padding: 0;
          width: 100%;
          height: 100%;

          svg {
            width: 100%;
            height: 100%;
          }

          &:hover {
            background-color: transparent;
            border: 0px;
            box-shadow: none;
            outline: none;
          }

          &:hover {
            background-color: transparent;
            border: 0px;
            box-shadow: none;
            outline: none;
          }
        }
      }
    }
  }
}

.SchedulewMeeting {
  .modal-dialog {
    .modal-content {
      .modal-header {
        justify-content: center;
        padding: 1rem 1rem;

        .modal-title {
          h4 {
            font-size: 18px;
          }
        }
      }

      .modal-body {
        .MeetingNewSetup {
          padding: 10px 0;
        }
      }
    }
  }
}

.CreatePollModal {
  .modal-dialog {
    max-width: 380px;

    .modal-content {
      .modal-header {
        justify-content: center;
        padding: 1rem 1rem;

        .modal-title {
          h4 {
            font-size: 20px;
          }
        }
      }

      .modal-body {
        padding: 0 0;

        .MeetingNewSetup {
          padding: 0px 30px 0px 32px;
          height: 460px;
          overflow-y: auto;
          width: 378px;
          margin: 20px 0;
          scrollbar-width: thin;

          .DayMeetingTimeSetup {
            .btnFullBtn {
              height: 45px;
            }
          }
        }
      }
    }
  }
}

.YoutProfilePopUp {
  .FullYourProfile {
    padding-left: 14px
  }

  .modal-dialog {
    max-width: 680px;

    .modal-content {
      height: 560px;

      .modal-header {
        padding: 16px 30px 0;
        border: 0;

        .modal-title {
          h4 {
            font-size: 18px;
          }
        }

        .MyMeeting {
          padding: 8px 13px 8px 8px;
        }
      }

      .modal-body {
        .MeetingNewSetup {
          padding: 10px 0;

          .DayMeetingTimeSetup {
            .btnFullBtn {
              height: 45px;
            }
          }
        }
      }
    }
  }
}

.ProfileOverflow {
  overflow-y: auto;
  overflow-x: hidden;
  height: 332px;
  padding-left: 0px !important;
  padding-right: 14px !important;
  scrollbar-width: thin;
}

.FullYourProfile {
  .nav-tabs {
    border: 0;
    display: block;
    margin: 0px 0 22px;

    li {
      display: inline-block;
      text-align: center;
      margin: 0 16px;

      .nav {
        justify-content: center;
        cursor: pointer;

        .nav-link {
          padding: 0;
          background-color: transparent;
          border: 0;
          padding: 8px 28px;
          border-radius: 100px;
          font-weight: 500;
          font-size: 15px;
          color: #333;

          &.active {
            padding: 8px 28px;
            background-color: var(--gray);
            border: 0px;
            border-radius: 100px;
          }
        }
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.UserEditInfoX {
  .nav-tabs {
    border-bottom: 1px solid #dee2e6;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
}

.InfoInterestEdit {
  .ProfileBasicDetails {
    padding: 0 8px;

    .form-group {
      margin-bottom: 1em;
      position: relative;

      .EditInfoDetails {
        position: relative;

        label {
          font-size: 12px;
          font-weight: 400;
          color: #676767;
          margin-bottom: 4px;
        }

        .form-control {
          font-size: 14px;
          height: 44px;
          border: 1px #e9e6e6 solid;
          border-radius: 5px;
          letter-spacing: .3px;
          font-weight: 300;
          //cursor: pointer;
          position: relative;
          z-index: 2;
          background: transparent;

          &:hover {
            box-shadow: none;
            outline: none;
          }

          &:focus {
            box-shadow: none;
            outline: none;
          }
        }

        textarea {
          font-size: 14px;
          border: 1px #e9e6e6 solid;
          width: 100%;
          padding: 8px 10px;
          height: 70px !important;
          border-radius: 5px;
          letter-spacing: 0.3px;
          font-weight: 300;

          &:hover {
            box-shadow: none;
            outline: none;
          }

          &:focus {
            box-shadow: none;
            outline: none;
          }
        }

        .error {
          position: absolute;
          font-size: 11px;
          bottom: -18px;
          left: 0;
        }

        .SelectImgs {
          margin: 10px 0 0px;
          cursor: pointer;
          position: relative;
          height: 114px;

          img {
            border-radius: 10px;
            border: 1px #e8e8e8 solid;
            height: 100%;
            width: 100%;
          }

          .SelectImgBg {
            position: absolute;
            top: 8px;
            right: 10px;
            width: 20px;
            height: 20px;

            img {
              border: 0px !important;
            }
          }
        }

        .UpdateOvelayImgVodeo {
          .minHeightBox {
            height: 114px !important;
            min-height: 114px !important;
            flex: 0 0 202px !important;
            max-width: 202px !important;
          }
        }

        .LimitTxt {
          position: absolute;
          right: 4px;
          top: 3px;
          font-weight: 500;
          font-size: 12px;
          color: #a7a4a4;
        }

        .TimeZoneProfile {
          position: relative;

          &:after {
            content: "";
            display: inline-block;
            width: 10px;
            height: 10px;
            border-top: 2px solid #787878;
            border-right: 2px solid #787878;
            transform: rotate(135deg);
            position: absolute;
            right: 18px;
            top: 14px;
          }
        }

      }

      .ContryCodeNumber {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .DialCodePin {
          width: 85px;
          padding-right: 12px !important;

          select {
            text-align: center;
          }
        }

        .PhoneNoInput {
          width: 88%;
        }
      }
    }

    .form-control {
      font-size: 14px;
      height: 44px;
      border: 1px #e9e6e6 solid;
      border-radius: 5px;
      letter-spacing: .3px;
      font-weight: 300;
      //cursor: pointer;
      position: relative;
      z-index: 2;
      background: transparent;

      &:hover {
        box-shadow: none;
        outline: none;
      }

      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
  }
}



.FooterButton {
  .EditSaveNext {
    position: absolute;
    bottom: 28px;
    margin: 0px auto;
    left: 0;
    right: 0;

    .SaveNext {
      background-color: var(--blue);
      font-weight: 500;
      font-size: 15px;
      width: 340px;
      padding: 10px 0;
      border-radius: 100px;
    }

    .nav {
      display: block;
    }
  }

  .YourProsaveNext {
    text-align: right !important;
    bottom: 15px;
    padding: 0 24px;
    border-top: 1px #e9e6e6 solid;

    .SaveNext {
      width: auto !important;
      margin: 14px 0 0;
      padding: 10px 40px;
      font-weight: 300;
    }
  }
}

.ProfileMyInterest {
  ul {
    margin: 0;
    padding: 0;
    position: relative;

    .error {
      position: absolute;
      font-size: 14px;
      bottom: -6px;
      left: 0;
    }
  }

  li {
    display: inline-block;
    width: 33.33%;
  }

  .check-group {
    display: block;
    margin-bottom: 25px;

    input {
      padding: 0;
      height: initial;
      width: initial;
      margin-bottom: 0;
      display: none;
      cursor: pointer;
    }

    label {
      position: relative;
      cursor: pointer;
      font-weight: 400;
      margin: 0;
      font-size: 13.5px;

      &::before {
        content: '';
        -webkit-appearance: none;
        background-color: transparent;
        border: 1px solid #7d7d7d;
        padding: 10px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        border-radius: 0px;
        cursor: pointer;
        margin-right: 12px;
      }
    }
  }

  .check-group input:checked+label:after {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    left: 8px;
    width: 6px;
    height: 11px;
    border: solid #7d7d7d;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  .check-group input:checked+label:before {
    background-color: #ffffff;
  }

  p {
    color: #2b393e;
    font-size: 14px;
    font-weight: 500;
    margin: 10px 0 10px;
  }
}

.NetworkFilters {
  width: 360px;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .form-group {
    width: 100%;

    label {
      font-size: 14px;
      font-weight: 500;
      color: #676767;
      margin-bottom: 4px;
    }

  }
}

.DeskHelpPeople {
  .InfoHelpDesk {
    width: 100%;
    padding: 18px;
    height: calc(100vh - 158px);
    overflow-y: auto;
    position: relative;
    scrollbar-width: thin;

    .ChatInfos {
      width: 100%;
      height: calc(100vh - 260px);
      overflow-y: auto;
      padding-right: 14px;
      scrollbar-width: thin;

      .ReceiverMassage {
        position: relative;
        width: 100%;
        float: left;
        margin: 0 0 14px;

        .receveMessage {
          float: left;
          background-color: #ffffff;
          padding: 16px 20px;
          border-radius: 16px 16px 16px 0px;
          min-width: 130px;

          p {
            margin: 0;
            font-size: 11px;
            font-weight: 400;
            margin-bottom: 2px;
            color: #3a3a3a;
          }

          h4 {
            margin: 0;
            font-size: 14px;
            color: #3c3c3c;
          }
        }

        .ReceiveTimeDate {
          display: block;
          font-size: 11px;
          width: 100%;
          float: left;
          margin: 6px 0 0;
          color: #717171;
          font-weight: 500;
          text-align: left;
        }
      }

      .SenderMassage {
        position: relative;
        width: 100%;
        float: left;
        margin: 0 0 14px;

        .SenderMessage {
          float: right;
          background-color: var(--gray);
          padding: 6px 12px 6px 6px;
          border-radius: 16px 16px 0px 16px;

          // min-width: 130px;
          p {
            margin: 0;
            font-size: 11px;
            font-weight: 400;
            margin-bottom: 2px;
            color: #3a3a3a;
          }

          h4 {
            margin: 0;
            font-size: 12px;
            color: #3c3c3c;
          }

        }

        .SenderTimeDate {
          display: block;
          font-size: 10px;
          width: 100%;
          float: left;
          margin: 6px 0 0;
          color: #717171;
          font-weight: 500;
          text-align: right;
        }
      }

    }
  }
}

.PeopleHelpDeskInfo {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 380px;
  height: calc(100vh - 73px);
  background: var(--light-gray);
  z-index: 9;
  border-radius: 8px;
  padding: 0px 0 0;

  .responsive-tabs-container {
    position: relative;

    .CloseButton {
      padding: 0 0;
      width: 24px;
      height: 24px;
      position: absolute;
      right: 9px;
      top: 5px;

      svg {
        width: 100%;
        height: 100%;
      }

      &:hvoer {
        box-shadow: none;
        outline: none;
      }

      &:focus {
        box-shadow: none;
        outline: none;
      }
    }

    .nav-tabs {
      padding: 18px 26px;

      li {
        width: 100%;
        text-align: center;

        .nav {
          .nav-link {
            background-color: transparent;
            padding: 0;
            border: 0;
            color: var(--blue);
            font-weight: 500;
            font-size: 18px;
          }

          p {
            font-size: 14px;
            text-align: left;
            color: var(--dark-gray);
            font-weight: 500;
            margin: 10px 0 0;
            line-height: 20px;
          }
        }
      }
    }
  }

  .InfoHelpDesk {
    width: 100%;
    padding: 18px;
    height: calc(100vh - 200px);
    overflow-y: auto;
    position: relative;
    scrollbar-width: thin;
  }
}

.MyNewRoomMeeting {
  overflow-y: auto;
  overflow-x: hidden;
  height: 445px;
  padding-left: 15px !important;
  padding-right: 25px !important;
  scrollbar-width: thin;

  .RoomMeetingStyleNew {
    background-color: var(--light-gray);
    border-radius: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 22px;
    padding: 8px 8px;

    .RoomLeftDyTime {
      background-color: #ffffff;
      width: 115px;
      padding: 25px 15px;
      border-radius: 20px;
      text-align: center;
      position: relative;
      height: 100%;

      h4 {
        margin: 0;
        color: var(--dark-gray);
        font-weight: 400;
        line-height: 25px;
        font-size: 18px;

        strong {
          display: block;
          font-size: 18px;
          font-weight: 500;
        }
      }

      p {
        margin: 3px 0 0;
        color: var(--blue);
        font-weight: 400;
        line-height: 25px;
        font-size: 18px;
      }
    }

    .RoomRightStatusMeeting {
      width: 80%;
      padding: 6px 22px;

      .HaveWithMeetig {
        p {
          margin: 0 0 10px;
          font-size: 15px;
          font-weight: 500;
          color: #656464;
        }
      }

      .ViewMeetingWithUs {
        display: flex;
        border-radius: 8px;
        margin-right: 18px;
        width: 100%;
        justify-content: space-between;

        .ZxMeetingTpIngo {
          width: 60%;
          display: inline-flex;
          align-items: center;

          .NewMeetingPeopleImgs {
            width: 72px;
            height: 72px;
            margin-right: 14px;

            .img-fluid {
              border-radius: 100px;
            }
          }

          .NewMeetingPeopleInfo {
            h3 {
              margin: 0 0 2px;
              font-size: 18px;
              font-weight: 500;
            }

            p {
              margin: 0 0 2px;
              font-size: 14px;
              font-weight: 400;
            }

            span {
              background-color: #dedede;
              padding: 4px 17px;
              border-radius: 100px;
              font-size: 14px;
              font-weight: 400;
              margin: 6px 0 0;
              display: inline-block;

            }
          }
        }

        .MeetingStatusInfo {
          margin: 8px 0 0;

          .StatusIngoNew {
            background-color: #ffffff;
            color: #eb354d;
            border: 0;
            border-radius: 100px;
            padding: 8px 18px;
            box-shadow: 0px 6px 6px rgb(0 0 0 / 9%);
            font-weight: 500;
            font-size: 14px;
          }
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.MyRoomNewMeeting {
  .modal-dialog {
    max-width: 720px;

    .modal-content {
      .modal-header {
        border: 0;
        padding: 24px 30px 10px;
      }
    }
  }
}


.EnterRoomEventPopup {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 380px;
  height: calc(100vh - 73px);
  background: var(--light-gray);
  z-index: 9;
  border-radius: 10px;
  padding: 0px 0 0;

  .Tabs-Room-Event {
    .nav-tabs {
      li {
        width: 50%;

        .nav {
          display: block;
          text-align: center;

          .nav-link {
            text-transform: uppercase;
            font-weight: 500;
            padding: 12px 0;
            color: var(--dark-gray);

            &.active {
              background-color: var(--gray);
              border-radius: 10px 0px 0px 0px;
              border-bottom: 2px #eb354d solid;
              color: #eb354d;
            }
          }
        }
      }
    }
  }

  .InfoPeopleChat {
    .PeopleChatPopup {
      position: initial;
      background-color: transparent;
      height: auto;

      .nav-tabs {
        border-bottom: 1px solid #bbbbbb !important;
      }

      .InfoPeopleChat {
        height: calc(100vh - 215px) !important;
      }
    }
  }
}


.RoomDetailSitting {
  background-color: #ffffff;
  padding: 10px 10px;
  border-radius: 12px;

  .RoomEventLogo {
    text-align: center;
    padding: 10px 0 14px;

    .img-fluid {
      width: 200px;
    }
  }

  .RoomSittingStatus {
    background-color: var(--light-gray);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 15px;
    border-radius: 4px;

    .SittingHereRoom {
      p {
        margin: 0;
        font-size: 14px;
        font-weight: 500;
      }

      h4 {
        margin: 0;
        font-size: 20px;
        font-weight: 600;
      }
    }

    .RoomExitNow {
      .ExitButtonRed {
        background-color: #eb354d;
        color: #ffffff;
        padding: 2px 20px;
        border-radius: 100px;
        font-size: 15px;
        font-weight: 500;
        border: 0;
      }
    }
  }

  .EventRoomPreview {
    margin: 10px 0 0;

    label {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 6px;
    }

  }
}

.EventStagePreview {
  margin: 18px 0;

  .img-fluid {
    border-radius: 10px;
  }
}

.ExploreOtherRooms {
  margin-top: 18px;

  h4 {
    color: var(--dark-gray);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 14px;
  }

  .RoomMeetingBoard {
    margin-bottom: 12px;
    padding: 14px 18px 24px;
  }
}

.JoinTableRoom {
  text-align: center;
  width: calc(100vw - 80px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .JoinPersonTable {
    background-color: #ffffff;
    border-radius: 24px;
    width: 44%;
  }

  .joinTableHeader {
    background-color: var(--light-gray);
    padding: 18px 0;
    border-radius: 24px 24px 0 0;

    h4 {
      margin: 0;
      font-size: 18px;
      font-weight: 500;
    }
  }

  .joinNowTableUs {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    padding: 48px 28px;

    .JoinWithTableLt {
      width: 50%;
      padding: 0 24px;
      overflow: hidden;

      .img-fluid {
        border-radius: 16px;
        width: 100%;
      }

      #pre-local-player {
        position: absolute;
        height: 100%;
        width: 100%;

        div {
          position: initial;
          border-radius: 12px;

          video {
            position: relative !important;
            display: block;
            border-radius: 10px;
          }
        }
      }

      .cameraHolder {
        position: relative;
        height: 100%;
        width: 100%;

        .cameraThumbnail {
          background-color: #f0f0f0 !important;
          position: absolute !important;
          width: 100%;
          height: 100%;
          padding: 0 100px;
          border-radius: 10px;

          svg {
            width: 100%;
            height: 100%;
            fill: #b3b3b3;
          }
        }

        .cameraThumbnailOff {
          background-color: #000 !important;
          position: absolute !important;
          width: 100%;
          height: 100%;
          padding: 0 125px;
          border-radius: 10px;

          svg {
            width: 100%;
            height: 100%;
            fill: #b3b3b3;
          }
        }
      }

    }

    .SelectCamraMicro {
      width: 50%;
      padding: 0 20px;

      .CamraMicophoneDDl {
        text-align: left;

        label {
          font-size: 15px;
          font-weight: 500;
          margin-bottom: 6px;
        }
      }

      .JoinTableBtn {
        margin-top: 32px;
        margin-top: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .JoinRedButton {
          background-color: var(--blue);
          color: #ffffff;
          font-weight: 400;
          border-radius: 100px;
          padding: 8px 15px;
          width: 100%;
          border-color: var(--blue);
          font-size: 14px;
        }

        .DevRedButton {
          background-color: transparent;
          font-weight: 400;
          border-radius: 100px;
          padding: 8px 15px;
          width: 100%;
          border: 1px #a5a5a5 solid;
          font-size: 14px;
          margin-right: 10px;
          color: #333333;
        }
      }
    }
  }
}


.BsActionBox4F {
  position: absolute;
  width: 92px;
  bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  background-color: #fff;
  margin: 0 auto;
  right: 0;
  height: 36px;
  border-radius: 100px;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 21%);

  .BsMic {
    margin-right: 0px !important;
  }

  .BsEnd {
    margin: 0 8px;
    margin-right: 0;

    .RedCommonBtn {
      margin-top: 0 !important;
      padding: 5px 28px;
      height: auto;
      border: 0;

      &:hover {
        border: 0;
        outline: none;
        box-shadow: none;
      }

      &:focus {
        border: 0;
        outline: none;
        box-shadow: none;
      }
    }
  }

  .ActionLive {
    margin: 0 8px;
    width: 26px;
    height: 26px;
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;
      fill: #333;
    }
  }

  &:first-child {
    margin-left: 0;
  }
}

.SelectDropdown {
  position: relative;

  .form-control {
    font-size: 14px;
    padding-left: 20px;
    border-radius: 100px;
    height: 42px;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    border: 1px solid #ced4da;
    padding-right: 50px;
    position: relative;
    background-color: transparent;
    z-index: 5;
    cursor: pointer;
    overflow: hidden;

    &:hover {
      outline: none;
      box-shadow: none;
      border: 1px solid #ced4da;
    }

    &:focus {
      outline: none;
      box-shadow: none;
      border: 1px solid #ced4da;
    }
  }

  &:after {
    content: "";
    display: inline-block;
    width: 13px;
    height: 13px;
    border-top: 2px solid var(--dark-gray);
    border-right: 2px solid var(--dark-gray);
    transform: rotate(135deg);
    position: absolute;
    right: 20px;
    top: 11px;
  }

  .SeleftDDl {
    .dropdown-toggle {
      font-size: 14px;
      padding-left: 20px;
      border-radius: 100px;
      height: 38px;
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      background-color: var(--light-gray);
      color: var(--dark-gray);
      font-weight: 500;
      border: 1px #8c8c8c solid;
      width: 100%;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 42px;

      &:after {
        display: none;
      }

      &:hover {
        outline: none;
        box-shadow: none;
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .dropdown-menu {
      width: 100%;
      height: 150px;
      overflow-y: auto;
      padding: 0px 0 !important;
      scrollbar-width: thin;

      .dropdown-item {
        font-size: 15px;
        white-space: break-spaces;
        word-break: break-word;
      }

      &::-webkit-scrollbar {
        width: 5px !important;
      }
    }

    .schedule-dropdown {
      width: 100%;
      height: auto !important;
      max-height: 150px !important;
      overflow-y: auto;
      padding: 0px 0 !important;
      scrollbar-width: thin;
    }
  }
}

.FindMatchDetail {
  margin: 10px 0 0;

  h2 {
    font-weight: 600;
    font-size: 20px;
    margin: 0 0 35px;
  }

  .MatchInfoDetail {
    background-color: var(--gray);
    border-radius: 12px;
    padding: 14px 30px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .DetailsTxtInfo {
      width: 100%;

      h3 {
        font-weight: 500;
        font-size: 18px;
        margin: 0;
      }

      p {
        font-weight: 500;
        font-size: 18px;
        margin: 0;
      }
    }
  }

  .leftMatchFind {
    .MatchNumber {
      strong {
        color: #eb354d;
        font-size: 42px;
        font-weight: 600;
      }
    }

    .DetailsTxtInfo {
      padding-left: 35px;

    }
  }

  .RightMatchFind {
    .MatchNumber {
      strong {
        color: #eb354d;
        font-size: 42px;
        font-weight: 600;
      }
    }

    .DetailsTxtInfo {
      padding-right: 12px;
    }
  }
}



.FindBestMatch {
  text-align: center;
  width: calc(100vw - 280px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .MatchBoxFindPeople {
    width: 78%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .PeopleMatchImg {
      width: 50%;
      padding: 0 60px;

      .MatchPeopleHere {
        .img-fluid {
          border-radius: 22px 22px 0 0;
        }
      }

      .FintMatchFooter {
        position: relative;
        background: #ffffff;
        padding: 40px 0px;
        border-radius: 0 0 22px 22px;
        padding: 65px 0px;

        .findMatchAction {
          position: absolute;
          left: 0;
          right: 0;
          background: #ffffff;
          width: 120px;
          margin: 0px auto;
          top: -30px;
          border-radius: 100px;
          padding: 4px 0px;
          box-shadow: 0px 2px 7px rgb(0 0 0 / 37%);

          .ActionIcon {
            width: 35px;
            height: 35px;
            display: inline-block;
            margin: 0 6px;

            svg {
              width: 100%;
              height: 100%;
            }
          }
        }

        .ExitNetworking {
          position: absolute;
          bottom: 33px;
          margin: 0px auto;
          left: 0;
          right: 0;
          width: 60%;
        }
      }
    }

    .BestMatchFindArea {
      width: 50%;
      padding: 0 30px;

      h5 {
        color: #fff;
        font-size: 20px;
        margin: 25px 0 0;
        font-weight: 400;
      }
    }
  }
}

.NetworkingSetup {
  height: calc(100vh - 130px);
  overflow-y: auto;
  padding: 0 52px 25px;
  margin-top: 40px;
  scrollbar-width: thin;

  .NetworkingProfiles {
    background-color: #ffffff;
    padding: 18px 18px;
    border-radius: 20px;
  }
}


.RecommendProfiles {
  padding: 20px 0 0;

  h4 {
    margin: 0 0 10px;
    font-size: 22px;
  }

  p {
    margin: 18px 0 0;
    font-size: 20px;
  }
}

.NetwokingProfileQuick {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--light-gray);
  padding: 10px 15px;
  border-radius: 8px;
  margin-top: 18px;

  .ProfilePicInfo {
    width: 60px;
    height: 60px;

    .img-fluid {
      width: 100%;
      height: 100%;
      border-radius: 100px;
    }
  }

  .ProfileTxtInfo {
    width: 64%;
    position: relative;

    h4 {
      font-size: 17px;
      font-weight: 500;
      margin: 0 0 4px;
    }

    p {
      font-size: 14px;
      font-weight: normal;
      margin: 0;
    }

    svg {
      fill: var(--dark-gray);
    }
  }

  .ReadProfileIcon {
    width: 40px;
    height: 40px;
    background-color: #fff;
    padding: 8px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;

    img {
      width: 100%;
    }
  }
}



.NetworkIngAllAttendess {
  background-color: #ffffff;
  padding: 18px 18px;
  border-radius: 20px;
  margin: 34px 0;

  .AttendeesHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
  }

  .LeftSideAttendess {
    width: 350px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h4 {
      margin: 0;
      font-size: 22px;
      font-weight: 500;
    }

    .RedButton {
      padding: 6px 16px;
      width: 120px;
      font-size: 18px;

      &:hover {
        outline: none;
        box-shadow: none;
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .ResteHere {
      background-color: transparent;
      border: 0;

      &:focus {
        outline: none;
        box-shadow: none;
        border: 0;
      }

      &:hover {
        outline: none;
        box-shadow: none;
        border: 0;
      }
    }
  }

  .RIghtAttendesSearch {
    .NetworkPeople {
      width: 300px;

      .PepleSerchHere {
        .SerchHere {
          height: 42px;
        }
      }
    }
  }

  .FilderToggleBox {
    background-color: var(--light-gray);
    padding: 20px 20px;
    border-radius: 12px;
    margin-top: 18px;

    .FilterToggleInfo {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      width: 415px;

      h4 {
        font-size: 16px;
        margin: 0;
      }

      .InputHere {
        .form-control {
          width: 280px;
          border: 0;
          height: 42px;
          border-radius: 100px;
          font-size: 13px;
          padding: 0 12px 0 20px;
          border: 1px #a5a5a5 solid;

          &:hover {
            outline: none;
            box-shadow: none;
          }

          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
      }
    }
  }

  .InterestTag {
    display: inline-block;
    margin-left: 23px;

    span {
      background-color: #fff;
      padding: 2px 28px 2px 12px;
      border-radius: 4px;
      position: relative;
      margin-left: 9px;
      font-size: 13px;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      svg {
        fill: #000;
        position: absolute;
        right: 4px;
        top: 5px;
        cursor: pointer;
      }
    }

    p {
      display: inline-block;
      margin-left: 16px;
      font-weight: 500;
      font-size: 15px;

      a {
        color: var(--dark-gray);
      }
    }
  }
}

.MatchsConnectionPrev {
  background-color: #ffffff;
  padding: 18px 18px;
  border-radius: 20px;
  margin: 34px 0;

  h2 {
    font-size: 20px;
    font-weight: 500;
    margin: 0 0 2px;
  }

  p {
    font-size: 14px;
    margin: 0;
  }
}

.QuickShufflingMatch {
  background-color: var(--light-gray);
  padding: 22px 25px;
  border-radius: 16px;

  h2 {
    font-size: 22px;
    margin: 0 0 4px;
  }

  p {
    font-size: 14px;
    margin: 0 0 4px;
    font-weight: 400;
  }

  .MatchQuickGIF {
    padding: 22px 36px;
  }

  .RedButton {
    padding: 11px 12px;
    width: 170px;
    margin: 0px auto;
    display: table;
    font-size: 16px;

    svg {
      width: 22px;
      height: 22px;
    }

    &:hover {
      outline: none;
      box-shadow: none;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

/***************/
.popups-test {
  padding: 100px 0;

  .popInlind {
    display: inline-block;
    margin: 0 20px !important;
  }
}

/***************/

.lobby-bg-img {
  z-index: 0 !important;
}

// .carousel .slider-wrapper.axis-horizontal .slider .slide img {
//     height: 100%;
// }
.mx-ticker-wrapper marquee {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accordion-button:not(.collapsed) {
  color: #212529;
  background-color: #ffffff;
  box-shadow: none;
  border: 0;
}

.PeopleChatPopup {
  .StateEventDtl {
    padding: 0;
    height: calc(100vh - 150px);
    overflow: hidden;

    .peopleInfos {
      //padding: 18px 18px 0px;
      height: calc(100vh - 140px);
      overflow-y: auto;
      overflow-x: hidden;
      scrollbar-width: thin;

      .PepleSerchHere {
        width: 90%;
        margin: 18px auto 0;
      }

      .PeopleInfoScroll {
        height: calc(100vh - 214px);
        overflow-y: auto;
        overflow-x: hidden;
        padding: 10px 18px 0;
        scrollbar-width: thin;
      }

      .PeopleAttendList {
        height: calc(100vh - 225px);
        overflow-y: auto;
        margin-top: 14px;
        padding-right: 14px;
        scrollbar-width: thin;
      }

      .PepleSerchHere {
        margin-bottom: 10px;
      }

      .DataNotFound {
        height: calc(100vh - 225px);
      }
    }

    .nav-tabs {
      padding: 10px 10px 10px;

      li {
        width: 40%;
        margin-bottom: 0px !important;

        .nav {
          .nav-link {
            padding: 6px 20px;
            position: relative;

            &.active {
              padding: 6px 20px;
            }

            .badge {
              top: 3px !important
            }
          }
        }
      }
    }

    .ChatInfos {
      height: calc(100vh - 266px) !important;
      padding: 36px 18px 0;
    }

    // .PollInfos {

    // }



    // .StageBackStage {
    //     .nav-tabs {
    //         li {
    //             width: 100%;
    //             .nav {
    //                 .nav-link {
    //                    background: transparent;
    //                    padding: 12px 10px 12px;
    //                    width: 100%;
    //                  }
    //             }
    //         }
    //          li+li {
    //              width: auto !important;
    //          }
    //     }
    // }
    .StageBackStage {
      .nav-tabs {
        justify-content: space-evenly !important;
        padding: 0px !important;

        li {
          .nav-link {
            color: #333;
            border-bottom: 2px transparent solid;
            background-color: transparent !important;
            box-shadow: none !important;
            border-radius: 0px !important;
            padding: 12px 0;

            &.active {
              border-bottom: 2px #e2747f solid;
              padding: 12px 0;
              background-color: transparent !important;
              box-shadow: none !important;
              border-radius: 0px !important;
              width: 100%;
              color: #333;
            }
          }
        }
      }
    }
  }
}

.CameraPrevModal {
  .modal-dialog {
    max-width: 750px;
    height: calc(100vh - 50px);
    margin: 0 auto !important;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0s ease-out !important;
    transform: translate(0, 0px) !important;

    .modal-content {
      background-color: transparent;

      .DarkBlurBackground {
        .JoinTableRoom {
          width: 100%;

          .JoinPersonTable {
            width: 100%;

            .modal-body {
              padding: 0;

              .joinNowTableUs {
                padding: 38px 5px;
              }

              .JoinWithTableLt {
                padding: 0 12px 0 22px;
              }

              .joinNowTableUsGranted {
                padding: 22px 80px;
                width: 100%;
                margin: 0 auto;
                background: #2d2d2d;
                border-radius: 0px 0px 20px 20px;

                p {
                  margin: 0;
                  color: #fff;
                  font-weight: 500;
                  font-size: 14px;
                }

                div {
                  width: 100% !important;
                  height: 100% !important;
                  margin: 16px 0 10px;

                  video {
                    width: 100%;
                    height: 100%;
                  }
                }
              }

              .joinNowTableUsLoader {
                padding: 32px 80px;
                width: 100%;
                margin: 0 auto;
                background: #2d2d2d;
                height: 330px;

                .loader {
                  width: 460px;
                  height: 100%;
                  background: #000;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 8px;
                  margin: 0 auto;

                  svg {
                    width: 45px;
                    height: 45px;
                    color: #6f6e6e;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.RedCommonBtn {
  border-radius: 100px;
  color: #ffffff;
  font-weight: 500;
  font-size: 18px;
  box-shadow: 0px 4px 5px #00000012;
  height: 44px;
  margin-top: 35px;
  justify-content: center;
  align-items: center;
  width: auto;
  padding: 10px 28px;
  display: flex;

  &:hover {
    color: #ffffff;
  }

  &:focus {
    color: #ffffff;
  }
}


.BackstageInfoStream {
  // width: calc(100% - 370px);
  width: 100%;
  float: left;
  position: relative;
  background-color: var(--light-gray);
  border-radius: 10px;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  z-index: 999;
  .HeadingBstage {
    width: 100%;
    text-align: left;
    background-color: var(--gray);
    display: flex;
    padding: 8px 10px;
    border-radius: 10px 10px 0 0;
    position: relative;
    align-items: center;
    justify-content: space-between;

    //height: 57px;
    h3 {
      color: #eb354d;
      font-weight: 500;
      font-size: 18px;
      //text-transform: uppercase;
      display: inline-block;
      margin: 0;
      padding-left: 44px;
      position: absolute;
    }

    .BsMidAction {
      /*position: absolute; */
      /* width: 225px; */
      top: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 0;
      background-color: #fff;
      margin: 0 auto;
      right: 0;
      height: 41px;
      border-radius: 100px;
      box-shadow: 0px 4px 10px #00000036;
      display: inline-flex;
      padding: 0 10px;

      .BsMic {
        margin-right: 0px !important;
      }

      .BsEnd {
        margin: 0 8px;
        margin-right: -8px;

        .RedCommonBtn {
          margin-top: 0 !important;
          padding: 5px 28px;
          height: auto;
          border: 0;

          &:hover {
            border: 0;
            outline: none;
            box-shadow: none;
          }

          &:focus {
            border: 0;
            outline: none;
            box-shadow: none;
          }
        }
      }

      .ActionLive {
        margin: 0 6px;
        width: 28px;
        height: 28px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        svg {
          width: 100%;
          height: 100%;
          fill: #5d5d5d;
        }

        .StartEndRecordBtn {
          background: transparent;
          border: 0;
          padding: 2px 4px
        }
      }

      .StartStop {
        width: auto;
        height: auto;
        margin-right: 0;
        position: relative;

        .switch {
          min-width: 54px !important;
          min-height: 24px !important;
          border-radius: 100px
        }

        .btn-danger {
          .switch-group {
            .switch-on {
              background-color: #fff !important;
              padding-right: 1.2rem !important;
              padding: 4px 0 4px 4px;
              line-height: 15px;
            }

            .btn {
              font-size: 13px;
              color: #1e1e1e;
            }

            .switch-handle {
              height: 18px;
              width: 18px;
              border-width: 0 1px;
              background-color: #d70b0b;
              border-radius: 100px;
              padding: 0;
              right: 11px;
              top: 2px;
            }
          }
        }

        .btn-secondary {
          .switch-off {
            background-color: #d5d5d5 !important;
            padding: 4px 0 4px 20px;
            font-size: 13px;
            line-height: 15px;
            color: #1e1e1e;
          }

          .switch-handle {
            height: 18px;
            width: 18px;
            border-width: 0 1px;
            background-color: #737373;
            padding: 0;
            left: 11px;
            top: 2px;
            border-radius: 100px;
          }
        }

      }

      .disabled-recording-btn {
        &::after {
          position: absolute;
          content: "";
          background-color: rgb(217 217 217 / 58%);
          left: 0;
          right: 0;
          width: 100%;
          height: 100%;
          top: 0;
          border-radius: 100px;
        }
      }
    }



    .ActionBcFcHeader {
      display: inline-flex;

      .RigthtStreamBs {
        margin-left: 8px;

        .MuteBtnUnmute {
          display: inline-flex;
          align-items: center;
          justify-content: center;

          .BsUnmuteIcon {
            width: 16px;
            height: 16px;
            display: inline-flex;
            vertical-align: middle;
            margin-right: 6px;

            svg {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }








  }

  .streamingUsers {
    /* overflow-y: hidden; */
    /* padding: 6px 0 6px; */
    /* display: flex; */
    /* flex-flow: row nowrap; */
    /* align-items: center; */
    /* justify-content: flex-start; */
    width: 100%;
    overflow: hidden;

    .StreamVideoFrame {
      overflow: hidden;
      overflow-y: hidden;
      background-color: #f0f0f0;
      scrollbar-width: thin;
      /* display: inline-flex; */
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      padding: 6px 4px;
      position: relative !important;
      height: 162px;
      width: 99.20%;
      margin-left: 6px;
      border-radius: 10px;

      .back-stage-stream-scroll {
        display: inline-flex;
        padding: 0 0;
        float: left;
        width: 100%;

        .slick-slider {
          width: 100%;

          .slick-arrow {
            position: absolute;
            top: 38%;
            transform: translate(-38%);
          }

          .slick-prev {
            left: 12px;
            //background: #f00;
            z-index: 4;
          }

          .slick-next {
            right: 8px;
            //background: #f00;
            z-index: 9;
          }

          // .slick-list {
          //   .slick-slide {

          //   }
          // }
          .slick-disabled {
            opacity: .3;
          }
        }

        .list-null {
          display: initial !important;

          .video_footer {
            .mx-user-role {
              position: absolute;
              bottom: 34px;
              left: 0;
              //background-color: #eb354d;
              padding: 0px 6px;
              color: #fff;
              border-radius: 0 4px 0 0;
              font-weight: 400;
              height: 22px;
              display: flex;
              align-items: center;
              justify-content: center;

              small {
                font-size: 10px;
                color: #fff;
              }
            }
          }
        }

        .video_footer {
          width: auto !important;
          margin: 0 3px;
          height: 150px !important;
          position: relative !important;
          background-color: var(--dark-gray);
          border-radius: 10px;
          border: 2px #f0f0f0 solid;

          .ShortName {
            height: 85%;

            h4 {
              font-size: 24px;
              height: 55px;
              width: 55px;
            }
          }

          .localPlayer {
            width: 100% !important;
            height: 100% !important;
            position: relative !important;

            img {
              width: 100%;
            }

            div {
              border-radius: 8px;
              position: relative;
              background: rgb(0 0 0 / 11%) !important;

              video {
                position: relative !important;
                border-radius: 10px;
              }
            }

            .userLoginBg {
              height: 100%;
            }
          }

          .bottom_footer {
            position: absolute;
            display: flex;
            bottom: 0;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            background-color: #ffffff;
            border-radius: 0 0 6px 6px;
            padding: 8px 8px;
            z-index: 5;

            .NameExpFull {
              width: 100%;

              h4 {
                margin: 0;
                font-size: 14px;
                font-weight: 500;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
              }
            }

            .activeSpeaker {
              padding-left: 2px;

              h4 {
                padding-left: 12px;
                position: relative;

                .ActiveGreen {
                  position: absolute;
                  width: 8px;
                  height: 8px;
                  left: 0px;
                  top: 4px;
                  ;
                }
              }
            }

            .RightSvgIcon {
              display: inherit;

              span {
                margin-left: 12px;
                display: inline-flex;
                width: 22px;
                height: 22px;
                align-items: center;
                justify-content: center;

                &:first-child {
                  margin-left: 0;
                }

                svg {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }

      &::-webkit-scrollbar {
        height: 4px;
      }
    }

  }
}

.slick-prev:before,
.slick-next:before {
  font-size: 28px !important;
}

.MainViewBackStage {
  display: flex;
  width: calc(100% - 382px);
  flex-direction: column;
  text-align: center;
  height: calc(100vh - 348px);
  justify-content: center;
  align-items: center;
  float: left;

  .RemoveAllDiv {
    width: auto !important;
    display: inline-block !important;
    height: 100% !important;

    .front-stage-stream-scroll {
      //position: absolute;
      left: 0;
      right: 0;
      margin: 0px auto;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .alignAllDiv {
    width: 100%;
    height: 100%;
    position: relative;
    display: inline-block;

    .img-fluid {
      height: 100% !important;
      width: 100% !important;
    }

    .front-stage-stream-scroll {
      height: 100%;
      position: relative;
      overflow: hidden;

      .row {

        // div {
        //     width: 100% !important;
        //     height: 100% !important;
        // }
        .JoinBroadcastBtn {
          width: 330px !important;
          height: auto !important;
          position: relative;
          z-index: 9;

          .form-group {
            margin: 30px 0px 0px;
          }
        }

        .RightSvgIcon {
          width: auto !important;
          height: auto !important;
        }

        .front-stage-share-screen {
          .ImageBroadcastLive {
            .RatioDiv16 {
              img {
                object-fit: contain;
              }
            }
          }

          .PDFBroadcastLive {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            .RatioDiv16 {
              //aspect-ratio: 16/9;
              height: 99%;
              margin: 0px auto;
              width: auto;

              img {
                object-fit: scale-down;
                position: initial !important;
                width: 100% !important;
                height: 100% !important;
                //border-radius: 10px;
              }
            }
          }

          .pinUserInfo {
            .video_footer {
              .userLoginBg {
                div {
                  video {
                    object-fit: cover !important;
                  }
                }
              }

              .RemoveSpeaker {
                .pinRemoveAction {
                  span {
                    background-color: #ffffff;
                    width: 34px;
                    height: 34px;

                    svg {
                      width: 40px;
                      height: 40px;
                      left: -3px;
                      top: -1px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .localPlayer {
        width: 100%;
        height: 100%;

        div {
          border-radius: 4px;

          video {
            position: relative !important;
            border-radius: 4px !important
          }
        }
      }

      .UserNameScreen {
        position: absolute;
        z-index: 4;
        width: 100%;
        text-align: center;
        top: 0;

        span {
          background-color: #000000d1;
          color: #fff;
          padding: 3px 14px;
          font-weight: 500;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          width: 200px;
          margin: 0px auto;
        }
      }

      .video_footer {
        width: 100%;
        height: 100%;

        .ShortName {
          height: 100%;
        }
      }

      .RightSvgIcon {
        .mh-cursor-pointer {
          position: absolute;
          top: 10px;
          left: 10px;
          background-color: #000;
          border-radius: 100px;
          width: 40px;
          height: 40px;
          padding: 12px;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            width: 100%;
            height: 100%;

            path {
              fill: #fff !important;
            }
          }
        }
      }
    }
  }
}





.CreatQuizPoll {
  .form-group {
    .form-switch {
      .form-check-input {
        background-color: #e8e8e8;

        &:checked {
          background-color: #36a710 !important;
          border-color: #36a710 !important;
        }

        &:focus {
          border-color: #989898;
          box-shadow: none;
        }
      }

      input {
        &:hover {
          outline: none;
          box-shadow: none;
        }

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }

    label {
      color: var(--dark-gray);
      font-size: 14px;
      margin: 0 0 5px;
      font-weight: 500;
    }

    .PollOptionBx {
      position: relative;

      .SerchHere {
        width: 100%;
        height: 45px;
        border-radius: 100px;
        font-size: 14px;
        padding: 0 12px;
        border: 1px #a5a5a5 solid;
        color: var(--dark-gray);

        &:hover {
          outline: none;
          box-shadow: none;
        }

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }

      .RemoveOption {
        position: absolute;
        right: 1px;
        top: 1px;
        height: 95%;
        // background-color: #e8e3e3;
        padding: 0 12px;
        border-radius: 0 6px 6px 0px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 24px;
          height: 24px;
          fill: #eb354d;
          cursor: pointer;
        }
      }
    }

    .MoreOption {
      .SerchHere {
        padding-right: 52px !important;
      }
    }

    .SelectDropdown {
      .dropdown {
        .css-2b097c-container {

          .css-1pahdxg-control {
            height: 46px;
            border: 1px #a5a5a5 solid;
            box-shadow: none;
            padding: 0 12px;
            font-size: 14px;
            border-radius: 100px;
            cursor: pointer;

            div {
              padding: 0 0;
              padding-right: 15px;

              .css-1wy0on6 {
                .css-1okebmr-indicatorSeparator {
                  display: none;
                }

                .css-1okebmr-indicatorSeparator {
                  display: none;
                }

                .css-1gtu0rj-indicatorContainer {
                  display: none;
                }

                &::after {
                  content: "";
                  display: inline-block;
                  width: 13px;
                  height: 13px;
                  border-top: 2px solid var(--dark-gray);
                  border-right: 2px solid var(--dark-gray);
                  transform: rotate(135deg);
                  position: absolute;
                  right: 20px;
                  top: 15px;
                }

                .css-tlfecz-indicatorContainer {
                  display: none !important;
                }
              }
            }

            .css-1wy0on6 {
              .css-1okebmr-indicatorSeparator {
                display: none;
              }

              .css-1okebmr-indicatorSeparator {
                display: none;
              }

              .css-1gtu0rj-indicatorContainer {
                display: none;
              }

              &::after {
                content: "";
                display: inline-block;
                width: 11px;
                height: 11px;
                border-top: 2px solid var(--dark-gray);
                border-right: 2px solid var(--dark-gray);
                transform: rotate(135deg);
                position: absolute;
                right: 20px;
                top: 15px;
              }

              .css-tlfecz-indicatorContainer {
                display: none !important;
              }
            }
          }

          .css-yk16xz-control {
            width: 100%;
            height: 46px;
            border-radius: 100px;
            font-size: 14px;
            padding: 0 12px;
            border: 1px #a5a5a5 solid;
            color: var(--dark-gray);
            cursor: pointer;

            div {
              padding: 0 0;
              padding-right: 15px;


            }

            .css-1wy0on6 {
              .css-1okebmr-indicatorSeparator {
                display: none;
              }

              .css-1gtu0rj-indicatorContainer {
                display: none;
              }

              &::after {
                content: "";
                display: inline-block;
                width: 13px;
                height: 13px;
                border-top: 2px solid var(--dark-gray);
                border-right: 2px solid var(--dark-gray);
                transform: rotate(135deg);
                position: absolute;
                right: 20px;
                top: 15px;
              }

              .css-tlfecz-indicatorContainer {
                display: none !important;
              }
            }
          }
        }

        div {
          text-align: left !important;
        }
      }

      .css-1hb7zxy-IndicatorsContainer {
        div {
          display: none;
        }

        .css-tlfecz-indicatorContainer {
          display: none;
        }

        .css-1okebmr-indicatorSeparator {
          display: none;
        }

        &::after {
          content: "";
          display: inline-block;
          width: 11px;
          height: 11px;
          border-top: 2px solid var(--dark-gray);
          border-right: 2px solid var(--dark-gray);
          transform: rotate(135deg);
          position: absolute;
          right: 20px;
          top: 14px;
        }
      }

      &::after {
        display: none;
      }
    }
  }
}

.JoinBroadcastBtn {
  width: 300px;

  .btnFullBtn {
    height: auto;
    padding: 10px 0;
    font-size: 16px;
    font-weight: 400;
  }
}

.SelectCamraMicro {
  .CamraMicophoneDDl {
    .form-group {
      label {
        color: var(--dark-gray);
      }

      .SelectDropdown {
        .dropdown {
          .css-2b097c-container {

            .css-1pahdxg-control {
              height: 50px;
              border: 1px #a5a5a5 solid;
              box-shadow: none;
              padding: 0 12px;
              font-size: 14px;
              border-radius: 100px;
              cursor: pointer;

              div {
                padding: 0 0;
                padding-right: 15px;

                .css-1wy0on6 {
                  .css-1okebmr-indicatorSeparator {
                    display: none;
                  }

                  .css-1okebmr-indicatorSeparator {
                    display: none;
                  }

                  .css-1gtu0rj-indicatorContainer {
                    display: none;
                  }

                  &::after {
                    content: "";
                    display: inline-block;
                    width: 13px;
                    height: 13px;
                    border-top: 2px solid var(--dark-gray);
                    border-right: 2px solid var(--dark-gray);
                    transform: rotate(135deg);
                    position: absolute;
                    right: 20px;
                    top: 15px;
                  }

                  .css-tlfecz-indicatorContainer {
                    display: none !important;
                  }
                }
              }

              .css-1wy0on6 {
                .css-1okebmr-indicatorSeparator {
                  display: none;
                }

                .css-1okebmr-indicatorSeparator {
                  display: none;
                }

                .css-1gtu0rj-indicatorContainer {
                  display: none;
                }

                &::after {
                  content: "";
                  display: inline-block;
                  width: 13px;
                  height: 13px;
                  border-top: 2px solid var(--dark-gray);
                  border-right: 2px solid var(--dark-gray);
                  transform: rotate(135deg);
                  position: absolute;
                  right: 20px;
                  top: 15px;
                }

                .css-tlfecz-indicatorContainer {
                  display: none !important;
                }
              }
            }

            .css-yk16xz-control {
              width: 100%;
              height: 50px;
              border-radius: 100px;
              font-size: 14px;
              padding: 0 12px;
              border: 1px #a5a5a5 solid;
              color: var(--dark-gray);
              cursor: pointer;

              div {
                padding: 0 0;
                padding-right: 15px;


              }

              .css-1wy0on6 {
                .css-1okebmr-indicatorSeparator {
                  display: none;
                }

                .css-1gtu0rj-indicatorContainer {
                  display: none;
                }

                &::after {
                  content: "";
                  display: inline-block;
                  width: 13px;
                  height: 13px;
                  border-top: 2px solid var(--dark-gray);
                  border-right: 2px solid var(--dark-gray);
                  transform: rotate(135deg);
                  position: absolute;
                  right: 20px;
                  top: 15px;
                }

                .css-tlfecz-indicatorContainer {
                  display: none !important;
                }
              }
            }
          }

          div {
            text-align: left !important;
          }
        }

        .css-1hb7zxy-IndicatorsContainer {
          div {
            display: none;
          }

          .css-tlfecz-indicatorContainer {
            display: none;
          }

          .css-1okebmr-indicatorSeparator {
            display: none;
          }

          &::after {
            content: "";
            display: inline-block;
            width: 13px;
            height: 13px;
            border-top: 2px solid var(--dark-gray);
            border-right: 2px solid var(--dark-gray);
            transform: rotate(135deg);
            position: absolute;
            right: 20px;
            top: 14px;
          }
        }

        &::after {
          display: none;
        }
      }
    }
  }
}











.BackIconAction {
  position: absolute;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 8px;
  border-radius: 100px;
  z-index: 2;

  .ActionLive {
    margin: 0 2px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    background: #eb354d;
    border-radius: 100px;

    .widthSvg {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 5px;
      width: 100%;

      svg {
        fill: #fff;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.ShortName {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;

  h4 {
    color: #8e8e8e;
    font-size: 24px;
    font-weight: 700;
    border: 2px #8e8e8e solid;
    border-radius: 100px;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    margin: 0;
  }
}

.check-group {
  display: block;
  margin-bottom: 15px;

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  label {
    position: relative;
    cursor: pointer;
    font-weight: 500;
    margin: 0;

    &:before {
      content: '';
      -webkit-appearance: none;
      background-color: transparent;
      border: 1px solid #e0e0e0;
      padding: 10px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      border-radius: 4px;
      cursor: pointer;
      margin-right: 12px;
    }
  }
}

.check-group input:checked+label:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 8px;
  width: 7px;
  height: 12px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.check-group input:checked+label:before {
  background-color: #2f73f7;
}

.CheckHere {
  text-align: left;
  padding: 0 10px;

  .check-group {
    margin-bottom: 20px !important;
    position: relative;

    label {
      font-weight: 400 !important;
      font-size: 14px !important;

      p {
        display: initial;
        margin: 0px !important;
        font-size: 13px;
        word-break: break-word;
        color: #fff !important;
        padding-left: 27px;

        a {
          color: #1A78E5 !important;

          &:hover {
            color: #1A78E5;
          }

          &:focus {
            color: #1A78E5;
          }
        }
      }
    }

    .error {
      width: 90%;
      font-size: 12px;
      margin: 8px 0 0;
      text-align: left;
      display: block;
    }
  }
}

.CheckHere .check-group input:checked+label:before {
  background-color: transparent;
  border: 2px #ffffff solid;
  border-radius: 0;
  padding: 7px;
  margin-right: 8px;
}

.CheckHere .check-group label:before {
  background-color: transparent;
  border: 2px #ffffff solid;
  border-radius: 0;
  padding: 7px;
  margin-right: 8px;
  top: 0;
  left: 0;
  position: absolute;
}

.CheckHere .check-group input:checked+label:after {
  top: 4px;
  left: 7px;
  height: 9px;
  width: 5px;
  border-color: #ffffff;
}

.RoomMeetingPopUp {
  overflow: hidden;

  .modal-dialog {
    max-width: 100% !important;
    height: calc(100vh - 0px) !important;

    .modal-content {
      height: 100vh;
      background-color: transparent;

      .modal-header {
        padding: 20px 20px;
        text-align: center;
        display: block;
        position: relative;

        .modal-title {
          text-align: center;
        }
      }

      .modal-body {
        padding: 0px;

        .content-wrap {
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          height: 100%;
          width: 100%;
          max-width: 100%;
          margin: auto;
          position: relative;

          .RightTopStyle {
            position: fixed;
          }

          .content {
            position: relative;
            -webkit-transform-origin: 0% 0% 0;
            transform-origin: 0% 0% 0;
            height: 100%;
            width: 100%;
            max-width: 100%;
            padding: 0 0 !important;

            .MeetingNewSetup {
              top: auto;
              right: 0;
              margin: 0 auto;
              width: 100%;
              z-index: 1;
              position: absolute;
              left: 0;
              background-color: transparent;
              padding: 0;
              border-radius: 0;
              max-width: 100%;
              height: 100%;
              /* height: 75.695vh!important;*/
              aspect-ratio: 16/9;

              iframe {
                width: 100%;
                border-radius: 8px;
                float: left;
                height: 100%;
              }
            }

            .RoomMeetingModalSetup {
              width: 100%;
              height: 100%;
              transition: all 0.5s ease;
              -webkit-transition: all 0.5s ease;
              -moz-transition: all 0.5s ease;
              -o-transition: all 0.5s ease;
              -o-transition: all 0.5s ease;

              .whereByshrinkSize {
                display: flex;
                width: 100%;
                height: 100%;
                aspect-ratio: 16/9;
                align-items: center;
                justify-content: center;

                whereby-embed {
                  max-width: 90%;
                  max-height: 90%;
                  height: 100% !important;
                  margin: 0 auto;
                  aspect-ratio: 16/9;
                }
              }
            }

            .shrinkWhereBy {
              width: calc(100% - 348px) !important;
              margin: 0 !important;
              top: 0 !important;
              transition: all 0.5s ease;
              -webkit-transition: all 0.5s ease;
              -moz-transition: all 0.5s ease;
              -o-transition: all 0.5s ease;
              -o-transition: all 0.5s ease;
              aspect-ratio: 16/9;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;

              .whereByshrinkSize {
                height: auto !important;
              }
            }
          }
        }
      }

      .InviteTableCall {
        height: calc(100vh - 64px) !important;

        .invitedTableCell {
          position: relative;
          height: calc(100vh - 64px);
        }
      }
    }
  }

  .ModalCloseBtn {
    position: absolute;
    right: 0px;
    bottom: -65px;
    width: 45px;
    height: 45px;
    left: 0;
    margin: auto;

    .CloseModal {
      background-color: transparent;
      border: 0px;
      padding: 0;
      width: 100%;
      height: 100%;

      svg {
        width: 100%;
        height: 100%;
        fill: #fff;
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &:hover {
        outline: none;
        box-shadow: none;
      }
    }
  }
}

.frontend-dashboard {
  padding: 0 0 !important;

  .MainViewBackStage {
    width: 100% !important;
    display: inherit !important;
    height: calc(100vh - 50px) !important;
    position: relative;
    display: flex !important;
    align-items: center;
    justify-content: center;

    .RemoveAllDiv {
      width: auto !important;
      height: 100% !important;
      display: flex !important;
      aspect-ratio: 16/9;
      position: relative;
      margin: 0px auto;

      .front-stage-stream-scroll {
        position: absolute;
        top: 0;
        width: 85% !important;
        aspect-ratio: 16/9;
        margin: 0px auto 0;
        padding: 0px 4px;
        border-radius: 10px;
      }

      img {
        position: absolute;
        left: 0;
        right: 0;
        height: 100% !important;
        width: 100% !important;
        margin: 0px auto;
      }
    }

    .alignAllDiv {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex !important;
      align-items: center;
      justify-content: center;

      .front-stage-stream-scroll {
        aspect-ratio: 16/9;
        margin: 0px auto 0;
        padding: 0px 4px;
        border-radius: 10px;
        width: 85%;
        height: auto;
        left: 0;
        right: 0;
        top: auto;

        // background-color: rgb(0 0 0 / 50%);
        .row {
          height: 100%;
          align-items: center;

          .video_footer {
            position: relative;
            background: #202020;
            border-radius: 10px;
            //     .ShortName {
            //         h4 {

            //         }
            //     }
            // .localPlayer {
            //     div {
            //         video {

            //         }
            //     }
            // }
            .bottom_footer {
              position: absolute;
              bottom: 5px;
              left: 0;
              width: 100%;
              text-align: left;

              h4 {
                color: #fff;
                font-size: calc(6px + 1vmin);
                font-weight: 500;
                margin: 0;

                span {
                  background-color: #000000;
                  padding: 4px 7px;
                  border-radius: 0px 0 10px 10px;
                }
              }
            }
          }

          .VideoBroadcastLive {
            position: absolute;
            padding: 0px;
          }

          .stage-user-list {
            height: 100%;
            padding: 0;
          }
        }
      }
    }
  }
}

.MainViewBackStage {
  .front-stage-stream-scroll {
    .row {
      height: 100%;
      align-items: center;

      .front-stage-share-screen {
        display: none;
      }

      .stage-user-list {
        display: inline-flex;
        height: 100%;
        align-items: center;
        flex-wrap: wrap;
        padding: 0;
        justify-content: center;

        .list-1 {
          flex: 0 0 auto;
          width: 100%;
          height: 100%;
          padding: 2px 2px;
        }

        .list-2 {
          flex: 0 0 auto;
          width: 50%;
          height: 50%;
          padding: 2px 2px 2px;
        }

        .list-3 {
          flex: 0 0 auto;
          width: 50%;
          height: 50%;
          padding: 2px 2px 2px;
        }

        .list-4 {
          flex: 0 0 auto;
          width: 50%;
          height: 50%;
          padding: 2px 2px 2px;

          .video_footer {
            .bottom_footer {

              //bottom: 0px !important;
              h4 {
                span {
                  display: initial !important;
                }
              }
            }
          }
        }

        .list-5 {
          flex: 0 0 auto;
          width: 33.33333333%;
          height: 50%;
          padding: 2px 2px 2px;
        }

        .list-6 {
          flex: 0 0 auto;
          width: 33.33333333%;
          height: 50%;
          padding: 2px 2px 2px;
        }

        .list-7 {
          flex: 0 0 auto;
          width: 25%;
          height: 50%;
          padding: 2px 2px 2px;
        }

        .list-8 {
          flex: 0 0 auto;
          width: 25%;
          height: 50%;
          padding: 4px 4px 4px;
        }

        .list-9 {
          flex: 0 0 auto;
          width: 25%;
          height: 33.33333333%;
          padding: 2px 2px 2px;
        }

        .list-10 {
          flex: 0 0 auto;
          width: 25%;
          height: 33.33333333%;
          padding: 2px 2px 2px;
        }

        .list-11 {
          flex: 0 0 auto;
          width: 25%;
          height: 33.33333333%;
          padding: 2px 2px 2px;
        }

        .list-12 {
          flex: 0 0 auto;
          width: 25%;
          height: 33.33333333%;
          padding: 2px 2px 2px;
        }

        .list-13 {
          flex: 0 0 auto;
          width: 25%;
          height: 25%;
          padding: 2px 2px 2px;
        }

        .list-14 {
          flex: 0 0 auto;
          width: 25%;
          height: 25%;
          padding: 2px 2px 2px;
        }

        .list-15 {
          flex: 0 0 auto;
          width: 25%;
          height: 25%;
          padding: 2px 2px 2px;
        }

        .list-16 {
          flex: 0 0 auto;
          width: 25%;
          height: 25%;
          padding: 2px 2px 2px;
        }

        .video_footer {
          .RightSvgIcon {
            .mh-cursor-pointer {
              transform: rotate(-180deg);
              padding: 10px;
            }
          }

          .bottom_footer.lower_third {

            //bottom: 0px !important;
            h4 {
              span {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box !important;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}












.FrontstageInfoStream {
  width: 100%;
  text-align: left;
  display: flex;
  // padding: 8px 20px;
  border-radius: 10px 10px 0 0;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  bottom: 26px;

  .BsMidAction {
    align-items: center;
    justify-content: center;
    left: 0;
    background-color: #fff;
    margin: 0 auto;
    right: 0;
    height: 43px;
    border-radius: 100px;
    box-shadow: 0px 4px 10px #00000036;
    display: inline-flex;
    padding: 0 3px;

    .BsMic {
      margin-right: 0px !important;
    }

    .BsEnd {
      margin: 0 8px;
      margin-right: 0;

      .RedCommonBtn {
        margin-top: 0 !important;
        padding: 5px 28px;
        height: auto;
        border: 0;

        &:hover {
          border: 0;
          outline: none;
          box-shadow: none;
        }

        &:focus {
          border: 0;
          outline: none;
          box-shadow: none;
        }
      }
    }

    .ActionLive {
      margin: 0 6px;
      width: 30px;
      height: 30px;
      cursor: pointer;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}



.organizer-frontend-dashboard {
  padding: 0 0 !important;

  .MainViewBackStage {
    width: calc(100% - 388px);
    // display: inherit !important;
    height: calc(100vh - 50px) !important;
    position: relative;
    display: flex !important;
    align-items: center;
    justify-content: center;
    transition: all 3s ease;
    -webkit-transition: all 3s ease;
    -moz-transition: all 3s ease;
    -o-transition: all 3s ease;
    -o-transition: all 3s ease;

    img {
      border-radius: 10px;
    }

    .RemoveAllDiv {
      width: 100% !important;
      height: 100% !important;
      display: flex !important;
      align-items: center;
      justify-content: center;
      aspect-ratio: 16/9;

      .front-stage-stream-scroll {
        position: absolute;
        left: 0;
        right: 0;
        width: 85%;
        height: auto !important;
        margin: 0px auto;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .alignAllDiv {
      width: 100%;
      height: 100%;
      position: relative;
      // display: inline-block;
      display: flex !important;
      align-items: center;
      justify-content: center;

      img {
        height: 100%;
      }

      .front-stage-stream-scroll {
        aspect-ratio: 16/9;
        margin: 0px auto 0;
        padding: 0px 4px;
        border-radius: 10px;
        width: 85%;
        height: auto;

        // position: absolute;
        // top: 0;
        // width: 85%;
        // height: auto;
        // left: 0;
        // right: 0;
        // background-color: rgb(0 0 0 / 50%);
        .row {
          height: 100%;
          align-items: center;

          .video_footer {
            position: relative;
            background: #202020;
            border-radius: 10px;

            .bottom_footer {
              position: absolute;
              bottom: 0px;
              left: 0;
              width: 100%;
              text-align: left;

              h4 {
                color: #fff;
                font-size: calc(6px + 1vmin);
                font-weight: 500;
                margin: 0;

                span {
                  background-color: #000000;
                  padding: 4px 7px;
                  border-radius: 0px 6px 0px 10px;
                }
              }
            }
          }

          .VideoBroadcastLive {
            position: absolute;
            padding: 0px;
          }

        }
      }
    }

  }

  .FrontstageInfoStream {
    width: calc(100% - 392px);
    float: right;
    right: 0;
    left: inherit;
  }

}



.oraganizer-backstage-dashboard {
  padding: 0 0 !important;


  .MainViewBackStage {
    float: left;
    width: calc(100% - 440px);
    //display: inherit !important;
    height: calc(100vh - 50px) !important;
    position: relative;
    margin-left: 80px !important;
    // align-items: center;
    //justify-content: space-evenly !important;
    // flex-direction: column;
    justify-content: initial !important;

    //margin: 30px 0 0;
    .DivChangeWidthView {
      float: right;
      width: 100%;
      height: calc(100vh - 50px) !important;
      position: relative;
      margin: 0px !important;
      transition: all 0.5s ease;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;

      // display: flex;
      // align-items: center;
      // justify-content: center;
      // flex-direction: column;
      .ChangeWidthScroll {
        width: calc(100% - 0px);
        float: right;
        transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        padding: 0 45px;
        height: calc(100vh - 50px) !important;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .flexDicSeps {
          width: 70%;

          // aspect-ratio: 16/9;
          // display: flex;
          // align-items: center;
          // justify-content: center;
          // flex-direction: column;
          margin: 0px auto;
          background: #000;
          border-radius: 12px;

          .RemoveAllDiv {
            width: 100% !important;
            height: auto !important;
            display: flex !important;
            aspect-ratio: 16/9;
            margin: 0px auto;

            .img-fluid {
              position: absolute;
              left: 0;
              right: 0;
              height: 100% !important;
              width: 100% !important;
              margin: 0px auto;
            }

            .front-stage-stream-scroll {
              //position: absolute;
              width: 98%;
              height: auto;
              left: 0;
              right: 0;
              top: 0;
              margin: 0px auto;

              .row {
                .stage-user-list {
                  background-color: transparent !important;
                }
              }
            }

            img {
              width: 100%;
              height: 100%;
            }

            .ImptyStreamBg {
              display: none;
            }
          }

          .alignAllDiv {
            width: 100%;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: auto;

            //aspect-ratio: 16/9;
            .front-stage-stream-scroll {
              aspect-ratio: 16/9;
              margin: 0px auto 0;
              padding: 0px 2px;
              border-radius: 4px;
              overflow: initial !important;
              // position: absolute;
              // top: 0;
              width: 100%;

              // height: auto;
              // left: 0;
              // right: 0;
              // background-color: rgb(0 0 0 / 50%);
              .row {
                height: 100%;
                align-items: center;

                .video_footer {
                  position: relative;
                  background: #202020;
                  border-radius: 4px;
                  overflow: hidden;

                  .bottom_footer {
                    position: absolute;
                    width: 100%;
                    bottom: -120px;
                    left: 0%;
                    transition: all 0.6s ease;
                    -webkit-transition: all 0.6s ease;
                    -moz-transition: all 0.6s ease;
                    -o-transition: all 0.6s ease;
                    -o-transition: all 0.6s ease;

                    .NameExpFull {
                      width: 100%;
                      background: rgb(0 0 0 / 60%);
                      height: 48px;
                      border-radius: 0px 0px 4px 4px;
                      margin-top: 0;

                      h4 {
                        color: #ffffff;
                        font-size: 20px;
                        font-weight: 400;
                        margin: 0;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        padding: 0 5px;
                        height: 100%;

                        span {
                          width: 100%;
                        }
                      }
                    }

                  }

                  .lower_third {
                    left: 0px;
                    bottom: 0px !important;
                    transition: all 0.5s ease;
                    -webkit-transition: all 0.5s ease;
                    -moz-transition: all 0.5s ease;
                    -o-transition: all 0.5s ease;
                    -o-transition: all 0.5s ease;
                  }
                }

                .VideoBroadcastLive {
                  position: absolute;
                  padding: 0px;
                  width: auto;
                  height: 100%;
                  left: 0;
                  margin: 0px auto;
                  right: 0;
                  z-index: 91;
                  div {
                    width: 100% !important;
                    height: 100% !important;
                    aspect-ratio: 16/9;
                  }
                }

                .ImageBroadcastLive {
                  width: 100%;
                  height: 100%;

                  .RatioDiv16 {
                    aspect-ratio: 16/9;
                    height: 100%;
                    width: 100%;
                    margin: 0px auto;

                    img {
                      //object-fit: scale-down;
                      position: initial !important;
                      border-radius: 10px;
                    }
                  }
                }

                .PDFBroadcastLive {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  position: relative;

                  .RatioDiv16 {
                    //aspect-ratio: 16/9;
                    height: 99%;
                    margin: 0px auto;
                    width: auto;

                    img {
                      //object-fit: scale-down;
                      position: initial !important;
                      width: 100% !important;
                      height: 100% !important;
                      //border-radius: 10px;
                    }
                  }
                }

                .stage-user-list {
                  height: 100%;
                  padding: 0;
                  position: relative;
                  //z-index: 6;
                  background-color: #000000;
                }

                .front-stage-share-screen {
                  display: block !important;
                  height: 100%;
                  position: relative;
                  //z-index: 2;
                  background-color: #000;

                  .VideoBroadcastLive {
                    position: absolute;
                    padding: 0px;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    a

                    .alignBlockAuto {
                      display: inline-block;
                      width: auto !important;
                      aspect-ratio: auto !important;
                    }
                  }

                  .ImageBroadcastLive {
                    width: 100%;
                    height: 100%;

                    .RatioDiv16 {
                      aspect-ratio: 16/9;
                      height: 100%;
                      width: 100%;
                      margin: 0px auto;

                      img {
                        //object-fit: scale-down;
                        position: initial !important;
                        border-radius: 10px;
                      }
                    }

                  }

                  .PDFBroadcastLive {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;

                    .RatioDiv16 {
                      //aspect-ratio: 16/9;
                      height: 99%;
                      margin: 0px auto;
                      width: auto;

                      img {
                        //object-fit: scale-down;
                        position: initial !important;
                        width: 100% !important;
                        height: 100% !important;
                        //border-radius: 10px;
                      }
                    }
                  }

                  #pdfViewer {
                    width: 100% !important;
                    height: 100% !important;

                    iframe {
                      width: 100% !important;
                      height: 100% !important;
                    }
                  }
                }

                .front-stage-share-screen.empty-front-stage-share-screen {
                  display: none !important;
                }

                .front-stage-share-screen.screen-share-element {
                  height: 100% !important;
                }

                .stage-user-list {
                  .list-1 {
                    .video_footer {
                      .bottom_footer {
                        h4 {
                          font-size: 22px;
                        }
                      }
                    }
                  }

                  .list-5,
                  .list-6,
                  .list-7,
                  .list-8 {
                    .video_footer {
                      .bottom_footer {
                        h4 {
                          font-size: 18px;
                        }
                      }
                    }

                    .RightSvgIcon {
                      .mh-cursor-pointer {
                        padding: 6px;
                        top: 6px;
                        left: 6px;
                        width: 25px;
                        height: 25px;
                      }
                    }
                  }

                  .list-9,
                  .list-10,
                  .list-11,
                  .list-12,
                  .list-13 {
                    .video_footer {
                      .bottom_footer {
                        h4 {
                          font-size: 12px;
                        }
                      }
                    }

                    .RightSvgIcon {
                      .mh-cursor-pointer {
                        padding: 6px;
                        top: 6px;
                        left: 6px;
                        width: 25px;
                        height: 25px;
                      }
                    }
                  }

                  .list-14,
                  .list-15,
                  .list-16,
                  .list-17,
                  .list-18 {
                    .video_footer {
                      .bottom_footer {
                        h4 {
                          font-size: 12px;
                        }
                      }
                    }

                    .RightSvgIcon {
                      .mh-cursor-pointer {
                        padding: 6px;
                        top: 6px;
                        left: 6px;
                        width: 25px;
                        height: 25px;
                      }
                    }
                  }
                }

                .PollQnaBase {
                  position: absolute;
                  left: 0;
                  bottom: 2px;
                  width: 100%;
                  z-index: 99;
                  max-height: calc(100vh - 312px);
                  overflow-y: auto;
                  overflow-x: hidden;
                  scrollbar-width: thin;

                  .ScreenCast {
                    width: 100%;
                    margin: 0 0 15px;
                    background-color: rgba(0, 0, 0, 0.32);
                    padding: 10px 10px;
                    border-radius: 6px;

                    h4 {
                      background-color: #1a78e5;
                      color: #fff;
                      font-weight: 400;
                      font-size: 15px;
                      padding: 10px 14px;
                      border-radius: 4px;
                      text-align: left;
                      margin: 0 0 14px;
                    }

                    .OptionPoll {
                      width: 100%;

                      .row {
                        align-items: center !important;

                        .PollActionTab {
                          background-color: #f0f0f0;
                          display: inline-block;
                          text-align: left;
                          padding: 6px 18px;
                          font-size: 14px;
                          border-radius: 4px;
                          margin: 0 0 12px;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          display: -webkit-box !important;
                          -webkit-line-clamp: 1;
                          -webkit-box-orient: vertical;
                          width: 100%;
                        }

                        .option-1 {
                          flex: 0 0 auto;
                          width: 100%;
                        }

                        .option-2 {
                          flex: 0 0 auto;
                          width: 50%;
                        }

                        .option-3 {
                          flex: 0 0 auto;
                          width: 50%;
                        }

                        .option-4 {
                          flex: 0 0 auto;
                          width: 50%;
                        }

                        .option-5 {
                          flex: 0 0 auto;
                          width: 33.33333333%;
                        }

                        .option-6 {
                          flex: 0 0 auto;
                          width: 33.33333333%;
                        }

                        .option-7 {
                          flex: 0 0 auto;
                          width: 33.33333333%;
                        }

                        .option-8 {
                          flex: 0 0 auto;
                          width: 33.33333333%;
                        }
                      }
                    }

                    .row {
                      justify-content: left;
                      align-items: end !important;

                      .QuestionVote {
                        padding: 0px 0px !important;
                        background-color: #fff;
                        border-radius: 6px;
                        box-shadow: 0px 0px 6px rgb(0 0 0 / 5%);
                        position: relative;
                        width: 100%;
                        margin: 0px;

                        //height: 100px;
                        .UpdateVoteStream {
                          position: relative;
                          width: 100%;
                          height: 100%;

                          .QnAuserImg {
                            width: 65px;
                            height: 65px;
                            background-color: #1a78e5;
                            border-radius: 8px;
                            margin-right: 6px;
                            position: absolute;
                            left: 15px;
                            top: 15px;
                            border: 1px #fff solid;

                            .img-fluid {
                              border-radius: 8px;
                              position: initial !important;
                            }
                          }

                          .ShowAnAQue {
                            width: calc(100% - 0px);
                            display: flex;
                            align-items: center;
                            background: #1a78e5;
                            height: 52px;
                            padding-left: 100px;
                            justify-content: space-between;
                            border-radius: 6px 6px 0px 0px;

                            .NameQnAuser {
                              padding: 5px 0;
                              display: flex;
                              justify-content: center;
                              flex-direction: column;
                              text-align: left;
                              width: 80%;

                              h4 {
                                font-size: 14px;
                                font-weight: 500;
                                margin: 0;
                                background-color: transparent;
                                padding: 0;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;
                                width: 100%;
                                color: #fff;
                                ;
                              }
                            }

                            .QnaVoteCount {
                              padding: 0px 0px;
                              border-radius: 0;
                              display: flex;
                              justify-content: center;
                              margin-right: 22px;

                              svg {
                                fill: #ffffff;
                              }

                              span {
                                vertical-align: middle;
                                margin-left: 8px;
                                color: #fff;
                                box-shadow: 0px 0px 6px rgb(0 0 0 / 7%);
                                font-size: 15px;
                                display: inline-flex;
                                align-items: center;
                                justify-content: center;
                              }
                            }
                          }
                        }

                        .ShowQueHere {
                          padding-left: 100px;
                          min-height: 58px;
                          display: flex;
                          align-items: center;

                          p {
                            font-size: 14px;
                            line-height: 20px;
                            color: var(--dark-gray);
                            letter-spacing: 0.2px;
                            margin: 0;
                            text-align: left;
                            overflow: hidden;
                            /* text-overflow: ellipsis; */
                            /* display: -webkit-box; */
                            /* -webkit-line-clamp: 1; */
                            /* -webkit-box-orient: vertical; */
                            width: 90%;
                            padding: 6px 0;
                          }
                        }

                        &:last-child {
                          margin-bottom: 0px;
                        }
                      }
                    }

                  }
                }



              }

            }
          }

          .HeadingBstage {
            display: flex;
            // border-radius: 10px 10px 0 0;
            // position: absolute;
            align-items: center;
            justify-content: space-between;
            // top: 0px;
            width: 100%;
            padding: 10px 20px;

            .ActionBcFcHeader {
              display: inline-flex;
              transition: all 0.5s ease;
              -webkit-transition: all 0.5s ease;
              -moz-transition: all 0.5s ease;
              -o-transition: all 0.5s ease;
              -o-transition: all 0.5s ease;

              .BsMidAction {
                top: 6px;
                display: flex;
                align-items: center;
                justify-content: center;
                left: 0;
                background-color: #333;
                //margin: 0 auto;
                right: 0;
                height: 36px;
                border-radius: 100px;
                box-shadow: 0px 4px 10px #00000036;
                display: inline-flex;
                padding: 0 10px;
                float: left;

                .BsMic {
                  margin-right: 0px !important;
                }

                .BsEnd {
                  margin: 0 8px;
                  margin-right: -8px;

                  .RedCommonBtn {
                    margin-top: 0 !important;
                    padding: 5px 28px;
                    height: auto;
                    border: 0;

                    &:hover {
                      border: 0;
                      outline: none;
                      box-shadow: none;
                    }

                    &:focus {
                      border: 0;
                      outline: none;
                      box-shadow: none;
                    }
                  }
                }

                .ActionLive {
                  margin: 0 6px;
                  width: 22px;
                  height: 22px;
                  position: relative;
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  svg {
                    width: 100%;
                    height: 100%;
                    fill: #fff;
                  }

                  .StartEndRecordBtn {
                    background: transparent;
                    border: 0;
                    padding: 2px 4px
                  }
                }

                .BGImgUpdate {
                  width: 15px;
                  height: 15px;
                }

                .BsSetting {
                  width: 18px !important;
                }

                .StartStop {
                  width: auto;
                  height: auto;
                  margin-right: 0;
                  position: relative;

                  .switch {
                    min-width: 54px !important;
                    min-height: 20px !important;
                    border-radius: 100px;
                    border: 0px;
                  }

                  .btn-danger {
                    .switch-group {
                      .switch-on {
                        background-color: #fff !important;
                        padding-right: 1.2rem !important;
                        padding: 3px 0 3px 4px;
                        line-height: 15px;
                      }

                      .btn {
                        font-size: 13px;
                        color: #1e1e1e;
                      }

                      .switch-handle {
                        height: 16px;
                        width: 16px;
                        border-width: 0 1px;
                        background-color: #d70b0b;
                        border-radius: 100px;
                        padding: 0;
                        right: 11px;
                        top: 2px;
                      }
                    }
                  }

                  .btn-secondary {
                    .switch-off {
                      background-color: #727070 !important;
                      padding: 2px 0 2px 16px;
                      font-size: 12px;
                      line-height: 15px;
                      color: #b5b5b5;
                    }

                    .switch-handle {
                      height: 16px;
                      width: 16px;
                      border-width: 0 1px;
                      background-color: #b3b3b3;
                      padding: 0;
                      left: 11px;
                      top: 2px;
                      border-radius: 100px;
                    }
                  }

                }

                .disabled-recording-btn {
                  &::after {
                    position: absolute;
                    content: "";
                    background-color: rgb(217 217 217 / 58%);
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    border-radius: 100px;
                  }
                }

              }

              .RigthtStreamBs {
                margin-left: 8px;

                .MuteBtnUnmute {
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;

                  .BsUnmuteIcon {
                    width: 16px;
                    height: 16px;
                    display: inline-flex;
                    vertical-align: middle;
                    margin-right: 8px;

                    svg {
                      width: 100%;
                      height: 100%;
                    }
                  }
                }
              }
            }

            .RigthtStreamBs {
              .StreamRight {
                .leaveStreamDark {
                  svg {
                    width: 16px;
                    height: 16px;
                  }
                }
              }
            }
          }
        }

        .mixhubbListLayout {
          .WrapmixhubbList {
            .ScrollmixhubbWrap {
              .ListContentAdd {
                .ListPreviewMix {
                  .MainViewBackStage {
                    .DivChangeWidthView {
                      .ChangeWidthScroll {
                        .flexDicSeps {
                          .alignAllDiv {
                            .front-stage-stream-scroll {
                              .row {
                                .stage-user-list {
                                  display: inline-flex;
                                  height: 100%;
                                  align-items: center;
                                  flex-wrap: wrap;
                                  padding: 0px !important;
                                  justify-content: center;
                                  width: 100% !important;
                                  background-color: transparent !important;

                                  .list-1 {
                                    flex: 0 0 auto;
                                    width: 100%;
                                    height: 100%;
                                    padding: 2px 2px;
                                  }

                                  .list-2 {
                                    flex: 0 0 auto;
                                    width: 50%;
                                    height: 50%;
                                    padding: 2px 2px 2px;
                                  }

                                  .list-3 {
                                    flex: 0 0 auto;
                                    width: 50%;
                                    height: 50%;
                                    padding: 2px 2px 2px;
                                  }

                                  .list-4 {
                                    flex: 0 0 auto;
                                    width: 50%;
                                    height: 50%;
                                    padding: 2px 2px 2px;
                                  }

                                  .list-5 {
                                    flex: 0 0 auto;
                                    width: 33.33333333%;
                                    height: 50%;
                                    padding: 2px 2px 2px;
                                  }

                                  .list-6 {
                                    flex: 0 0 auto;
                                    width: 33.33333333%;
                                    height: 50%;
                                    padding: 2px 2px 2px;
                                  }

                                  .list-7 {
                                    flex: 0 0 auto;
                                    width: 25%;
                                    height: 50%;
                                    padding: 2px 2px 2px;
                                  }

                                  .list-8 {
                                    flex: 0 0 auto;
                                    width: 25%;
                                    height: 50%;
                                    padding: 2px 2px 2px;
                                  }

                                  .list-9 {
                                    flex: 0 0 auto;
                                    width: 25%;
                                    height: 33.33333333%;
                                    padding: 2px 2px 2px;
                                  }

                                  .list-10 {
                                    flex: 0 0 auto;
                                    width: 25%;
                                    height: 33.33333333%;
                                    padding: 2px 2px 2px;
                                  }

                                  .list-11 {
                                    flex: 0 0 auto;
                                    width: 25%;
                                    height: 33.33333333%;
                                    padding: 2px 2px 2px;
                                  }

                                  .list-12 {
                                    flex: 0 0 auto;
                                    width: 25%;
                                    height: 33.33333333%;
                                    padding: 2px 2px 2px;
                                  }

                                  .list-13 {
                                    flex: 0 0 auto;
                                    width: 25%;
                                    height: 25%;
                                    padding: 2px 2px 2px;
                                  }

                                  .list-14 {
                                    flex: 0 0 auto;
                                    width: 25%;
                                    height: 25%;
                                    padding: 2px 2px 2px;
                                  }

                                  .list-15 {
                                    flex: 0 0 auto;
                                    width: 25%;
                                    height: 25%;
                                    padding: 2px 2px 2px;
                                  }

                                  .list-16 {
                                    flex: 0 0 auto;
                                    width: 25%;
                                    height: 25%;
                                    padding: 2px 2px 2px;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .ChangeWidthView {
        width: calc(100% - 340px);
        padding: 0 0 !important;
        float: right;
        transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;

        .flexDicSeps {
          width: 95% !important;
        }
      }
    }

  }

  .BottomStatusFoot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 8px 20px;
    min-height: 51px;

    .LeftStatuSide {
      .TypeAction {
        display: inline-block;
        text-align: center;
        font-size: 12px;
        color: #fff;
        font-weight: 400;
        margin-right: 10px;

        .OnoffSwitch {
          display: inline-block;

          .MuiFormControlLabel-root {
            margin: 0px !important;
          }

          .MuiSwitch-root {
            padding: 2px 6px !important;
            width: 48px;
            height: 20px;

            .MuiButtonBase-root {
              padding: 4px 8px !important;

              .MuiSwitch-thumb {
                width: 12px !important;
                height: 12px !important;
              }
            }

            .Mui-checked {
              padding: 4px 8px !important;
            }

            .MuiSwitch-track {
              opacity: 1 !important;
              background-color: #585757;
            }

          }
        }
      }
    }

    .RighttStatuSide {
      .leaveStream {
        margin-left: 8px;
        font-size: 14px;
        padding: 8px 30px 8px 14px !important;
        float: right;
        font-weight: 400;
      }

      .SendStream {
        margin-left: 8px;
        font-size: 12px;
        padding: 6px 12px !important;
        float: right;
        font-weight: 400;
      }
    }
  }



  .ChangeWidthView {

    // display: initial !important;
    // transition: all 0.5s ease;
    // -webkit-transition: all 0.5s ease;
    // -moz-transition: all 0.5s ease;
    // -o-transition: all 0.5s ease;
    // -o-transition: all 0.5s ease;
    .DivChangeWidthView {
      float: right;
      width: calc(100% - 348px);
      position: relative;
      transition: all 0.5s ease;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      margin: 0px !important;
      display: flex !important;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 0 auto !important;
      padding: 0 70px;
      height: calc(100vh - 50px);
    }
  }

  .broadcast-not-started {

    //height: calc(100vh - 220px) !important;
    .alignAllDiv {
      .front-stage-stream-scroll {
        width: 100%;
      }
    }
  }

  .FrontstageInfoStream {
    padding-left: 185px;
  }
}

.BackstageRtStream {
  .GroupStreamMix {
    .ChangeWidthScroll {
      .flexDicSeps {
        .alignAllDiv {
          .front-stage-stream-scroll {
            .row {
              .video-js {
                background-color: transparent !important;
                width: 100% !important;
                height: 0px !important;
                padding-top: 56.25% !important;
              }
            }
          }
        }
      }
    }
  }
}


.audience-front-stage-roll {
  .container-fluid {
    .MainViewBackStage {
      .alignAllDiv {
        .front-stage-stream-scroll {
          .row {
            .video-js {
              background-color: initial!important;
              position: relative;
              z-index: 91;
            }

            .stage-user-list {
              .list-1 {
                .video_footer {
                  .bottom_footer {
                    .NameExpFull {
                      width: 100%;
                      height: 48px;

                      h4 {
                        font-size: 20px;
                      }
                    }
                  }
                }
              }

              .list-2,
              .list-3,
              .list-4 {
                .video_footer {
                  .bottom_footer {
                    .NameExpFull {
                      width: 100%;
                      height: 48px;

                      h4 {
                        font-size: 20px;
                      }
                    }
                  }
                }
              }

              .list-5,
              .list-6 {
                .video_footer {
                  .bottom_footer {
                    .NameExpFull {
                      height: 42px;

                      h4 {
                        font-size: 20px;
                      }
                    }
                  }
                }
              }

              .list-7,
              .list-8 {
                .video_footer {
                  .bottom_footer {
                    .NameExpFull {
                      height: 42px;

                      h4 {
                        font-size: 18px;
                      }
                    }
                  }
                }
              }

              .list-9,
              .list-10,
              .list-11,
              .list-12 {
                .video_footer {
                  .bottom_footer {
                    .NameExpFull {
                      height: 42px;

                      h4 {
                        font-size: 18px;
                      }
                    }
                  }
                }
              }
            }

            .PollQnaBase {
              max-height: calc(100vh - 190px) !important;
            }
          }
        }
      }
    }

    .SideRightMenu {
      height: calc(100% - 312px);
      transition: all 0.5s ease;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;

      ul {
        li {
          padding: 8px 10px !important;
          margin: 10px 0 !important;

          span {
            svg {
              width: 28px;
              height: 28px;
            }
          }

          .RightTopStyle {
            ul {
              li {
                margin: 0px !important;
                padding: 0px !important;
              }
            }
          }
        }
      }
    }
  }
}


.oraganizer-backstage-dashboard {
  .container-fluid {
    .MainViewBackStage {
      .DivChangeWidthView {
        .ChangeWidthScroll {
          .flexDicSeps {
            .alignAllDiv {
              .front-stage-stream-scroll {
                .row {
                  .stage-user-list {

                    .list-2,
                    .list-3,
                    .list-4 {
                      .video_footer {
                        .bottom_footer {
                          .NameExpFull {
                            width: 100%;
                            height: 45px;

                            h4 {
                              //padding: 0px 12px 0px 35px;
                            }
                          }
                        }
                      }
                    }

                    .list-5,
                    .list-6 {
                      .video_footer {
                        .bottom_footer {
                          .NameExpFull {
                            width: 100%;
                            height: 44px;

                            h4 {
                              //padding: 0px 8px 0px 30px;
                            }
                          }
                        }
                      }
                    }

                    .list-7,
                    .list-8 {
                      .video_footer {
                        .bottom_footer {
                          .NameExpFull {
                            width: 100%;
                            height: 38px;

                            h4 {
                              //padding: 0px 8px 0px 30px;
                              font-size: 15px;
                            }
                          }
                        }

                        .lower_third {
                          left: 0px;
                        }
                      }
                    }

                    .list-9,
                    .list-10,
                    .list-11,
                    .list-12 {
                      .video_footer {
                        .bottom_footer {
                          .NameExpFull {
                            width: 100%;
                            height: 34px;

                            h4 {
                              //padding: 0px 8px 0px 30px;
                              font-size: 15px;
                            }
                          }
                        }

                        .lower_third {
                          left: 0px;
                        }
                      }
                    }

                    .list-13,
                    .list-14,
                    .list-15,
                    .list-16 {
                      .video_footer {
                        .bottom_footer {
                          .NameExpFull {
                            width: 100%;
                            height: 34px;

                            h4 {
                              font-size: 12px;
                            }
                          }
                        }

                        .lower_third {
                          left: 0px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

}

.backstage-dashboard {
  padding: 0 0 !important;

  .SideRightMenu {
    height: calc(100% - 312px);
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;

    ul {
      li {
        padding: 8px 10px !important;
        margin: 10px 0 !important;

        span {
          svg {
            width: 28px;
            height: 28px;
          }
        }
      }
    }
  }

  .MainViewBackStage {
    width: calc(100% - 380px);
    display: inherit !important;
    height: calc(100vh - 280px) !important;
    position: relative;
    transition: all 0.5s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;

    .FlexResizeStep {
      float: left;
      width: 100%;
      //height: calc(100vh - 132px) !important;
      height: 100% !important;
      position: relative;
      margin: 0px !important;
      transition: all 0.5s ease;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;

      .ChangeWithSize {
        width: calc(100% - 0px);
        float: left;
        transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        padding: 0 0px;
        //height: calc(100vh - 58px) !important;
        height: 100% !important;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .fullFreeContainer {
          width: auto;
          margin: 0px auto;
          //background: #f00;
          border-radius: 4px;
          height: 100% !important;
          padding: 4px;

          //aspect-ratio: 16/9;
          .RemoveAllDiv {
            width: auto !important;
            height: 100% !important;
          }
        }
      }
    }

    .front-stage-stream-scroll {
      aspect-ratio: 16/9;
      margin: 0px auto 0;
      padding: 0px 4px;

      //border-radius: 10px;
      // position: absolute;
      // top: 0;
      // width: 85%;
      // height: auto;
      // left: 0;
      // right: 0;
      // background-color: rgb(0 0 0 / 50%);
      .row {
        margin: 0px auto;
        justify-content: center;
        height: 100%;
        align-items: center;

        .video_footer {
          position: relative;
          background: #202020;
          border-radius: 4px;
          overflow: hidden;

          .bottom_footer {
            position: absolute;
            width: 100%;
            bottom: -120px;
            left: 0%;
            transition: all 0.6s ease;
            -webkit-transition: all 0.6s ease;
            -moz-transition: all 0.6s ease;
            -o-transition: all 0.6s ease;
            -o-transition: all 0.6s ease;

            .NameExpFull {
              width: 100%;
              background: rgb(0 0 0 / 60%);
              height: 48px;
              border-radius: 0px 0px 4px 4px;
              margin-top: 0;

              h4 {
                color: #ffffff;
                font-size: 20px;
                font-weight: 400;
                margin: 0;
                display: flex;
                align-items: center;
                text-align: center;
                padding: 0 5px;
                height: 100%;

                span {
                  width: 100%;
                }
              }
            }

          }

          .lower_third {
            left: 0px;
            bottom: 0px !important;
            transition: all 0.5s ease;
            -webkit-transition: all 0.5s ease;
            -moz-transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
          }
        }

        .VideoBroadcastLive {
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          padding: 0;
        }

        .ImageBroadcastLive {
          width: 100%;
          height: 100%;

          .RatioDiv16 {
            aspect-ratio: 16/9;
            height: 100%;
            width: 100%;
            margin: 0px auto;

            img {
              //object-fit: scale-down;
              position: initial !important;
              border-radius: 10px;
            }
          }
        }

        .PDFBroadcastLive {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          .RatioDiv16 {
            //aspect-ratio: 16/9;
            height: 99%;
            margin: 0px auto;
            width: auto;

            img {
              //object-fit: scale-down;
              position: initial !important;
              //border-radius: 10px;
              width: 100% !important;
              height: 100% !important;
            }
          }
        }

        .front-stage-share-screen {
          display: block !important;
          height: 100%;

          .VideoBroadcastLive {
            position: absolute;
            padding: 0px;
            width: 100%;
            height: 100%;
            top: 0;

            .alignBlockAuto {
              display: inline-block;
              width: auto !important;
              aspect-ratio: auto !important;
            }

            div {
              width: 100% !important;
              height: 100% !important;
              aspect-ratio: 16/9;
            }

          }

          .ImageBroadcastLive {
            width: 100%;
            height: 100%;

            .RatioDiv16 {
              aspect-ratio: 16/9;
              height: 100%;
              width: 100%;
              margin: 0px auto;

              img {
                //object-fit: scale-down;
                position: initial !important;
                border-radius: 10px;
              }
            }
          }

          .PDFBroadcastLive {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            .RatioDiv16 {
              //aspect-ratio: 16/9;
              height: 99%;
              margin: 0px auto;
              width: auto;

              img {
                object-fit: scale-down;
                position: initial !important;
                //border-radius: 10px;
                width: 100% !important;
                height: 100% !important;
              }
            }
          }

          #pdfViewer {
            width: 100% !important;
            height: 100% !important;

            iframe {
              width: 100% !important;
              height: 100% !important;
            }
          }

          .pinUserInfo {
            .video_footer {
              .userLoginBg {
                div {
                  video {
                    object-fit: cover !important;
                  }
                }
              }

              .RemoveSpeaker {
                .pinRemoveAction {
                  span {
                    background-color: #ffffff;
                    width: 34px;
                    height: 34px;

                    svg {
                      width: 40px;
                      height: 40px;
                      left: -3px;
                      top: -1px;
                    }
                  }
                }
              }
            }
          }
        }

        .front-stage-share-screen.empty-front-stage-share-screen {
          display: none !important;
        }

        .front-stage-share-screen.screen-share-element {
          height: auto !important;
        }

        .stage-user-list {

          .list-5,
          .list-6,
          .list-7,
          .list-8,
          .list-9,
          .list-10,
          .list-11,
          .list-12,
          .list-13,
          .list-14,
          .list-15,
          .list-16,
          .list-17,
          .list-18 {
            .video_footer {
              .bottom_footer {
                h4 {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }

  .StageFootShrink {
    width: calc(100% - 322px) !important;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
  }

  .broadcast-not-started {
    height: calc(100vh - 175px) !important;

    .alignAllDiv {
      .front-stage-stream-scroll {
        .row {
          .front-stage-share-screen {
            .VideoBroadcastLive {
              .alignBlockAuto {
                display: inline-block;
                width: auto !important;
                aspect-ratio: auto !important;

              }

              div {
                width: 100% !important;
                height: 100% !important;
                aspect-ratio: 16/9;
              }
            }

            .ImageBroadcastLive {
              width: 100%;
              height: 100%;

              .RatioDiv16 {
                aspect-ratio: 16/9;
                height: 100%;
                width: 100%;
                margin: 0px auto;

                img {
                  //object-fit: scale-down;
                  position: initial !important;
                  border-radius: 10px;
                }
              }
            }

            .PDFBroadcastLive {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;

              .RatioDiv16 {
                //aspect-ratio: 16/9;
                height: 99%;
                margin: 0px auto;
                width: auto;

                img {
                  //object-fit: scale-down;
                  position: initial !important;
                  //border-radius: 10px;
                  width: 100% !important;
                  height: 100% !important;
                }
              }
            }

            .pinUserInfo {
              .video_footer {
                .userLoginBg {
                  div {
                    video {
                      object-fit: cover !important;
                    }
                  }
                }

                .RemoveSpeaker {
                  .pinRemoveAction {
                    span {
                      background-color: #ffffff;
                      width: 34px;
                      height: 34px;

                      svg {
                        width: 40px;
                        height: 40px;
                        left: -3px;
                        top: -1px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .FrontstageInfoStream {
    padding-left: 185px;
  }

  .container-fluid {
    .BackstageInfoStream {
      .HeadingBstage {
        .BsMidAction {
          .ActionLive {
            .SettingPopHover {
              bottom: 40px;
              top: auto !important;
              left: -48px;

              &::before {
                top: auto;
                border-bottom: 1px solid #dcd8d8;
                border-right: 1px solid #dcd8d8;
                border-top: 1px solid transparent;
                border-left: 1px solid transparent;
                bottom: -8px;
              }

            }
          }
        }
      }
    }
  }

  .fullFreeContainer {
    aspect-ratio: 16/9;
  }

  .FlexResizeStep {
    // .ChangeWithSize {
    //   .fullFreeContainer {

    //      .RemoveAllDiv {
    //        width: auto !important;
    //        height: 100% !important;
    //      }
    //   }
    // }
    .SpeakerSizeShrink {
      width: calc(100% - 345px) !important;

      .fullFreeContainer {
        // width: 100% !important;
        height: 100% !important;
      }
    }
  }
}

.timer-countDown {
  background: #eaeaea;
  margin: 0 0 10px;
  padding: 6px 0;

  .timerBodyCondown {
    display: flex;
    align-items: center;
    width: 72%;
    margin: 0px auto;
    justify-content: space-between;

    div {
      background-color: rgb(0 0 0 / 21%);
      border-radius: 10px;

      .time-wrapper {
        background-color: transparent !important;

        .time {
          color: var(--blue);
          font-weight: 500;
          font-size: 30px;
          line-height: initial;
          background: transparent;
          width: auto !important;
          height: auto !important;
        }

        div {
          display: block;
          color: var(--dark-gray);
          font-size: 10px;
          font-weight: 500;
          background: transparent;
          width: auto !important;
          height: auto !important;
        }
      }

    }

    span {
      color: rgb(0 0 0 / 21%);
      font-size: 70px;
      line-height: 0px;
      //font-family: fantasy;
    }

    .event-live {
      background-color: transparent !important;
      width: 100% !important;
      height: auto !important;

      h3 {
        font-size: 25px;
        font-weight: 500;
        margin: 0;
      }
    }
  }
}

.TimeSpendIcon {
  text-align: center;
  margin: 45px 0;

  svg {
    width: 50px;
    height: 50px;
  }
}

.SlighlyEarly {
  h4 {
    margin: 0;
    font-size: 20px;
    letter-spacing: .5px;
  }
}

.EventCalSchedules {
  margin: 38px 0 0;

  h5 {
    color: var(--blue);
    font-weight: 500;
    font-size: 19px;
    position: relative;
    width: 70%;
    overflow: hidden;
    margin: 0 auto;

    span {
      background-color: #fff;
      position: relative;
      z-index: 4;
      padding: 0 14px;
    }

    &::after {
      content: "";
      position: absolute;
      background-color: #c7c7c7;
      width: 100%;
      height: 1px;
      left: 0;
      top: 11px
    }
  }

  .EventCalenderIcon {
    margin: 36px 0 0;

    img {
      width: 60px;
      height: 60px;
      margin: 0 10px;
    }
  }
}




.audience-front-stage-roll {
  padding: 0 0 !important;

  .MainViewBackStage {
    width: 100% !important;
    /* display: inherit !important; */
    position: relative;
    margin: 0px 0 0px;
    height: calc(100vh - 305px) !important;
    position: relative;
    transition: all 0.5s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;

    .FlexResizeStep {
      float: left;
      width: 100%;
      //height: calc(100vh - 132px) !important;
      height: 100% !important;
      position: relative;
      margin: 0px !important;
      transition: all 0.5s ease;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;

      .ChangeWithSize {
        width: calc(100% - 0px);
        float: left;
        transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        padding: 0 0px;
        //height: calc(100vh - 58px) !important;
        height: 100% !important;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .fullFreeContainer {
          width: auto;
          margin: 0px auto;
          //background: #000;
          border-radius: 4px;
          height: 100% !important;
          //padding: 4px;
          aspect-ratio: 16/9;

          .RemoveAllDiv {
            width: auto !important;
            height: 100% !important;
          }
        }
      }

      .SpeakerSizeShrink {
        width: calc(100% - 345px);
        transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;

        .fullFreeContainer {
          //width: 100% !important;
          height: 100% !important;
        }
      }
    }

    .RemoveAllDiv {
      width: auto !important;
      height: 100% !important;
      display: flex !important;
      aspect-ratio: 16/9;

      .front-stage-stream-scroll {
        //width: 95%;
        width: 100%;
        height: auto;
        left: 0;
        right: 0;
        top: 0;
        margin: 0px auto;
      }

      .go-backstage-btn {
        position: absolute;
        max-width: 300px;
        left: 0;
        right: 0;
        margin: 0px auto;
        bottom: 90px;
        z-index: 6;
      }

      img {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0px auto;

      }
    }

    .alignAllDiv {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 100%;
        width: auto;
        margin: 0 auto;
      }

      .front-stage-stream-scroll {
        aspect-ratio: 16/9;
        margin: 0px auto 0;
        padding: 0px 0px;
        border-radius: 4px;

        // background-color: rgb(0 0 0 / 50%);
        .row {
          height: 100%;
          align-items: center;

          mux-player {
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 1;
            padding: 0;
          }

          .front-stage-stream-scroll {
            display: block !important;

            .VideoBroadcastLive {
              position: absolute;
              padding: 0px;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
            }

            .ImageBroadcastLive {
              width: 100%;
              height: 100%;

              .RatioDiv16 {
                aspect-ratio: 16/9;
                height: 100%;
                width: 100%;
                margin: 0px auto;

                img {
                  //object-fit: scale-down;
                  position: initial !important;
                  border-radius: 10px;
                }
              }
            }

            .PDFBroadcastLive {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;

              .RatioDiv16 {
                //aspect-ratio: 16/9;
                height: 99%;
                margin: 0px auto;
                width: auto;

                img {
                  //object-fit: scale-down;
                  position: initial !important;
                  //border-radius: 10px;
                  width: 100% !important;
                  height: 100% !important;
                }
              }
            }
          }

          .video_footer {
            position: relative;
            background: #202020;
            border-radius: 6px;
            overflow: hidden;

            .bottom_footer {
              position: absolute;
              bottom: -120px;
              width: 100%;
              left: 0px;
              transition: all 0.6s ease;
              -webkit-transition: all 0.6s ease;
              -moz-transition: all 0.6s ease;
              -o-transition: all 0.6s ease;
              -o-transition: all 0.6s ease;

              .NameExpFull {
                width: 100%;
                background: rgba(0, 0, 0, 0.6);
                height: 48px;
                border-radius: 0px 0px 4px 4px;
                margin-top: 0;

                h4 {
                  color: #ffffff;
                  font-size: 20px;
                  font-weight: 400;
                  margin: 0;
                  display: flex;
                  align-items: center;
                  text-align: center;
                  padding: 0 5px;
                  height: 100%;

                  span {
                    width: 100%;
                  }
                }
              }
            }

            .lower_third {
              left: 0px;
              bottom: 0px;
              transition: all 0.5s ease;
              -webkit-transition: all 0.5s ease;
              -moz-transition: all 0.5s ease;
              -o-transition: all 0.5s ease;
              -o-transition: all 0.5s ease;
            }
          }

          .front-stage-share-screen {
            display: block !important;
            height: 100%;

            .VideoBroadcastLive {
              position: absolute;
              padding: 0px;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;

              div {
                width: 100% !important;
                height: 100% !important;
                aspect-ratio: 16/9;
              }

              .alignBlockAuto {
                display: inline-block;
                width: auto !important;
                aspect-ratio: auto !important;

                img {
                  width: auto !important;
                }
              }
            }

            .ImageBroadcastLive {
              width: 100%;
              height: 100%;

              .RatioDiv16 {
                aspect-ratio: 16/9;
                height: 100%;
                width: 100%;
                margin: 0px auto;

                img {
                  //object-fit: scale-down;
                  position: initial !important;
                  border-radius: 10px;
                }
              }
            }

            .PDFBroadcastLive {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;

              .RatioDiv16 {
                //aspect-ratio: 16/9;
                height: 99%;
                margin: 0px auto;
                width: auto;

                img {
                  //object-fit: scale-down;
                  position: initial !important;
                  //border-radius: 10px;
                  width: 100% !important;
                  height: 100% !important;
                }
              }
            }

            #pdfViewer {
              width: 100% !important;
              height: 100% !important;

              iframe {
                width: 100% !important;
                height: 100% !important;
              }
            }

            .pinUserInfo {
              .video_footer {
                .userLoginBg {
                  div {
                    video {
                      object-fit: cover !important;
                    }
                  }
                }

                .RemoveSpeaker {
                  .pinRemoveAction {
                    span {
                      background-color: #ffffff;
                      width: 34px;
                      height: 34px;

                      svg {
                        width: 40px;
                        height: 40px;
                        left: -3px;
                        top: -1px;
                      }
                    }
                  }
                }
              }
            }
          }

          .front-stage-share-screen.empty-front-stage-share-screen {
            display: none !important;
          }

          .front-stage-share-screen.screen-share-element {
            height: auto !important;
          }

          .PollQnaBase {
            position: absolute;
            left: 0;
            bottom: 2px;
            width: 100%;
            z-index: 99;
            max-height: calc(100vh - 200px);
            overflow-y: auto;
            overflow-x: hidden;
            scrollbar-width: thin;

            .ScreenCast {
              width: 100%;
              margin: 0 0 15px;
              background-color: rgba(0, 0, 0, 0.32);
              padding: 12px 0px;
              border-radius: 6px;

              h4 {
                background-color: #1a78e5;
                color: #fff;
                font-weight: 400;
                font-size: 15px;
                padding: 10px 14px;
                border-radius: 4px;
                text-align: left;
              }

              .OptionPoll {
                width: 100%;

                .row {
                  align-items: center !important;

                  //justify-content: center;
                  .PollActionTab {
                    background-color: #f0f0f0;
                    display: inline-block;
                    text-align: left;
                    padding: 6px 18px;
                    font-size: 14px;
                    border-radius: 4px;
                    margin: 0 0 12px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box !important;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    width: 100%;
                  }

                  .option-1 {
                    flex: 0 0 auto;
                    width: 100%;
                  }

                  .option-2 {
                    flex: 0 0 auto;
                    width: 50%;
                  }

                  .option-3 {
                    flex: 0 0 auto;
                    width: 50%;
                  }

                  .option-4 {
                    flex: 0 0 auto;
                    width: 50%;
                  }

                  .option-5 {
                    flex: 0 0 auto;
                    width: 33.33333333%;
                  }

                  .option-6 {
                    flex: 0 0 auto;
                    width: 33.33333333%;
                  }

                  .option-7 {
                    flex: 0 0 auto;
                    width: 33.33333333%;
                  }

                  .option-8 {
                    flex: 0 0 auto;
                    width: 33.33333333%;
                  }
                }
              }

              .row {
                justify-content: left;
                align-items: end !important;

                .QuestionVote {
                  padding: 0px 0px !important;
                  background-color: #fff;
                  border-radius: 6px;
                  box-shadow: 0px 0px 6px rgb(0 0 0 / 5%);
                  position: relative;
                  width: 100%;
                  margin: 0 0 22px;
                  height: 100px;

                  .UpdateVoteStream {
                    position: relative;
                    width: 100%;
                    height: 100%;

                    .QnAuserImg {
                      width: 65px;
                      height: 65px;
                      background-color: #1a78e5;
                      border-radius: 8px;
                      margin-right: 6px;
                      position: absolute;
                      left: 15px;
                      top: 15px;
                      border: 1px #fff solid;

                      .img-fluid {
                        border-radius: 8px;
                        position: initial !important;
                      }
                    }

                    .ShowAnAQue {
                      width: calc(100% - 0px);
                      display: flex;
                      align-items: center;
                      background: #1a78e5;
                      height: 50%;
                      padding-left: 100px;
                      justify-content: space-between;
                      border-radius: 6px 6px 0px 0px;

                      .NameQnAuser {
                        padding: 5px 0;
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        text-align: left;
                        width: 80%;

                        h4 {
                          font-size: 14px;
                          font-weight: 500;
                          margin: 0;
                          background-color: transparent;
                          padding: 0;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          display: -webkit-box;
                          -webkit-line-clamp: 1;
                          -webkit-box-orient: vertical;
                          width: 100%;
                          color: #fff;
                          ;
                        }
                      }

                      .QnaVoteCount {
                        padding: 0px 0px;
                        border-radius: 0;
                        display: flex;
                        justify-content: center;
                        margin-right: 22px;

                        svg {
                          fill: #ffffff;
                        }

                        span {
                          vertical-align: middle;
                          margin-left: 8px;
                          color: #fff;
                          box-shadow: 0px 0px 6px rgb(0 0 0 / 7%);
                          font-size: 15px;
                          display: inline-flex;
                          align-items: center;
                          justify-content: center;
                        }
                      }
                    }
                  }

                  .ShowQueHere {
                    padding-left: 100px;
                    height: 50%;
                    display: flex;
                    align-items: center;

                    p {
                      font-size: 14px;
                      line-height: 20px;
                      color: var(--dark-gray);
                      letter-spacing: 0.2px;
                      margin: 0;
                      text-align: left;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      -webkit-line-clamp: 1;
                      -webkit-box-orient: vertical;
                      width: 90%;
                    }
                  }
                }
              }

            }
          }
        }

        .auttendee-bs-heading {
          position: absolute;
          top: -48px;
          background-color: #c51212;
          color: #fff;
          font-size: 20px;
          font-weight: 500;
          padding: 5px 0;
          border-radius: 10px;
          border: 2px #d54242 solid;
        }
      }
    }

    .DivChangeWidthView {
      width: auto;
      height: 100%;
      aspect-ratio: 16/9;

      .ChangeWidthScroll {
        width: 100% !important;
        height: 100% !important;

        .flexDicSeps {
          width: 100% !important;
          height: 100% !important;

          .alignAllDiv {
            width: 100%;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            .front-stage-share-screen {
              width: 95% !important;
              position: relative;
              aspect-ratio: 16/9;

              .VideoBroadcastLive {
                width: 100% !important;
                height: 100% !important;

                div {
                  width: 100% !important;
                  height: 100% !important;

                  iframe {
                    width: 100% !important;
                    height: 100% !important;
                  }
                }
                video {
                  position: relative;
                  z-index: 9;
                }
              }

              #pdfViewer {
                width: 100% !important;
                height: 100% !important;

                iframe {
                  width: 100% !important;
                  height: 100% !important;
                }
              }
            }

            .front-stage-stream-scroll {
              aspect-ratio: 16/9;
              margin: 0px auto 0;
              padding: 0px 2px;
              border-radius: 4px;
              overflow: initial !important;
              width: 100%;

              .row {
                height: 100%;
                align-items: center;
              }
            }
          }

          .RemoveAllDiv {
            width: 100% !important;
            height: auto !important;
            display: flex !important;
            aspect-ratio: 16/9;
            margin: 0px auto;

            .img-fluid {
              position: absolute;
              left: 0;
              right: 0;
              height: 100% !important;
              width: 100% !important;
              margin: 0px auto;
            }

            .front-stage-stream-scroll {
              width: 100%;
              height: auto;
              left: 0;
              right: 0;
              top: 0;
              margin: 0px auto;
            }
          }
        }
      }

    }

  }

  .go-backstage-btn {
    bottom: 196px !important;
    transition: all 0.5s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
  }

  .BackstageInfoStream {
    .HeadingBstage {
      height: 57px;
    }
  }

  .StageFootShrink {
    width: calc(100% - 345px) !important;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
  }

  .row {
    .attendeeMid {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
    }
  }
}

.audience-front-stage-roll.collapse-out-frontstage {
  .MainViewBackStage {
    height: calc(100vh - 112px) !important;
    position: relative;
    transition: all 0.5s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;

    .alignAllDiv {
      .front-stage-stream-scroll {
        padding: 0 0;

        .row {
          .stage-user-list {
            .list-1 {
              .video_footer {
                .bottom_footer {
                  .NameExpFull {
                    height: 46px;

                    h4 {
                      font-size: 24px;
                    }
                  }
                }
              }
            }

            .list-2,
            .list-3,
            .list-4 {
              .video_footer {
                .bottom_footer {
                  .NameExpFull {
                    height: 40px;

                    h4 {
                      font-size: 20px;
                    }
                  }
                }
              }
            }

            .list-5,
            .list-6 {
              .video_footer {
                .bottom_footer {
                  .NameExpFull {
                    height: 38px;

                    h4 {
                      font-size: 18px;
                    }
                  }
                }
              }
            }

            .list-7,
            .list-8 {
              .video_footer {
                .bottom_footer {
                  .NameExpFull {
                    height: 36px;

                    h4 {
                      font-size: 16px;
                    }
                  }
                }
              }
            }

            .list-9,
            .list-10,
            .list-11,
            .list-12 {
              .video_footer {
                .bottom_footer {
                  .NameExpFull {
                    height: 36px;

                    h4 {
                      font-size: 16px;
                    }
                  }
                }
              }
            }

            .list-13,
            .list-14,
            .list-15,
            .list-16 {
              .video_footer {
                .bottom_footer {
                  .NameExpFull {
                    width: 100%;
                    height: 36px;

                    h4 {
                      font-size: 12px;
                    }
                  }
                }
              }
            }
          }
        }
      }

    }
  }

  .go-backstage-btn {
    bottom: 10px !important;
    transition: all 0.5s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
  }

  .SideRightMenu {
    height: calc(100% - 120px);
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;

    ul {
      li {
        margin: 14px 0px !important;
        padding: 8px 10px !important;

        .RightTopStyle {
          ul {
            li {
              margin: 0px !important;
              padding: 0px !important;
            }
          }
        }
      }
    }
  }

  .BackstageInfoStream {
    .HeadingBstage {
      height: 57px;
    }
  }
}

.BoothFootAction {
  position: fixed;
  bottom: 15px;
  left: 0;
  right: 0;
  margin: 0px auto;
  text-align: center;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;

  .ActionBtnBottom {
    display: inline-block;

    .btn-info {
      color: #fff;
      background: linear-gradient(30deg, #3367e4 25%, #3367e4 0, #8e3fbd 70%) !important;
      border: 0px;
      margin: 0 8px;
      cursor: pointer;
      padding: 12px 14px;

      &:hover {
        outline: none;
        box-shadow: none;
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }

      svg {
        width: 22px;
        height: 22px;
      }
    }
  }
}

.edit-expo-booth {
  display: inline-block;
  position: fixed;
  bottom: 15px;
  right: 90px;
  z-index: 8;

  .btn-info {
    color: #fff;
    background: linear-gradient(30deg, #3367e4 25%, #3367e4 0, #8e3fbd 70%) !important;
    border: 0px;
    margin: 0 0px;
    cursor: pointer;
    padding: 12px 14px;
    font-size: 15px;
  }
}

.FilesInfos {
  height: calc(100vh - 160px);
  overflow-y: auto;
  padding-right: 14px;
  padding: 18px 18px 0px;
  scrollbar-width: thin;

  .FilesActionDoc {
    border-bottom: 1px var(--gray) solid;
    margin: 0 0 15px;
    padding-bottom: 15px;
    display: flex;
    align-items: center;

    .thumbnail {
      float: left;
      background-color: var(--gray);
      padding: 8px;
      border-radius: 6px;
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;

      .BgThumbnail {
        width: 42px;
        height: 42px;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    .FileTypeNmae {
      margin-left: 18px;

      p {
        font-size: 14px;
        line-height: 22px;
        color: var(--dark-gray);
        margin-bottom: 24px;
        letter-spacing: .2px;
        margin: 0 0 6px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .FilesDownload {
        display: inline-block;

        .fileDwnload {
          background: #fff;
          display: inline-block;
          text-decoration: none;
          padding: 6px 16px;
          border-radius: 100px;
          font-size: 13px;
          font-weight: 500;
          color: var(--blue);
          text-transform: uppercase;
        }
      }

      .FilesPreview {
        display: inline-block;
        margin-right: 8px;

        .filePreview {
          background: #fff;
          display: inline-block;
          text-decoration: none;
          padding: 6px 30px;
          border-radius: 100px;
          font-size: 12px;
          font-weight: 500;
          border: 0;
          color: var(--blue);
          text-transform: uppercase;
        }
      }
    }

    &:last-child {
      margin-bottom: 0px;
      padding-bottom: 0px;
      border-bottom: 0px;
    }
  }

}

.JoinRequestedMx {
  padding: 18px;
  height: calc(100vh - 160px) !important;
  overflow-y: auto;
  scrollbar-width: thin;

  .JoinStageRequest {
    .JoinRequestedUser {
      h4 {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .requestActionBox {
    padding: 16px 16px;
    border-radius: 6px;
    margin-top: 10px;

    .MxUserReject {
      h4 {
        font-size: 14px;
        font-weight: 500;
        color: var(--dark-gray);
        margin: 0 0 15px;
      }

      .UserProActionMx {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        button {
          font-size: 12px;
          padding: 4px 12px;
          border-radius: 0;
          font-weight: 400;
        }

        .MxUserReject {

          margin-right: 8px;
        }

        // .MxUserAccept {

        // }

        .MxUserAccepted {
          background: #eafbf3;
          width: 100%;
          text-align: center;
          padding: 10px 0;

          span {
            font-size: 15px;
            font-weight: 400;
          }
        }

        .MxUserRejected {
          background: #ffe7e7;
          width: 100%;
          text-align: center;
          padding: 10px 0;

          span {
            font-size: 15px;
            font-weight: 400;
          }
        }
      }
    }
  }
}


.LogOUtBtn {
  position: fixed;
  top: 8px;
  right: 8px;
  z-index: 92;

  .EnterBtn {
    padding: 3px;
    width: 24px;
    height: 24px;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }

    &:hover {
      outline: none;
      box-shadow: none;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.LogOUtPopBtn {
  position: fixed;
  top: 1px;
  right: -26px;

  .EnterBtn {
    padding: 6px !important;
    width: 28px;
    height: 28px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 4px 4px 0px;

    svg {
      width: 100%;
      height: 100%;
    }

    &:hover {
      outline: none;
      box-shadow: none;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.confettiCanvas {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 8;

  canvas {
    width: 100% !important;
    height: 100% !important;
  }
}

.uploadFilesPic {
  .minHeightBox {
    flex: 0 0 313px !important;
    max-width: 313px !important;

    .dzu-previewContainer {
      height: 100%;
      justify-content: center;
    }

    .drop-zone-img-preview {
      position: relative;
      padding: 0 !important;
      height: 100%;
      aspect-ratio: 16/9;

      video {
        width: 100% !important;
        height: 100% !important;
      }

      iframe {
        width: 100% !important;
        height: 100% !important;
      }

      .dzu-previewStatusContainer {
        position: absolute;
        top: 0;
        width: 100%;
        left: 0;

        .upload-icons {
          background-color: #fff;
          width: 22px;
          height: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100px;
          right: 12px !important;
          left: auto !important;
          left: initial !important;
          cursor: pointer;
          top: 12px !important;
          padding: 3px;

          svg {
            width: 100%;
            height: 100%;
            fill: #eb354d;
          }
        }
      }

      img {
        object-fit: scale-down;
      }

      .dzu-previewFileName {
        width: 100%;
        height: auto;
        //position: absolute;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0px;
      }
    }

    progress {
      position: relative;
      //top: -22px;
    }

    .dzu-previewContainer {
      .dzu-previewButton {
        position: absolute;
        top: 4px;
        right: 7px;
        color: #000;
      }
    }
  }
}

.drop-zone-img-preview {
  position: relative;
  width: 100%;
  height: 100%;

  img {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0px;
    border-radius: 10px;
    object-fit: scale-down;
  }

  video {
    width: 100% !important;
    height: 100% !important;
  }

  .dzu-previewStatusContainer {
    justify-content: center;
    height: 100%;
    flex-direction: column;
  }

  .preview-docx {
    background-color: #fff;
  }

  .preview-docx {
    background-color: rgb(255 255 255 / 22%);
    margin: 0px !important;
    height: 100% !important;
  }
}

.UpdateOvelayImgVodeo {
  .minHeightBox {
    border: 1px #1A4788 dashed !important;
    height: 170px !important;
    min-height: 170px !important;
    border-radius: 10px;
    margin: 0px auto 0 !important;

    .UploaderFiles {
      height: 100%;
      width: 100%;
      padding: 0px 0;

      .UploadLabel {
        font-weight: 500 !important;
        font-size: 12px !important;
        line-height: 18px;
        width: 100%;
        height: 100%;
        padding: 16px 0 !important;

        .ImageUploadFix {
          height: 100%;
          //position: absolute;
          width: 100%;

          // display: flex;
          // align-items: center;
          // justify-content: center;
          // flex-direction: column;
          .ImgSelecetd {
            margin-bottom: 6px;

            svg {
              width: 42px;
              height: 42px;
            }

            .MidSelecetd {
              max-width: 100%;

              img {
                max-width: 100%;
                max-height: 100%;
                width: 100%;
                height: 100%;
                border-radius: 6px;
                position: absolute;
                left: 0;
                top: 0;
                padding: 0px;
                border-radius: 10px;
                object-fit: scale-down;
              }
            }
          }
        }

        span {
          color: #333333 !important;

          span {
            color: #237be2 !important;
          }
        }

        .img-fluid {
          margin: 0 0 11px;
          border-radius: 0px !important;
          width: 42px;
        }

      }
    }

    .NewFileUpload {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      flex-direction: column;

      .UploadLabel {
        height: 84px;

        #file-upload {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: transparent;
          opacity: 0;
          left: 0;
          top: 0;
          z-index: 6;
        }

        .ImageUploadFix {
          .ImgActionUp {
            position: absolute;
            right: 10px;
            top: 8px;
            z-index: 8;

            .EditImgBtn {
              background-color: #fff;
              width: 22px;
              height: 22px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 100px;
              right: 12px !important;
              left: auto !important;
              left: initial !important;
              cursor: pointer;
              top: 12px !important;
              padding: 3px;
              border: 0px;

              svg {
                width: 100%;
                height: 100%;
                fill: #eb354d;
              }
            }
          }
        }
      }

      .SeletMediaImg {
        width: 100%;
        position: relative;
        z-index: 8;
        span {
          font-size: 14px;
        }
        p {
          margin: 0;
        }
      }

    }

  }

  .minHeightBox {
    .default-image-selected {
      position: relative;
      padding: 0 !important;

      .UploadLabel {
        margin: 0px !important;
        margin-top: 0px !important;
        padding: 0px !important;
        border: 0px;
        font-weight: 500;
        font-size: 11px;
        line-height: 17px;

        .uploadImgIcon {
          position: absolute;
          height: 24px;
          right: 10px !important;
          top: 11px !important;
          background-color: var(--light-gray);
          border-radius: 100px;
          display: flex;
          align-items: center;
          cursor: pointer;
          z-index: 5;

          .UploadDeleteIco {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px;
            width: 24px;
            height: 24px;
            background-color: var(--light-gray);
            border-radius: 100px;

            svg {
              width: 100%;
              height: 100%;
              fill: #000;
            }
          }
        }

        .uploadPreviewIcon {
          position: absolute;
          height: 24px;
          right: 46px !important;
          top: 11px !important;
          background-color: #caafaf;
          border-radius: 100px;
          display: flex;
          align-items: center;
          cursor: pointer;

          .UploadPreviewIco {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1px;
            width: 24px;
            height: 24px;
            background-color: var(--light-gray);
            border-radius: 100px;

            svg {
              width: 100%;
              height: 100%;
              fill: #000;
            }
          }
        }

        .img-fluid {
          margin: 0 0 0px !important;
          width: 100% !important;
          height: 100% !important;
          object-fit: scale-down;
        }

        video {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.SelectDropdown {
  .dropdown {
    .css-2b097c-container {
      color: #000;

      .css-1pahdxg-control {
        height: 50px;
        border: 1px #a5a5a5 solid;
        box-shadow: none;
        padding: 0 12px;
        font-size: 14px;
        border-radius: 100px;
        cursor: pointer;

        div {
          padding: 0 0;
          padding-right: 15px;

          .css-1wy0on6 {
            .css-1okebmr-indicatorSeparator {
              display: none;
            }

            .css-1okebmr-indicatorSeparator {
              display: none;
            }

            .css-1gtu0rj-indicatorContainer {
              display: none;
            }

            &::after {
              content: "";
              display: inline-block;
              width: 13px;
              height: 13px;
              border-top: 2px solid var(--dark-gray);
              border-right: 2px solid var(--dark-gray);
              transform: rotate(135deg);
              position: absolute;
              right: 20px;
              top: 15px;
            }

            .css-tlfecz-indicatorContainer {
              display: none !important;
            }
          }
        }

        .css-1wy0on6 {
          .css-1okebmr-indicatorSeparator {
            display: none;
          }

          .css-1okebmr-indicatorSeparator {
            display: none;
          }

          .css-1gtu0rj-indicatorContainer {
            display: none;
          }

          &::after {
            content: "";
            display: inline-block;
            width: 13px;
            height: 13px;
            border-top: 2px solid var(--dark-gray);
            border-right: 2px solid var(--dark-gray);
            transform: rotate(135deg);
            position: absolute;
            right: 20px;
            top: 15px;
          }

          .css-tlfecz-indicatorContainer {
            display: none !important;
          }
        }
      }

      .css-yk16xz-control {
        width: 100%;
        height: 50px;
        border-radius: 100px;
        font-size: 14px;
        padding: 0 12px;
        border: 1px #a5a5a5 solid;
        color: var(--dark-gray);
        cursor: pointer;

        div {
          padding: 0 0;
          padding-right: 15px;


        }

        .css-1wy0on6 {
          .css-1okebmr-indicatorSeparator {
            display: none;
          }

          .css-1gtu0rj-indicatorContainer {
            display: none;
          }

          &::after {
            content: "";
            display: inline-block;
            width: 13px;
            height: 13px;
            border-top: 2px solid var(--dark-gray);
            border-right: 2px solid var(--dark-gray);
            transform: rotate(135deg);
            position: absolute;
            right: 20px;
            top: 15px;
          }

          .css-tlfecz-indicatorContainer {
            display: none !important;
          }
        }
      }

    }

    div {
      text-align: left !important;
    }
  }

  .css-1hb7zxy-IndicatorsContainer {
    div {
      display: none;
    }

    .css-tlfecz-indicatorContainer {
      display: none;
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }

    &::after {
      content: "";
      display: inline-block;
      width: 13px;
      height: 13px;
      border-top: 2px solid var(--dark-gray);
      border-right: 2px solid var(--dark-gray);
      transform: rotate(135deg);
      position: absolute;
      right: 20px;
      top: 14px;
    }
  }

  &::after {
    display: none;
  }
}



.AreYouSure {
  .modal-dialog {
    max-width: 500px;
    height: calc(100vh - 50px);
    margin: 0 auto !important;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0s ease-out !important;
    transform: translate(0, 0px) !important;

    .modal-content {
      background-color: transparent;

      .DarkBlurBackground {
        .SureTableLeave {
          background-color: #ffffff;
          border-radius: 10px;
          padding: 0;

          .modal-body {
            text-align: center;
            padding: 0 !important;
          }

          .LeagePgHeader {
            background-color: var(--light-gray);
            padding: 14px 0;
            border-radius: 10px 10px 0 0;

            h4 {
              margin: 0;
              font-size: 18px;
              font-weight: 500;
              text-align: center;
              color: var(--blue);
            }
          }

          .FullContainer {
            text-align: center;
            padding: 0 0 24px;

            p {
              text-align: center;
              margin: 0;
              padding: 30px 10px;
              font-size: 16px;
              font-weight: 500;
            }

            .ActionBtnMain {
              display: inline-block;
              margin: 0 6px;
              width: 145px;

              button {
                font-size: 15px;
                padding: 8px 10px;
              }
            }
          }
        }
      }
    }
  }
}

.StageExtras {
  .SelectDropdown {
    &:after {
      display: none;
    }
  }
}

.beginTimer-div {
  width: 80px;
  background-color: #eb233c;
  padding: 2px;
  border-radius: 0px 0px 6px 6px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  z-index: 99;
}

.JoinStageRequestSegment {
  background-color: var(--gray);
  padding: 10px;
  border-radius: 10px;
  text-align: center;

  p {
    font-size: 15px;
    margin: 0 0 6px;
    text-align: left;

    &:last-child {
      margin: 0;
    }
  }
}

.JoinStageRequestSent {
  background-color: var(--gray);
  padding: 10px;
  border-radius: 10px;
  text-align: center;

  p {
    font-size: 15px;
    margin: 0 0 6px;
    text-align: left;
  }
}


.JoinRequestedUser {
  background-color: var(--gray);
  padding: 15px 0;
  border-radius: 100px;
  text-align: center;

  h4 {
    font-size: 18px;
    margin: 0px;
    text-align: center;
  }
}

.activeQues {
  float: right;
  background-color: #188125;
  color: #fff;
  font-size: 10px;
  padding: 1px 5px;
  border-radius: 100px;
  margin: 6px 4px 0 6px;
}

.InactiveQues {
  float: right;
  background-color: #686868;
  color: #fff;
  font-size: 10px;
  padding: 1px 5px;
  border-radius: 100px;
  margin: 6px 4px 0 6px;
}

.CloseQues {
  float: right;
  background-color: #d50505;
  color: #fff;
  font-size: 10px;
  padding: 1px 5px;
  border-radius: 100px;
  margin: 6px 0 0;
}

.ImagesBroadcastLive {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;

  img {
    width: auto;
    height: 100%;
  }
}



.screen-stage-main-div {
  .MainViewBackStage {
    .front-stage-stream-scroll {
      height: calc(100vh - 50px) !important;
      width: 85% !important;
      padding: 0 28px;

      .row {
        .front-stage-share-screen {
          width: 82%;
          display: initial !important;
          height: 100%;

          // flex: 4 1;
          // display: block !important;
          // align-items: center;
          // justify-content: center;
          .VideoBroadcastLive {
            position: relative !important;
            z-index: 91;
            img {
              position: initial !important;
            }

            div {
              width: 100% !important;
              height: 100% !important;
              aspect-ratio: 16/9;
              display: flex;
              align-items: center;
              justify-content: center;

              iframe {
                height: auto !important;
                aspect-ratio: 16/9;
              }

              video {
                height: auto !important;
                aspect-ratio: 16/9;
              }
            }
          }

          .ImageBroadcastLive {
            width: 100%;
            height: 100%;

            .RatioDiv16 {
              aspect-ratio: 16/9;
              height: 100%;
              width: 100%;
              margin: 0px auto;

              img {
                //object-fit: scale-down;
                position: initial !important;
                border-radius: 10px;
              }
            }
          }

          .PDFBroadcastLive {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            .RatioDiv16 {
              //aspect-ratio: 16/9;
              height: 99%;
              margin: 0px auto;
              width: auto;

              img {
                object-fit: scale-down;
                position: initial !important;
                //border-radius: 10px;
                width: 100% !important;
                height: 100% !important;
              }
            }
          }

          .video_footer {
            div {
              video {
                object-fit: contain !important;
              }
            }
          }

          #pdfViewer {
            width: 100% !important;
            height: 100% !important;

            iframe {
              width: 100% !important;
              height: 100% !important;
            }
          }
        }

        .front-stage-share-screen.empty-front-stage-share-screen {
          display: none !important;
        }

        .front-stage-share-screen.screen-share-element {
          height: auto !important;
        }

        .stage-user-list {
          //flex: 1 1;
          width: 18%;
          height: calc(100vh - 220px) !important;
          padding: 10px 0;
          padding-right: 6px !important;
          display: block;
          overflow-y: auto;
          overflow-x: auto;
          scrollbar-width: thin;

          .list-1,
          .list-2,
          .list-3,
          .list-4,
          .list-5,
          .list-6,
          .list-7,
          .list-8,
          .list-9,
          .list-10,
          .list-11,
          .list-12,
          .list-13,
          .list-14,
          .list-15,
          .list-16 {
            width: auto;
            height: auto;
          }

          .RightSvgIcon {
            .mh-cursor-pointer {
              top: 6px;
              left: 6px;
              width: 22px;
              height: 22px;
              padding: 5px !important;
            }
          }

          .video_footer {
            .bottom_footer {
              h4 {
                font-size: 14px;
              }
            }

            .localPlayer {
              div {
                video {
                  border-radius: 10px;
                }
              }
            }

            .RightSvgIcon {
              .mh-cursor-pointer {
                transform: rotate(-180deg);
                padding: 7px;
              }
            }

            .bottom_footer {

              //bottom: 0px !important;
              h4 {
                width: 100%;

                span {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box !important;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                  width: 100%;

                }
              }
            }
          }
        }
      }

      .localPlayer {
        aspect-ratio: 16/9;
      }
    }
  }

}

.screen-stage-main-div {
  .MainViewBackStage {
    .alignAllDiv {
      .front-stage-stream-scroll {
        width: 100% !important
      }
    }


  }
}

.screen-stage-main-div.oraganizer-backstage-dashboard {
  .container-fluid {
    display: block !important;

    .MainViewBackStage {
      .DivChangeWidthView {
        .ChangeWidthScroll {

          .flexDicSeps {
            .alignAllDiv {
              .front-stage-stream-scroll {
                height: 100% !important;

                .row {
                  margin: 0px auto;
                  justify-content: center;
                  height: 100%;
                  align-items: center;

                  .ImptyStreamBg {
                    display: none;
                  }

                  .front-stage-share-screen {
                    background-color: transparent !important;

                    .VideoBroadcastLive {
                      display: flex;
                      align-items: center;
                      justify-content: center;

                      div {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        video {
                          height: auto !important;
                        }
                      }

                      .alignBlockAuto {
                        img {
                          width: 100% !important;
                        }
                      }
                    }

                    .ImageBroadcastLive {
                      width: 100%;
                      height: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: center;

                      .RatioDiv16 {
                        aspect-ratio: 16/9;
                        height: auto;
                        margin: 0px auto;
                        width: 100%;

                        img {
                          //object-fit: scale-down;
                          position: initial !important;
                          border-radius: 6px;
                        }
                      }
                    }

                    .PDFBroadcastLive {
                      width: 100%;
                      height: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      position: relative;

                      .RatioDiv16 {
                        //aspect-ratio: 16/9;
                        height: 99%;
                        margin: 0px auto;
                        width: auto;

                        img {
                          //object-fit: scale-down;
                          position: initial !important;
                          //border-radius: 10px;
                          aspect-ratio: 16/9;
                          width: 100% !important;
                          height: 100% !important;
                          aspect-ratio: 16/9;
                        }
                      }
                    }
                  }

                  .front-stage-share-screen.screen-share-element {
                    height: auto !important;
                  }

                  .stage-user-list {
                    color: #000;
                    height: calc(100% - 132px) !important;
                    padding: 6px 0;
                    padding-right: 6px !important;

                    .video_footer {
                      .RightSvgIcon {
                        .mh-cursor-pointer {
                          width: 24px;
                          height: 24px;
                          padding: 6px;
                          top: 5px;
                          left: 5px;
                        }

                        .RemoveSpeaker {
                          span {
                            width: 80px;
                            padding: 3px 10px;
                            font-size: 12px;
                          }
                        }
                      }

                      .ShortName {
                        h4 {
                          font-size: 22px !important;
                          width: 50px !important;
                          height: 50px !important;
                        }
                      }

                      .bottom_footer {
                        position: absolute;
                        bottom: -120px;
                        left: 0px;
                        transition: all 0.6s ease;
                        -webkit-transition: all 0.6s ease;
                        -moz-transition: all 0.6s ease;
                        -o-transition: all 0.6s ease;
                        -o-transition: all 0.6s ease;

                        .NameExpFull {
                          width: 100% !important;
                          border-radius: 0px 0px 4px 4px;
                          background: rgb(0 0 0 / 50%) !important;
                          height: 24px;
                          margin-top: 0;

                          h4 {
                            color: #ffffff;
                            font-size: 14px;
                            font-weight: 400;
                            margin: 0;
                            display: flex;
                            align-items: center;
                            text-align: center;
                            padding: 0px 5px;
                            height: 100%;
                          }
                        }
                      }

                      .lower_third {
                        left: 0px;
                        bottom: 0px !important;
                        transition: all 0.5s ease;
                        -webkit-transition: all 0.5s ease;
                        -moz-transition: all 0.5s ease;
                        -o-transition: all 0.5s ease;
                        -o-transition: all 0.5s ease;
                      }
                    }

                    .video_footer {
                      aspect-ratio: 16/9;

                      .BackIconAction {
                        top: 4px;
                        right: 4px;

                        .ActionLive {
                          width: 20px;
                          height: 20px;

                          .widthSvg {
                            padding: 3px 3px;
                          }
                        }
                      }
                    }
                  }

                  .stage-user-list {
                    height: calc(100% - 10%) !important;
                    padding-right: 6px !important;

                    .video_footer {
                      .bottom_footer {
                        h4 {
                          font-size: 14px;
                        }
                      }
                    }
                  }

                  .PollQnaBase {
                    position: absolute;
                    left: 0;
                    bottom: 2px;
                    width: 100%;
                    z-index: 99;
                    max-height: calc(100vh - 312px);
                    overflow-y: auto;
                    overflow-x: hidden;
                    scrollbar-width: thin;

                    .ScreenCast {
                      width: 100%;
                      margin: 0 0 15px;
                      background-color: rgba(0, 0, 0, 0.32);
                      padding: 8px 0px;
                      border-radius: 6px;

                      h4 {
                        background-color: #1a78e5;
                        color: #fff;
                        font-weight: 400;
                        font-size: 15px;
                        padding: 10px 14px;
                        border-radius: 4px;
                        text-align: left;
                      }

                      .OptionPoll {
                        width: 100%;

                        .row {
                          align-items: center !important;

                          .PollActionTab {
                            background-color: #f0f0f0;
                            display: inline-block;
                            text-align: left;
                            padding: 6px 18px;
                            font-size: 14px;
                            border-radius: 4px;
                            margin: 0 0 12px;
                            width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box !important;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                          }

                          .option-1 {
                            flex: 0 0 auto;
                            width: 100%;
                          }

                          .option-2 {
                            flex: 0 0 auto;
                            width: 50%;
                          }

                          .option-3 {
                            flex: 0 0 auto;
                            width: 50%;
                          }

                          .option-4 {
                            flex: 0 0 auto;
                            width: 50%;
                          }

                          .option-5 {
                            flex: 0 0 auto;
                            width: 33.33333333%;
                          }

                          .option-6 {
                            flex: 0 0 auto;
                            width: 33.33333333%;
                          }

                          .option-7 {
                            flex: 0 0 auto;
                            width: 33.33333333%;
                          }

                          .option-8 {
                            flex: 0 0 auto;
                            width: 33.33333333%;
                          }
                        }
                      }

                      .row {
                        justify-content: left;
                        align-items: end !important;

                        .QuestionVote {
                          padding: 0px 0px !important;
                          background-color: #fff;
                          border-radius: 6px;
                          box-shadow: 0px 0px 6px rgb(0 0 0 / 5%);
                          position: relative;
                          width: 100%;
                          margin: 5px 0 5px;
                          height: 100%;
                          display: inline-block;

                          .UpdateVoteStream {
                            position: relative;
                            width: 100%;
                            height: 100%;

                            .QnAuserImg {
                              width: 65px;
                              height: 65px;
                              background-color: #1a78e5;
                              border-radius: 8px;
                              margin-right: 6px;
                              position: absolute;
                              left: 15px;
                              top: 14px;
                              border: 1px #fff solid;

                              .img-fluid {
                                border-radius: 8px;
                                position: initial !important;
                              }
                            }

                            .ShowAnAQue {
                              width: calc(100% - 0px);
                              display: flex;
                              align-items: center;
                              background: #1a78e5;
                              height: 50px;
                              padding-left: 100px;
                              justify-content: space-between;
                              border-radius: 6px 6px 0px 0px;

                              .NameQnAuser {
                                padding: 5px 0;
                                display: flex;
                                justify-content: center;
                                flex-direction: column;
                                text-align: left;
                                width: 80%;

                                h4 {
                                  font-size: 16px;
                                  font-weight: 500;
                                  margin: 0;
                                  background-color: transparent;
                                  padding: 0;
                                  overflow: hidden;
                                  text-overflow: ellipsis;
                                  display: -webkit-box;
                                  -webkit-line-clamp: 1;
                                  -webkit-box-orient: vertical;
                                  width: 100%;
                                  color: #fff;
                                  ;
                                }
                              }

                              .QnaVoteCount {
                                padding: 0px 0px;
                                border-radius: 0;
                                display: flex;
                                justify-content: center;
                                margin-right: 22px;

                                svg {
                                  fill: #ffffff;
                                }

                                span {
                                  vertical-align: middle;
                                  margin-left: 8px;
                                  color: #fff;
                                  box-shadow: 0px 0px 6px rgb(0 0 0 / 7%);
                                  font-size: 15px;
                                  display: inline-flex;
                                  align-items: center;
                                  justify-content: center;
                                }
                              }
                            }
                          }

                          .ShowQueHere {
                            padding-left: 100px;
                            height: 50%;
                            display: flex;
                            align-items: center;

                            p {
                              font-size: 14px;
                              line-height: 20px;
                              color: var(--dark-gray);
                              letter-spacing: 0.2px;
                              margin: 0;
                              text-align: left;
                              // overflow: hidden;
                              // text-overflow: ellipsis;
                              // display: -webkit-box;
                              // -webkit-line-clamp: 1;
                              // -webkit-box-orient: vertical;
                              width: 95%;
                            }
                          }
                        }
                      }

                    }
                  }


                }
              }

              .RemoveAllDiv {
                .front-stage-stream-scroll {
                  width: 95% !important;

                  .row {
                    .front-stage-share-screen.screen-share-element {
                      display: flex !important;
                      align-items: center;
                      justify-content: center;
                    }
                  }
                }
              }



              .DivChangeWidthView {
                .ChangeWidthScroll {
                  .mixhubbListLayout {
                    .WrapmixhubbList {
                      .ScrollmixhubbWrap {
                        .ListContentAdd {
                          .ListPreviewMix {
                            .MainViewBackStage {
                              float: left;
                              width: 100%;
                              display: inherit !important;
                              height: 100% !important;
                              position: relative;
                              margin-left: 0px !important;
                              // align-items: center;
                              // justify-content: center;
                              // flex-direction: column;

                              .DivChangeWidthView {
                                float: right;
                                width: 100%;
                                height: 100% !important;
                                position: relative;
                                margin: 0px !important;
                                transition: all 0.6s ease;
                                -webkit-transition: all 0.6s ease;
                                -moz-transition: all 0.6s ease;
                                -o-transition: all 0.6s ease;
                                -o-transition: all 0.6s ease;
                                display: initial !important;
                                align-items: center;
                                justify-content: center;
                                flex-direction: column;
                                padding: 0;

                                .ChangeWidthScroll {
                                  width: 100%;
                                  padding: 0;
                                  height: auto !important;

                                  .flexDicSeps {
                                    width: 100% !important;
                                    height: 100% !important;
                                    background: transparent !important;

                                    .RemoveAllDiv {
                                      width: auto !important;
                                      height: 100% !important;
                                      display: flex !important;
                                      aspect-ratio: 16/9;
                                      margin: 0px auto;

                                      .img-fluid {
                                        position: absolute;
                                        left: 0;
                                        right: 0;
                                        height: 100% !important;
                                        width: 100% !important;
                                        margin: 0px auto;
                                      }

                                      .front-stage-stream-scroll {
                                        //position: absolute;
                                        width: 85%;
                                        height: auto;
                                        left: 0;
                                        right: 0;
                                        top: 0;
                                        margin: 0px auto;
                                      }

                                      img {
                                        width: 100%;
                                        height: 100%;
                                      }
                                    }

                                    .alignAllDiv {
                                      width: 100%;
                                      height: 100% !important;
                                      position: relative;
                                      display: flex;
                                      align-items: center;
                                      justify-content: center;
                                      height: calc(100vh - 310px);

                                      .front-stage-stream-scroll {
                                        aspect-ratio: 16/9;
                                        margin: 0px auto 0;
                                        padding: 0px 0px;
                                        border-radius: 6px;

                                        // position: absolute;
                                        // top: 0;
                                        //width: 100%;
                                        // height: auto;
                                        // left: 0;
                                        // right: 0;
                                        // background-color: rgb(0 0 0 / 50%);
                                        .row {
                                          height: 100%;
                                          align-items: center;
                                          width: 100%;
                                          display: block;

                                          .video_footer {
                                            position: relative;
                                            background: #000;
                                            border: 1px #2e2e2e solid;
                                            border-radius: 4px;

                                            .bottom_footer {
                                              display: none;
                                            }
                                          }

                                          .VideoBroadcastLive {
                                            position: absolute;
                                            padding: 0px;
                                            width: auto;
                                            height: 100%;
                                            left: 0;
                                            margin: 0px auto;
                                            right: 0;

                                            div {
                                              width: 100% !important;
                                              height: 100% !important;
                                              aspect-ratio: 16/9;
                                            }
                                          }

                                          .ImageBroadcastLive {
                                            width: 100%;
                                            height: 100%;

                                            .RatioDiv16 {
                                              aspect-ratio: 16/9;
                                              height: 100%;
                                              width: 100%;
                                              margin: 0px auto;

                                              img {
                                                //object-fit: scale-down;
                                                position: initial !important;
                                                border-radius: 10px;
                                              }
                                            }
                                          }

                                          .PDFBroadcastLive {
                                            width: 100%;
                                            height: 100%;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            position: relative;

                                            .RatioDiv16 {
                                              //aspect-ratio: 16/9;
                                              height: 99%;
                                              margin: 0px auto;
                                              width: auto;

                                              img {
                                                //object-fit: scale-down;
                                                position: initial !important;
                                                //border-radius: 10px;
                                                width: 100% !important;
                                                height: 100% !important;
                                              }
                                            }
                                          }

                                          .stage-user-list {
                                            height: 100% !important;
                                            width: 100% !important;
                                            padding: 0;
                                            padding-right: 6px !important;
                                            overflow-y: initial;
                                            overflow-x: initial;
                                            display: flex;
                                            scrollbar-width: thin;

                                            .list-1 {
                                              flex: 0 0 auto;
                                              width: 100%;
                                              height: 100%;
                                              padding: 2px 2px;

                                              .video_footer {
                                                .bottom_footer {
                                                  bottom: 0px ​ !important;

                                                  h4 {
                                                    span {
                                                      display: initial !important;
                                                    }
                                                  }
                                                }
                                              }
                                            }

                                            .list-2 {
                                              flex: 0 0 auto;
                                              width: 50%;
                                              height: 50%;
                                              padding: 2px 2px 2px;

                                              .video_footer {
                                                .bottom_footer {
                                                  bottom: 0px ​ !important;

                                                  h4 {
                                                    span {
                                                      display: initial !important;
                                                    }
                                                  }
                                                }
                                              }
                                            }

                                            .list-3 {
                                              flex: 0 0 auto;
                                              width: 50%;
                                              height: 50%;
                                              padding: 2px 2px 2px;
                                            }

                                            .list-4 {
                                              flex: 0 0 auto;
                                              width: 50%;
                                              height: 50%;
                                              padding: 2px 2px 2px;
                                            }

                                            .list-5 {
                                              flex: 0 0 auto;
                                              width: 33.33333333%;
                                              height: 50%;
                                              padding: 2px 2px 2px;
                                              ;
                                            }

                                            .list-6 {
                                              flex: 0 0 auto;
                                              width: 33.33333333%;
                                              height: 50%;
                                              padding: 2px 2px 2px;
                                              ;
                                            }

                                            .list-7 {
                                              flex: 0 0 auto;
                                              width: 25%;
                                              height: 50%;
                                              padding: 2px 2px 2px;
                                            }

                                            .list-8 {
                                              flex: 0 0 auto;
                                              width: 25%;
                                              height: 50%;
                                              padding: 2px 2px 2px;
                                            }

                                            .list-9 {
                                              flex: 0 0 auto;
                                              width: 25%;
                                              height: 33.33333333%;
                                              padding: 2px 2px 2px;
                                            }

                                            .list-10 {
                                              flex: 0 0 auto;
                                              width: 25%;
                                              height: 33.33333333%;
                                              padding: 2px 2px 2px;
                                            }

                                            .list-11 {
                                              flex: 0 0 auto;
                                              width: 25%;
                                              height: 33.33333333%;
                                              padding: 2px 2px 2px;
                                            }

                                            .list-12 {
                                              flex: 0 0 auto;
                                              width: 25%;
                                              height: 33.33333333%;
                                              padding: 2px 2px 2px;
                                            }

                                            .list-13 {
                                              flex: 0 0 auto;
                                              width: 25%;
                                              height: 25%;
                                              padding: 2px 2px 2px;
                                            }

                                            .list-14 {
                                              flex: 0 0 auto;
                                              width: 25%;
                                              height: 25%;
                                              padding: 2px 2px 2px;
                                            }

                                            .list-15 {
                                              flex: 0 0 auto;
                                              width: 25%;
                                              height: 25%;
                                              padding: 2px 2px 2px;
                                            }

                                            .list-16 {
                                              flex: 0 0 auto;
                                              width: 25%;
                                              height: 25%;
                                              padding: 2px 2px 2px;
                                            }
                                          }

                                          .front-stage-share-screen {
                                            display: block !important;
                                            height: 100%;

                                            .VideoBroadcastLive {
                                              position: absolute;
                                              padding: 0px;
                                              width: 100%;
                                              height: 100%;
                                              left: 0;
                                              top: 0;

                                              .alignBlockAuto {
                                                display: inline-block;
                                                width: auto !important;
                                                aspect-ratio: auto !important;
                                              }
                                            }

                                            .ImageBroadcastLive {
                                              width: 100%;
                                              height: 100%;

                                              .RatioDiv16 {
                                                aspect-ratio: 16/9;
                                                height: 100%;
                                                width: 100%;
                                                margin: 0px auto;

                                                img {
                                                  //object-fit: scale-down;
                                                  position: initial !important;
                                                  border-radius: 10px;
                                                }
                                              }

                                            }

                                            .PDFBroadcastLive {
                                              width: 100%;
                                              height: 100%;
                                              display: flex;
                                              align-items: center;
                                              justify-content: center;
                                              position: relative;

                                              .RatioDiv16 {
                                                //aspect-ratio: 16/9;
                                                height: 99%;
                                                margin: 0px auto;
                                                width: auto;

                                                img {
                                                  //object-fit: scale-down;
                                                  position: initial !important;
                                                  //border-radius: 10px;
                                                  width: 100% !important;
                                                  height: 100% !important;
                                                }
                                              }
                                            }
                                          }

                                          .front-stage-share-screen.empty-front-stage-share-screen {
                                            display: none !important;
                                          }

                                          .front-stage-share-screen.screen-share-element {
                                            height: 100% !important;
                                          }

                                          .stage-user-list {

                                            .list-1,
                                            .list-2,
                                            .list-3,
                                            .list-4 {
                                              padding: 1px;

                                              .video_footer {
                                                .ShortName {
                                                  h4 {
                                                    font-size: 18px !important;
                                                    width: auto !important;
                                                    height: auto !important;
                                                    border: 0 !important;
                                                  }
                                                }
                                              }

                                              .RightSvgIcon {
                                                .mh-cursor-pointer {
                                                  padding: 6px;
                                                  top: 6px;
                                                  left: 6px;
                                                  width: 25px;
                                                  height: 25px;
                                                }
                                              }
                                            }

                                            .list-5,
                                            .list-6,
                                            .list-7,
                                            .list-8 {
                                              padding: 1px;

                                              .video_footer {
                                                .ShortName {
                                                  h4 {
                                                    font-size: 16px !important;
                                                    width: auto !important;
                                                    height: auto !important;
                                                    border: 0 !important;
                                                  }
                                                }
                                              }

                                              .RightSvgIcon {
                                                .mh-cursor-pointer {
                                                  padding: 6px;
                                                  top: 6px;
                                                  left: 6px;
                                                  width: 25px;
                                                  height: 25px;
                                                }
                                              }
                                            }

                                            .list-9,
                                            .list-10,
                                            .list-11,
                                            .list-12 {
                                              padding: 1px;

                                              .video_footer {
                                                .ShortName {
                                                  h4 {
                                                    font-size: 16px !important;
                                                    width: auto !important;
                                                    height: auto !important;
                                                    border: 0 !important;
                                                  }
                                                }
                                              }

                                              .RightSvgIcon {
                                                .mh-cursor-pointer {
                                                  padding: 6px;
                                                  top: 6px;
                                                  left: 6px;
                                                  width: 25px;
                                                  height: 25px;
                                                }
                                              }
                                            }

                                            .list-13,
                                            .list-14,
                                            .list-15,
                                            .list-16 {
                                              padding: 1px;

                                              .video_footer {
                                                .ShortName {
                                                  h4 {
                                                    font-size: 16px !important;
                                                    width: auto !important;
                                                    height: auto !important;
                                                    border: 0 !important;
                                                  }
                                                }
                                              }

                                              .RightSvgIcon {
                                                .mh-cursor-pointer {
                                                  padding: 6px;
                                                  top: 6px;
                                                  left: 6px;
                                                  width: 25px;
                                                  height: 25px;
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }


                              }

                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .mixhubbListLayout {
            color: #000;

            .WrapmixhubbList {
              .ScrollmixhubbWrap {
                .ListContentAdd {
                  .ListPreviewMix {
                    .MainViewBackStage {
                      .DivChangeWidthView {
                        .ChangeWidthScroll {
                          padding: 0px 0px !important;

                          .flexDicSeps {
                            .alignAllDiv {
                              .front-stage-stream-scroll {
                                .row {
                                  .stage-user-list {
                                    display: inline-flex;
                                    height: 100%;
                                    align-items: center;
                                    flex-wrap: wrap;
                                    padding: 0px !important;
                                    justify-content: center;
                                    width: 100% !important;

                                    .list-1 {
                                      flex: 0 0 auto;
                                      width: 100%;
                                      height: 100%;
                                      padding: 2px 2px;
                                    }

                                    .list-2 {
                                      flex: 0 0 auto;
                                      width: 50%;
                                      height: 50%;
                                      padding: 2px 2px 2px;
                                    }

                                    .list-3 {
                                      flex: 0 0 auto;
                                      width: 50%;
                                      height: 50%;
                                      padding: 2px 2px 2px;
                                    }

                                    .list-4 {
                                      flex: 0 0 auto;
                                      width: 50%;
                                      height: 50%;
                                      padding: 2px 2px 2px;
                                    }

                                    .list-5 {
                                      flex: 0 0 auto;
                                      width: 33.33333333%;
                                      height: 50%;
                                      padding: 2px 2px 2px;
                                    }

                                    .list-6 {
                                      flex: 0 0 auto;
                                      width: 33.33333333%;
                                      height: 50%;
                                      padding: 2px 2px 2px;
                                    }

                                    .list-7 {
                                      flex: 0 0 auto;
                                      width: 25%;
                                      height: 50%;
                                      padding: 2px 2px 2px;
                                    }

                                    .list-8 {
                                      flex: 0 0 auto;
                                      width: 25%;
                                      height: 50%;
                                      padding: 2px 2px 2px;
                                    }

                                    .list-9 {
                                      flex: 0 0 auto;
                                      width: 25%;
                                      height: 33.33333333%;
                                      padding: 2px 2px 2px;
                                    }

                                    .list-10 {
                                      flex: 0 0 auto;
                                      width: 25%;
                                      height: 33.33333333%;
                                      padding: 2px 2px 2px;
                                    }

                                    .list-11 {
                                      flex: 0 0 auto;
                                      width: 25%;
                                      height: 33.33333333%;
                                      padding: 2px 2px 2px;
                                    }

                                    .list-12 {
                                      flex: 0 0 auto;
                                      width: 25%;
                                      height: 33.33333333%;
                                      padding: 2px 2px 2px;
                                    }

                                    .list-13 {
                                      flex: 0 0 auto;
                                      width: 25%;
                                      height: 25%;
                                      padding: 2px 2px 2px;
                                    }

                                    .list-14 {
                                      flex: 0 0 auto;
                                      width: 25%;
                                      height: 25%;
                                      padding: 2px 2px 2px;
                                    }

                                    .list-15 {
                                      flex: 0 0 auto;
                                      width: 25%;
                                      height: 25%;
                                      padding: 2px 2px 2px;
                                    }

                                    .list-16 {
                                      flex: 0 0 auto;
                                      width: 25%;
                                      height: 25%;
                                      padding: 2px 2px 2px;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

      }







    }
  }
}

.screen-stage-main-div.organizer-frontend-dashboard {
  .MainViewBackStage {
    .RemoveAllDiv {
      .front-stage-stream-scroll {
        width: 95% !important;
      }
    }

    .front-stage-stream-scroll {

      //height: calc(100vh - 155px) !important;
      .row {
        .front-stage-share-screen {
          height: 100%;
          background-color: transparent !important;

          .VideoBroadcastLive {
            height: 100%;

            .alignBlockAuto {
              display: inline-block;
              width: auto !important;
              aspect-ratio: auto !important;

              img {
                width: 100% !important;
              }
            }
          }

          .ImageBroadcastLive {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .RatioDiv16 {
              width: 100%;
              aspect-ratio: 16/9;
              height: auto;
              margin: 0px auto;

              img {
                //object-fit: scale-down;
                position: initial !important;
                border-radius: 10px;
              }
            }
          }

          .PDFBroadcastLive {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            .RatioDiv16 {
              //aspect-ratio: 16/9;
              height: 99%;
              margin: 0px auto;
              width: auto;

              img {
                //object-fit: scale-down;
                position: initial !important;
                //border-radius: 10px;
                width: 100% !important;
                height: 100% !important;
              }
            }
          }
        }
      }

      // .row {
      //     .stage-user-list {

      //         .video_footer {
      //             .ShortName {
      //                 h4 {

      //                 }
      //             }
      //         }
      //     }
      // }
    }
  }
}



//  .screen-stage-main-div.audience-front-stage-roll {
//      .MainViewBackStage {
//          .front-stage-stream-scroll {
//              .row {
//                  .stage-user-list {

//                  }
//              }
//         }
//      }
//  }
// .screen-stage-main-div.backstage-dashboard {
//     .MainViewBackStage {
//         .alignAllDiv {

//         }
//     }
//  }

.screen-stage-main-div {
  .container-fluid {
    height: 100%;
    display: block;
    flex-flow: column nowrap;
    align-items: flex-end;
    flex: 1 1;
    overflow: hidden;
    justify-content: space-between;
    background-size: cover !important;
  }

  .MainViewBackStage {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    position: relative;
  }
}

.screen-stage-main-div .container-fluid .MainViewBackStage .DivChangeWidthView .ChangeWidthScroll .flexDicSeps .alignAllDiv .front-stage-stream-scroll .row .video_footer {
  aspect-ratio: 16/9;
}

.screen-stage-main-div.backstage-dashboard .MainViewBackStage .RemoveAllDiv .front-stage-stream-scroll .row .stage-user-list {
  height: calc(100vh - 400px) !important;
}

.VideoBroadcastLive {
  .alignBlockAuto {
    display: inline-block;
    width: auto !important;
    aspect-ratio: auto !important;

    img {
      object-fit: scale-down;
    }
  }
}


.screen-stage-main-div.frontend-dashboard {
  .MainViewBackStage {
    .RemoveAllDiv {
      .front-stage-stream-scroll {
        .row {
          .front-stage-share-screen {
            .ImageBroadcastLive {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;

              .RatioDiv16 {
                aspect-ratio: 16/9;
                height: auto !important;
                margin: 0px auto;
                width: 100%;
                color: #fff;

                img {
                  //object-fit: scale-down;
                  position: initial !important;
                  border-radius: 10px;
                }
              }
            }

            .PDFBroadcastLive {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;

              .RatioDiv16 {
                //aspect-ratio: 16/9;
                height: 99%;
                margin: 0px auto;
                width: auto;

                img {
                  //object-fit: scale-down;
                  position: initial !important;
                  //border-radius: 10px;
                  width: 100% !important;
                  height: 100% !important;
                }
              }
            }
          }
        }
      }
    }

    .alignAllDiv {
      .front-stage-stream-scroll {
        height: calc(100vh - 155px) !important;

        .row {
          .front-stage-share-screen {
            height: 100%;

            .VideoBroadcastLive {
              height: 100%;

              .alignBlockAuto {
                display: inline-block;
                width: auto !important;
                aspect-ratio: auto !important;

                img {
                  width: 100% !important;
                }
              }

            }

            .ImageBroadcastLive {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;

              .RatioDiv16 {
                aspect-ratio: 16/9;
                height: auto !important;
                margin: 0px auto;
                width: 100%;

                img {
                  //object-fit: scale-down;
                  position: initial !important;
                  border-radius: 10px;
                }
              }
            }

            .PDFBroadcastLive {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;

              .RatioDiv16 {
                //aspect-ratio: 16/9;
                height: 99%;
                margin: 0px auto;
                width: auto;

                img {
                  //object-fit: scale-down;
                  position: initial !important;
                  //border-radius: 10px;
                  width: 100% !important;
                  height: 100% !important;
                }
              }
            }
          }
        }
      }
    }
  }
}


.screen-stage-main-div.backstage-dashboard {
  .MainViewBackStage {
    .alignAllDiv {
      width: auto !important;
      height: 100% !important;
      display: flex !important;
      aspect-ratio: 16/9;
      margin: 0px auto;

      .front-stage-stream-scroll {
        height: calc(100vh - 348px) !important;
        width: 95% !important;
        padding: 0px !important;

        .row {
          .stage-user-list {
            height: calc(100vh - 360px) !important;
            padding-right: 6px !important;
          }

          .front-stage-share-screen {

            //height: auto !important;
            .VideoBroadcastLive {
              .alignBlockAuto {
                display: inline-block;
                width: auto !important;
                aspect-ratio: auto !important;

                img {
                  width: 100% !important;
                }
              }
            }

            .ImageBroadcastLive {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;

              .RatioDiv16 {
                aspect-ratio: 16/9;
                height: auto;
                margin: 0px auto;
                width: 100%;

                img {
                  //object-fit: scale-down;
                  position: initial !important;
                  border-radius: 10px;
                }
              }
            }

            .PDFBroadcastLive {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;

              .RatioDiv16 {
                //aspect-ratio: 16/9;
                height: 99%;
                margin: 0px auto;
                width: auto;

                img {
                  object-fit: scale-down;
                  position: initial !important;
                  //border-radius: 10px;
                  width: 100% !important;
                  height: 100% !important;
                }
              }
            }
          }

          .stage-user-list {
            .video_footer {
              .ShortName {
                h4 {
                  width: 50px;
                  height: 50px;
                  font-size: calc(8px + 2vmin);
                }
              }

              .bottom_footer {
                .NameExpFull {
                  width: 100% !important;
                  height: 26px !important;

                  h4 {
                    font-size: 12px !important;
                  }
                }

              }

              .lower_third {
                left: 0px !important;
              }
            }
          }
        }
      }

      .ExpendNext {
        position: absolute;
        bottom: 5px;
        right: 5px;
        cursor: pointer;
        background-color: #000;
        border-radius: 100px;
        outline: 1px solid #fff;
        padding: 4px 35px 4px 12px;
        z-index: 995;

        span {
          color: #fff;
          font-size: 14px;
          display: block;

          &::before {
            content: "";
            display: inline-block;
            width: 10px;
            height: 10px;
            border-top: 2px solid #fff;
            border-right: 2px solid #fff;
            transform: rotate(-45deg);
            position: absolute;
            right: 14px;
            top: 12px;
          }
        }
      }

      .NextScenePreview {
        position: absolute;
        z-index: 6;
        bottom: 5px;
        right: 5px;
        border-radius: 4px;
        overflow: inherit !important;
        background: #333333;
        color: white;
        font-size: 16px;
        font-weight: 400;
        width: 155px;
        height: 87px;
        cursor: pointer;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0px;
        border: 2px #fff solid;
        display: none;

        .nextScroll {
          position: absolute;
          width: 100%;
          top: -30px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 4px;
          background-color: #00000045;
          border-radius: 8px;

          span {
            font-size: 15px;
            letter-spacing: .4px;
          }

          svg {
            width: 12px;
            height: 12px;
          }
        }

        .ChangeWidthScroll {
          width: 100% !important;
          height: 100% !important;

          .flexDicSeps {
            width: 100% !important;
            height: 100% !important;

            .alignAllDiv {
              width: 100% !important;
              height: 100% !important;

              .front-stage-stream-scroll {
                width: 100% !important;
                height: 100% !important;

                .row {
                  .stage-user-list {
                    display: inline-flex;
                    height: 100% !important;
                    align-items: center;
                    flex-wrap: wrap;
                    padding: 2px !important;
                    justify-content: center;
                    width: 100% !important;

                    .list-1 {
                      flex: 0 0 auto;
                      width: 100%;
                      height: 100%;
                      padding: 2px 2px;
                    }

                    .list-2 {
                      flex: 0 0 auto;
                      width: 50%;
                      height: 50%;
                      padding: 2px 2px 2px;
                    }

                    .list-3 {
                      flex: 0 0 auto;
                      width: 50%;
                      height: 50%;
                      padding: 2px 2px 2px;
                    }

                    .list-4 {
                      flex: 0 0 auto;
                      width: 50%;
                      height: 50%;
                      padding: 2px 2px 2px;
                    }

                    .list-5 {
                      flex: 0 0 auto;
                      width: 33.33333333%;
                      height: 50%;
                      padding: 2px 2px 2px;
                    }

                    .list-6 {
                      flex: 0 0 auto;
                      width: 33.33333333%;
                      height: 50%;
                      padding: 2px 2px 2px;
                    }

                    .list-7 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 50%;
                      padding: 2px 2px 2px;
                    }

                    .list-8 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 50%;
                      padding: 2px 2px 2px;
                    }

                    .list-9 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 33.33333333%;
                      padding: 2px 2px 2px;
                    }

                    .list-10 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 33.33333333%;
                      padding: 2px 2px 2px;
                    }

                    .list-11 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 33.33333333%;
                      padding: 2px 2px 2px;
                    }

                    .list-12 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 33.33333333%;
                      padding: 2px 2px 2px;
                    }

                    .list-13 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 25%;
                      padding: 2px 2px 2px;
                    }

                    .list-14 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 25%;
                      padding: 2px 2px 2px;
                    }

                    .list-15 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 25%;
                      padding: 2px 2px 2px;
                    }

                    .list-16 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 25%;
                      padding: 2px 2px 2px;
                    }

                    .video_footer {
                      border-radius: 4px !important;
                    }
                  }

                  .stage-user-list {
                    .list-1 {
                      .video_footer {
                        .ShortName {
                          h4 {
                            font-size: 28px !important;
                            width: auto !important;
                            height: auto !important;
                            border: 0 !important;
                          }
                        }
                      }

                      .RightSvgIcon {
                        .mh-cursor-pointer {
                          padding: 6px;
                          top: 6px;
                          left: 6px;
                          width: 25px;
                          height: 25px;
                        }
                      }
                    }

                    .list-2,
                    .list-3,
                    .list-4 {
                      .video_footer {
                        .ShortName {
                          h4 {
                            font-size: 18px !important;
                            width: auto !important;
                            height: auto !important;
                            border: 0 !important;
                          }
                        }
                      }

                      .RightSvgIcon {
                        .mh-cursor-pointer {
                          padding: 6px;
                          top: 6px;
                          left: 6px;
                          width: 25px;
                          height: 25px;
                        }
                      }
                    }

                    .list-5,
                    .list-6,
                    .list-7,
                    .list-8 {
                      .video_footer {
                        .ShortName {
                          h4 {
                            font-size: 16px !important;
                            width: auto !important;
                            height: auto !important;
                            border: 0 !important;
                          }
                        }
                      }

                      .RightSvgIcon {
                        .mh-cursor-pointer {
                          padding: 6px;
                          top: 6px;
                          left: 6px;
                          width: 25px;
                          height: 25px;
                        }
                      }
                    }

                    .list-9,
                    .list-10,
                    .list-11,
                    .list-12 {
                      .video_footer {
                        .ShortName {
                          h4 {
                            font-size: 16px !important;
                            width: auto !important;
                            height: auto !important;
                            border: 0 !important;
                          }
                        }
                      }

                      .RightSvgIcon {
                        .mh-cursor-pointer {
                          padding: 6px;
                          top: 6px;
                          left: 6px;
                          width: 25px;
                          height: 25px;
                        }
                      }
                    }

                    .list-13,
                    .list-14,
                    .list-15,
                    .list-16 {
                      .video_footer {
                        .ShortName {
                          h4 {
                            font-size: 16px !important;
                            width: auto !important;
                            height: auto !important;
                            border: 0 !important;
                          }
                        }
                      }

                      .RightSvgIcon {
                        .mh-cursor-pointer {
                          padding: 6px;
                          top: 6px;
                          left: 6px;
                          width: 25px;
                          height: 25px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }


  .broadcast-not-started {
    height: calc(100vh - 50px) !important;

    .alignAllDiv {
      .front-stage-stream-scroll {
        width: 80% !important;
        height: calc(100vh - 50px) !important;

        .row {
          .JoinBroadcastBtn {
            position: absolute !important;
            bottom: 25px !important;
          }
        }
      }
    }
  }



  .join-not-backstage {
    .alignAllDiv {
      .front-stage-stream-scroll {
        width: 100% !important;
        height: 100% !important;

        .row {
          .stage-user-list {
            height: calc(100vh - 220px) !important;
            padding-right: 6px;
          }
        }
      }
    }
  }
}



.auttendee-bs-heading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;

  span {
    background: #eb233c;
    padding: 3px 15px;
    color: #fff;
    border-radius: 0 0 5px 5px;
  }
}

.backstage-dashboard {
  .join-not-backstage {
    height: calc(100vh - 50px) !important;
    margin: 0 0 0 !important;

    .alignAllDiv {

      .front-stage-stream-scroll {
        .row {
          .JoinBroadcastBtn {
            position: absolute;
            bottom: 20px;
          }
        }
      }
    }
  }
}

.oraganizer-backstage-dashboard {
  .join-not-backstage {
    height: calc(100vh - 50px) !important;
    width: calc(100% - 388px) !important;
    margin: 0 0 0 !important;

    .alignAllDiv {
      width: 100% !important;

      .front-stage-stream-scroll {
        .row {
          .JoinBroadcastBtn {
            position: absolute;
            bottom: 20px;
          }
        }
      }
    }
  }
}

.audience-front-stage-roll {
  .join-not-backstage {
    height: calc(100vh - 50px) !important;
    width: calc(100% - 388px) !important;
    margin: 0 0 0 !important;

    .alignAllDiv {
      width: 100% !important;

      .front-stage-stream-scroll {
        .row {
          .JoinBroadcastBtn {
            position: absolute;
            bottom: 45px;
          }
        }
      }
    }
  }
}

.audience-front-stage-roll.screen-stage-main-div {
  .MainViewBackStage {
    .alignAllDiv {
      .front-stage-stream-scroll {
        height: 100% !important;

        .row {
          .front-stage-share-screen {
            .ImageBroadcastLive {
              display: flex;
              align-items: center;
              justify-content: center;

              .RatioDiv16 {
                width: 100%;
                height: auto;
              }
            }

            .PDFBroadcastLive {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;

              .RatioDiv16 {
                //aspect-ratio: 16/9;
                height: 99%;
                margin: 0px auto;
                width: auto;

                img {
                  //object-fit: scale-down;
                  position: initial !important;
                  //border-radius: 10px;
                  width: 100% !important;
                  height: 100% !important;
                }
              }
            }
          }

          .stage-user-list {
            .video_footer {
              .ShortName {
                h4 {
                  width: 50px;
                  height: 50px;
                  font-size: calc(8px + 2vmin);
                }
              }

              .bottom_footer {
                .NameExpFull {
                  width: 100% !important;
                  height: 30px !important;

                  h4 {
                    font-size: 18px !important;
                  }
                }

              }

              .lower_third {
                left: 0px !important;
              }
            }
          }
        }
      }
    }
  }
}

.go-backstage-btn {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 36px;
  text-align: center;
  max-width: 220px;
  margin: 0px auto;
  z-index: 9;

  .RedCommonBtn {
    margin-top: 0px;
  }
}

.go-backstage-btn-sidebar {
  text-align: center;
  max-width: 220px;
  margin: 0px auto;

  .RedCommonBtn {
    margin-top: 0px;
    font-size: 16px;
    box-shadow: 0px 4px 5px #00000012;
    height: 42px;
  }
}

.collapsDivToggle {
  width: 30px;
  height: 30px;
  //position: absolute;
  left: 14px;
  top: 13px;
  z-index: 6;

  .CastingToggle {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    background-color: #ffffff;
    border-color: #ffffff;
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }

    // &:after {
    //     content: "";
    //     width: 10px;
    //     height: 10px;
    //     border-top: 2px solid #000;
    //     border-right: 2px solid #000;
    //     position: absolute;
    //     right: 10px;
    //     top: 9px;
    //     background-image: none;
    //     transform: rotate(135deg);
    //     transition: all 0.5s ease;
    //     -webkit-transition: all 0.5s ease;
    //     -moz-transition: all 0.5s ease;
    //     -o-transition: all 0.5s ease;
    // }
    &:hover {
      outline: none;
      box-shadow: none;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .collapse-in-backstage {
    transform: rotate(180deg);
  }

  .collapse-in-backstage {
    &::after {
      transform: rotate(-45deg);
      top: 13px;
      transition: all 0.6s ease;
      -webkit-transition: all 0.6s ease;
      -moz-transition: all 0.6s ease;
      -o-transition: all 0.6s ease;
    }
  }
}


.screen-stage-main-div.backstage-dashboard.collapse-out-frontstage {
  .MainViewBackStage {
    .alignAllDiv {
      width: 100%;
      height: 100%;
      position: relative;
      display: inline-block;

      img {
        height: 100%;
      }

      .front-stage-stream-scroll {
        aspect-ratio: 16/9 !important;
        margin: 0px auto 0;
        padding: 0px 4px;
        border-radius: 10px;
        width: 85% !important;
        height: calc(100vh - 135px) !important;

        .row {
          .front-stage-share-screen.screen-share-element {
            height: auto !important;
          }

          .stage-user-list {
            .video_footer {
              .bottom_footer {
                .NameExpFull {
                  width: 100% !important;
                  height: 30px !important;

                  h4 {
                    font-size: 12px !important;
                  }
                }

              }

              .lower_third {
                left: 0px !important;
              }
            }
          }
        }

      }
    }
  }
}
















.oraganizer-backstage-dashboard.collapse-out-frontstage {
  .MainViewBackStage {
    height: calc(100vh - 135px) !important;
    transition: all 0.6s ease;
    -webkit-transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    -o-transition: all 0.6s ease;
    overflow: hidden;

    .alignAllDiv {
      .front-stage-stream-scroll {
        .row {
          .front-stage-share-screen {
            background-color: transparent !important;

            .VideoBroadcastLive {
              .alignBlockAuto {
                aspect-ratio: 16/9 !important;
                width: 100% !important;
              }
            }

            .ImageBroadcastLive {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;

              .RatioDiv16 {
                aspect-ratio: 16/9;
                height: auto;
                margin: 0px auto;
                width: 100%;

                img {
                  //object-fit: scale-down;
                  position: initial !important;
                  border-radius: 10px;
                }
              }
            }

            .PDFBroadcastLive {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;

              .RatioDiv16 {
                //aspect-ratio: 16/9;
                height: 99%;
                margin: 0px auto;
                width: auto;

                img {
                  //object-fit: scale-down;
                  position: initial !important;
                  //border-radius: 10px;
                  width: 100% !important;
                  height: 100% !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .HeadingBstage {
    border-radius: 10px !important;
  }
}

.screen-stage-main-div.backstage-dashboard.collapse-out-frontstage {
  .MainViewBackStage {
    .alignAllDiv {
      width: 100%;
      height: 100%;
      position: relative;
      display: inline-block;

      img {
        height: 100%;
      }

      .front-stage-stream-scroll {
        aspect-ratio: 16/9 !important;
        margin: 0px auto 0;
        padding: 0px 4px;
        border-radius: 10px;
        width: 95% !important;
        height: calc(100vh - 135px) !important;

        .row {
          .stage-user-list {
            height: calc(100vh - 220px) !important;
            padding-right: 6px !important;
          }
        }
      }

      .ExpendNext {
        position: absolute;
        bottom: 5px;
        right: 5px;
        cursor: pointer;
        background-color: #000;
        border-radius: 100px;
        outline: 1px solid #fff;
        padding: 4px 35px 4px 12px;
        z-index: 995;

        span {
          color: #fff;
          font-size: 14px;
          display: block;

          &::before {
            content: "";
            display: inline-block;
            width: 10px;
            height: 10px;
            border-top: 2px solid #fff;
            border-right: 2px solid #fff;
            transform: rotate(-45deg);
            position: absolute;
            right: 14px;
            top: 12px;
          }
        }
      }

      .NextScenePreview {
        position: absolute;
        z-index: 6;
        bottom: 5px;
        right: 5px;
        border-radius: 4px;
        overflow: inherit !important;
        background: #333333;
        color: white;
        font-size: 16px;
        font-weight: 400;
        width: 176px;
        height: 99px;
        cursor: pointer;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0px;
        border: 2px #fff solid;
        display: none;

        .nextScroll {
          position: absolute;
          width: 100%;
          top: -30px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 4px;
          background-color: #00000045;
          border-radius: 8px;

          span {
            font-size: 15px;
            letter-spacing: .4px;
          }

          svg {
            width: 12px;
            height: 12px;
          }
        }

        .ChangeWidthScroll {
          width: 100% !important;
          height: 100% !important;

          .flexDicSeps {
            width: 100% !important;
            height: 100% !important;

            .alignAllDiv {
              width: 100% !important;
              height: 100% !important;

              .front-stage-stream-scroll {
                width: 100% !important;
                height: 100% !important;

                .row {
                  .stage-user-list {
                    display: inline-flex;
                    height: 100% !important;
                    align-items: center;
                    flex-wrap: wrap;
                    padding: 2px !important;
                    justify-content: center;
                    width: 100% !important;

                    .list-1 {
                      flex: 0 0 auto;
                      width: 100%;
                      height: 100%;
                      padding: 2px 2px;
                    }

                    .list-2 {
                      flex: 0 0 auto;
                      width: 50%;
                      height: 50%;
                      padding: 2px 2px 2px;
                    }

                    .list-3 {
                      flex: 0 0 auto;
                      width: 50%;
                      height: 50%;
                      padding: 2px 2px 2px;
                    }

                    .list-4 {
                      flex: 0 0 auto;
                      width: 50%;
                      height: 50%;
                      padding: 2px 2px 2px;
                    }

                    .list-5 {
                      flex: 0 0 auto;
                      width: 33.33333333%;
                      height: 50%;
                      padding: 2px 2px 2px;
                    }

                    .list-6 {
                      flex: 0 0 auto;
                      width: 33.33333333%;
                      height: 50%;
                      padding: 2px 2px 2px;
                    }

                    .list-7 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 50%;
                      padding: 2px 2px 2px;
                    }

                    .list-8 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 50%;
                      padding: 2px 2px 2px;
                    }

                    .list-9 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 33.33333333%;
                      padding: 2px 2px 2px;
                    }

                    .list-10 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 33.33333333%;
                      padding: 2px 2px 2px;
                    }

                    .list-11 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 33.33333333%;
                      padding: 2px 2px 2px;
                    }

                    .list-12 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 33.33333333%;
                      padding: 2px 2px 2px;
                    }

                    .list-13 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 25%;
                      padding: 2px 2px 2px;
                    }

                    .list-14 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 25%;
                      padding: 2px 2px 2px;
                    }

                    .list-15 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 25%;
                      padding: 2px 2px 2px;
                    }

                    .list-16 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 25%;
                      padding: 2px 2px 2px;
                    }

                    .video_footer {
                      border-radius: 4px !important;
                    }
                  }

                  .stage-user-list {
                    .list-1 {
                      .video_footer {
                        .ShortName {
                          h4 {
                            font-size: 28px !important;
                            width: auto !important;
                            height: auto !important;
                            border: 0 !important;
                          }
                        }
                      }

                      .RightSvgIcon {
                        .mh-cursor-pointer {
                          padding: 6px;
                          top: 6px;
                          left: 6px;
                          width: 25px;
                          height: 25px;
                        }
                      }
                    }

                    .list-2,
                    .list-3,
                    .list-4 {
                      .video_footer {
                        .ShortName {
                          h4 {
                            font-size: 18px !important;
                            width: auto !important;
                            height: auto !important;
                            border: 0 !important;
                          }
                        }
                      }

                      .RightSvgIcon {
                        .mh-cursor-pointer {
                          padding: 6px;
                          top: 6px;
                          left: 6px;
                          width: 25px;
                          height: 25px;
                        }
                      }
                    }

                    .list-5,
                    .list-6,
                    .list-7,
                    .list-8 {
                      .video_footer {
                        .ShortName {
                          h4 {
                            font-size: 16px !important;
                            width: auto !important;
                            height: auto !important;
                            border: 0 !important;
                          }
                        }
                      }

                      .RightSvgIcon {
                        .mh-cursor-pointer {
                          padding: 6px;
                          top: 6px;
                          left: 6px;
                          width: 25px;
                          height: 25px;
                        }
                      }
                    }

                    .list-9,
                    .list-10,
                    .list-11,
                    .list-12 {
                      .video_footer {
                        .ShortName {
                          h4 {
                            font-size: 16px !important;
                            width: auto !important;
                            height: auto !important;
                            border: 0 !important;
                          }
                        }
                      }

                      .RightSvgIcon {
                        .mh-cursor-pointer {
                          padding: 6px;
                          top: 6px;
                          left: 6px;
                          width: 25px;
                          height: 25px;
                        }
                      }
                    }

                    .list-13,
                    .list-14,
                    .list-15,
                    .list-16 {
                      .video_footer {
                        .ShortName {
                          h4 {
                            font-size: 16px !important;
                            width: auto !important;
                            height: auto !important;
                            border: 0 !important;
                          }
                        }
                      }

                      .RightSvgIcon {
                        .mh-cursor-pointer {
                          padding: 6px;
                          top: 6px;
                          left: 6px;
                          width: 25px;
                          height: 25px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.align-screen-share {
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-controls-disabled {
  pointer-events: none;
}



.container-expired {
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(125.418deg, #1142a5 8%, #9127d9 55%);
  padding: 0 10%;

  .text-block {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    h1 {
      color: #fff;
      font-weight: bold;
      font-size: 6vmin;
      margin: 0;
      text-align: center;
    }
  }
}

.VideoBroadcastLive {
  img {
    object-fit: scale-down;
    border-radius: 10px;
  }
}

// .alignBlockAuto {
//     display: inline-block;
//     width: auto !important;
//     aspect-ratio: auto !important;
// }


.oraganizer-backstage-dashboard.collapse-out-frontstage {
  .MainViewBackStage {
    .RemoveAllDiv {
      width: auto !important;

      .front-stage-stream-scroll {
        width: 85% !important;
      }
    }
  }
}

.screen-stage-main-div.oraganizer-backstage-dashboard.collapse-out-frontstage {
  .MainViewBackStage {
    .RemoveAllDiv {
      width: 100% !important;

      .front-stage-stream-scroll {
        width: 85% !important;
        background-color: transparent !important;

        .row {
          .ImptyStreamBg {
            display: none;
          }
        }
      }
    }

  }
}

// .Share-align-screen {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     height: 100%;
// }

.UserInfoDetail {
  background: linear-gradient(180deg, #3367e4 15%, #3367e4 0, #8e3fbd 64%) !important;
  width: 142px;
  height: 115px;
  position: absolute;
  top: -125px;
  padding: 6px 6px;
  left: -49px;
  border: 1px #fff solid;
  border-radius: 4px;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  .UserImgScal {
    width: 42px;
    height: 42px;
    display: inline-flex;
    margin: 0 0 0px;
    border-radius: 100px;

    img {
      border: 1px #cdcdcd solid;
      border-radius: 100px;
    }
  }

  .UserInfoTxt {
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    height: 100%;
    margin-left: 0px;
    width: 132px;

    h4 {
      font-size: 12px;
      font-weight: 500;
      color: #fff;
      margin: 0 0 3px;
      text-align: center;
    }

    p {
      font-size: 10px;
      font-weight: 400;
      color: #fff;
      margin: 0;
      line-height: 15px;
      text-align: center;
    }
  }

  .bottomArrow {
    content: "";
    height: 16px;
    width: 16px;
    position: absolute;
    background-color: #8e3fbd;
    bottom: -8px;
    left: 0;
    border-top: #ffffff solid 1px;
    border-left: #ffffff solid 1px;
    transform: rotate(-135deg);
    right: 0;
    margin: auto;
  }
}

.EventTypeActonIn {
  .form-group {
    .SelectDropdown {
      .autoCompleteSelect {
        .css-2b097c-container {
          .css-yk16xz-control {
            min-height: 50px !important;
            height: auto !important;
            padding: 4px 15px !important;

            .css-1hb7zxy-IndicatorsContainer {
              &:after {
                display: none !important;
              }
            }

            div {
              padding: 0px !important;
            }

            .css-1rhbuit-multiValue {
              padding: 2px 4px !important;
              border-radius: 100px;

              div {
                padding: 0px 0px !important;
              }

              .css-xb97g8 {
                cursor: pointer;

                svg {
                  color: #363636 !important;
                  fill: #363636 !important;
                }

                &:hover {
                  background: transparent !important;
                }
              }
            }

            .css-g1d714-ValueContainer {
              .css-1wa3eu0-placeholder {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
              }
            }
          }

          .css-1pahdxg-control {
            min-height: 50px !important;
            height: auto !important;
            padding: 4px 15px !important;
            cursor: pointer;

            .css-1hb7zxy-IndicatorsContainer {
              &:after {
                display: none !important;
              }
            }

            div {
              padding: 0px 0px !important;
            }

            .css-1rhbuit-multiValue {
              padding: 2px 4px !important;
              border-radius: 100px;

              div {
                padding: 0px 0px !important;
              }

              .css-xb97g8 {
                cursor: pointer;

                svg {
                  color: #363636 !important;
                  fill: #363636 !important;
                }

                &:hover {
                  background: transparent !important;
                }
              }
            }

            .css-g1d714-ValueContainer {
              .css-1wa3eu0-placeholder {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
              }
            }

            .css-1wy0on6 {
              .css-1okebmr-indicatorSeparator {
                display: none;
              }

              .css-1gtu0rj-indicatorContainer {
                display: none;
              }

              &:after {
                content: "";
                display: inline-block;
                width: 13px;
                height: 13px;
                border-top: 2px solid var(--dark-gray);
                border-right: 2px solid var(--dark-gray);
                transform: rotate(135deg);
                position: absolute;
                right: 20px;
                top: 15px;
              }

              .css-tlfecz-indicatorContainer {
                display: none !important;
              }
            }
          }
        }
      }
    }
  }
}

.screen-stage-main-div.oraganizer-backstage-dashboard.collapse-out-frontstage {
  .MainViewBackStage {
    .alignAllDiv {
      width: 100%;
      height: 100%;
      position: relative;
      display: inline-block;

      img {
        height: 100%;
      }

      .front-stage-stream-scroll {
        background-color: transparent !important;
        aspect-ratio: 16/9 !important;
        margin: 0px auto 0;
        padding: 0px 4px;
        border-radius: 10px;
        width: 85% !important;
        height: calc(100vh - 135px) !important;

        .row {
          .stage-user-list {
            height: calc(100vh - 220px) !important;
            padding-right: 6px !important;
          }

          .front-stage-share-screen.screen-share-element {
            height: auto !important;
          }

          .ImptyStreamBg {
            display: none;
          }
        }
      }
    }
  }
}

.mixhubbNotification {
  position: relative;
  top: -2px;

  .IconStyle {
    width: 28px !important;
    height: 28px !important;
    margin-right: 0px;

    svg {
      width: 100%;
      height: 100%;
      fill: #fff;
    }

    .NoticHere {
      background-color: #eb233c;
      padding: 0 2px;
      font-size: 10px;
      position: absolute;
      top: -6px;
      right: -3px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 18px !important;
      border: 2px #fff solid;
      color: #fff;
      height: 18px;
      font-weight: 500;
      letter-spacing: 0.1px;
    }
  }
}

.ChatMixInfoMixhubb {
  position: fixed;
  top: 52px;
  right: 5px;
  width: 340px;
  height: calc(100vh - 55px);
  background-color: var(--light-gray);
  z-index: 97;
  border-radius: 8px;
  padding: 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  overflow: hidden;

  .headingTop {
    border-bottom: 1px #d1d1d1 solid;
    padding: 18px 8px;
    margin: 0 10px;

    h2 {
      margin: 0;
      display: inline-block;
      font-size: 16px;
      font-weight: 500;
      color: #1A78E5;

      img {
        width: 15px;
        height: 15px;
        display: inline-block;
        margin-right: 8px;
      }
    }

    .CloseButton {
      padding: 0;
      width: 28px;
      height: 28px;
      position: absolute;
      right: 14px;
      top: 16px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .ConvertionPepple {
      padding: 0;
      width: 24px;
      height: 24px;
      position: absolute;
      right: 58px;
      top: 16px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  .PeopleConvrtsList {
    height: calc(100vh - 140px);
    overflow-y: auto;
    padding: 12px 12px 0px;
    position: relative;
    scrollbar-width: thin;

    .NoRecoardInfo {
      display: grid;
      height: calc(100vh - 150px);
      text-align: center;
      width: 100%;
      align-content: center;
      justify-content: center;
    }

    .ConvrtsListMix {
      display: flex;
      padding: 10px 0;
      border-bottom: 1px solid #d4d4d4;
      //cursor: pointer;
      position: relative;
      align-items: center;
      justify-content: flex-start;

      .ConvrtsListMixImg {
        width: 35px;
        height: 35px;
        border-radius: 100px;
        background-color: #dadada;
        position: relative;

        img {
          width: 35px;
          height: 35px;
          border-radius: 100px;
        }
      }

      .AboutConvrtsList {
        margin-left: 10px;
        padding: 5px 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: left;
        width: 85%;
        padding-right: 24px;
        position: relative;

        h4 {
          font-size: 14px;
          margin: 0 0 5px;
          font-weight: 400;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          color: #000000;
        }

        p {
          font-size: 12px;
          margin: 0;
          color: #333333;
          word-break: break-word;
          position: relative;
        }
      }

      .ChatCursor {
        cursor: pointer;
      }

      .NoticHere {
        background-color: #eb233c;
        padding: 0 4px;
        font-size: 10px;
        position: absolute;
        top: 24px;
        right: 0px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 20px !important;
        min-height: 20px !important;
        border: 2px #fff solid;
        color: #fff;
        font-weight: 600;
      }
    }

    .peopleInfos {
      .PeopleAttendList {
        height: calc(100vh - 222px);
        overflow-y: auto;
        margin-top: 18px;
        padding-right: 14px;
        scrollbar-width: thin;

        .PeopleList {
          width: 100%;
          display: flex;
          padding: 10px 0;
          border-bottom: 1px #d4d4d4 solid;
          cursor: pointer;
          position: relative;

          .PeopleImg {
            width: 38px;
            height: 38px;
            border-radius: 100px;
            background-color: #dadada;
            position: relative;

            img {
              width: 38px;
              height: 38px;
              border-radius: 100px;
            }
          }

          .AboutPeople {
            margin-left: 10px;
            padding: 5px 0;
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: left;
            width: 85%;

            h4 {
              font-size: 14px;
              margin: 0 0 3px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }
          }
        }
      }

      .ChatInfos {
        width: 100%;
        height: calc(100vh - 275px);
        overflow-y: auto;
        // margin-top: 28px;
        padding: 35px 18px 0px !important;
        padding-left: 0 !important;
        scrollbar-width: thin;

        .ReceiverMassage {
          position: relative;
          width: 100%;
          float: left;
          margin: 0 0 14px;

          .receveMessage {
            float: left;
            background-color: #ffffff;
            padding: 12px 20px;
            border-radius: 16px 16px 16px 0px;
            min-width: 130px;

            p {
              margin: 0;
              font-size: 12px;
              font-weight: 500;
              margin-bottom: 2px;
              color: #696969;
            }

            h4 {
              margin: 0;
              font-size: 14px;
              color: #3c3c3c;
              line-height: 19px;
            }
          }

          .ReceiveTimeDate {
            display: block;
            font-size: 11px;
            width: 100%;
            float: left;
            margin: 6px 0 0;
            color: #717171;
            font-weight: 500;
            text-align: left;
          }
        }


        .SenderMassage {
          position: relative;
          width: 100%;
          float: left;
          margin: 0 0 14px;

          .SenderMessage {
            float: right;
            background-color: var(--gray);
            padding: 6px 18px 6px 6px;
            border-radius: 16px 16px 16px 0px;
            display: flex;

            // min-width: 130px;
            p {
              margin: 0;
              font-size: 12px;
              font-weight: 400;
              margin-bottom: 2px;
              color: #515151;
              text-align: left;
            }

            h4 {
              margin: 0;
              font-size: 12px;
              color: #3c3c3c;
              line-height: 18px;
              word-break: break-word;
              text-align: left;
              font-weight: 400;
            }

            .chatPicsIn {
              display: inline-flex;
              width: 40px;
              height: 40px;
              position: relative;
              background-color: #f0f0f0;

              img {
                width: 100%;
              }
            }

            .chatAllin {
              display: inline-block;
              flex: 1;
            }
          }

          .SenderTimeDate {
            display: block;
            font-size: 10px;
            width: 100%;
            float: left;
            margin: 6px 0 0;
            color: #717171;
            font-weight: 500;
            text-align: right;
          }
        }

        .align-left {
          width: 90% !important;
          float: left;

          .SenderMessage {
            float: left;
            display: flex;
            border-radius: 16px 16px 16px 0px !important;
            background-color: #fff !important;

            .chatPicsIn {
              display: inline-flex;
              width: 40px;
              height: 40px;
              margin-right: 8px !important;
              position: relative;
              background-color: #f0f0f0;
              border-radius: 12px 12px 12px 0px !important;

              img {
                width: 100%;
                border-radius: 12px 12px 12px 0px !important;
              }

              &:hover {
                .PersonInfoChat {
                  display: flex !important;
                }
              }
            }

            .chatAllin {
              display: inline-block;
              flex: 1;
            }
          }

          .SenderTimeDate {
            text-align: left;
          }
        }


        .align-right {
          width: 90% !important;
          float: right;

          .SenderMessage {
            border-radius: 16px 16px 0px 18px !important;
            padding: 6px 6px 6px 12px !important;
            flex-direction: row-reverse !important;
            background-color: var(--gray);

            h4 {
              text-align: flex-end !important;
            }

            .chatPicsIn {
              margin-right: 0px !important;
              margin-left: 12px !important;
              background-color: #f0f0f0;
              border-radius: 12px 12px 0px 12px !important;
              position: relative;

              img {
                border-radius: 12px 12px 0px 12px !important;
              }

              .PersonInfoChat {
                display: none !important;
              }
            }
          }
        }

      }

      .common-event-chat {
        height: calc(100vh - 305px) !important;
        margin-top: 6px;
      }
    }
  }

  .ConverSearchTop {
    height: calc(100vh - 218px);
    overflow-y: auto;
    margin: 10px 0 0;
    scrollbar-width: thin;

    .NoRecoardInfo {
      display: grid;
      height: calc(100vh - 220px);
      text-align: center;
      width: 100%;
      align-content: center;
      justify-content: center;
    }
  }
}

.NotifyMixhubbRt {
  .PeopleConvrtsList {
    overflow: hidden !important;

    .NofifyMessageUser {
      height: calc(100vh - 192px);
      padding: 0px !important;
      overflow-y: auto;
      scrollbar-width: thin;

      .ConvrtsListMix {
        padding: 0px !important;

        a {
          color: var(--dark-gray);
        }

        .AboutConvrtsList {
          margin-left: 0 !important;
          width: 100% !important;
          padding: 10px 16px !important;
          position: relative;

          .TimeFormat {
            font-size: 12px;
            font-weight: 400;
            color: #5d5d5d;
            margin: 0 0 5px;
          }

          h4 {
            padding-right: 50px;
            font-weight: 400;
            display: block;
          }

          p {
            font-weight: 400;
          }

          .ChatNext {
            position: absolute;
            right: 20px;
            top: 14px;
            display: flex;
            align-items: center;
            background-color: #fff;
            width: 28px;
            height: 28px;
            box-shadow: 0px 0px 6px rgb(0 0 0 / 10%);
            border-radius: 100px;
            justify-content: center;
            padding: 7px;

            svg {
              transform: rotate(180deg);
            }
          }
        }

        .unread-message {
          font-weight: bold;
          background-color: #fff;
          cursor: pointer;

          // padding: 10px 12px !important;
          .TimeFormat {
            top: 5px;
          }

          h4 {
            font-weight: 500;
            color: #000;
          }
        }

        &:last-child {
          border-bottom: 0px;
        }
      }
    }
  }

  .headingTop {
    display: flex;
    justify-content: space-between;
    align-items: center;

    //padding: 32px 10px 20px;
    .CloseButton {
      right: 7px;
      top: 14px;
    }

    p {
      margin: 0;
      font-size: 12px;
      background-color: #ffffff;
      color: #5d5d5d;
      cursor: pointer;
      padding: 7px 14px;
      border-radius: 100px;
      font-weight: 400;
      position: absolute;
      right: 40px;
    }
  }

  .PeopleConvrtsList {
    height: calc(100vh - 126px);
    padding: 0px !important;
  }
}




.AboutPeople {
  margin-left: 10px;
  padding: 5px 0;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
  text-align: left;
  width: 85%;

  h4 {
    font-size: 14px;
    margin: 0 0 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  p {
    font-size: 12px;
    margin: 0;
    color: #383838;
  }
}

.feaderList {
  border: 1px #f0f0f0 solid;
  padding: 14px 14px;
  border-radius: 6px;
  position: relative;
  min-height: 90px;
  margin-top: 8px;

  h4 {
    color: #222222;
    font-weight: bold;
    font-weight: 500;
    font-size: 14px;
    padding-right: 34px;
  }

  p {
    color: #222222;
    font-size: 12px;
    margin-bottom: 0px;
  }

  h5 {
    font-size: 14px;
    font-weight: 500;
    color: #222222;
    margin: 0 0 20px;
  }
}

.EnterEventin {
  margin-top: 10px;

  .CommonGtBtn {
    background: linear-gradient(60deg, #3367e4 25%, #3367e4 0%, #b550f9 70%);
    color: var(--white) !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    text-transform: capitalize !important;
    padding: 6px 20px !important;

    &:hover {
      outline: none !important;
      box-shadow: none !important;
      background-color: transparent;
    }

    &:focus {
      outline: none !important;
      box-shadow: none !important;
      background-color: transparent;
    }
  }
}

.SwitchRight {
  position: absolute;
  right: 10px;
  top: 5px;
}

.OnoffSwitch {
  .MuiFormControlLabel-root {
    margin: 0px !important;
  }

  .MuiSwitch-root {
    padding: 12px 7px !important;
    width: 48px;
    height: 37px;

    .MuiButtonBase-root {
      padding: 13px 8px !important;

      .MuiSwitch-thumb {
        width: 11px !important;
        height: 11px !important;
      }
    }
  }
}

.MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
  background-color: #19dd22 !important;
}

.MuiSwitch-colorSecondary.Mui-checked {
  color: #ffffff !important;
}

.MuiSwitch-colorSecondary.Mui-checked:hover {
  background-color: transparent !important;
}

.MuiSwitch-colorSecondary.Mui-checked:hover,
.MuiIconButton-root:hover {
  background-color: transparent !important;
}

.HeadingStatic {
  margin: 14px 0 0;

  h2 {
    font-weight: 500;
    font-size: 16px;
    margin: 0;

    img {
      margin-right: 4px;
      display: inline-block;
      vertical-align: middle;
      width: 28px;
    }
  }
}

.editBoothDetailsInfo {
  label {
    font-size: 13px;
    font-weight: 300;
    color: #676767;
    margin-bottom: 4px;
  }

  .SelectDropdown {
    &:after {
      display: none !important;
    }
  }
}

.CommonModalPopup {
  .modal-dialog {
    margin: 0% auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .modal-content {
      padding: 18px 18px;
      border-radius: 10px;

      .modal-header {
        padding: 0 0 22px;
        border-bottom: 0px;

        .modal-title {
          h4 {
            color: #222;
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0.5px;
            margin: 0;
            font-family: "Poppins", sans-serif !important;
          }
        }

        .ModalCloseBtn {
          position: absolute;
          right: 8px;
          padding: 0;
          display: block;
          top: 8px;
          min-width: 25px;

          .MuiButton-label {
            width: auto;
            padding: 0;
            display: block;
            position: relative;
            height: 25px;
            width: 25px;
            line-height: 0;
            float: right;
          }

          svg {
            width: 100%;
            height: 100%;
            opacity: 0.5;
          }

          &:hover {
            background-color: transparent;
          }

          &:focus {
            background-color: transparent;
          }
        }

        .btn-close {
          display: none;

          span {
            display: none;
          }
        }
      }

      .modal-body {
        padding: 0 0;

        .GroupForm {
          position: relative;

          label {
            position: absolute;
            left: 14px;
            top: 9px;
            font-size: 12px;
            letter-spacing: 0.1px;
            color: #808080;
            margin: 0;
            z-index: 4;
            font-family: "Poppins", sans-serif !important;
          }

          .form-control {
            background-color: var(--light-gray);
            border-radius: 6px;
            padding: 20px 14px 6px !important;
            font-size: 14px;

            input {
              font-family: "Poppins", sans-serif !important;
              font-size: 12px;

              &:focus {
                background-color: transparent !important;
              }
            }
          }

          .MuiInputBase-root {
            &:after {
              display: none;
            }

            &:before {
              display: none;
            }

            textarea {
              height: 40px;
            }
          }

          .LimitDivTxg {
            position: absolute;
            font-size: 11px;
            font-weight: 500;
            right: 10px;
            top: 6px;
            color: #696969;
          }
        }

        .ConfirmAction {
          h4 {
            font-size: 15px;
            font-weight: 500;
            margin: 0 0 28px;
          }
        }
      }

      .modal-footer {
        padding: 0 0;

        .DeleteCancleRoom {
          width: 100%;
          text-align: center;
          margin: 18px 0 0;

          .WhiteBtn {
            background-color: #ffffff !important;
            color: #007bff !important;
            font-weight: 500 !important;
            font-size: 14px !important;
            text-transform: capitalize !important;
            border: 1px #F1F1F1 solid !important;
            padding: 6px 28px !important;
            margin-right: 12px;

            &:hover {
              outline: none !important;
              box-shadow: none !important;
              background-color: transparent;
            }

            &:focus {
              outline: none !important;
              box-shadow: none !important;
              background-color: transparent;
            }
          }

          .CommonGtBtn {
            background: linear-gradient(60deg, #3367e4 25%, #3367e4 0%, #b550f9 70%);
            color: #ffffff !important;
            font-weight: 500 !important;
            font-size: 14px !important;
            text-transform: capitalize !important;
            padding: 6px 20px !important;
          }
        }

        .ContBtn {
          text-transform: capitalize !important;
          background: linear-gradient(60deg, #3367e4 25%, #3367e4 0%, #b550f9 70%);
          color: var(--white) !important;
          font-size: 16px !important;
          margin: 20px 0 0px !important;
          padding: 11px 0 !important;
          font-weight: 500 !important;
          width: 100%;
          border: 0px !important;
        }
      }
    }
  }
}

.Upload-Max-View {
  flex: 0 0 329px;
  max-width: 329px;

  .BoothZoneFileAdd {
    .NetworkingSetupop {
      padding: 0px 0 16px;
      position: relative;

      .uplodLogo {
        label {
          font-weight: 400;
          font-size: 12px;
          color: #3f4254;
          margin: 0 0 10px;
          font-family: "Poppins", sans-serif !important;
        }

        .minHeightBox {
          height: 185px !important;
          min-height: 185px !important;
          border: 1px #1A4788 dashed !important;
          border-radius: 10px;
          margin: 5px 0 0 !important;

          .UploaderFiles {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;

            .UploadLabel {
              font-weight: 400;
              font-size: 12px;
              line-height: 17px;
              color: #3f4254;
              margin: 0 0 10px;

              .img-fluid {
                width: 50px;
                margin: 0 0 10px;
                border-radius: 0px !important;
              }
            }
          }

          .drop-zone-img-preview {
            padding: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;

            .upload-icons {
              position: absolute;
              top: 32px !important;
              left: auto !important;
              right: 12px !important;
              width: 25px;
              background: #f5f5f5;
              height: 25px;
              border-radius: 100px;
              padding: 3px;
              display: flex;
              align-items: center;
              justify-content: center;

              svg {
                width: 100%;
                height: 100%;
              }
            }

            .dzu-previewFileName {
              font-family: "Poppins", sans-serif !important;
              word-break: break-word;
              text-align: center;
            }
          }
        }
      }
    }
  }
}

.All_New_Added_File {
  margin-top: 12px;

  ul {
    padding: 0;
    margin: 0;
    margin-left: -5px;

    li {
      background-color: #f1f1f1;
      padding: 6px 10px;
      display: inline-flex;
      align-items: center;
      position: relative;
      margin: 5px 5px;
      font-weight: 400;
      font-size: 13px;
      border-radius: 4px;
      box-shadow: 0px 0px 5px rgb(0 0 0 / 18%);
      border: 1px #e1dddd solid;

      span {
        margin-left: 14px;
        background-color: #f00;
        width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px 3px;
        border-radius: 4px;
        cursor: pointer;

        svg {
          color: #fff;
          fill: #fff;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.EditInfoDetails {
  .UpdateOvelayImgVodeo {
    .minHeightBox {
      border: 1px #1A4788 dashed !important;
      height: 176px !important;
      min-height: 176px !important;
      border-radius: 10px;
      margin: 0px 0 0 !important;

      .UploaderFiles {
        height: 100%;
        width: 100%;
        padding: 0px 0;

        .UploadLabel {
          font-weight: 500 !important;
          font-size: 10px !important;
          line-height: 14px;
          width: 100%;
          height: 100%;
          padding: 16px 0 !important;
          margin: 0 !important;

          span {
            color: #237be2;
          }

          .img-fluid {
            margin: 0 0 11px;
            width: 42px;
            border-radius: 0px !important;
          }
        }
      }
    }

    .minHeightBox {
      .default-image-selected {
        position: relative;
        padding: 0 !important;

        .UploadLabel {
          margin: 0px !important;
          margin-top: 0px !important;
          padding: 0px !important;
          border: 0px;
          font-weight: 500;
          font-size: 11px;
          line-height: 17px;

          .uploadImgIcon {
            position: absolute;
            height: 24px;
            right: 10px !important;
            top: 11px !important;
            background-color: var(--light-gray);
            border-radius: 100px;
            display: flex;
            align-items: center;
            cursor: pointer;
            z-index: 5;

            .UploadDeleteIco {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0px;
              width: 24px;
              height: 24px;
              background-color: var(--light-gray);
              border-radius: 100px;

              svg {
                width: 100%;
                height: 100%;
                fill: #000;
              }
            }
          }

          .uploadPreviewIcon {
            position: absolute;
            height: 24px;
            right: 46px !important;
            top: 11px !important;
            background-color: #caafaf;
            border-radius: 100px;
            display: flex;
            align-items: center;
            cursor: pointer;

            .UploadPreviewIco {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 1px;
              width: 24px;
              height: 24px;
              background-color: var(--light-gray);
              border-radius: 100px;

              svg {
                width: 100%;
                height: 100%;
                fill: #000;
              }
            }
          }

          .img-fluid {
            margin: 0 0 0px !important;
            width: 100% !important;
            height: 100% !important;
            object-fit: scale-down;
          }

          video {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

.EventChatAccords {
  .nav-tabs {
    display: none;
  }

  .InfoPeopleChat {
    height: calc(100vh - 132px) !important;
    padding: 0px !important;

    .ChatInfos {
      height: calc(100vh - 215px) !important;
      padding: 35px 18px 0px !important;
      margin-top: 12px;
    }
  }

  .responsive-tabs-container {
    h2 {
      font-size: 16px;
      margin: 0;
      border-bottom: 1px #d1d1d1 solid;
      padding: 6px 20px 17px;
      width: 100%;
    }
  }

  .CloseButton {
    top: 12px !important
  }
}

.ExpoCoversChats {
  .PeopleChatPopup {
    .StateEventDtl {
      height: calc(100vh - 160px);

      .ChatInfos {
        height: calc(100vh - 232px) !important;
        padding: 36px 18px 0;
      }

      .PollInfos {
        height: calc(100vh - 160px) !important;

        .PollMhAuto {
          height: calc(100vh - 160px) !important;
        }

        .NowPollCreating {
          .btn {
            margin-bottom: 4px;
          }
        }
      }

      .QnaInfos {
        height: calc(100vh - 162px) !important;

        .QnaInfosPols {
          height: calc(100vh - 252px) !important;
        }
      }

      .EventInfoStage {
        height: calc(100vh - 172px);

        .ChatInfos {
          height: calc(100vh - 248px) !important;
          padding: 36px 18px 0;
        }

        .PollInfos {
          height: calc(100vh - 172px) !important;

          .PollMhAuto {
            height: calc(100vh - 280px) !important;
          }

          .archieved {
            height: calc(100vh - 220px) !important;
          }

          .NowPollCreating {
            .btn {
              margin-bottom: 4px;
            }
          }
        }

        .QnaInfos {
          height: calc(100vh - 216px) !important;

          .QnaInfosPols {
            height: calc(100vh - 235px) !important;
          }
        }
      }
    }

    .nav-tabs {
      min-height: 85px;
    }
  }

  .SlideCommonPopup {
    .nav-tabs {
      min-height: auto;
      padding: 0px 10px 4px !important;
      height: 64px;

      li {
        margin: 0 0 5px !important;
      }
    }

    .StateEventDtl {
      .nav-tabs {
        min-height: auto !important;
      }
    }
  }
}

.PersonInfoChat {
  position: absolute;
  width: 110px;
  background-color: #45494c;
  border: 1px #f0f0f0 solid;
  border-radius: 6px;
  text-align: center;
  padding: 3px 4px;
  left: -5px;
  top: -42px;
  display: none;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 26px;

  h5 {
    font-size: 9px;
    color: #f0f0f0;
    margin: 0;
    font-weight: 400;
    position: relative;
    z-index: 5;
  }

  p {
    font-size: 9px !important;
    color: #f0f0f0 !important;
    margin: 0 !important;
    font-weight: 300 !important;
    position: relative;
    z-index: 5;
    line-height: initial;
  }

  &::after {
    content: "";
    height: 12px;
    width: 12px;
    position: absolute;
    background-color: #45494c;
    bottom: -5px;
    border-bottom: #dcd8d8 solid 0px;
    border-right: #DCD8D8 solid 0px;
    transform: rotate(45deg);
    z-index: 0;
    left: 20%;
  }
}




.FileDownloadView {
  z-index: 99999;

  .modal-dialog {
    max-width: 94% !important;
    height: 97% !important;

    .modal-content {
      width: 100%;
      height: 100%;
      border-radius: 10px !important;

      .modal-header {
        padding: 16px 30px 0;
        border: 0;

        .modal-title {
          h4 {
            font-size: 18px;
          }
        }

        .MyMeeting {
          padding: 8px 13px 8px 8px;
        }
      }

      .modal-body {
        width: 100%;
        height: 100%;

        .VideoBroadcastLive {
          width: 100%;
          height: 100%;
          text-align: center;

          div {
            width: 100%;
            height: 100%;

            iframe {
              width: 100%;
              height: 100%;
            }

            video {
              width: 100%;
              height: 100%;
            }
          }

          iframe {
            width: 100%;
          }

          img {
            width: 100%;
            height: 100%;
          }
        }

        .FullYourProfile {
          iframe {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

.InviteTableCall {
  padding-top: 0px !important;

  .headingTop {
    border-bottom: 1px #d1d1d1 solid;
    padding: 18px 8px;
    margin: 0 10px;

    h2 {
      margin: 0;
      display: inline-block;
      font-size: 16px;
      font-weight: 500;
      color: #1A78E5;

      img {
        width: 15px;
        height: 15px;
        display: inline-block;
        margin-right: 8px;
      }
    }

    .CloseButton {
      padding: 0;
      width: 28px;
      height: 28px;
      position: absolute;
      right: 14px;
      top: 16px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .ConvertionPepple {
      padding: 0;
      width: 24px;
      height: 24px;
      position: absolute;
      right: 58px;
      top: 16px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  .peopleInfos {
    height: calc(100vh - 125px);
    overflow-y: auto;
    padding: 12px 12px 0px;
    position: relative;
    scrollbar-width: thin;

    .PeopleAttendList {
      height: calc(100vh - 260px);
      overflow-y: auto;
      margin-top: 12px;
      padding-right: 14px;
      scrollbar-width: thin;

      .CheckPeopleList {
        border-bottom: 1px #d4d4d4 solid;
        align-items: center;

        .notification-checkbox {
          margin-right: 14px;
        }

        .PeopleList {
          display: flex;
          padding: 10px 0;
          cursor: pointer;
          position: relative;
          align-items: center;

          .notification-checkbox {
            margin-right: 12px;

            input {
              height: 14px;
              width: 14px;
            }
          }

          .PeopleImg {
            width: 38px;
            height: 38px;
            border-radius: 100px;
            background-color: #dadada;
            position: relative;

            img {
              width: 38px;
              height: 38px;
              border-radius: 100px;
            }
          }

          .AboutPeople {
            margin-left: 10px;
            padding: 5px 0;
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: left;
            width: 85%;

            h4 {
              font-size: 14px;
              margin: 0 0 3px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }
          }

          .ActiveGreen {
            display: none;
          }
        }
      }

    }
  }
}

.AvailableInvite {
  width: 92%;
  position: absolute;
  bottom: 9px;
  right: 0;
  background: #fff;
  margin: 0px auto;
  left: 0;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 14px;
  font-size: 13px;
  color: #333;
  font-weight: 500;

  .BtnType {
    color: #fff;
    border-radius: 100px;
    font-size: 12px;
    padding: 4px 12px;
  }

  p {
    margin: 0;
  }
}

.CripViewImages {
  .modal-dialog {
    max-width: 530px;
    height: 100%;
    z-index: 46;

    .modal-content {
      width: 100%;
      height: auto;
      border-radius: 6px !important;

      .modal-body {
        height: 285px;
        background: #d6d6d6;
        width: 96%;
        margin: 10px auto 10px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        div {
          height: 100%;

          img {
            height: 100%;
          }
        }

        .ReactCrop__crop-selection {
          div {
            height: auto !important;
          }
        }
      }

      // ReactCrop__rule-of-thirds-vt
      .modal-header {
        padding: 16px 30px 0;
        margin-top: 10px;
        border: 0;
      }

      .modal-footer {
        justify-content: center;

        .btn {
          width: 135px;
          color: #fff;
          border-radius: 4px;
        }

        .CropImg {
          background-color: #237be2;
          border: 1px #237be2 solid;
        }

        .ChangeImg {
          background-color: #4e4e4e;
          border: 1px #4e4e4e solid;
          text-transform: capitalize;
        }
      }
    }
  }

  &:after {
    content: "";
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgb(0 0 0 / 85%);
    height: 100%;
  }
}

.AddFilePopup {
  .modal-dialog {
    margin: 0% auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .modal-content {
      padding: 35px 18px 18px;
      border-radius: 16px;

      .modal-header {
        padding: 0 0 12px;
        border-bottom: 0px;

        .modal-title {
          h4 {
            color: #333;
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 0.5px;
            margin: 0;
            font-family: "Poppins", sans-serif !important;
          }
        }

        .ModalCloseBtn {
          position: absolute;
          right: 8px;
          padding: 0;
          display: block;
          top: 8px;
          min-width: 25px;

          .MuiButton-label {
            width: auto;
            padding: 0;
            display: block;
            position: relative;
            height: 25px;
            width: 25px;
            line-height: 0;
            float: right;
          }

          svg {
            width: 100%;
            height: 100%;
            opacity: 0.5;
          }

          &:hover {
            background-color: transparent;
          }

          &:focus {
            background-color: transparent;
          }
        }

        .btn-close {
          display: none;

          span {
            display: none;
          }
        }
      }

      .modal-body {
        padding: 0 0;

        .GroupForm {
          position: relative;

          .LimitTxt {
            position: absolute;
            right: 4px;
            top: -17px;
            font-weight: 500;
            font-size: 11px;
            color: #a7a4a4;
          }

          label {
            position: absolute;
            left: 14px;
            top: 9px;
            font-size: 12px;
            letter-spacing: 0.1px;
            color: #808080;
            margin: 0;
            z-index: 4;
            font-family: "Poppins", sans-serif !important;
          }

          .form-control {
            background-color: var(--light-gray);
            border-radius: 6px;
            padding: 20px 14px 6px !important;
            font-size: 14px;

            input {
              font-family: "Poppins", sans-serif !important;
              font-size: 12px;

              &:focus {
                background-color: transparent !important;
              }
            }
          }

          .MuiInputBase-root {
            &:after {
              display: none;
            }

            &:before {
              display: none;
            }

            textarea {
              height: 40px;
            }
          }

          .LimitDivTxg {
            position: absolute;
            font-size: 11px;
            font-weight: 500;
            right: 10px;
            top: 6px;
            color: #696969;
          }
        }

        .ConfirmAction {
          h4 {
            font-size: 15px;
            font-weight: 500;
            margin: 0 0 28px;
          }
        }

        .form-group {
          .filesAll {
            position: relative;
            z-index: 6;

            label {
              color: #333;
              font-size: 15px;
              font-weight: 500;
              letter-spacing: 0.5px;
              margin: 0 0 10px;
              font-family: "Poppins", sans-serif !important;
            }
          }

          .SelectDropdown {
            &::after {
              display: none;
            }
          }
        }

        .Upload-Max-View {
          .BoothZoneFileAdd {
            .NetworkingSetupop {
              .uplodLogo {
                .minHeightBox {
                  .drop-zone-img-preview {
                    padding: 0px !important;
                  }
                }
              }
            }
          }
        }
      }

      .modal-footer {
        padding: 0 0;

        .DeleteCancleRoom {
          width: 100%;
          text-align: center;
          margin: 18px 0 0;

          .WhiteBtn {
            background-color: #ffffff !important;
            color: #007bff !important;
            font-weight: 500 !important;
            font-size: 14px !important;
            text-transform: capitalize !important;
            border: 1px #F1F1F1 solid !important;
            padding: 6px 28px !important;
            margin-right: 12px;

            &:hover {
              outline: none !important;
              box-shadow: none !important;
              background-color: transparent;
            }

            &:focus {
              outline: none !important;
              box-shadow: none !important;
              background-color: transparent;
            }
          }

          .CommonGtBtn {
            background: linear-gradient(60deg, #3367e4 25%, #3367e4 0%, #b550f9 70%);
            color: #ffffff !important;
            font-weight: 500 !important;
            font-size: 14px !important;
            text-transform: capitalize !important;
            padding: 6px 20px !important;
          }
        }

        .ContBtn {
          text-transform: capitalize !important;
          background: linear-gradient(60deg, #3367e4 25%, #3367e4 0%, #b550f9 70%);
          color: var(--white) !important;
          font-size: 16px !important;
          margin: 20px 0 0px !important;
          padding: 11px 0 !important;
          font-weight: 500 !important;
          width: 100%;
          border: 0px !important;
        }
      }
    }
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.UserEditInfos {
  .FullYourProfile {
    padding-left: 14px;

    .ProfileEditPic {
      .ProfileOverflow {
        padding-left: 0px !important;
        padding-right: 14px !important;
        height: 338px !important;
        padding-top: 4px;

        .PicProdileUp {
          flex: 0 0 310px !important;
          max-width: 310px !important;
          border: 1px #333333 dashed !important;
          border-radius: 10px;
          padding: 12px 15px;
          margin-bottom: 18px;

          .minHeightBox {
            height: 115px !important;
            min-height: 115px !important;
            flex: 0 0 115px !important;
            max-width: 115px !important;
            background-color: #f1f1f1;
            border-radius: 100px;
            border: 0px !important;
            overflow: initial !important;

            .UploaderFiles {
              .UploadLabel {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 0px !important;

                img {
                  margin: 0px !important;
                }
              }
            }

            .drop-zone-img-preview {
              position: inherit !important;

              img {
                border-radius: 100px;
                object-fit: cover;
                position: inherit !important;
              }

              .dzu-previewStatusContainer {
                .upload-icons {
                  width: 35px;
                  height: 35px;
                  background-color: #fff;
                  border: 2px #ed374f solid;
                  box-shadow: 0px 0px 10px #0003;
                  right: 77px !important;
                  top: 50% !important;
                  transform: translateY(-50%);

                  svg {
                    fill: #ed374f;
                  }
                }
              }
            }

            .default-image-selected {
              position: inherit !important;

              .UploadLabel {
                color: #000 !important;

                img {
                  border-radius: 100px !important;
                  object-fit: cover !important;
                }

                .uploadImgIcon {
                  right: 48px !important;
                  top: 50% !important;
                  transform: translateY(-50%);

                  .UploadDeleteIco {
                    width: 35px;
                    height: 35px;
                    background-color: #fff;
                    border: 2px #ed374f solid;
                    box-shadow: 0px 0px 10px #0003;

                    svg {
                      fill: #ed374f;
                    }
                  }
                }

                .uploadPreviewIcon {
                  right: 95px !important;
                  top: 50% !important;
                  transform: translateY(-50%);

                  .UploadPreviewIco {
                    width: 35px;
                    height: 35px;
                    background-color: #fff;
                    border: 2px #1a78e5 solid;
                    box-shadow: 0px 0px 10px #0003;
                  }

                  svg {
                    fill: #1a78e5;
                  }
                }

              }
            }
          }

          .minHeightBox {
            margin: 0px !important;

            .UploaFileStage {
              .UploadLabel {
                span {
                  position: absolute;
                  width: 177px;
                  right: 0;
                  top: 50% !important;
                  transform: translateY(-50%);
                  font-size: 12px !important;
                  margin: 10px 0 0;

                  span {
                    position: initial !important;
                  }

                  p {
                    font-size: 12px !important;
                  }
                }
              }
            }
          }
        }
      }

      .ProfileOverflow {
        .EditInfoDetails {
          label {
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 4px;
            color: #676767;
          }
        }
      }
    }
  }
}

.UserEditInfos {
  .modal-dialog {
    .modal-content {
      .modal-header {
        padding: 22px 30px 6px !important;
      }
    }
  }
}

.widgetContainer {
  .RightBrowseWidget {
    .WidgetBrowseList {
      .items-parent {
        .OtherWidgets {
          height: calc(100vh - 80px) !important;

          .widget-inputs-wrapper {
            .AddImgMore {
              margin: 0px 0 30px !important;
            }
          }

          .FooterButton {
            .CommonGtBtn {
              font-weight: 500 !important;
              text-transform: uppercase !important;
            }
          }
        }
      }
    }
  }
}

.maxScreenHeight.StageRecordingView {
  margin-top: 0px !important;
}

.StageRecordingView {
  height: 100vh !important;
  margin-top: 0px !important;

  .scale-blur-fix {
    height: 100vh !important;

    .BlurFullImgs {
      height: 100vh !important;
    }
  }

  .audience-front-stage-roll {
    .container-fluid {
      padding: 0px !important;

      .MainViewBackStage {
        height: 100vh !important;
        overflow: hidden;
        .alignAllDiv {
          .front-stage-stream-scroll {
            .front-stage-share-screen {
              .stage-user-list {
                height: 94%!important;
              }
            }
          }
        }
      }
    }
  }
}

.RecordLiveStream {
  position: absolute;
  left: 14px;
  top: 6px;
  z-index: 6;

  .liveRecord {
    width: 55px;
    font-size: 12px;
    height: 20px;
    background-color: #ea4335;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    color: #fff;
    border: 1px #EA4335 solid;
    padding: 3px 8px;
    line-height: 26px;
    font-weight: 400;
    z-index: 6;

    .LiveIcon {
      width: 10px;
      height: 10px;
      display: flex;
      margin-right: 3px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  .live-recording {
    width: 100px;
    font-size: 12px;
    height: 22px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    color: #fff;
    line-height: 26px;
    font-weight: 400;
    background-color: rgb(0 0 0 / 28%);
    padding: 2px 8px;
    z-index: 6;

    .RecordIcon {
      width: 14px;
      height: 14px;
      display: flex;
      margin-left: 5px;

      svg {
        width: 100%;
        height: 100%;
        animation: blinker 1.2s linear infinite;
      }
    }
  }
}


.customer-chat {
  position: fixed;
  bottom: 22px;
  right: 22px;
  width: 68px;
  height: 68px;
  cursor: pointer;
  z-index: 9;

  svg {
    width: 100%;
    height: 100%;
  }
}

.tooltip .tooltip-inner {
  font-size: 12px;
  white-space: normal;
  width: auto !important;
  max-width: 100%;
}



.MuiPopover-root {
  .MuiMenu-paper {
    ul {
      li {
        font-size: 14px !important;
        font-family: "Poppins", sans-serif !important;
        border-bottom: 1px #f0f0f0 solid !important;
        padding: 8px 20px !important;
        font-weight: 400;

        &:last-child {
          border-bottom: 0px !important;
        }

        .MuiButtonBase-root {
          padding: 0 !important;
        }

        .PrivateSwitchBase-checked-2 {
          span {
            svg {
              color: #0475ff;
            }
          }
        }

        .Mui-checked {
          span {
            svg {
              color: #0475ff;
            }
          }
        }

        .MuiListItemText-root {
          padding: 0px 8px !important;

          span {
            font-size: 14px !important;
            font-family: "Poppins", sans-serif !important;
            font-weight: 400;
          }
        }
      }
    }
  }
}

.MuiSelect-select {
  &:focus {
    background-color: transparent !important;
  }
}

.LeftBarToggle {
  display: none;
}

.ThankyouPopUp {
  .modal-dialog {
    .modal-content {
      .modal-header {
        padding: 0;

        .modal-title {
          display: none;
        }

        .ModalCloseBtn {
          z-index: 5;
        }
      }

      .modal-body {
        .ThankyouNow {
          text-align: center;
          height: 260px;
          display: flex;
          align-items: center;
          justify-content: center;

          .SuccessThankx {
            svg {
              width: 48px;
              height: 48px;
            }

            h3 {
              color: #3477F3;
              text-transform: uppercase;
              font-weight: 600;
              font-size: 30px;
              margin: 15px 0 10px;
            }

            p {
              color: #222222;
              font-size: 15px;
              font-weight: 300;
              margin: 0px 0;
            }
          }
        }
      }
    }
  }
}

.LeftRegTop {
  height: auto;
  position: relative;
}

.RegisterPageAdvance .container .row .LoginRegisterPage .EnterEventAdvance::-webkit-scrollbar {
  width: 2px !important;
}

.loader {
  font-size: 14px;
  margin: 10px 0 0;
}



.SideBarHeading {
  .CloseButton {
    padding: 0 0;
    width: 26px;
    height: 26px;
    position: absolute;
    right: 8px;
    top: 10px;
    z-index: 9;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  h2 {
    font-size: 16px;
    margin: 0;
    border-bottom: 1px #d1d1d1 solid;
    padding: 14px 20px;
    width: 100%;
    color: var(--blue);
    font-weight: 400;
  }
}

.TopMenuList {
  padding: 0 0px;
  border-bottom: 1px #2e2e2e solid;
}

.FixSideMenu {
  position: fixed;
  width: 65px;
  height: 100vh;
  background-color: var(--black);
  z-index: 99;
  top: 50px;

  .StatuSideMenu {
    width: 100%;
    display: block;
    text-align: center;
    height: 100%;
    padding: 35px 0px;

    .MenuIconList {
      ul {
        margin: 0px;
        padding: 0px;

        li {
          display: block;
          margin: 0 0 20px;
          cursor: pointer;
          position: relative;

          svg {
            width: 26px;
            fill: #adadad;

            circle {
              stroke: #adadad;
            }
          }

          &.active-bar {
            svg {
              fill: #fff;

              circle {
                stroke: #fff;
              }
            }

            &:after {
              content: '';
              width: 3px;
              background-color: #fff;
              height: 100%;
              position: absolute;
              left: 0;
            }
          }

          .badge {
            display: block;
            padding: 1px 0 !important;
            width: 10px;
            height: 10px;
            border-radius: 100px;
            position: absolute;
            top: -8px;
            left: 0;
            right: 0;
            margin: 0px auto !important;
            text-decoration: blink;
            animation: blinker 1s linear infinite;
          }
        }
      }
    }

    .BottomMenuList {
      margin-top: 24px;
    }
  }
}

.SlideBarTransform {
  position: absolute;
  height: calc(100vh - 62px);
  top: 9px;
  width: 340px;
  background: var(--light-gray);
  z-index: 16;
  border-radius: 10px;
  transform: translateX(-30rem);
  left: 3.5rem;
  transition: all 0.6s ease;
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  border-right: 0.85px solid rgba(255, 255, 255, 0.165);
  border-image: initial;
  box-shadow: rgb(0 0 0 / 2%) 0px 10px 6.2px -4.95px, rgb(0 0 0 / 2%) 0px 0px 10.2px 0.55px;
  border: 0px;

  .BackstageCommLt {
    width: 100%;
    padding: 0px;

    .BsHeightFixLx {
      overflow: auto;
      overflow-y: auto;
      flex-flow: row nowrap;
      align-items: center;
      position: relative !important;
      height: calc(100vh - 114px);
      scrollbar-width: thin;
      width: 100%;

      .peopleInfos {
        height: 100%;
      }

      .back-stage-stream-scroll {
        width: 100%;
        padding: 16px;
        float: left;

        .list-null {
          margin-bottom: 15px;

          .video_footer {
            .ShortName {
              width: 100%;
              height: calc(100% - 38px);
            }

            .mx-user-role {
              position: absolute;
              bottom: 34px;
              left: 0;
              background-color: #eb354d;
              padding: 0px 6px;
              color: #fff;
              border-radius: 0 4px 0 0;
              font-weight: 400;
              height: 22px;
              display: flex;
              align-items: center;
              justify-content: center;

              small {
                font-size: 10px;
                color: #fff;
              }
            }
          }

          &:last-child {
            margin-bottom: 0px;
          }
        }

        .video_footer {
          width: 100% !important;
          //height: 180px !important;
          position: relative !important;
          background-color: var(--dark-gray);
          border-radius: 10px;
          border: 2px #f0f0f0 solid;

          .localPlayer {
            width: 100% !important;
            height: 100% !important;
            position: relative !important;
            aspect-ratio: 16/9;

            img {
              width: 100%;
            }

            div {
              border-radius: 10px 10px 0px 0px;
              position: relative;
              background: rgb(0 0 0 / 11%) !important;

              video {
                position: relative !important;
                border-radius: 0px;
              }
            }

            .userLoginBg {
              height: 100%;
            }
          }

          .bottom_footer {
            .NameExpFull {
              //position: absolute;
              display: flex;
              bottom: 0;
              width: 100%;
              align-items: center;
              justify-content: space-between;
              background-color: #ffffff;
              border-radius: 0 0 8px 8px;
              padding: 10px 16px;
              position: relative;
              z-index: 20;

              h4 {
                margin: 0;
                font-size: 12px;
                font-weight: 500;
                width: 74%;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;

                small {
                  font-size: 12px;
                  font-weight: 400;
                  color: #120120;
                  vertical-align: middle;
                }
              }

              .RightSvgIcon {
                display: inherit;

                span {
                  margin-left: 7px;
                  display: inline-flex;
                  width: 22px;
                  height: 22px;
                  align-items: center;
                  justify-content: center;

                  &:first-child {
                    margin-left: 0;
                  }

                  &:last-child {
                    padding: 2px;
                  }

                  svg {
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }

            .activeSpeaker {
              padding-left: 8px;

              h4 {
                padding-left: 12px;
                position: relative;

                .ActiveGreen {
                  position: absolute;
                  width: 8px;
                  height: 8px;
                  left: 0px;
                  top: 4px;
                  ;
                }
              }
            }
          }

          .BackIconAction {
            left: 8px !important;
            right: auto !important;

            .ActionLive {
              width: 18px;
              height: 18px;

              .widthSvg {
                padding: 3px 3px;
              }
            }
          }
        }
      }

      &::-webkit-scrollbar {
        height: 4px;
      }

      .UploadBroadcastImgs {
        padding: 16px;

        .BackGroundBgUpload {
          margin: 12px 0 0;
        }

        .StageImgvideoOverlay {
          .TitleAndTrash {
            margin: 12px 0;
            position: relative;
            z-index: 9;
          }
        }

        .UpdateOvelayImgVodeo {
          width: 100%;

          .img-fluid {
            border-radius: 4px;
          }

          .form-group {
            margin: 0 0 15px !important;

            .form-control {
              width: 100%;
              border: 1px #f1f1f1 solid;
              border-radius: 100px;
              padding: 12px 20px;
              font-size: 14px;
              background-color: #ffffff;

              &:hover {
                outline: none;
                box-shadow: none;
              }

              &:focus {
                outline: none;
                box-shadow: none;
              }
            }

            .VideoBroadcastLive {
              aspect-ratio: 16/9;

              video {
                border-radius: 10px;
                aspect-ratio: 16/9;
              }

              iframe {
                border-radius: 10px;
                aspect-ratio: 16/9;
              }
            }
          }

          p {
            font-size: 12px;
            color: #000;
            font-weight: 400;

            span {
              cursor: pointer;
            }
          }
        }

        .BroadcastMedia {
          cursor: pointer;
          text-align: center;
          width: 100%;
          margin: 10px auto 10px;

          span {
            font-size: 14px;
            font-weight: 500;
            color: #363636;
            background-color: #ffffff;
            padding: 10px 10px;
            border-radius: 6px;
            width: 100%;
            display: block;
            box-shadow: 0px 0px 5px rgb(0 0 0 / 25%) !important;
          }
        }

        .form-group {
          text-align: center;
          position: relative;
          margin: 0;

          .ClickBroadcast {
            cursor: pointer;
            text-align: center;
            width: 100%;
            margin: 15px auto 10px;

            span {
              font-size: 15px;
              font-weight: 500;
              color: #363636;
              background-color: #ffffff;
              padding: 10px 10px;
              border-radius: 6px;
              width: 100%;
              display: block;
            }

            .Broadcasting {
              font-size: 15px;
              font-weight: 500;
              color: #363636;
              background-color: #ffffff;
              padding: 10px 10px;
              border-radius: 6px;
              width: 100%;
              display: block;
              border: 0;
              margin: 0 0 10px;
              box-shadow: 0px 0px 5px rgb(0 0 0 / 18%) !important;
            }
          }

          .StopsBroadcast {
            span {
              background-color: #ed374f !important;
              color: #f1f1f1 !important;
            }
          }

          .DelBroadcastItem {
            position: absolute;
            top: 8px;
            right: 40px;
            width: 22px;
            height: 22px;
            z-index: 9;
            background: #fff;
            border-radius: 100px;
            padding: 0 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            span {
              display: inline-flex;

              svg {
                width: 100%;
                height: 100%;
                fill: #eb354d;
              }
            }
          }

          .StageImgvideoOverlay {
            padding: 10px 0px 4px;
            width: 100%;

            .TitleAndTrash {
              width: 100%;

              .form-control {
                width: 100%;
                background-color: #fff;
                border: 1px #d6d3d3 solid;
                border-radius: 100px;
                height: 42px;
                padding-left: 18px;
                font-size: 13px;

                &:hover {
                  outline: 0;
                  box-shadow: none;
                }

                &:focus {
                  outline: 0;
                  box-shadow: none;
                }
              }
            }

            .TrashOverlayImgVideos {
              width: 20px;
              height: 20px;
              text-align: center;

              .deleteMode {
                padding: 0 0;
                background: transparent;
                border: 0;

                &:hover {
                  outline: none;
                  border: 0;
                  box-shadow: none;
                  background: transparent;
                }

                &:focus {
                  outline: none;
                  border: 0;
                  box-shadow: none;
                  background: transparent;
                }
              }

              img {
                width: 13px;
              }
            }
          }

          .UpdateOvelayImgVodeo {
            width: 100%;

            .minHeightBox {
              margin-top: 14px !important;
            }

            .img-fluid {
              border-radius: 4px;
            }

            .form-control {
              width: 100%;
              border: 1px #f1f1f1 solid;
              border-radius: 100px;
              padding: 12px 20px;
              font-size: 14px;
              background-color: #ffffff;

              &:hover {
                outline: none;
                box-shadow: none;
              }

              &:focus {
                outline: none;
                box-shadow: none;
              }
            }

            p {
              font-size: 12px;
              color: #000;
              font-weight: 500;

              span {
                cursor: pointer;
              }
            }
          }

          img {
            border-radius: 6px;
          }
        }

        .UpdateOvelayImgVodeo {
          .minHeightBox {
            .UploaFileStage {
              .UploadLabel {
                margin-top: 0px !important;

                .img-fluid {
                  margin-top: 26px !important;
                }
              }
            }
          }
        }
      }

      .responsive-tabs-container {
        .nav-tabs {
          li {
            width: 50%;
            margin-bottom: 0px !important;

            .nav {
              .nav-link {
                padding: 6px 20px;
                position: relative;

                &.active {
                  padding: 6px 20px;
                }

                .badge {
                  top: 3px !important
                }
              }

              .nav-link {
                color: #5d5d5d;
                border-bottom: 2px transparent solid;
                background-color: transparent !important;
                box-shadow: none !important;
                border-radius: 0px !important;
                padding: 14px 0;
                width: 100%;
                border: 0;
                text-align: center;
                cursor: pointer;

                &.active {
                  border-bottom: 3px #1a78e5 solid;
                  padding: 14px 0;
                  background-color: transparent !important;
                  box-shadow: none !important;
                  border-radius: 0px !important;
                  width: 100%;
                  color: #333;
                }
              }
            }
          }
        }
      }

      .EventInfoStage {
        .QnaInfos {
          height: calc(100vh - 188px) !important;
          position: relative;
          padding: 0px 0 !important;

          .QnaInfosPols {
            height: calc(100vh - 268px);
            overflow-y: auto;
            overflow-x: hidden;
            padding: 0 18px;
            scrollbar-width: thin;
            margin: 18px 0 16px;

            .QuestionVote {
              padding: 18px 18px !important;
              background-color: #fff;
              border-radius: 10px;
              box-shadow: 0px 0px 6px rgb(0 0 0 / 5%);
              position: relative;

              .DotToggle {
                right: 10px;
                top: 15px;
              }

              .ShowAnAQue {
                width: calc(100% - 35px);
                display: flex;
                align-items: center;

                .QnAuserImg {
                  width: 35px;
                  height: 35px;
                  background-color: #363636;
                  background-color: var(--dark-gray);
                  border-radius: 100px;
                  margin-right: 10px;
                  display: contents;

                  .img-fluid {
                    border-radius: 100px;
                    width: 35px;
                    height: 35px;
                  }
                }

                .NameQnAuser {
                  margin-left: 10px;
                  padding: 5px 0;
                  display: flex;
                  justify-content: center;
                  flex-direction: column;
                  text-align: left;
                  width: 85%;

                  h4 {
                    font-size: 14px;
                    font-weight: 500;
                    margin: 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    width: 160px;
                    color: #333;
                    ;
                  }
                }

              }

              p {
                font-size: 13px;
                line-height: 20px;
                color: var(--dark-gray);
                letter-spacing: 0.2px;
                margin: 13px 0 4px;
              }

              .QnaVoteCount {
                background: transparent;
                padding: 4px 0px;
                border-radius: 0;
                margin: 4px 0 0;
              }

              .ClickBroadcast {
                .GrayButton {
                  padding: 8px 32px;
                  font-size: 15px;
                  margin: 12px 0 0;
                }
              }
            }
          }

          .QnaSessionEnds {
            width: 90%;
            margin: 0 auto 0;
          }
        }
      }

      .StageInfoBar {
        padding: 16px;

        .accordion {
          .accordion-item {
            .accordion-header {
              margin-bottom: 6px;
            }

            .accordion-collapse {
              .accordion-body {
                .form-group {
                  margin-bottom: 0px !important;
                }

                .StageExtras {
                  margin-top: 0px;
                }

                .ControlPanelType {
                  .ControlPanel {
                    display: inline-block;
                    margin: 0 4px;
                    font-size: 13px;
                    font-weight: 400;
                    text-align: center;
                  }
                }
              }
            }
          }
        }
      }

      .PeopleList {
        display: flex;
        padding: 10px 12px;
        border-bottom: 1px #d4d4d4 solid;
        position: relative;

        .PeopleImg {
          width: 38px;
          height: 38px;
          border-radius: 100px;
          background-color: #dadada;
          position: relative;

          img {
            width: 100%;
            height: 100%;
            border-radius: 100px;
          }
        }

        .AboutPeople {
          margin-left: 10px;
          padding: 5px 0;
          display: flex;
          /* align-items: center; */
          justify-content: center;
          flex-direction: column;
          text-align: left;
          width: 85%;

          h4 {
            font-size: 14px;
            margin: 0 0 3px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }

          p {
            font-size: 12px;
            margin: 0;
            color: #383838;
          }
        }

        .pendingReqts {
          padding-right: 72px;

          .RequestAction {
            position: absolute;
            right: 10px;
            top: auto;
            display: flex;
            align-items: center;
            cursor: pointer;

            .AcptRjct {
              width: 24px;
              height: 24px;
              display: inline-flex;
              margin: 0 4px;
              padding: 0 !important;
              border: 0 !important;
              background-color: transparent !important;
              svg {
                width: 100%;
                height: 100%;
                fill: #333;
                color: #333;
              }
            }
          }
        }
      }

      .StageBackStage {
        .EventInfoStage {
          .PollInfos {
            height: calc(100vh - 172px);

            .PollMhAuto {
              height: calc(100vh - 300px);
            }
          }
        }
      }
    }
  }
}

.TransformActive {
  transform: translateX(0.6rem) !important;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.DotToggle {
  position: absolute;
  right: 4px;
  width: 32px;
  height: 32px;
  top: 4px;
  cursor: pointer;
  z-index: 93;

  svg {
    width: 100%;
    height: 100%;
  }

}

.RemindMe {
  position: absolute;
  z-index: 6;
  top: 8px;
  left: 8px;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 18%);
  cursor: pointer;

  span {
    background-color: #ffffff;
    font-size: 14px;
    padding: 2px 12px;
    border-radius: 100px;
  }
}

.ToogglePopover {
  //position: absolute;
  width: 220px;
  padding: 10px 0;
  background-color: #fff;
  border: 1px #707070 solid;
  border-radius: 6px;
  left: 52px;
  top: -10px;

  .DotsDdlList {
    position: relative;
    z-index: 9;

    ul {
      margin: 0px;
      padding: 0px;

      li {
        display: block;
        font-size: 14px;
        font-weight: 400;
        color: var(--dark-gray);
        padding: 8px 18px;
        cursor: pointer;

        span {
          display: inline-block;
          margin-right: 5px;
          width: 20px;
          height: 20px;

          //vertical-align: middle;
          svg {
            width: 16px !important;
            height: 16px !important;
            fill: #5d5e5d;
          }
        }

        &:hover {
          background-color: #efefef;
        }
      }
    }

    .ClickBroadcast {
      display: block;
      padding: 8px 16px;
      cursor: pointer;

      .PopupButton {
        background-color: transparent !important;
        color: var(--dark-gray);
        font-size: 14px;
        font-weight: 400;
        border: 0px;
        width: 100%;
        text-align: left;
        padding: 0 !important;

        span {
          margin-right: 6px;
          width: 18px;
          height: 18px;
          display: inline-flex;
          vertical-align: middle;

          svg {
            width: 100%;
            height: 100%;
            fill: #5d5e5d;
          }
        }
      }
    }
  }

  &::before {
    content: "";
    background-color: #ffffff;
    top: 16px;
    left: -8px;
    transform: rotate(-45deg);
    position: absolute;
    width: 16px;
    height: 16px;
    border-left: 1px solid;
    border-top: 1px solid;
    border-color: #707070;
  }
}

.mixhubbListLayout {
  height: auto;
  width: 100%;
  //position: relative;
  z-index: 8;
  //background: #f09090;
  clear: both;
  margin-top: 14px;

  .WrapmixhubbList {
    display: inline-flex;
    flex-direction: row;
    //padding: 0.75rem 0;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: thin;

    .ScrollmixhubbWrap {
      display: flex;
      margin: 0px 0 10px;

      .ListContentAdd {
        display: inline-flex;
        flex-shrink: 0;
        margin-right: 15px;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;

        .ListPreviewMix {
          position: relative;
          border-radius: 4px;
          overflow: hidden;
          background: #333333;
          color: white;
          font-size: 14px;
          font-weight: 400;
          width: 176px;
          height: 99px;
          cursor: pointer;
          opacity: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 0px;
          border: 2px transparent solid;

          .MainViewBackStage {
            float: left;
            width: 100%;
            display: inherit !important;
            height: 100% !important;
            position: relative;
            margin-left: 0px !important;
            // align-items: center;
            // justify-content: center;
            // flex-direction: column;

            .DivChangeWidthView {
              float: right;
              width: 100%;
              height: 100% !important;
              position: relative;
              margin: 0px !important;
              transition: all 0.6s ease;
              -webkit-transition: all 0.6s ease;
              -moz-transition: all 0.6s ease;
              -o-transition: all 0.6s ease;
              -o-transition: all 0.6s ease;
              display: flex !important;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              padding: 0;

              .ChangeWidthScroll {
                width: 100%;
                padding: 0;
                height: auto !important;

                .flexDicSeps {
                  width: 100% !important;
                  height: 100% !important;
                  background: transparent !important;

                  .RemoveAllDiv {
                    width: auto !important;
                    height: 100% !important;
                    display: flex !important;
                    aspect-ratio: 16/9;
                    margin: 0px auto;

                    .img-fluid {
                      position: absolute;
                      left: 0;
                      right: 0;
                      height: 100% !important;
                      width: 100% !important;
                      margin: 0px auto;
                    }

                    .front-stage-stream-scroll {
                      //position: absolute;
                      width: 85%;
                      height: auto;
                      left: 0;
                      right: 0;
                      top: 0;
                      margin: 0px auto;
                    }

                    img {
                      width: 100%;
                      height: 100%;
                    }
                  }

                  .alignAllDiv {
                    width: 100%;
                    height: 100% !important;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: calc(100vh - 310px);

                    .front-stage-stream-scroll {
                      aspect-ratio: 16/9;
                      margin: 0px auto 0;
                      padding: 0px 0px;
                      border-radius: 6px 6px 0px 0px;
                      // position: absolute;
                      // top: 0;
                      width: 100% !important;

                      // height: auto;
                      // left: 0;
                      // right: 0;
                      // background-color: rgb(0 0 0 / 50%);
                      .row {
                        height: 100%;
                        align-items: center;
                        width: 100%;
                        display: block;

                        .video_footer {
                          position: relative;
                          background: #000;
                          border: 1px #2e2e2e solid;
                          border-radius: 6px;

                          .bottom_footer {
                            display: none;
                          }
                        }

                        .VideoBroadcastLive {
                          position: absolute;
                          padding: 0px;
                          width: auto;
                          height: 100%;
                          left: 0;
                          margin: 0px auto;
                          right: 0;

                          div {
                            width: 100% !important;
                            height: 100% !important;
                            aspect-ratio: 16/9;
                          }
                        }

                        .ImageBroadcastLive {
                          width: 100%;
                          height: 100%;

                          .RatioDiv16 {
                            aspect-ratio: 16/9;
                            height: 100%;
                            width: 100%;
                            margin: 0px auto;

                            img {
                              //object-fit: scale-down;
                              position: initial !important;
                              border-radius: 10px;
                            }
                          }
                        }

                        .PDFBroadcastLive {
                          width: 100%;
                          height: 100%;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          position: relative;

                          .RatioDiv16 {
                            //aspect-ratio: 16/9;
                            height: 99%;
                            margin: 0px auto;
                            width: auto;

                            img {
                              //object-fit: scale-down;
                              position: initial !important;
                              //border-radius: 10px;
                              width: 100% !important;
                              height: 100% !important;
                            }
                          }
                        }

                        .front-stage-share-screen {
                          display: block !important;
                          height: 100%;

                          .VideoBroadcastLive {
                            position: absolute;
                            padding: 0px;
                            width: 100%;
                            height: 100%;
                            left: 0;
                            top: 0;

                            .alignBlockAuto {
                              display: inline-block;
                              width: auto !important;
                              aspect-ratio: auto !important;
                            }
                          }

                          .ImageBroadcastLive {
                            width: 100%;
                            height: 100%;

                            .RatioDiv16 {
                              aspect-ratio: 16/9;
                              height: 100%;
                              width: 100%;
                              margin: 0px auto;

                              img {
                                //object-fit: scale-down;
                                position: initial !important;
                                border-radius: 10px;
                              }
                            }

                          }

                          .PDFBroadcastLive {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            position: relative;

                            .RatioDiv16 {
                              //aspect-ratio: 16/9;
                              height: 99%;
                              margin: 0px auto;
                              width: auto;

                              img {
                                //object-fit: scale-down;
                                position: initial !important;
                                //border-radius: 10px;
                                width: 100% !important;
                                height: 100% !important;
                              }
                            }
                          }
                        }

                        .front-stage-share-screen.empty-front-stage-share-screen {
                          display: none !important;
                        }

                        .front-stage-share-screen.screen-share-element {
                          height: 100% !important;
                        }

                        .stage-user-list {
                          height: 100% !important;
                          padding: 0;
                          overflow-y: initial;
                          scrollbar-width: thin;
                          overflow-x: initial;

                          .list-1 {
                            padding: 1px;

                            .video_footer {
                              .ShortName {
                                h4 {
                                  font-size: 28px !important;
                                  width: auto !important;
                                  height: auto !important;
                                  border: 0 !important;
                                }
                              }
                            }

                            .RightSvgIcon {
                              .mh-cursor-pointer {
                                padding: 6px;
                                top: 6px;
                                left: 6px;
                                width: 25px;
                                height: 25px;
                              }
                            }
                          }

                          .list-2,
                          .list-3,
                          .list-4 {
                            padding: 1px;

                            .video_footer {
                              .ShortName {
                                h4 {
                                  font-size: 18px !important;
                                  width: auto !important;
                                  height: auto !important;
                                  border: 0 !important;
                                }
                              }
                            }

                            .RightSvgIcon {
                              .mh-cursor-pointer {
                                padding: 6px;
                                top: 6px;
                                left: 6px;
                                width: 25px;
                                height: 25px;
                              }
                            }
                          }

                          .list-5,
                          .list-6,
                          .list-7,
                          .list-8 {
                            padding: 1px;

                            .video_footer {
                              .ShortName {
                                h4 {
                                  font-size: 16px !important;
                                  width: auto !important;
                                  height: auto !important;
                                  border: 0 !important;
                                }
                              }
                            }

                            .RightSvgIcon {
                              .mh-cursor-pointer {
                                padding: 6px;
                                top: 6px;
                                left: 6px;
                                width: 25px;
                                height: 25px;
                              }
                            }
                          }

                          .list-9,
                          .list-10,
                          .list-11,
                          .list-12 {
                            padding: 1px;

                            .video_footer {
                              .ShortName {
                                h4 {
                                  font-size: 16px !important;
                                  width: auto !important;
                                  height: auto !important;
                                  border: 0 !important;
                                }
                              }
                            }

                            .RightSvgIcon {
                              .mh-cursor-pointer {
                                padding: 6px;
                                top: 6px;
                                left: 6px;
                                width: 25px;
                                height: 25px;
                              }
                            }
                          }

                          .list-13,
                          .list-14,
                          .list-15,
                          .list-16 {
                            padding: 1px;

                            .video_footer {
                              .ShortName {
                                h4 {
                                  font-size: 16px !important;
                                  width: auto !important;
                                  height: auto !important;
                                  border: 0 !important;
                                }
                              }
                            }

                            .RightSvgIcon {
                              .mh-cursor-pointer {
                                padding: 6px;
                                top: 6px;
                                left: 6px;
                                width: 25px;
                                height: 25px;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }


            }

          }





        }

        .CopyDelInline {
          position: absolute;
          top: 4px;
          right: 6px;
          display: flex;
          align-items: center;

          .DeleteSceneBx {
            width: 22px;
            height: 22px;
            z-index: 9;
            background: #1d252a;
            border-radius: 100px;
            padding: 5px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin-left: 4px;

            svg {
              width: 100%;
              height: 100%;
              fill: #ffffff;
            }
          }

          .CopySceneBx {
            width: 22px;
            height: 22px;
            z-index: 9;
            background: #1d252a;
            border-radius: 100px;
            padding: 6px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            svg {
              width: 100%;
              height: 100%;
              fill: #ffffff;
            }
          }

          .OnAirMix {
            width: auto;
            z-index: 9;
            background: #e6344d;
            border-radius: 100px;
            padding: 0px 6px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            color: #fff;
            font-size: 10px;
            margin-right: 4px;
            border: 1px #e6344d solid;
            align-items: center;
            line-height: 14px;
          }
        }

        // &:last-child {
        //   margin-right: 0px;
        // }
        .active-list {
          border: 2px #1a78e5 solid;
        }
      }

      .AddMoreList {
        width: 10rem;
        height: 6.188rem;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 4.5em;
          height: 4.5em;
          cursor: pointer;
        }
      }

      [data-rbd-droppable-context-id="1"] {
        background: transparent !important;
        overflow: hidden !important;
      }
    }
  }
}

.MuiPopover-root {
  .MuiPaper-root {
    &.popoverToggleDotts {
      padding: 0 0px !important;
      background-color: transparent !important;
      border-radius: 5px;
      border: 0;
      box-shadow: none !important;
      overflow: initial !important;

      .MuiPaper-root {
        background-color: transparent !important;
      }
    }
  }
}



.BackstageRtStream {
  position: absolute;
  top: 9px;
  right: 4px;
  width: 340px;
  height: calc(100vh - 62px);
  background: var(--light-gray);
  z-index: 16;
  border-radius: 10px;
  padding: 0;

  .GroupStreamMix {
    width: 100%;
    padding: 12px;

    //min-height: 190px;
    .StreamShowNow {
      .img-fluid {
        aspect-ratio: 16/9;
        border-radius: 8px 8px 0px 0px;
      }
    }

    .StreamFooter {
      width: 100%;
      font-size: 16px;
      background-color: var(--white);
      padding: 10px 12px;
      text-transform: uppercase;
      font-weight: 500;
      color: var(--dark-gray);
      border-radius: 0px 0px 8px 8px;

      .RoundPoint {
        width: 12px;
        height: 12px;
        display: inline-block;
        border-radius: 100%;
        vertical-align: middle;
        margin-right: 10px;
      }
    }

    .oraganizer-backstage-dashboard {
      padding: 0 0 !important;

      .container-fluid {
        padding: 0 0 !important;
      }

      .SideViewBackStage {
        width: 100%;
        height: 100% !important;
        margin-left: 0px !important;
        justify-content: initial !important;

        .alignAllDiv {
          width: 100%;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          height: auto;

          //aspect-ratio: 16/9;
          .front-stage-stream-scroll {
            aspect-ratio: 16/9;
            margin: 0px auto 0;
            padding: 4px 4px;
            border-radius: 4px;
            overflow: initial !important;
            background-color: #000;
            // position: absolute;
            // top: 0;
            width: 100%;

            // height: auto;
            // left: 0;
            // right: 0;
            // background-color: rgb(0 0 0 / 50%);
            .row {
              height: 100%;
              align-items: center;

              .stage-user-list {
                .video_footer {
                  background: #444444;
                  border-radius: 4px;
                  height: 100%;
                  position: relative;
                  overflow: hidden;

                  .bottom_footer {
                    position: absolute;
                    width: 100%;
                    bottom: -120px;
                    left: 0%;
                    transition: all 0.6s ease;
                    -webkit-transition: all 0.6s ease;
                    -moz-transition: all 0.6s ease;
                    -o-transition: all 0.6s ease;
                    -o-transition: all 0.6s ease;

                    .NameExpFull {
                      width: 100%;
                      background: rgb(0 0 0 / 60%);
                      height: 48px;
                      border-radius: 0px 0px 4px 4px;
                      margin-top: 0;

                      h4 {
                        color: #ffffff;
                        font-size: 20px;
                        font-weight: 400;
                        margin: 0;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        padding: 0 5px;
                        height: 100%;

                        span {
                          width: 100%;
                        }
                      }
                    }
                  }

                  .lower_third {
                    left: 0px;
                    bottom: 0px !important;
                    transition: all 0.5s ease;
                    -webkit-transition: all 0.5s ease;
                    -moz-transition: all 0.5s ease;
                    -o-transition: all 0.5s ease;
                    -o-transition: all 0.5s ease;
                  }

                  .localPlayer {
                    width: 100%;
                    height: 100%;

                    div {
                      border-radius: 4px;

                      video {
                        border-radius: 4px;
                        position: relative !important;
                      }
                    }
                  }

                  .BackIconAction {
                    top: 6px;
                    right: 4px;

                    .ActionLive {
                      width: 18px;
                      height: 18px;

                      .widthSvg {
                        padding: 3px 3px;
                      }
                    }
                  }
                }

                .list-1 {
                  .video_footer {
                    .bottom_footer {
                      .NameExpFull {
                        width: 100%;
                        height: 40px;

                        h4 {
                          font-size: 12px;
                        }
                      }
                    }

                    .lower_third {
                      left: 0px;
                    }
                  }
                }

                .list-2,
                .list-3,
                .list-4 {
                  .video_footer {
                    .bottom_footer {
                      .NameExpFull {
                        width: 100%;
                        height: 20px;

                        h4 {
                          font-size: 8px;
                        }
                      }
                    }

                    .lower_third {
                      left: 0px !important;

                    }
                  }
                }

                .list-5,
                .list-6 {
                  .video_footer {
                    .bottom_footer {
                      .NameExpFull {
                        width: 100%;
                        height: 18px;

                        h4 {
                          font-size: 8px;
                        }
                      }
                    }

                    .lower_third {
                      left: 0px;
                    }
                  }
                }

                .list-7,
                .list-8 {
                  .video_footer {
                    .bottom_footer {
                      .NameExpFull {
                        width: 100%;
                        height: 14px;

                        h4 {
                          font-size: 8px;
                        }
                      }
                    }

                    .lower_third {
                      left: 0px;
                    }
                  }
                }

                .list-9,
                .list-10,
                .list-11,
                .list-12 {
                  .video_footer {
                    .bottom_footer {
                      .NameExpFull {
                        width: 100%;
                        height: 12px;

                        h4 {
                          font-size: 6px;
                        }
                      }
                    }

                    .lower_third {
                      left: 0px;
                    }
                  }
                }

                .list-13,
                .list-14,
                .list-15,
                .list-16 {
                  .video_footer {
                    .bottom_footer {
                      .NameExpFull {
                        width: 100%;
                        height: 12px;

                        h4 {
                          font-size: 6px;
                        }
                      }
                    }

                    .lower_third {
                      left: 0px;
                    }
                  }
                }
              }

              .PollQnaBase {
                position: absolute;
                left: 0;
                bottom: 2px;
                width: 100%;
                z-index: 99;
                max-height: 170px;
                overflow-y: auto;
                overflow-x: hidden;
                scrollbar-width: thin;

                .ScreenCast {
                  padding: 6px 12px 6px 6px;
                  width: 100%;
                  background-color: rgba(0, 0, 0, 0.32);
                  border-radius: 6px;
                  margin: 0 0 4px;

                  .row {
                    .col-sm-12.col-md-6.col-lg-4 {
                      padding: 0 4px;
                    }

                    .UpdateVoteStream {
                      position: relative;
                      width: 100%;
                      height: 100%;

                      .QnAuserImg {
                        width: 65px;
                        height: 65px;
                        background-color: #1a78e5;
                        border-radius: 8px;
                        margin-right: 6px;
                        position: absolute;
                        left: 15px;
                        top: 15px;
                        border: 1px #fff solid;

                        .img-fluid {
                          border-radius: 8px;
                          position: initial !important;
                        }
                      }

                      .ShowAnAQue {
                        width: calc(100% - 0px);
                        display: flex;
                        align-items: center;
                        background: #1a78e5;
                        height: 50%;
                        padding-left: 100px;
                        justify-content: space-between;
                        border-radius: 6px 6px 0px 0px;

                        .NameQnAuser {
                          padding: 5px 0;
                          display: flex;
                          justify-content: center;
                          flex-direction: column;
                          text-align: left;
                          width: 80%;

                          h4 {
                            font-size: 14px;
                            font-weight: 500;
                            margin: 0;
                            background-color: transparent;
                            padding: 0;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            width: 100%;
                            color: #fff;
                            ;
                          }
                        }

                        .QnaVoteCount {
                          padding: 0px 0px;
                          border-radius: 0;
                          display: flex;
                          justify-content: center;
                          margin-right: 22px;

                          svg {
                            fill: #ffffff;
                          }

                          span {
                            vertical-align: middle;
                            margin-left: 8px;
                            color: #fff;
                            box-shadow: 0px 0px 6px rgb(0 0 0 / 7%);
                            font-size: 15px;
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                          }
                        }
                      }
                    }

                    .ShowQueHere {
                      padding-left: 100px;
                      height: 50%;
                      display: flex;
                      align-items: center;

                      p {
                        font-size: 14px;
                        line-height: 20px;
                        color: var(--dark-gray);
                        letter-spacing: 0.2px;
                        margin: 0;
                        text-align: left;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        width: 90%;
                      }
                    }
                  }
                }
              }

              .VideoBroadcastLive {
                position: absolute;
                padding: 0px;
                width: auto;
                height: 100%;
                left: 0;
                margin: 0px auto;
                right: 0;

                div {
                  width: 100% !important;
                  height: 100% !important;
                  aspect-ratio: 16/9;
                }
              }

              .ImageBroadcastLive {
                width: 100%;
                height: 100%;

                .RatioDiv16 {
                  aspect-ratio: 16/9;
                  height: 100%;
                  width: 100%;
                  margin: 0px auto;

                  img {
                    //object-fit: scale-down;
                    position: initial !important;
                    border-radius: 10px;
                  }
                }
              }

              .PDFBroadcastLive {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;

                .RatioDiv16 {
                  //aspect-ratio: 16/9;
                  height: 99%;
                  margin: 0px auto;
                  width: auto;

                  img {
                    //object-fit: scale-down;
                    position: initial !important;
                    width: 100% !important;
                    height: 100% !important;
                    //border-radius: 10px;
                  }
                }
              }

              .stage-user-list {
                height: 100%;
                padding: 0;
              }

              .front-stage-share-screen {
                display: block !important;
                height: 100%;

                .VideoBroadcastLive {
                  position: absolute;
                  padding: 0px;
                  width: 100%;
                  height: 100%;
                  left: 0;
                  top: 0;

                  .alignBlockAuto {
                    display: inline-block;
                    width: auto !important;
                    aspect-ratio: auto !important;
                  }
                }

                .ImageBroadcastLive {
                  width: 100%;
                  height: 100%;

                  .RatioDiv16 {
                    aspect-ratio: 16/9;
                    height: 100%;
                    width: 100%;
                    margin: 0px auto;

                    img {
                      //object-fit: scale-down;
                      position: initial !important;
                      border-radius: 10px;
                    }
                  }

                }

                .PDFBroadcastLive {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  position: relative;

                  .RatioDiv16 {
                    //aspect-ratio: 16/9;
                    height: 99%;
                    margin: 0px auto;
                    width: auto;

                    img {
                      //object-fit: scale-down;
                      width: 100% !important;
                      height: 100% !important;
                      position: initial !important;
                      //border-radius: 10px;
                    }
                  }
                }
              }

              .front-stage-share-screen.empty-front-stage-share-screen {
                display: none !important;
              }

              .front-stage-share-screen.screen-share-element {
                height: 100% !important;
              }

              .stage-user-list {
                display: inline-flex;
                height: 100%;
                align-items: center;
                flex-wrap: wrap;
                padding: 2px 10px;
                justify-content: center;

                .list-1 {
                  flex: 0 0 auto;
                  width: 100%;
                  height: 100%;
                  padding: 2px 2px;
                }

                .list-2 {
                  flex: 0 0 auto;
                  width: 50%;
                  height: 50%;
                  padding: 4px 4px 4px;
                }

                .list-3 {
                  flex: 0 0 auto;
                  width: 50%;
                  height: 50%;
                  padding: 2px 2px 2px;
                }

                .list-4 {
                  flex: 0 0 auto;
                  width: 50%;
                  height: 50%;
                  padding: 2px 2px 2px;
                }

                .list-5 {
                  flex: 0 0 auto;
                  width: 33.33333333%;
                  height: 50%;
                  padding: 2px 2px 2px;
                }

                .list-6 {
                  flex: 0 0 auto;
                  width: 33.33333333%;
                  height: 50%;
                  padding: 2px 2px 2px;
                }

                .list-7 {
                  flex: 0 0 auto;
                  width: 25%;
                  height: 50%;
                  padding: 2px 2px 2px;
                }

                .list-8 {
                  flex: 0 0 auto;
                  width: 25%;
                  height: 50%;
                  padding: 2px 2px 2px;
                }

                .list-9 {
                  flex: 0 0 auto;
                  width: 25%;
                  height: 33.33333333%;
                  padding: 2px 2px 2px;
                }

                .list-10 {
                  flex: 0 0 auto;
                  width: 25%;
                  height: 33.33333333%;
                  padding: 2px 2px 2px;
                }

                .list-11 {
                  flex: 0 0 auto;
                  width: 25%;
                  height: 33.33333333%;
                  padding: 2px 2px 2px;
                }

                .list-12 {
                  flex: 0 0 auto;
                  width: 25%;
                  height: 33.33333333%;
                  padding: 2px 2px 2px;
                }

                .list-13 {
                  flex: 0 0 auto;
                  width: 25%;
                  height: 25%;
                  padding: 2px 2px 2px;
                }

                .list-14 {
                  flex: 0 0 auto;
                  width: 25%;
                  height: 25%;
                  padding: 2px 2px 2px;
                }

                .list-15 {
                  flex: 0 0 auto;
                  width: 25%;
                  height: 25%;
                  padding: 2px 2px 2px;
                }

                .list-16 {
                  flex: 0 0 auto;
                  width: 25%;
                  height: 25%;
                  padding: 2px 2px 2px;
                }

                .video_footer {
                  .RightSvgIcon {
                    .mh-cursor-pointer {
                      transform: rotate(-180deg);
                      padding: 10px;
                    }
                  }

                  .bottom_footer {

                    //bottom: 0px !important;
                    h4 {
                      span {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        width: 100%;
                      }
                    }
                  }
                }
              }

              .list-2,
              .list-3,
              .list-4,
              .list-5,
              .list-6,
              .list-7,
              .list-8 {
                .ShortName {
                  h4 {
                    font-size: 16px;
                    width: 38px;
                    height: 38px;
                  }
                }

                .video_footer {
                  .BackIconAction {
                    top: 4px;
                    right: 2px;

                    .ActionLive {
                      width: 15px;
                      height: 15px;

                      .widthSvg {
                        padding: 2px 2px;
                      }
                    }
                  }
                }
              }

              .list-9,
              .list-10,
              .list-11,
              .list-12 {
                .ShortName {
                  h4 {
                    font-size: 15px;
                    width: 38px;
                    height: 38px
                  }
                }

                .video_footer {
                  .BackIconAction {
                    top: 4px;
                    right: 2px;

                    .ActionLive {
                      width: 13px;
                      height: 13px;
                      margin: 0 1px;

                      .widthSvg {
                        padding: 2px 2px;
                      }
                    }
                  }
                }
              }

              .list-13,
              .list-14,
              .list-15,
              .list-16 {
                .ShortName {
                  h4 {
                    font-size: 10px;
                    width: 24px;
                    height: 24px;
                  }
                }
              }


            }
          }
        }

        .RemoveAllDiv {
          width: 100% !important;
          height: auto !important;
          display: flex !important;
          aspect-ratio: 16/9;
          margin: 0px auto;

          .img-fluid {
            position: absolute;
            left: 0;
            right: 0;
            height: 100% !important;
            width: 100% !important;
            margin: 0px auto;
            border-radius: 6px;
          }

          .front-stage-stream-scroll {
            //position: absolute;
            width: 98%;
            height: auto;
            left: 0;
            right: 0;
            top: 0;
            margin: 0px auto;

            //position: relative;
            .row {
              justify-content: left;
              align-items: end !important;

              .PollQnaBase {
                position: absolute;
                left: 0;
                bottom: 2px;
                width: 100%;
                z-index: 99;
                max-height: 174px;
                overflow-y: auto;
                overflow-x: hidden;
                scrollbar-width: thin;

                .ScreenCast {
                  width: 100%;
                  margin: 0 0 8px;
                  background-color: rgba(0, 0, 0, 0.32);
                  padding: 2px 4px;
                  border-radius: 6px;

                  h4 {
                    background-color: #1a78e5;
                    color: #fff;
                    font-weight: 400;
                    font-size: 12px;
                    padding: 5px 8px;
                    border-radius: 4px;
                    text-align: left;
                  }

                  .OptionPoll {
                    width: 100%;

                    .row {
                      align-items: center !important;
                      margin: 0px !important;

                      .PollActionTab {
                        background-color: #f0f0f0;
                        display: inline-block;
                        text-align: left;
                        padding: 6px 18px;
                        font-size: 14px;
                        border-radius: 4px;
                        margin: 0 0 12px;
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box !important;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                      }

                      .option-1 {
                        flex: 0 0 auto;
                        width: 100%;
                      }

                      .option-2 {
                        flex: 0 0 auto;
                        width: 50%;
                      }

                      .option-3 {
                        flex: 0 0 auto;
                        width: 50%;
                      }

                      .option-4 {
                        flex: 0 0 auto;
                        width: 50%;
                      }

                      .option-5 {
                        flex: 0 0 auto;
                        width: 33.33333333%;
                      }

                      .option-6 {
                        flex: 0 0 auto;
                        width: 33.33333333%;
                      }

                      .option-7 {
                        flex: 0 0 auto;
                        width: 33.33333333%;
                      }

                      .option-8 {
                        flex: 0 0 auto;
                        width: 33.33333333%;
                      }
                    }
                  }

                  .row {
                    justify-content: left;
                    align-items: end !important;

                    .QuestionVote {
                      padding: 0px 0px !important;
                      background-color: #fff;
                      border-radius: 6px;
                      box-shadow: 0px 0px 6px rgb(0 0 0 / 5%);
                      position: relative;
                      width: 100%;
                      margin: 0 0 8px;
                      height: 45px;

                      .UpdateVoteStream {
                        position: relative;
                        width: 100%;
                        height: 100%;

                        .QnAuserImg {
                          width: 30px;
                          height: 30px;
                          background-color: #1a78e5;
                          border-radius: 4px;
                          margin-right: 6px;
                          position: absolute;
                          left: 8px;
                          top: 8px;
                          border: 1px #fff solid;

                          .img-fluid {
                            border-radius: 4px;
                            position: initial !important;
                          }
                        }

                        .ShowAnAQue {
                          width: calc(100% - 0px);
                          display: flex;
                          align-items: center;
                          background: #1a78e5;
                          height: 50%;
                          padding-left: 50px;
                          justify-content: space-between;
                          border-radius: 6px 6px 0px 0px;

                          .NameQnAuser {
                            padding: 5px 0;
                            display: flex;
                            justify-content: center;
                            flex-direction: column;
                            text-align: left;
                            width: 80%;

                            h4 {
                              font-size: 10px;
                              font-weight: 500;
                              margin: 0;
                              background-color: transparent;
                              padding: 0;
                              overflow: hidden;
                              text-overflow: ellipsis;
                              display: -webkit-box;
                              -webkit-line-clamp: 1;
                              -webkit-box-orient: vertical;
                              width: 100%;
                              color: #fff;
                              ;
                            }
                          }

                          .QnaVoteCount {
                            padding: 0px 0px;
                            border-radius: 0;
                            display: flex;
                            justify-content: center;
                            margin-right: 8px;

                            svg {
                              fill: #ffffff;
                              width: 10px;
                              height: 10px;
                            }

                            span {
                              vertical-align: middle;
                              margin-left: 5px;
                              color: #fff;
                              box-shadow: 0px 0px 6px rgb(0 0 0 / 7%);
                              font-size: 10px;
                              display: inline-flex;
                              align-items: center;
                              justify-content: center;
                              height: 12px;
                            }
                          }
                        }
                      }

                      .ShowQueHere {
                        padding-left: 50px;
                        height: 50%;
                        display: flex;
                        align-items: center;

                        p {
                          font-size: 8px;
                          line-height: 20px;
                          color: var(--dark-gray);
                          letter-spacing: 0.2px;
                          margin: 0;
                          text-align: left;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          display: -webkit-box;
                          -webkit-line-clamp: 1;
                          -webkit-box-orient: vertical;
                          width: 90%;
                        }
                      }
                    }
                  }

                }
              }
            }
          }

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .BottomStatusFoot {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 10px 20px;
        min-height: 51px;

        .LeftStatuSide {
          .TypeAction {
            display: inline-block;
            text-align: center;
            font-size: 12px;
            color: #fff;
            font-weight: 400;
            margin-right: 10px;

            .OnoffSwitch {
              display: inline-block;

              .MuiFormControlLabel-root {
                margin: 0px !important;
              }

              .MuiSwitch-root {
                padding: 12px 7px !important;
                width: 48px;
                height: 40px;

                .MuiButtonBase-root {
                  padding: 14px 10px !important;

                  .MuiSwitch-thumb {
                    width: 12px !important;
                    height: 12px !important;
                  }
                }

                .Mui-checked {
                  padding: 14px 8px !important;
                }

                .MuiSwitch-track {
                  opacity: 1 !important;
                  background-color: #585757;
                }

              }
            }
          }
        }

        .RighttStatuSide {
          .leaveStream {
            margin-left: 8px;
            font-size: 14px;
            padding: 8px 30px 8px 14px !important;
            float: right;
            font-weight: 400;
          }

          .SendStream {
            margin-left: 8px;
            font-size: 14px;
            padding: 8px 14px 8px 14px !important;
            float: right;
            font-weight: 400;
          }
        }
      }

      .broadcast-not-started {

        //height: calc(100vh - 220px) !important;
        .alignAllDiv {
          .front-stage-stream-scroll {
            width: 100%;
          }
        }
      }

      .FrontstageInfoStream {
        padding-left: 185px;
      }
    }

    .oraganizer-backstage-dashboard.screen-stage-main-div {
      padding: 0 0 !important;

      .container-fluid {
        padding: 0 0 !important;
      }

      .SideViewBackStage {
        width: 100%;
        height: 100% !important;
        margin-left: 0px !important;
        justify-content: initial !important;

        .RemoveAllDiv {
          width: 100% !important;
          height: auto !important;
          display: flex !important;
          aspect-ratio: 16/9;
          margin: 0px auto;

          .img-fluid {
            position: absolute;
            left: 0;
            right: 0;
            height: 100% !important;
            width: 100% !important;
            margin: 0px auto;
          }

          .front-stage-stream-scroll {
            //position: absolute;
            width: 98%;
            height: auto;
            left: 0;
            right: 0;
            top: 0;
            margin: 0px auto;

            .ImptyStreamBg {
              display: none;
            }
          }

          img {
            width: 100%;
            height: 100%;
          }
        }

        .alignAllDiv {
          width: 100%;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          height: auto;

          //aspect-ratio: 16/9;
          .front-stage-stream-scroll {
            aspect-ratio: 16/9;
            margin: 0px auto 0;
            padding: 8px 14px;
            border-radius: 4px;
            overflow: initial !important;
            // position: absolute;
            // top: 0;
            width: 100%;

            // height: auto;
            // left: 0;
            // right: 0;
            // background-color: rgb(0 0 0 / 50%);
            .row {
              height: 100%;
              align-items: center;

              .stage-user-list {
                color: #000;

                .video_footer {
                  background: #444444;
                  border-radius: 4px;
                  height: 100%;
                  position: relative;

                  .bottom_footer {
                    position: absolute;
                    width: 100%;
                    bottom: -120px;
                    left: 0%;
                    transition: all 0.6s ease;
                    -webkit-transition: all 0.6s ease;
                    -moz-transition: all 0.6s ease;
                    -o-transition: all 0.6s ease;
                    -o-transition: all 0.6s ease;

                    .NameExpFull {
                      width: 100%;
                      background: rgb(0 0 0 / 60%);
                      height: 48px;
                      border-radius: 0px 0px 4px 4px;
                      margin-top: 0;

                      h4 {
                        color: #ffffff;
                        font-size: 20px;
                        font-weight: 400;
                        margin: 0;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        padding: 0 5px;
                        height: 100%;

                        span {
                          width: 100%;
                        }
                      }
                    }
                  }

                  .lower_third {
                    left: 0px;
                    bottom: 0px !important;
                    transition: all 0.5s ease;
                    -webkit-transition: all 0.5s ease;
                    -moz-transition: all 0.5s ease;
                    -o-transition: all 0.5s ease;
                    -o-transition: all 0.5s ease;
                  }
                }
              }

              .VideoBroadcastLive {
                position: absolute;
                padding: 0px;
                width: auto;
                height: 100%;
                left: 0;
                margin: 0px auto;
                right: 0;

                div {
                  width: 100% !important;
                  height: 100% !important;
                  aspect-ratio: 16/9;
                }
              }

              .ImageBroadcastLive {
                width: 100%;
                height: 100%;

                .RatioDiv16 {
                  aspect-ratio: 16/9;
                  height: 100%;
                  width: 100%;
                  margin: 0px auto;

                  img {
                    //object-fit: scale-down;
                    position: initial !important;
                    border-radius: 4px;
                  }
                }
              }

              .PDFBroadcastLive {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;

                .RatioDiv16 {
                  //aspect-ratio: 16/9;
                  height: 99%;
                  margin: 0px auto;
                  width: auto;

                  img {
                    //object-fit: scale-down;
                    position: initial !important;
                    width: 100% !important;
                    height: 100% !important;
                    //border-radius: 10px;
                  }
                }
              }

              .stage-user-list {
                height: 100%;
                padding: 0;
              }

              .front-stage-share-screen {
                width: 75%;
                padding: 0 6px;
                position: relative;

                .ImageBroadcastLive {
                  width: 100%;
                  height: 100%;
                  align-items: center;
                  justify-content: center;
                  display: flex;

                  .RatioDiv16 {
                    aspect-ratio: 16/9;
                    height: auto;
                    width: 100%;
                    margin: 0px auto;

                    img {
                      //object-fit: scale-down;
                      position: initial !important;
                      border-radius: 4px;
                    }
                  }

                }

                .PDFBroadcastLive {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  position: relative;

                  .RatioDiv16 {
                    //aspect-ratio: 16/9;
                    height: 99%;
                    margin: 0px auto;
                    width: auto;

                    img {
                      //object-fit: scale-down;
                      position: initial !important;
                      width: 100% !important;
                      height: 100% !important;
                      //border-radius: 10px;
                    }
                  }
                }
              }

              .front-stage-share-screen.empty-front-stage-share-screen {
                display: none !important;
              }

              .front-stage-share-screen.screen-share-element {
                height: 100% !important;
              }

              .stage-user-list {
                width: 24%;
                display: block;
                overflow-y: auto;
                overflow-x: auto;
                scrollbar-width: thin;

                .list-1,
                .list-2,
                .list-3,
                .list-4,
                .list-5,
                .list-6,
                .list-7,
                .list-8,
                .list-9,
                .list-10,
                .list-11,
                .list-12,
                .list-13,
                .list-14,
                .list-15,
                .list-16 {
                  flex: 0 0 auto;
                  width: auto;
                  height: auto;
                  padding: 2px 2px;
                  aspect-ratio: 16/9;
                }

                .video_footer {
                  .RightSvgIcon {
                    .mh-cursor-pointer {
                      transform: rotate(-180deg);
                      padding: 4px;
                    }
                  }

                  .bottom_footer {
                    .NameExpFull {
                      width: 100%;
                      height: 8px;

                      h4 {
                        font-size: 4px;
                      }
                    }

                  }
                }
              }

              .list-1,
              .list-2,
              .list-3,
              .list-4,
              .list-5,
              .list-6,
              .list-7,
              .list-8,
              .list-9,
              .list-10,
              .list-11,
              .list-12,
              .list-13,
              .list-14,
              .list-15,
              .list-16 {
                .ShortName {
                  h4 {
                    font-size: 8px;
                    width: 24px;
                    height: 24px;
                  }
                }
              }



            }

          }
        }


      }

      .BottomStatusFoot {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 10px 20px;

        .LeftStatuSide {
          .TypeAction {
            display: inline-block;
            text-align: center;
            font-size: 12px;
            color: #fff;
            font-weight: 400;
            margin-right: 10px;

            .OnoffSwitch {
              display: inline-block;

              .MuiFormControlLabel-root {
                margin: 0px !important;
              }

              .MuiSwitch-root {
                padding: 12px 7px !important;
                width: 48px;
                height: 40px;

                .MuiButtonBase-root {
                  padding: 14px 10px !important;

                  .MuiSwitch-thumb {
                    width: 12px !important;
                    height: 12px !important;
                  }
                }

                .Mui-checked {
                  padding: 14px 8px !important;
                }

                .MuiSwitch-track {
                  opacity: 1 !important;
                  background-color: #585757;
                }

              }
            }
          }
        }

        .RighttStatuSide {
          .leaveStream {
            margin-left: 8px;
            font-size: 14px;
            padding: 8px 30px 8px 14px !important;
            float: right;
            font-weight: 400;
          }

          .SendStream {
            margin-left: 8px;
            font-size: 14px;
            padding: 8px 14px 8px 14px !important;
            float: right;
            font-weight: 400;
          }
        }
      }

      .broadcast-not-started {

        //height: calc(100vh - 220px) !important;
        .alignAllDiv {
          .front-stage-stream-scroll {
            width: 100%;
          }
        }
      }

      .FrontstageInfoStream {
        padding-left: 185px;
      }
    }



    .ChangeWidthScroll {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .flexDicSeps {
        width: 100%;
        height: 100%;
        position: relative;

        .alignAllDiv {
          width: 100% !important;
          height: auto !important;
          display: flex !important;
          aspect-ratio: 16/9;
          margin: 0px auto;

          .front-stage-stream-scroll {
            aspect-ratio: 16/9;
            margin: 0px auto 0;
            padding: 0px 0px;
            border-radius: 4px;
            overflow: initial !important;
            width: 100%;
            background-color: #000;

            .stage-user-list {
              display: inline-flex;
              height: 100%;
              align-items: center;
              flex-wrap: wrap;
              padding: 0px !important;
              justify-content: center;
              width: 100% !important;

              .video_footer {
                width: 100%;
                height: 100%;
                position: relative;
                background: #2e2e2e;
                border: 1px #2e2e2e solid;
                border-radius: 4px;
              }

              .list-1 {
                flex: 0 0 auto;
                width: 100%;
                height: 100%;
                padding: 2px 2px;
              }

              .list-2 {
                flex: 0 0 auto;
                width: 50%;
                height: 50%;
                padding: 2px 2px 2px;
              }

              .list-3 {
                flex: 0 0 auto;
                width: 50%;
                height: 50%;
                padding: 2px 2px 2px;
              }

              .list-4 {
                flex: 0 0 auto;
                width: 50%;
                height: 50%;
                padding: 2px 2px 2px;
              }

              .list-5 {
                flex: 0 0 auto;
                width: 33.33333333%;
                height: 50%;
                padding: 2px 2px 2px;
              }

              .list-6 {
                flex: 0 0 auto;
                width: 33.33333333%;
                height: 50%;
                padding: 2px 2px 2px;
              }

              .list-7 {
                flex: 0 0 auto;
                width: 25%;
                height: 50%;
                padding: 2px 2px 2px;
              }

              .list-8 {
                flex: 0 0 auto;
                width: 25%;
                height: 50%;
                padding: 2px 2px 2px;
              }

              .list-9 {
                flex: 0 0 auto;
                width: 25%;
                height: 33.33333333%;
                padding: 2px 2px 2px;
              }

              .list-10 {
                flex: 0 0 auto;
                width: 25%;
                height: 33.33333333%;
                padding: 2px 2px 2px;
              }

              .list-11 {
                flex: 0 0 auto;
                width: 25%;
                height: 33.33333333%;
                padding: 2px 2px 2px;
              }

              .list-12 {
                flex: 0 0 auto;
                width: 25%;
                height: 33.33333333%;
                padding: 2px 2px 2px;
              }

              .list-13 {
                flex: 0 0 auto;
                width: 25%;
                height: 25%;
                padding: 2px 2px 2px;
              }

              .list-14 {
                flex: 0 0 auto;
                width: 25%;
                height: 25%;
                padding: 2px 2px 2px;
              }

              .list-15 {
                flex: 0 0 auto;
                width: 25%;
                height: 25%;
                padding: 2px 2px 2px;
              }

              .list-16 {
                flex: 0 0 auto;
                width: 25%;
                height: 25%;
                padding: 2px 2px 2px;
              }
            }

            .stage-user-list {
              height: 100% !important;
              padding: 0;
              overflow-y: initial;
              overflow-x: initial;
              scrollbar-width: thin;

              .list-1 {
                .video_footer {
                  .ShortName {
                    h4 {
                      font-size: 28px !important;
                    }
                  }
                }

                .RightSvgIcon {
                  .mh-cursor-pointer {
                    padding: 6px;
                    top: 6px;
                    left: 6px;
                    width: 25px;
                    height: 25px;
                  }
                }
              }

              .list-2,
              .list-3,
              .list-4 {
                .video_footer {
                  .ShortName {
                    h4 {
                      font-size: 18px !important;
                      width: 45px;
                      height: 45px;
                    }
                  }
                }

                .RightSvgIcon {
                  .mh-cursor-pointer {
                    padding: 6px;
                    top: 6px;
                    left: 6px;
                    width: 25px;
                    height: 25px;
                  }
                }
              }

              .list-5,
              .list-6 {
                .video_footer {
                  .ShortName {
                    h4 {
                      font-size: 18px !important;
                      width: 45px;
                      height: 45px;
                    }
                  }
                }

                .RightSvgIcon {
                  .mh-cursor-pointer {
                    padding: 6px;
                    top: 6px;
                    left: 6px;
                    width: 25px;
                    height: 25px;
                  }
                }
              }

              .list-7,
              .list-8 {
                .video_footer {
                  .ShortName {
                    h4 {
                      font-size: 18px !important;
                      width: 45px;
                      height: 45px;
                    }
                  }
                }

                .RightSvgIcon {
                  .mh-cursor-pointer {
                    padding: 6px;
                    top: 6px;
                    left: 6px;
                    width: 25px;
                    height: 25px;
                  }
                }
              }

              .list-7,
              .list-8 {
                .video_footer {
                  .ShortName {
                    h4 {
                      font-size: 16px !important;
                      width: 40px;
                      height: 40px;
                    }
                  }
                }

                .RightSvgIcon {
                  .mh-cursor-pointer {
                    padding: 6px;
                    top: 6px;
                    left: 6px;
                    width: 25px;
                    height: 25px;
                  }
                }
              }

              .list-9,
              .list-10,
              .list-11,
              .list-12 {
                padding: 1px;

                .video_footer {
                  .ShortName {
                    h4 {
                      font-size: 12px !important;
                      width: 32px;
                      height: 32px;
                    }
                  }
                }

                .RightSvgIcon {
                  .mh-cursor-pointer {
                    padding: 6px;
                    top: 6px;
                    left: 6px;
                    width: 25px;
                    height: 25px;
                  }
                }
              }

              .list-13,
              .list-14,
              .list-15,
              .list-16 {
                padding: 1px;

                .video_footer {
                  .ShortName {
                    h4 {
                      font-size: 12px !important;
                      width: 32px;
                      height: 32px;
                    }
                  }
                }

                .RightSvgIcon {
                  .mh-cursor-pointer {
                    padding: 6px;
                    top: 6px;
                    left: 6px;
                    width: 25px;
                    height: 25px;
                  }
                }
              }
            }
          }
        }

        .RemoveAllDiv {
          .img-fluid {
            position: absolute;
            left: 0;
            right: 0;
            height: 100% !important;
            width: 100% !important;
            margin: 0px auto;
          }
        }
      }
    }






  }

  .BsHeightFixLx {
    width: 100%;
    height: calc(100vh - 314px);

    .responsive-tabs-container {
      .nav-tabs {
        li {
          width: 100%;
          margin-bottom: 0px !important;

          .nav {
            .nav-link {
              padding: 6px 20px;
              position: relative;

              &.active {
                padding: 6px 20px;
              }

              .badge {
                top: 3px !important
              }
            }

            .nav-link {
              color: #5d5d5d;
              border-bottom: 2px transparent solid;
              background-color: transparent !important;
              box-shadow: none !important;
              border-radius: 0px !important;
              padding: 14px 0;
              width: 100%;
              border: 0;
              text-align: center;
              cursor: pointer;
              font-size: 14px;

              &.active {
                border-bottom: 3px #1a78e5 solid;
                padding: 14px 0;
                background-color: transparent !important;
                box-shadow: none !important;
                border-radius: 0px !important;
                width: 100%;
                color: #333;
              }
            }

            .badge {
              display: block;
              padding: 1px 0 !important;
              width: 10px;
              height: 10px;
              border-radius: 100px;
              position: absolute;
              top: -8px;
              left: 0;
              right: 0;
              margin: 0px auto !important;
              text-decoration: blink;
              animation: blinker 1s linear infinite;
            }
          }
        }
      }
    }

    .front-stage-active {
      .nav-tabs {
        li {
          width: 50%;
        }
      }
    }

    .EventInfoStage {
      height: calc(100vh - 338px);
      width: 100%;
      position: relative;

      .tab-content {
        .ChatInfos {
          height: calc(100vh - 470px);
          width: 100%;
          margin-top: 18px;
          padding: 0 15px;
          overflow-y: auto;
          margin-bottom: 14px;
          scrollbar-width: thin;

          .SenderMassage {
            position: relative;
            width: 100%;
            float: left;
            margin: 0 0 14px;

            .SenderMessage {
              float: right;
              padding: 8px 18px 8px 8px;
              border-radius: 16px 16px 0 16px;

              .chatPicsIn {
                .PersonInfoChat {
                  position: absolute;
                  width: 95px;
                  background-color: #45494c;
                  border: 1px solid #f0f0f0;
                  border-radius: 6px;
                  padding: 3px 4px;
                  left: -5px;
                  top: -42px;
                  display: none;
                  text-align: center;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;
                  min-height: 26px;
                }

              }



              .chatAllin {
                display: inline-block;
                flex: 1 1;

                h4 {
                  margin: 0;
                  font-size: 12px;
                  color: #3c3c3c;
                  line-height: 18px;
                  word-break: break-word;
                  text-align: left;
                  font-weight: 400;
                }

                p {
                  font-size: 12px;
                  font-weight: 400;
                  margin: 0 0 2px;
                  color: #515151;
                  text-align: left;
                }
              }
            }

            .SenderTimeDate {
              display: block;
              font-size: 10px;
              width: 100%;
              float: left;
              margin: 6px 0 0;
              color: #717171;
              font-weight: 500;
              text-align: right;
            }
          }

          .align-left {
            width: 90% !important;
            float: left;

            .SenderMessage {
              float: left;
              display: flex;
              border-radius: 16px 16px 16px 0px !important;
              background-color: #fff !important;

              .chatPicsIn {
                display: inline-flex;
                width: 40px;
                height: 40px;
                margin-right: 12px !important;
                position: relative;
                background-color: #f0f0f0;
                border-radius: 12px 12px 12px 0px !important;

                img {
                  width: 100%;
                  border-radius: 12px 12px 12px 0px !important;
                }

                &:hover {
                  .PersonInfoChat {
                    display: flex !important;

                  }
                }
              }

              .chatAllin {
                display: inline-block;
                flex: 1;
              }
            }

            .SenderTimeDate {
              text-align: left;
            }
          }

          .align-right {
            width: 90% !important;
            float: right;

            .SenderMessage {
              padding: 6px 6px 6px 12px !important;
              border-radius: 16px 16px 0 18px !important;
              background-color: #dcdcdc;
              background-color: var(--gray);
              display: flex;
              flex-direction: row-reverse !important;

              .chatPicsIn {
                display: inline-flex;
                width: 40px;
                height: 40px;
                margin-right: 0 !important;
                margin-left: 12px !important;
                position: relative;
                background-color: #f0f0f0;
                border-radius: 12px 12px 0 12px !important;

                .PersonInfoChat {
                  display: none !important;
                }

                img {
                  width: 100%;
                  border-radius: 12px 12px 0 12px !important;
                }
              }
            }
          }

          .MessageTextBox {
            bottom: 8px;

            .MsgEmojis {
              .EmojiIconsIn {
                width: 305px;
              }
            }
          }
        }

        .EnableChat {
          width: 100%;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 6px 10px;

          .BlueColor {
            background-color: #ddd;
            color: #333333;
            font-weight: 400;
            border-radius: 100px;
            padding: 6px 2px;
            width: 90%;
            border-color: #929292;
            font-size: 13px;
            margin: 0 6px;
          }
        }
      }
    }

    .chatEnableTab {
      .tab-content {
        .ChatInfos {
          height: calc(100vh - 470px);
        }
      }
    }
  }
}

.StartQnAScene {
  text-align: center;
  height: calc(100vh - 114px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 32px;

  svg {
    width: 80px;
  }

  p {
    margin: 22px 0 22px;
    font-size: 15px;
    line-height: 24px;
    padding: 0 14px;
  }

  button {
    font-size: 15px;
    margin: 10px 0 10px;
  }
}

.PollSceneStart {
  .StartQnAScene {
    svg {
      width: 52px;
    }
  }
}

.tetstoccer {
  width: 100%;
  position: relative;

  .tetstcorve {
    width: 280px;
    background: #c64f4f;
    height: 60px;
    margin-top: 40px;
    position: relative;
    margin-left: 18px;
    -webkit-clip-path: polygon(100% 25%, 98% 72%, 0 72%, 2% 24%);
    clip-path: polygon(100% 0%, 98% 100%, 0 100%, 2% 0%);
    box-shadow: 0px 0px 0px;
  }

  &::after {
    content: '';
    width: 12px;
    height: 100%;
    position: absolute;
    left: -3px;
    background-color: #000;
    z-index: 8;
    top: 0;
    transform: rotate(5deg);
    -webkit-clip-path: polygon(100% 25%, 98% 72%, 0 72%, 2% 24%);
    clip-path: polygon(100% 0%, 100% 100%, 0 100%, 4% 0%);
  }

  &::before {
    content: '';
    width: 12px;
    height: 80px;
    position: absolute;
    left: 40px;
    background-color: #0853db;
    z-index: 8;
    top: -10px;
    transform: rotate(5deg);
    -webkit-clip-path: polygon(100% 25%, 98% 72%, 0 72%, 2% 24%);
    clip-path: polygon(100% 0%, 100% 100%, 0 100%, 4% 0%);
    overflow: initial;
  }
}



.SettingPopHover {
  border: 1px #7e7e7e solid;
  position: absolute;
  width: 300px;
  background-color: #fff;
  z-index: 999999;
  top: 38px;
  left: -55px;
  padding: 15px;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 18%);
  border-radius: 6px;

  .form-group {
    margin-bottom: 15px;

    label {
      font-size: 14px;
      width: 100%;
      text-align: left;
      margin-bottom: 2px;
    }

    .form-control {
      background-color: #f1f3f4;
      border: 1px #707070 solid;
    }

    .SelectDropdown {
      .dropdown {
        .css-2b097c-container {
          .css-1pahdxg-control {
            height: 40px;
          }

          .css-yk16xz-control {
            height: 40px;

            .css-1wy0on6 {
              &::after {
                width: 12px;
                height: 12px;
                right: 18px;
                top: 10px;
              }
            }
          }
        }
      }

      .css-1hb7zxy-IndicatorsContainer {
        &::after {
          width: 12px;
          height: 12px;
          right: 18px;
          top: 10px;
        }
      }
    }
  }

  &::before {
    content: "";
    height: 16px;
    width: 16px;
    position: absolute;
    background-color: #fff;
    top: -8px;
    left: 55px;
    border-top: 1px solid #dcd8d8;
    border-left: 1px solid #dcd8d8;
    transform: rotate(45deg);
    z-index: -1;
  }
}



.backstage-dashboard.collapse-out-frontstage {
  .container-fluid {
    .MainViewBackStage {
      .alignAllDiv {
        .front-stage-stream-scroll {
          padding: 0 0;

          .row {

            // .front-stage-share-screen {
            //   padding: 0 0;
            // }
            .stage-user-list {
              .list-1 {
                .video_footer {
                  .bottom_footer {
                    .NameExpFull {
                      height: 46px;

                      h4 {
                        font-size: 24px;
                      }
                    }
                  }
                }
              }

              .list-2,
              .list-3,
              .list-4 {
                .video_footer {
                  .bottom_footer {
                    .NameExpFull {
                      height: 40px;

                      h4 {
                        font-size: 20px;
                      }
                    }
                  }
                }
              }

              .list-5,
              .list-6 {
                .video_footer {
                  .bottom_footer {
                    .NameExpFull {
                      height: 38px;

                      h4 {
                        font-size: 18px;
                      }
                    }
                  }
                }
              }

              .list-7,
              .list-8 {
                .video_footer {
                  .bottom_footer {
                    .NameExpFull {
                      height: 36px;

                      h4 {
                        font-size: 16px;
                      }
                    }
                  }
                }
              }

              .list-9,
              .list-10,
              .list-11,
              .list-12 {
                .video_footer {
                  .bottom_footer {
                    .NameExpFull {
                      height: 36px;

                      h4 {
                        font-size: 16px;
                      }
                    }
                  }
                }
              }

              .list-13,
              .list-14,
              .list-15,
              .list-16 {
                .video_footer {
                  .bottom_footer {
                    .NameExpFull {
                      width: 100%;
                      height: 36px;

                      h4 {
                        font-size: 12px;
                      }
                    }
                  }
                }
              }
            }
          }
        }

      }

      .ExpendNext {
        position: absolute;
        bottom: 5px;
        right: 5px;
        cursor: pointer;
        background-color: #000;
        border-radius: 100px;
        outline: 1px solid #fff;
        padding: 4px 35px 4px 12px;
        z-index: 995;

        span {
          color: #fff;
          font-size: 14px;
          display: block;

          &::before {
            content: "";
            display: inline-block;
            width: 10px;
            height: 10px;
            border-top: 2px solid #fff;
            border-right: 2px solid #fff;
            transform: rotate(-45deg);
            position: absolute;
            right: 14px;
            top: 12px;
          }
        }
      }

      .NextScenePreview {
        position: absolute;
        z-index: 6;
        bottom: 5px;
        right: 5px;
        border-radius: 4px;
        overflow: inherit !important;
        background: #333333;
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        width: 176px;
        height: 99px;
        cursor: pointer;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0px;
        border: 2px #fff solid;
        display: none;

        .nextScroll {
          position: absolute;
          width: 100%;
          top: -30px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 4px;
          background-color: #00000045;
          border-radius: 8px;

          span {
            font-size: 15px;
            letter-spacing: .4px;
          }

          svg {
            width: 12px;
            height: 12px;
          }
        }

        .ChangeWidthScroll {
          width: 100% !important;
          height: 100% !important;

          .flexDicSeps {
            width: 100% !important;
            height: 100% !important;

            .alignAllDiv {
              width: 100% !important;
              height: 100% !important;

              .front-stage-stream-scroll {
                width: 100% !important;
                height: 100% !important;

                .row {
                  .stage-user-list {
                    display: inline-flex;
                    height: 100% !important;
                    align-items: center;
                    flex-wrap: wrap;
                    padding: 2px !important;
                    justify-content: center;
                    width: 100% !important;

                    .list-1 {
                      flex: 0 0 auto;
                      width: 100%;
                      height: 100%;
                      padding: 2px 2px;
                    }

                    .list-2 {
                      flex: 0 0 auto;
                      width: 50%;
                      height: 50%;
                      padding: 2px 2px 2px;
                    }

                    .list-3 {
                      flex: 0 0 auto;
                      width: 50%;
                      height: 50%;
                      padding: 2px 2px 2px;
                    }

                    .list-4 {
                      flex: 0 0 auto;
                      width: 50%;
                      height: 50%;
                      padding: 2px 2px 2px;
                    }

                    .list-5 {
                      flex: 0 0 auto;
                      width: 33.33333333%;
                      height: 50%;
                      padding: 2px 2px 2px;
                    }

                    .list-6 {
                      flex: 0 0 auto;
                      width: 33.33333333%;
                      height: 50%;
                      padding: 2px 2px 2px;
                    }

                    .list-7 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 50%;
                      padding: 2px 2px 2px;
                    }

                    .list-8 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 50%;
                      padding: 2px 2px 2px;
                    }

                    .list-9 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 33.33333333%;
                      padding: 2px 2px 2px;
                    }

                    .list-10 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 33.33333333%;
                      padding: 2px 2px 2px;
                    }

                    .list-11 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 33.33333333%;
                      padding: 2px 2px 2px;
                    }

                    .list-12 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 33.33333333%;
                      padding: 2px 2px 2px;
                    }

                    .list-13 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 25%;
                      padding: 2px 2px 2px;
                    }

                    .list-14 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 25%;
                      padding: 2px 2px 2px;
                    }

                    .list-15 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 25%;
                      padding: 2px 2px 2px;
                    }

                    .list-16 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 25%;
                      padding: 2px 2px 2px;
                    }

                    .video_footer {
                      border-radius: 4px !important;
                    }
                  }

                  .stage-user-list {
                    .list-1 {
                      .video_footer {
                        .ShortName {
                          h4 {
                            font-size: 28px !important;
                            width: auto !important;
                            height: auto !important;
                            border: 0 !important;
                          }
                        }
                      }

                      .RightSvgIcon {
                        .mh-cursor-pointer {
                          padding: 6px;
                          top: 6px;
                          left: 6px;
                          width: 25px;
                          height: 25px;
                        }
                      }
                    }

                    .list-2,
                    .list-3,
                    .list-4 {
                      .video_footer {
                        .ShortName {
                          h4 {
                            font-size: 18px !important;
                            width: auto !important;
                            height: auto !important;
                            border: 0 !important;
                          }
                        }
                      }

                      .RightSvgIcon {
                        .mh-cursor-pointer {
                          padding: 6px;
                          top: 6px;
                          left: 6px;
                          width: 25px;
                          height: 25px;
                        }
                      }
                    }

                    .list-5,
                    .list-6,
                    .list-7,
                    .list-8 {
                      .video_footer {
                        .ShortName {
                          h4 {
                            font-size: 16px !important;
                            width: auto !important;
                            height: auto !important;
                            border: 0 !important;
                          }
                        }
                      }

                      .RightSvgIcon {
                        .mh-cursor-pointer {
                          padding: 6px;
                          top: 6px;
                          left: 6px;
                          width: 25px;
                          height: 25px;
                        }
                      }
                    }

                    .list-9,
                    .list-10,
                    .list-11,
                    .list-12 {
                      .video_footer {
                        .ShortName {
                          h4 {
                            font-size: 16px !important;
                            width: auto !important;
                            height: auto !important;
                            border: 0 !important;
                          }
                        }
                      }

                      .RightSvgIcon {
                        .mh-cursor-pointer {
                          padding: 6px;
                          top: 6px;
                          left: 6px;
                          width: 25px;
                          height: 25px;
                        }
                      }
                    }

                    .list-13,
                    .list-14,
                    .list-15,
                    .list-16 {
                      .video_footer {
                        .ShortName {
                          h4 {
                            font-size: 16px !important;
                            width: auto !important;
                            height: auto !important;
                            border: 0 !important;
                          }
                        }
                      }

                      .RightSvgIcon {
                        .mh-cursor-pointer {
                          padding: 6px;
                          top: 6px;
                          left: 6px;
                          width: 25px;
                          height: 25px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .BackstageInfoStream {
      .HeadingBstage {
        .ActionBcFcHeader {
          display: inline-flex;
          position: absolute;
          right: 60px;
          ttransition: all 0.6s ease;
          -webkit-transition: all 0.6s ease;
          -moz-transition: all 0.6s ease;
          -o-transition: all 0.6s ease;
          -o-transition: all 0.6s ease;
        }
      }
    }
  }

}

.backstage-dashboard {
  .container-fluid {
    .MainViewBackStage {
      .alignAllDiv {
        .front-stage-stream-scroll {
          .row {
            .stage-user-list {
              .list-1 {
                .video_footer {
                  .bottom_footer {
                    .NameExpFull {
                      width: 100%;
                      height: 42px;

                      h4 {
                        font-size: 20px;
                      }
                    }
                  }
                }
              }

              .list-2,
              .list-3,
              .list-4 {
                .video_footer {
                  .bottom_footer {
                    .NameExpFull {
                      height: 40px;

                      h4 {
                        font-size: 20px;
                      }
                    }
                  }
                }
              }

              .list-5,
              .list-6 {
                .video_footer {
                  .bottom_footer {
                    .NameExpFull {
                      height: 38px;

                      h4 {
                        font-size: 18px;
                      }
                    }
                  }
                }
              }

              .list-7,
              .list-8 {
                .video_footer {
                  .bottom_footer {
                    .NameExpFull {
                      height: 36px;

                      h4 {
                        font-size: 16px;
                      }
                    }
                  }
                }
              }

              .list-9,
              .list-10,
              .list-11,
              .list-12 {
                .video_footer {
                  .bottom_footer {
                    .NameExpFull {
                      height: 36px;

                      h4 {
                        font-size: 16px;
                      }
                    }
                  }
                }
              }

              .list-13,
              .list-14,
              .list-15,
              .list-16 {
                .video_footer {
                  .bottom_footer {
                    .NameExpFull {
                      width: 100%;
                      height: 36px;

                      h4 {
                        font-size: 12px;
                      }
                    }
                  }
                }
              }
            }

            .PollQnaBase {
              position: absolute;
              left: 0;
              bottom: 2px;
              width: 100%;
              z-index: 99;
              max-height: calc(100vh - 200px);
              overflow-y: auto;
              overflow-x: hidden;
              scrollbar-width: thin;

              .ScreenCast {
                width: 100%;
                margin: 0 0 15px;
                background-color: rgba(0, 0, 0, 0.32);
                padding: 12px 0px;
                border-radius: 6px;

                h4 {
                  background-color: #1a78e5;
                  color: #fff;
                  font-weight: 400;
                  font-size: 15px;
                  padding: 10px 14px;
                  border-radius: 4px;
                  text-align: left;
                }

                .OptionPoll {
                  width: 100%;

                  .row {
                    align-items: center !important;

                    .PollActionTab {
                      background-color: #f0f0f0;
                      display: inline-block;
                      text-align: left;
                      padding: 6px 18px;
                      font-size: 14px;
                      border-radius: 4px;
                      margin: 0 0 12px;
                      width: 100%;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: -webkit-box !important;
                      -webkit-line-clamp: 1;
                      -webkit-box-orient: vertical;
                    }

                    .option-1 {
                      flex: 0 0 auto;
                      width: 100%;
                    }

                    .option-2 {
                      flex: 0 0 auto;
                      width: 50%;
                    }

                    .option-3 {
                      flex: 0 0 auto;
                      width: 50%;
                    }

                    .option-4 {
                      flex: 0 0 auto;
                      width: 50%;
                    }

                    .option-5 {
                      flex: 0 0 auto;
                      width: 33.33333333%;
                    }

                    .option-6 {
                      flex: 0 0 auto;
                      width: 33.33333333%;
                    }

                    .option-7 {
                      flex: 0 0 auto;
                      width: 33.33333333%;
                    }

                    .option-8 {
                      flex: 0 0 auto;
                      width: 33.33333333%;
                    }
                  }
                }

                .row {
                  justify-content: left;
                  align-items: end !important;

                  .QuestionVote {
                    padding: 0px 0px !important;
                    background-color: #fff;
                    border-radius: 6px;
                    box-shadow: 0px 0px 6px rgb(0 0 0 / 5%);
                    position: relative;
                    width: 100%;
                    margin: 0px;
                    height: 100%;

                    .UpdateVoteStream {
                      position: relative;
                      width: 100%;
                      height: 100%;

                      .QnAuserImg {
                        width: 65px;
                        height: 65px;
                        background-color: #1a78e5;
                        border-radius: 8px;
                        margin-right: 6px;
                        position: absolute;
                        left: 15px;
                        top: 15px;
                        border: 1px #fff solid;

                        .img-fluid {
                          border-radius: 8px;
                          position: initial !important;
                        }
                      }

                      .ShowAnAQue {
                        width: calc(100% - 0px);
                        display: flex;
                        align-items: center;
                        background: #1a78e5;
                        height: 50px;
                        padding-left: 100px;
                        justify-content: space-between;
                        border-radius: 6px 6px 0px 0px;

                        .NameQnAuser {
                          padding: 5px 0;
                          display: flex;
                          justify-content: center;
                          flex-direction: column;
                          text-align: left;
                          width: 80%;

                          h4 {
                            font-size: 16px;
                            font-weight: 500;
                            margin: 0;
                            background-color: transparent;
                            padding: 0;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            width: 100%;
                            color: #fff;
                            ;
                          }
                        }

                        .QnaVoteCount {
                          padding: 0px 0px;
                          border-radius: 0;
                          display: flex;
                          justify-content: center;
                          margin-right: 22px;

                          svg {
                            fill: #ffffff;
                          }

                          span {
                            vertical-align: middle;
                            margin-left: 8px;
                            color: #fff;
                            box-shadow: 0px 0px 6px rgb(0 0 0 / 7%);
                            font-size: 15px;
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                          }
                        }
                      }
                    }

                    .ShowQueHere {
                      padding-left: 100px;
                      display: flex;
                      align-items: center;

                      p {
                        font-size: 14px;
                        line-height: 20px;
                        color: var(--dark-gray);
                        letter-spacing: 0.2px;
                        margin: 0;
                        padding: 6px 0;
                        text-align: left;
                        // overflow: hidden;
                        // text-overflow: ellipsis;
                        // display: -webkit-box;
                        // -webkit-line-clamp: 1;
                        // -webkit-box-orient: vertical;
                        width: 90%;
                      }
                    }
                  }
                }

              }
            }
          }
        }

      }

      .ExpendNext {
        position: absolute;
        bottom: 5px;
        right: 5px;
        cursor: pointer;
        background-color: #000;
        border-radius: 100px;
        outline: 1px solid #fff;
        padding: 4px 35px 4px 12px;
        z-index: 995;

        span {
          color: #fff;
          font-size: 14px;
          display: block;

          &::before {
            content: "";
            display: inline-block;
            width: 10px;
            height: 10px;
            border-top: 2px solid #fff;
            border-right: 2px solid #fff;
            transform: rotate(-45deg);
            position: absolute;
            right: 14px;
            top: 12px;
          }
        }
      }

      .NextScenePreview {
        position: absolute;
        z-index: 6;
        bottom: 5px;
        right: 5px;
        border-radius: 4px;
        overflow: inherit !important;
        background: #333333;
        color: white;
        font-size: 16px;
        font-weight: 400;
        width: 155px;
        height: 87px;
        cursor: pointer;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0px;
        border: 2px #fff solid;
        display: none;
        z-index: 1080;

        .nextScroll {
          position: absolute;
          width: 100%;
          top: -30px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 4px;
          background-color: #00000045;
          border-radius: 8px;

          span {
            font-size: 15px;
            letter-spacing: .4px;
          }

          svg {
            width: 12px;
            height: 12px;
          }
        }

        .ChangeWidthScroll {
          width: 100% !important;
          height: 100% !important;

          .flexDicSeps {
            width: 100% !important;
            height: 100% !important;

            .alignAllDiv {
              width: 100% !important;
              height: 100% !important;

              .front-stage-stream-scroll {
                width: 100% !important;
                height: 100% !important;

                .row {
                  .stage-user-list {
                    display: inline-flex;
                    height: 100% !important;
                    align-items: center;
                    flex-wrap: wrap;
                    padding: 2px !important;
                    justify-content: center;
                    width: 100% !important;

                    .list-1 {
                      flex: 0 0 auto;
                      width: 100%;
                      height: 100%;
                      padding: 2px 2px;
                    }

                    .list-2 {
                      flex: 0 0 auto;
                      width: 50%;
                      height: 50%;
                      padding: 2px 2px 2px;
                    }

                    .list-3 {
                      flex: 0 0 auto;
                      width: 50%;
                      height: 50%;
                      padding: 2px 2px 2px;
                    }

                    .list-4 {
                      flex: 0 0 auto;
                      width: 50%;
                      height: 50%;
                      padding: 2px 2px 2px;
                    }

                    .list-5 {
                      flex: 0 0 auto;
                      width: 33.33333333%;
                      height: 50%;
                      padding: 2px 2px 2px;
                    }

                    .list-6 {
                      flex: 0 0 auto;
                      width: 33.33333333%;
                      height: 50%;
                      padding: 2px 2px 2px;
                    }

                    .list-7 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 50%;
                      padding: 2px 2px 2px;
                    }

                    .list-8 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 50%;
                      padding: 2px 2px 2px;
                    }

                    .list-9 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 33.33333333%;
                      padding: 2px 2px 2px;
                    }

                    .list-10 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 33.33333333%;
                      padding: 2px 2px 2px;
                    }

                    .list-11 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 33.33333333%;
                      padding: 2px 2px 2px;
                    }

                    .list-12 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 33.33333333%;
                      padding: 2px 2px 2px;
                    }

                    .list-13 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 25%;
                      padding: 2px 2px 2px;
                    }

                    .list-14 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 25%;
                      padding: 2px 2px 2px;
                    }

                    .list-15 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 25%;
                      padding: 2px 2px 2px;
                    }

                    .list-16 {
                      flex: 0 0 auto;
                      width: 25%;
                      height: 25%;
                      padding: 2px 2px 2px;
                    }

                    .video_footer {
                      border-radius: 4px !important;
                    }
                  }

                  .stage-user-list {
                    .list-1 {
                      .video_footer {
                        .ShortName {
                          h4 {
                            font-size: 28px !important;
                            width: auto !important;
                            height: auto !important;
                            border: 0 !important;
                          }
                        }
                      }

                      .RightSvgIcon {
                        .mh-cursor-pointer {
                          padding: 6px;
                          top: 6px;
                          left: 6px;
                          width: 25px;
                          height: 25px;
                        }
                      }
                    }

                    .list-2,
                    .list-3,
                    .list-4 {
                      .video_footer {
                        .ShortName {
                          h4 {
                            font-size: 18px !important;
                            width: auto !important;
                            height: auto !important;
                            border: 0 !important;
                          }
                        }
                      }

                      .RightSvgIcon {
                        .mh-cursor-pointer {
                          padding: 6px;
                          top: 6px;
                          left: 6px;
                          width: 25px;
                          height: 25px;
                        }
                      }
                    }

                    .list-5,
                    .list-6,
                    .list-7,
                    .list-8 {
                      .video_footer {
                        .ShortName {
                          h4 {
                            font-size: 16px !important;
                            width: auto !important;
                            height: auto !important;
                            border: 0 !important;
                          }
                        }
                      }

                      .RightSvgIcon {
                        .mh-cursor-pointer {
                          padding: 6px;
                          top: 6px;
                          left: 6px;
                          width: 25px;
                          height: 25px;
                        }
                      }
                    }

                    .list-9,
                    .list-10,
                    .list-11,
                    .list-12 {
                      .video_footer {
                        .ShortName {
                          h4 {
                            font-size: 16px !important;
                            width: auto !important;
                            height: auto !important;
                            border: 0 !important;
                          }
                        }
                      }

                      .RightSvgIcon {
                        .mh-cursor-pointer {
                          padding: 6px;
                          top: 6px;
                          left: 6px;
                          width: 25px;
                          height: 25px;
                        }
                      }
                    }

                    .list-13,
                    .list-14,
                    .list-15,
                    .list-16 {
                      .video_footer {
                        .ShortName {
                          h4 {
                            font-size: 16px !important;
                            width: auto !important;
                            height: auto !important;
                            border: 0 !important;
                          }
                        }
                      }

                      .RightSvgIcon {
                        .mh-cursor-pointer {
                          padding: 6px;
                          top: 6px;
                          left: 6px;
                          width: 25px;
                          height: 25px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .BackstageInfoStream {
      .HeadingBstage {
        .ActionBcFcHeader {
          display: inline-flex;
          position: absolute;
          right: 12px;
          transition: all 0.6s ease;
          -webkit-transition: all 0.6s ease;
          -moz-transition: all 0.6s ease;
          -o-transition: all 0.6s ease;
          -o-transition: all 0.6s ease;
        }
      }
    }
  }

}

.backstage-dashboard.collapse-out-frontstage {
  .SideRightMenu {
    height: calc(100% - 120px);
    transition: all 0.6s ease;
    -webkit-transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    -o-transition: all 0.6s ease;
    -o-transition: all 0.6s ease;

    ul {
      li {
        margin: 14px 0px !important;
        padding: 10px 10px !important;

        .RightTopStyle {
          ul {
            li {
              margin: 0px !important;
              padding: 0px !important;
            }
          }
        }
      }
    }
  }

  .MainViewBackStage {
    height: calc(100vh - 120px) !important;
    transition: all 0.6s ease;
    -webkit-transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    -o-transition: all 0.6s ease;
    -o-transition: all 0.6s ease;

    .FlexResizeStep {
      .ChangeWithSize {
        .fullFreeContainer {
          .RemoveAllDiv {
            width: auto !important;
            height: 100% !important;
          }
        }
      }

      .SpeakerSizeShrink {
        width: calc(100% - 345px);

        .fullFreeContainer {
          width: 100% !important;
          height: 100% !important;
        }
      }
    }

    .alignAllDiv {
      .front-stage-stream-scroll {
        .row {
          .stage-user-list {

            .list-5,
            .list-6,
            .list-7,
            .list-8 {
              .video_footer {
                .bottom_footer {
                  h4 {
                    font-size: 14px;
                  }
                }
              }
            }

            .list-9,
            .list-10,
            .list-11,
            .list-12,
            .list-13 {
              .video_footer {
                .bottom_footer {
                  h4 {
                    font-size: 12px;
                    // span {

                    // }
                  }
                }
              }
            }

            .list-14,
            .list-15,
            .list-16,
            .list-17,
            .list-18 {
              .video_footer {
                .bottom_footer {
                  h4 {
                    font-size: 12px;
                  }
                }
              }
            }
          }

          .PollQnaBase {
            position: absolute;
            left: 0;
            bottom: 2px;
            width: 100%;
            z-index: 99;
            max-height: calc(100vh - 200px);
            overflow-y: auto;
            overflow-x: hidden;
            scrollbar-width: thin;

            .ScreenCast {
              width: 100%;
              margin: 0 0 15px;
              background-color: rgba(0, 0, 0, 0.32);
              padding: 12px 0px;
              border-radius: 6px;

              h4 {
                background-color: #1a78e5;
                color: #fff;
                font-weight: 400;
                font-size: 15px;
                padding: 10px 14px;
                border-radius: 4px;
                text-align: left;
              }

              .OptionPoll {
                width: 100%;

                .row {
                  align-items: center !important;

                  //justify-content: center;
                  .PollActionTab {
                    background-color: #f0f0f0;
                    display: inline-block;
                    text-align: left;
                    padding: 6px 18px;
                    font-size: 14px;
                    border-radius: 4px;
                    margin: 0 0 12px;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box !important;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                  }

                  .option-1 {
                    flex: 0 0 auto;
                    width: 100%;
                  }

                  .option-2 {
                    flex: 0 0 auto;
                    width: 50%;
                  }

                  .option-3 {
                    flex: 0 0 auto;
                    width: 50%;
                  }

                  .option-4 {
                    flex: 0 0 auto;
                    width: 50%;
                  }

                  .option-5 {
                    flex: 0 0 auto;
                    width: 33.33333333%;
                  }

                  .option-6 {
                    flex: 0 0 auto;
                    width: 33.33333333%;
                  }

                  .option-7 {
                    flex: 0 0 auto;
                    width: 33.33333333%;
                  }

                  .option-8 {
                    flex: 0 0 auto;
                    width: 33.33333333%;
                  }
                }
              }

              .row {
                justify-content: left;
                align-items: end !important;

                .QuestionVote {
                  padding: 0px 0px !important;
                  background-color: #fff;
                  border-radius: 6px;
                  box-shadow: 0px 0px 6px rgb(0 0 0 / 5%);
                  position: relative;
                  width: 100%;
                  margin: 0 0 22px;
                  height: 90px;

                  .UpdateVoteStream {
                    position: relative;
                    width: 100%;
                    height: 100%;

                    .QnAuserImg {
                      width: 65px;
                      height: 65px;
                      background-color: #1a78e5;
                      border-radius: 8px;
                      margin-right: 6px;
                      position: absolute;
                      left: 15px;
                      top: 15px;
                      border: 1px #fff solid;

                      .img-fluid {
                        border-radius: 8px;
                        position: initial !important;
                      }
                    }

                    .ShowAnAQue {
                      width: calc(100% - 0px);
                      display: flex;
                      align-items: center;
                      background: #1a78e5;
                      height: 50%;
                      padding-left: 100px;
                      justify-content: space-between;
                      border-radius: 6px 6px 0px 0px;

                      .NameQnAuser {
                        padding: 5px 0;
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        text-align: left;
                        width: 80%;

                        h4 {
                          font-size: 14px;
                          font-weight: 500;
                          margin: 0;
                          background-color: transparent;
                          padding: 0;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          display: -webkit-box;
                          -webkit-line-clamp: 1;
                          -webkit-box-orient: vertical;
                          width: 100%;
                          color: #fff;
                          ;
                        }
                      }

                      .QnaVoteCount {
                        padding: 0px 0px;
                        border-radius: 0;
                        display: flex;
                        justify-content: center;
                        margin-right: 22px;

                        svg {
                          fill: #ffffff;
                        }

                        span {
                          vertical-align: middle;
                          margin-left: 8px;
                          color: #fff;
                          box-shadow: 0px 0px 6px rgb(0 0 0 / 7%);
                          font-size: 15px;
                          display: inline-flex;
                          align-items: center;
                          justify-content: center;
                        }
                      }
                    }
                  }

                  .ShowQueHere {
                    padding-left: 100px;
                    height: 50%;
                    display: flex;
                    align-items: center;

                    p {
                      font-size: 14px;
                      line-height: 20px;
                      color: var(--dark-gray);
                      letter-spacing: 0.2px;
                      margin: 0;
                      text-align: left;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      -webkit-line-clamp: 1;
                      -webkit-box-orient: vertical;
                      width: 90%;
                    }
                  }
                }
              }

            }
          }
        }
      }
    }
  }

  .BackstageInfoStream {
    .BsMidAction {
      .ActionLive {
        .SettingPopHover {
          .form-group {
            .SelectDropdown {
              .dropdown {
                .css-2b097c-container {
                  .css-26l3qy-menu {
                    bottom: 35px;
                    top: auto;
                  }
                }
              }
            }
          }
        }
      }
    }
  }


}

.MediaImgsMix {
  border: 1px #1A4788 dashed !important;
  aspect-ratio: 16/9;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    aspect-ratio: 16/9;
    border-radius: 6px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background-position: 0px 0px, 10px 10px;
    background-size: 20px 20px;
    background-image: linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee 100%), linear-gradient(45deg, #eee 25%, white 25%, white 75%, #eee 75%, #eee 100%);
    object-fit: scale-down;
  }
}

.PdfFIleMix {
  border: 1px #1A4788 dashed !important;
  aspect-ratio: 16/9;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  word-break: break-word;
  font-size: 13px;
  padding: 0 12px;

}


.RemoveSpeaker {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 91;
  .pinRemoveAction {
    position: absolute;
    z-index: 95;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      background-color: #ffffff;
      width: 24px;
      height: 24px;
      box-shadow: 0px 2px 2px rgb(0 0 0 / 50%);
      padding: 0 0;
      display: inline-block;
      border-radius: 100px;
      margin-left: 8px;
      position: relative;
      cursor: pointer;

      svg {
        width: 34px;
        height: 34px;
        position: relative;
        left: -5px;
        top: -4px;
      }
    }
  }

  &::after {
    background-color: rgb(0 0 0 / 40%);
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.NotRemoveSpeaker {
  color: #f90;    
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 91;
  .pinRemoveAction {
    display: none;
  }
}

.ExpoCoversChats {
  .SlideCommonPopup {
    .StateEventDtl {
      .EventInfoStage {
        .PollInfos {
          height: calc(100vh - 208px) !important;
        }
      }
    }
  }
}

.ReadMorePopUp {
  .modal-dialog {
    max-width: 750px;

    .modal-content {
      .modal-header {
        padding: 0 0 16px !important;

        .modal-title {
          h4 {
            color: #227CE5;
            font-size: 18px;
            margin: 0;
          }
        }
      }

      .modal-body {
        .ScheduleDayEvent {
          margin-bottom: 14px;

          span {
            background-color: #ffffff;
            font-size: 12px;
            padding: 4px 10px;
            font-weight: 500;
            color: #1F1B20;
          }
        }

        .ReadMoreDeso {
          background-color: #F1F3F4;
          padding: 14px;
          border-radius: 6px;

          p {
            font-size: 14px;
            margin: 0;
            color: #1F1B20;
          }

          .SectionWatch {
            width: 100%;
            text-align: left;
            margin: 18px 0 2px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .RedButton {
              padding: 6px 16px;
              font-size: 15px;
              width: 145px;
            }

            .PopUpImgSpeaker {
              .SpkInfoImgs {
                width: 50px;
                height: 50px;
                display: inline-block;
                margin-right: 10px;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 100px;
                }
              }

              .SpkInfoCount {
                border: 2px #1A78E5 solid;
                color: #1A78E5;
                width: 46px;
                height: 46px;
                border-radius: 100px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                font-size: 16px;
              }
            }

            .BackAgain {
              .BlueinButton {
                padding: 6px 16px;
              }
            }
          }
        }
      }
    }
  }
}

.MobileAppFilter {
  display: none;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0 14px;

  .SelectSchedule {
    width: 80%;

    .PepleSerchHere {
      .SerchHere {
        background-color: #F1F3F4;
      }
    }
  }

  .InnerActionDotFill {
    display: inline-flex;

    .BgCommRd {
      background-color: #F1F3F4;
      border-radius: 100px;
      width: 44px;
      height: 44px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      // margin-left: 14px;
    }
  }
}

.FeedBackPopUp {
  .modal-dialog {
    max-width: 950px;

    .modal-content {
      padding: 0 0 !important;

      .modal-header {
        padding: 0px !important;

        .ModalCloseBtn {
          z-index: 6;
        }

        .modal-title {
          display: none;
        }
      }

      .modal-body {
        .FdInnrForm {
          height: 500px;

          .LeftFBDetail {
            max-width: 310px;
            float: left;
            background-color: #f2f2f2;
            padding: 26px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            flex-flow: wrap;
            border-radius: 12px 0px 0px 12px;

            h1 {
              font-weight: 600;
              color: #1A78E5;
              font-size: 32px;
              line-height: 46px;
              padding: 0 14px;
            }

            .FBIcoInfo {
              svg {
                width: 100%;
                height: 100%;
              }
            }
          }

          .RightFbDetail {
            float: left;
            width: calc(100% - 335px);
            padding: 25px 0px 0px 25px;
            height: 100%;

            .HeadingFb {
              border-bottom: 1px #CECECE solid;
              padding: 0 0 5px;

              h4 {
                color: #1A78E5;
                font-weight: 500;
                font-size: 22px;
                margin: 0 0 10px;
              }

              p {
                color: #333333;
                font-size: 15px;
                font-weight: 400;
                margin: 0;
              }
            }
          }

          .FbFormMixInfos {
            height: calc(100% - 138px);
            overflow-y: auto;
            overflow-x: hidden;
            scrollbar-width: thin;
            margin: 18px 0 0;

            .row {
              .col-md-12 {
                &:last-child {
                  .FeedForm {
                    border-bottom: 0px !important;
                    padding-bottom: 0px !important;
                  }
                }
              }
            }

            .form-group {
              .FeedForm {
                border-bottom: 1px #f0f0f0 solid;
                padding: 0 0 25px;
                width: 96%;

                p {
                  font-size: 14px;
                  margin: 0 0 10px;
                  font-weight: 400;
                }

                .MuiFormControl-root {
                  width: 96%;

                  .MuiInputBase-root {
                    width: 100%;

                    .MuiInputBase-input {
                      border: 1px #959595 solid;
                      height: 26px;
                      padding: 6px 12px 6px;
                      line-height: 24px;
                      font-size: 14px !important;
                      font-family: "Poppins", sans-serif !important;
                    }

                    &::after {
                      display: none;
                    }

                    &::before {
                      display: none;
                    }
                  }

                  .Mui-focused {
                    background-color: transparent !important;
                  }
                }

                .ClickInputs {
                  .area-check {
                    display: inline-flex;
                    align-items: center;
                    margin-right: 22px;
                    font-size: 14px;
                    margin-bottom: 3px;
                    margin-top: 3px;

                    input {
                      width: 18px;
                      height: 18px;
                      vertical-align: middle;
                      margin-right: 8px;
                      cursor: pointer;

                      &:first-child {
                        margin-left: 0px;
                      }
                    }

                    .MuiButtonBase-root {
                      padding: 0;
                      margin-right: 8px;
                    }

                    .PrivateSwitchBase-checked-2 {
                      span {
                        svg {
                          color: #0475ff;
                        }
                      }
                    }

                    .Mui-checked {
                      span {
                        svg {
                          color: #0475ff;
                        }
                      }
                    }

                  }
                }

                .MultipleSelect {
                  .Mui-focused {
                    background: transparent !important;
                  }

                  .MuiInputBase-root {
                    width: 96%;

                    input {
                      width: 18px;
                      height: 18px;
                      vertical-align: middle;
                      margin-right: 8px;

                      &:first-child {
                        margin-left: 0px;
                      }
                    }

                    &::after {
                      display: none;
                    }

                    &::before {
                      display: none;
                    }

                    .MuiSelect-root {
                      border: 1px #959595 solid;
                      height: 26px;
                      padding: 6px 12px 6px;
                      line-height: 24px;
                      font-size: 14px !important;
                      font-family: "Poppins", sans-serif !important;
                    }
                  }
                }
              }
            }
          }

          .FeedFormSubmit {
            width: 100%;
            border-top: 1px #f0f0f0 solid;
            position: relative;

            .MuiButtonBase-root {
              background-color: #4bd93f;
              text-transform: capitalize;
              color: #fff;
              font-size: 16px;
              font-family: "Poppins", sans-serif !important;
              font-weight: 500;
              padding: 6px 48px 6px 28px;
              position: absolute;
              top: 22px;
              right: 25px;
              border-radius: 100px;

              .MuiButton-label {
                span {
                  width: 16px;
                  height: 16px;
                  display: flex;
                  position: absolute;
                  right: 20px;

                  svg {
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.SpeakerGpList {
  .modal-dialog {
    max-width: 360px;

    .modal-content {
      height: calc(100vh - 180px);
      padding: 0px !important;

      .modal-header {
        border-bottom: 1px solid #dee2e6 !important;
        padding: 20px 20px 20px !important;

        .ModalCloseBtn {
          right: 10px;
          top: 18px;
        }

        .modal-title {
          display: block !important;

          h4 {
            color: #1A78E5;
          }
        }

        .ModalCloseBtn {
          z-index: 5;
        }
      }

      .modal-body {
        .SpeakerList {
          .SpeakerListSection {
            margin: 14px 0 0px;
            display: block;
            overflow-x: hidden;
            overflow-y: auto;
            scrollbar-width: thin;
            width: 100%;
            height: calc(100vh - 274px);
            padding: 0px 18px 0px;

            .EventSpeakerExp {
              background-color: var(--light-gray);
              display: inline-flex;
              padding: 10px 12px;
              border-radius: 8px;
              min-width: 100%;
              min-height: 76px;
              align-items: center;
              margin: 0 0 12px;
              align-items: center;

              .ImgSpeakerBg {
                width: 45px;
                height: 45px;

                .img-fluid {
                  border-radius: 100px;
                  height: 100%;
                  object-fit: cover !important;
                }
              }

              .SpeakerNamePos {
                width: 135px;
                margin-left: 12px;
                padding: 3px 0;

                .SpeakerDegDetail {
                  h4 {
                    font-size: 14px;
                    margin: 0 0 3px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    color: #8944FC;
                    font-weight: 500;

                    strong {
                      font-weight: 600;
                    }
                  }

                  .DetailPostMix {
                    cursor: pointer;

                    p {
                      font-size: 13px;
                      margin: 0;
                      color: #383838;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      -webkit-line-clamp: 1;
                      -webkit-box-orient: vertical;
                      line-height: 16px;
                    }

                    span {
                      font-size: 13px;
                      margin: 0;
                      color: #383838;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      -webkit-line-clamp: 1;
                      -webkit-box-orient: vertical;

                      b {
                        font-weight: 500;
                      }
                    }
                  }
                }
              }

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }

      }
    }
  }
}

.MuiPopover-root {
  .MuiPaper-root.expoToggleDots {
    right: 58px !important;
    left: auto !important;

  }
}

.MuiPopover-root {
  .expoToggleDots {
    .ToogglePopover {
      &::before {
        content: "";
        left: auto !important;
        transform: rotate(135deg) !important;
        right: -8px !important;
      }
    }
  }
}

.SpeakerStageList {
  width: 100%;

  .EventSpeakerStage {
    background-color: #DCDCDC;
    display: inline-flex;
    padding: 8px 12px;
    border-radius: 8px;
    width: 208px;
    min-height: 76px;
    align-items: center;
    margin: 5px 6px 5px;

    .ImgSpeakerBg {
      width: 40px;
      height: 40px;

      .img-fluid {
        border-radius: 100px;
        height: 100%;
        object-fit: cover !important;
      }
    }

    .SpeakerNamePos {
      width: 135px;
      margin-left: 12px;
      padding: 3px 0;

      .SpeakerDegDetail {
        h4 {
          font-size: 14px;
          margin: 0 0 3px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          color: #333333;

          strong {
            font-weight: 600;
          }
        }

        .DetailPostMix {
          cursor: pointer;

          p {
            font-size: 12px;
            margin: 0;
            color: #616164;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            line-height: 16px;
          }

          span {
            font-size: 12px;
            margin: 0;
            color: #383838;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;

            b {
              font-weight: 500;
            }
          }
        }
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.Stage-Back-Event-Info {
  padding: 4px 0 0;
  height: 176px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  width: 100%;

  .EventBtmInfo {
    padding: 12px 0 0;

    h2 {
      color: #1A78E5;
      font-size: 20px;
      margin: 0 0 6px;
    }

    p {
      color: #333333;
      font-size: 14px;
      margin: 0;
    }
  }
}

.audience-front-stage-roll.collapse-out-frontstage.screen-stage-main-div {
  .MainViewBackStage {
    .alignAllDiv {
      .front-stage-stream-scroll {
        aspect-ratio: 16/9 !important;
        margin: 0px auto 0;
        padding: 0px 0px;
        border-radius: 10px;
        width: 100% !important;
        //height: calc(100vh - 135px) !important;
      }
    }
  }
}

.audience-front-stage-roll.screen-stage-main-div {
  .MainViewBackStage {
    .FlexResizeStep {
      .ChangeWithSize {
        .fullFreeContainer {
          .alignAllDiv {
            .front-stage-stream-scroll {
              .row {
                .stage-user-list {
                  height: calc(100vh - 360px) !important;
                  padding-right: 6px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.audience-front-stage-roll.collapse-out-frontstage.screen-stage-main-div {
  .MainViewBackStage {
    .FlexResizeStep {
      .ChangeWithSize {
        .fullFreeContainer {
          .alignAllDiv {
            .front-stage-stream-scroll {
              .row {
                .stage-user-list {
                  height: calc(100vh - 264px) !important;
                  padding-right: 6px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.ActiveGreen {
  position: absolute;
  width: 9px;
  height: 9px;
  background-color: #2bba20;
  border-radius: 100px;
  left: 1px;
  top: 0px;
}

.AddSpeakerInfo {
  .modal-dialog {
    max-width: 350px;

    .modal-content {
      height: 512px;

      .modal-header {
        padding: 16px 30px 16px !important;
        border: 1px solid #dee2e6 !important;
        border-radius: 20px 20px 0px 0px;
      }

      .modal-body {
        padding: 0px !important;

        .FullYourProfile {
          padding-left: 0px !important;

          .InfoInterestEdit {
            .ProfileOverflow {
              height: 360px;
              padding: 12px 20px !important;
              margin-top: 10px;
              padding-bottom: 0px !important;

              .ContryBothPhone {
                .DialCodePin {
                  .TimeZoneProfile {
                    .TimeZoneActive {
                      top: 46px !important;

                      .ZoneSearchHere {
                        .SearchBarInput {
                          height: 36px;
                        }
                      }
                    }
                  }
                }
              }
            }

            .FooterButton {
              .EditSaveNext {
                padding: 0 30px;
              }
            }
          }
        }
      }
    }
  }
}

.ArchivedStageRecord.audience-front-stage-roll {
  .MainViewBackStage {
    height: calc(100vh - 68px) !important;

    .FlexResizeStep {
      .ChangeWithSize {
        width: 100% !important;
      }
    }
  }
}

.mTop-50 {
  margin-top: 50px;
}

.auto-none {
  overflow-y: initial !important;
  scrollbar-width: thin;
}

.TimeZoneFile {
  .EditInfoDetails {
    label {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 6px;
      color: #676767;
    }

    .TimeZoneProfile {
      position: relative;

      .ZoneSelectBox {
        position: relative;

        .valueInput {
          width: 100%;
          height: 50px;
          border-radius: 6px;
          font-size: 14px;
          padding: 0 12px;
          border: 1px #929292 solid;
          color: var(--dark-gray);
          cursor: pointer;
          position: relative;
          z-index: 9;
          background-color: transparent;

          &:hover {
            border: 1px #929292 solid !important;
            box-shadow: none;
            outline: none;
          }

          &:focus {
            box-shadow: none;
            outline: none;
            border: 1px #929292 solid !important;
          }
        }

        &:after {
          content: "";
          display: inline-block;
          width: 11px;
          height: 11px;
          border-top: 2px solid #000;
          border-right: 2px solid #000;
          transform: rotate(135deg);
          position: absolute;
          right: 18px;
          top: 16px;
          transition: all 0.6s ease;
          -webkit-transition: all 0.6s ease;
          -moz-transition: all 0.6s ease;
          -o-transition: all 0.6s ease;
          -o-transition: all 0.6s ease;
          z-index: 4;
        }
      }

      .arrow-icon {
        &:after {
          transform: rotate(-45deg);
          top: 22px;
          transition: all 0.5s ease;
          -webkit-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
        }
      }

      .TimeZoneActive {
        border: 1px #707070 solid;
        margin: 8px 0 0;
        border-radius: 10px;
        padding: 10px 14px 10px;
        position: absolute;
        left: 0;
        top: 48px;
        width: 100%;
        z-index: 9;
        background-color: #fff;

        .ZoneSearchHere {
          width: 100%;
          margin: 0 0 0;
          position: relative;

          .SearchBarInput {
            width: 100%;
            height: 45px;
            border-radius: 100px;
            font-size: 14px;
            padding: 0 12px;
            border: 1px #929292 solid;
            color: var(--dark-gray);
            padding-left: 46px;

            &:hover {
              border: 1px #929292 solid !important;
              box-shadow: none;
              outline: none;
            }

            &:focus {
              box-shadow: none;
              outline: none;
              border: 1px #929292 solid !important;
            }
          }

          svg {
            position: absolute;
            left: 14px;
            top: 10px;
            width: 28px;
            height: 28px;
          }
        }

        .ZoneOptionBox {
          height: 225px;
          overflow-y: auto;
          scrollbar-width: thin;
          margin-top: 12px;

          ul {
            margin: 0;
            padding: 0;
            position: relative;
            display: inline-block;

            li {
              display: block;
              font-size: 14px;
              margin: 6px 0;
              cursor: pointer;
              width: 100% !important;

              &:first-child {
                margin-top: 0px;
              }
            }
          }
        }
      }
    }
  }
}

.AdvanceFormMicrosite {
  .modal-dialog {
    max-width: 750px;
    height: auto !important;
    margin: 30px auto !important;

    .modal-content {
      border-radius: 8px 0px 8px 8px !important;

      .modal-header {
        padding: 8px !important;
        border: 0px !important;
      }

      .modal-body {
        .EventTypeAdvamce {
          .EnterEventAction {
            width: 60%;
            margin: 0px auto;

            .EventTypeActonIn {
              h4 {
                font-size: 20px;
                color: var(--black);
                font-weight: 500;
                margin: 14px 0 38px;
                text-align: center;
              }

              .form-group {
                label {
                  font-size: 13px;
                  font-weight: 400;
                  margin-bottom: 10px;
                }

                .SelectDdl {
                  .form-control {
                    z-index: 5;
                  }

                  &::after {
                    z-index: 2;
                  }
                }

                .form-control {
                  border: 1px #a5a5a5 solid;
                  border-radius: 100px;
                  padding: 14px 28px;
                  color: #333333;
                  height: 50px;
                  font-weight: 400;
                  font-size: 14px;
                  background-color: transparent !important;
                }

                .EnterBtn {
                  // background-color: #1a78e5 !important;
                  // color: #fff !important;
                  margin-top: 15px;
                }

                .css-2b097c-container {
                  .css-yk16xz-control {
                    .css-1wa3eu0-placeholder {
                      font-weight: 300;
                    }
                  }

                  .css-1pahdxg-control {
                    .css-1wa3eu0-placeholder {
                      font-weight: 300;
                    }
                  }
                }
              }

              .CheckHere {
                label {
                  p {
                    color: #333 !important;
                  }

                  h1,
                  h2,
                  h3,
                  h4,
                  h5,
                  p,
                  a {
                    display: inline-block !important;
                    margin: 0 !important;
                    word-break: break-word !important;
                    padding-left: 27px !important;
                    background-color: initial !important;
                    color: #333 !important;
                    font-family: "Poppins", sans-serif !important;
                    font-size: 14px !important;
                  }

                  ol {
                    padding-left: 2.8rem;
                  }

                  ul {
                    padding-left: 2.8rem;
                  }
                }

                .check-group {
                  input:checked+label:before {
                    border: 2px #333333 solid;
                  }

                  input:checked+label:after {
                    border-color: #333333;
                  }

                  label:before {
                    border: 2px #333333 solid;
                  }
                }
              }
            }
          }
        }
      }
    }

  }
}

.CheckHere {
  label {
    p {
      color: #333 !important;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    p {
      display: inline-block !important;
      margin: 0px !important;
      word-break: break-word !important;
      padding-left: 27px !important;
      background-color: transparent !important;
      color: #fff !important;
    }
  }
}

.EventTypeActonIn {
  .SelectDropdown {
    .dropdown {
      .SelectDdl {
        position: relative;

        &::after {
          content: "";
          width: 10px;
          height: 10px;
          border-top: 2px solid #363636;
          border-right: 2px solid #363636;
          position: absolute;
          right: 25px;
          top: 18px;
          background-image: none;
          transform: rotate(135deg);
          -webkit-transform: rotate(135deg);
          transition: all 0.5s ease;
          -webkit-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
          z-index: 8;
        }
      }
    }
  }
}

.HeaderTopExp {
  width: 100%;
  background-color: #8944fb;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  height: 85px;

  .LogoInsetHere {
    display: flex;
    background-color: #fff;
    height: 100%;
    align-items: center;
    padding: 8px 8px;
    border-radius: 6px;

    .InnerBgLogo {
      img {
        object-fit: scale-down;
        width: 100%;
        height: 68px;
        position: relative;
        max-height: 100%;
      }
    }
  }

  .DateExperienceHere {
    text-align: right;

    h3 {
      color: #fff;
      font-size: 18px;
      margin: 0 0 6px;
    }

    p {
      color: #fff;
      margin: 0;
      font-size: 13px;
    }
  }
}

.scheduleTages {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin: 18px 0 0px;

  .TagSelect {
    width: 112px;
    margin-right: 15px;

    p {
      color: #fff;
      margin: 0;
      font-size: 15px;
      font-weight: 400;
    }
  }

  .TagNameList {
    width: 84%;
    position: relative;

    ul {
      padding: 0px;
      margin: 0px;

      li {
        display: inline-block;
        color: #ffffff;
        background: #1d1d1d;
        border: 1px #525252 solid;
        border-radius: 100px;
        padding: 8px 2px;
        font-weight: 300;
        position: relative;
        text-align: center;

        svg {
          margin-right: 4px;
        }

        .check-group {
          margin: 0px !important;
          display: inline-flex;

          label {
            color: #ffffff;
            font-weight: 300;
            font-size: 13px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;

            &::before {
              display: none;
            }
          }

          input:checked+label:before {
            background-color: transparent !important;
          }

          input:checked+label:after {
            left: 3px !important;
            top: 3px !important;
            border-width: 0 2px 2px 0 !important;
            border: solid #ffffff;
            border: solid #ffffff
          }

          input:checked+label {
            padding-left: 20px;
            color: #fff;
          }

          input {
            display: initial;
            opacity: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 5;
          }
        }
      }

      .slick-slider {
        position: initial;

        .slick-list {
          .slick-track {
            .slick-slide {
              padding: 0 4px;
            }
          }
        }

        .slick-arrow {
          &::before {
            display: none;
          }
        }

        .slick-prev {
          left: -18px;
          z-index: 9;
          text-align: center;
        }

        .slick-next {
          right: -26px;
          z-index: 9;
          text-align: center;
        }
      }
    }
  }
}

.TagNameList::-webkit-scrollbar {
  width: 4px;
  background-color: #e7e7e7;
  height: 2px;
  border-radius: 4px;
}

.TagNameList::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #1a78e5;
}

.RecommendedValue {
  margin: 10px 0 0;
  border-bottom: 1px #ded7d7 solid;

  b {
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 600;
  }

  ul {
    margin: 0;
    position: relative;

    li {
      display: block;
      font-size: 14px;
      color: #1A78E5;
      margin: 2px 0 8px;
      cursor: pointer;
      position: relative;
      width: 100%;

      &::before {
        right: 12px;
        top: 2px;
        width: 8px;
        height: 14px;
        border: solid #1A78E5;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        content: "";
        position: absolute;
      }

      &:hover {
        background-color: #fbfbfb;
      }
    }
  }
}

.socialMicroInfo {
  text-align: center;

  h4 {
    font-size: 19px;
    font-weight: 500;
    color: #333333;
    margin: 0;
  }

  ul {
    margin: 34px 0 20px;
    padding: 0px;

    li {
      margin: 0 14px;
      cursor: pointer;
      display: inline-block;

      svg {
        width: 55px;
        height: 55px;
      }
    }
  }

  .SocialCopyLink {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: 0px auto 14px;

    .LinkUrl {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow-x: hidden;
      background-color: #F1F3F4;
      padding: 10px 14px;
      border-radius: 4px;
      font-size: 13px;

      span {
        margin-right: 4px;

        svg {
          width: 15px;
          height: 15px;
        }
      }
    }

    .LinkCpyBtn {
      margin-left: 12px;

      .btn-primary {
        background-color: #1A78E5;
        border-color: #1A78E5;
        font-size: 14px;
        text-transform: uppercase;
        padding: 8px 16px;
        -webkit-transition-duration: 0.4s;
        /* Safari */
        transition-duration: 0.4s;
        text-decoration: none;
        overflow: hidden;
        position: relative;

        &:after {
          content: "";
          background: #96c7ff;
          display: block;
          position: absolute;
          padding-top: 300%;
          padding-left: 350%;
          margin-left: -20px !important;
          margin-top: -120%;
          opacity: 0;
          transition: all 0.8s
        }

        &:active {
          &:after {
            padding: 0;
            margin: 0;
            opacity: 1;
            transition: 0s
          }
        }
      }
    }
  }
}

.SocialIconPopup {
  .modal-dialog {
    max-width: 420px
  }
}

.RoomScheduleTable {
  height: calc(100vh - 168px);
  overflow-y: auto;
  padding: 0 52px 0;
  margin: 14px 0 0;
  scrollbar-width: thin;
}

.TagRoomsInfo {
  height: calc(100vh - 235px);
  overflow-y: auto;
  scrollbar-width: thin;
  padding: 5px 52px 0;
}


.portrait-content {
  display: none;
}

.ScheduleDayStage {
  .SelectDayStage {
    .DayScheduleStages {
      .SelectDropdown {
        .SeleftDDl {
          .schedule-dropdown {
            transform: initial;
            top: 48px;
            border-radius: 10px;
            overflow: hidden !important;
            padding: 7px 4px 0 0px !important;
            max-height: 160px !important;

            .DdlScheduleFix {
              width: 100%;
              max-height: 145px !important;
              overflow-y: auto;
              padding: 0px 12px 0 12px !important;
              scrollbar-width: thin;

              &::-webkit-scrollbar-track {
                border-radius: 4px;
                box-shadow: inset 0 0 5px #f1f0f0;
                background-color: #fffcfc;
              }

              &::-webkit-scrollbar-thumb {
                border-radius: 4px;
                background-color: #e0e0e0;
              }

              &::-webkit-scrollbar {
                width: 6px;
              }

              .dropdown-item {
                font-size: 14px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                border-bottom: 1px solid rgba(0, 0, 0, 0.06);
                padding: 5px 16px;
                line-height: 24px !important;

                &:hover {
                  background-color: #f8f8f8;
                  color: #212529;
                }

                &:focus {
                  background-color: #f8f8f8;
                  color: #212529;
                }
              }
            }
          }
        }
      }
    }
  }
}

.StageTabCollapes {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  z-index: 99;
  display: none;

  .MobileStageFtr {
    width: 100%;
    background-color: var(--light-gray);
    border-radius: 10px 10px 0px 0px;
    //border: 1px #000 solid;
    z-index: 8;
    display: none;

    .CollapesStage {
      width: 32px;
      height: 32px;
      background: #fff;
      position: absolute;
      left: 14px;
      top: 12px;
      box-shadow: 0px 0px 2px #000;
      border-radius: 4px;
      z-index: 6;

      &:after {
        content: "";
        display: inline-block;
        width: 11px;
        height: 11px;
        border-top: 2px solid #000;
        border-right: 2px solid #000;
        transform: rotate(135deg);
        position: absolute;
        right: 10px;
        top: 8px;
        transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        z-index: 4;
      }
    }

    .collapseStageBtm {
      &::after {
        transform: rotate(-45deg);
        right: 10px;
        top: 13px;
        transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
      }
    }

    .nav-tabs {
      justify-content: space-evenly !important;
      padding: 0px !important;
      padding-left: 20px !important;

      li {
        width: 50%;
        display: inline-block;
        cursor: pointer;
        margin: 0 0 0px;
        text-align: center;
        //padding: 6px 0;
        position: relative;

        .nav {
          justify-content: center;
          width: auto;

          .nav-link {
            color: #333;
            border: 0px;
            border-bottom: 2px transparent solid;
            background-color: transparent !important;
            box-shadow: none !important;
            border-radius: 0px !important;
            padding: 12px 0;
            width: 45%;
            font-size: 18px;
            font-weight: 600;

            &.active {
              border-bottom: 3px #1A78E5 solid;
              padding: 12px 0;
              background-color: transparent !important;
              box-shadow: none !important;
              border-radius: 0px !important;
              width: 45%;
              color: #1A78E5;
            }
          }
        }

        &::after {
          content: "";
          position: absolute;
          border-right: 1px #a8a8a8 solid;
          height: 32px;
          top: 10px;
          right: 0px;
        }

        &:last-child {
          &::after {
            display: none;
          }
        }
      }
    }
  }
}

.MobileStageDemo {
  position: absolute;
  left: 0;
  top: 0;
}

.vjs-modal-dialog-content {
  word-break: break-word;
}

.DeviceCompatibility {
  .modal-dialog {
    max-width: 710px;
    height: calc(100vh - 50px);
    margin: 0 auto !important;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0s ease-out !important;
    transform: translate(0, 0px) !important;

    .modal-content {
      background-color: transparent;

      .DarkBlurBackground {
        .CheckDevice {
          width: 100%;
          background-color: #ffffff;
          border-radius: 12px;

          .DeviceCompTable {
            width: 100%;

            .modal-body {
              padding: 0;

              .HeaderCheckSystem {
                //background-color: var(--light-gray);
                border-bottom: 1px rgb(112 112 112 / 21%) solid;
                padding: 18px 20px;
                border-radius: 24px 24px 0 0;

                h4 {
                  margin: 0;
                  font-size: 18px;
                  font-weight: 500;
                  color: #227CE5;
                }
              }

              .SysInfoCheck {
                padding: 10px 22px 24px;

                .FormGroupCheck {
                  display: flex;
                  background-color: #F1F3F4;
                  position: relative;
                  margin: 16px 0;
                  padding: 10px 14px;
                  align-items: center;
                  justify-content: space-between;
                  border-radius: 6px;

                  .recomdBtn {
                    color: #4285F4;
                    background-color: #fff;
                    font-size: 12px;
                    padding: 4px 12px;
                    border-radius: 100px;
                    border: 0px;
                    outline: none;
                    box-shadow: none;
                    position: absolute;
                    right: 48px;
                    bottom: 16px;

                    &:hover {
                      border: 0px;
                      outline: none;
                      box-shadow: none;
                    }

                    &:focus {
                      border: 0px;
                      outline: none;
                      box-shadow: none;
                    }
                  }

                  .SysInfosDevice {
                    font-size: 14px;
                    display: flex;
                    align-items: center;

                    span {
                      width: 26px;
                      height: 26px;
                      display: inline-block;
                      margin-right: 5px;

                      svg {
                        width: 100%;
                        height: 100%;
                      }
                    }

                    p {
                      font-size: 14px;
                      margin: 0px;

                      small {
                        display: block;
                        font-size: 10px;
                      }
                    }
                  }

                  .SystemActionCheck {
                    width: 16px;
                    height: 16px;
                    display: inline-flex;

                    svg {
                      width: 100%;
                      height: 100%;
                    }
                  }

                  .SoundSpeakerCheck {
                    width: 70%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .SpeakerGroup {
                      width: 62%;

                      .SoundSpeaker {
                        p {
                          margin: 0;
                          font-size: 12px;
                          font-weight: 400;
                        }

                        audio {
                          height: 40px;
                          margin: 5px 0 5px;
                          width: 100%;
                          border-radius: 100px;
                        }
                      }

                      .ActionWorking {
                        margin-top: 12px;
                        padding-bottom: 10px;

                        .TestSound {
                          background-color: transparent;
                          border: 1px var(--blue) solid;
                          border-radius: 100px;
                          font-size: 14px;
                          padding: 2px 30px;
                          margin-right: 8px;
                          color: var(--blue);
                          box-shadow: 0px 0px 1px var(--blue);
                          text-transform: capitalize;
                        }

                        .YesTestSound {
                          background-color: var(--blue);
                          border: 1px var(--blue) solid;
                          text-transform: capitalize;
                          border-radius: 100px;
                          font-size: 14px;
                          padding: 2px 30px;
                          margin-right: 8px;
                          color: var(--white);
                          box-shadow: 0px 0px 1px var(--blue);

                          &:hover {
                            background-color: var(--blue);
                            color: #fff;
                          }

                          &:focus {
                            background-color: var(--blue);
                            color: #fff;
                          }
                        }
                      }
                    }
                  }

                  .loader {
                    width: 24px;
                    height: 24px;
                    margin: 0;
                  }
                }

                .JoinTableBtn {
                  text-align: right;

                  .BlueinButton {
                    width: auto;
                    font-size: 15px;
                    letter-spacing: .5px;
                    padding: 4px 32px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-panel {
  background-color: #fff;
}

.mobileSocialPopup {
  display: none !important;
}

.PresAudiPopup {
  .modal-dialog {
    max-width: 650px;

    .modal-content {
      padding: 0px 0px;

      .modal-header {
        border-bottom: 1px solid #dee2e6;
        padding: 16px 18px;

        .modal-title {
          h4 {
            color: #1A78E5;
            font-size: 20px;
          }
        }

        .ModalCloseBtn {
          top: 15px;

          .MuiButton-label {
            height: 26px;
            width: 26px;
          }
        }
      }

      .modal-body {
        .ShareScreenWith {
          padding: 40px 0px;

          .row {
            margin: 0;
          }

          .ShareInfoLive {
            text-align: center;
            width: 220px;
            margin: 0px auto;

            .ShareIconLt {
              background-color: #F0F0F0;
              text-align: center;
              width: 100%;
              height: 180px;
              margin: 0 auto;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 8px;
            }

            .ShareActionInfo {
              .sharefileType {
                height: 0;
                overflow: hidden;
                width: 0;
              }

              .shareIt {
                border: 1px #333333 solid;
                border-radius: 100px;
                font-size: 15px;
                padding: 6px 20px;
                margin: 18px 0 15px;
              }

              p {
                color: #333;
                font-size: 14px;
                font-weight: 400;
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}

.sharefileType+label {
  border: 1px #333333 solid;
  border-radius: 100px;
  font-size: 14px;
  padding: 7px 20px;
  margin: 18px 0 15px;
}

.LoaderOverlay {
  width: 100% !important;
  height: 100% !important;
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(0 0 0 / 52%);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 40px;
    height: 40px;
    position: relative;
    z-index: 999999;
  }
}

.slide-preview {
  background-color: #000;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 6px;
  width: 160px;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 0;
  border-radius: 5px;
  z-index: 98;
  .PreviewSlideBtm {
    button {
      background-color: transparent;
      border: 0;
      padding: 0;
      width: 9px;
      height: 9px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    span {
      color: #fff;
      font-size: 11px;
      letter-spacing: 2px;
      font-family: sans-serif;
    }
  }

  .SlideTxtprev {
    margin-left: 8px;

    p {
      color: #fff;
      font-size: 11px;
      margin: 0;
    }
  }
}

.Toastify__close-button {
  opacity: 1;

  svg {
    fill: #ffffff !important;
    height: 16px !important;
    width: 16px !important;
  }
}

.Toastify__close-button {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 1 !important;
}

.Toastify {
  .Toastify__toast-container {
    width: 600px;
    top: 3.7em !important;

    .Toastify__toast {
      min-height: 54px;
      border-radius: 100px;
      width: 100%;
      position: absolute !important;
      padding: 2px 2px !important;
      top: 0px !important;

      .Toastify__toast-body {
        padding-left: 14px;
        display: inline-flex;
        align-items: center;

        span {
          svg {
            width: 22px;
            height: 22px;
            fill: #fff !important;

            path {
              fill: #fff !important;
            }
          }
        }

        div {
          padding-left: 10px;

          p {
            margin: 0;
            font-weight: 300;
            font-size: 14px;
            padding-right: 22px;
            color: #fff;
            letter-spacing: .4px;
            line-height: 19px;

            strong {
              font-weight: 600;
            }
          }

          small {
            font-weight: 500;
            font-size: 9px;
            font-family: "Poppins";
            color: #616164;
          }
        }
      }
    }
  }
}

// .Toastify__progress-bar {
//   height: 2px !important;
//   opacity: 1 !important;
//   background-color: #fff !important;
// }
.Toastify__toast--success {
  border: 1px #34A853 solid !important;
}

.Toastify__toast--success {
  background: #34A853 !important;
}

.Toastify__toast--error {
  border: 1px #DE3C34 solid !important;
}

.Toastify__toast--error {
  background: #DE3C34 !important;
}

.Toastify__toast--warning {
  border: 1px #FF9701 solid !important;
}

.Toastify__toast--warning {
  background: #FF9701 !important;
}

.Toastify__toast--info {
  border: 1px #1A78E5 solid !important;
}

.Toastify__toast--info {
  background: #1A78E5 !important;
}

#aboutInfo {
  display: none;
}

.VirtuslBgPreview {
  .modal-dialog {
    max-width: 950px;
    height: calc(100vh - 50px);
    margin: 0 auto !important;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0s ease-out !important;
    transform: translate(0, 0px) !important;

    .modal-content {
      border-radius: 8px;
      padding: 0 24px;

      .modal-header {
        padding: 0px;

        .TableHeader {
          //background-color: var(--light-gray);
          padding: 24px 0px;
          border-radius: 8px 8px 0 0;
          width: 100%;

          h4 {
            margin: 0;
            font-size: 16px;
            font-weight: 450;
            color: #1A78E5;
          }
        }
      }

      .modal-body {
        padding: 1.5rem 0;

        .EfectFullBg {
          display: flex;
          //align-items: center;
          justify-content: center;

          .LeftChageBg {
            aspect-ratio: 16/9;
            width: 76%;
            margin-right: 20px;

            img {
              border-radius: 8px;
              aspect-ratio: 16/9;
              width: 100%;
            }
          }

          .RightAllBg {
            width: 22%;
            position: relative;

            .noBgBlur {
              margin-bottom: 20px;

              p {
                color: #333333;
                font-size: 14px;
                margin: 0 0 4px;
              }

              ul {
                width: 100%;
                overflow-y: auto;
                scrollbar-width: thin;

                li {
                  width: 54px;
                  height: 54px;
                  margin-right: 16px;
                  border: 1px #707070 solid;
                  cursor: alias;
                  border-radius: 8px;
                  padding: 8px;
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;

                  &.activeBg {
                    border: 2px #237BE2 solid;
                  }

                  &:last-child {
                    margin-right: 0px;
                  }

                  span {
                    width: 40px;
                    height: 40px;

                    svg {
                      width: 100%;
                      height: 100%;
                    }
                  }
                }
              }
            }

            .SelectBgIn {
              p {
                color: #333333;
                font-size: 14px;
                margin: 0px;
              }

              ul {
                width: 100%;
                overflow-y: auto;
                max-height: 210px;
                scrollbar-width: thin;

                li {
                  display: inline-table;
                  width: 52px;
                  height: 52px;
                  margin: 6px 6px;
                  cursor: pointer;

                  img {
                    border-radius: 8px;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }

                  &.activeBg {
                    color: #000;

                    img {
                      outline: 2px #237BE2 solid;
                      outline-offset: 1px;
                    }
                  }
                }
              }
            }

            .BlueinButton {
              padding: 8px 22px;
              font-size: 12px;
              position: absolute;
              bottom: 0;
            }
          }
        }
      }
    }
  }
}

.ContryNoCode {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .DialCodePin {
    width: 195px;
    margin-right: 14px;

    .TimeZoneProfile {
      position: relative;

      .ZoneSelectBox {
        position: relative;
        background-color: transparent;
        border: 1px #e9e6e6 solid;
        height: 44px;
        border-radius: 6px;
        font-size: 14px;
        cursor: pointer;

        .valueInput {
          width: 100%;
          color: #363636;
          cursor: pointer;
          background-color: transparent;
          padding: 11px 10px;
          border: 0px;
          font-weight: 300;
          padding-right: 28px;
          position: relative;
          z-index: 99;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;

          &:hover {
            border: 0px !important;
            box-shadow: none;
            outline: none;
          }

          &:focus {
            box-shadow: none;
            outline: none;
            border: 0px !important;
          }
        }

        &:after {
          content: "";
          display: inline-block;
          width: 8px;
          height: 8px;
          border-top: 1px solid #000;
          border-right: 1px solid #000;
          transform: rotate(135deg);
          position: absolute;
          right: 12px;
          top: 15px;
          transition: all 0.5s ease;
          -webkit-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
          z-index: 4;
        }
      }

      .arrow-icon {
        &:after {
          transform: rotate(-45deg);
          top: 20px;
          transition: all 0.5s ease;
          -webkit-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
          right: 11px;
        }
      }

      .TimeZoneActive {
        border: 1px #e9e6e6 solid;
        margin: 0 0;
        border-radius: 10px;
        padding: 8px 6px 8px;
        position: absolute;
        left: 0;
        top: 48px;
        width: 268px;
        z-index: 9;
        background-color: #FFFFFF;

        .ZoneSearchHere {
          width: 100%;
          margin: 0 0 0;
          position: relative;

          .SearchBarInput {
            width: 100%;
            height: 40px;
            border-radius: 100px;
            font-size: 12.5px;
            padding: 0 10px;
            border: 1px #d7d7d7 solid;
            color: #363636;

            &:hover {
              border: 1px #d7d7d7 solid !important;
              box-shadow: none;
              outline: none;
            }

            &:focus {
              box-shadow: none;
              outline: none;
              border: 1px #d7d7d7 solid !important;
            }
          }
        }

        .ZoneOptionBox {
          max-height: 94px;
          overflow-y: auto;
          scrollbar-width: thin;
          padding: 0 6px;
          position: relative;

          ul {
            margin: 0;
            padding: 0;
            position: relative;
            display: inline-block;
            width: 100%;
            float: left;

            li {
              cursor: pointer;
              font-size: 14px;
              color: #3f4254;
              padding: 5px;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              line-height: inherit;
              overflow: hidden;
            }
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: #c0c3c5;
          }

          &::-webkit-scrollbar-track {
            border-radius: 4px;
            box-shadow: inset 0 0 5px #cfcfcf;
            background-color: #fff;
          }

          .RemoveDialCode {
            li {
              &:first-child {
                margin-top: 0px;
              }
            }
          }
        }

        .selectedInfoli {
          ul {
            li {
              &:first-child {
                margin-top: 38px;
              }

              &.selected-dial-code {
                width: 100%;
                background-color: #f3f3f3;
                position: absolute;
                top: 0;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                padding: 4px 6px;
                line-height: 24px;
                margin-top: 0px;
              }
            }
          }
        }
      }
    }

    .FlagIconInput {
      color: #000;

      .ZoneSelectBox {
        .valueInput {
          padding-left: 34px;
        }
      }
    }
  }

  .PhoneNoInput {
    width: 100%;
  }
}

.HomeLobby {
  display: none;
}

footer {
  width: 100%;
  background-color: #F1F3F4;
  text-align: center;
  padding: 12px 0;

  ul {
    margin: 0px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    li {
      display: inline-block;
      font-size: 11px;
      font-weight: 300;

      a {
        color: #8944FC;
        border-right: 1px #8944FC solid;
        padding: 0 12px;
        height: 12px !important;
        display: block;
        line-height: 12px;

        &:hover {
          color: #8944FC;
          text-decoration: underline !important;
        }
      }

      &:last-child {
        a {
          border-right: 0px;
        }
      }
    }
  }
}

.RegFooter {
  position: fixed;
  bottom: 0;
  margin: 0px;
  right: 0;
  width: 31%;
  min-width: 31%;
  z-index: 6;
  border-top: 1px rgb(0 0 0 / 15%) solid;
  background-color: #F1F3F4 !important;
}

.LanguageZone {
  display: none;
}

.languageChng {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9999;

  .modal-dialog {
    max-width: 450px;

    .modal-content {
      //min-height: 220px;
      padding: 0;

      .modal-header {
        padding: 16px 16px;
        border-bottom: 1px solid #dee2e6;

        .modal-title {
          h4 {
            font-size: 18px;
            color: #4285F4
          }
        }

        .MuiButton-label {
          display: none !important;
        }

        .ResetLanguage {
          cursor: pointer;

          svg {
            width: 22px;
            height: 22px;
          }
        }
      }

      .modal-body {
        padding: 50px 80px !important;

        .Selectlanguage {
          width: 100%;
          position: relative;
          display: block !important;
          margin: 0px auto;

          select {
            width: 100%;
            margin: 0px auto;
            display: block;
            padding: 0px 14px;
            height: 44px;
            color: #333333;
            font-size: 14px;
            font-family: "Poppins", sans-serif !important;
            background: transparent;
            position: relative;
            z-index: 4;
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            border: 1px #707070 solid;
            border-radius: 100px;
            display: block !important;

            .option {
              font-size: 14px;
              font-family: "Poppins", sans-serif !important;
            }

            &:hover {
              outline: none;
            }

            &:focus {
              outline: none;
            }
          }

          &::after {
            content: "";
            display: inline-block;
            width: 10px;
            height: 10px;
            border-top: 2px solid #505050;
            border-right: 2px solid #505050;
            transform: rotate(135deg);
            position: absolute;
            right: 16px;
            top: 14px;
            transition: all 0.5s ease;
            -webkit-transition: all 0.5s ease;
            -moz-transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
            z-index: 3;
          }
        }

        #google_translate_element {
          .skiptranslate {
            color: transparent;
            max-height: 44px;
            overflow: hidden;

            div {
              width: 100%;
              position: relative;
              display: block !important;
              margin: 0px auto;

              select {
                width: 100%;
                margin: 0px auto;
                display: block;
                padding: 0px 14px;
                height: 44px;
                color: #333333;
                font-size: 14px;
                font-family: "Poppins", sans-serif !important;
                background: transparent;
                position: relative;
                z-index: 4;
                appearance: none;
                -moz-appearance: none;
                -webkit-appearance: none;
                border: 1px #707070 solid;
                border-radius: 100px;

                .option {
                  font-size: 14px;
                  font-family: "Poppins", sans-serif !important;
                }

                &:hover {
                  outline: none;
                }

                &:focus {
                  outline: none;
                }
              }

              &::after {
                content: "";
                display: inline-block;
                width: 11px;
                height: 11px;
                border-top: 2px solid #505050;
                border-right: 2px solid #505050;
                transform: rotate(135deg);
                position: absolute;
                right: 16px;
                top: 14px;
                transition: all 0.5s ease;
                -webkit-transition: all 0.5s ease;
                -moz-transition: all 0.5s ease;
                -o-transition: all 0.5s ease;
                z-index: 3;
              }
            }

            span {
              display: none;
            }
          }
        }

        .LanguageZone {
          position: relative;
          display: block !important;

          .LangSelectBox {
            width: 100%;
            position: relative;
            display: block !important;
            margin: 0 auto;

            .valueInput {
              width: 100%;
              margin: 0 auto;
              display: block;
              padding: 0 14px;
              height: 44px;
              color: #333;
              font-size: 14px;
              font-family: "Poppins", sans-serif !important;
              background: transparent;
              position: relative;
              z-index: 4;
              appearance: none;
              -moz-appearance: none;
              -webkit-appearance: none;
              border: 1px solid #707070;
              border-radius: 100px;
              cursor: pointer;
              display: block !important;

              &:hover {
                border: 1px solid #707070;
                box-shadow: none;
                outline: none;
              }

              &:focus {
                border: 1px solid #707070;
                box-shadow: none;
                outline: none;
              }
            }

            &:after {
              content: "";
              display: inline-block;
              width: 11px;
              height: 11px;
              border-top: 2px solid #505050;
              border-right: 2px solid #505050;
              -webkit-transform: rotate(135deg);
              transform: rotate(135deg);
              position: absolute;
              right: 22px;
              top: 14px;
              transition: all .5s ease;
              -webkit-transition: all .5s ease;
              -moz-transition: all .5s ease;
              -o-transition: all .5s ease;
              z-index: 3;
            }
          }

          .arrow-icon {
            &:after {
              transform: rotate(-45deg);
              top: 19px;
              transition: all .5s ease;
              -webkit-transition: all .5s ease;
              -moz-transition: all .5s ease;
              -o-transition: all .5s ease;
            }
          }

          .LangZoneActive {
            position: absolute;
            width: 100%;
            background-color: #fff;
            z-index: 95;
            border: 1px #eae6e6 solid;
            overflow: hidden;
            top: 48px;
            height: 320px;

            .langSearchHere {
              padding: 16px 10px 0;

              .SearchLangInput {
                width: 100%;
                border-radius: 100px;
                border: 1px solid #707070;
                padding: 10px 16px;
                font-size: 13px;
                font-weight: 400;

                &:hover {
                  outline: none;
                  box-shadow: none;
                  border: 1px solid #707070;
                }

                &:focus {
                  outline: none;
                  box-shadow: none;
                  border: 1px solid #707070;
                }
              }
            }

            .LangOption {
              height: 235px;
              margin: 16px 0 0px;
              scrollbar-width: thin;
              overflow-y: auto;
              padding: 0 0 0px;

              ul {
                li {
                  display: block;
                  font-size: 14px;
                  margin: 4px 0;
                  font-weight: 400;
                  cursor: pointer;
                  padding: 2px 14px;

                  &:first-child {
                    margin-top: 0px;
                  }

                  &:hover {
                    background-color: #f2f2f2;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.LangChnageBtm {
  position: relative;
  margin: 30px 0 0;
  width: 100%;
  text-align: center;

  .ChngLanuage {
    width: 100%;
    display: block;
    bottom: 16px;
    left: 0;
    right: 0;
    margin: 0 auto;
    font-size: 14px;
    padding: 10px 22px;
  }
}

.skiptranslate {

  //display: none !important;
  iframe {
    height: 0px !important;
    height: 0px !important;
    border: 0px;
    box-shadow: none !important;
  }
}

#goog-gt-tt {
  display: none !important;
}

.VIpgJd-suEOdc {
  display: none !important;
}

.VIpgJd-suEOdc.skiptranslate {
  display: none !important;
}

.goog-text-highlight {
  background-color: transparent !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: none !important;
  /* Firefox 3.5 - 3.6 */
}

.LeftChageBg,
.EfectFullBg {
  position: relative;
}

.cameraOffScreen {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #000;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 19px;
}

.archiveVote {
  cursor: default !important;
}

.GrayButtonInfo {
  background-color: var(--gray-info);
  color: #333333;
  font-weight: 400;
  border-radius: 100px;
  padding: 10px 12px;
  width: 100%;
  border-color: #333333;
  font-size: 16px;

  &:hover {
    box-shadow: none;
    outline: 0;
    color: #333333;
    background-color: #eaeced;
    border-color: #333333;
  }

  &:focus {
    box-shadow: none;
    outline: 0;
    color: #333333;
    background-color: #F1F3F4;
    border-color: #333333;
  }
}

.CurrentLanguage {
  position: absolute;
  top: 20px;
  right: 22px;
  z-index: 5;

  span {
    background-color: #fff !important;
    color: #5d5d5d !important;
    padding: 5px 34px 5px 14px;
    border-radius: 100px;
    font-size: 13px;
    position: relative;
    cursor: pointer;

    &:before {
      content: "";
      width: 8px;
      height: 8px;
      border-top: 2px solid #363636;
      border-right: 2px solid #363636;
      position: absolute;
      right: 14px;
      top: 10px;
      background-image: none;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      transition: all 0.5s ease;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
    }

    svg {
      width: 18px;
      margin-right: 6px;
    }
  }
}

.Selectlanguage .notranslate {
  display: none !important;
}

.DataNotFound {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #203A45;
  font-weight: 400;
  font-size: 14px;

  .DataIcon {
    width: 140px;
    height: 140px;

    div {
      cursor: auto !important;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.YoutProfilePopUp {
  .modal-dialog {
    .modal-content {
      .modal-body {
        .FullYourProfile {
          .ProfileEditPic {
            .ContryNoCode {
              .DialCodePin {
                width: 100% !important;
              }

              .YourProfileDial {
                .TimeZoneProfile {
                  .TimeZoneActive {
                    top: 46px !important;
                    width: 296px !important;

                    .ZoneOptionBox {
                      max-height: 95px !important;
                      position: relative;

                      .RemoveDialCode {
                        li {
                          &:first-child {
                            margin-top: 0px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.ytp-right-controls {
  display: none !important;

  .ytp-youtube-button {
    display: none !important;
  }
}

.ytp-youtube-button {
  display: none !important;
}

.yt-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000;
}

.youtubeSession {

  // position: absolute;
  // height: 100%;
  // width: 100%;
  .YoutubeVideo {
    position: relative;
    overflow: hidden;
    width: 100%;
    aspect-ratio: 16/9;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;

    &:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      background-color: transparent;
    }

    iframe {
      width: 300%;
      height: 100%;
      margin-left: -100%;
      // pointer-events: none;
    }

    .ControlFrame {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 95;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: rgb(0 0 0 / 30%);

      .LeftControls {
        display: flex;
        align-items: center;

        .UtButton {
          background-color: transparent;

          button {
            padding: 0px;
            border: 0;
            background-color: transparent;

            svg {
              background-color: transparent;
              width: 30px;
              height: 30px;
              fill: #fff;
            }
          }
        }

        .MuteUnmute {
          display: flex;
          align-items: center;
          height: auto !important;
          width: auto !important;

          button {
            width: 30px;
            height: 30px;
          }

          &:hover {
            // .VolumeCtrl {
            //   height: 30px;
            //   width: 42px !important;
            //   margin-right: 7px;
            //   margin-left: 4px;
            //   transition: all 0.5s ease;
            //   -webkit-transition: all 0.5s ease;
            //   -moz-transition: all 0.5s ease;
            //   -o-transition: all 0.5s ease;
            // }

            button {
              width: 30px;
              height: 30px;
            }
          }
        }

        .liveSession {
          cursor: pointer;
          display: inline-flex;
          margin-left: 8px;

          span {
            background-color: #eb354d;
            color: #fff;
            font-size: 10px;
            border-radius: 3px;
            padding: 0 5px;
            font-weight: 400;
            text-transform: uppercase;
            letter-spacing: .3px;
            margin-left: 6px;
          }
        }

        .VolumeCtrl {
          height: 30px;
          width: 42px !important;
          margin-right: 0px;
          margin-left: 0px;
          overflow: hidden;
          transition: all 0.5s ease;
          -webkit-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;

          .e-range {
            -webkit-appearance: none;
            appearance: none;
            width: 42px;
            margin: 0;
            height: 3px;
            cursor: pointer;
            position: relative;

            &:focus {
              outline: none;
            }

            &::-webkit-slider-thumb {
              position: relative;
              appearance: none;
              height: 9px;
              width: 9px;
              background-color: #fff;
              border-radius: 100%;
              border: 0;
              top: 0px;
              box-shadow: webkit-slider-thumb-shadow();
              transition: background-color 150ms;
            }
          }
        }

        .GoLive {
          span {
            background-color: #868686 !important;
          }
        }
      }

      .RightControls {
        display: none;
        .UtButton {
          width: 30px;
          height: 30px;
          padding: 0px;
          border: 0;
          background-color: transparent;

          button {
            padding: 0px;
            border: 0;
            background-color: transparent;

            svg {
              background-color: transparent;
              height: 30px;
              width: 30px;
              fill: #ffffff;
            }
          }
        }
      }
    }

    .PlayAgain {
      display: none;
    }
  }
}

.BackstageRtStream {
  .GroupStreamMix {
    .ChangeWidthScroll {
      .front-stage-stream-scroll {
        .youtubeSession {
          .YoutubeVideo {
            .ControlFrame {
              padding: 0 6px;
            }
          }

          .playBlack {
            .PlayAgain {
              display: flex !important;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              z-index: 90;
              align-items: center;
              justify-content: center;

              svg {
                fill: #fff;
                opacity: 0.18;
                width: 60px;
              }
            }

            &::after {
              background-color: #000000 !important;
            }
          }
        }
      }
    }
  }
}

.MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
  background-color: #19dd22 !important;
}

.MuiSwitch-colorSecondary.Mui-checked {
  color: #ffffff !important;
}

.MuiSwitch-colorSecondary.Mui-checked:hover {
  background-color: transparent !important;
}

.MuiSwitch-colorSecondary.Mui-checked:hover,
.MuiIconButton-root:hover {
  background-color: transparent !important;
}

.NotificationSound {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #4285F4;
  border-radius: 10px;
  width: 93%;
  margin: 6px auto 6px;
  padding: 4px 4px 4px 14px;

  p {
    color: #fff;
    font-weight: 400;
    margin: 0;
    font-size: 14px;
  }

  .SoundSwitch {

    .MuiSwitch-root {
      width: 56px;
      height: 42px;
      padding: 10px 0px !important;
      margin-right: 6px;

      .MuiButtonBase-root {
        .MuiSwitch-thumb {
          width: 16px !important;
          height: 16px !important;
        }
      }

      .MuiButtonBase-root {
        padding: 13px 3px !important;
        color: #585757 !important;
        // left: 7px;
        // right: auto;
      }

      .MuiSwitch-colorSecondary.Mui-checked {
        color: #4285F4 !important;
        padding: 13px 17px !important;
        // right: 26px;
        // left: auto;
      }

      .MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
        background-color: #f1f1f1 !important;
        opacity: 1 !important;
        border-radius: 100px !important;
      }
    }

    .MuiSwitch-root {
      .MuiSwitch-track {
        opacity: 1 !important;
        background-color: #f1f1f1;
        border-radius: 100px;

        &:after {
          content: "OFF";
          position: absolute;
          top: 13px;
          font-size: 12px;
          right: 12px;
          font-weight: 500;
          color: #5c5b5b;
        }
      }
    }
  }
}

.SoundSwitch .MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track:after {
  content: "ON";
  position: absolute;
  top: 13px;
  font-size: 12px;
  left: 12px;
  font-weight: 500;
  color: #5c5b5b;
}

.yt-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000;
}

.AttendeeFrame {
  .YoutubeVideo {
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    position: relative;
    z-index: 91;
    .ControlFrame {
      padding: 0 15px;

      .LeftControls {
        display: flex;
        align-items: center;

        .UtButton {
          background-color: transparent;

          button {
            padding: 0px;
            border: 0;
            background-color: transparent;

            svg {
              background-color: transparent;
              width: 40px;
              height: 40px;
              fill: #fff;
            }
          }
        }

        .MuteUnmute {
          display: flex;
          align-items: center;
          height: auto !important;
          width: auto !important;

          button {
            width: 40px;
            height: 40px;
          }

          &:hover {
            .VolumeCtrl {
              // height: 32px;
              // width: 48px !important;
              // margin-right: 7px;
              // margin-left: 4px;
              // transition: all 0.5s ease;
              // -webkit-transition: all 0.5s ease;
              // -moz-transition: all 0.5s ease;
              // -o-transition: all 0.5s ease;

              // .e-range {
              //   -webkit-appearance: none;
              //   appearance: none;
              //   width: 60px;
              //   margin: 0;
              //   height: 3px;
              //   cursor: pointer;
              //   position: relative;

              //   &:focus {
              //     outline: none;
              //   }

              //   &::-webkit-slider-thumb {
              //     position: relative;
              //     appearance: none;
              //     height: 12px;
              //     width: 12px;
              //     background-color: #fff;
              //     border-radius: 100%;
              //     border: 0;
              //     top: 0px;
              //     box-shadow: webkit-slider-thumb-shadow();
              //     transition: background-color 150ms;
              //   }
              // }
            }

            button {
              width: 40px;
              height: 40px;
            }
          }
        }

        .liveSession {
          span {
            font-size: 12px;
            margin-left: 8px;
          }
        }

        .VolumeCtrl {
          height: 32px;
          width: 60px !important;
          margin-right: 0px;
          margin-left: 0px;
          overflow: hidden;
          transition: all 0.5s ease;
          -webkit-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
        }
      }

      .RightControls {
        .UtButton {
          width: 40px;
          height: 40px;

          button {
            padding: 0px;
            border: 0;
            background-color: transparent;

            svg {
              background-color: transparent;
              height: 100%;
              width: 100%;
              fill: #fff;
            }
          }
        }
      }
    }
  }

  .playBlack {
    &::after {
      background-color: #000000 !important;
    }

    .PlayAgain {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 90;
      align-items: center;
      justify-content: center;

      svg {
        fill: #fff;
        opacity: 0.12;
        width: 180px;
      }
    }
  }

  .fullScreen {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 999;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;

    iframe {
      position: fixed;
      left: 0;
      top: 0;
      transition: all 0.5s ease;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
    }
  }
}

.registered {
  /* background-color: #fff; */
  width: 500px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.mobileBgImg {
  display: none !important;
}

.TextToolTip {
  font-weight: 600;
  font-size: 14px;
  display: inline-block;

  span {
    color: #676464;
    font-weight: 600;
    font-size: 14px;
    margin-left: 7px;
    cursor: pointer;
  }

  label {
    display: inline-block;
    color: #222222;
    font-weight: 400;
    font-size: 13px;
  }
}



.container-error {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #FFF5F4;
  align-items: center;
  justify-content: center;
  display: flex;
  overflow: hidden;

  .CurveLine {
    position: absolute;
    width: 100%;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);

    .maxImg {
      width: 100%;
      height: 164px;
    }
  }

  .CurveLine1 {
    left: 0px;
    margin-top: -90px;
    width: calc(50% - 424px);
  }

  .CurveLine2 {
    right: 0px;
    margin-top: -112px;
    width: calc(50% - 424px);
  }

  .ErrorInbox {
    position: relative;
    z-index: 8;
    display: flex;
    width: 850px;
    text-align: center;
    background-color: #ffffff;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    flex-direction: column;
    padding: 50px 0 80px;

    .LogoWebinar {
      margin-bottom: 20px;

      .maxImg {
        width: 200px;
      }
    }

    .PlugWebinar {
      width: 100%;
      margin-top: 20px;

      svg {
        width: 100% !important;
        height: auto;
      }
    }

    .ErrorInfo {
      margin-top: 30px;

      h3 {
        color: #333333;
        font-size: 82px;
        font-weight: 700;
      }

      strong {
        font-weight: 600;
        font-size: 20px;
        color: #333333;
        margin-bottom: 5px;
        display: block;
      }

      p {
        color: #333333;
        margin: 0;
      }
    }

    h1 {
      color: #fff;
      font-weight: bold;
      font-size: 6vmin;
      margin: 0;
      text-align: center;
    }
  }
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf-ti6hGc,
.VIpgJd-ZVi9od-aZ2wEe-OiiCO {
  display: none;
}

.ql-snow .ql-picker {
  font-family: 400 !important;
}

.ql-snow .ql-picker.ql-font {
  width: 122px !important;
}

.ql-snow .ql-picker.ql-header {
  width: 116px !important;
}

.ContryBothPhone {
  .DialCodePin {
    display: flex;
    margin-right: 0px !important;

    .TimeZoneProfile {
      .ZoneSelectBox {
        border-radius: 5px 0px 0px 5px !important;
        border-right: 0px !important;
        width: 112px;

        .error {
          position: absolute;
          font-size: 11px;
          bottom: -18px;
          left: 0;
          width: 280px;
        }

        &::before {
          content: "";
          width: 1px;
          background-color: #d9d8d8;
          height: 22px;
          display: inline-block;
          position: absolute;
          right: -2px;
          top: 10px;
        }

        .flag-box {
          position: absolute;
          left: 8px;
          top: 14px;
        }
      }

      &::after {
        display: none !important;
      }
    }

    .EditInfoDetails {
      input {
        border-radius: 0px 5px 5px 0px !important;
        border-left: 0px !important;
      }
    }

    .phoneNoInput {
      width: 100%;
    }
  }
}

.EventTypeActonIn {
  #dial_code {
    display: flex;
    width: 100%;

    .ListSeleftDDl {
      .SelectDdl {
        .CodevalueInput {
          border: 1px #a5a5a5 solid;
          border-radius: 100px 0px 0px 100px;
          padding: 14px 34px 14px 16px;
          color: #333333;
          height: 50px;
          font-weight: 400;
          font-size: 15px;
          background-color: transparent !important;
          width: 140px;
          border-right: 0px;
          position: relative;
          z-index: 5;
          cursor: pointer;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;

          &:focus {
            outline: none;
          }
        }

        &:after {
          right: 18px !important;
          z-index: 2;
          cursor: pointer;
        }
      }

      .arrow-icon {
        &:after {
          transform: rotate(-45deg);
          position: absolute;
          top: 24px !important;
          z-index: 4;
        }
      }

      .ZoneOptionBox {
        border: 1px solid #f2f2f2;
        border-radius: 5px;
        width: 280px;
        margin-top: 5px;
        position: absolute;
        top: 100%;
        z-index: 9;
        background-color: #fff;
        padding: 8px 6px 4px;
        left: 0;

        .DialJustNow {
          margin-top: 8px;
          overflow-y: auto;
          height: 154px;

          ul {
            padding: 0px;
            margin: 0px;
            position: relative;
            overflow: hidden;
            display: inline-block;

            width: 100%;

            li {
              font-size: 14px;
              color: #3f4254;
              padding: 5px;
              width: 100%;
              cursor: pointer;

              &:first-child {
                margin-top: 34px;
              }

              &.selected-dial-code {
                width: 100%;
                background-color: #f3f3f3 !important;
                position: absolute;
                top: 0;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                padding: 4px 6px;
                line-height: 24px;
                margin-top: 0px;
              }
            }
          }
        }

        .TimeZoneActive {
          .ZoneSearchHere {
            .SearchBarInput {
              font-size: 15px;
              height: 36px;
              font-family: "Poppins", sans-serif !important;
              color: #333333;
              width: 100%;
              border: 1px #dce2e6 solid !important;
              border-radius: 10px;
              padding: 0 12px;
              font-weight: 400;

              &:focus {
                outline: none;
              }

              &::-webkit-input-placeholder {
                color: #989898;
              }

              &::-ms-input-placeholder {
                color: #989898;
              }

              &::placeholder {
                color: #989898;
              }
            }
          }
        }
      }
    }

    .SelectDdl {
      width: 140px;

      .flag-box {
        position: absolute;
        left: 16px;
        top: 18px;
      }

      .form-control {
        border-right: 0px;
        border-radius: 100px 0px 0px 100px;
        padding-left: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        line-height: inherit;
      }

      &::before {
        content: "";
        width: 1px;
        background-color: #ccc;
        height: 22px;
        right: 0;
        display: inline-block;
        position: absolute;
        top: 15px;
      }

      &::after {
        right: 18px;
      }
    }

    #phone_number {
      width: 100%;
      //position: relative;

      .form-control {
        border-left: 0px !important;
        border-radius: 0px 100px 100px 0px !important;
        padding-left: 12px !important;
        cursor: auto !important;
      }
    }
  }
}

.EventTypeActonIn {
  #country {
    width: 100%;

    .ListSeleftDDl {
      .SelectDdl {
        .CodevalueInput {
          border: 1px #a5a5a5 solid;
          border-radius: 100px;
          padding: 14px 34px 14px 16px;
          color: #333333;
          height: 50px;
          font-weight: 400;
          font-size: 15px;
          background-color: transparent !important;
          width: 100%;
          border-right: 0px;
          position: relative;
          z-index: 5;
          cursor: pointer;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;

          &:focus {
            outline: none;
          }
        }

        &:after {
          z-index: 2;
          cursor: pointer;
        }
      }

      .arrow-icon {
        &:after {
          transform: rotate(-45deg);
          position: absolute;
          top: 24px !important;
          z-index: 4;
        }
      }

      .ZoneOptionBox {
        border: 1px solid #f2f2f2;
        border-radius: 5px;
        width: 280px;
        margin-top: 5px;
        position: absolute;
        top: 100%;
        z-index: 9;
        background-color: #fff;
        padding: 8px 6px 4px;
        left: 0;

        .DialJustNow {
          margin-top: 8px;
          overflow-y: auto;
          height: 200px;

          ul {
            padding: 0px;
            margin: 0px;
            position: relative;
            overflow: hidden;
            display: inline-block;

            width: 100%;

            li {
              font-size: 14px;
              color: #3f4254;
              padding: 5px;
              width: 100%;
              cursor: pointer;
            }
          }
        }

        .selectedField {
          ul {
            li {
              &:first-child {
                margin-top: 34px;
              }

              &.selected-dial-code {
                width: 100%;
                background-color: #f3f3f3 !important;
                position: absolute;
                top: 0;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                padding: 4px 6px;
                line-height: 24px;
                margin-top: 0px;
              }
            }
          }
        }

        .TimeZoneActive {
          .ZoneSearchHere {
            .SearchBarInput {
              font-size: 15px;
              height: 36px;
              font-family: "Poppins", sans-serif !important;
              color: #333333;
              width: 100%;
              border: 1px #dce2e6 solid !important;
              border-radius: 10px;
              padding: 0 12px;
              font-weight: 400;

              &:focus {
                outline: none;
              }

              &::-webkit-input-placeholder {
                color: #989898;
              }

              &::-ms-input-placeholder {
                color: #989898;
              }

              &::placeholder {
                color: #989898;
              }
            }
          }
        }
      }
    }

    #phone_number {
      width: 100%;
      position: relative;

      .form-control {
        border-left: 0px !important;
        border-radius: 0px 100px 100px 0px !important;
        padding-left: 12px !important;
        cursor: auto !important;
      }
    }
  }
}

.ImptyStreamBg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: 0px auto 0;
  left: 0;
  right: 0;
  z-index: 0;

  img {
    width: 80px !important;
    height: auto !important;
  }

  span {
    display: block;
    width: 320px;
    margin: 10px auto 0;
    color: #cacaca;
    font-size: 14px;
  }
}

.ql-size-extra-small {
  font-size: 12px !important;
}

.ql-size-small {
  font-size: 14px !important;
}

.ql-size-medium-size {
  font-size: 18px !important;
}

.ql-size-large {
  font-size: 20px !important;
}

.ql-size-extra-large {
  font-size: 24px !important;
}

.ql-size-huge {
  font-size: 28px !important;
}


.flag-box {
  display: inline-flex;
  vertical-align: middle;
  width: 20px;
  margin-right: 6px;
  height: 15px;
  align-items: center;
}

.iti-flag {
  width: 20px
}

.iti-flag.be {
  width: 18px
}

.iti-flag.ch {
  width: 15px
}

.iti-flag.mc {
  width: 19px
}

.iti-flag.ne {
  width: 18px
}

.iti-flag.np {
  width: 13px
}

.iti-flag.va {
  width: 15px
}


.border-activated {
  box-shadow: 0 0 0 3px #4285f4 !important;
  border-radius: 5px;
}

.list-null .border-activated {
  border: 2px solid #4285f4 !important;
  border-radius: 10px !important;
  box-shadow: none !important;
}

.overlayPlayer > div:before {
  content: "";
  position: absolute; 
  width: 100%; 
  height: 100%; 
  left: 0; 
  top: 0;
}

@media only screen and (-webkit-min-device-pixel-ratio:2),
only screen and (min--moz-device-pixel-ratio:2),
only screen and (-o-min-device-pixel-ratio:2 / 1),
only screen and (min-device-pixel-ratio:2),
only screen and (min-resolution:192dpi),
only screen and (min-resolution:2dppx) {
  .iti-flag {
    background-size: 5630px 15px
  }
}

.iti-flag.ac {
  height: 10px;
  background-position: 0 0;
}

.iti-flag.ad {
  height: 14px;
  background-position: -22px 0;
}

.iti-flag.ae {
  height: 10px;
  background-position: -44px 0;
}

.iti-flag.af {
  height: 14px;
  background-position: -66px 0;
}

.iti-flag.ag {
  height: 14px;
  background-position: -88px 0;
}

.iti-flag.ai {
  height: 10px;
  background-position: -110px 0;
}

.iti-flag.al {
  height: 15px;
  background-position: -132px 0;
}

.iti-flag.am {
  height: 10px;
  background-position: -154px 0;
}

.iti-flag.ao {
  height: 14px;
  background-position: -176px 0;
}

.iti-flag.aq {
  height: 14px;
  background-position: -198px 0;
}

.iti-flag.ar {
  height: 13px;
  background-position: -220px 0;
}

.iti-flag.as {
  height: 10px;
  background-position: -242px 0;
}

.iti-flag.at {
  height: 14px;
  background-position: -264px 0;
}

.iti-flag.xa {
  height: 10px;
  background-position: -286px 0;
}

.iti-flag.au {
  height: 10px;
  background-position: -286px 0;
}

.iti-flag.aw {
  height: 14px;
  background-position: -308px 0;
}

.iti-flag.ax {
  height: 13px;
  background-position: -330px 0;
}

.iti-flag.az {
  height: 10px;
  background-position: -352px 0;
}

.iti-flag.ba {
  height: 10px;
  background-position: -374px 0;
}

.iti-flag.bb {
  height: 14px;
  background-position: -396px 0;
}

.iti-flag.bd {
  height: 12px;
  background-position: -418px 0;
}

.iti-flag.be {
  height: 15px;
  background-position: -440px 0;
}

.iti-flag.bf {
  height: 14px;
  background-position: -460px 0;
}

.iti-flag.bg {
  height: 12px;
  background-position: -482px 0;
}

.iti-flag.bh {
  height: 12px;
  background-position: -504px 0;
}

.iti-flag.bi {
  height: 12px;
  background-position: -526px 0;
}

.iti-flag.bj {
  height: 14px;
  background-position: -548px 0;
}

.iti-flag.bl {
  height: 14px;
  background-position: -570px 0;
}

.iti-flag.bm {
  height: 10px;
  background-position: -592px 0;
}

.iti-flag.bn {
  height: 10px;
  background-position: -614px 0;
}

.iti-flag.bo {
  height: 14px;
  background-position: -636px 0;
}

.iti-flag.bq {
  height: 14px;
  background-position: -658px 0;
}

.iti-flag.br {
  height: 14px;
  background-position: -680px 0;
}

.iti-flag.bs {
  height: 10px;
  background-position: -702px 0;
}

.iti-flag.bt {
  height: 14px;
  background-position: -724px 0;
}

.iti-flag.bv {
  height: 15px;
  background-position: -746px 0;
}

.iti-flag.bw {
  height: 14px;
  background-position: -768px 0;
}

.iti-flag.by {
  height: 10px;
  background-position: -790px 0;
}

.iti-flag.bz {
  height: 14px;
  background-position: -812px 0;
}

.iti-flag.ca {
  height: 10px;
  background-position: -834px 0;
}

.iti-flag.cc {
  height: 10px;
  background-position: -856px 0;
}

.iti-flag.cd {
  height: 15px;
  background-position: -878px 0;
}

.iti-flag.cf {
  height: 14px;
  background-position: -900px 0;
}

.iti-flag.cg {
  height: 14px;
  background-position: -922px 0;
}

.iti-flag.ch {
  height: 15px;
  background-position: -944px 0;
}

.iti-flag.ci {
  height: 14px;
  background-position: -961px 0;
}

.iti-flag.ck {
  height: 10px;
  background-position: -983px 0;
}

.iti-flag.cl {
  height: 14px;
  background-position: -1005px 0;
}

.iti-flag.cm {
  height: 14px;
  background-position: -1027px 0;
}

.iti-flag.cn {
  height: 14px;
  background-position: -1049px 0;
}

.iti-flag.co {
  height: 14px;
  background-position: -1071px 0;
}

.iti-flag.cp {
  height: 14px;
  background-position: -1093px 0;
}

.iti-flag.cr {
  height: 12px;
  background-position: -1115px 0;
}

.iti-flag.cu {
  height: 10px;
  background-position: -1137px 0;
}

.iti-flag.cv {
  height: 12px;
  background-position: -1159px 0;
}

.iti-flag.cw {
  height: 14px;
  background-position: -1181px 0;
}

.iti-flag.cx {
  height: 10px;
  background-position: -1203px 0;
}

.iti-flag.cy {
  height: 13px;
  background-position: -1225px 0;
}

.iti-flag.cz {
  height: 14px;
  background-position: -1247px 0;
}

.iti-flag.de {
  height: 12px;
  background-position: -1269px 0;
}

.iti-flag.dg {
  height: 10px;
  background-position: -1291px 0;
}

.iti-flag.dj {
  height: 14px;
  background-position: -1313px 0;
}

.iti-flag.dk {
  height: 15px;
  background-position: -1335px 0;
}

.iti-flag.dm {
  height: 10px;
  background-position: -1357px 0;
}

.iti-flag.do {
  height: 13px;
  background-position: -1379px 0;
}

.iti-flag.dz {
  height: 14px;
  background-position: -1401px 0;
}

.iti-flag.ea {
  height: 14px;
  background-position: -1423px 0;
}

.iti-flag.ec {
  height: 14px;
  background-position: -1445px 0;
}

.iti-flag.ee {
  height: 13px;
  background-position: -1467px 0;
}

.iti-flag.eg {
  height: 14px;
  background-position: -1489px 0;
}

.iti-flag.eh {
  height: 10px;
  background-position: -1511px 0;
}

.iti-flag.er {
  height: 10px;
  background-position: -1533px 0;
}

.iti-flag.es {
  height: 14px;
  background-position: -1555px 0;
}

.iti-flag.et {
  height: 10px;
  background-position: -1577px 0;
}

.iti-flag.eu {
  height: 14px;
  background-position: -1599px 0;
}

.iti-flag.fi {
  height: 12px;
  background-position: -1621px 0;
}

.iti-flag.fj {
  height: 10px;
  background-position: -1643px 0;
}

.iti-flag.fk {
  height: 10px;
  background-position: -1665px 0;
}

.iti-flag.fm {
  height: 11px;
  background-position: -1687px 0;
}

.iti-flag.fo {
  height: 15px;
  background-position: -1709px 0;
}

.iti-flag.fr {
  height: 14px;
  background-position: -1731px 0;
}

.iti-flag.ga {
  height: 15px;
  background-position: -1753px 0;
}

.iti-flag.gb {
  height: 10px;
  background-position: -1775px 0;
}

.iti-flag.xg {
  height: 10px;
  background-position: -1775px 0;
}

.iti-flag.gd {
  height: 12px;
  background-position: -1797px 0;
}

.iti-flag.ge {
  height: 14px;
  background-position: -1819px 0;
}

.iti-flag.gf {
  height: 14px;
  background-position: -1841px 0;
}

.iti-flag.gg {
  height: 14px;
  background-position: -1863px 0;
}

.iti-flag.xu {
  height: 14px;
  background-position: -1863px 0;
}

.iti-flag.gh {
  height: 14px;
  background-position: -1885px 0;
}

.iti-flag.gi {
  height: 10px;
  background-position: -1907px 0;
}

.iti-flag.gl {
  height: 14px;
  background-position: -1929px 0;
}

.iti-flag.gm {
  height: 14px;
  background-position: -1951px 0;
}

.iti-flag.gn {
  height: 14px;
  background-position: -1973px 0;
}

.iti-flag.gp {
  height: 14px;
  background-position: -1995px 0;
}

.iti-flag.gq {
  height: 14px;
  background-position: -2017px 0;
}

.iti-flag.gr {
  height: 14px;
  background-position: -2039px 0;
}

.iti-flag.gs {
  height: 10px;
  background-position: -2061px 0;
}

.iti-flag.gt {
  height: 13px;
  background-position: -2083px 0;
}

.iti-flag.gu {
  height: 11px;
  background-position: -2105px 0;
}

.iti-flag.gw {
  height: 10px;
  background-position: -2127px 0;
}

.iti-flag.gy {
  height: 12px;
  background-position: -2149px 0;
}

.iti-flag.hk {
  height: 14px;
  background-position: -2171px 0;
}

.iti-flag.hm {
  height: 10px;
  background-position: -2193px 0;
}

.iti-flag.hn {
  height: 10px;
  background-position: -2215px 0;
}

.iti-flag.hr {
  height: 10px;
  background-position: -2237px 0;
}

.iti-flag.ht {
  height: 12px;
  background-position: -2259px 0;
}

.iti-flag.hu {
  height: 10px;
  background-position: -2281px 0;
}

.iti-flag.ic {
  height: 14px;
  background-position: -2303px 0;
}

.iti-flag.id {
  height: 14px;
  background-position: -2325px 0;
}

.iti-flag.ie {
  height: 10px;
  background-position: -2347px 0;
}

.iti-flag.il {
  height: 15px;
  background-position: -2369px 0;
}

.iti-flag.xm {
  height: 10px;
  background-position: -2391px 0;
}

.iti-flag.in {
  height: 14px;
  background-position: -2413px 0;
}

.iti-flag.io {
  height: 10px;
  background-position: -2435px 0;
}

.iti-flag.iq {
  height: 14px;
  background-position: -2457px 0;
}

.iti-flag.ir {
  height: 12px;
  background-position: -2479px 0;
}

.iti-flag.is {
  height: 15px;
  background-position: -2501px 0;
}

.iti-flag.it {
  height: 14px;
  background-position: -2523px 0;
}

.iti-flag.xj {
  height: 12px;
  background-position: -2545px 0;
}

.iti-flag.jm {
  height: 10px;
  background-position: -2567px 0;
}

.iti-flag.jo {
  height: 10px;
  background-position: -2589px 0;
}

.iti-flag.jp {
  height: 14px;
  background-position: -2611px 0;
}

.iti-flag.ke {
  height: 14px;
  background-position: -2633px 0;
}

.iti-flag.kg {
  height: 12px;
  background-position: -2655px 0;
}

.iti-flag.kh {
  height: 13px;
  background-position: -2677px 0;
}

.iti-flag.ki {
  height: 10px;
  background-position: -2699px 0;
}

.iti-flag.km {
  height: 12px;
  background-position: -2721px 0;
}

.iti-flag.kn {
  height: 14px;
  background-position: -2743px 0;
}

.iti-flag.kp {
  height: 10px;
  background-position: -2765px 0;
}

.iti-flag.kr {
  height: 14px;
  background-position: -2787px 0;
}

.iti-flag.kw {
  height: 10px;
  background-position: -2809px 0;
}

.iti-flag.ky {
  height: 10px;
  background-position: -2831px 0;
}

.iti-flag.kz {
  height: 10px;
  background-position: -2853px 0;
}

.iti-flag.la {
  height: 14px;
  background-position: -2875px 0;
}

.iti-flag.lb {
  height: 14px;
  background-position: -2897px 0;
}

.iti-flag.lc {
  height: 10px;
  background-position: -2919px 0;
}

.iti-flag.li {
  height: 12px;
  background-position: -2941px 0;
}

.iti-flag.lk {
  height: 10px;
  background-position: -2963px 0;
}

.iti-flag.lr {
  height: 11px;
  background-position: -2985px 0;
}

.iti-flag.ls {
  height: 14px;
  background-position: -3007px 0;
}

.iti-flag.lt {
  height: 12px;
  background-position: -3029px 0;
}

.iti-flag.lu {
  height: 12px;
  background-position: -3051px 0;
}

.iti-flag.lv {
  height: 10px;
  background-position: -3073px 0;
}

.iti-flag.ly {
  height: 10px;
  background-position: -3095px 0;
}

.iti-flag.ma {
  height: 14px;
  background-position: -3117px 0;
}

.iti-flag.mc {
  height: 15px;
  background-position: -3139px 0;
}

.iti-flag.md {
  height: 10px;
  background-position: -3160px 0;
}

.iti-flag.me {
  height: 10px;
  background-position: -3182px 0;
}

.iti-flag.mf {
  height: 14px;
  background-position: -3204px 0;
}

.iti-flag.mg {
  height: 14px;
  background-position: -3226px 0;
}

.iti-flag.mh {
  height: 11px;
  background-position: -3248px 0;
}

.iti-flag.mk {
  height: 10px;
  background-position: -3270px 0;
}

.iti-flag.ml {
  height: 14px;
  background-position: -3292px 0;
}

.iti-flag.mm {
  height: 14px;
  background-position: -3314px 0;
}

.iti-flag.mn {
  height: 10px;
  background-position: -3336px 0;
}

.iti-flag.mo {
  height: 14px;
  background-position: -3358px 0;
}

.iti-flag.mp {
  height: 10px;
  background-position: -3380px 0;
}

.iti-flag.mq {
  height: 14px;
  background-position: -3402px 0;
}

.iti-flag.mr {
  height: 14px;
  background-position: -3424px 0;
}

.iti-flag.ms {
  height: 10px;
  background-position: -3446px 0;
}

.iti-flag.mt {
  height: 14px;
  background-position: -3468px 0;
}

.iti-flag.mu {
  height: 14px;
  background-position: -3490px 0;
}

.iti-flag.mv {
  height: 14px;
  background-position: -3512px 0;
}

.iti-flag.mw {
  height: 14px;
  background-position: -3534px 0;
}

.iti-flag.mx {
  height: 12px;
  background-position: -3556px 0;
}

.iti-flag.my {
  height: 10px;
  background-position: -3578px 0;
}

.iti-flag.mz {
  height: 14px;
  background-position: -3600px 0;
}

.iti-flag.na {
  height: 14px;
  background-position: -3622px 0;
}

.iti-flag.nc {
  height: 10px;
  background-position: -3644px 0;
}

.iti-flag.ne {
  height: 15px;
  background-position: -3666px 0;
}

.iti-flag.nf {
  height: 10px;
  background-position: -3686px 0;
}

.iti-flag.ng {
  height: 10px;
  background-position: -3708px 0;
}

.iti-flag.ni {
  height: 12px;
  background-position: -3730px 0;
}

.iti-flag.nl {
  height: 14px;
  background-position: -3752px 0;
}

.iti-flag.an {
  height: 14px;
  background-position: -5658px 0;
}

.iti-flag.no {
  height: 15px;
  background-position: -3774px 0;
}

.iti-flag.np {
  height: 15px;
  background-position: -3796px 0;
}

.iti-flag.nr {
  height: 10px;
  background-position: -3811px 0;
}

.iti-flag.nu {
  height: 10px;
  background-position: -3833px 0;
}

.iti-flag.nz {
  height: 10px;
  background-position: -3855px 0;
}

.iti-flag.om {
  height: 10px;
  background-position: -3877px 0;
}

.iti-flag.pa {
  height: 14px;
  background-position: -3899px 0;
}

.iti-flag.pe {
  height: 14px;
  background-position: -3921px 0;
}

.iti-flag.pf {
  height: 14px;
  background-position: -3943px 0;
}

.iti-flag.pg {
  height: 15px;
  background-position: -3965px 0;
}

.iti-flag.ph {
  height: 10px;
  background-position: -3987px 0;
}

.iti-flag.pk {
  height: 14px;
  background-position: -4009px 0;
}

.iti-flag.pl {
  height: 13px;
  background-position: -4031px 0;
}

.iti-flag.pm {
  height: 14px;
  background-position: -4053px 0;
}

.iti-flag.pn {
  height: 10px;
  background-position: -4075px 0;
}

.iti-flag.pr {
  height: 14px;
  background-position: -4097px 0;
}

.iti-flag.ps {
  height: 10px;
  background-position: -4119px 0;
}

.iti-flag.pt {
  height: 14px;
  background-position: -4141px 0;
}

.iti-flag.pw {
  height: 13px;
  background-position: -4163px 0;
}

.iti-flag.py {
  height: 11px;
  background-position: -4185px 0;
}

.iti-flag.qa {
  height: 8px;
  background-position: -4207px 0;
}

.iti-flag.re {
  height: 14px;
  background-position: -4229px 0;
}

.iti-flag.ro {
  height: 14px;
  background-position: -4251px 0;
}

.iti-flag.rs {
  height: 14px;
  background-position: -4273px 0;
}

.iti-flag.ru {
  height: 14px;
  background-position: -4295px 0;
}

.iti-flag.rw {
  height: 14px;
  background-position: -4317px 0;
}

.iti-flag.sa {
  height: 14px;
  background-position: -4339px 0;
}

.iti-flag.sb {
  height: 10px;
  background-position: -4361px 0;
}

.iti-flag.sc {
  height: 10px;
  background-position: -4383px 0;
}

.iti-flag.sd {
  height: 10px;
  background-position: -4405px 0;
}

.iti-flag.se {
  height: 13px;
  background-position: -4427px 0
}

.iti-flag.sg {
  height: 14px;
  background-position: -4449px 0;
}

.iti-flag.sh {
  height: 10px;
  background-position: -4471px 0;
}

.iti-flag.si {
  height: 10px;
  background-position: -4493px 0;
}

.iti-flag.sj {
  height: 15px;
  background-position: -4515px 0;
}

.iti-flag.sk {
  height: 14px;
  background-position: -4537px 0;
}

.iti-flag.sl {
  height: 14px;
  background-position: -4559px 0;
}

.iti-flag.sm {
  height: 15px;
  background-position: -4581px 0;
}

.iti-flag.sn {
  height: 14px;
  background-position: -4603px 0;
}

.iti-flag.so {
  height: 14px;
  background-position: -4625px 0;
}

.iti-flag.sr {
  height: 14px;
  background-position: -4647px 0;
}

.iti-flag.ss {
  height: 10px;
  background-position: -4669px 0;
}

.iti-flag.st {
  height: 10px;
  background-position: -4691px 0;
}

.iti-flag.sv {
  height: 12px;
  background-position: -4713px 0;
}

.iti-flag.sx {
  height: 14px;
  background-position: -4735px 0;
}

.iti-flag.sy {
  height: 14px;
  background-position: -4757px 0;
}

.iti-flag.sz {
  height: 14px;
  background-position: -4779px 0;
}

.iti-flag.ta {
  height: 10px;
  background-position: -4801px 0;
}

.iti-flag.tc {
  height: 10px;
  background-position: -4823px 0;
}

.iti-flag.td {
  height: 14px;
  background-position: -4845px 0;
}

.iti-flag.tf {
  height: 14px;
  background-position: -4867px 0;
}

.iti-flag.tg {
  height: 13px;
  background-position: -4889px 0;
}

.iti-flag.th {
  height: 14px;
  background-position: -4911px 0;
}

.iti-flag.tj {
  height: 10px;
  background-position: -4933px 0;
}

.iti-flag.tk {
  height: 10px;
  background-position: -4955px 0;
}

.iti-flag.tl {
  height: 10px;
  background-position: -4977px 0;
}

.iti-flag.tp {
  height: 10px;
  background-position: -4977px 0;
}

.iti-flag.tm {
  height: 14px;
  background-position: -4999px 0;
}

.iti-flag.tn {
  height: 14px;
  background-position: -5021px 0;
}

.iti-flag.to {
  height: 10px;
  background-position: -5043px 0;
}

.iti-flag.tr {
  height: 14px;
  background-position: -5065px 0;
}

.iti-flag.tt {
  height: 12px;
  background-position: -5087px 0;
}

.iti-flag.tv {
  height: 10px;
  background-position: -5109px 0;
}

.iti-flag.tw {
  height: 14px;
  background-position: -5131px 0;
}

.iti-flag.tz {
  height: 14px;
  background-position: -5153px 0;
}

.iti-flag.ua {
  height: 14px;
  background-position: -5175px 0;
}

.iti-flag.ug {
  height: 14px;
  background-position: -5197px 0;
}

.iti-flag.um {
  height: 11px;
  background-position: -5219px 0;
}

.iti-flag.us {
  height: 11px;
  background-position: -5241px 0;
}

.iti-flag.uy {
  height: 14px;
  background-position: -5263px 0;
}

.iti-flag.uz {
  height: 10px;
  background-position: -5285px 0;
}

.iti-flag.va {
  height: 15px;
  background-position: -5307px 0;
}

.iti-flag.vc {
  height: 14px;
  background-position: -5324px 0;
}

.iti-flag.ve {
  height: 14px;
  background-position: -5346px 0;
}

.iti-flag.vg {
  height: 10px;
  background-position: -5368px 0;
}

.iti-flag.vi {
  height: 14px;
  background-position: -5390px 0;
}

.iti-flag.vn {
  height: 14px;
  background-position: -5412px 0;
}

.iti-flag.vu {
  height: 12px;
  background-position: -5434px 0;
}

.iti-flag.wf {
  height: 14px;
  background-position: -5456px 0;
}

.iti-flag.ws {
  height: 10px;
  background-position: -5478px 0;
}

.iti-flag.xk {
  height: 15px;
  background-position: -5500px 0;
}

.iti-flag.ye {
  height: 14px;
  background-position: -5522px 0;
}

.iti-flag.yt {
  height: 14px;
  background-position: -5544px 0;
}

.iti-flag.za {
  height: 14px;
  background-position: -5566px 0;
}

.iti-flag.zm {
  height: 14px;
  background-position: -5588px 0;
}

.iti-flag.zw {
  height: 10px;
  background-position: -5610px 0;
}

.iti-flag.yu {
  height: 14px;
  background-position: -5634px 0;
}

.iti-flag {
  width: 20px;
  height: 15px;
  box-shadow: 0 0 1px 0 #888;
  background: #eaeaea url("../Assets/images/flags.png");
  background-repeat: no-repeat;
  background-color: #dbdbdb;
  background-position: 20px 0
}

.iti-flag.np {
  background-color: transparent
}

.UpperDialCode {
  .country-code {
    .CodePinCtrl {
      .flag-box {
        top: 18px;
      }
    }
  }
}

.archived-chat .active .tab-content>.tab-pane {
  display: block;
}

.archived-chat .EventInfoStage.chatEnableTab {
  position: relative;
}

.PicProdileUp {
  flex: 0 0 310px !important;
  max-width: 310px !important;
  border: 1px #333333 dashed !important;
  border-radius: 10px;
  padding: 12px 15px;

  .UpdateOvelayImgVideo {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;

    #userProfile {
      position: absolute;
      left: 0;
      opacity: 0;
      width: 100%;
      height: 100px;
      border-radius: 100px;
      z-index: 9;
      cursor: pointer;
      padding: 0px !important;
    }

    input#file-upload {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 9;
      opacity: 0;
      cursor: pointer;
    }

    img {
      background-color: #f2f2f2;
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }

    .ImgActionUp {
      position: absolute;
      z-index: 8;
      box-shadow: 0px 0px 10px #0003;
      right: 60px !important;
      top: 50% !important;
      transform: translateY(-50%);
      border-radius: 100px;

      .EditImgBtn {
        border: 2px #ed374f solid;
        width: 36px;
        height: 35px;
        border-radius: 100px;
        box-shadow: none;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        svg {
          width: 38px;
          height: 37px;
          fill: #ed374f !important;
        }
      }

      .RemoveImgBtn {
        background-color: #e7f1fe;
        width: 32px;
        height: 32px;
        margin-left: 4px;
        border-radius: 100px;
        box-shadow: none;
        box-shadow: 0px 0px 6px #0000006b;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        svg {
          width: 16px;
          height: 16px;
          fill: #1877f2 !important;
        }
      }
    }

    .ImgSelecetd {
      width: 100px;
      height: 100px;
      margin: 0 0 0;
      position: relative;
      background-color: #dddddd;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;

      .MidSelecetd {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 4;

        img {
          width: 100%;
          height: 100%;
          margin: 0 auto;
          background-position: 0px 0px, 10px 10px;
          background-size: 20px 20px;
          background-image: linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee 100%), linear-gradient(45deg, #eee 25%, white 25%, white 75%, #eee 75%, #eee 100%);
        }
      }

      svg {
        width: 40px;
        height: 40px;
      }
    }

    .BrowserImageTxt {
      width: 150px;
      position: absolute;
      right: 0;
      top: 14px;

      span {
        display: block;
        font-size: 12.5px;
        text-align: center;
        font-weight: 400;

        span {
          color: #237be2 !important;
        }
      }
    }
  }

  .UploadOnImage {
    #userProfile {
      width: 100px !important;
    }
  }
}



.CropImgPopup {
  width: 450px;
  padding: 10px 10px 0;
  background-color: #fff;
  overflow: hidden;
  border-radius: 10px;
  margin: 10px auto 10px;
  position: relative;
  z-index: 4;

  .MuiBox-root {
    position: relative;
  }

  header {
    h4 {
      display: none;
    }

    button {
      right: -5px !important;
      top: -7px !important;
      background-color: #ffffff;
      width: 26px;
      height: 26px;
      border-radius: 0px;

      svg {
        width: 12px;
        height: 12px;
        margin: -2px 8px 0;
      }

      &:hover {
        background-color: #ffffff !important;
      }
    }

    .CloseBtn {
      padding: 0;
      display: block;
      min-width: auto;
      position: absolute;
      right: 20px;
      top: 16px;
      z-index: 8;

      &:hover {
        background-color: #ffffff !important;
      }
    }
  }

  .CropImgInner {
    height: 285px;
    background-color: #d6d6d6;
    width: 100%;
    text-align: center;
    position: relative;
  }

  .ReactCrop {
    //width: 100% !important;
    margin: 0px auto;
    height: 100%;
    position: relative;

    .ReactCrop__child-wrapper {
      //aspect-ratio: 16/9;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        //aspect-ratio: 16/9;
        height: 100%;
        width: auto !important;
        // object-fit: scale-down;
      }
    }

    img {
      //aspect-ratio: 16/9;
      height: 100%;
      // object-fit: scale-down;
    }
  }

  .IconSuccess {
    width: 110px;
  }

  .PopupAction {
    margin: 0;

    .btn {
      width: 140px !important;
      margin: 0 14px;
    }

    .BlueBdrButton {
      border-radius: 10px !important;
    }
  }
}

.BlueBtnDark {
  background-color: #1877f2 !important;
  color: #fff !important;
  border: 1px #1877f2 solid !important;
  border-radius: 10px !important;
  text-transform: capitalize !important;
  font-size: 15px !important;
  margin: 0 10px;
  padding: 8px 6px !important;
}

.BlueBdrButton {
  background-color: transparent !important;
  color: #1877f2 !important;
  border: 1px #1877f2 solid !important;
  text-transform: capitalize !important;
  font-size: 15px !important;
  margin: 0 10px;
  padding: 8px 6px !important;
}

.alignCenter {
  align-items: center;
}

.EventTypeActonIn #dial_code .ListSeleftDDl .ZoneOptionBox .DialJustNow ul {
  display: flex;
  flex-direction: column;
}

li.notranslate.selected {
  order: -1;
  background-color: #eee;
}

.EventTypeActonIn #dial_code .ListSeleftDDl .ZoneOptionBox .DialJustNow ul li:hover {
  background-color: #fbfbfb;
}

li.highlighted-item:first-child {
  background-color: #f3f3f3;
}

.RecommendedValueInfo {
  padding: 0 10px;
  margin-top: 6px;

  b {
    font-weight: 400;
    font-size: 14px;
    color: #1877f2 !important;
  }

  ul {
    margin: 2px 0 4px;
    border-bottom: 1px #d3cece solid;
    padding: 0 0 6px;

    li {
      display: block;
      font-size: 14px;
      color: #3f4254;
      padding: 4px 0px;
      width: 100%;
      cursor: pointer;
      margin: 0px !important;

      &:hover {
        background-color: #fbfbfb;
      }
    }
  }
}

.media-select-popup {
  .modal-dialog {
    .modal-content {
      .modal-body {
        .media-list-wrapper {
          .SelectEnterZone {
            margin-bottom: 20px;

            .EnterZoneHeader {
              .SerchIcon {
                position: absolute;
                right: auto !important;
                left: 15px !important;
                top: 12px;

                svg {
                  width: 18px;
                  height: 18px;
                }
              }

              .SerchHere {
                height: 50px;
                padding: 0 14px 0 42px !important;
              }
            }
          }
        }
      }
    }
  }
}

.PeopleChatPopup .StateEventDtl .StageBackStage .EventInfoStage .PollInfos .MhBtnRemove {
  height: calc(100vh - 204px) !important;
}

.PeopleChatPopup .StateEventDtl .StageBackStage .EventInfoStage .PollInfos .PollMhAuto {
  height: calc(100vh - 204px) !important;
}

.PeopleChatPopup .StateEventDtl .StageBackStage .EventInfoStage .PollInfos {
  height: calc(100vh - 186px) !important;
}

.IframeUrlInfo {
  iframe {
    width: 100%;
    height: 100%;
    position: fixed;
  }
}

.loading-file-percentage-wrapper {
  position: absolute;
  width: 99%;
  left: 0;
  top: 0;
  height: 99%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  border-radius: 15px;
  overflow: hidden;
  flex-direction: column;
  background: #f0f0f0;

  p {
    margin-bottom: 4px;
    font-weight: 400;
  }

  .loading-file-percentage {
    position: relative;
    width: 100px;
    height: 5px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 10px;

    .loading-percentage {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      background-color: #4285f4;
      border-radius: 10px;
    }
  }
}

.SeatsImgORder {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  span {
    // background-color: #f00;
    display: inline-flex;
    margin: 0 0px;
    width: 26px;
    height: 26px;
    border-radius: 100px;
    position: absolute;
    left: 11px;
    top: 7px;
    font-size: 15px;
    align-items: center;
    justify-content: center;

    &:nth-child(1) {
      background-color: #3F2E88;
      left: 5px !important;
    }

    &:nth-child(2) {
      background-color: #FFF5F4;
      left: 8px;
    }

    // &:nth-child(3) {
    //   background-color: #d7b509;
    //   left: 9px;
    // }
  }
}

.RoomSpekerList {}

.SpeakerGpList {
  .modal-dialog {
    .modal-content {
      height: calc(100vh - 460px) !important;

      .modal-body {
        .RoomSpekerList {
          .SpeakerListSection {
            height: calc(100vh - 548px) !important;

            .EventSpeakerExp {
              min-height: auto !important;
              height: 68px;

              .ImgSpeakerBg {
                width: 38px;
                height: 38px;
              }
            }
          }
        }
      }
    }
  }
}

.stage-user-list {
  .video_footer {
    .WaveSound {
      //background-color: #3f2e88;
      width: 38px;
      height: 40px;
      //display: none;
      position: absolute;
      left: 5px;
      bottom: 0px;
      z-index: 9;

      .SoundWave {
        width: 100%;
        height: 100%;

      }
    }

  }

  .border-activated {
    .WaveSound {
      display: block;
    }
  }
}

.list-null {
  .video_footer {
    .WaveSound {
      //background-color: #3f2e88;
      width: 38px;
      height: 40px;
      //display: none;
      position: absolute;
      left: 0px;
      bottom: 28px;
      z-index: 9;

      .SoundWave {
        width: 100%;
        height: 100%;

      }
    }


  }

  .border-activated {
    .WaveSound {
      display: block;
    }
  }
}


.BsHeightFixLx {
  .list-null {
    .video_footer {
      .WaveSound {
        //background-color: #3f2e88;
        width: 38px;
        height: 40px;
        //display: none;
        position: absolute;
        left: 5px;
        bottom: 38px;
        z-index: 9;

        .SoundWave {
          width: 100%;
          height: 100%;

        }
      }


    }

    .border-activated {
      .WaveSound {
        display: block;
      }
    }
  }
}


.screen-share-element {
  .video_footer {
    .WaveSound {
      //background-color: #3f2e88;
      width: 38px;
      height: 40px;
      display: none;
      position: absolute;
      left: 5px;
      bottom: 0px;
      z-index: 9;

      .SoundWave {
        width: 100%;
        height: 100%;

      }
    }

  }

  .border-activated {
    .WaveSound {
      display: block;
    }
  }
}

.screen-share-element {
  .pinUserInfo {
    .video_footer {
      .WaveSound {
        display: block !important;
      }  
    }
    .border-activated {
      .WaveSound {
        display: block;
      }
    }
  }
}

.screen-stage-main-div {
  .stage-user-list {
    .video_footer {
      .WaveSound {
        left: 0px;
        bottom: -5px;
      }

    }
  }
}




.border-activated {
  .ShortName {
    h4 {
      position: relative;
      
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 100%;
        box-shadow: 0 0 0 0 rgba(255,255,255,.1);
        border-radius: 100%;
        opacity: 0;
        -webkit-animation: sdb03 2.3s infinite;
        animation: sdb03 2.3s infinite;
        box-sizing: border-box;
      }
    

    } 
  }
}
@-webkit-keyframes sdb03 {
0% {
  opacity: 0;
}
30% {
  opacity: 1;
}
60% {
  box-shadow: 0 0 0 30px rgba(255,255,255,.1);
  opacity: 0;
}
100% {
  opacity: 0;
}
}
@keyframes sdb03 {
0% {
  opacity: 0;
}
30% {
  opacity: 1;
}
60% {
  box-shadow: 0 0 0 30px rgba(255,255,255,.1);
  opacity: 0;
}
100% {
  opacity: 0;
}
}
.NoDataFound {
  width: 100% !important;
}

// .front-stage-share-screen > div{display:none}
// .front-stage-share-screen.screen-share-element > div{display:block}
span.password-tool-tip {
  display: inline-block;
  margin-left: 7px;
  cursor: pointer;
}
span.password-tool-tip svg {
  width: 18px;
  fill: #999;
}
span.password-tool-tip svg path{
  fill: #999;
}
.password-best-practices p {
  color: #333!important;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 5px;
}
.password-best-practices ul {
  padding-left: 20px;
  font-size: 12px;
}
button.back-login {
    position: absolute;
    top: 20px;
    background-color: transparent;
    border: none;
    display: flex;
    gap: 6px;
    align-items: center;
    z-index: 9;
}
