
.RegisterPagePro {
	background-size: 100% 100%;
	padding: 18px 0 0;
	background-attachment: fixed;
	
    height: auto !important;
    display: inline-block;
    background-color:#FFF5F4;
    .staticPositon {
        width: 100%;
        min-height: calc(95vh - 0.36rem);
        .container {
            max-width: calc(100vw - 40%) !important;
            .row {
                .LeftImgBlock {
                    display: inline-block !important;
                    //float: left;
                    overflow: initial!important;
                    width: calc(100% - 42%);
                    height: auto;
                    // background-color: #fff!important;
                    border-radius: 10px;

                    .LeftBlurSide {
                        width: 100%;
                        aspect-ratio: 16/9;
                        float: left;
                        height: auto;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        position: relative;
                        cursor: auto;
                        margin-bottom: 22px;
                        .scale-blur-fix {
                            border-radius: 12px;
                            .LoginLeftHg {
                                height: 100%;
                            }
                        }
                        .LoginBigImg {
                            .LeftRegTop {
                                .img-fluid {
                                    border-radius: 10px 10px 0 0;
                                }
                                .LoginEventLogo {
                                    top: 14px;
                                    left: 14px;
                                    width: 150px;
                                    .EventLogoFix {
                                        width: 100% !important;
                                        .img-fluid {
                                            border-radius: 0px !important;
                                        }
                                    }
                                }
                            }
                            .LeftRegTop {
                                height: 100%;
                                position: relative;
                                aspect-ratio: 16/9;
                                width: 100%;
                                .img-fluid {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: initial !important;
                                }
                            }
                        }
                        
                    }
                }
                .LoginRegisterPage {
                    position: initial !important;
                    //display: inline-block;
                    float: right;
                    //margin-left: 10px;
                    width: 39%;
                    min-width: 39%;
                    min-height: auto;
                    height: initial;
                    overflow-x: initial !important;
                    overflow-y: initial !important;
                    padding-bottom: 6px;
                    .EnterEventAdvance {
                        width: 100% !important;
                        padding: 30px 0 35px;
                        display: table;
                        position: relative;
                        overflow: hidden;
                        margin: 0 0 0px;
                        h2 {
                            font-weight: 600;
                            font-size: 32px;
                            margin: 20px 0 40px;
                            color: #fff;
                            letter-spacing: .5px;
                        }
                        .EventTypeAdvamce {
                            .form-group {
                                margin-bottom: 1.5em;
                                position: relative;
                                label {
                                    width: 100%;
                                    text-align: left;
                                    text-transform: uppercase;
                                    font-size: 15px;
                                    letter-spacing: .2px;
                                    margin: 0 0 12px;
                                }
                                .form-control {
                                    background-color: #fff;
                                    border-radius: 8px;
                                    padding: 16px 28px;
                                    color: #333;
                                    height: 54px;
                                    font-weight: 300;
                                    font-size: 15px;
                                    &:hover {
                                        box-shadow: none;
                                        outline: 0;
                                    }
                                    &:focus {
                                        box-shadow: none;
                                        outline: 0;
                                    }
                                    &::-webkit-input-placeholder {
                                        color: #ddd;
                                        font-weight: 300;
                                    }
                                    &::-ms-input-placeholder {
                                        color: #ddd;
                                        font-weight: 300;
                                    }
                                    &::placeholder {
                                        color: #ddd;
                                        font-weight: 300;
                                    }
                                }
                                .EnterBtn {
                                    background-color: rgba(0, 0, 0, 0.2);
                                  
                                    border: 0px;
                                    padding: 10px 28px;
                                    color: #fff;
                                    height: 54px;
                                    font-weight: 500;
                                    font-size: 18px;
                                    width: 100%;
                                    border-radius: 8px;
                                    &:hover {
                                        box-shadow: none;
                                        outline: 0;
                                    }
                                    &:focus {
                                        box-shadow: none;
                                        outline: 0;
                                    }
                                }
                                p {
                                    color: #1a78e5;
                                }
                                .OrTxt {
                                    position: relative;
                                    text-align: center;
                                    margin-top: 40px;
                                    &:after {
                                        content: "";
                                        position: absolute;
                                        background-color: #c7c7c7;
                                        width: 100%;
                                        height: 1px;
                                        left: 0;
                                        top: 11px;
                                    }
                                    span {
                                        /*background-color: #8944fc;*/
                                        font-weight: 500;
                                        letter-spacing: 1.5px;
                                        z-index: 5;
                                        position: relative;
                                        padding: 0 12px;
                                        color: #fff;
                                        font-size: 14px;
                                    }
                                }
                                .error {
                                    position: absolute;
                                    left: 0;
                                    width: 90%;
                                    bottom: -18px;
                                    font-size: 12px;
                                    margin: 0px auto;
                                    right: 0;
                                    text-align: left;
                                }
                                .EnterOtpValue {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    .OtpValue {
                                        input {
                                            margin: 0 8px;
                                            width: 44px !important;
                                            height: 44px !important;
                                            border-radius: 4px;
                                            box-shadow: none;
                                            outline: none;
                                            border: 1px rgb(0 0 0 / 40%) solid;
                                            font-size: 22px;
                                            font-weight: 500;
                                            &:first-child {
                                                margin-left: 0;
                                            }
                                            &:last-child {
                                                margin-left: 0;
                                            }
                                            &::-webkit-input-placeholder {
                                                color: #d3d3d3 !important;
                                                font-weight: 500;
                                            }
                                            &::-ms-input-placeholder {
                                                color: #d3d3d3 !important;
                                                font-weight: 500;
                                            }
                                            &::placeholder {
                                                color: #d3d3d3;
                                                font-weight: 500 !important;
                                            }
                                        }
                                    }
                                }
                                .SelectDropdown {
                                    .dropdown {
                                        .css-2b097c-container {
                                            .css-1pahdxg-control {
                                                height: 50px;
                                                border: 1px #a5a5a5 solid;
                                                box-shadow: none;
                                                padding: 0 12px;
                                                font-size: 14px;
                                                border-radius: 100px;
                                                cursor: pointer;
                                                div {
                                                    padding: 0 0;
                                                    padding-right: 15px;
                                                    .css-1wy0on6 {
                                                        .css-1okebmr-indicatorSeparator {
                                                            display: none;
                                                        }
                                                        .css-1okebmr-indicatorSeparator {
                                                            display: none;
                                                        }
                                                        .css-1gtu0rj-indicatorContainer {
                                                            display: none;
                                                        }
                                                        &::after {
                                                            content: "";
                                                            display: inline-block;
                                                            width: 13px;
                                                            height: 13px;
                                                            border-top: 2px solid var(--dark-gray);
                                                            border-right: 2px solid var(--dark-gray);
                                                            transform: rotate(135deg);
                                                            position: absolute;
                                                            right: 20px;
                                                            top: 15px;
                                                        }
                                                        .css-tlfecz-indicatorContainer {
                                                            display: none !important;
                                                        }
                                                    }
                                                }
                                                .css-1wy0on6 {
                                                    .css-1okebmr-indicatorSeparator {
                                                        display: none;
                                                    }
                                                    .css-1okebmr-indicatorSeparator {
                                                        display: none;
                                                    }
                                                    .css-1gtu0rj-indicatorContainer {
                                                        display: none;
                                                    }
                                                    &::after {
                                                        content: "";
                                                        display: inline-block;
                                                        width: 13px;
                                                        height: 13px;
                                                        border-top: 2px solid var(--dark-gray);
                                                        border-right: 2px solid var(--dark-gray);
                                                        transform: rotate(135deg);
                                                        position: absolute;
                                                        right: 20px;
                                                        top: 15px;
                                                    }
                                                    .css-tlfecz-indicatorContainer {
                                                        display: none !important;
                                                    }
                                                }
                                            }
                                            .css-yk16xz-control {
                                                width: 100%;
                                                height: 50px;
                                                border-radius: 100px;
                                                font-size: 14px;
                                                padding: 0 12px;
                                                border: 1px #a5a5a5 solid;
                                                color: var(--dark-gray);
                                                cursor: pointer;
                                                div {
                                                    padding: 0 0;
                                                    padding-right: 15px;
                                                }
                                                .css-1wy0on6 {
                                                    .css-1okebmr-indicatorSeparator {
                                                        display: none;
                                                    }
                                                    .css-1gtu0rj-indicatorContainer {
                                                        display: none;
                                                    }
                                                    &::after {
                                                        content: "";
                                                        display: inline-block;
                                                        width: 13px;
                                                        height: 13px;
                                                        border-top: 2px solid var(--dark-gray);
                                                        border-right: 2px solid var(--dark-gray);
                                                        transform: rotate(135deg);
                                                        position: absolute;
                                                        right: 20px;
                                                        top: 15px;
                                                    }
                                                    .css-tlfecz-indicatorContainer {
                                                        display: none !important;
                                                    }
                                                }
                                            }
                                        }
                                        div {
                                            text-align: left !important;
                                        }
                                    }
                                    .css-1hb7zxy-IndicatorsContainer {
                                        div {
                                            display: none;
                                        }
                                        .css-tlfecz-indicatorContainer {
                                            display: none;
                                        }
                                        .css-1okebmr-indicatorSeparator {
                                            display: none;
                                        }
                                        &::after {
                                            content: "";
                                            display: inline-block;
                                            width: 13px;
                                            height: 13px;
                                            border-top: 2px solid var(--dark-gray);
                                            border-right: 2px solid var(--dark-gray);
                                            transform: rotate(135deg);
                                            position: absolute;
                                            right: 20px;
                                            top: 14px;
                                        }
                                    }
                                    &::after {
                                        display: none;
                                    }
                                }
                                .timer {
                                    p {
                                        font-size: 14px;
                                        margin: 16px 0 0;
                                        font-weight: 400;
                                    }
                                }
                                .resendOtp {
                                    p {
                                        font-size: 14px;
                                        margin: 16px 0 0;
                                        font-weight: 400;
                                        text-align: center;
                                    }
                                    button {
                                        font-size: 14px;
                                        margin: 12px 0 0;
                                        font-weight: 400;
                                    }
                                }
                            }
                            .SelectDropdown {
                                .SeleftDDl {
                                    .dropdown-toggle {
                                        background-color: #eaeaea;
                                        height: 56px;
                                        color: #6c757d;
                                        border: 0;
                                    }
                                }
                                &:after {
                                    border-top: 2px solid #6b6b6b;
                                    border-right: 2px solid #6b6b6b;
                                    right: 26px;
                                    top: 18px;
                                }
                            }
                            .OptMessaes {
                                font-size: 15px;
                                margin: 0px 0 20px;
                                font-weight: 400;
                                strong {

                                }
                            }
                        }
                        .SocialLoginType {
                            position: relative;
                            z-index: 4;
                            text-align: center;
                            ul {
                                margin: 0px;
                                padding: 0;
                                li {
                                    display: inline-block;
                                    margin: 0 8px;
                                    &:first-child {
                                        margin-left: 0px;
                                    }
                                    &:last-child {
                                        margin-right: 0px;
                                    }
                                    .img-fluid {
                                        cursor: pointer;
                                        width: 46px;
                                        height: 46px;
                                    }
                                    svg {
                                        cursor: pointer;
                                        width: 46px;
                                        height: 46px;
                                        // ellipse{
                                        //     fill:#FFF5F4!important;
                                        // }
                                    }
                                    button {
                                        border: 0;
                                        background-color: transparent;
                                        padding: 0px !important;
                                    }
                                }
                            }
                        }
    
                        
                        .Vactor1 {
                            position: absolute;
                            width: 120px;
                            height: 170px;
                            overflow: hidden;
                            left: 0;
                            bottom: 0;
                            &::before {
                                content: '';
                                position: absolute;
                                background-image: url('../images/path-3.svg');
                                background-repeat: no-repeat;
                                bottom: -55px;
                                left: -105px;
                                width: 220px;
                                height: 220px;
                                background-size: 100% 100%;
                                opacity: .5;
                            }
                        }
                        .Vactor2 {
                            position: absolute;
                            width: 70px;
                            height: 70px;
                            overflow: hidden;
                            right: 0;
                            top: 0;
                            &::after {
                                content: '';
                                position: absolute;
                                background-image: url('../images/path-4.svg');
                                background-repeat: no-repeat;
                                top: -54px;
                                right: -54px;
                                width: 155px;
                                height: 155px;
                                background-size: 100% 100%;
                                opacity: .5;
                            }
                        }
                        .CurrentLanguage {
                            position: absolute;
                            top: 20px;
                            right: 22px;
                            z-index: 5;
                            span {
                                background-color: #fff;
                                padding: 5px 34px 5px 14px;
                                border-radius: 100px;
                                font-size: 13px;
                                position: relative;
                                cursor: pointer;
                                &:before {
                                    content: "";
                                    width: 9px;
                                    height: 9px;
                                    border-top: 2px solid #363636;
                                    border-right: 2px solid #363636;
                                    position: absolute;
                                    right: 14px;
                                    top: 8px;
                                    background-image: none;
                                    transform: rotate(135deg);
                                    -webkit-transform: rotate(135deg);
                                    transition: all 0.5s ease;
                                    -webkit-transition: all 0.5s ease;
                                    -moz-transition: all 0.5s ease;
                                    -o-transition: all 0.5s ease;
                                }
                                svg {
                                    width: 18px;
                                    margin-right: 6px;
                                }
                            }
                        }
                    }
                    .AdvanceEventDelReg {
                        width: 100% !important;
                        margin: 0 0 0;
                        padding: 48px 26px 26px;
                        overflow: initial !important;
                        text-align: left;
                        position: relative;
                        border: 1px #f5f5f5 solid;
                        height: 100%;
                        h3 {
                            color: #fff;
                            margin: 10px 0 22px;
                            font-size: 22px;
                            font-weight: 500;
                            line-height: 33px;
                        }
                        .DetailFixEventMx {
                            background-color: #ffffff;
                            border-radius: 6px;
                            padding: 0px;
                            position: relative;
                            z-index: 3;
                            // border: 1px #f5f5f5 solid;
                            .FullDeltailInfo {
                                padding:0;
                                border: none;
                                .EventNfollowUs {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    gap:20px;
                                    flex-wrap: wrap;
                                    .SocialMicro {
                                        // width: 34px;
                                        // height: 34px;
                                        border-radius: 100px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        cursor: pointer;
                                        gap: 10px;
                                        padding:5px 15px;
                                        font-size: 14px;
                                        font-weight: 500;
                                        svg {
                                            width: 18px;
                                            height: 18px;
                                        }
                                    }
                                }
                            }
                            span {
                                background-color: #8944fc;
                                color: #fff;
                                padding: 4px 15px;
                                font-size: 14px;
                                font-weight: 400;
                                border-radius: 100px;
                                text-transform: uppercase;
                            }
                            .TimeScheduleCal {
                                display: flex;
                                align-items: center;
                                margin: 24px 0 10px;
                                .TimeCalenderMix {
                                    display: inline-flex;
                                    align-items: center;
                                    svg {
                                        margin-right: 15px;
                                        width: 22px;
                                        height: 22px;
                                    }
                                    p {
                                        margin: 0px;
                                        font-size: 16px;
                                        margin-right: 25px;
                                    }
                                    &:last-child {
                                        p {
                                            margin-right: 0px;
                                        }
                                    } 
                                }
                            }
                            .registration-timezone {
                                background-color: #f1f1f1;
                                border-radius: 0px 0px 6px 6px;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                padding: 6px 14px;
                                //position: absolute;
                                //bottom: 0;
                                width: 100%;
                                left: 0;
                                div {
                                    font-size: 14px;
                                }
                            }
                            .LogoScheduleEvent {
                                margin: 20px 0 10px;
                                img {
                                    height: 32px;
                                    object-fit: scale-down;
                                }
                            }
                        }
                        .Vactor1 {
                            position: absolute;
                            width: 60px;
                            height: 60px;
                            overflow: hidden;
                            left: 0;
                            bottom: 0;
                            &::before {
                                content: '';
                                position: absolute;
                                background-image: url('../images/path-2.svg');
                                background-repeat: no-repeat;
                                bottom: -55px;
                                left: -55px;
                                width: 160px;
                                height: 160px;
                                background-size: 100% 100%;
                                opacity: .5;
                            }
                        }
                        .Vactor2 {
                            position: absolute;
                            width: 104px;
                            height: 104px;
                            overflow: hidden;
                            right: 0;
                            top: 0;
                            &::after {
                                content: '';
                                position: absolute;
                                background-image: url('../images/path-1.svg');
                                background-repeat: no-repeat;
                                top: -40px;
                                right: -40px;
                                width: 140px;
                                height: 140px;
                                background-size: 100% 100%;
                                opacity: .5;
                            }
                        }
                        
                        
                    }
                    .SlightlyEarlyYet {
                        background-color: #8944fc;
                        width: 100% !important;
                        position: relative;
                        //overflow: hidden;
                        margin: 10px 0 14px;
                        
                        .SlightlyEvents {
                            position: relative;
                            z-index: 2;
                            padding: 28px;
                            h3 {
                                font-weight: 500;
                                font-size: 22px;
                                margin: 0px;
                                color: #fff;
                                letter-spacing: 0.5px;
                            }
                            p {
                                font-weight: 400;
                                font-size: 20px;
                                margin: 0px;
                                color: #fff;
                                letter-spacing: 0.5px;
                            }
                        }
                        .Vactor1 {
                            position: absolute;
                            width: 120px;
                            height: 170px;
                            overflow: hidden;
                            left: 0;
                            bottom: 0;
                            &::before {
                                content: '';
                                position: absolute;
                                background-image: url('../images/path-3.svg');
                                background-repeat: no-repeat;
                                bottom: -55px;
                                left: -105px;
                                width: 220px;
                                height: 220px;
                                background-size: 100% 100%;
                                opacity: .5;
                            }
                        }
                        .Vactor2 {
                            position: absolute;
                            width: 70px;
                            height: 70px;
                            overflow: hidden;
                            right: 0;
                            top: 0;
                            &::after {
                                content: '';
                                position: absolute;
                                background-image: url('../images/path-4.svg');
                                background-repeat: no-repeat;
                                top: -54px;
                                right: -54px;
                                width: 155px;
                                height: 155px;
                                background-size: 100% 100%;
                                opacity: .5;
                            }
                        }
                       
                        
                        .SlightlyTimeSocial {
                            padding: 26px 25px 45px;
                            .OrTxt {
                                position: relative;
                                text-align: center;
                                span {
                                    //background-color: #8944fc;
                                    text-transform: uppercase;
                                    font-weight: 300;
                                    letter-spacing: 1.5px;
                                    z-index: 5;
                                    position: relative;
                                    padding: 0 12px;
                                    color: #fff;
                                    font-size: 15px;
                                }
                                &:after {
                                    content: "";
                                    position: absolute;
                                    background-color: #c7c7c7;
                                    width: 100%;
                                    height: 1px;
                                    left: 0;
                                    top: 11px;
                                }
                            }
    
                            .SocialLoginType {
                                position: relative;
                                z-index: 4;
                                ul {
                                    margin: 0px;
                                    padding: 0;
                                    li {
                                        display: inline-block;
                                        margin: 0 8px;
                                        &:first-child {
                                            margin-left: 0px;
                                        }
                                        &:last-child {
                                            margin-right: 0px;
                                        }
                                        .img-fluid {
                                            cursor: pointer;
                                            width: 52px;
                                        }
                                        button {
                                            border: 0;
                                            background-color: transparent;
                                        }
                                    }
                                }
                            }
                            .EventCalSchedules {
                                position: relative;
                                z-index: 4;
                                .form-group {
                                    margin: 0;
                                    h5 {
                                        display: none;
                                    }
                                }
                                .EventCalenderIcon {
                                    img {
                                        width: 50px;
                                        height: 50px;
                                        margin: 0 6px;
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                        
                        .EventTypeAdvamce {
                            width: 100% !important;
                            .EnterEventAction {
                                width: 100% !important;
                                display: block;
                                padding: 12px 40px;
                                position: relative;
                                z-index: 3;
                                .EventTypeActonIn {
                                    .form-group {
                                        color: #000;
                                        .form-control {
                                            background-color: rgb(0 0 0 / 20%);
                                            border-radius: 100px;
                                            border: 0px;
                                            padding: 16px 28px;
                                            color: #ffffff;
                                            height: 50px;
                                            font-weight: 300;
                                            font-size: 15px;
                                            &:hover {
                                                box-shadow: none;
                                                outline: 0;
                                            }
                                            &:focus {
                                                box-shadow: none;
                                                outline: 0;
                                            }
                                            &::-webkit-input-placeholder {
                                                color: #ddd;
                                                font-weight: 300;
                                            }
                                            &::-ms-input-placeholder {
                                                color: #ddd;
                                                font-weight: 300;
                                            }
                                            &::placeholder {
                                                color: #ddd;
                                                font-weight: 300;
                                            }
                                        }
    
                                        .SelectDropdown {
                                            .dropdown {
                                                .css-2b097c-container {
                                                    .css-1pahdxg-control {
                                                        height: 50px;
                                                        border: 1px rgba(0, 0, 0, 0.2) solid;
                                                        box-shadow: none;
                                                        padding: 0px 28px;
                                                        font-size: 14px;
                                                        border-radius: 100px;
                                                        cursor: pointer;
                                                        color: #fff;
                                                        background-color: rgba(0, 0, 0, 0.2);
                                                        div {
                                                            padding: 0 0;
                                                            padding-right: 15px;
                                                            color: #fff;
                                                            font-size: 15px;
                                                            font-weight: 300;
                                                            .css-12jo7m5 {
                                                                color: #000 !important;
                                                                font-size: 12px !important;
                                                                font-weight: 400 !important;
                                                            }
                                                            .css-1wy0on6 {
                                                                .css-1okebmr-indicatorSeparator {
                                                                    display: none;
                                                                }
                                                                .css-1okebmr-indicatorSeparator {
                                                                    display: none;
                                                                }
                                                                .css-1gtu0rj-indicatorContainer {
                                                                    display: none;
                                                                }
                                                                &::after {
                                                                    content: "";
                                                                    display: inline-block;
                                                                    width: 13px;
                                                                    height: 13px;
                                                                    border-top: 2px solid var(--dark-gray);
                                                                    border-right: 2px solid var(--dark-gray);
                                                                    transform: rotate(135deg);
                                                                    position: absolute;
                                                                    right: 20px;
                                                                    top: 15px;
                                                                }
                                                                .css-tlfecz-indicatorContainer {
                                                                    display: none !important;
                                                                }
                                                            }
                                                            .css-1wa3eu0-placeholder {
                                                                overflow: hidden;
                                                                text-overflow: ellipsis;
                                                                display: -webkit-box;
                                                                -webkit-line-clamp: 1;
                                                                -webkit-box-orient: vertical;
                                                            }
                                                        }
                                                        .css-1wy0on6 {
                                                            .css-1okebmr-indicatorSeparator {
                                                                display: none;
                                                            }
                                                            .css-1okebmr-indicatorSeparator {
                                                                display: none;
                                                            }
                                                            .css-1gtu0rj-indicatorContainer {
                                                                display: none;
                                                            }
                                                            &::after {
                                                                content: "";
                                                                display: inline-block;
                                                                width: 13px;
                                                                height: 13px;
                                                                border-top: 2px solid var(--dark-gray);
                                                                border-right: 2px solid var(--dark-gray);
                                                                transform: rotate(135deg);
                                                                position: absolute;
                                                                right: 20px;
                                                                top: 15px;
                                                            }
                                                            .css-tlfecz-indicatorContainer {
                                                                display: none !important;
                                                            }
                                                        }
                                                    }
                                                    .css-yk16xz-control {
                                                        width: 100%;
                                                        height: 50px;
                                                        border-radius: 100px;
                                                        font-size: 14px;
                                                        padding: 0px 28px;
                                                        border: 1px rgba(0, 0, 0, 0.2) solid;
                                                        color: var(--dark-gray);
                                                        cursor: pointer;
                                                        background-color: rgba(0, 0, 0, 0.2);
                                                        div {
                                                            padding: 0 0;
                                                            padding-right: 15px;
                                                            color: #fff;
                                                            font-size: 15px;
                                                            font-weight: 300;
                                                        }
                                                        .css-12jo7m5 {
                                                            color: #000 !important;
                                                            font-size: 12px !important;
                                                            font-weight: 400 !important;
                                                        }
    
                                                        .css-1wa3eu0-placeholder {
                                                            overflow: hidden;
                                                            text-overflow: ellipsis;
                                                            display: -webkit-box;
                                                            -webkit-line-clamp: 1;
                                                            -webkit-box-orient: vertical;
                                                        }
                                                        .css-1wy0on6 {
                                                            .css-1okebmr-indicatorSeparator {
                                                                display: none;
                                                            }
                                                            .css-1gtu0rj-indicatorContainer {
                                                                display: none;
                                                            }
                                                            &::after {
                                                                content: "";
                                                                display: inline-block;
                                                                width: 13px;
                                                                height: 13px;
                                                                border-top: 2px solid var(--dark-gray);
                                                                border-right: 2px solid var(--dark-gray);
                                                                transform: rotate(135deg);
                                                                position: absolute;
                                                                right: 20px;
                                                                top: 15px;
                                                            }
                                                            .css-tlfecz-indicatorContainer {
                                                                display: none !important;
                                                            }
                                                        }
                                                        
                                                    }
                                                }
                                                div {
                                                    text-align: left !important;
                                                }
                                            }
                                            .css-1hb7zxy-IndicatorsContainer {
                                                div {
                                                    display: none;
                                                }
                                                .css-tlfecz-indicatorContainer {
                                                    display: none;
                                                }
                                                .css-1okebmr-indicatorSeparator {
                                                    display: none;
                                                }
                                                &::after {
                                                    content: "";
                                                    display: inline-block;
                                                    width: 11px;
                                                    height: 11px;
                                                    border-top: 2px solid #fff;
                                                    border-right: 2px solid #fff;
                                                    transform: rotate(135deg);
                                                    position: absolute;
                                                    right: 24px;
                                                    top: 16px;
                                                }
                                            }
                                            &::after {
                                                display: none;
                                            }
                                        }
    
                                    }
                                }
                                .LogOUtBtn {
                                    .EnterBtn {
                                        background-color: #8944fc;
                                    }
                                }
                            }
                        }
    
                    }
                }
    
                .RegisterWrapper {
                    display: flex;
                    justify-content: space-between;
                    .LoginRegisterPage {
                    //   height: 100%;
                    }
                  }
            }
    
        }
    }
	
}

.RadiusMx {
	border-radius: 10px;
}

.TimerCoundTime {
    background-color: rgb(0 0 0 / 40%);
    margin: 0 0 10px;
    padding: 15px 0;
    position: relative;
    z-index: 4;
    .timerBodyCondown {
      display: flex;
      align-items: center;
      width: 340px;
      margin: 0px auto;
      justify-content: space-between;
      div {
        background-color: #fff;
        border-radius: 6px;
        color: #000;
        .time-wrapper {
          background-color: transparent !important;
          .time {
            //color: #8944FC;
            font-weight: 600;
            font-size: 30px;
            line-height: 38px;
            background: transparent;
            width: auto !important;
            height: auto !important;
          }
          div {
            display: block;
            //color: var(--dark-gray);
            font-size: 10px;
            font-weight: 500;
            background: transparent;
            width: auto !important;
            height: auto !important;
          }
        }
  
      }
      span {
        color: rgb(0 0 0 / 21%);
        font-size: 70px;
        line-height: 0px;
        font-family: fantasy;
      }
      .event-live {
        background-color: transparent !important;
        width: 100% !important;
        height: auto !important;
        h3 {
          font-size: 22px;
          font-weight: 500;
          margin: 0;
        }
      }
    }
  }
.RegisterPagePro .RegistrationTabsHome {
    width: 100%;
    float: left;
    .CloseBar {
        display: none;
    }
    .responsive-tabs-container {
        position: sticky;
        position: -webkit-sticky !important;
        top: 5px;
        z-index: 9;
        transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        .nav-tabs {
            padding: 20px 0 0;
            border-bottom: 1px solid #eee;
            background-color: #fff;
            border-radius: 0;
            box-shadow: none;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            //margin-left: 3px;
            text-align: center;
            .nav {
                justify-content: center;
                display: block;
                .nav-link  {
                    padding: 0;
                    border: 0;
                    padding: 8px 32px;
                    border-radius: 0;
                    font-weight: 500;
                    font-size: 16px;
                    color: #333;
                    cursor: pointer;
                    border-bottom: 4px solid transparent;
                    
                    &:hover {
                        border: 0px;
                        box-shadow: none;
                        outline: none;
                        border-bottom: 4px solid transparent;
                    }
                    &:focus {
                        border: 0px;
                        box-shadow: none;
                        outline: none;
                    }
                }
            }
        }
        ul {
            align-items: center;
            justify-content: space-around;
            width: 100%;
            display: flex;
            text-align: center;
            li {
                display: inline-block;
                a {
                    padding: 0;
                    border: 0;
                    padding: 10px 32px;
                    border-radius: 100px;
                    font-weight: 400;
                    font-size: 16px;
                    color: #333;
                    cursor: pointer;
                }
            }
        }
    }
    .InfoStageOrder {
        //margin: 30px 0 0;
        //padding: 0 12px;
        .AdvanceTab {
            .InfoHeadingTop {
                margin-bottom: 18px;
                h4 {
                    color: #8944FC;
                    font-size: 20px;
                    font-weight: 500;
                    text-transform: capitalize;
                    svg {
                        width: 25px;
                        height: 25px;
                        //vertical-align: sub;
                        margin-right: 10px;
                    }
                }
                p {
                    color: #353535;
                    //font-size: 15px;
                    margin: 0;
                    word-wrap: break-word;
                    a {
                        cursor: pointer;
                    }
                }
                ul {
                    padding-left: 20px;
                }
            }
            .scheduleBlock {
                padding: 0 8px;
                .SetEventSchedule {
                    .SelectDayStage {
                        position: absolute;
                        top: -10px;
                        right: 10px;
                        width: 420px;
                        background-color: #ffffff;
                        display: flex;
                        flex-direction: row-reverse;
                        // align-items: center;
                        // justify-content: space-between;
                        .DayScheduleStages {
                            width: 50%;
                            min-width: 46%;
                            margin-left: 14px;
                            .SelectDropdown {
                                .SeleftDDl {
                                    .dropdown {
                                        position: relative;
                                        .dropdown-toggle {
                                            position: relative;
                                            border: 1px solid #eee;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            display: -webkit-box;
                                            -webkit-line-clamp: 1;
                                            -webkit-box-orient: vertical;
                                            background-color: transparent;
                                            border-radius: 5px;
                                            &::before {
                                                content: '';
                                                width: 10px;
                                                height: 10px;
                                                border-top: 2px solid #363636;
                                                border-right: 2px solid #363636;
                                                position: absolute;
                                                right: 20px;
                                                top: 14px;
                                                background-image: none;
                                                transform: rotate(135deg);
                                                -webkit-transform: rotate(135deg);
                                                transition: all 0.5s ease;
                                                -webkit-transition: all 0.5s ease;
                                                -moz-transition: all 0.5s ease;
                                                -o-transition: all 0.5s ease;
                                            }
                                        }
                                        .schedule-dropdown {
                                            transform: initial !important;
                                            top: 48px !important;
                                            border-radius: 10px;
                                            overflow: hidden !important;
                                            padding: 7px 4px 0 0px !important;
                                            max-height: 160px !important;
                                            inset: inherit !important;
                                            margin-top: 2px;
                                            z-index: 6;
                                            .DdlScheduleFix {
                                                width: 100%;
                                                max-height: 145px !important;
                                                overflow-y: auto;
                                                padding: 0px 12px 0px 12px !important;
                                                &::-webkit-scrollbar-track {
                                                    border-radius: 4px;
                                                    box-shadow: inset 0 0 5px #f1f0f0;
                                                    background-color: #fffcfc;
                                                }
                                                &::-webkit-scrollbar-thumb {
                                                    border-radius: 4px;
                                                    background-color: #e0e0e0;
                                                }
                                                &::-webkit-scrollbar {
                                                    width: 6px;
                                                }
                                            }
                                        }
                                    }
                                    .show {
                                        .dropdown-toggle {
                                            &:before {
                                                transform: rotate(-45deg);
                                                top: 18px;
                                                transition: all 0.5s ease;
                                                -webkit-transition: all 0.5s ease;
                                                -moz-transition: all 0.5s ease;
                                                -o-transition: all 0.5s ease;
                                              }
                                        }
                                    }
                                }
                            }
                        }
                        .SelectSchedule {
                            width: 100%;
                            margin-left: 14px;
                            .PepleSerchHere {
                                .SerchHere {
                                    height: 40px !important;
                                }
                            }
                        }
                    }
                }
            }
            .sponsorBlock {
                padding: 0 8px;
                ul {
                    margin: 0;
                    padding: 0;
                    --bs-gutter-x: 1.5rem;
                    --bs-gutter-y: 0;
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: calc(var(--bs-gutter-y) * -1);
                    margin-right: calc(var(--bs-gutter-x) * -.5);
                    margin-left: calc(var(--bs-gutter-x) * -.5);
                    li {
                        width: 22%;
                        border: 1px #d7d7d7 solid;
                        height: 70px;
                        vertical-align: middle;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        padding: 4px 4px;
                        margin: 0 8px 15px;
                        border-radius: 6px;
                        a {
                            height: 100%;
                            img {
                                width: 100%;
                                object-fit: scale-down;
                                height: 100%;
                                padding: 0 5px;
                            }
                        }
                        img {
                            width: 100%;
                            object-fit: scale-down;
                            height: 100%;
                            padding: 0 5px;
                        }
                    }
                }
            }
        }

        .ScheduleMixTab {
            position: relative;
            cursor: auto;
            .scheduleBlock {
                .Advancd-md {
                    width: 100% !important;
                    padding: 0 4px;
                }
                .AllPlanSchedule {
                    padding: 0px 10px 0 0 !important;
                    margin: 10px 0 0;
                    height: auto !important;
                    overflow-x: hidden;
                    min-height: 100px;
                    .ScheduleCalendarInfos {
                        width: 100%;
                        .StageFullInfoFix {
                          background-color: #fff5f4;
                          border-radius: 20px;
                          width: 100%;
                          display: flex;
                          align-items: inherit;
                          justify-content: space-between;
                          margin-bottom: 20px;
                          position: relative;
                          height: auto;
                          border: 1px #fff5f4 solid;
                          flex-direction: column;
                          .CalenderDateScheduleInof {
                            background-color: transparent;
                            height: auto;
                            padding: 20px 12px 12px;
                            border-radius: 20px 0px 0px 20px;
                            text-align: left;
                            position: relative;
                            width: 100%;
                            display: block;
                            align-items: center;
                            justify-content: space-evenly;
                            //justify-content: center;
                            flex-flow: column;
                            .SchTimeDateFix.notranslate {
                                display: inline-flex;
                                align-items: center;
                                font-weight: 600;
                                font-size: 16px;
                                background-color: #fff;
                                padding: 1px 15px;
                                border-radius: 5px;
                                gap:10px;
                               
                            }
                            .StageNo {
                              /*position: absolute; */
                              /* top: 15px; */
                              border: 1px #eb354d solid;
                              color: #eb354d;
                              border-radius: 80px;
                              padding: 2px 6px;
                              left: 20px;
                              font-size: 13px;
                              font-weight: 500;
                              background: #ffffff;
                              left: 0;
                              right: 0;
                              overflow: hidden;
                              text-overflow: ellipsis;
                              display: -webkit-box;
                              -webkit-line-clamp: 1;
                              -webkit-box-orient: vertical;
                              width: 130px;
                              margin: 0px auto;
                              min-height: 26px;
                            }
                            .DtStageEventDay {
                              //margin-top: 18px;
                              display: flex;
                              h4 {
                                color: var(--dark-gray);
                                font-weight: 500;
                                line-height: inherit;
                                margin: 0;
                                font-size: inherit;
                                display: flex;
                                gap: 10px;
                                strong {
                                  display: block;
                                  font-weight: 500;
                                }
                              }
                            }
                            .ScheduleTMFx {
                              margin: 0;
                              p {
                                color: #333;
                                font-size: inherit;
                                font-weight: 500;
                                text-transform: uppercase;
                                margin: 0 0 0;
                              }
                            }
                            .ScheduleStatusOngoing {
                              .EventStyle {
                                color: var(--dark-gray);
                                position: absolute;
                                top: -15px;
                                border: 0;
                                border-radius: 80px;
                                padding: 2px 6px;
                                font-size: 13px;
                                font-weight: 600;
                                left: 0;
                                right: 0;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;
                                width: 110px;
                                margin: 0px auto;
                                min-height: 26px;
                              }
                            }
                            .calenderInfoGoogle {
                              background-color: #fff;
                              padding: 8px 12px;
                              border-radius: 100px;
                              box-shadow: 0px 0px 6px rgb(0 0 0 / 28%);
                              display: none;
                              a {
                                padding: 0 5px;
                                svg {
                                  width: 18px;
                                  height: 18px;
                                }
                              }
                            }
                          }
                          &:last-child {
                            margin-bottom: 20px !important;
                          }
                         
                        }
                        .ScheduleInfoWithConts {
                          width: 100%;
                          padding: 0 16px;
                              h3 {
                                margin: 0 0 8px;
                                font-size: 18px;
                                font-weight: 500;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;
                                color: #8944FC;
                              }
                              .ScheduleDiscribe {
                                position: relative;
                                p {
                                  margin: 0;
                                  //padding-right: 92px;
                                  font-size: 14px;
                                  font-weight: 400;
                                  // overflow: hidden;
                                  // text-overflow: ellipsis;
                                  // display: -webkit-box;
                                  // -webkit-line-clamp: 1;
                                  // -webkit-box-orient: vertical;
                                  .ReadMoreSec {
                                    //position: absolute;
                                    top: 0;
                                    right: 0;
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: #1a78e5
                                  }
                                }
                                
                              }
                             
                              .BackAgain {
                                position: absolute;
                                right: 0px;
                                top: 0;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                background-color: #f0f0f0;
                                border-radius: 0px 10px 10px 0px;
                                a {
                                  height: 100%;
                                  display: flex;
                                  padding: 0 14px;
                                  img {
                                    width: 15px !important;
                                  }
                                }
                              }
                        }
                        .SpeakerRowList {
                          margin: 14px 0 0;
                          display: inline-flex;
                          align-items: center;
                          justify-content: flex-start;
                          //overflow-x: auto;
                          //overflow-y: hidden;
                          width: 100%;
                          margin-bottom: 0px;
                          padding-bottom: 30px;
                          height: auto;
                          .EventSpeakerExp {
                            background-color: transparent;
                            display: inline-flex;
                            padding: 10px 12px;
                            border-radius: 8px;
                            margin-right: 18px;
                            //min-width: 216px;
                            min-height: 82px;
                            align-items: center;
                            border: 1px #8944FC solid;
                            width:auto;
                            .ImgSpeakerBg {
                              width: 45px;
                              height: 45px;
                              .img-fluid {
                                border-radius: 100px;
                                height: 100%;
                                object-fit: cover !important;
                              }
                            }
                            .SpeakerNamePos {
                              width: 135px;
                              margin-left: 12px;
                              padding: 3px 0;
                              .SpeakerDegDetail {
                                h4 {
                                    font-size: 14px;
                                    margin: 0 0 3px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 1;
                                    -webkit-box-orient: vertical;
                                    color: #8944FC;
                                    font-weight: 500;
                                  strong {
                                    font-weight: 600;
                                  }
                                }
                                .DetailPostMix {
                                  cursor: pointer;
                                  p {
                                    font-size: 13px;
                                    margin: 0;
                                    color: #383838;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 1;
                                    -webkit-box-orient: vertical;
                                    line-height: 16px;
                                  }
                                  span {
                                    font-size: 13px;
                                    margin: 0;
                                    color: #383838;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 1;
                                    -webkit-box-orient: vertical;
                                    b {
                                      font-weight: 500;
                                    }
                                  } 
                                }
                              }
                            }
                            &:last-child {
                              margin-right: 0;
                            }
                          }
                          .slick-slider {
                            position: initial;
                            width: 100%;
                            .slick-list {
                              .slick-track {
                                .slick-slide {
                                  padding: 0 6px;
                                }
                              }
                            }
                            .slick-arrow {
                              bottom: -7px !important;
                              top: auto !important;
                              &::before {
                                display: none;
                              }
                            }
                            .slick-prev {
                              left: auto;
                              right: 31px;
                            }
                            .slick-next {
                              right: 8px;
                            }
                          }
                        }
                        .Watch-Session {
                          display: inline-flex;
                          margin-top: 16px;
                          .RedButton  {
                            padding: 6px 16px;
                            font-size: 15px;
                          }
                        }
                      }
                }
                .schedulOverFlow {
                    //height: calc(100vh - 425px) !important;
                    //overflow-x: hidden;
                }
            }
        }
        .AdvanceTab {
            margin: 30px 0 0;
        }
    }
}
.LoginAboutRegister {
    margin: 0 0 20px;
    ul {
      margin: 0;
      padding: 0;
      li {
        display: inline-block;
        margin-right: 8px;
        a {
            svg {
                width: 30px;
                height: 30px;
            }
        }
      }
    }
  }


  .enter-the-event-div {
        position: fixed;
        bottom: 0;
        width: 98%;
        z-index: 16;
        left: 0;
        display: none;
        right: 0;
        margin: 0px auto;
    .enter-the-event-button {
        background-color: #8944FC;
        border: 0;
        box-shadow: none;
        width: 100%;
        padding: 16px 2px;
        border-radius: 12px 12px 0 0px;
        color: #fff;
        font-weight: 500;
        font-size: 20px;
    }
}

.RegisterPagePro {
    .staticPositon { 
        .container {
            .row {
                .MobileTopView {
                    display: none;
                }
                .LoginRegisterPage {
                    .DeskTopView {
                        display: block;
                    }
                }
                
            }
        }
    }
    
}
.RegisterPagePro  h2.eventHeader {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 20px;
}
.RegisterPagePro .event-detail-wrapper {
    background-color: #fff;
    border-radius: 0 0 10px 10px;
    padding: 0px 30px 30px;
    clear: both;
}
.RegisterPagePro .LeftImgBlock .event-detail-wrapper .LogoScheduleEvent img {
    width: 100%;
    object-fit: contain;
    max-width: 180px;
    margin-bottom: 15px;
    max-height: 100px;
    object-position: left;
}
.RegisterPagePro .TimeScheduleCal.notranslate {
    display: flex;
    gap: 30px;
    color: #333;
    flex-wrap: wrap;
    margin-bottom: 15px;
    .TimeCalenderMix {
        display: flex;
        align-items: center;
        gap: 10px;
        svg {
            max-width: 20px;
        }
        p {
            margin-bottom: 0;
        }
        path {
            fill: #333;
        }
    }
}
.RegisterPagePro .registration-timezone {
    color: #333;
    display: flex;
    gap: 3px;
}
.RegisterPagePro .LeftBlurSideInner {
    background-color: #fff;
    border-radius: 10px;
    height: 100%;
}
.RegisterPagePro .event-detail-wrapper.clearfix.event-detail-tabbing {
    margin-top: 30px;
    border-radius: 10px;
}
.RegisterPagePro button.btn.btn-add-to-calendar {
    display: block;
    border-radius: 100px;
    font-size: 14px;
    font-weight: 500;
}
.calendar-pop .EventCalSchedules h5 {
    text-align: center;
}
.calendar-pop .EventCalSchedules .EventCalenderIcon{text-align: center;}
.CurrentLanguage1 {
    position: absolute;
    top: 20px;
    right: 22px;
    z-index: 5;
    span {
        background-color: #fff;
        padding: 5px 34px 5px 14px;
        border-radius: 100px;
        font-size: 13px;
        position: relative;
        cursor: pointer;
        &:before {
            content: "";
            width: 9px;
            height: 9px;
            border-top: 2px solid #363636;
            border-right: 2px solid #363636;
            position: absolute;
            right: 14px;
            top: 8px;
            background-image: none;
            transform: rotate(135deg);
            -webkit-transform: rotate(135deg);
            transition: all 0.5s ease;
            -webkit-transition: all 0.5s ease;
            -moz-transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
        }
        svg {
            width: 18px;
            margin-right: 6px;
        }
    }
}
.RegisterPagePro footer { background-color: transparent; }
.pro-event-expired {
    text-align: center;
    font-size: 26px;
    font-weight: 600;
}

.RegisterPageAll.RegisterPagePro .dropdown-toggle {
    position: relative;
    border: 1px solid #eee;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    background-color: #fff;
    border-radius: 5px;
    width:155px;
    margin-left:auto;
    &::before {
        content: '';
        width: 10px;
        height: 10px;
        border-top: 2px solid #363636;
        border-right: 2px solid #363636;
        position: absolute;
        right: 20px;
        top: 14px;
        background-image: none;
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
        transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    }
}

.RegisterPagePro .RegistrationTabsHome .InfoStageOrder .ScheduleMixTab .ScheduleCalendarInfos .SpeakerRowList .slick-slider .slick-arrow { bottom: -7px !important; top: auto !important; }
.RegisterPagePro .RegistrationTabsHome .InfoStageOrder .ScheduleMixTab .ScheduleCalendarInfos .SpeakerRowList{padding:0 0 30px;}

.RegisterPagePro .ProfileOverflow.schedule-dropdown.dropdown-menu.show[x-placement="top-start"] {
    transform: translate(0px, -48px)!important;
}
.RegisterPagePro .ProfileOverflow.schedule-dropdown.dropdown-menu.show[x-placement="bottom-start"] {
    transform: translate(0px, 48px)!important;
}
.df-logo-share-event {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap:15px;
}
.df-logo-share-event .SocialMicro {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    cursor: pointer;
}
.RegisterPagePro .registration-timezone strong {
    font-weight: 500;
}
.pro-event-expired {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    img {
        max-width: 90%;
    }
}


  @media (min-width:1600px) and (max-width:1919px) {
    .RegisterPagePro {
        .staticPositon {
            .container {
                .row {
                    .LoginRegisterPage {
                        .AdvanceEventDelReg {
                            padding: 72px 24px 24px;
                            h3 {
                                font-size: 20px;
                                line-height: 32px;
                                padding: 0px 0 12px;
                                margin: 0px 0 4px;
                            }
                            .DetailFixEventMx {
                                span {
                                    padding: 4px 14px;
                                    font-size: 12px;
                                }
                                .TimeScheduleCal {
                                    .TimeCalenderMix {
                                        svg {
                                            margin-right: 8px;
                                            width: 18px;
                                            height: 18px;
                                        }
                                        p {
                                            font-size: 14px;
                                            margin-right: 10px;
                                        }                                    
                                    }
                                }
                            }
                        }
                        .EnterEventAdvance {
                            // padding: 45px 40px 40px;
                            h2 {
                                font-size: 28px;
                                margin: 0px 0 34px;
                            }
                            .Vactor1 {
                                &::before {
                                    bottom: -47px;
                                    left: -106px;
                                    width: 200px;
                                    height: 200px;
                                }
                            }
                            .Vactor2 {
                                &::after {
                                    width: 145px;
                                    height: 145px;
                                }
                            }
                        }
                        .SlightlyEarlyYet {
                            .SlightlyEvents {
                                h3 {
                                    font-size: 18px;
                                }
                                p {
                                    font-size: 18px;
                                }
                            }
                            .timerBodyCondown {
                                .event-live {
                                    h3 {
                                        font-size: 20px;
                                    }
                                }
                            }
                            .SlightlyTimeSocial {
                                .SocialLoginType {
                                    ul {
                                        li {
                                            .img-fluid {
                                                width: 48px;
                                            }
                                        }
                                    }
                                }
                            }
                            &::before {
                                bottom: -47px;
                                left: -106px;
                                width: 200px;
                                height: 200px;
                            }
                            &::after {
                                width: 135px;
                                height: 135px;
                            }
                        }
                    }
                }
            }
        }
        
    }
    .RegisterPagePro h2.eventHeader { font-size: 24px; }
  }

  @media (min-width:1440px) and (max-width:1599px) {
    .RegisterPagePro h2.eventHeader { font-size: 24px; }
    .RegisterPagePro {
        .staticPositon {
            .container {
                max-width: calc(100vw - 26%) !important;
                .row {
                    .LoginRegisterPage {
                        .AdvanceEventDelReg {
                            padding: 72px 22px 22px;
                            h3 {
                                font-size: 18px;
                                font-weight: 500;
                                line-height: 26px;
                                margin: 0px 0 18px;
                            }
                            .DetailFixEventMx {
                                span {
                                    padding: 4px 14px;
                                    font-size: 12px;
                                }
                                .TimeScheduleCal {
                                    .TimeCalenderMix {
                                        svg {
                                            margin-right: 5px;
                                            width: 16px;
                                            height: 16px;
                                        }
                                        p {
                                            font-size: 12px;
                                            margin-right: 10px;
                                            font-weight: 500;
                                        }                       
                                    }
                                }
                                .LogoScheduleEvent {
                                    img {
                                        height: 30px;
                                    }
                                }
                            }
                        }
                        .EnterEventAdvance {
                            // padding: 30px 28px 30px;
                            h2 {
                                font-size: 26px;
                                margin: 10px 0 25px;
                            }
                            .Vactor1 {
                                &::before {
                                    bottom: -47px;
                                    left: -67px;
                                    width: 160px;
                                    height: 200px;
                                }
                            }
                            .Vactor2 {
                                &::after {
                                    width: 145px;
                                    height: 145px;
                                }
                            }
                            .EventTypeAdvamce {
                                .form-group {
                                    .form-control {
                                        height: 48px;
                                    }
                                    .EnterBtn {
                                        padding: 12px 28px;
                                        height: 50px;
                                    }
                                    .EnterOtpValue {
                                        .OtpValue {
                                            input {
                                                margin: 0 4px;
                                                width: 40px!important;
                                                height: 42px!important;
                                                font-size: 20px;
                                            }    
                                        }
                                    }
                                }
                            }
                            .SocialLoginType {
                                ul {
                                    li {
                                        .img-fluid {
                                            width: 48px !important;
                                        }
                                    }
                                }
                            }
                        }
                        .SlightlyEarlyYet {
                            .SlightlyEvents {
                                h3 {
                                    font-size: 18px;
                                }
                                p {
                                    font-size: 18px;
                                }
                            }
                            .timerBodyCondown {
                                .event-live {
                                    h3 {
                                        font-size: 20px;
                                    }
                                }
                            }
                            .SlightlyTimeSocial {
                                .SocialLoginType {
                                    ul {
                                        li {
                                            .img-fluid {
                                                width: 48px;
                                            }
                                        }
                                    }
                                }
                            }
                            &::before {
                                bottom: -47px;
                                left: -106px;
                                width: 200px;
                                height: 200px;
                            }
                            &::after {
                                width: 135px;
                                height: 135px;
                            }
                        }
    
                        .SlightlyEarlyYet {
                            .SlightlyEvents {
                                padding: 30px;
                                h3 {
                                    font-size: 16px;
                                }
                                p {
                                    font-size: 16px;
                                }
                            }
                            .SlightlyTimeSocial {
                                padding: 24px 30px 40px;
                                .SocialLoginType {
                                    ul {
                                        li {
                                            margin: 0 4px;
                                        }
                                    }
                                }
                            }
                        }
    
                    }
                }
            }
        }
    }
    .TimerCoundTime {
        .timerBodyCondown {
            width: 278px;
            div {
                width: 60px !important;
                height: 60px !important;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-flow: column;
                svg {
                    display: none !important;
                }
                .time-wrapper {
                    .time {
                        font-size: 25px;
                        line-height: 30px;
                    }
                }
            }
        }
    }
}

  @media (min-width:1366px) and (max-width:1439px) {
    .RegisterPagePro h2.eventHeader { font-size: 22px; }
    .RegisterPagePro {
        .staticPositon {
            .container {
                max-width: calc(100vw - 26%) !important;
                .row {
                    .LoginRegisterPage {
                        .AdvanceEventDelReg {
                            padding: 72px 22px 22px;
                            h3 {
                                font-size: 18px;
                                font-weight: 500;
                                line-height: 26px;
                                margin: 0px 0 12px;
                            }
                            .DetailFixEventMx {
                                .FullDeltailInfo {
                                    padding: 10px 0px 10px;
                                }
                                span {
                                    padding: 4px 14px;
                                    font-size: 11px;
                                }
                                .TimeScheduleCal {
                                    .TimeCalenderMix {
                                        svg {
                                            margin-right: 5px;
                                            width: 15px;
                                            height: 15px;
                                        }
                                        p {
                                            font-size: 12px;
                                            margin-right: 12px;
                                        }                       
                                    }
                                }
                                .LogoScheduleEvent {
                                    img {
                                        height: 30px;
                                    }
                                }
                            }
                        }
                        .EnterEventAdvance {
                            // padding: 40px 28px 30px;
                            h2 {
                                font-size: 24px;
                                margin: 0px 0 20px;
                            }
                            .Vactor1 {
                                &::before {
                                    bottom: -46px;
                                    left: -106px;
                                    width: 185px;
                                    height: 185px
                                }
                            }
                            .Vactor2 {
                                &::after {
                                    width: 145px;
                                    height: 145px;
                                }
                            }
                            .EventTypeAdvamce {
                                .form-group {
                                        margin-bottom: 1.3em;
                                    .form-control {
                                        height: 48px;
                                    }
                                    .EnterBtn {
                                        padding: 12px 28px;
                                        height: 50px;
                                    }
                                    .EnterOtpValue {
                                        .OtpValue {
                                            input {
                                                margin: 0 4px;
                                                width: 38px !important;
                                                height: 38px !important;
                                                font-size: 18px;
                                            }    
                                        }
                                    }
                                }
                            }
                            .SocialLoginType {
                                
                                ul {
                                    li {
                                        .img-fluid {
                                            width: 48px !important;
                                        }
                                    }
                                }
                            }
                        }
                        .SlightlyEarlyYet {
                            .SlightlyEvents {
                                h3 {
                                    font-size: 18px;
                                }
                                p {
                                    font-size: 18px;
                                }
                            }
                            .timerBodyCondown {
                                .event-live {
                                    h3 {
                                        font-size: 20px;
                                    }
                                }
                            }
                            .SlightlyTimeSocial {
                                .SocialLoginType {
                                    ul {
                                        li {
                                            .img-fluid {
                                                width: 48px;
                                            }
                                        }
                                    }
                                }
                            }
                            &::before {
                                bottom: -46px;
                                left: -106px;
                                width: 185px;
                                height: 185px
                            }
                            &::after {
                                width: 130px;
                                height: 130px;
                            }
                        }
    
                        .SlightlyEarlyYet {
                            .SlightlyEvents {
                                padding: 30px 22px;
                                h3 {
                                    font-size: 16px;
                                }
                                p {
                                    font-size: 16px;
                                }
                            }
                            .SlightlyTimeSocial {
                                padding: 24px 30px 40px;
                                .SocialLoginType {
                                    ul {
                                        li {
                                            margin: 0 4px;
                                        }
                                    }
                                }
                            }
                        }
    
                    }
                }
            }
        }
        
    }
    
    .RegisterPagePro .staticPositon .container .row .LoginRegisterPage .SlightlyEarlyYet .EventTypeAdvamce .EnterEventAction {
        padding: 12px 26px !important;
    }
    .RegisterPagePro .staticPositon .container .row .LoginRegisterPage .SlightlyEarlyYet .EventTypeAdvamce .EnterEventAction .EventTypeActonIn .form-group .form-control {
        height: 46px;
    }
    .EventTypeActonIn .form-group .EnterBtn {
        //padding: 8px 28px;
        font-size: 16px;
    }
    .TimerCoundTime {
        .timerBodyCondown {
            width: 278px;
            div {
                width: 60px !important;
                height: 60px !important;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-flow: column;
                svg {
                    display: none !important;
                }
                .time-wrapper {
                    .time {
                        font-size: 25px;
                        line-height: 30px;
                    }
                }
            }
        }
    }
  }

  @media (min-width:1280px) and (max-width:1365px) {
    .RegisterPagePro .RegistrationTabsHome .InfoStageOrder .AdvanceTab .InfoHeadingTop h4{font-size: 18px;}
    .RegisterPagePro .staticPositon .container .row .LoginRegisterPage .AdvanceEventDelReg .DetailFixEventMx .FullDeltailInfo .EventNfollowUs{gap: 15px;}
    .RegisterPagePro h2.eventHeader { font-size: 22px; }
    .RegisterPagePro {
        .staticPositon {
            .container {
                max-width: calc(100vw - 22%) !important;
                .row {
                    .LoginRegisterPage {
                        .AdvanceEventDelReg {
                            padding: 72px 22px 22px;
                            h3 {
                                font-size: 18px;
                                font-weight: 500;
                                line-height: 26px;
                                margin: 0px 0 14px;
                            }
                            .DetailFixEventMx {
                                .FullDeltailInfo {
                                    padding: 20px 15px 8px;
                                }
                                span {
                                    padding: 4px 14px;
                                    font-size: 11px;
                                }
                                .TimeScheduleCal {
                                    .TimeCalenderMix {
                                        svg {
                                            margin-right: 6px;
                                            width: 16px;
                                            height: 16px;
                                        }
                                        p {
                                            font-size: 12px;
                                            margin-right: 11px;
                                        }                       
                                    }
                                }
                                .LogoScheduleEvent {
                                    img {
                                        height: 30px;
                                    }
                                }
                                .registration-timezone {
                                    div {
                                        font-size: 13px;
                                    }
                                }
                            }
                        }
                        .EnterEventAdvance {
                            // padding: 24px 28px 30px;
                            h2 {
                                font-size: 26px;
                                margin: 20px 0 32px;
                            }
                            .Vactor1 {
                                &::before {
                                    bottom: -46px;
                                    left: -106px;
                                    width: 185px;
                                    height: 185px
                                }
                            }
                            .Vactor2 {
                                &::after {
                                    width: 145px;
                                    height: 145px;
                                }
                            }
                            .EventTypeAdvamce {
                                .form-group {
                                    .form-control {
                                        height: 48px;
                                    }
                                    .EnterBtn {
                                        padding: 12px 28px;
                                        height: 50px;
                                    }
                                    .EnterOtpValue {
                                        .OtpValue {
                                            input {
                                                margin: 0 4px;
                                                width: 38px !important;
                                                height: 38px !important;
                                                font-size: 18px;
                                            }    
                                        }
                                    }
                                }
                            }
                            .SocialLoginType {
                                
                                ul {
                                    li {
                                        .img-fluid {
                                            width: 48px !important;
                                        }
                                    }
                                }
                            }
                        }
                        .SlightlyEarlyYet {
                            .SlightlyEvents {
                                h3 {
                                    font-size: 18px;
                                }
                                p {
                                    font-size: 18px;
                                }
                            }
                            .timerBodyCondown {
                                .event-live {
                                    h3 {
                                        font-size: 20px;
                                    }
                                }
                            }
                            .SlightlyTimeSocial {
                                .SocialLoginType {
                                    ul {
                                        li {
                                            .img-fluid {
                                                width: 48px;
                                            }
                                        }
                                    }
                                }
                            }
                            &::before {
                                bottom: -46px;
                                left: -106px;
                                width: 185px;
                                height: 185px
                            }
                            &::after {
                                width: 130px;
                                height: 130px;
                            }
                        }
    
                        .SlightlyEarlyYet {
                            .SlightlyEvents {
                                padding: 30px 22px;
                                h3 {
                                    font-size: 16px;
                                }
                                p {
                                    font-size: 16px;
                                }
                            }
                            .SlightlyTimeSocial {
                                padding: 24px 30px 40px;
                                .SocialLoginType {
                                    ul {
                                        li {
                                            margin: 0 4px;
                                        }
                                    }
                                }
                            }
                        }
    
                    }
                }
            }
        }
        
    }
    .TimerCoundTime {
        .timerBodyCondown {
            width: 278px;
            div {
                width: 60px !important;
                height: 60px !important;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-flow: column;
                svg {
                    display: none !important;
                }
                .time-wrapper {
                    .time {
                        font-size: 25px;
                        line-height: 30px;
                    }
                }
            }
        }
    }
    .ArchivedStageRecord.audience-front-stage-roll .MainViewBackStage {
        height: calc(100vh - 68px) !important;
    }
  }
  @media (min-width:1200px) and (max-width:1279px) {
    .RegisterPagePro .RegistrationTabsHome .InfoStageOrder .AdvanceTab .InfoHeadingTop h4{font-size: 18px;}
    .RegisterPagePro h2.eventHeader { font-size: 22px; }
    .RegisterPagePro {
        .staticPositon {
            .container {
                max-width: calc(100vw - 10%) !important;
                .row {
                    .LoginRegisterPage {
                        .AdvanceEventDelReg {
                            padding: 72px 22px 22px;
                            h3 {
                                font-size: 18px;
                                font-weight: 500;
                                line-height: 26px;
                            }
                            .DetailFixEventMx {
                                span {
                                    padding: 4px 14px;
                                    font-size: 11px;
                                }
                                .TimeScheduleCal {
                                    .TimeCalenderMix {
                                        svg {
                                            margin-right: 8px;
                                            width: 16px;
                                            height: 16px;
                                        }
                                        p {
                                            font-size: 13px;
                                            margin-right: 14px;
                                        }                       
                                    }
                                }
                                .LogoScheduleEvent {
                                    img {
                                        height: 30px;
                                    }
                                }
                            }
                        }
                        .EnterEventAdvance {
                            // padding: 24px 28px 30px;
                            h2 {
                                font-size: 24px;
                                margin: 20px 0 38px;
                            }
                            .Vactor1 {
                                &::before {
                                    bottom: -46px;
                                    left: -106px;
                                    width: 185px;
                                    height: 185px
                                }
                            }
                            .Vactor2 {
                                &::after {
                                    width: 145px;
                                    height: 145px;
                                }
                            }
                            .EventTypeAdvamce {
                                .form-group {
                                    .form-control {
                                        height: 48px;
                                    }
                                    .EnterBtn {
                                        padding: 12px 28px;
                                        height: 50px;
                                    }
                                    .EnterOtpValue {
                                        .OtpValue {
                                            input {
                                                margin: 0 4px;
                                                width: 38px !important;
                                                height: 38px !important;
                                                font-size: 18px;
                                            }    
                                        }
                                    }
                                }
                            }
                            .SocialLoginType {
                                
                                ul {
                                    li {
                                        .img-fluid {
                                            width: 48px !important;
                                        }
                                    }
                                }
                            }
                        }
                        .SlightlyEarlyYet {
                            .SlightlyEvents {
                                h3 {
                                    font-size: 18px;
                                }
                                p {
                                    font-size: 18px;
                                }
                            }
                            .timerBodyCondown {
                                .event-live {
                                    h3 {
                                        font-size: 20px;
                                    }
                                }
                            }
                            .SlightlyTimeSocial {
                                .SocialLoginType {
                                    ul {
                                        li {
                                            .img-fluid {
                                                width: 48px;
                                            }
                                        }
                                    }
                                }
                            }
                            &::before {
                                bottom: -46px;
                                left: -106px;
                                width: 185px;
                                height: 185px
                            }
                            &::after {
                                width: 130px;
                                height: 130px;
                            }
                        }
    
                        .SlightlyEarlyYet {
                            .SlightlyEvents {
                                padding: 30px 22px;
                                h3 {
                                    font-size: 16px;
                                }
                                p {
                                    font-size: 16px;
                                }
                            }
                            .SlightlyTimeSocial {
                                padding: 24px 30px 40px;
                                .SocialLoginType {
                                    ul {
                                        li {
                                            margin: 0 4px;
                                        }
                                    }
                                }
                            }
                        }
    
                    }
                }
            }
        }
        
    }
    .TimerCoundTime {
        .timerBodyCondown {
            width: 278px;
            div {
                width: 60px !important;
                height: 60px !important;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-flow: column;
                svg {
                    display: none !important;
                }
                .time-wrapper {
                    .time {
                        font-size: 25px;
                        line-height: 30px;
                    }
                }
            }
        }
    }
    .ArchivedStageRecord.audience-front-stage-roll .MainViewBackStage {
        height: calc(100vh - 68px) !important;
    }
  }
  @media (min-width:992px) and (max-width:1023px) {
    .RegisterPagePro .RegistrationTabsHome .InfoStageOrder .AdvanceTab .InfoHeadingTop h4{font-size: 18px;}
    .RegisterPagePro h2.eventHeader { font-size: 22px; }
    .RegisterPagePro {
        .staticPositon {
            .container {
                max-width: calc(100vw - 0%) !important;
                .row {
                    .LoginRegisterPage {
                        .AdvanceEventDelReg {
                            padding: 70px 22px 22px;
                            h3 {
                                font-size: 18px;
                                font-weight: 500;
                                line-height: 26px;
                            }
                            .DetailFixEventMx {
                                span {
                                    padding: 4px 14px;
                                    font-size: 11px;
                                }
                                .TimeScheduleCal {
                                    .TimeCalenderMix {
                                        svg {
                                            margin-right: 8px;
                                            width: 16px;
                                            height: 16px;
                                        }
                                        p {
                                            font-size: 12px;
                                            margin-right: 14px;
                                        }                       
                                    }
                                }
                                .LogoScheduleEvent {
                                    img {
                                        height: 30px;
                                    }
                                }
                            }
                        }
                        .EnterEventAdvance {
                            padding: 24px 30px 30px;
                            h2 {
                                font-size: 18px;
                                margin: 20px 0 30px;
                            }
                            .Vactor1 {
                                &::before {
                                    bottom: -46px;
                                    left: -106px;
                                    width: 185px;
                                    height: 185px
                                }
                            }
                            .Vactor2 {
                                &::after {
                                    width: 145px;
                                    height: 145px;
                                }
                            }
                            .EventTypeAdvamce {
                                .form-group {
                                    .form-control {
                                        height: 48px;
                                    }
                                    .EnterBtn {
                                        padding: 12px 28px;
                                        height: 50px;
                                    }
                                    .EnterOtpValue {
                                        .OtpValue {
                                            input {
                                                margin: 0 4px;
                                                width: 38px !important;
                                                height: 38px !important;
                                                font-size: 18px;
                                            }    
                                        }
                                    }
                                }
                            }
                            .SocialLoginType {
                                
                                ul {
                                    li {
                                        .img-fluid {
                                            width: 48px !important;
                                        }
                                    }
                                }
                            }
                        }
                        .SlightlyEarlyYet {
                            .SlightlyEvents {
                                h3 {
                                    font-size: 18px;
                                }
                                p {
                                    font-size: 18px;
                                }
                            }
                            .timerBodyCondown {
                                .event-live {
                                    h3 {
                                        font-size: 20px;
                                    }
                                }
                            }
                            .SlightlyTimeSocial {
                                .SocialLoginType {
                                    ul {
                                        li {
                                            .img-fluid {
                                                width: 48px;
                                            }
                                        }
                                    }
                                }
                            }
                            &::before {
                                bottom: -46px;
                                left: -106px;
                                width: 185px;
                                height: 185px
                            }
                            &::after {
                                width: 130px;
                                height: 130px;
                            }
                        }
    
                        .SlightlyEarlyYet {
                            .SlightlyEvents {
                                padding: 30px 22px;
                                h3 {
                                    font-size: 16px;
                                }
                                p {
                                    font-size: 16px;
                                }
                            }
                            .SlightlyTimeSocial {
                                padding: 24px 30px 40px;
                                .SocialLoginType {
                                    ul {
                                        li {
                                            margin: 0 4px;
                                        }
                                    }
                                }
                            }
                        }
    
                    }
                }
            }
        }
        
    }
    .RegistrationTabsHome {
        .InfoStageOrder {
            .ScheduleMixTab {
                .scheduleBlock {
                    .AllPlanSchedule {
                        .ScheduleCalendarInfos {
                            .StageFullInfoFix {
                                .CalenderDateScheduleInof {
                                    width: 135px;
                                }
                                .ScheduleInfoWithConts {
                                    width: calc(100% - 136px);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .TimerCoundTime {
        .timerBodyCondown {
            width: 278px;
            div {
                width: 60px !important;
                height: 60px !important;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-flow: column;
                svg {
                    display: none !important;
                }
                .time-wrapper {
                    .time {
                        font-size: 25px;
                        line-height: 30px;
                    }
                }
            }
        }
    }
  }
  @media (min-width:1024px) and (max-width:1199px) {
    .RegisterPagePro .RegistrationTabsHome .InfoStageOrder .AdvanceTab .InfoHeadingTop h4{font-size: 18px;}
    .RegisterPagePro h2.eventHeader { font-size: 22px; }
    .RegisterPagePro {
        .staticPositon {
            .container {
                max-width: calc(100vw - 0%) !important;
                .row {
                    .LoginRegisterPage {
                        .AdvanceEventDelReg {
                            padding: 64px 22px 22px;
                            h3 {
                                font-size: 18px;
                                font-weight: 500;
                                line-height: 26px;
                            }
                            .DetailFixEventMx {
                                span {
                                    padding: 4px 14px;
                                    font-size: 11px;
                                }
                                .TimeScheduleCal {
                                    .TimeCalenderMix {
                                        svg {
                                            margin-right: 8px;
                                            width: 16px;
                                            height: 16px;
                                        }
                                        p {
                                            font-size: 12px;
                                            margin-right: 14px;
                                        }                       
                                    }
                                }
                                .LogoScheduleEvent {
                                    img {
                                        height: 30px;
                                    }
                                }
                                .registration-timezone {
                                    div {
                                        font-size: 13px;
                                    }
                                }
                            }
                        }
                        .EnterEventAdvance {
                            // padding: 24px 38px 30px;
                            h2 {
                                font-size: 24px;
                                margin: 20px 0 38px;
                            }
                            .Vactor1 {
                                &::before {
                                    bottom: -46px;
                                    left: -106px;
                                    width: 185px;
                                    height: 185px
                                }
                            }
                            .Vactor2 {
                                &::after {
                                    width: 145px;
                                    height: 145px;
                                }
                            }
                            .EventTypeAdvamce {
                                .form-group {
                                    .form-control {
                                        height: 48px;
                                    }
                                    .EnterBtn {
                                        padding: 12px 28px;
                                        height: 50px;
                                    }
                                    .EnterOtpValue {
                                        .OtpValue {
                                            input {
                                                margin: 0 4px;
                                                width: 38px !important;
                                                height: 38px !important;
                                                font-size: 18px;
                                            }    
                                        }
                                    }
                                }
                            }
                            .SocialLoginType {
                                
                                ul {
                                    li {
                                        .img-fluid {
                                            width: 48px !important;
                                        }
                                    }
                                }
                            }
                        }
                        .SlightlyEarlyYet {
                            .SlightlyEvents {
                                h3 {
                                    font-size: 18px;
                                }
                                p {
                                    font-size: 18px;
                                }
                            }
                            .timerBodyCondown {
                                .event-live {
                                    h3 {
                                        font-size: 20px;
                                    }
                                }
                            }
                            .SlightlyTimeSocial {
                                .SocialLoginType {
                                    ul {
                                        li {
                                            .img-fluid {
                                                width: 48px;
                                            }
                                        }
                                    }
                                }
                            }
                            &::before {
                                bottom: -46px;
                                left: -106px;
                                width: 185px;
                                height: 185px
                            }
                            &::after {
                                width: 130px;
                                height: 130px;
                            }
                        }
    
                        .SlightlyEarlyYet {
                            .SlightlyEvents {
                                padding: 30px 22px;
                                h3 {
                                    font-size: 16px;
                                }
                                p {
                                    font-size: 16px;
                                }
                            }
                            .SlightlyTimeSocial {
                                padding: 24px 30px 40px;
                                .SocialLoginType {
                                    ul {
                                        li {
                                            margin: 0 4px;
                                        }
                                    }
                                }
                            }
                        }
    
                    }
                }
            }
        }
    }
    .RegistrationTabsHome {
        .InfoStageOrder {
            .ScheduleMixTab {
                .scheduleBlock {
                    .AllPlanSchedule {
                        .ScheduleCalendarInfos {
                            .StageFullInfoFix {
                                .CalenderDateScheduleInof {
                                    width: 155px;
                                }
                                .ScheduleInfoWithConts {
                                    width: calc(100% - 152px);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .TimerCoundTime {
        .timerBodyCondown {
            width: 278px;
            div {
                width: 60px !important;
                height: 60px !important;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-flow: column;
                svg {
                    display: none !important;
                }
                .time-wrapper {
                    .time {
                        font-size: 25px;
                        line-height: 30px;
                    }
                }
            }
        }
    }
  }
  @media (min-width:768px) and (max-width:991px) {
    .RegisterPagePro .RegistrationTabsHome .InfoStageOrder .AdvanceTab .InfoHeadingTop h4{font-size: 18px;}
    .RegisterPagePro h2.eventHeader { font-size: 22px; }
    .RegisterPagePro {
        .staticPositon {
            .container {
                max-width: calc(100vw - 0%) !important;
                .row {
                    .LeftImgBlock {
                        width: calc(100% - 39.6%);
                        float: left;
                    }
                    .LoginRegisterPage {
                        width: 38%;
                        min-width: 38%;
                    }
                    .LoginRegisterPage {
                        .AdvanceEventDelReg {
                            padding: 64px 18px 16px;
                            h3 {
                                font-size: 18px;
                                font-weight: 500;
                                line-height: 26px;
                            }
                            .DetailFixEventMx {
                                span {
                                    padding: 4px 14px;
                                    font-size: 11px;
                                }
                                .TimeScheduleCal {
                                    .TimeCalenderMix {
                                        svg {
                                            margin-right: 6px;
                                            width: 15px;
                                            height: 15px;
                                        }
                                        p {
                                            font-size: 11px;
                                            margin-right: 16px;
                                        }                       
                                    }
                                }
                                .LogoScheduleEvent {
                                    img {
                                        height: 30px;
                                    }
                                }
                            }
                        }
                        .EnterEventAdvance {
                            // padding: 24px 28px 30px;
                            h2 {
                                font-size: 24px;
                                margin: 0px 0 20px;
                            }
                            .Vactor1 {
                                &::before {
                                    bottom: -46px;
                                    left: -106px;
                                    width: 185px;
                                    height: 185px
                                }
                            }
                            .Vactor2 {
                                &::after {
                                    width: 145px;
                                    height: 145px;
                                }
                            }
                            .EventTypeAdvamce {
                                .form-group {
                                    .form-control {
                                        height: 48px;
                                    }
                                    .EnterBtn {
                                        padding: 12px 28px;
                                        height: 50px;
                                    }
                                    .EnterOtpValue {
                                        .OtpValue {
                                            input {
                                                margin: 0 4px;
                                                width: 38px !important;
                                                height: 38px !important;
                                                font-size: 18px;
                                            }    
                                        }
                                    }
                                }
                            }
                            .SocialLoginType {
                                
                                ul {
                                    li {
                                        .img-fluid {
                                            width: 48px !important;
                                        }
                                    }
                                }
                            }
                        }
                        .SlightlyEarlyYet {
                            .SlightlyEvents {
                                h3 {
                                    font-size: 18px;
                                }
                                p {
                                    font-size: 18px;
                                }
                            }
                            .timerBodyCondown {
                                .event-live {
                                    h3 {
                                        font-size: 20px;
                                    }
                                }
                            }
                            .SlightlyTimeSocial {
                                .SocialLoginType {
                                    ul {
                                        li {
                                            .img-fluid {
                                                width: 48px;
                                            }
                                        }
                                    }
                                }
                            }
                            &::before {
                                bottom: -46px;
                                left: -106px;
                                width: 185px;
                                height: 185px
                            }
                            &::after {
                                width: 130px;
                                height: 130px;
                            }
                        }
    
                        .SlightlyEarlyYet {
                            .SlightlyEvents {
                                padding: 30px 22px;
                                h3 {
                                    font-size: 16px;
                                }
                                p {
                                    font-size: 16px;
                                }
                            }
                            .SlightlyTimeSocial {
                                padding: 24px 15px 40px;
                                .SocialLoginType {
                                    ul {
                                        li {
                                            margin: 0 4px;
                                        }
                                    }
                                }
                                .EventCalSchedules {
                                    .EventCalenderIcon {
                                        img {
                                            width: 45px;
                                            height: 45px;
                                            margin: 0 4px;
                                        }
                                    }
                                }
                            }
                        }
    
                    }
                }
            }
        }
    }
    
    .RegistrationTabsHome {
        .responsive-tabs-container {
            .nav-tabs {
                padding: 8px 10px;
                ul {
                    li {
                        a {
                            padding: 4px 15px;
                            font-size: 14px;
                        }
                    }
                }
                .nav {
                    .nav-link {
                        padding: 4px 10px;
                        font-weight: 400;
                        font-size: 14px;
                        &.active {
                            padding: 4px 10px;
                        }
                    }
                }
            }
        }
    }
    .TimerCoundTime .timerBodyCondown span {
        font-size: 45px;
    }
    .RegistrationTabsHome {
        .InfoStageOrder {
            .ScheduleMixTab {
                .scheduleBlock {
                    .SetEventSchedule {
                        .SelectDayStage {
                            width: 100% !important;
                            position: inherit !important;
                            .DayScheduleStages {
                                margin: 0 7px !important;
                                .SelectDropdown {
                                    .SeleftDDl {
                                        .dropdown {
                                            .dropdown-toggle {
                                                font-size: 14px !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .AllPlanSchedule {
                        .ScheduleCalendarInfos {
                            .StageFullInfoFix {
                                margin-bottom: 20px;
                                display: block;
                                height: auto;
                                .CalenderDateScheduleInof {
                                    display: flex;
                                    cursor: cell;
                                    justify-content: space-between;
                                    padding: 12px 18px 12px;
                                    border-radius: 16px 16px 0 0;
                                    margin-right: 0px;
                                    width: 100%;
                                    height: auto;
                                    align-items: center;
                                    flex-flow: inherit;
                                    .StageNo {
                                        padding: 2px 8px;
                                        top: auto;
                                        left: 0;
                                        right: 0;
                                        width: 130px;
                                        margin: 0px auto;
                                        bottom: -10px;
                                        font-size: 12px;
                                    }
                                    .DtStageEventDay {
                                        h4 {
                                            line-height: 24px;
                                            font-size: 15px;
                                            strong {
                                                font-weight: 600;
                                                display: inline-block;
                                            }
                                        }
                                    }
                                    .ScheduleTMFx {
                                        margin: 5px 0 5px !important;
                                        p {
                                            font-size: 16px;
                                            font-weight: 600;
                                        }
                                    }
                                    .ScheduleStatusOngoing {
                                        .EventStyle {
                                            font-weight: 500;
                                            padding: 4px 12px;
                                            font-size: 13px;
                                        }
                                    }
                                }
                                .ScheduleInfoWithConts {
                                    width: 100%;
                                    padding: 16px 16px 40px 16px;
                                    h3 {
                                        margin: 0px 0 5px;
                                        font-size: 18px;
                                    }
                                    p {
                                        font-size: 12px;
                                    }
                                    .SpeakerRowList {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
        }
    }
    }
    .RegistrationTabsHome {
        .InfoStageOrder {
            .tab-pane {
                .AdvanceTab {
                    .InfoHeadingTop {
                        h4 {
                            font-size: 18px;
                            svg {
                                width: 23px;
                                height: 23px;
                            }
                        }
                    }
                }
            }
        }
    }
    .RegisterPagePro .staticPositon .container .row .LeftImgBlock .LeftBlurSide .LoginBigImg .LeftRegTop .LoginEventLogo{
        top: 10px;
        left: 10px;
        width: 120px;
    }
    .TimerCoundTime {
        .timerBodyCondown {
            width: 255px;
            div {
                width: 55px !important;
                height: 55px !important;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-flow: column;
                svg {
                    display: none !important;
                }
                .time-wrapper {
                    .time {
                        font-size: 25px;
                        line-height: 30px;
                    }
                }
            }
        }
    }
    .RegistrationTabsHome .InfoStageOrder .tab-pane .AdvanceTab .sponsorBlock ul li {
        width: 28%;
    }
    .RegistrationTabsHome .InfoStageOrder .ScheduleMixTab .scheduleBlock .AllPlanSchedule .ScheduleCalendarInfos .SpeakerRowList .slick-slider .slick-arrow {
        bottom: 0px !important;
    }
}
@media (max-width: 768px) {
    .RegisterPagePro .staticPositon .container .row .LoginRegisterPage .EnterEventAdvance{padding:0px 0 25px;}
    .RegisterPagePro .staticPositon .container .row .LoginRegisterPage .EnterEventAdvance .EventTypeAdvamce .form-group .OrTxt{margin-top: 0;}
}
@media (max-width: 767px) {
    .RegisterPagePro .staticPositon .container .row .LoginRegisterPage .SlightlyEarlyYet{margin:5px 0 5px;}
    .RegisterPagePro .RegistrationTabsHome .InfoStageOrder .AdvanceTab .InfoHeadingTop h4{font-size: 18px;}
    .RegisterPagePro h2.eventHeader { font-size: 22px; }
    .RegisterPagePro .RegistrationTabsHome .InfoStageOrder .ScheduleMixTab .scheduleBlock .AllPlanSchedule .ScheduleCalendarInfos .StageFullInfoFix .CalenderDateScheduleInof .SchTimeDateFix.notranslate{width:100%;}
    .RegistrationTabsHome {
        .InfoStageOrder {
            .ScheduleMixTab {
                .scheduleBlock {
                    .AllPlanSchedule {
                        .ScheduleCalendarInfos {
                            .StageFullInfoFix {
                                height: auto;
                                .CalenderDateScheduleInof {
                                    display: flex;
                                    justify-content: space-between;
                                    height: auto;
                                    width: 100%;
                                    flex-flow: inherit;
                                    margin-top: 0px !important;
                                }
                                .ScheduleInfoWithConts {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
        }
    }
    }
    // .RegistrationTabsHome .InfoStageOrder .ScheduleMixTab .scheduleBlock .AllPlanSchedule {
    //     height: 435px !important;
    // }
    .PepleSerchHere .SerchIcon {
        top: 8px;
    }
    .InfoStageOrder {
        .tab-pane {
            .AdvanceTab {
                .scheduleBlock {
                    .SetEventSchedule {
                        .SelectDayStage {
                            .SelectDays {
                                margin-left: 0px !important;
                            }
                            .SelectAllStages {
                                margin-right: 0px !important;
                            }
                        }
                    }
                }
            }
        }
    }
    .RegistrationTabsHome .InfoStageOrder .ScheduleMixTab .scheduleBlock .AllPlanSchedule .ScheduleCalendarInfos .StageFullInfoFix .ScheduleInfoWithConts .SpeakerRowList {
        height: auto !important;
    }
    .enter-the-event-div {
        display: block !important;
    }
    .RegisterPagePro .staticPositon .container .row .LoginRegisterPage .AdvanceEventDelReg .DetailFixEventMx .registration-timezone div {
        font-size: 14px;
    }
    // .RegisterPagePro .staticPositon .container .row .LeftImgBlock .LeftBlurSide {
    //     display: none;
    // }
    .RegistrationTabsHome {
        margin-top: 25px !important;
        // .responsive-tabs-container {
        //     position: sticky;
        //     top: 0;
        //     z-index: 9999;
        // }
    }
    .RegisterPagePro {
        .staticPositon {
            .container {
                .row {
                    .MobileTopView {
                        // display: block;
                        margin-bottom: 0px !important;
                        .AdvanceEventDelReg {
                            margin-bottom: 0px !important;
                        }
                    }
                    .LoginRegisterPage {
                        .DeskTopView {
                            // display: none;
                        }
                    }
                    
                }
            }
        }
    }
    .RegistrationTabsHome .InfoStageOrder .ScheduleMixTab .scheduleBlock .AllPlanSchedule {
        padding: 15px 0px 0 0 !important;
    }
    .sponsorBlock {
        margin: 0 0 12px;
    }
    #AdvanceForm {margin: 0 0 0px;}
    footer {
        background-color: transparent;
        margin-bottom: 68px;
        ul {
            display: block;
            li {
                font-size: 14px;
                a {
                    height: 14px !important;
                }
            }
        }
    }
    .SlightlyEarlyYet {margin: 0 0 65px;}
    .RegisterPagePro {
        .staticPositon {
            .container {
                .row {
                    .RegisterWrapper {
                        display: block !important;
                        .LoginRegisterPage {
                            position: initial !important;
                            top: 0px !important;
                            height: auto !important;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width:576px) and (max-width:767px) {
    .RegisterPagePro {
        .staticPositon {
            .container {
                max-width: calc(100vw - 0%) !important;
                .row {
                    .LeftImgBlock {
                        width: 100%;
                        float: left;
                    }
                    .LoginRegisterPage {
                        width: 100%;
                        float: left;
                    }
                    .LoginRegisterPage {
                        .AdvanceEventDelReg {
                            padding: 64px 22px 22px;
                            h3 {
                                font-size: 18px;
                                font-weight: 500;
                                line-height: 26px;
                            }
                            .DetailFixEventMx {
                                span {
                                    padding: 4px 14px;
                                    font-size: 11px;
                                }
                                .TimeScheduleCal {
                                    .TimeCalenderMix {
                                        svg {
                                            margin-right: 8px;
                                            width: 16px;
                                            height: 16px;
                                        }
                                        p {
                                            font-size: 13px;
                                            margin-right: 14px;
                                        }                       
                                    }
                                }
                                .LogoScheduleEvent {
                                    img {
                                        height: 30px;
                                        width: auto !important;
                                    }
                                }
                            }
                        }
                        .EnterEventAdvance {
                            // padding: 24px 28px 30px;
                            h2 {
                                font-size: 24px;
                                margin: 20px 0 38px;
                            }
                            .Vactor1 {
                                &::before {
                                    bottom: -46px;
                                    left: -106px;
                                    width: 185px;
                                    height: 185px
                                }
                            }
                            .Vactor2 {
                                &::after {
                                    width: 145px;
                                    height: 145px;
                                }
                            }
                            .EventTypeAdvamce {
                                .form-group {
                                    .form-control {
                                        height: 48px;
                                    }
                                    .EnterBtn {
                                        padding: 12px 28px;
                                        height: 50px;
                                    }
                                    .EnterOtpValue {
                                        .OtpValue {
                                            input {
                                                margin: 0 4px;
                                                width: 38px !important;
                                                height: 38px !important;
                                                font-size: 18px;
                                            }    
                                        }
                                    }
                                }
                            }
                            .SocialLoginType {
                                
                                ul {
                                    li {
                                        .img-fluid {
                                            width: 48px !important;
                                        }
                                    }
                                }
                            }
                        }
                        .SlightlyEarlyYet {
                            .SlightlyEvents {
                                h3 {
                                    font-size: 18px;
                                }
                                p {
                                    font-size: 18px;
                                }
                            }
                            .timerBodyCondown {
                                .event-live {
                                    h3 {
                                        font-size: 20px;
                                    }
                                }
                            }
                            .SlightlyTimeSocial {
                                .SocialLoginType {
                                    ul {
                                        li {
                                            .img-fluid {
                                                width: 48px;
                                            }
                                        }
                                    }
                                }
                            }
                            &::before {
                                bottom: -46px;
                                left: -106px;
                                width: 185px;
                                height: 185px
                            }
                            &::after {
                                width: 130px;
                                height: 130px;
                            }
                        }
    
                        .SlightlyEarlyYet {
                            .SlightlyEvents {
                                padding: 30px 22px;
                                h3 {
                                    font-size: 16px;
                                }
                                p {
                                    font-size: 16px;
                                }
                            }
                            .SlightlyTimeSocial {
                                padding: 24px 30px 40px;
                                .SocialLoginType {
                                    ul {
                                        li {
                                            margin: 0 4px;
                                        }
                                    }
                                }
                            }
                        }
    
                    }
                }
            }
        }
    }
    
    .RegistrationTabsHome {
        .responsive-tabs-container {
            .nav-tabs {
                padding: 8px 10px;
                ul {
                    li {
                        a {
                            padding: 4px 15px;
                            font-size: 14px;
                        }
                    }
                }
                .nav {
                    .nav-link {
                        padding: 4px 10px;
                        font-weight: 400;
                        font-size: 14px;
                        &.active {
                            padding: 4px 10px;
                        }
                    }
                }
            }
        }
    }
    .TimerCoundTime .timerBodyCondown span {
        font-size: 45px;
    }
    .RegistrationTabsHome {
        .InfoStageOrder {
            .ScheduleMixTab {
                .scheduleBlock {
                    .SetEventSchedule {
                        .SelectDayStage {
                            //width: 330px !important;
                            .DayScheduleStages {
                                .SelectDropdown {
                                    .SeleftDDl {
                                        .dropdown {
                                            .dropdown-toggle {
                                                font-size: 14px !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .AllPlanSchedule {
                        padding-right: 0px !important;
                        //height: 350px !important;
                        .ScheduleCalendarInfos {
                            .StageFullInfoFix {
                                margin-bottom: 20px;
                                display: block;
                                height: auto;
                                border: 1px #8944FB solid;
                                border-radius: 8px;
                                padding: 12px;
                                .CalenderDateScheduleInof {
                                    display: flex;
                                    cursor: cell;
                                    justify-content: space-between;
                                    padding: 12px 18px 12px;
                                    border-radius: 15px 15px 0 0;
                                    height: auto;
                                    width: 100%;
                                    flex-flow: inherit;
                                    .StageNo {
                                        padding: 2px 8px;
                                        top: auto;
                                        left: 0;
                                        right: 0;
                                        width: 130px;
                                        margin: 0px auto;
                                        bottom: -10px;
                                        font-size: 12px;
                                    }
                                    .DtStageEventDay {
                                        h4 {
                                            line-height: 24px;
                                            font-size: 15px;
                                            strong {
                                                font-weight: 600;
                                                display: inline-block;
                                            }
                                        }
                                    }
                                    .ScheduleTMFx {
                                        margin: 5px 0 5px !important;
                                        p {
                                            font-size: 16px;
                                            font-weight: 600;
                                        }
                                    }
                                    .ScheduleStatusOngoing {
                                        .EventStyle {
                                            font-weight: 500;
                                            padding: 4px 12px;
                                            font-size: 13px;
                                        }
                                    }
                                }
                                .ScheduleInfoWithConts {
                                    width: 100%;
                                    padding: 0 0 34px 0 !important;
                                    h3 {
                                        margin: 12px 0 5px;
                                        font-size: 18px;
                                    }
                                    p {
                                        font-size: 12px;
                                    }
                                    .SpeakerRowList {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
        }
    }
    }
    .RegistrationTabsHome {
        .InfoStageOrder {
            .tab-pane {
                .AdvanceTab {
                    .InfoHeadingTop {
                        h4 {
                            font-size: 18px;
                            svg {
                                width: 23px;
                                height: 23px;
                            }
                        }
                    }
                }
            }
        }
    }
    .RegisterPagePro .staticPositon .container .row .LeftImgBlock .LeftBlurSide .LoginBigImg .LeftRegTop .LoginEventLogo {
        top: 10px;
        left: 10px;
        width: 120px;
    }
    .RegistrationTabsHome {
        .InfoStageOrder {
            .tab-pane {
                .AdvanceTab {
                    .scheduleBlock {
                        .SetEventSchedule {
                            .SelectDayStage {
                                width: 100%;
                                position: inherit !important;
                                .DayScheduleStages {
                                    .SelectDropdown{
                                        .dropdown {
                                            .dropdown-toggle {
                                                font-size: 15px !important;
                                                &::before {
                                                    width: 12px;
                                                    height: 12px;                                                
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .RegistrationTabsHome .InfoStageOrder .ScheduleMixTab .scheduleBlock .AllPlanSchedule .ScheduleCalendarInfos .SpeakerRowList .slick-slider .slick-arrow {
        bottom: -10px !important;
    }
}
  @media (min-width:480px) and (max-width:575px) {
    .RegisterPagePro {
        .staticPositon {
            .container {
                max-width: calc(100vw - 0%) !important;
                .row {
                    .LeftImgBlock {
                        width: 100%;
                        float: left;
                    }
                    .LoginRegisterPage {
                        width: 100%;
                        float: left;
                    }
                    .LoginRegisterPage {
                        .AdvanceEventDelReg {
                            padding: 64px 22px 22px;
                            h3 {
                                font-size: 18px;
                                font-weight: 500;
                                line-height: 26px;
                            }
                            .DetailFixEventMx {
                                span {
                                    padding: 4px 14px;
                                    font-size: 11px;
                                }
                                .TimeScheduleCal {
                                    .TimeCalenderMix {
                                        svg {
                                            margin-right: 8px;
                                            width: 16px;
                                            height: 16px;
                                        }
                                        p {
                                            font-size: 13px;
                                            margin-right: 14px;
                                        }                       
                                    }
                                }
                                .LogoScheduleEvent {
                                    img {
                                        height: 30px;
                                        width: auto !important;
                                    }
                                }
                            }
                        }
                        .EnterEventAdvance {
                            padding: 24px 28px 30px;
                            h2 {
                                font-size: 18px;
                                margin: 20px 0 38px;
                            }
                            .Vactor1 {
                                &::before {
                                    bottom: -46px;
                                    left: -106px;
                                    width: 185px;
                                    height: 185px
                                }
                            }
                            .Vactor2 {
                                &::after {
                                    width: 145px;
                                    height: 145px;
                                }
                            }
                            .EventTypeAdvamce {
                                .form-group {
                                    .form-control {
                                        height: 48px;
                                    }
                                    .EnterBtn {
                                        padding: 12px 28px;
                                        height: 50px;
                                    }
                                    .EnterOtpValue {
                                        .OtpValue {
                                            input {
                                                margin: 0 4px;
                                                width: 38px !important;
                                                height: 38px !important;
                                                font-size: 18px;
                                            }    
                                        }
                                    }
                                }
                            }
                            .SocialLoginType {
                                
                                ul {
                                    li {
                                        .img-fluid {
                                            width: 48px !important;
                                        }
                                    }
                                }
                            }
                        }
                        .SlightlyEarlyYet {
                            .SlightlyEvents {
                                h3 {
                                    font-size: 18px;
                                }
                                p {
                                    font-size: 18px;
                                }
                            }
                            .timerBodyCondown {
                                .event-live {
                                    h3 {
                                        font-size: 20px;
                                    }
                                }
                            }
                            .SlightlyTimeSocial {
                                .SocialLoginType {
                                    ul {
                                        li {
                                            .img-fluid {
                                                width: 48px;
                                            }
                                        }
                                    }
                                }
                            }
                            &::before {
                                bottom: -46px;
                                left: -106px;
                                width: 185px;
                                height: 185px
                            }
                            &::after {
                                width: 130px;
                                height: 130px;
                            }
                        }
    
                        .SlightlyEarlyYet {
                            .SlightlyEvents {
                                padding: 30px 22px;
                                h3 {
                                    font-size: 16px;
                                }
                                p {
                                    font-size: 16px;
                                }
                            }
                            .SlightlyTimeSocial {
                                padding: 24px 30px 40px;
                                .SocialLoginType {
                                    ul {
                                        li {
                                            margin: 0 4px;
                                        }
                                    }
                                }
                            }
                        }
    
                    }
                }
            }
        }
    }
    
    .RegistrationTabsHome {
        .responsive-tabs-container {
            .nav-tabs {
                padding: 8px 10px;
                ul {
                    li {
                        a {
                            padding: 4px 15px;
                            font-size: 14px;
                        }
                    }
                }
                .nav {
                    .nav-link {
                        padding: 5px 14px;
                        font-weight: 400;
                        font-size: 15px;
                        &.active {
                            padding: 5px 14px;
                        }
                    }
                }
            }
        }
    }
    .TimerCoundTime .timerBodyCondown span {
        font-size: 35px;
    }
    .RegistrationTabsHome {
        .InfoStageOrder {
            .tab-pane {
                .AdvanceTab {
                    .InfoHeadingTop {
                        p {
                            //font-size: 14px;
                        }
                        h4 {
                            font-size: 20px;
                            margin: 0 0 18px;
                            svg {
                                width: 24px;
                                height: 24px;
                            }
                        }
                    }
                }
            }
        }
    }
    

    .RegistrationTabsHome {
        .InfoStageOrder {
            .ScheduleMixTab {
                .scheduleBlock {
                    .SetEventSchedule {
                        .SelectDayStage {
                            width: 100% !important;
                            position: inherit !important;
                            margin: 8px 0 0;
                            .DayScheduleStages {
                                .SelectDropdown {
                                    .SeleftDDl {
                                        .dropdown {
                                            .dropdown-toggle {
                                                font-size: 14px !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .AllPlanSchedule {
                        .ScheduleCalendarInfos {
                            .StageFullInfoFix {
                                margin-bottom: 20px;
                                display: block;
                                height: auto;
                                border: 1px #8944FB solid;
                                border-radius: 8px;
                                padding: 12px;
                                .CalenderDateScheduleInof {
                                    display: flex;
                                    cursor: cell;
                                    justify-content: space-between;
                                    padding: 12px 12px 16px;
                                    border-radius: 15px 15px 0 0;
                                    .StageNo {
                                        padding: 2px 8px;
                                        top: auto;
                                        left: 0;
                                        right: 0;
                                        width: 130px;
                                        margin: 0px auto;
                                        bottom: -10px;
                                        font-size: 12px;
                                    }
                                    .DtStageEventDay {
                                        h4 {
                                            line-height: 24px;
                                            font-size: 15px;
                                            strong {
                                                font-weight: 600;
                                                display: inline-block !important;
                                            }
                                        }
                                    }
                                    .ScheduleTMFx {
                                        margin-bottom: 0px !important;
                                        p {
                                            font-size: 14px;
                                            font-weight: 500;
                                        }
                                    }
                                    .ScheduleStatusOngoing {
                                        margin: 5px 0 0 !important;
                                        .EventStyle {
                                            font-weight: 500;
                                            padding: 4px 12px;
                                            font-size: 12px;
                                        }
                                    }
                                }
                                .ScheduleInfoWithConts {
                                    padding: 0px 0px 32px 0px !important;
                                    h3 {
                                        margin: 12px 0 5px;
                                        font-size: 18px;
                                    }
                                    p {
                                        font-size: 12px;
                                    }
                                    .SpeakerRowList {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
        }
        .tab-pane {
            .AdvanceTab {
                .InfoHeadingTop {
                    h4 {
                        font-size: 18px;
                        svg {
                            width: 23px;
                            height: 23px;
                        }
                    }
                }
            }
        }
        }
    }
    .RegisterPagePro .staticPositon .container .row .LeftImgBlock .LeftBlurSide .LoginBigImg .LeftRegTop .LoginEventLogo {
        top: 10px;
        left: 10px;
        width: 110px;
    }
    .RegistrationTabsHome .InfoStageOrder .tab-pane .AdvanceTab .sponsorBlock ul li {
        width: 30%;
    }
    .RegistrationTabsHome .InfoStageOrder .ScheduleMixTab .scheduleBlock .AllPlanSchedule .ScheduleCalendarInfos .SpeakerRowList .slick-slider .slick-arrow {
        bottom: -10px !important;
    }
  }
@media (max-width:479px) {
    .RegisterPagePro {
        .staticPositon {
            .container {
                max-width: calc(100vw - 0%) !important;
                .row {
                    .LeftImgBlock {
                        width: 100%;
                        float: left;
                    }
                    .LoginRegisterPage {
                        width: 100%;
                        float: left;
                    }
                    .LoginRegisterPage {
                        .AdvanceEventDelReg {
                            padding: 64px 22px 22px;
                            h3 {
                                font-size: 18px;
                                font-weight: 500;
                                line-height: 26px;
                            }
                            .DetailFixEventMx {
                                .FullDeltailInfo {
                                    padding: 0 13px 10px;
                                }
                                span {
                                    padding: 4px 14px;
                                    font-size: 11px;
                                }
                                .TimeScheduleCal {
                                    .TimeCalenderMix {
                                        svg {
                                            margin-right: 6px;
                                            width: 15px;
                                            height: 15px;
                                        }
                                        p {
                                            font-size: 13px;
                                            margin-right: 14px;
                                        }                       
                                    }
                                }
                                .LogoScheduleEvent {
                                    img {
                                        height: 30px;
                                        width: auto !important;
                                    }
                                }
                            }
                        }
                        .EnterEventAdvance {
                            // padding: 24px 28px 30px;
                            h2 {
                                font-size: 24px;
                                margin: 20px 0 28px;
                            }
                            .Vactor1 {
                                &::before {
                                    bottom: -46px;
                                    left: -106px;
                                    width: 185px;
                                    height: 185px
                                }
                            }
                            .Vactor2 {
                                &::after {
                                    width: 145px;
                                    height: 145px;
                                }
                            }
                            .EventTypeAdvamce {
                                .form-group {
                                    .form-control {
                                        height: 48px;
                                    }
                                    .EnterBtn {
                                        padding: 12px 28px;
                                        height: 50px;
                                    }
                                    .EnterOtpValue {
                                        .OtpValue {
                                            input {
                                                margin: 0 4px;
                                                width: 40px!important;
                                                height: 42px!important;
                                                font-size: 20px;
                                            }    
                                        }
                                    }
                                }
                            }
                            .SocialLoginType {
                                ul {
                                    li {
                                        .img-fluid {
                                            width: 40px !important;
                                        }
                                        svg{
                                            width: 40px;
                                        }
                                    }
                                }
                            }
                        }
                        .SlightlyEarlyYet {
                            .SlightlyEvents {
                                h3 {
                                    font-size: 18px;
                                }
                                p {
                                    font-size: 18px;
                                }
                            }
                            .timerBodyCondown {
                                .event-live {
                                    h3 {
                                        font-size: 20px;
                                    }
                                }
                            }
                            .SlightlyTimeSocial {
                                .SocialLoginType {
                                    ul {
                                        li {
                                            .img-fluid {
                                                width: 48px;
                                            }
                                        }
                                    }
                                }
                            }
                            &::before {
                                bottom: -46px;
                                left: -106px;
                                width: 185px;
                                height: 185px
                            }
                            &::after {
                                width: 130px;
                                height: 130px;
                            }
                        }
    
                        .SlightlyEarlyYet {
                            .SlightlyEvents {
                                padding: 30px 22px;
                                h3 {
                                    font-size: 16px;
                                }
                                p {
                                    font-size: 16px;
                                }
                            }
                            .SlightlyTimeSocial {
                                padding: 24px 30px 40px;
                            }
                        }
    
                    }
                }
            }
        }
    }
    .RegistrationTabsHome {
        .responsive-tabs-container {
            .nav-tabs {
                padding: 8px 10px;
                ul {
                    li {
                        a {
                            padding: 4px 8px;
                            font-size: 12px;
                        }
                    }
                }
                .nav {
                    .nav-link {
                        padding: 4px 10px;
                        font-weight: 400;
                        font-size: 12px;
                        &.active {
                            padding: 4px 10px;
                        }
                    }
                }
            }
        }
    }
    .RegisterPagePro {
        .staticPositon {
            .container {
                .row {
                    .LoginRegisterPage {
                        .SlightlyEarlyYet {
                            .EventTypeAdvamce {
                                .EnterEventAction {
                                    padding: 12px 15px;
                                    .EventTypeActonIn {
                                        .form-group {
                                            .form-control {
                                                height: 50px;
                                                font-size: 14px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        
    }
    .RegisterPagePro {
        .staticPositon {
            .container {
                .row {
                    .LoginRegisterPage {
                        .SlightlyEarlyYet {
                            .EventTypeAdvamce {
                                .EnterEventAction {
                                    .EventTypeActonIn {
                                        .form-group {
                                            .SelectDropdown {
                                                .dropdown {
                                                    .css-2b097c-container {
                                                        .css-yk16xz-control {
                                                            font-size: 14px;
                                                            padding: 0px 18px;
                                                        }
                                                        .css-1pahdxg-control {
                                                            font-size: 14px;
                                                            padding: 0px 18px !important;
                                                        }
                                                        .css-yk16xz-control div {
                                                            font-size: 14px;
                                                        }
                                                        .css-1pahdxg-control div {
                                                            font-size: 14px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        
    }

    .TimerCoundTime {
        .timerBodyCondown {
            width: 88%;
            div {
                width: 64px !important;
                height: 64px !important;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-flow: column;
                svg {
                    display: none;
                }
            }
        }
    }
    .RegisterPagePro {
        .staticPositon {
            .container {
                .row {
                    .LoginRegisterPage {
                        .SlightlyEarlyYet {
                            .SlightlyTimeSocial {
                                .OrTxt {
                                    span {
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        
    }
    .TimerCoundTime .timerBodyCondown span {
        font-size: 35px;
    }
    .RegistrationTabsHome {
        .InfoStageOrder {
            .tab-pane {
                .AdvanceTab {
                    .InfoHeadingTop {
                        p {
                            //font-size: 14px;
                        }
                        h4 {
                            font-size: 20px;
                            margin: 0 0 18px;
                            svg {
                                width: 24px;
                                height: 24px;
                            }
                        }
                    }
                }
            }
        }
    }
    .RegistrationTabsHome {
        .InfoStageOrder {
            .ScheduleMixTab {
                .scheduleBlock {
                    padding: 0 0 !important;
                    .SetEventSchedule {
                        .SelectDayStage {
                            width: 100% !important;
                            position: inherit !important;
                            //display: block !important;
                            margin: 8px 0 0;
                            .DayScheduleStages {
                                .SelectDropdown {
                                    .SeleftDDl {
                                        .dropdown {
                                            .dropdown-toggle {
                                                font-size: 14px !important;
                                                max-width: 100vw !important;
                                            }
                                        }
                                    }
                                }
                            }
                            
                            .DayScheduleStages {
                                margin: 0 8px !important;
                            }
                            .SelectAllStages {
                                margin-right: 0px !important;
                            }
                            .SelectDays {
                                margin-left: 0px !important;
                            }
                        }
                    }
                    .AllPlanSchedule {
                        .ScheduleCalendarInfos {
                            .StageFullInfoFix {
                                margin-bottom: 20px;
                                display: block;
                                height: auto;
                                border: 1px #8944FB solid;
                                border-radius: 8px;
                                padding: 12px;
                                .CalenderDateScheduleInof {
                                    display: flex;
                                    cursor: cell;
                                    justify-content: space-between;
                                    padding: 12px 12px 16px;
                                    border-radius: 15px 15px 0 0;
                                    .StageNo {
                                        padding: 2px 8px;
                                        top: auto;
                                        left: 0;
                                        right: 0;
                                        width: 130px;
                                        margin: 0px auto;
                                        bottom: -10px;
                                        font-size: 12px;
                                    }
                                    .DtStageEventDay {
                                        h4 {
                                            line-height: 24px;
                                            font-size: 15px;
                                            strong {
                                                font-weight: 600;
                                                display: inline-block !important;
                                            }
                                        }
                                    }
                                    .ScheduleTMFx {
                                        margin-bottom: 0px !important;
                                        p {
                                            font-size: 14px;
                                            font-weight: 500;
                                        }
                                    }
                                    .ScheduleStatusOngoing {
                                        margin: 5px 0 0 !important;
                                        .EventStyle {
                                            font-weight: 500;
                                            padding: 4px 12px;
                                            font-size: 12px;
                                        }
                                    }
                                }
                                .ScheduleInfoWithConts {
                                    padding: 0px 0px 30px 0px !important;
                                    h3 {
                                        margin: 10px 0 5px;
                                        font-size: 18px;
                                    }
                                    p {
                                        font-size: 12px;
                                    }
                                    .SpeakerRowList {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .tab-pane {
                .AdvanceTab {
                    .InfoHeadingTop {
                        h4 {
                            font-size: 18px;
                            svg {
                                width: 23px;
                                height: 23px;
                            }
                        }
                    }
                }
            }
        }
    }
    .RegistrationTabsHome .InfoStageOrder .tab-pane .AdvanceTab .sponsorBlock ul li {
        width: 46% !important;
        margin: 0px 5px 10px !important;
    }
    .RegisterPagePro .staticPositon .container .row .LeftImgBlock .LeftBlurSide .LoginBigImg .LeftRegTop .LoginEventLogo {
        top: 10px;
        left: 10px;
        width: 90px;
    }
    .RegistrationTabsHome {
        .InfoStageOrder {
            .tab-pane {
                .AdvanceTab {
                    .scheduleBlock {
                        .SetEventSchedule {
                            .SelectDayStage {
                                width: 100%;
                                position: inherit !important;
                                margin-bottom: 20px;
                                .DayScheduleStages {
                                    .SelectDropdown{
                                        .dropdown {
                                            .dropdown-toggle {
                                                width: 100% !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .RegistrationTabsHome {
        .InfoStageOrder {
            padding: 0 0px;
            .ScheduleMixTab {
                .scheduleBlock {
                    .Advancd-md {
                        width: 100%!important;
                        padding: 0 15px;
                        .AllPlanSchedule {
                            padding: 15px 0px 0 0 !important;
                            margin: 8px 0 24px;
                        }
                    }

                    
                }
            }
        }
    }
    .RegistrationTabsHome .InfoStageOrder .ScheduleMixTab .scheduleBlock  {
        height: auto !important;
    }
    .RegisterPagePro .staticPositon .container .row .LoginRegisterPage .AdvanceEventDelReg .DetailFixEventMx .FullDeltailInfo .EventNfollowUs .SocialMicro {
        // width: 30px;
        // height: 30px;
    }
    .RegistrationTabsHome .InfoStageOrder .ScheduleMixTab .scheduleBlock .AllPlanSchedule .ScheduleCalendarInfos .SpeakerRowList .slick-slider .slick-arrow {
        bottom: -10px !important;
    }
    .RegistrationTabsHome .InfoStageOrder .AdvanceTab .sponsorBlock ul li {
        margin: 0 6px 10px;
        width: 28%;
    }
}
@media (max-width: 360px) {
    .RegistrationTabsHome {
        .responsive-tabs-container {
            .nav-tabs {
                padding: 8px 8px;
                margin-left: 2px;
                .nav {
                    .nav-link {
                        padding: 4px 8px;
                        font-weight: 400;
                        font-size: 12px;
                        &.active {
                            padding: 4px 10px;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1024px) and (orientation: landscape) {
    .RegisterPagePro .staticPositon .container .row .RegisterWrapper {
        display: block;
    }
    footer {
        position: relative;
        bottom: 0px;
        top: auto;
    }
    .RegisterPagePro .staticPositon .container .row .RegisterWrapper .LoginRegisterPage {
        height: auto;
    }
    // .RegisterPagePro .staticPositon .container .row .LeftImgBlock {
    //     margin-bottom: 52px !important;
    // }
    .RegistrationTabsHome .InfoStageOrder .ScheduleMixTab .scheduleBlock .schedulOverFlow {
        height: 208px !important;
        overflow-x: hidden;
        padding-top: 0 !important;
    }
    .RegistrationTabsHome .InfoStageOrder .ScheduleMixTab .scheduleBlock .AllPlanSchedule .ScheduleCalendarInfos .StageFullInfoFix:last-child {
        margin-bottom: 4px !important;
    }
}
@media(max-width:575px){
    .RegisterPagePro .event-detail-wrapper {
        padding: 0px 15px 10px;
    }
}
.staticNavHr {
    position: relative;
    width: 100%;
    height: 62px;
    top: 0;
    margin: -60px 0 0;
}



