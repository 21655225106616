.PresAudiPopup.media-select-popup .modal-body .media-list-wrapper{
    padding: 30px !important;
    max-height: calc(100vh - 240px);
    overflow: auto;
}
.media-select-popup .SpkInfoImgs {
    position: relative;
    input {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        opacity: 0;
        z-index: 1;
        cursor: pointer;
    }
    label {
        display: flex;
    border: 1px solid #eee;
    padding: 12px 40px 12px 15px;
    border-radius: 50px;
    margin-bottom: 15px;
    font-size: 16px;
    align-items: center;
    gap: 15px;
    svg {
        width: 20px;
        flex: 0 0 20px;
    }
    span{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
        &:before {
            content: "";
            position: absolute;
            width: 25px;
            height: 25px;
            border: 2px solid #ccc;
            border-radius: 20px;
            right: 15px;
        }
        &:after {
            content: "";
            position: absolute;
            width: 12px;
            height: 7px;
            border-left: 2px solid #ccc;
            border-bottom: 2px solid #ccc;
            right: 19px;
            top: calc(50% - 3px);
            transform: rotate(-45deg) translateY(-50%);
        }
    }
}
.media-select-popup .SpkInfoImgs input:checked + label{ border: 1px solid #1A78E5;color:#1A78E5}
.media-select-popup .SpkInfoImgs input:checked + label svg path{fill:#1A78E5}
.media-select-popup .SpkInfoImgs input:checked + label:before{ border: 2px solid #1A78E5;}
.media-select-popup .SpkInfoImgs input:checked + label:after{  border-left: 2px solid #1A78E5; border-bottom: 2px solid #1A78E5;}
.media-select-popup .SpkInfoImgs + p { text-align: center; color: #333;}
.media-select-popup button.enter-the-event-button { background-color: #1A78E5; border: none; color: #fff; width: 100%; padding: 14px; border-radius: 50px; font-size: 18px; }

label.btn.mixhubb-uploader-label.mt-4.UploadLabel{cursor: auto;}
p.select-media-text { color: #4285f4!important; cursor: pointer; }
.UpdateOvelayImgVodeo .minHeightBox.over-f { height: auto!important; }

.media-list-buttons { padding: 15px 30px 30px 30px; text-align: center;}

.media-list-buttons { padding: 15px 30px 30px 30px; text-align: center;}
.UpdateOvelayImgVodeo .minHeightBox .UploaderFiles .UploadLabel {
    
    padding: 0px 0 !important;
}
.PresAudiPopup.media-select-popup .modal-dialog {
    max-width: 600px;
}
.PresAudiPopup.media-select-popup .modal-dialog .modal-body > h4 {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #cccf;
    font-weight: normal;
    font-size: 18px;
}


@media(max-width:1600px){
    .PresAudiPopup.media-select-popup .modal-dialog {
        max-width: 500px;
    }
}
@media(max-width:1440px){
    .media-select-popup .SpkInfoImgs label{font-size: 14px;}
    .media-select-popup .SpkInfoImgs label svg {
        width: 18px;
        flex: 0 0 18px;
    }
    .media-select-popup .SpkInfoImgs label:before{height: 22px;width:22px;}
    .media-select-popup .SpkInfoImgs label:after{width:10px;height: 6px;}
    .media-select-popup button.enter-the-event-button{font-size: 16px;}
    .media-list-buttons p { font-size: 14px;}
    .PresAudiPopup.media-select-popup .modal-dialog .modal-body > h4{font-size: 16px;}
}
@media(max-width:1280px){ 
    .media-select-popup button.enter-the-event-button { font-size: 14px;}
    .PresAudiPopup.media-select-popup .modal-dialog .modal-body > h4{font-size: 14px;}
}      