@media(min-width:1920px) {
	
}
@media(min-width:1600px) and (max-width:1919px) {
	.MixhubbHeader {
        padding: 10px 0;
    }
    .screen-stage-main-div {
        .MainViewBackStage {
            .front-stage-stream-scroll {
                .row {
                    .stage-user-list {
                        .RightSvgIcon {
                            .mh-cursor-pointer {
                                top: 4px;
                                left: 4px;
                                width: 20px;
                                height: 20px;
                                padding: 4px !important;
                            }
                        }
                        .video_footer {
                            .bottom_footer {
                                h4 {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .screen-stage-main-div.oraganizer-backstage-dashboard.collapse-out-frontstage {
        .MainViewBackStage {
            .front-stage-stream-scroll {
                .row {
                    .stage-user-list {
                        .video_footer {
                            .bottom_footer{
                                h4 {
                                    font-size: 14px;
                                }
                            }
                            .ShortName {
                                h4 {
                                    font-size: 28px;
                                    width: 65px;
                                    height: 65px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
     
    .screen-stage-main-div.oraganizer-backstage-dashboard {
        .MainViewBackStage {
            .front-stage-stream-scroll {
                .row {
                    .stage-user-list {
                        .video_footer {
                            .bottom_footer {
                                h4 {
                                    font-size: 12px;
                                }
                            }
                            .ShortName {
                                h4 {
                                    font-size: 20px;
                                    width: 50px;
                                    height: 50px;
                                }
                            }
                            .BackIconAction {
                                top: 5px !important;
                                right: 2px !important;
                                .ActionLive {
                                    width: 24px !important;
                                    height: 24px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .frontend-dashboard {
        .MainViewBackStage {
            .alignAllDiv {
                .front-stage-stream-scroll {
                    width: 75% !important;
                }
            }
            .RemoveAllDiv {
                .front-stage-stream-scroll {
                    width: 85% !important;
                }
            }
        }
    }
    
    .backstage-dashboard {
        .container-fluid {
            .MainViewBackStage {      
                .alignAllDiv {
                    .front-stage-stream-scroll {
                        .row {
                            .stage-user-list {
                                .list-2, .list-3, .list-4 {
                                    .video_footer {
                                        .bottom_footer{
                                            .NameExpFull {
                                            width: 100%;
                                            height: 48px;
                                            h4 {
                                                //padding: 0px 8px 0px 30px;
                                            }
                                            }
                                        }
                                    }
                                }
                                .list-5, .list-6 {
                                    .video_footer {
                                        .bottom_footer{
                                            .NameExpFull {
                                            width: 100%;
                                            height: 48px;
                                            h4 {
                                                //padding: 0px 8px 0px 30px;
                                            }
                                            }
                                        }
                                    }
                                }
                                .list-7, .list-8 {
                                    .video_footer {
                                        .bottom_footer{
                                            .NameExpFull {
                                            width: 100%;
                                            height: 42px;
                                            h4 {
                                                font-size: 12px;
                                            }
                                            }
                                        }
                                    }
                                }
                                .list-9, .list-10, .list-11, .list-12 {
                                    .video_footer {
                                        .bottom_footer {
                                            .NameExpFull {
                                            width: 100%;
                                            height: 36px;
                                            h4 {
                                                font-size: 12px;
                                                }
                                            }
                                        }
                                    }
                                }
                                .list-13, .list-14, .list-15, .list-16 {
                                    .video_footer {
                                        .bottom_footer {
                                            .NameExpFull {
                                            width: 100%;
                                            height: 36px;
                                                h4 {
                                                    font-size: 12px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                
            }
        }
    
    }
    .SpeakerStageList .EventSpeakerStage {
        width: 174px;
        min-height: 72px;
        margin: 0 6px 8px;
    }
    .RegistrationTabsHome .InfoStageOrder .tab-pane .AdvanceTab .InfoHeadingTop p {
        //font-size: 15px !important;
    }

    .audience-front-stage-roll .MainViewBackStage {
        height: calc(100vh - 292px) !important;
    }
    .audience-front-stage-roll .go-backstage-btn {
        bottom: 183px !important;
    }
    .audience-front-stage-roll  {
        .container-fluid {
            .BackstageInfoStream  {
                .streamingUsers {
                    .StreamVideoFrame {
                        height: 172px;
                    }
                }
            }
        }
      }

}
@media(min-width:1440px) and (max-width:1599px) {
    
    .MixhubbHeader {
        padding: 10px 0;
        .HeaderMainTop {
            .MixhubbLogo {
                max-width: 190px;
                flex: 0 0 190px;
            }
            .MixhubbMenu {
                display: flex;
                // max-width: 72%;
                // flex: 0 0 72%;
                ul {
                    li {
                        margin: 0 22px;
                    }
                }
            }
            
        }
    }
    .BackstageInfoStream .streamingUsers .StreamScroll .userLoginBg {
        width: 270px;
        height: 180px;
    }
    .BackstageInfoStream .streamingUsers .StreamScroll .userLoginBg .video_footer {
        padding: 5px 15px;
    }
    .RegisterPageAll {
        .LoginRegisterPage {
            .MixhubbExpDae {
                padding: 20px 12px;
            }
        }
    }
    .RegisterPageAll {
        .LoginRegisterPage {
            .MixhubbExpDae {
                h1 {
                    font-size: 28px;
                    margin: 0 0 8px;
                }
            }
        }
    }
    .RegisterPageAll {
        .LoginRegisterPage {
            .MixhubbExpDae {
                p {
                    color: #333333;
                    font-size: 25px;
                    margin: 0;
                    font-weight: 400;
                }
                .event-timezone {
                    font-size: 18px;
                  }
            }
        }
    }
    .screen-stage-main-div .MainViewBackStage .front-stage-stream-scroll .row .stage-user-list .video_footer .bottom_footer h4, .screen-stage-main-div .MainViewBackStage .front-stage-stream-scroll .row .stage-user-list .video_footer .bottom_footer .h4 {
        font-size: 12px;
    }
    .screen-stage-main-div .MainViewBackStage .front-stage-stream-scroll .row .stage-user-list .RightSvgIcon .mh-cursor-pointer {
        top: 4px;
        left: 4px;
        width: 20px;
        height: 20px;
        padding: 4px !important;
    }
    .screen-stage-main-div .MainViewBackStage .front-stage-stream-scroll .row .stage-user-list .video_footer .bottom_footer h4 {
        font-size: 12px;
    }
    .screen-stage-main-div.oraganizer-backstage-dashboard.collapse-out-frontstage .MainViewBackStage .front-stage-stream-scroll .row .stage-user-list .video_footer .bottom_footer h4 {
        font-size: 14px;
    }
    

   
    .screen-stage-main-div.oraganizer-backstage-dashboard.collapse-out-frontstage .MainViewBackStage .front-stage-stream-scroll .row .stage-user-list .video_footer .ShortName h4 {
        font-size: 28px;
        width: 65px;
        height: 65px;
    }
    .screen-stage-main-div.oraganizer-backstage-dashboard .MainViewBackStage .front-stage-stream-scroll .row .stage-user-list .video_footer .BackIconAction {
        top: 5px !important;
        right: 2px !important;
    }
    
    .RegisterPageAll {
        .LeftImgBlock {
            .LoginEventLogo {
                top: 15px;
                left: 15px;
                width: 180px;
            }
        }
    }
    .BackstageRtStream {
        .BsHeightFixLx {
            height: calc(100vh - 258px);
            .EventInfoStage {
                height: calc(100vh - 330px);
                width: 100%;
                position: relative;
                .tab-content {
                    .ChatInfos {
                        height: calc(100vh - 464px);
                    }
                }
            }
        }
    }
    .backstage-dashboard.collapse-out-frontstage {
        .MainViewBackStage {
            .FlexResizeStep {
                .SpeakerSizeShrink {
                    .fullFreeContainer {
                        display: flex;
                        align-items: center;
                        width: 100% !important;
                        height: auto !important;
                        .RemoveAllDiv {
                            width: 100% !important;
                            height: auto !important;
                        }
                    }
                }
                .ChangeWithSize  {
                    .front-stage-stream-scroll {
                        height: 100% !important;
                        .row {
                            .PollQnaBase {
                                .ScreenCast {
                                    width: 100%;
                                    margin: 0 0 35px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .audience-front-stage-roll.collapse-out-frontstage {
        .MainViewBackStage {
            .FlexResizeStep {
                .SpeakerSizeShrink {
                    .fullFreeContainer {
                        display: flex;
                        align-items: center;
                        width: 100% !important;
                        height: auto !important;
                        .RemoveAllDiv {
                            width: 100% !important;
                            height: auto !important;
                        }
                    }
                }
            }
        }
    }
    .SpeakerStageList .EventSpeakerStage {
        width: 205px;
        min-height: 72px;
        margin: 6px 6px 4px;
    }
    .RegistrationTabsHome .InfoStageOrder .tab-pane .AdvanceTab .InfoHeadingTop p {
        //font-size: 15px !important;
    }
    .RegisterPageAdvance .container .row .LoginRegisterPage .AdvanceEventDelReg .DetailFixEventMx .registration-timezone div {
        font-size: 14px;
    }
    .RegistrationTabsHome {
        .InfoStageOrder {
            .ScheduleMixTab {
                .scheduleBlock {
                    .AllPlanSchedule {
                        .ScheduleCalendarInfos {
                            .StageFullInfoFix {
                                .CalenderDateScheduleInof {
                                    .DtStageEventDay {
                                        h4 {
                                            font-size: 22px !important;
                                        }
                                    }
                                    .ScheduleTMFx {
                                        p {
                                            font-size: 18px !important;
                                        }
                                    }
                                }
                                .CalenderDateScheduleInof {
                                    .calenderInfoGoogle {
                                        padding: 5px 10px !important;
                                    }
                                }
                                .CalenderDateScheduleInof {
                                    .calenderInfoGoogle {
                                        a {
                                            padding: 0 4px;
                                            svg {
                                                width: 16px !important;
                                                height: 16px !important;
                                            }
                                        }
                                    }
                                }
                            }
                            .SpeakerRowList {
                                .EventSpeakerExp {
                                    .SpeakerNamePos {
                                        .SpeakerDegDetail {
                                            h4 {
                                                font-size: 13px !important;
                                            }
                                            .DetailPostMix {
                                                p {
                                                    font-size: 12px !important;
                                                }
                                                span {
                                                    font-size: 12px !important;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .StageInfoBar .accordion .accordion-item .accordion-body {
        padding: 0 4px 8px;
    }
    .OnoffSwitch .MuiSwitch-root {
        width: 42px;
    }
    .BackstageRtStream .BsHeightFixLx .EventInfoStage .tab-content .EnableChat .BlueColor {
        padding: 6px 2px;
        font-size: 13px;
    }
    .oraganizer-backstage-dashboard {
        .MainViewBackStage {
            .DivChangeWidthView {
                .ChangeWidthScroll {
                    .flexDicSeps {
                        .HeadingBstage {
                            .ActionBcFcHeader {
                                .BsMidAction {
                                    height: 34px !important;
                                    padding: 0 10px !important;
                                    .ActionLive {
                                        margin: 0 6px !important;
                                        width: 22px;
                                        height: 22px;
                                    }
                                    .BsMic {
                                        margin-right: 0px !important;
                                        margin-left: 0px !important;
                                    }
                                    .BsSetting {
                                    width: 16px !important;
                                    }
                                    .StartStop {
                                        width: auto !important;
                                        height: auto !important;
                                    }
                                    .BGImgUpdate {
                                        width: 15px;
                                        height: 15px;
                                    }
                                }
                                .RigthtStreamBs {
                                    margin-left: 8px;
                                    .DarkGrayButton {
                                        padding: 6px 14px;
                                        font-size: 12px;
                                    }
                                }                                
                            }
                            .RigthtStreamBs {
                                .StreamRight {
                                    .leaveStreamDark {
                                        padding: 6px 12px 6px 18px;
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .BottomStatusFoot {
            padding: 6px 12px;
        }
    }
    .audience-front-stage-roll .MainViewBackStage {
        height: calc(100vh - 302px)!important;
    }
    .audience-front-stage-roll.collapse-out-frontstage .go-backstage-btn {
        bottom: 10px !important;
    }
    .audience-front-stage-roll .go-backstage-btn {
        bottom: 193px !important;
    }
    .BackstageInfoStream .streamingUsers .StreamVideoFrame .back-stage-stream-scroll .video_footer {
        height: 130px !important;
    }
   
    .Stage-Back-Event-Info {
        height: 168px;
        padding: 0;
    }
    .backstage-dashboard .MainViewBackStage {
        height: calc(100vh - 262px) !important;
    }
    .BackstageInfoStream .streamingUsers .StreamVideoFrame {
        height: 144px;
    }
    .BackstageInfoStream .streamingUsers .StreamVideoFrame .back-stage-stream-scroll .video_footer .ShortName h4 {
        font-size: 20px;
        height: 45px;
        width: 45px;
    }
    .container-error {
        .ErrorInbox {
            width: 622px;
            padding: 50px 0 60px;
            .LogoWebinar {
                margin-bottom: 16px;
            }
            .PlugWebinar {
                margin-top: 0px;
            }
            .ErrorInfo {
                h3 {
                    font-size: 72px;
                }
            }
        }
        .CurveLine1 {
            width: calc(50% - 310px);
        }
        .CurveLine2 {
            width: calc(50% - 310px);
        }
    }
    .audience-front-stage-roll  {
        .container-fluid {
            .BackstageInfoStream  {
                .streamingUsers {
                    .StreamVideoFrame {
                        height: 182px;
                    }
                }
            }
        }
    }
}

@media(max-width: 1366px) {
    .BackstageInfoStream .streamingUsers .StreamScroll .userLoginBg {
        width: 270px;
        height: 180px;
    }
    .BackstageInfoStream .streamingUsers .StreamScroll .userLoginBg .video_footer {
        padding: 5px 15px;
    }
    .BackstageInfoStream .HeadingBstage .BsMidAction .ActionLive {
        margin: 0 6px;
        width: 32px;
        height: 32px;
    }
    .SchedulePageEvent {
        .SetEventSchedule {
            .ScheduleDayStage {
                .DayScheduleStages {
                    .SelectDropdown {
                        .SeleftDDl {
                            .dropdown {
                                .dropdown-toggle {
                                    &::before {
                                        top: 10px;
                                    }
                                }
                            }
                            .show {
                            .dropdown-toggle {
                                &:before {
                                    transform: rotate(-45deg);
                                    top: 16px;
                                    transition: all 0.5s ease;
                                    -webkit-transition: all 0.5s ease;
                                    -moz-transition: all 0.5s ease;
                                    -o-transition: all 0.5s ease;
                                }
                             }
                            }
                        }
                    }
                }
                .SelectDayStage {
                    .SelectSchedule {
                        .PepleSerchHere {
                            input {
                                height: 36px !important;
                            }
                            .SerchIcon {
                                top: 6px;
                                svg {
                                    width: 20px;
                                    height: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .ScheduleDayStage .SelectDayStage .DayScheduleStages .SelectDropdown .SeleftDDl .schedule-dropdown {
        top: 40px;
    }
}
@media(max-width: 1366px) and (min-width: 1024px) {
    .MixhubbHeader .HeaderMainTop .MixhubbMenu ul li .nav-mixhubb .live-icon .RecordLiveStream {
        bottom: -9px;
    }
    
}
@media(max-width: 1440px) and (min-width: 769px) {
    .timer-countDown {
        .timerBodyCondown {
            width: 322px;
            div {
                width: 60px !important;
                height: 60px !important;
                .time-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    .time {
                        font-size: 26px;
                        line-height: 30px;
                    }
                }
            }
            span {
                font-size: 50px;
            }
        }
    }
    .BackstageInfoStream .streamingUsers .StreamScroll .userLoginBg {
        width: 270px;
        height: 180px;
    }
    .BackstageInfoStream .streamingUsers .StreamScroll .userLoginBg .video_footer {
        padding: 5px 15px;
    }
}

@media(max-width: 1366px) {
    .BackstageInfoStream .streamingUsers .StreamScroll .userLoginBg {
        width: 270px;
        height: 180px;
    }
    .BackstageInfoStream .streamingUsers .StreamScroll .userLoginBg .video_footer {
        padding: 5px 15px;
    }
    .BackstageInfoStream .HeadingBstage .BsMidAction .ActionLive {
        margin: 0 6px;
        width: 32px;
        height: 32px;
    }
}



@media(min-width:1366px) and (max-width:1439px) {
	.MixhubbHeader {
        padding: 10px 0;
        .HeaderMainTop {
            .MixhubbLogo {
                max-width: 170px;
                flex: 0 0 170px;
            }
            .MixhubbMenu {
                display: flex;
                // max-width: 70%;
                // flex: 0 0 70%;
                ul {
                    li {
                        margin: 0 22px;
                    }
                }
            }
            // .MixhubbRight {
            //     max-width: 14%;
            //     flex: 0 0 14%;
            // }
        }
    }
    .SchedulePageEvent {
        .SetEventSchedule {
            .ScheduleDayStage {
                h4 {
                    font-size: 20px;
                    svg {
                        width: 20px;
                        height: 20px;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
    .AllPlanSchedule {
       //padding: 25px 20px 0;
        .ScheduleCalendarInfos {
            .StageFullInfoFix {
                //margin-bottom: 38px;
                //height: 256px;
                .CalenderDateScheduleInof {
                    //padding: 22px 16px 22px;
                    .StageNo {
                        padding: 0px 16px;
                    }
                    .DtStageEventDay {
                        h4 {
                            line-height: 34px;
                            font-size: 24px;
                        }
                    }
                    .ScheduleTMFx {
                        margin: 6px 0 16px;
                        p {
                            font-size: 20px;
                        }
                    }
                    .ScheduleStatusOngoing {
                        //margin: 10px 0 0;
                        label {
                            font-size: 15px;
                        }
                    }

                }
                .calenderInfoGoogle {
                    a {

                        svg {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
            .ScheduleInfoWithConts {
                width: calc(100% - 170px);
                position: relative;
                h3 {
                    margin: 0 0 5px;
                    font-size: 18px;
                }
                p {
                    font-size: 14px;
                }
                .Watch-Session {
                    .RedButton {
                        padding: 6px 14px;
                        font-size: 14px;
                    }
                    .BlueinButton {
                        padding: 6px 14px;
                        font-size: 14px;
                    }
                }
            }
            .SpeakerRowList {
                .EventSpeakerExp {
                    padding: 10px 10px;
                    //margin-right: 15px;
                    //min-height: 84px;
                    .ImgSpeakerBg {
                        width: 45px;
                        height: 45px;
                    }
                    .SpeakerNamePos {
                        margin-left: 12px;
                        padding: 3px 0;
                        h4 {
                            font-size: 14px;
                        }
                        p {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
        .RoomMeetingBoard {
            .Available10Seats {
                ul {
                    li {
                        margin: 4px !important;
                    }
                    .SelectSeat {
                        &:first-child {
                           .UserInfoDetail {
                                left: -26px;
                           }
                           .bottomArrow {
                               left: -72px !important;
                           }
                        }
                        &:nth-child(6) {
                            .UserInfoDetail {
                                left: -26px;
                           }
                           .bottomArrow {
                               left: -72px !important;
                           }
                         }
                    }
                }
            }
        }
    }
    .MixhubbStage {
        overflow-y: inherit;
        .StageMixhubbEvent {
            .SetEventInfo {
                padding: 32px 20px 20px;
                .SetupStatus{
                    span {
                        padding: 2px 28px;
                    }
                }
                h3 {
                    font-size: 22px;
                }
                .AboutSetupInfo {
                    background-color: var(--light-gray);
                    padding: 15px 15px;
                    margin: 25px 0 25px;
                    h4 {
                        font-size: 15px;
                    }
                    p {
                        font-size: 14px;
                    }
                }
    
                .ExpoSetupInfo {
                    min-height: 88px;
                }
                .EnterStageBtn {
                    padding: 10px 28px;
                }
            }
        }
    }   
    .RoomMeetingBoard .RoomEventLogo {
        width: 100px;
    }
    .RegisterPageAll {
        .LoginRegisterPage {
            .MixhubbExpDae {
                h1 {
                    font-size: 28px;
                    margin: 0 0 12px;
                }
            }
        }
    }
    .RegisterPageAll {
        .LoginRegisterPage {
            .MixhubbExpDae {
                p {
                    color: #333333;
                    font-size: 25px;
                    margin: 0;
                    font-weight: 400;
                }
                .event-timezone {
                    font-size: 17px;
                }
            }
        }
    }
    .RegisterPageAll {
        .LoginRegisterPage {
            .EnterEventAction {
                h2 {
                    font-size: 24px;
                }
            }
        }
    }
    .RegisterPageAll {
        .LoginRegisterPage {
            .EnterEventAction {
                margin: 30px auto 0;
            }
        }
    }
    .screen-stage-main-div .MainViewBackStage .front-stage-stream-scroll .row .stage-user-list .RightSvgIcon .mh-cursor-pointer {
        top: 4px;
        left: 4px;
        width: 20px;
        height: 20px;
        padding: 4px !important;
    }
    .screen-stage-main-div .MainViewBackStage .front-stage-stream-scroll .row .stage-user-list .video_footer .bottom_footer h4 {
        font-size: 12px;
    }
    .screen-stage-main-div.oraganizer-backstage-dashboard.collapse-out-frontstage .MainViewBackStage .front-stage-stream-scroll .row .stage-user-list .video_footer .bottom_footer h4 {
        font-size: 12px;
    }
    .screen-stage-main-div.oraganizer-backstage-dashboard .MainViewBackStage .front-stage-stream-scroll .row .stage-user-list .video_footer .bottom_footer h4 {
        font-size: 12px;
    }
    .screen-stage-main-div.oraganizer-backstage-dashboard .MainViewBackStage .front-stage-stream-scroll .row .stage-user-list .video_footer .ShortName h4 {
        font-size: 18px;
        width: 38px;
        height: 38px;
    }
    .screen-stage-main-div.oraganizer-backstage-dashboard.collapse-out-frontstage .MainViewBackStage .front-stage-stream-scroll .row .stage-user-list .video_footer .ShortName h4 {
        font-size: 28px;
        width: 65px;
        height: 65px;
    }
    .BackIconAction .ActionLive .widthSvg {
        padding: 2px 2px;
    }
    .BackIconAction .ActionLive {
            width: 22px;
            height: 22px;
    }
    .screen-stage-main-div.oraganizer-backstage-dashboard .MainViewBackStage .front-stage-stream-scroll .row .stage-user-list .video_footer .BackIconAction {
        top: 5px !important;
        right: 2px !important;
    }
    .screen-stage-main-div.oraganizer-backstage-dashboard .MainViewBackStage .front-stage-stream-scroll .row .stage-user-list .video_footer .BackIconAction .ActionLive {
        width: 18px !important;
        height: 18px !important;
    }
    .screen-stage-main-div {
        .container-fluid {
            //padding: 0;
        }
        .MainViewBackStage {
          .front-stage-stream-scroll {
            width: 100% !important;
          }
        }
    }
    .PeopleChatPopup, .ChatMixInfoMixhubb {
        width: 300px !important;
    }
    .MessageTextBox {
        .MsgEmojis {
            .EmojiIconsIn {
                top: -218px;
                width: 272px;
                left: -17px;
                .EmojiMainIco {
                    ul {
                        li {
                            width: 32px;
                            height: 36px;
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
    .BackStageSessionInfo {
        width: 340px;
    }
    .EventChatAccords .responsive-tabs-container h2, .EventChatAccords .responsive-tabs-container .h2 {
        font-size: 16px;
    }
    .MessageTextBox .form-control {
        height: 50px;
        padding: 12px 62px 12px 50px;
    }
   
    .MessageTextBox {
        .MsgEmojis {
            left: 16px;
            top: 12px;
            width: 20px;
            height: 20px;
        }
        .form-control {
            height: 50px;
            padding: 14px 62px 14px 50px;
        }
        .MsgSendIcon {
            width: 38px;
            height: 38px;
        }
        .MsgSendIcon {
            width: 38px;
            height: 38px;
        }
    }
    .SetUpMeeting {
        .MeetingWithPeople {
            .FixMeetingHere {
                .BackAgain {
                    img {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
    }
    .BackStageSessionInfo .InfoStageOrder .tab-content .StageInfoBar .StatEventSchedule {
        padding: 0 0;
    }
    .BackStageSessionInfo {
        .InfoStageOrder {
            .SendRequestNow {
                .BroadcastStart {
                    .RedButton {
                        padding: 8px 0 !important;
                    }
                }
                .RedButton {
                    padding: 8px 0 !important;
                }
            }
        }
    }
    .UpdateOvelayImgVodeo .minHeightBox {
        height: 151px !important;
        min-height: 151px !important;
    }
    .MixhubbStage .oraganizer-backstage-dashboard .BackstageInfoStream {
        width: calc(100% - 365px);
    }

   


    // .RightTopStyle {
    //     padding: 6px 20px 6px;
    //     ul {
    //         li {
    //             margin: 0 7px;
    //             button {
    //                 svg {
    //                     width: 20px;
    //                     height: 19px;
    //                 }
    //             }
    //         }
    //     }
    // } 
    .BackstageInfoStream {
        .HeadingBstage {
            h3 {
                font-size: 18px;
            }
            .BsMidAction {
                height: 36px;
                .ActionLive {
                    margin: 0 4px;
                    width: 24px;
                    height: 24px;
                }
                .BGImgUpdate {
                    margin: 0 4px;
                    width: 18px;
                    height: 18px;
                }
                .BsSetting {
                    width: 18px;
                    height: 18px;
                }
                .StartStop {
                    width: auto;
                    height: auto;
                    margin-right: 0;
                    position: relative;
                }
                .BsEnd {
                    .RedCommonBtn {
                        padding: 4px 18px;
                        font-size: 16px;
                    }
                }
            }
        }
    }
    .DarkGrayButton {
        padding: 6px 14px;
        font-size: 12px;
    }
    .BackStageSessionInfo .InfoStageOrder .tab-content .StageInfoBar .StatEventSchedule h3 {
        font-size: 16px;
    }
    .SelectDropdown {
        .dropdown {
            .css-2b097c-container {
                .css-yk16xz-control {
                    height: 42px;
                }
                .css-1pahdxg-control {
                    height: 42px;
                }
            } 
        }
    }
    .SelectDropdown .css-1hb7zxy-IndicatorsContainer::after {
        top: 12px;
    }
    .SlideCommonPopup .nav-tabs li .nav .nav-link {
        padding: 3px 10px;
        font-size: 13px;
    }
    .SlideCommonPopup .nav-tabs li .nav .nav-link.active {
        padding: 2px 10px;
    }
    .collapsDivToggle {
        width: 25px;
        height: 25px;
        top: 8px;
        .CastingToggle {
            padding: 5px;
        }
    }
    .collapsDivToggle .CastingToggle:after {
        right: 9px;
        top: 8px;
    }
    .collapsDivToggle .collapse-in-backstage:after {
        right: 9px;
        top: 11px;
    }
    .backstage-dashboard {
        .MainViewBackStage {
            height: calc(100vh - 242px) !important;
        }
        .FlexResizeStep {
            .SpeakerSizeShrink {
                width: calc(100% - 305px) !important;
            }
        }
        .StageFootShrink {
            width: calc(100% - 305px) !important;
        }
    }
    .backstage-dashboard.collapse-out-frontstage .MainViewBackStage {
        height: calc(100vh - 118px) !important;
    }
    .ChatMixInfoMixhubb .PeopleConvrtsList .peopleInfos .ChatInfos .SenderMassage .SenderMessage h4 {
        font-weight: 500;
    }
    .RegisterPageAll {
        .LeftImgBlock {
            .LoginEventLogo {
                top: 15px;
                left: 15px;
                width: 170px;
            }
        }
    }
    .SlideBarTransform {
        width: 280px;
        left: 3.4rem;
        border-radius: 4px;
    }
    .BackstageRtStream {
        right: 4px;
        width: 280px;
        border-radius: 4px;
    }
    .oraganizer-backstage-dashboard {
        .MainViewBackStage {
            width: calc(100% - 365px);
            .DivChangeWidthView {
                .ChangeWidthView {
                    width: calc(100% - 232px);
                }
            }
        }
    }
    .oraganizer-backstage-dashboard .MainViewBackStage .DivChangeWidthView .ChangeWidthScroll .flexDicSeps .HeadingBstage .RigthtStreamBs .StreamRight  .leaveStream {
        font-size: 13px;
        padding: 4px 22px 4px 10px !important;
    }
    .oraganizer-backstage-dashboard .MainViewBackStage .DivChangeWidthView .ChangeWidthScroll .flexDicSeps .HeadingBstage .RigthtStreamBs .StreamRight  .leaveStream {
        position: relative;
    }
    .oraganizer-backstage-dashboard .MainViewBackStage .DivChangeWidthView .ChangeWidthScroll .flexDicSeps .HeadingBstage .RigthtStreamBs .StreamRight  .leaveStream svg {
        width: 16px;
        top: 2px;
    }
    .oraganizer-backstage-dashboard .BottomStatusFoot .RighttStatuSide .SendStream {
        font-size: 12px;
        padding: 4px 8px 4px 8px !important;
    }
    .FixSideMenu {
        width: 60px;
        .StatuSideMenu {
            padding: 18px 0px;
            .MenuIconList {
                ul {
                    li {
                        margin: 0 0 12px;
                        svg {
                            width: 24px;
                            fill: #adadad;
                        }
                    }
                }
            }
            .BottomMenuList {
                margin-top: 12px;
            }
        }
    }
    .oraganizer-backstage-dashboard {
        .MainViewBackStage {
            .DivChangeWidthView {
                .ChangeWidthScroll {
                    .flexDicSeps {
                        .HeadingBstage {
                            padding: 6px 12px;
                            .ActionBcFcHeader {
                                .BsMidAction {
                                    height: 33px !important;
                                    padding: 0 4px !important;
                                    
                                    .ActionLive {
                                        margin: 0 4px !important;
                                        width: 22px;
                                        height: 22px;
                                    }
                                    .BsMic {
                                        margin-right: 0px !important;
                                        margin-left: 0px !important;
                                    }
                                    .BsSetting {
                                    width: 16px !important;
                                    }
                                    .StartStop {
                                        width: auto !important;
                                        height: auto !important;
                                    }
                                    .BGImgUpdate {
                                        width: 15px;
                                        height: 15px;
                                    }
                                }
                                .RigthtStreamBs {
                                    margin-left: 6px;
                                    .DarkGrayButton {
                                        padding: 6px 12px;
                                        font-size: 12px;
                                    }
                                }                                
                            }
                            .RigthtStreamBs {
                                .StreamRight {
                                    .leaveStreamDark {
                                        padding: 6px 8px 6px 12px;
                                        font-size: 12px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .BottomStatusFoot {
            padding: 6px 12px;
        }
    }
    .mixhubbListLayout .WrapmixhubbList .ScrollmixhubbWrap .ListContentAdd .ListPreviewMix {
        font-size: 15px;
        width: 165px;
        height: 93px;
    }

    .screen-stage-main-div.backstage-dashboard {
        .container-fluid {
          .MainViewBackStage {
              .alignAllDiv {
                  .front-stage-stream-scroll {
                      .row {
                          .stage-user-list {
                              .video_footer {
                                  .bottom_footer {
                                      .NameExpFull {
                                          .h4 {
                                              font-size: 8px !important;
                                          }
                                      }
                                  }
                              }
                          }
                      }
                  }
              }
          }
        }
    }
   
    .screen-stage-main-div.oraganizer-backstage-dashboard {
      .container-fluid {
        .MainViewBackStage {
          .DivChangeWidthView {
            .ChangeWidthScroll.ChangeWidthView  {
              .flexDicSeps {
                .alignAllDiv {
                  .front-stage-stream-scroll {
                    .row {
                      .stage-user-list {
                          height: calc(100% - 10%) !important;
                          .video_footer {
                            .ShortName {
                              h4 {
                                font-size: 22px !important;
                                width: 50px !important;
                                height: 50px !important;
                              }
                            }
                            .bottom_footer {
                              .NameExpFull {
                                width: 100%;
                                height: 16px;
                                h4 {
                                  font-size: 8px;
                                }
                              }                        
                            }
                            .ShortName {
                                h4 {
                                  font-size: 14px !important;
                                  width: 34px !important;
                                  height: 34px !important;
                                }
                            }
                          }
                        }
                    }
                  }
                }
              }
            }
          }
        }
      } 
    }
    
    .BackstageRtStream {
        .BsHeightFixLx {
            height: calc(100vh - 235px);
            .EventInfoStage {
                height: calc(100vh - 295px);
                .tab-content {
                    .ChatInfos {
                        height: calc(100vh - 414px);
                    }
                    .EnableChat {
                        .BlueColor {
                            padding: 5px 6px;
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }
    .screen-stage-main-div.oraganizer-backstage-dashboard .container-fluid .MainViewBackStage .DivChangeWidthView .ChangeWidthScroll .flexDicSeps .alignAllDiv .front-stage-stream-scroll .row .PollQnaBase .ScreenCast h4 {
        font-size: 14px;
        padding: 10px 14px;
    }
    .BackstageRtStream {
        .BsHeightFixLx {
            .responsive-tabs-container {
                .nav-tabs {
                    li {
                        .nav {
                            .nav-link {
                                border-bottom: 2px transparent solid;
                                padding: 10px 0;
                                font-size: 14px;
                                &.active {
                                    border-bottom: 2px #1a78e5 solid;
                                    padding: 10px 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .MixSessionHeader {
        .HeaderMainTop {
            .SessionHere {
                .NameSessionGoes {
                    p {
                        font-size: 13px;
                    }
                    span {
                        font-size: 12px;
                    }
                }
            }
        }
    }
    .mixhubbListLayout .WrapmixhubbList .ScrollmixhubbWrap {
        margin: 0px 0 5px;
    }
    .ZoneEnterDiscInfo {
        .EnterZoneBooth {
            .maxHeightBooth {
                h3 {
                    font-size: 18px;
                    margin: 0 0 5px;
                    line-height: 24px;
                }
                p {
                    font-size: 13px;
                }
            }
        }
    }
    .SchedulePageEvent .SetEventSchedule .ScheduleDayStage .DayScheduleStages {
        width: 170px !important;
    }
    .SchedulePageEvent .SetEventSchedule .ScheduleDayStage .ScheduleTagsKey .TagsBtn {
        height: 36px;
    }
    .backstage-dashboard.collapse-out-frontstage {
        .MainViewBackStage {
            .FlexResizeStep {
                .SpeakerSizeShrink {
                    .fullFreeContainer {
                        display: flex;
                        align-items: center;
                        // .RemoveAllDiv {
                        //     width: 100% !important;
                        //     height: auto !important;
                        // }
                    }
                }
            }
        }
    }
    .audience-front-stage-roll.collapse-out-frontstage {
        .MainViewBackStage {
            .FlexResizeStep {
                .SpeakerSizeShrink {
                    .fullFreeContainer {
                        display: flex;
                        align-items: center;
                        width: auto !important;
                        height: 100% !important;
                        .RemoveAllDiv {
                            width: 100% !important;
                            height: auto !important;
                        }
                    }
                }
            }
        }
    }
    .audience-front-stage-roll {
        .StageFootShrink {
            width: calc(100% - 306px) !important;
        }
        .Stage-Back-Event-Info {
            .EventBtmInfo {
                h2 {
                    font-size: 16px;
                }
                p {
                    color: #333333;
                    font-size: 13px;
                    margin: 0;
                }
            }
        }
    }
    .audience-front-stage-roll.collapse-out-frontstage .go-backstage-btn {
        bottom: 12px !important;
    }
    .audience-front-stage-roll {
        .MainViewBackStage {
            height: calc(100vh - 298px) !important;
        }
        .go-backstage-btn {
            bottom: 192px !important;
            max-width: 200px !important;
            transition: all 0.5s ease;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            .RedCommonBtn {
                font-size: 16px;
                height: 35px;
                padding: 10px 20px;
            }
            .BlueinButton {
                padding: 8px 14px !important;
                font-size: 16px !important;
            }
        }
    }
    .audience-front-stage-roll.collapse-out-frontstage {
        .MainViewBackStage {
            height: calc(100vh - 118px) !important;
            .go-backstage-btn {
                bottom: 13px !important;
                max-width: 180px !important;
                transition: all 0.5s ease;
                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                .RedCommonBtn {
                    font-size: 16px;
                    height: 35px;
                    padding: 10px 20px;
                }
            }
        } 
    }
    .SpeakerStageList .EventSpeakerStage {
        width: 195px;
        min-height: 72px;
        margin: 0 6px 10px;
    }
    .audience-front-stage-roll {
        .BackstageInfoStream {
            .HeadingBstage {
                height: 54px;
            }
        }
    }
   
    .audience-front-stage-roll.collapse-out-frontstage .BackstageInfoStream .HeadingBstage {
        height: 54px;
    }
    .audience-front-stage-roll {
        .container-fluid {
            .BackstageInfoStream  {
                .HeadingBstage {
                    .collapsDivToggle {
                        top: 12px;
                    }
                }
            }
        }
    }

    .BackstageInfoStream {
        .HeadingBstage {
            padding: 6px 12px;
        }
        .streamingUsers {
            .StreamVideoFrame {
                height: 132px;
                .back-stage-stream-scroll {
                    .video_footer {
                        //width: 230px !important;
                        height: 155px !important;
                        
                    }
                    .Stage-Back-Event-Info {
                        height: 176px;
                    }
                }
            }
        }
    }
   
    .SlideCommonPopup .nav-tabs {
        padding: 8px 8px 8px;
    }
    .QnaInfos .MessageTextBox .form-control {
        padding: 14px 62px 14px 18px !important;
    }
    .MessageTextBox p {
        top: -28px;
        font-size: 14px;
    }
    .StageExtras .accordion .accordion-item .accordion-button {
        font-size: 14px;
    }
    .LivetreamBtn {
        padding: 5px 16px;
    }
    .audience-front-stage-roll.collapse-out-frontstage .StageFootShrink {
        width: calc(100% - 306px) !important;
    }
    .audience-front-stage-roll.collapse-out-frontstage {
        .MainViewBackStage {
            .FlexResizeStep {
                .SpeakerSizeShrink {
                    width: calc(100% - 306px) !important;
                }
            }
        }
    }
    .RegisterPageAdvance .container .row .LoginRegisterPage .AdvanceEventDelReg .DetailFixEventMx .registration-timezone div {
        font-size: 13px !important;
    }
    .RoomMeetingPopUp .modal-dialog .modal-content .modal-body .content-wrap .content .shrinkWhereBy {
        width: calc(100% - 305px) !important;
    }
    .BackstageInfoStream {
        .streamingUsers {
            .StreamVideoFrame {
                .back-stage-stream-scroll {
                    .video_footer {
                        .bottom_footer {
                            padding: 6px 8px;
                            .NameExpFull {
                                h4 {
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .backstage-dashboard.collapse-out-frontstage {
        .SideRightMenu {
            height: calc(100% - 104px) !important;
            ul {
                li {
                    margin: 10px 0px !important;
                    padding: 8px 8px !important;
                    ul {
                        li {
                            button {
                                svg {
                                    width: 24px;
                                    height: 24px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .backstage-dashboard {
        .SideRightMenu {
            height: calc(100% - 274px);
            ul {
                li {
                    font-size: 12px !important;
                    margin: 6px 0 !important;
                    padding: 8px 8px !important;
                    span {
                        margin: 0 0 2px;
                        svg {
                            width: 20px;
                            height: 20px;
                        }
                    }
                    .RightTopStyle {
                        ul {
                            li {
                                button {
                                    svg {
                                        width: 24px;
                                        height: 24px;
                                    }
                                    span {
                                        font-size: 12px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }


    .audience-front-stage-roll.collapse-out-frontstage {
        .container-fluid {
            .SideRightMenu {
                height: calc(100% - 112px) !important;
                ul {
                    li {
                        margin: 10px 0px !important;
                        padding: 8px 8px !important;
                        ul {
                            li {
                                button {
                                    svg {
                                        width: 24px;
                                        height: 24px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .audience-front-stage-roll {
        .container-fluid {
            .SideRightMenu {
                height: calc(100% - 274px);
                ul {
                    li {
                        font-size: 12px !important;
                        margin: 6px 0 !important;
                        padding: 8px 8px !important;
                        span {
                            margin: 0 0 2px;
                            svg {
                                width: 20px;
                                height: 20px;
                            }
                        }
                        .RightTopStyle {
                            ul {
                                li {
                                    button {
                                        svg {
                                            width: 24px;
                                            height: 24px;
                                        }
                                        span {
                                            font-size: 12px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .ZoneEnterDiscInfo {
        margin: 16px 0 0;
    }
    .scheduleTages .TagNameList {
        width: 82%;
    }
    .StageInfoBar .accordion .accordion-item .accordion-body {
        padding: 0 4px 8px;
    }
    .OnoffSwitch .MuiSwitch-root {
        width: 44px;
    }
    .SlideBarTransform .BackstageCommLt .BsHeightFixLx .StageInfoBar .accordion .accordion-item .accordion-collapse .accordion-body .ControlPanelType .ControlPanel {
        margin: 0 1px;
        font-size: 12px;
    }
    .SelectDropdown .SeleftDDl .dropdown-toggle {
        font-size: 14px;
        height: 36px;
    }
    .SchedulePageEvent .SetEventSchedule .ScheduleDayStage .ScheduleTagsKey .TagsBtn {
        font-size: 14px;
        height: 36px;
    }
    .SchedulePageEvent .SetEventSchedule .ScheduleDayStage .SelectDayStage .SelectSchedule .PepleSerchHere input {
        height: 36px;
        padding: 0 32px 0 14px;
    }
    .SchedulePageEvent .SetEventSchedule .ScheduleDayStage .SelectDayStage .SelectSchedule .PepleSerchHere .SerchIcon {
        top: 6px;
    }
    .SchedulePageEvent .SetEventSchedule .ScheduleDayStage .SelectDayStage .SelectSchedule .PepleSerchHere .SerchIcon svg {
        width: 18px;
        height: 18px;
    }
    .BackstageInfoStream .streamingUsers .StreamVideoFrame .back-stage-stream-scroll .video_footer {
        height: 118px !important;
    }
   
    .BackstageInfoStream .streamingUsers .StreamVideoFrame .back-stage-stream-scroll .video_footer .ShortName h4 {
        font-size: 20px;
        height: 45px;
        width: 45px;
    }
    .container-error {
        .ErrorInbox {
            width: 622px;
            padding: 50px 0 60px;
            .LogoWebinar {
                margin-bottom: 16px;
            }
            .PlugWebinar {
                margin-top: 0px;
            }
            .ErrorInfo {
                h3 {
                    font-size: 72px;
                }
            }
        }
        .CurveLine1 {
            width: calc(50% - 310px);
        }
        .CurveLine2 {
            width: calc(50% - 310px);
        }
    }
    .audience-front-stage-roll  {
        .container-fluid {
            .BackstageInfoStream  {
                .streamingUsers {
                    .StreamVideoFrame {
                        height: 180px;
                    }
                }
            }
        }
    }
      .RoomMeetingBoard {
        .Available3Seats {
            ul {
                width: 125px;
            }
        }
    }
    .RoomMeetingBoard {
        .Available5Seats {
            ul {
                width: 175px;
            }
        }
    }
    .RoomMeetingBoard {
        .Available5Seats {
            ul {
                width: 210px;
            }
        }
    }
    .RoomMeetingBoard {
        .Available9Seats {
            ul {
                li {
                width: 36px !important;
                height: 36px !important;
                }
            }
        }
    }
    .RoomMeetingBoard {
        .Available10Seats {
            ul {
                display: inline-grid;
                li {
                    width: 36px !important;
                    height: 36px !important;
                }
            }
        }
    }
    .RoomScheduleTable {
        .roomsColTable {
            padding-right: calc(var(--bs-gutter-x) * .3) !important;
            padding-left: calc(var(--bs-gutter-x) * .3) !important;
        }
    }
    .SeatsImgORder {
        span {
            width: 24px;
            height: 24px;
            left: 8px;
            font-size: 14px;

            &:nth-child(1) {
                left: 3px !important;
            }

            &:nth-child(2) {
                left: 6px !important;
            }
        }
    }
}
@media(min-width:1280px) and (max-width:1365px) {
	.MixhubbHeader {
        padding: 10px 0;
        .HeaderMainTop {
            .MixhubbLogo {
                max-width: 170px;
                flex: 0 0 170px;
            }
            .MixhubbMenu {
                display: flex;
                // max-width: 70%;
                // flex: 0 0 70%;
                ul {
                    li {
                        margin: 0 22px;
                    }
                }
            }
            // .MixhubbRight {
            //     max-width: 14%;
            //     flex: 0 0 14%;
            // }
        }
    }
    .SchedulePageEvent {
        .SetEventSchedule {
            .ScheduleDayStage {
                padding: 8px 0;
                padding-right: 12px;
                padding-left: 28px;
                h4 {
                    font-size: 20px;
                    svg {
                        width: 22px;
                        height: 22px;
                        margin-right: 12px;
                    }
                }
                .SelectDayStage {
                    .SelectDays {
                        .SelectDropdown {
                            .dropdown {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }

    .AllPlanSchedule {
        //padding: 25px 20px 0;
        .ScheduleCalendarInfos {
            .StageFullInfoFix {
                //margin-bottom: 38px;
               // height: 256px;
                .CalenderDateScheduleInof {
                    padding: 40px 12px 28px;
                    .StageNo {
                        padding: 0px 16px;
                    }
                    .DtStageEventDay {
                        h4 {
                            line-height: 34px;
                            font-size: 24px;
                        }
                    }
                    .ScheduleTMFx {
                        margin: 6px 0 16px;
                        p {
                            font-size: 20px;
                        }
                    }
                    .ScheduleStatusOngoing {
                        //margin: 10px 0 0;
                        label {
                            font-size: 15px;
                        }
                    }

                }
                .calenderInfoGoogle {
                    a {

                        svg {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
            .ScheduleInfoWithConts {
                width: calc(100% - 170px);
                position: relative;
                h3 {
                    margin: 0 0 5px;
                    font-size: 18px;
                }
                p {
                    font-size: 14px;
                }
                .Watch-Session {
                    .RedButton {
                        padding: 6px 14px;
                        font-size: 14px;
                    }
                    .BlueinButton {
                        padding: 6px 14px;
                        font-size: 14px;
                    }
                }
            }
            .SpeakerRowList {
                .EventSpeakerExp {
                    padding: 10px 10px;
                    //min-height: 75px;
                    .ImgSpeakerBg {
                        width: 45px;
                        height: 45px;
                    }
                    .SpeakerNamePos {
                        margin-left: 12px;
                        padding: 3px 0;
                        h4 {
                            font-size: 14px;
                        }
                        p {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
        .RoomMeetingBoard {
            .Available10Seats {
                ul {
                    li {
                        margin: 4px !important;
                    }
                    .SelectSeat {
                        &:first-child {
                           .UserInfoDetail {
                                left: -26px;
                           }
                           .bottomArrow {
                               left: -72px !important;
                           }
                        }
                        &:nth-child(6) {
                            .UserInfoDetail {
                                left: -26px;
                           }
                           .bottomArrow {
                               left: -72px !important;
                           }
                         }
                    }
                }
            }
        }
    }


    .MixhubbStage {
        overflow-y: inherit;
        .StageMixhubbEvent {
            .SetEventInfo {
                padding: 32px 20px 20px;
                .SetupStatus{
                    span {
                        padding: 2px 28px;
                    }
                }
                h3 {
                    font-size: 22px;
                }
                .AboutSetupInfo {
                    background-color: var(--light-gray);
                    padding: 15px 15px;
                    margin: 25px 0 25px;
                    h4 {
                        font-size: 15px;
                    }
                    p {
                        font-size: 14px;
                    }
                }
    
                .ExpoSetupInfo {
                    min-height: 88px;
                }
                .EnterStageBtn {
                    padding: 10px 28px;
                }
            }
        }
    }
    .RoomMeetingBoard {
        .RoomEventLogo {
            width: 100px;
        }
    }



    .NetwokingProfileQuick {
        margin-top: 20px;
        .ProfilePicInfo {
            width: 55px;
            height: 55px;
        }
        .ProfileTxtInfo {
            width: 58%;
            position: relative;
            h4 {
                font-size: 15px;
                font-weight: 600;
                margin: 0 0 2px;
            }
            p {
                font-size: 13px;
            }
        }
    }


    .QuickShufflingMatch {
        background-color: var(--light-gray);
        padding: 22px 25px;
        border-radius: 16px;
        h2 {
            font-size: 20px;
        }
        p {
            font-size: 14px;
            margin: 0 0 4px;
            font-weight: 400;
        }
        .MatchQuickGIF {
            padding: 22px 28px;
        }
        .RedButton {
            width: 160px;
            margin: 0px auto;
            display: table;
            font-size: 15px;
            font-weight: 700;
            svg {
                width: 20px;
                height: 20px;
            }
        }
    }

    .RegisterPageAll {
        .LoginRegisterPage {
            .MixhubbExpDae {
                h1 {
                    font-size: 25px;
                    margin: 0 0 12px;
                }
            }
        }
    }
    .RegisterPageAll {
        .LoginRegisterPage {
            .MixhubbExpDae {
                p {
                    color: #333333;
                    font-size: 25px;
                    margin: 0;
                    font-weight: 400;
                }
                .event-timezone {
                    font-size: 16px;
                }
            }
        }
    }
    .RegisterPageAll {
        .LoginRegisterPage {
            .EnterEventAction {
                h2 {
                    font-size: 24px;
                }
            }
        }
    }
    .RegisterPageAll {
        .LoginRegisterPage {
            .EnterEventAction {
                margin: 30px auto 0;
                width: 300px;
            }
        }
    }
    // .RightTopStyle {
    //     height: 56px;
    //     padding: 5px 25px 5px;
    //     ul {
    //         li {
    //             margin: 0 8px;
    //             button {
    //                 svg {
    //                     width: 22px;
    //                     height: 21px;
    //                 }
    //             }
    //         }
    //     }
    // }

    .screen-stage-main-div .MainViewBackStage .front-stage-stream-scroll .row .stage-user-list .RightSvgIcon .mh-cursor-pointer {
        top: 4px;
        left: 4px;
        width: 20px;
        height: 20px;
        padding: 4px !important;
    }
    .screen-stage-main-div .MainViewBackStage .front-stage-stream-scroll .row .stage-user-list .video_footer .bottom_footer h4 {
        font-size: 12px;
    }
    .screen-stage-main-div.oraganizer-backstage-dashboard.collapse-out-frontstage .MainViewBackStage .front-stage-stream-scroll .row .stage-user-list .video_footer .bottom_footer h4 {
        font-size: 12px;
    }
    .screen-stage-main-div.oraganizer-backstage-dashboard .MainViewBackStage .front-stage-stream-scroll .row .stage-user-list .video_footer .bottom_footer h4 {
        font-size: 12px;
    }

    .screen-stage-main-div.oraganizer-backstage-dashboard .MainViewBackStage .front-stage-stream-scroll .row .stage-user-list .video_footer .ShortName h4 {
        font-size: 18px;
        width: 38px;
        height: 38px;
    }
    .screen-stage-main-div.oraganizer-backstage-dashboard.collapse-out-frontstage .MainViewBackStage .front-stage-stream-scroll .row .stage-user-list .video_footer .ShortName h4 {
        font-size: 28px;
        width: 65px;
        height: 65px;
    }
    .BackIconAction .ActionLive .widthSvg {
        padding: 2px 2px;
    }
    .BackIconAction .ActionLive {
            width: 18px;
            height: 18px;
    }
    .screen-stage-main-div.oraganizer-backstage-dashboard .MainViewBackStage .front-stage-stream-scroll .row .stage-user-list .video_footer .BackIconAction {
        top: 5px !important;
        right: 2px !important;
    }
    .screen-stage-main-div.oraganizer-backstage-dashboard .MainViewBackStage .front-stage-stream-scroll .row .stage-user-list .video_footer .BackIconAction .ActionLive {
        width: 18px !important;
        height: 18px !important;
    }


    .PeopleChatPopup, .ChatMixInfoMixhubb {
        width: 300px !important;
    }
    .MessageTextBox {
        .MsgEmojis {
            .EmojiIconsIn {
                top: -218px;
                width: 272px;
                left: -17px;
                .EmojiMainIco {
                    ul {
                        li {
                            width: 32px;
                            height: 36px;
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
    .BackStageSessionInfo {
        width: 340px;
    }
    
    .EventChatAccords .responsive-tabs-container h2, .EventChatAccords .responsive-tabs-container .h2 {
        font-size: 16px;
    }
    .MessageTextBox .form-control {
        height: 50px;
        padding: 12px 62px 12px 50px;
    }
    .QnaInfos .MessageTextBox .form-control {
        height: 50px;
        padding: 14px 62px 14px 14px !important;
    }
    .MessageTextBox {
        .MsgEmojis {
            left: 16px;
            top: 12px;
            width: 20px;
            height: 20px;
        }
        .form-control {
            height: 50px;
            padding: 14px 62px 14px 50px;
        }
        .MsgSendIcon {
            width: 38px;
            height: 38px;
        }
        .MsgSendIcon {
            width: 38px;
            height: 38px;
        }
    }

    .BackStageSessionInfo .InfoStageOrder .tab-content .StageInfoBar .StatEventSchedule {
        padding: 0 0;
    }
    .BackStageSessionInfo {
        .InfoStageOrder {
            .SendRequestNow {
                .BroadcastStart {
                    .RedButton {
                        padding: 8px 0 !important;
                    }
                }
                .RedButton {
                    padding: 8px 0 !important;
                }
            }
        }
    }
    .UpdateOvelayImgVodeo .minHeightBox {
        height: 151px !important;
        min-height: 151px !important;
    }
    .MixhubbStage .oraganizer-backstage-dashboard .BackstageInfoStream {
        width: calc(100% - 365px);
    }
    .oraganizer-backstage-dashboard .MainViewBackStage {
        width: calc(100% - 366px);
        height: calc(100vh - 316px) !important;
    }
    // .RightTopStyle {
    //     padding: 6px 20px 6px;
    //     ul {
    //         li {
    //             margin: 0 7px;
    //             button {
    //                 svg {
    //                     width: 20px;
    //                     height: 19px;
    //                 }
    //             }
    //         }
    //     }
    // } 
    .BackstageInfoStream {
        .HeadingBstage {
            h3 {
                font-size: 18px;
            }
            .BsMidAction {
                height: 34px;
                .ActionLive {
                    margin: 0 4px;
                    width: 24px;
                    height: 24px;
                }
                .BGImgUpdate {
                    margin: 0 4px;
                    width: 18px;
                    height: 18px;
                }
                .StartStop {
                    width: auto;
                    height: auto;
                    margin-right: 0;
                    position: relative;
                }
            }
        }
    }
    .DarkGrayButton {
        padding: 6px 14px;
        font-size: 12px;
    }
    .BackStageSessionInfo .InfoStageOrder .tab-content .StageInfoBar .StatEventSchedule h3 {
        font-size: 16px;
    }
    .SelectDropdown {
        .dropdown {
            .css-2b097c-container {
                .css-yk16xz-control {
                    height: 42px;
                }
                .css-1pahdxg-control {
                    height: 42px;
                }
            }
        }
    }
    .SelectDropdown .css-1hb7zxy-IndicatorsContainer::after {
        width: 11px;
        height: 11px;
    }
    .SelectDropdown .css-1hb7zxy-IndicatorsContainer::after {
        top: 12px;
    }
    .SlideCommonPopup {
        .nav-tabs {
            padding: 8px 8px 8px;
            li {
                .nav {
                    .nav-link {
                        padding: 3px 10px;
                        font-size: 13px;
                        &.active {
                            padding: 3px 10px;
                        }
                    }
                }
            }
        }
    }
    .BackstageInfoStream .HeadingBstage .BsMidAction .BsEnd .RedCommonBtn {
        padding: 4px 18px;
        font-size: 15px;
    }
    .collapsDivToggle {
        width: 30px;
        height: 30px;
        top: 10px;
    }
    .collapsDivToggle .CastingToggle:after {
        right: 9px;
        top: 8px;
    }
    .collapsDivToggle .collapse-in-backstage:after {
        right: 9px;
        top: 11px;
    }
    

    .ChatMixInfoMixhubb .PeopleConvrtsList .peopleInfos .ChatInfos .SenderMassage .SenderMessage h4 {
        font-weight: 500;
    }
    .RegisterPageAll {
        .LeftImgBlock {
            .LoginEventLogo {
                top: 15px;
                left: 15px;
                width: 170px;
            }
        }
    }
    .BackstageRtStream {
        right: 4px;
        width: 280px;
    }
    .SlideBarTransform {
        width: 280px;
    }
    .BackstageRtStream {
        .BsHeightFixLx {
            height: calc(100vh - 235px);
            .EventInfoStage {
                height: calc(100vh - 298px);
                .tab-content {
                    .ChatInfos {
                        height: calc(100vh - 418px);
                    }
                    .EnableChat {
                        .BlueColor {
                            padding: 6px 12px;
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }

    .FixSideMenu {
        width: 62px;
        .StatuSideMenu {
            padding: 18px 0px;
            .MenuIconList {
                ul {
                    li {
                        svg {
                            width: 28px;
                        }
                    }
                }
            }
        }
    }
    
    .ZoneEnterDiscInfo {
        .EnterZoneBooth {
            .maxHeightBooth {
                h3 {
                    font-size: 18px;
                    margin: 0 0 5px;
                    line-height: 24px;
                }
                p {
                    font-size: 13px;
                }
            }
        }
    }
    .FilesInfos {
        .FilesActionDoc {
            .FileTypeNmae {
                margin-left: 14px;
                p {
                    font-size: 13px;
                }
                .FilesPreview {
                    .filePreview {
                        padding: 4px 26px;
                        font-size: 12px;
                    }
                }
                .FilesDownload {
                    .fileDwnload {
                        padding: 6px 14px;
                        font-size: 12px;
                    }
                }
            }
            .thumbnail {
                width: 55px;
                height: 55px;
            }
        }
    }
    
    .SchedulePageEvent {
        .SetEventSchedule {
            .ScheduleDayStage {
                .DayScheduleStages {
                    .SelectDropdown {
                        .SeleftDDl {
                            .dropdown {
                                .dropdown-toggle {
                                    font-size: 14px;
                                    height: 36px;
                                    &::before {
                                        top: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .SchedulePageEvent .SetEventSchedule .ScheduleDayStage .DayScheduleStages {
        width: 170px !important;
    }
    .SchedulePageEvent .SetEventSchedule .ScheduleDayStage .ScheduleTagsKey .TagsBtn {
        height: 36px;
        font-size: 14px;
    }
    .backstage-dashboard.collapse-out-frontstage {
        .MainViewBackStage {
            .FlexResizeStep {
                .SpeakerSizeShrink {
                    .fullFreeContainer {
                        display: flex;
                        align-items: center;
                        width: 100% !important;
                        height: 100% !important;
                        .RemoveAllDiv {
                            width: 100% !important;
                            height: 100% !important;
                        }
                    }
                }
            }
        }
    }
    .backstage-dashboard {
        .MainViewBackStage {
            height: calc(100vh - 242px) !important;
            .FlexResizeStep {
                .SpeakerSizeShrink {
                    width: calc(100% - 306px) !important;
                    .fullFreeContainer {
                        display: flex;
                        align-items: center;
                        width: 100% !important;
                        height: 100% !important;
                        .RemoveAllDiv {
                            width: auto !important;
                            height: 100% !important;
                        }
                    }
                }
            }
            .StageFootShrink {
                width: calc(100% - 306px) !important;
            }
        }
        .StageFootShrink {
            width: calc(100% - 306px) !important;
        }
    }
    .backstage-dashboard.collapse-out-frontstage .MainViewBackStage {
        height: calc(100vh - 122px) !important;
    }
    
    .audience-front-stage-roll.collapse-out-frontstage {
        .MainViewBackStage {
            .FlexResizeStep {
                .SpeakerSizeShrink {
                    .fullFreeContainer {
                        display: flex;
                        align-items: center;
                        width: auto !important;
                        height: 100% !important;
                        .RemoveAllDiv {
                            width: 100% !important;
                            height: auto !important;
                        }
                    }
                }
            }
        }
    }


    .audience-front-stage-roll .MainViewBackStage .FlexResizeStep .SpeakerSizeShrink {
        width: calc(100% - 304px) !important;
    }
    .audience-front-stage-roll {
        .StageFootShrink {
            width: calc(100% - 304px) !important;
        }
        .Stage-Back-Event-Info {
            .EventBtmInfo {
                h2 {
                    font-size: 16px;
                }
                p {
                    color: #333333;
                    font-size: 13px;
                    margin: 0;
                }
            }
        }
    }

    
    .SpeakerStageList .EventSpeakerStage {
        width: 184px;
        min-height: 72px;
        margin: 0 6px 10px;
    }
    .audience-front-stage-roll {
        .container-fluid {
            .BackstageInfoStream  {
                .HeadingBstage {
                    .collapsDivToggle {
                        top: 14px;
                    }
                }
            }
        }
    }
    .RoomMeetingPopUp .modal-dialog .modal-content .modal-body .content-wrap .content .shrinkWhereBy {
        width: calc(100% - 305px) !important;
    }
    .BackstageInfoStream {
        .streamingUsers {
            .StreamVideoFrame {
                .back-stage-stream-scroll {
                    .video_footer {
                        .bottom_footer {
                            padding: 6px 8px;
                            .NameExpFull {
                                h4 {
                                    font-size: 13px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .BackstageInfoStream {
        .HeadingBstage {
            padding: 6px 12px;
        }
        .streamingUsers {
            .StreamVideoFrame {
                height: 130px;
                .back-stage-stream-scroll {
                    .video_footer {
                        //width: 230px !important;
                        height: 155px !important;
                    }
                }
            }
        }
    }
    .backstage-dashboard.collapse-out-frontstage {
        .SideRightMenu {
            height: calc(100% - 114px) !important;
            ul {
                li {
                    margin: 10px 0px !important;
                    padding: 8px 8px !important;
                    ul {
                        li {
                            button {
                                svg {
                                    width: 24px;
                                    height: 24px;
                                }
                            }
                            .RightTopStyle {
                                ul {
                                    li {
                                        button {
                                            svg {
                                                width: 24px;
                                                height: 24px;
                                            }
                                            span {
                                                font-size: 12px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .backstage-dashboard {
        .SideRightMenu {
            height: calc(100% - 282px);
            ul {
                li {
                    font-size: 12px !important;
                    margin: 6px 0 !important;
                    padding: 6px 8px !important;
                    span {
                        margin: 0 0 2px;
                        svg {
                            width: 22px;
                            height: 22px;
                        }
                    }
                    .RightTopStyle {
                        ul {
                            li {
                                button {
                                    svg {
                                        width: 26px;
                                        height: 26px;
                                    }
                                    span {
                                        font-size: 12px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .audience-front-stage-roll.collapse-out-frontstage {
        .container-fluid {
            .SideRightMenu {
                height: calc(100% - 114px) !important;
                ul {
                    li {
                        margin: 10px 0px !important;
                        padding: 8px 8px !important;
                        ul {
                            li {
                                button {
                                    svg {
                                        width: 24px;
                                        height: 24px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .audience-front-stage-roll {
        .container-fluid {
            .SideRightMenu {
                height: calc(100% - 282px);
                ul {
                    li {
                        font-size: 12px !important;
                        margin: 6px 0 !important;
                        padding: 6px 8px !important;
                        span {
                            margin: 0 0 2px;
                            svg {
                                width: 22px;
                                height: 22px;
                            }
                        }
                        .RightTopStyle {
                            ul {
                                li {
                                    button {
                                        svg {
                                            width: 26px;
                                            height: 26px;
                                        }
                                        span {
                                            font-size: 12px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .StageExtras .accordion .accordion-item .accordion-button {
        font-size: 14px !important;
    }
    .LivetreamBtn {
        padding: 6px 18px;
    }
    .SlideBarTransform .BackstageCommLt .BsHeightFixLx .StageInfoBar .accordion .accordion-item .accordion-collapse .accordion-body .ControlPanelType .ControlPanel {
        display: inline-block;
        margin: 0 -1px;
        font-size: 12px;
    }
    .StageExtras .accordion .accordion-item .collapsed::after {
        transform: rotate(135deg);
        top: 12px;
    }
    .StageExtras .accordion .accordion-item .accordion-button::after {
        width: 10px;
        height: 10px;
    }
    .FixSideMenu .StatuSideMenu .MenuIconList ul li {
        margin: 0 0 12px;
    }
    .oraganizer-backstage-dashboard {
        .MainViewBackStage {
            .DivChangeWidthView {
                .ChangeWidthScroll {
                    .flexDicSeps {
                        .HeadingBstage {
                            padding: 6px 14px;
                            .ActionBcFcHeader {
                                .BsMidAction {
                                    height: 33px !important;
                                    padding: 0 4px !important;
                                    
                                    .ActionLive {
                                        margin: 0 5px !important;
                                        width: 22px;
                                        height: 22px;
                                    }
                                    .BGImgUpdate {
                                        width: 15px;
                                        height: 15px;
                                    }
                                    .BsMic {
                                        margin-right: 0px !important;
                                        margin-left: 0px !important;
                                    }
                                    .BsSetting {
                                    width: 16px !important;
                                    }
                                    .StartStop {
                                        width: auto !important;
                                        height: auto !important;
                                    }
                                }
                                .RigthtStreamBs {
                                    margin-left: 6px;
                                    .DarkGrayButton {
                                        padding: 6px 12px;
                                        font-size: 12px;
                                    }
                                }                                
                            }
                            .RigthtStreamBs {
                                .StreamRight {
                                    .leaveStreamDark {
                                        padding: 6px 8px 6px 12px;
                                        font-size: 12px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .BottomStatusFoot {
            padding: 6px 12px;
        }
    }
    .Stage-Back-Event-Info {
        height: 150px
    }
    .SchedulePageEvent .SetEventSchedule .ScheduleDayStage .SelectDayStage .SelectSchedule .PepleSerchHere input {
        padding: 0 32px 0 14px;
    }
    .SlideBarTransform .BackstageCommLt .BsHeightFixLx .UploadBroadcastImgs .UpdateOvelayImgVodeo p {
        font-size: 11px;
    }
    .UpdateOvelayImgVodeo .minHeightBox .UploaderFiles .UploadLabel {
        font-size: 11px;
    }

    .BackstageInfoStream .streamingUsers .StreamVideoFrame .back-stage-stream-scroll .video_footer {
        height: 118px !important;
    }
    .audience-front-stage-roll .MainViewBackStage {
        height: calc(100vh - 276px)!important;
    }
    .BackstageInfoStream .streamingUsers .StreamVideoFrame .back-stage-stream-scroll .video_footer .ShortName h4 {
        font-size: 20px;
        height: 45px;
        width: 45px;
    }
    .container-error {
        .ErrorInbox {
            width: 622px;
            padding: 50px 0 60px;
            .LogoWebinar {
                margin-bottom: 16px;
            }
            .PlugWebinar {
                margin-top: 0px;
            }
            .ErrorInfo {
                h3 {
                    font-size: 65px;
                }
            }
        }
        .CurveLine1 {
            width: calc(50% - 310px);
        }
        .CurveLine2 {
            width: calc(50% - 310px);
        }
    }
    .audience-front-stage-roll  {
        .container-fluid {
            .BackstageInfoStream  {
                .streamingUsers {
                    .StreamVideoFrame {
                        height: 162px;
                    }
                }
            }
        }
      }
      .audience-front-stage-roll {
        .go-backstage-btn {
            bottom: 172px !important;
            max-width: 192px !important;
            transition: all 0.5s ease;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            .BlueinButton {
              padding: 8px 14px;
            }
            .RedCommonBtn {
              font-size: 16px;
              height: 38px;
            }
          }
      }
      .SeatsImgORder {
        span {
            width: 24px;
            height: 24px;
            left: 9px;
            font-size: 14px;

            &:nth-child(1) {
                left: 4px !important;
            }

            &:nth-child(2) {
                left: 7px !important;
            }
        }
    }
}

@media(min-width:1200px) and (max-width:1279px) {
    
    .MixhubbHeader {
        padding: 10px 0;
        .HeaderMainTop {
            .MixhubbLogo {
                max-width: 170px;
                flex: 0 0 170px;
            }
            .MixhubbMenu {
                display: flex;
                // max-width: 68%;
                // flex: 0 0 68%;
                ul {
                    li {
                        margin: 0 16px;
                        .nav-mixhubb {
                            .IconStyle {
                                width: 32px;
                                height: 32px;
                            }
                        }
                    }
                }
            }
            .MixhubbRight {
                // max-width: 14%;
                // flex: 0 0 14%;
                .headIcon {
                    .chatConvIcon {
                        width: 28px !important;
                        height: 28px !important;
                    }
                }
            }
        }
    }

    .SchedulePageEvent {
        .SetEventSchedule {
            .ScheduleDayStage {
                padding: 8px 0;
                padding-right: 10px;
                padding-left: 28px;
                h4 {
                    font-size: 20px;
                }
                .SelectDayStage {
                    .SelectDays {
                        .SelectDropdown {
                            .dropdown {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }

    .AllPlanSchedule {
        //padding: 25px 20px 0;
        .ScheduleCalendarInfos {
            .StageFullInfoFix {
                //margin-bottom: 38px;
                //height: 256px;
                .CalenderDateScheduleInof {
                    padding: 40px 12px 28px;
                    .StageNo {
                        padding: 0px 16px;
                    }
                    .DtStageEventDay {
                        h4 {
                            line-height: 34px;
                            font-size: 24px;
                        }
                    }
                    .ScheduleTMFx {
                        margin: 6px 0 16px;
                        p {
                            font-size: 20px;
                        }
                    }
                    .ScheduleStatusOngoing {
                        //margin: 10px 0 0;
                        label {
                            font-size: 15px;
                        }
                    }

                }
                .calenderInfoGoogle {
                    a {

                        svg {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
            .ScheduleInfoWithConts {
                width: calc(100% - 170px);
                position: relative;
                h3 {
                    margin: 0 0 5px;
                    font-size: 18px;
                }
                p {
                    font-size: 14px;
                }
                .Watch-Session {
                    .RedButton {
                        padding: 6px 14px;
                        font-size: 14px;
                    }
                    .BlueinButton {
                        padding: 6px 14px;
                        font-size: 14px;
                    }
                }
            }
            .SpeakerRowList {
                .EventSpeakerExp {
                    padding: 10px 10px;
                    //margin-right: 15px;
                    //min-height: 84px;
                    .ImgSpeakerBg {
                        width: 45px;
                        height: 45px;
                    }
                    .SpeakerNamePos {
                        margin-left: 12px;
                        padding: 3px 0;
                        h4 {
                            font-size: 14px;
                        }
                        p {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
        .RoomMeetingBoard {
            .Available10Seats {
                ul {
                    li {
                        margin: 4px !important;
                    }
                    .SelectSeat {
                        &:first-child {
                           .UserInfoDetail {
                                left: -26px;
                           }
                           .bottomArrow {
                               left: -72px !important;
                           }
                        }
                        &:nth-child(6) {
                            .UserInfoDetail {
                                left: -26px;
                           }
                           .bottomArrow {
                               left: -72px !important;
                           }
                         }
                    }
                }
            }
        }
    }

    

    .MixhubbStage {
        overflow-y: inherit;
        .StageMixhubbEvent {
            .SetEventInfo {
                padding: 32px 20px 20px;
                .SetupStatus{
                    span {
                        padding: 2px 28px;
                    }
                }
                h3 {
                    font-size: 22px;
                }
                .AboutSetupInfo {
                    background-color: var(--light-gray);
                    padding: 15px 15px;
                    margin: 25px 0 25px;
                    h4 {
                        font-size: 15px;
                    }
                    p {
                        font-size: 14px;
                    }
                }
    
                .ExpoSetupInfo {
                    min-height: 88px;
                }
                .EnterStageBtn {
                    padding: 10px 28px;
                }
            }
        }
    }

    .RoomMeetingBoard {
        .RoomEventLogo {
            width: 100px;
        }
    }
    .RoomMeetingBoard {
        .roomChairBlock {
            ul {
                li {
                    margin: 5px;
                    width: 42px;
                    height: 42px;
                }
            }
        }
    }


    .NetwokingProfileQuick {
        margin-top: 20px;
        .ProfilePicInfo {
            width: 55px;
            height: 55px;
        }
        .ProfileTxtInfo {
            width: 58%;
            position: relative;
            h4 {
                font-size: 15px;
                font-weight: 600;
                margin: 0 0 2px;
            }
            p {
                font-size: 13px;
            }
        }
    }


    .QuickShufflingMatch {
        background-color: var(--light-gray);
        padding: 22px 25px;
        border-radius: 16px;
        h2 {
            font-size: 20px;
        }
        p {
            font-size: 14px;
            margin: 0 0 4px;
            font-weight: 400;
        }
        .MatchQuickGIF {
            padding: 22px 28px;
        }
        .RedButton {
            width: 160px;
            margin: 0px auto;
            display: table;
            font-size: 15px;
            font-weight: 700;
            svg {
                width: 20px;
                height: 20px;
            }
        }
    }


    .RegisterPageAll {
        .LoginRegisterPage {
            .MixhubbExpDae {
                h1 {
                    font-size: 25px;
                    margin: 0 0 12px;
                }
            }
        }
    }
    .RegisterPageAll {
        .LoginRegisterPage {
            .MixhubbExpDae {
                p {
                    font-size: 20px;
                }
                .event-timezone {
                    font-size: 16px;
                    margin: 4px 0 0;
                }
            }
        }
    }
    .RegisterPageAll {
        .LoginRegisterPage {
            .EnterEventAction {
                h2 {
                    font-size: 24px;
                }
            }
        }
    }
    .RegisterPageAll {
        .LoginRegisterPage {
            .EnterEventAction {
                margin: 30px auto 0;
                width: 300px;
            }
        }
    }
    .RegisterPageAll {
        .LoginRegisterPage {
            .EnterEventAction {
                .SocialLoginType {
                    ul {
                        li {
                            display: inline-block;
                            margin: 0 10px;
                            .img-fluid {
                                cursor: pointer;
                                width: 50px;
                                height: 50px;
                            }
                            svg {
                                width: 50px;
                                height: 50px;
                              }
                        }
                    }
                }
            }
        }
    }
    .BackIconAction .ActionLive {
        width: 25px;
        height: 25px;
    }
    .screen-stage-main-div .MainViewBackStage .front-stage-stream-scroll .row .stage-user-list .RightSvgIcon .mh-cursor-pointer {
        top: 4px;
        left: 4px;
        width: 20px;
        height: 20px;
        padding: 4px !important;
    }
    .screen-stage-main-div .MainViewBackStage .front-stage-stream-scroll .row .stage-user-list .video_footer .bottom_footer h4 {
        font-size: 12px;
    }
    .screen-stage-main-div.oraganizer-backstage-dashboard.collapse-out-frontstage .MainViewBackStage .front-stage-stream-scroll .row .stage-user-list .video_footer .bottom_footer h4 {
        font-size: 12px;
    }
    .screen-stage-main-div.oraganizer-backstage-dashboard .MainViewBackStage .front-stage-stream-scroll .row .stage-user-list .video_footer .bottom_footer h4 {
        font-size: 12px;
    }

    .screen-stage-main-div.oraganizer-backstage-dashboard .MainViewBackStage .front-stage-stream-scroll .row .stage-user-list .video_footer .ShortName h4 {
        font-size: 18px;
        width: 38px;
        height: 38px;
    }
    .screen-stage-main-div.oraganizer-backstage-dashboard.collapse-out-frontstage .MainViewBackStage .front-stage-stream-scroll .row .stage-user-list .video_footer .ShortName h4 {
        font-size: 28px;
        width: 65px;
        height: 65px;
    }
    .BackIconAction {
        top: 4px;
        right: 4px;
    }
    .BackIconAction .ActionLive .widthSvg {
        padding: 4px 4px;
    }
    .BackIconAction .ActionLive {
            width: 22px;
            height: 22px;
    }
    .screen-stage-main-div.oraganizer-backstage-dashboard .MainViewBackStage .front-stage-stream-scroll .row .stage-user-list .video_footer .BackIconAction {
        top: 5px !important;
        right: 2px !important;
    }
    .screen-stage-main-div.oraganizer-backstage-dashboard .MainViewBackStage .front-stage-stream-scroll .row .stage-user-list .video_footer .BackIconAction .ActionLive {
        width: 24px !important;
        height: 24px !important;
    }

    .PeopleChatPopup, .ChatMixInfoMixhubb {
        width: 330px !important;
    }
    .BackStageSessionInfo {
        width: 340px;
    }
    .EventChatAccords .responsive-tabs-container h2, .EventChatAccords .responsive-tabs-container .h2 {
        font-size: 16px;
    }
    .MessageTextBox .form-control {
        height: 50px;
        padding: 12px 62px 12px 50px;
    }
   
    .MessageTextBox {
        .MsgEmojis {
            left: 16px;
            top: 12px;
            width: 20px;
            height: 20px;
        }
        .form-control {
            height: 50px;
            padding: 14px 62px 14px 50px;
        }
        .MsgSendIcon {
            width: 38px;
            height: 38px;
        }
        .MsgSendIcon {
            width: 38px;
            height: 38px;
        }
    }
    .BackStageSessionInfo .InfoStageOrder .tab-content .StageInfoBar .StatEventSchedule {
        padding: 0 0;
    }
    .BackStageSessionInfo {
        .InfoStageOrder {
            .SendRequestNow {
                .BroadcastStart {
                    .RedButton {
                        padding: 8px 0 !important;
                    }
                }
                .RedButton {
                    padding: 8px 0 !important;
                }
            }
        }
    }
    .UpdateOvelayImgVodeo .minHeightBox {
        height: 151px !important;
        min-height: 151px !important;
    }
    .MixhubbStage .oraganizer-backstage-dashboard .BackstageInfoStream {
        width: calc(100% - 365px);
    }
    .oraganizer-backstage-dashboard .MainViewBackStage {
        width: calc(100% - 365px);
        height: calc(100vh - 316px) !important;
    }
    // .RightTopStyle {
    //     padding: 6px 20px 6px;
    //     ul {
    //         li {
    //             margin: 0 7px;
    //             button {
    //                 svg {
    //                     width: 20px;
    //                     height: 19px;
    //                 }
    //             }
    //         }
    //     }
    // } 
    .BackstageInfoStream {
        .HeadingBstage {
            h3 {
                font-size: 18px;
            }
            .BsMidAction {
                height: 34px;
                .ActionLive {
                    margin: 0 4px;
                    width: 24px;
                    height: 24px;
                }
                .BGImgUpdate {
                    margin: 0 4px;
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }
    .DarkGrayButton {
        padding: 6px 14px;
        font-size: 12px;
    }
    .BackStageSessionInfo .InfoStageOrder .tab-content .StageInfoBar .StatEventSchedule h3 {
        font-size: 16px;
    }
    .SelectDropdown {
        .dropdown {
            .css-2b097c-container {
                .css-yk16xz-control {
                    height: 46px;
                }
                .css-1pahdxg-control {
                    height: 46px;
                }
            }
        }
    }
    .SelectDropdown .css-1hb7zxy-IndicatorsContainer::after {
        top: 12px;
    }
    .SlideCommonPopup .nav-tabs li .nav .nav-link {
        padding: 3px 12px;
    }
    .BackstageInfoStream .HeadingBstage .BsMidAction .BsEnd .RedCommonBtn {
        padding: 4px 18px;
        font-size: 15px;
    }
    .collapsDivToggle {
        width: 30px;
        height: 30px;
        top: 10px;
    }
    .collapsDivToggle .CastingToggle:after {
        right: 9px;
        top: 8px;
    }
    .collapsDivToggle .collapse-in-backstage:after {
        right: 9px;
        top: 11px;
    }
    .backstage-dashboard {
        .MainViewBackStage {
            height: calc(100vh - 242px) !important;
        }
        .FlexResizeStep {
            .SpeakerSizeShrink {
                width: calc(100% - 335px) !important;
            }
        }
        .StageFootShrink {
            width: calc(100% - 335px) !important;
        }
    }

    .ChatMixInfoMixhubb .PeopleConvrtsList .peopleInfos .ChatInfos .SenderMassage .SenderMessage h4 {
        font-weight: 500;
    }
    
    .MessageTextBox {
        .MsgEmojis {
            .EmojiIconsIn {
                top: -218px;
                width: 298px;
                left: -17px;
                .EmojiMainIco {
                    ul {
                        li {
                            width: 32px;
                            height: 36px;
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
    .RegisterPageAll {
        .LeftImgBlock {
            .LoginEventLogo {
                top: 15px;
                left: 15px;
                width: 170px;
            }
        }
    }
    .SchedulePageEvent {
        .SetEventSchedule {
            .ScheduleDayStage {
                h4 {
                    svg {
                        width: 22px;
                        height: 22px;
                        margin-right: 12px;
                    }
                }
            }
        }
    }
    .SchedulePageEvent .SetEventSchedule .ScheduleDayStage .DayScheduleStages {
        width: 170px !important;
    }
    .backstage-dashboard.collapse-out-frontstage {
        .MainViewBackStage {
            .FlexResizeStep {
                .SpeakerSizeShrink {
                    .fullFreeContainer {
                        display: flex;
                        align-items: center;
                        width: 100% !important;
                        height: auto !important;
                        .RemoveAllDiv {
                            width: 100% !important;
                            height: auto !important;
                        }
                    }
                }
            }
        }
    }
    .backstage-dashboard {
        .MainViewBackStage {
            .FlexResizeStep {
                .SpeakerSizeShrink {
                    width: calc(100% - 335px) !important;
                    .fullFreeContainer {
                        display: flex;
                        align-items: center;
                        width: 100% !important;
                        height: 100% !important;
                        .RemoveAllDiv {
                            width: auto !important;
                            height: 100% !important;
                        }
                    }
                }
            }
            .StageFootShrink {
                width: calc(100% - 335px) !important;
            }
        }
        .StageFootShrink {
            width: calc(100% - 335px) !important;
        }
    }
    .backstage-dashboard.collapse-out-frontstage .MainViewBackStage {
        height: calc(100vh - 120px) !important;
    }
    .BackstageInfoStream .HeadingBstage {
        height: 48px;
    }
    .audience-front-stage-roll.collapse-out-frontstage { 
        .MainViewBackStage {
            .FlexResizeStep {
                .SpeakerSizeShrink {
                    .fullFreeContainer {
                        display: flex;
                        align-items: center;
                        width: 100% !important;
                        height: auto !important;
                        .RemoveAllDiv {
                            width: 100% !important;
                            height: auto !important;
                        }
                    }
                }
            }
        }
    }
    .audience-front-stage-roll.collapse-out-frontstage .MainViewBackStage {
        height: calc(100vh - 118px) !important;
    }
    .audience-front-stage-roll .MainViewBackStage {
        height: calc(100vh - 294px) !important;
    }
    .audience-front-stage-roll .MainViewBackStage .FlexResizeStep .SpeakerSizeShrink {
        width: calc(100% - 334px) !important;
    }
    .audience-front-stage-roll .StageFootShrink {
        width: calc(100% - 334px) !important;
    }
    .SpeakerStageList {
       //margin: 0 0 12px;
        .EventSpeakerStage {
            width: 47%;
            min-height: 72px;
            margin: 6px 6px 4px;
        }
    }
    .audience-front-stage-roll {
        .container-fluid {
            .BackstageInfoStream  {
                .HeadingBstage {
                    .collapsDivToggle {
                        width: 26px;
                        height: 26px;
                        top: 12px;
                    }
                }
            }
        }
    }
    .RoomMeetingPopUp .modal-dialog .modal-content .modal-body .content-wrap .content .shrinkWhereBy {
        width: calc(100% - 305px) !important;
    }

    .backstage-dashboard.collapse-out-frontstage {
        .SideRightMenu {
            height: calc(100% - 114px) !important;
            ul {
                li {
                    margin: 10px 0px !important;
                    padding: 8px 8px !important;
                    ul {
                        li {
                            button {
                                svg {
                                    width: 24px;
                                    height: 24px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .backstage-dashboard {
        .SideRightMenu {
            height: calc(100% - 282px);
            ul {
                li {
                    font-size: 12px !important;
                    margin: 8px 0 !important;
                    padding: 8px 8px !important;
                    span {
                        margin: 0 0 2px;
                        svg {
                            width: 20px;
                            height: 20px;
                        }
                    }
                    .RightTopStyle {
                        ul {
                            li {
                                button {
                                    svg {
                                        width: 24px;
                                        height: 24px;
                                    }
                                    span {
                                        font-size: 12px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .audience-front-stage-roll.collapse-out-frontstage {
        .container-fluid {
            .SideRightMenu {
                height: calc(100% - 114px) !important;
                ul {
                    li {
                        margin: 10px 0px !important;
                        padding: 8px 8px !important;
                        ul {
                            li {
                                button {
                                    svg {
                                        width: 24px;
                                        height: 24px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        
    }
    .audience-front-stage-roll {
        .container-fluid {
            .SideRightMenu {
                height: calc(100% - 282px);
                ul {
                    li {
                        font-size: 12px !important;
                        margin: 8px 0 !important;
                        padding: 8px 8px !important;
                        span {
                            margin: 0 0 2px;
                            svg {
                                width: 20px;
                                height: 20px;
                            }
                        }
                        .RightTopStyle {
                            ul {
                                li {
                                    button {
                                        svg {
                                            width: 24px;
                                            height: 24px;
                                        }
                                        span {
                                            font-size: 12px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .StageExtras .accordion .accordion-item .accordion-button {
        font-size: 14px !important;
    }
    .LivetreamBtn {
        padding: 6px 18px;
    }
    .SlideBarTransform .BackstageCommLt .BsHeightFixLx .StageInfoBar .accordion .accordion-item .accordion-collapse .accordion-body .ControlPanelType .ControlPanel {
        display: inline-block;
        margin: 0 -1px;
        font-size: 12px;
    }
    .StageExtras .accordion .accordion-item .collapsed::after {
        transform: rotate(135deg);
        top: 12px;
    }
    .StageExtras .accordion .accordion-item .accordion-button::after {
        width: 10px;
        height: 10px;
    }
    .FixSideMenu .StatuSideMenu .MenuIconList ul li {
        margin: 0 0 12px;
    }
    .oraganizer-backstage-dashboard {
        .MainViewBackStage {
            .DivChangeWidthView {
                .ChangeWidthScroll {
                    .flexDicSeps {
                        .HeadingBstage {
                            padding: 8px 12px;
                            .ActionBcFcHeader {
                                .BsMidAction {
                                    height: 33px !important;
                                    padding: 0 10px !important;
                                    
                                    .ActionLive {
                                        margin: 0 5px !important;
                                        width: 20px;
                                        height: 20px;
                                    }
                                    .BGImgUpdate {
                                        width: 15px;
                                        height: 15px;
                                    }
                                    .BsMic {
                                        margin-right: 0px !important;
                                        margin-left: 0px !important;
                                    }
                                    .BsSetting {
                                        width: 16px !important;
                                    }
                                    .StartStop {
                                        width: auto !important;
                                        height: auto !important;
                                    }
                                }
                                .RigthtStreamBs {
                                    margin-left: 4px;
                                    .DarkGrayButton {
                                        padding: 6px 8px;
                                        font-size: 12px;
                                        .BsUnmuteIcon {
                                            width: 14px;
                                            height: 14px;
                                        }
                                    }
                                }                                
                            }
                            .RigthtStreamBs {
                                .StreamRight {
                                    .leaveStreamDark {
                                        padding: 6px 8px 6px 12px;
                                        font-size: 12px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .BottomStatusFoot {
            padding: 6px 12px;
        }
    }
    .SlideBarTransform {
        width: 280px;
    }
    .BackstageRtStream {
        right: 4px;
        width: 280px;
    }
    .BackstageInfoStream {
        .HeadingBstage {
            padding: 6px 12px;
        }
        .streamingUsers {
            .StreamVideoFrame {
                height: 130px;
                .back-stage-stream-scroll {
                    .video_footer {
                        //width: 230px !important;
                        height: 155px !important;
                    }
                }
            }
        }
    }
    .Stage-Back-Event-Info {
        height: 165px
    }
    .SchedulePageEvent .SetEventSchedule .ScheduleDayStage .DayScheduleStages .SelectDropdown .SeleftDDl .dropdown .dropdown-toggle {
        font-size: 14px;
        height: 36px;
    }
    .SchedulePageEvent .SetEventSchedule .ScheduleDayStage .ScheduleTagsKey .TagsBtn {
        height: 36px;
        font-size: 14px;
    }
    .FixSideMenu {
        width: 62px;
        .StatuSideMenu {
            .MenuIconList {
                ul {
                    li {
                        margin: 0 0 12px;
                        svg {
                            width: 26px;
                            fill: #adadad;
                        }
                    }
                }
            }
        }
    }

    .BackstageInfoStream .streamingUsers .StreamVideoFrame .back-stage-stream-scroll .video_footer {
        height: 118px !important;
    }
    
    // .backstage-dashboard .MainViewBackStage {
    //     height: calc(100vh - 248px) !important;
    // }
    .BackstageInfoStream .streamingUsers .StreamVideoFrame .back-stage-stream-scroll .video_footer .ShortName h4 {
        font-size: 18px;
        height: 40px;
        width: 40px;
    }
    .container-error {
        .ErrorInbox {
            width: 622px;
            padding: 50px 0 60px;
            .LogoWebinar {
                margin-bottom: 16px;
            }
            .PlugWebinar {
                margin-top: 0px;
            }
            .ErrorInfo {
                h3 {
                    font-size: 72px;
                }
            }
        }
        .CurveLine1 {
            width: calc(50% - 310px);
        }
        .CurveLine2 {
            width: calc(50% - 310px);
        }
    }
    .audience-front-stage-roll  {
        .container-fluid {
            .BackstageInfoStream  {
                .streamingUsers {
                    .StreamVideoFrame {
                        height: 180px;
                    }
                }
            }
        }
      }
      .audience-front-stage-roll {
        .go-backstage-btn {
            bottom: 190px !important;
            max-width: 192px !important;
            transition: all 0.5s ease;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            .BlueinButton {
              padding: 8px 14px;
            }
            .RedCommonBtn {
              font-size: 16px;
              height: 38px;
            }
          }
      }
      .SeatsImgORder {
        span {
            width: 24px;
            height: 24px;
            left: 8px;
            top: 5px;
            font-size: 14px;

            &:nth-child(1) {
                left: 3px !important;
            }

            &:nth-child(2) {
                left: 6px !important;
            }
        }
    }
}
@media (max-width:1199px) {
    .BackStageSessionInfo {
        width: 340px;
        left: -999px;
        top: 4px;
        height: calc(100vh - 66px);
        z-index: 99;
        transition: all 0.9s ease;
        -webkit-transition: all 0.9s ease;
        -moz-transition: all 0.9s ease;
        -o-transition: all 0.9s ease;
        .CloseBar {
            display: block;
            position: absolute;
            right: 2px;
            width: 24px;
            height: 24px;
            top: 2px;
            /* background-color: #f0f0f0; */
            border-radius: 0px 4px 4px 0px;
            cursor: pointer;
            svg {
                width: 100%;
                height: 100%;
            }
        }
    }
    .SlideLeftToggle {
        left: 4px !important;
        transition: all 0.9s ease;
        -webkit-transition: all 0.9s ease;
        -moz-transition: all 0.9s ease;
        -o-transition: all 0.9s ease;
    }
    .LeftBarToggle {
        position: absolute;
        left: 0;
        top: 30px;
        background-color: #fff;
        width: 33px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0 8px 8px 0;
        padding-right: 3px;
        
        .SlideArrow {
          transform: rotate(180deg);
          width: 26px;
          height: 26px;
          svg {
            width: 100%;
            height: 100%;
          }
      }
    }
    .MixhubbStage {
        .oraganizer-backstage-dashboard {
            .BackstageInfoStream {
                width: calc(100% - 8px);
                bottom: 4px;
                right: 4px;
            }
        }
    }
    .organizer-frontend-dashboard {
        .MainViewBackStage {
            width: calc(100% - 8px);
        }
    }
    .organizer-frontend-dashboard {
        .FrontstageInfoStream {
            width: calc(100% - 0px);
            bottom: 12px;
        }
    }
    .FrontstageInfoStream {
        .BsMidAction {
            padding: 0 8px;
            height: 36px;
            .ActionLive {
                margin: 0 3px;
                width: 26px;
                height: 26px;
                cursor: pointer;
            }
        }
    }
    
    .collapsDivToggle {
        width: 24px;
        height: 24px;
        right: 14px;
        top: 10px;
        .CastingToggle {
            padding: 4px;
            &:after {
                width: 10px;
                height: 10px;
                right: 8px;
                top: 6px;
                border-top: 2px solid #000;
                border-right: 2px solid #000;
            }
        }
        .collapse-in-backstage {
            &:after {
                top: 10px !important;
            }
        }
    }

    .BackstageInfoStream .streamingUsers .StreamVideoFrame .back-stage-stream-scroll .video_footer .ShortName h4 {
        font-size: 15px;
        height: 40px;
        width: 40px;
    }
    .BackstageInfoStream {
        .streamingUsers {
            .StreamVideoFrame {
                //height: 142px;
                .back-stage-stream-scroll {
                    .video_footer {
                        //width: 200px !important;
                        height: 135px !important;
                        .bottom_footer {
                            h4 {
                                font-size: 12px;
                            }
                            .RightSvgIcon {
                                .mh-cursor-pointer {
                                    width: 16px;
                                    height: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
   
    .liveRecord {
        width: 65px;
        padding: 3px 0;
        font-size: 12px;
        height: 28px;
        .LiveIcon {
            width: 12px;
            height: 12px;
        }
    }
    .live-recording {
        width: 105px;
        padding: 3px 0;
        font-size: 12px;
        height: 29px;
        left: 84px;
        .RecordIcon {
            width: 14px;
            height: 14px;
        }
    }
    .audience-front-stage-roll .MainViewBackStage .alignAllDiv .front-stage-stream-scroll .row .PollQnaBase .ScreenCast .row .QuestionVote .UpdateVoteStream .QnAuserImg img {
        width: 100% !important;
        height: 100% !important;
    }
    .backstage-dashboard .MainViewBackStage .alignAllDiv .front-stage-stream-scroll .row .PollQnaBase .ScreenCast .row .QuestionVote .UpdateVoteStream .QnAuserImg img {
        width: 100% !important;
        height: 100% !important;
    }


    .oraganizer-backstage-dashboard {
        .MainViewBackStage {
            .DivChangeWidthView {
                .ChangeWidthScroll {
                    .flexDicSeps {
                        .HeadingBstage {
                            padding: 6px 8px;
                            .ActionBcFcHeader {
                                .BsMidAction {
                                    height: 33px !important;
                                    padding: 0 4px !important;
                                    
                                    .ActionLive {
                                        margin: 0 4px !important;
                                        width: 22px;
                                        height: 22px;
                                    }
                                    .BsMic {
                                        margin-right: 0px !important;
                                        margin-left: 0px !important;
                                    }
                                    .BsSetting {
                                        width: 16px !important;
                                    }
                                    .StartStop {
                                        width: auto !important;
                                        height: auto !important;
                                    }
                                }
                                .RigthtStreamBs {
                                    margin-left: 4px;
                                    .DarkGrayButton {
                                        padding: 6px 8px;
                                        font-size: 11px;
                                        .BsUnmuteIcon {
                                            width: 14px;
                                            height: 14px;
                                        }
                                    }
                                }                                
                            }
                            .RigthtStreamBs {
                                .StreamRight {
                                    .leaveStreamDark {
                                        padding: 6px 8px 6px 12px;
                                        font-size: 12px;
                                        svg {
                                            width: 15px;
                                            height: 15px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .BottomStatusFoot {
            padding: 6px 12px;
        }
    }
}
@media(min-width:1024px) and (max-width:1199px) {
	.MixhubbHeader {
        padding: 10px 0;
        .HeaderMainTop {
            .MixhubbLogo {
                max-width: 160px;
                flex: 0 0 160px;
            }
            .MixhubbMenu {
                display: flex;
                // max-width: 68%;
                // flex: 0 0 68%;
                ul {
                    li {
                        margin: 0 10px;
                        .nav-mixhubb {
                            .IconStyle {
                                width: 32px;
                                height: 32px;
                            }
                        }
                    }
                }
            }
            .MixhubbRight {
                // max-width: 14%;
                // flex: 0 0 14%;
                .headIcon {
                    .chatConvIcon {
                        width: 28px !important;
                        height: 28px !important;
                    }
                }
            }
        }
    }
    // .RightTopStyle {
    //     padding: 2px 18px 2px;
    //     height: 54px;
    //     top: 15px;
    //     ul {
    //         li {
    //             margin: 0 6px;
    //             button {
    //                 svg {
    //                     width: 22px;
    //                     height: 23px;
    //                 }
    //             }
    //             span {
    //                 font-size: 10px;
    //             }
    //         }
    //     }
    // }
    .AllPlanSchedule::-webkit-scrollbar {
        width: 6px;
    }
    .AllPlanSchedule {
        height: calc(100vh - 244px);
    }
    .TagScheduleHght {
        height: calc(100vh - 166px);
    }
    .SchedulePageEvent {
        .SetEventSchedule {
            .ScheduleDayStage {
                padding: 8px 0;
                padding-right: 10px;
                padding-left: 18px;
                h4 {
                    font-size: 20px;
                }
                .SelectDayStage {
                    .SelectDays {
                        .SelectDropdown {
                            .dropdown {
                                font-size: 14px;
                                height: 38px;
                            }
                        }
                    }
                    .DayScheduleStages {
                        width: 180px!important;
                        margin-right: 8px;
                    }
                }
            }
        }
    }
    .SchedulePageEvent .SetEventSchedule .ScheduleDayStage .ScheduleTagsKey {
        margin-right: 10px;
    }
    .AllPlanSchedule {
        //padding: 25px 20px 0;
        .ScheduleCalendarInfos {
            .StageFullInfoFix {
                //margin-bottom: 38px;
                //height: 256px;
                .CalenderDateScheduleInof {
                    padding: 40px 12px 32px;
                    .StageNo {
                        padding: 0px 16px;
                    }
                    .DtStageEventDay {
                        h4 {
                            line-height: 34px;
                            font-size: 24px;
                        }
                    }
                    .ScheduleTMFx {
                        margin: 6px 0 16px;
                        p {
                            font-size: 20px;
                        }
                    }
                    .ScheduleStatusOngoing {
                        //margin: 10px 0 0;
                        label {
                            font-size: 15px;
                        }
                    }

                }
                .calenderInfoGoogle {
                    a {

                        svg {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
            .ScheduleInfoWithConts {
                width: calc(100% - 170px);
                position: relative;
                h3 {
                    margin: 0 0 5px;
                    font-size: 18px;
                }
                p {
                    font-size: 14px;
                }
                .Watch-Session {
                    .RedButton {
                        padding: 6px 14px;
                        font-size: 14px;
                    }
                    .BlueinButton {
                        padding: 6px 14px;
                        font-size: 14px;
                    }
                }
            }
            .SpeakerRowList {
                .EventSpeakerExp {
                    padding: 10px 10px;
                    //margin-right: 15px;
                    //min-height: 84px;
                    .ImgSpeakerBg {
                        width: 45px;
                        height: 45px;
                    }
                    .SpeakerNamePos {
                        margin-left: 12px;
                        padding: 3px 0;
                        h4 {
                            font-size: 14px;
                        }
                        p {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
        .RoomMeetingBoard {
            .Available10Seats {
                ul {
                    li {
                        margin: 4px !important;
                    }
                    .SelectSeat {
                        &:first-child {
                           .UserInfoDetail {
                                left: -26px;
                           }
                           .bottomArrow {
                               left: -72px !important;
                           }
                        }
                        &:nth-child(6) {
                            .UserInfoDetail {
                                left: -26px;
                           }
                           .bottomArrow {
                               left: -72px !important;
                           }
                         }
                    }
                }
            }
        }
    }

    .MixhubbLobby {
        .LobbySelImag {
            .BgLobbyFix {
                .LobbyBgWidgetHg {
                    .imageFeatureWidget {
                        width: auto;
                        height: auto;
                    }
                }
            }
        }
    }
    .MixhubbStage {
        overflow-y: inherit;
        .StageMixhubbEvent {
            .SetEventInfo {
                padding: 32px 20px 20px;
                .SetupStatus{
                    span {
                        padding: 2px 28px;
                    }
                }
                h3 {
                    font-size: 20px;
                }
                .AboutSetupInfo {
                    background-color: var(--light-gray);
                    padding: 15px 15px;
                    margin: 25px 0 25px;
                    h4 {
                        font-size: 15px;
                    }
                    p {
                        font-size: 14px;
                    }
                }
    
                .ExpoSetupInfo {
                    min-height: 88px;
                }
                .EnterStageBtn {
                    padding: 8px 25px;
                }
            }
        }
    }

    .RoomMeetingBoard {
        .RoomEventLogo {
            width: 90px;
            top: -13px;
            padding: 1px 8px;
        }
    }

    .RoomMeetingBoard {
        .roomChairBlock {
            ul {
                li {
                    margin: 4px;
                    width: 36px;
                    height: 36px;
                }
                .SelectSeat {
                    font-weight: bold;
                    font-size: 14px;
                }
            }
        }
    }
    .RoomMeetingBoard {
        .RoomCountNo {
            p {
                font-size: 14px;
            }
        }
    }

    .NetwokingProfileQuick {
        margin-top: 20px;
        padding: 10px 12px;
        .ProfilePicInfo {
            width: 48px;
            height: 48px;
            margin-right: 6px;
        }
        .ProfileTxtInfo {
            width: 58%;
            position: relative;
            h4 {
                font-size: 14px;
                font-weight: 500;
                margin: 0 0 2px;
            }
            p {
                font-size: 12px;
            }
        }
        .ReadProfileIcon {
            width: 38px;
            height: 38px;
            padding: 11px !important;
        }
    }
    .RecommendProfiles p {
        margin: 18px 0 0;
        font-size: 16px;
        line-height: 22px;
    }


    .QuickShufflingMatch {
        background-color: var(--light-gray);
        padding: 22px 25px;
        border-radius: 16px;
        h2 {
            font-size: 20px;
        }
        p {
            font-size: 14px;
            margin: 0 0 4px;
            font-weight: 400;
        }
        .MatchQuickGIF {
            padding: 22px 28px;
        }
        .RedButton {
            width: 160px;
            margin: 0px auto;
            display: table;
            font-size: 15px;
            font-weight: 700;
            svg {
                width: 20px;
                height: 20px;
            }
        }
    }

    .NetworkIngAllAttendess {
        .InterestTag {
            margin-left: 16px;
            span {
                margin-left: 0px;
                margin-right: 6px;
            }
        }
        .FilderToggleBox {
            .FilterToggleInfo {
                width: 355px;
                .InputHere {
                    .form-control {
                        width: 250px;
                        height: 40px;
                    }
                }
            }
        }
        .AttendeesHeader {
            .LeftSideAttendess {
                width: 315px;
                h4 {
                    font-size: 20px;
                }
                .RedButton {
                    width: 110px;
                    font-size: 16px;
                }
            }
        }
    }



    .RegisterPageAll {
        .LoginRegisterPage {
            .MixhubbExpDae {
                h1 {
                    font-size: 22px;
                    margin: 0 0 12px;
                }
            }
        }
    }
    .RegisterPageAll {
        .LoginRegisterPage {
            .MixhubbExpDae {
                p {
                    font-size: 18px;
                }
                .event-timezone {
                    font-size: 14px;
                    margin: 4px 0 0;
                }
            }
        }
    }
    .RegisterPageAll {
        .LoginRegisterPage {
            .EnterEventAction {
                h2 {
                    font-size: 20px;
                    margin: 0 0 25px;
                }
            }
        }
    }
    .RegisterPageAll {
        .LoginRegisterPage {
            .EnterEventAction {
                margin: 30px auto 0;
                width: 270px;
            }
        }
    }
    .RegisterPageAll {
        .LoginRegisterPage {
            .EnterEventAction {
                .SocialLoginType {
                    ul {
                        li {
                            display: inline-block;
                            margin: 0 8px;
                            .img-fluid {
                                cursor: pointer;
                                width: 45px;
                                height: 45px;
                            }
                            svg {
                                width: 45px;
                                height: 45px;
                              }
                        }
                    }
                }
            }
        }
    }
    .EventTypeActonIn {
        .form-group {
            .form-control {
                padding: 12px 28px;
            }
        }
    }
    .EventTypeActonIn {
        .form-group {
            .EnterBtn {
                border-radius: 100px;
                padding: 10px 28px;
            }
        }
    }
    .EventCalSchedules {
        h5 {
            width: 90%;
        }
    }
    .EventCalSchedules .EventCalenderIcon img {
        width: 52px;
        height: 52px;
        margin: 0 6px;
    }
    .timer-countDown {
        .timerBodyCondown {
            width: 272px;
            overflow: hidden;
            div {
                width: 55px !important;
                height: 55px !important;
                .time-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    .time {
                        font-size: 22px;
                        line-height: 30px;
                    }
                }
                .time-wrapper {
                    div {
                        font-size: 9px;
                    }
                }
            }
            span {
                font-size: 50px;
            }
        }
    }
    .RegisterPageAll .LoginRegisterPage .mixhubbLogo {
        width: 18.43vw;
    }
    .SchedulePageEvent .SetEventSchedule {
        padding: 0px 0 0px;
        margin: 20px 0 0px;
    }
    .StageBounded {
        .VideoBroadcastLive {
            img {
                height: auto !important;
            }   
        }
    }
    .BackIconAction .ActionLive {
        width: 25px;
        height: 25px;
    }
    .screen-stage-main-div.oraganizer-backstage-dashboard .MainViewBackStage .front-stage-stream-scroll .row .stage-user-list .video_footer .BackIconAction {
        top: 5px !important;
        right: 2px !important;
    }
    .screen-stage-main-div.oraganizer-backstage-dashboard .MainViewBackStage .front-stage-stream-scroll .row .stage-user-list .video_footer .BackIconAction .ActionLive {
        width: 24px !important;
        height: 24px !important;
    }
    .BackIconAction .ActionLive .widthSvg {
        padding: 4px 4px;
    }
    .BackIconAction .ActionLive {
            width: 22px;
            height: 22px;
    }
    .screen-stage-main-div .MainViewBackStage .front-stage-stream-scroll .row .stage-user-list .video_footer .bottom_footer h4 {
        font-size: 12px;
    }
    .BackstageInfoStream .HeadingBstage h3 {
        padding-left: 35px !important;
    }
    .RegisterPageAll {
        .LeftImgBlock {
            .LoginEventLogo {
                top: 15px;
                left: 15px;
                width: 150px;
            }
        }
    }
    .SlideBarTransform {
        .BackstageCommLt {
            .BsHeightFixLx {
                .UploadBroadcastImgs {
                    .UpdateOvelayImgVodeo {
                        .minHeightBox {
                            height: 104px !important;
                            min-height: 104px !important;
                            .UploaderFiles {
                                .mixhubb-uploader-label {
                                    font-size: 9px !important;
                                    line-height: 14px;
                                    .img-fluid {
                                        margin: 4px 0 !important;
                                        width: 35px;
                                    }
                                    span {
                                        p {
                                            font-size: 8px;
                                        }
                                    }
                                }
                            }
                            
                        }
                        
                    }
                }
            }
        }
    }
    .backstage-dashboard.collapse-out-frontstage {
        .MainViewBackStage {
            .FlexResizeStep {
                .SpeakerSizeShrink {
                    .fullFreeContainer {
                        display: flex;
                        align-items: center;
                        width: 100% !important;
                        height: auto !important;
                        .RemoveAllDiv {
                            width: 100% !important;
                            height: auto !important;
                        }
                    }
                }
            }
        }
    }
    .backstage-dashboard {
        .MainViewBackStage {
            .FlexResizeStep {
                .SpeakerSizeShrink {
                    .fullFreeContainer {
                        display: flex;
                        align-items: center;
                        width: 100% !important;
                        height: 100% !important;
                        .RemoveAllDiv {
                            width: auto !important;
                            height: 100% !important;
                        }
                    }
                }
            }
        }
    }
    .SpeakerStageList .EventSpeakerStage {
        width: 210px;
        min-height: 72px;
        margin: 0 6px 10px;
    }
    .audience-front-stage-roll {
        .container-fluid {
            .BackstageInfoStream  {
                .HeadingBstage {
                    height: 45px;
                    .collapsDivToggle {
                        top: 10px;
                    }
                }
            }
        }
    }
    .Stage-Back-Event-Info {
        height: 130px;
        .EventBtmInfo {
            h2 {
                font-size: 15px;
            }
            p {
                font-size: 12px;
            }
        }
    }
    .BackstageInfoStream .streamingUsers .StreamVideoFrame {
        height: 129px !important;
    }
    .PeopleChatPopup {
        width: 290px;
    }
    .audience-front-stage-roll .MainViewBackStage {
        height: calc(100vh - 250px) !important;
    }
    .audience-front-stage-roll.collapse-out-frontstage .MainViewBackStage {
        height: calc(100vh - 118px) !important;
    }
    .audience-front-stage-roll.collapse-out-frontstage {
        .MainViewBackStage {
            .FlexResizeStep {
                .ChangeWithSize {
                    .fullFreeContainer {
                        //width: auto !important;
                        width: 100% !important;
                        height: 100% !important;
                        .RemoveAllDiv {
                            width: 100% !important;
                            //height: auto !important;
                            height: 100% !important;
                        }
                    }
                }
                .SpeakerSizeShrink {
                    width: calc(100% - 294px);
                }
            }
        }
    }
    .audience-front-stage-roll {
        .MainViewBackStage {
            .FlexResizeStep {
                .ChangeWithSize {
                    .fullFreeContainer {
                        width: auto !important;
                        height: 100% !important;
                        .RemoveAllDiv {
                            width: 100% !important;
                            height: auto !important;
                        }
                    }
                }
                .SpeakerSizeShrink {
                    width: calc(100% - 294px);
                    .fullFreeContainer {
                        width: 100% !important;
                        height: auto !important;
                    }
                }
            }
        }
    }
    .audience-front-stage-roll.collapse-out-frontstage {
        .MainViewBackStage {
            .FlexResizeStep {
                .SpeakerSizeShrink {
                    .fullFreeContainer {
                        width: 100% !important;
                        height: auto !important;
                        .RemoveAllDiv {
                            width: auto !important;
                            height: 100% !important;
                        }
                    }
                }
                .SpeakerSizeShrink {
                    width: calc(100% - 294px);
                }
            }
        }
    }
    .audience-front-stage-roll .StageFootShrink {
        width: calc(100% - 294px) !important;
    }
    .audience-front-stage-roll {
        .MainViewBackStage {
            .FlexResizeStep {
                .SpeakerSizeShrink {
                    .fullFreeContainer {
                        width: auto !important;
                        height: 100% !important;
                        .RemoveAllDiv {
                            width: 100% !important;
                            height: 100% !important;
                        }
                    }
                }
                .SpeakerSizeShrink {
                    width: calc(100% - 294px);
                }
            }
        }
    }
    .backstage-dashboard {
        .MainViewBackStage {
            height: calc(100vh - 266px) !important;
        }
        .FlexResizeStep {
            .SpeakerSizeShrink {
                width: calc(100% - 295px) !important;
            }
        }
        .StageFootShrink {
            width: calc(100% - 295px) !important;
        }
    }
    .backstage-dashboard.collapse-out-frontstage .MainViewBackStage {
        height: calc(100vh - 120px) !important;
    }
    .SlideCommonPopup .nav-tabs {
        padding: 8px 8px 8px;
    }
    .SlideCommonPopup .nav-tabs li .nav .nav-link {
        font-size: 12px;
        padding: 2px 10px;
    }
    .SlideCommonPopup .nav-tabs li .nav .nav-link.active {
        padding: 2px 10px;
    }
    .BackstageInfoStream {
        .HeadingBstage {
            padding: 6px 10px;
            h3 {
                font-size: 16px;
            }
            .BsMidAction {
                height: 35px;
                .ActionLive {
                    margin: 0 4px;
                    width: 24px;
                    height: 24px;
                }
                .BGImgUpdate {
                    margin: 0 4px;
                    width: 18px;
                    height: 18px;
                }
                .BsSetting {
                    width: 18px;
                    height: 18px;
                }
                .StartStop {
                    width: auto;
                    height: auto;
                    margin-right: -3px;
                    position: relative;
                }
                .BsEnd {
                    .RedCommonBtn {
                        padding: 5px 18px;
                        font-size: 14px;
                    }
                }
            }
            .ActionBcFcHeader {
                .RigthtStreamBs {
                    .DarkGrayButton {
                       padding: 6px 14px;
                       font-size: 12px;
                    }
                }
            }
        }
    }
    .BackstageInfoStream .streamingUsers .StreamVideoFrame .back-stage-stream-scroll .video_footer .bottom_footer .NameExpFull h4 {
        font-size: 12px;
    }
    .ChatMixInfoMixhubb {
        width: 290px;
    }
    .RoomMeetingPopUp .modal-dialog .modal-content .modal-body .content-wrap .content .shrinkWhereBy {
        width: calc(100% - 294px) !important;
    }


    .BackstageRtStream .BsHeightFixLx .EventInfoStage {
        height: calc(100vh - 255px);
    }
    .BackstageRtStream .BsHeightFixLx .EventInfoStage .tab-content .ChatInfos {
        height: calc(100vh - 358px);
    }
    .MessageTextBox .form-control {
        height: 68px;
        padding: 15px 40px 15px 74px;
        font-size: 20px;
    }
    .MessageTextBox .MsgEmojis {
        left: 14px;
        top: 12px;
        width: 40px;
        height: 40px;
    }
    .MessageTextBox .MsgSendIcon {
        top: 5px;
        right: 0px;
        width: 72px;
        height: 58px;
    }
    .BackstageRtStream .BsHeightFixLx .EventInfoStage .tab-content .EnableChat .BlueColor {
        font-size: 13px;
        margin: 0 2px;
    }
    .oraganizer-backstage-dashboard .MainViewBackStage .DivChangeWidthView .ChangeWidthScroll .flexDicSeps .HeadingBstage .BsMidAction {
        height: 32px;
    }
    .backstage-dashboard.collapse-out-frontstage {
        .SideRightMenu {
            height: calc(100% - 114px) !important;
            ul {
                li {
                    margin: 10px 0px !important;
                    padding: 8px 8px !important;
                    ul {
                        li {
                            button {
                                svg {
                                    width: 24px;
                                    height: 24px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .backstage-dashboard {
        .SideRightMenu {
            height: calc(100% - 258px);
            ul {
                li {
                    font-size: 12px !important;
                    margin: 8px 0 !important;
                    padding: 8px 8px !important;
                    span {
                        margin: 0 0 2px;
                        svg {
                            width: 20px;
                            height: 20px;
                        }
                    }
                    .RightTopStyle {
                        ul {
                            li {
                                button {
                                    svg {
                                        width: 24px;
                                        height: 24px;
                                    }
                                    span {
                                        font-size: 12px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }


    .audience-front-stage-roll.collapse-out-frontstage {
        .container-fluid {
            .SideRightMenu {
                height: calc(100% - 114px) !important;
                ul {
                    li {
                        margin: 10px 0px !important;
                        padding: 8px 8px !important;
                        ul {
                            li {
                                button {
                                    svg {
                                        width: 24px;
                                        height: 24px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        
    }
    .audience-front-stage-roll {
        .container-fluid {
            .SideRightMenu {
                height: calc(100% - 258px);
                ul {
                    li {
                        font-size: 12px !important;
                        margin: 8px 0 !important;
                        padding: 8px 8px !important;
                        span {
                            margin: 0 0 2px;
                            svg {
                                width: 20px;
                                height: 20px;
                            }
                        }
                        .RightTopStyle {
                            ul {
                                li {
                                    button {
                                        svg {
                                            width: 24px;
                                            height: 24px;
                                        }
                                        span {
                                            font-size: 12px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    
    .StageExtras .accordion .accordion-item .accordion-button {
        font-size: 13px !important;
        padding: 12px 8px !important;
    }
    .LivetreamBtn {
        padding: 6px 18px;
    }
    .SlideBarTransform .BackstageCommLt .BsHeightFixLx .StageInfoBar .accordion .accordion-item .accordion-collapse .accordion-body .ControlPanelType .ControlPanel {
        display: inline-block;
        margin: 0 -1px;
        font-size: 12px;
    }
    .StageExtras .accordion .accordion-item .collapsed::after {
        transform: rotate(135deg);
        top: 12px;
    }
    .StageExtras .accordion .accordion-item .accordion-button::after {
        width: 10px;
        height: 10px;
        right: 10px;
    }
    .FixSideMenu .StatuSideMenu .MenuIconList ul li {
        margin: 0 0 22px;
    }
    .StageInfoBar .accordion .accordion-item {
        margin-bottom: 12px;
    }
    .SlideBarTransform .BackstageCommLt .BsHeightFixLx .UploadBroadcastImgs .form-group .ClickBroadcast .Broadcasting {
        font-size: 14px;
    }
    .oraganizer-backstage-dashboard .MainViewBackStage .DivChangeWidthView .ChangeWidthScroll .flexDicSeps .HeadingBstage .ActionBcFcHeader .BsMidAction .BGImgUpdate  {
        width: 15px;
        height: 15px;
    }
    .SelectDropdown  {
        .dropdown {
            .css-2b097c-container {
                .css-yk16xz-control {
                    height: 40px;
                    font-size: 13px;
                }
                .css-1pahdxg-control {
                    height: 40px;
                    font-size: 13px;
                }
            } 
        }
    }
    .TitleAndTrash {
        .css-2b097c-container {
            .css-yk16xz-control {
                height: 40px;
                font-size: 13px;
            }
            .css-1pahdxg-control {
                height: 40px;
                font-size: 13px;
            }
        } 
    }
    .TitleAndTrash .css-2b097c-container .css-1hb7zxy-IndicatorsContainer::after {
        width: 11px;
        height: 11px;
        top: 11px;
    }
    .SelectDropdown .css-1hb7zxy-IndicatorsContainer::after {
        width: 11px;
        height: 11px;
        top: 12px;
    }
    .SlideBarTransform .BackstageCommLt .BsHeightFixLx .StageInfoBar {
        padding: 10px;
    }
    .MessageTextBox {
        .MsgEmojis {
            .EmojiIconsIn {
                top: -218px;
                width: 272px;
                left: -17px;
                .EmojiMainIco {
                    ul {
                        li {
                            width: 32px;
                            height: 36px;
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
    .SchedulePageEvent .SetEventSchedule .ScheduleDayStage .DayScheduleStages .SelectDropdown .SeleftDDl .dropdown .dropdown-toggle {
        font-size: 14px;
        height: 38px;
    }
    .SchedulePageEvent .SetEventSchedule .ScheduleDayStage .SelectDayStage .SelectSchedule .PepleSerchHere input {
        height: 38px !important;
    }
    .SchedulePageEvent .SetEventSchedule .ScheduleDayStage .ScheduleTagsKey .TagsBtn {
        height: 36px;
        font-size: 14px;
    }
    .SchedulePageEvent .SetEventSchedule .ScheduleDayStage .SelectDayStage .SelectSchedule .PepleSerchHere input {
        padding: 0 32px 0 14px;
    }
    .SideBarHeading h2 {
        font-size: 16px !important;
    }
    .StageTabCollapes {
        display: block !important;
    }
    .SideRightMenu, .BackstageInfoStream {
        display: none !important;
    }

    .NotifyMixhubbRt .headingTop p {
        font-size: 12px;
        padding: 7px 10px;
        font-weight: 500;
    }
    
    .ChatMixInfoMixhubb .headingTop h2 {
        font-size: 16px;
    }
    .BackstageInfoStream .streamingUsers .StreamVideoFrame .back-stage-stream-scroll .video_footer {
        height: 118px !important;
    }
    // .BackstageInfoStream .streamingUsers .StreamVideoFrame {
    //     height: 144px !important;
    // }
    .backstage-dashboard .MainViewBackStage {
        height: calc(100vh - 248px) !important;
    }
    .BackstageInfoStream .streamingUsers .StreamVideoFrame .back-stage-stream-scroll .video_footer .ShortName h4 {
        font-size: 18px;
        height: 40px;
        width: 40px;
    }
    .container-error {
        .ErrorInbox {
            width: 500px;
            padding: 40px 0 40px;
            .LogoWebinar {
                margin-bottom: 25px;
                .maxImg {
                    width: 180px;
                }
            }
            .PlugWebinar {
                margin-top: 0px;
            }
            .ErrorInfo {
                strong {
                    font-size: 16px;
                }
                h3 {
                    font-size: 72px;
                }
                p {
                    font-size: 14px;
                    padding: 0 50px;
                }
            }
        }
        .CurveLine1 {
            width: calc(50% - 250px);
            margin-top: -94px;
        }
        .CurveLine2 {
            width: calc(50% - 250px);
        }
    }
    .audience-front-stage-roll  {
        .container-fluid {
            .BackstageInfoStream  {
                .streamingUsers {
                    .StreamVideoFrame {
                        height: 143px !important;
                    }
                }
            }
        }
      }

    //  ************ Lablet Layout Responsive Portrait  ***********

    body {
        overflow-y: auto !important;
    }
    .maxScreenHeight {
        height: calc(100vh - 48px);
        overflow-y: auto !important;
        margin-top: 48px;
        overflow-x: hidden !important;
        .scale-blur-fix {
            height: 100vh;
            position: fixed !important;
            width: 100%;
        }
    }
    .maxScreenHeight .scale-blur-fix .BlurFullImgs {
        width: 100%;
        height: calc(100vh - 0px);
    }
    .CameraPrevModal.show {
        display: block !important;
        align-items: center;
        .modal-dialog {
            width: 95% !important;
        }
    }
    .CameraPrevModal .modal-dialog .modal-content .DarkBlurBackground .JoinTableRoom .JoinPersonTable .modal-body .joinNowTableUsGranted {
        padding: 22px 20px;
    }
    .CameraPrevModal {
        .modal-dialog {
            .modal-content {
                .DarkBlurBackground {
                    .JoinTableRoom {
                        .JoinPersonTable {
                            .modal-body {
                                .joinNowTableUsGranted {
                                    p {
                                        font-size: 15px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .MixhubbLobby {
        .LobbySelImag {
            .BgLobbyFix {
                .LobbyBgWidgetHg {
                    .imageFeatureWidget {
                        width: auto !important;
                        height: 100% !important;
                    }
                }
            }
        }
    }
    .SchedulePageEvent {
        .SetEventSchedule {
            .ScheduleDayStage {
                padding: 10px 14px 10px 22px !important;
            }
        }
    }
    .SchedulePageEvent {
        .SetEventSchedule {
            .ScheduleDayStage {
                h4 {
                    font-size: 18px;
                }
            }
        }
    }
    .BackstageInfoStream {
        .HeadingBstage {
            padding: 8px 12px;
            h3 {
                font-size: 14px;
                font-weight: 600;
            }
            .BsMidAction {
                
                .ActionLive {
                    margin: 0 4px;
                    width: 28px;
                    height: 28px;
                }
                .BsEnd {
                    .RedCommonBtn {
                        padding: 4px 20px;
                    }
                }
            }
        }
    }
    .BackstageInfoStream {
        .streamingUsers {
            .StreamVideoFrame {
                .back-stage-stream-scroll {
                    .localPlayer {
                        width: 90px !important;
                        margin: 0 0px;
                        height: 70px !important;
                        position: relative !important;
                    }
                }
            }
        }
    }
    .MixhubbStage {
        .backstage-dashboard {
            .MainViewBackStage {
                width: calc(100% - 250px) !important;
                height: calc(100vh - 126px);
                margin: 0 auto;
                height: auto !important;
                float: none;
            }
        }
    }
    .CameraPrevModal {
        .modal-dialog {
            height: auto !important;
        }
    }
    .ModalCommonInfo{
        .modal-dialog {
            height: auto !important;
        }
    }
    .MixhubbStage {
        .backstage-dashboard {
            padding: 0 !important;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    
    .BackstateOverview {
        height: calc(100vh - 45px);
    }
    .BackstateOverview h1 {
        margin: 0 0 0px;
    }
    .StageBounded {
        height: calc(100vh - 116px) !important;
        position: relative !important;
    }
    .StageBounded {
        .VideoBroadcastLive {
            img {
                height: 100% !important;
            }   
        }
    }
    .RegisterPageAll {
        .LeftImgBlock {
            .LoginEventLogo {
                .EventLogoFix {
                    width: 100%;
                }
            }
        }
    }
    .audience-front-stage-roll.collapse-out-frontstage {
        height: auto;
        overflow-y: auto !important;
    }
    .MainViewBackStage .front-stage-stream-scroll .row .stage-user-list .video_footer .RightSvgIcon .mh-cursor-pointer {
        padding: 6px;
        width: 25px;
        height: 25px;
        top: 8px;
        left: 8px;
    }

    .RegisterPageAll .LeftImgBlock .LoginEventLogo {
        top: 8px;
        left: 8px;
        width: 100px;
    }
    .RecordLiveStream {
        left: 12px;
        top: -35px;
    }
    .portrait-content {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: #fff;
        width: 100%;
        height: 100%;
        height: 100vh;
        z-index: 99999999;
        .portrait-center {
            position: absolute;
            top: 50%;
            left: 0;
            margin: 0 auto;
            right: 0;
            transform: translateY(-50%);
            text-align: center;
            img {
                margin: 0 auto 10px;
                width: 200px;
                text-align: center;
            }
            p {
                font-size: 16px;
                color: #000;
                text-transform: uppercase;
                text-align: center;
                font-weight: 400;
            }
        }
    }
    .audience-front-stage-roll .BackstageInfoStream .HeadingBstage {
        height: 45px !important;
    }
    .audience-front-stage-roll.collapse-out-frontstage .BackstageInfoStream .HeadingBstage {
        height: 45px !important;
    }
    .collapsDivToggle {
        width: 26px;
        height: 26px;
    }
    .go-backstage-btn {
        display: none;
    }
    .StageTabCollapes .MobileStageFtr {
        display: block !important;
    }
    .BackstageInfoStream {
        width: 99%;
        position: fixed;
        bottom: 4px !important;
        left: 0;
        right: 0;
        margin: 0px auto;
        z-index: 999;
        display: none;
        .streamingUsers {
            .StreamVideoFrame {
                height: calc(100vh - 100px) !important;
                .back-stage-stream-scroll {
                    .Stage-Back-Event-Info {
                        height: calc(100vh - 116px) !important;
                        .row {
                           .SpeakerStageList {
                                margin: 15px 0 0;
                               .EventSpeakerStage {
                                   width: 46% !important;
                               }
                           } 
                        }
                    }
                }
            }
        }
    }

    .audience-front-stage-roll {
        .container-fluid {
            .MainViewBackStage {
                height: calc(100vh - 88px) !important;
                margin-top: 0px;
                .FlexResizeStep {
                    .ChangeWithSize {
                        .fullFreeContainer {
                            width: 100% !important;
                            height: 100% !important;
                            .RemoveAllDiv {
                                height: 100% !important;
                               .front-stage-stream-scroll {
                                    width: 100% !important;
                                    height: 100% !important;
                                }
                            }
                        }
                    }
                }
            }
            .SideRightMenu {
                display: flex !important;
                top: 48px;
                height: calc(100% - 96px) !important;
                ul {
                    li {
                        padding: 6px 2px 2px !important;
                        margin: 4px 0!important;
                        span {
                            font-size: 8px;
                            padding: 0!important;
                            line-height: 12px;
                        }
                        .RightTopStyle {
                            ul {
                                li {
                                    padding: 0px 2px 0px !important;
                                    margin: 0px !important;
                                    .ChatClick {
                                        span {
                                            font-size: 8px;
                                            padding: 0!important;
                                            line-height: 12px;
                                        }
                                    }
                                    
                                }
                            }
                        }
                    }
                }
                
            }
        }
    }

    .audience-front-stage-roll.collapse-out-frontstage {
        .container-fluid {
            .MainViewBackStage {
                height: calc(100vh - 88px) !important;
            }
            .SideRightMenu {
                display: none !important;
                height: calc(100% - 96px) !important;
                top: 48px;
                ul {
                    li {
                        padding: 6px 2px 2px !important;
                        margin: 4px 0!important;
                       
                        span {
                            font-size: 9px;
                            padding: 0!important;
                            line-height: 12px;
                        }
                        .RightTopStyle {
                            ul {
                                li {
                                    padding: 0px 2px 0px !important;
                                    margin: 0px !important;
                                    .ChatClick {
                                        span {
                                            font-size: 9px;
                                            padding: 0!important;
                                            line-height: 12px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                
            }
        }
    }
    .audience-front-stage-roll.screen-stage-main-div .container-fluid .MainViewBackStage {
        height: calc(100vh - 88px) !important;
    }
    .audience-front-stage-roll.collapse-out-frontstage.screen-stage-main-div .container-fluid .MainViewBackStage {
        height: calc(100vh - 88px) !important;
        margin: 0px !important;
    }
    .audience-front-stage-roll.collapse-out-frontstage{
        .container-fluid {
            .MainViewBackStage {
                height: calc(100vh - 88px) !important;
                .FlexResizeStep {
                    .ChangeWithSize {
                        height: 100% !important;
                        .fullFreeContainer {
                            //height: auto !important;
                            .RemoveAllDiv {
                                height: 100% !important;
                                img {
                                    height: auto !important;
                                    aspect-ratio: 16/9;
                                    object-fit: scale-down;
                                }
                                .front-stage-stream-scroll {
                                    height: 100% !important;
                                    .row {
                                        .stage-user-list {
                                            .video_footer {
                                                .ShortName {
                                                    h4 {
                                                        width: 45px;
                                                        height: 45px;
                                                        font-size: 22px;
                                                    }
                                                }
                                                .bottom_footer {
                                                    h4 {
                                                        font-size: 10px !important;
                                                        span {
                                                            line-height: 14px !important;
                                                        }
                                                    }
                                                }
                                                .BackIconAction {
                                                    top: 5px;
                                                    right: 3px;
                                                    .ActionLive {
                                                        width: 18px;
                                                        height: 18px;
                                                        .widthSvg {
                                                            padding: 3px 3px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                
                            }
                        }
                    }
                }
               
            }
        }
    }
   
    .MobJoinBackstage {
        display: none !important;
    }
    .PollInfos .PollMhAuto {
        height: calc(100vh - 198px);
    }
    .PollInfos .PollMhAuto .mh-poll-container .PollReqBar .PollCreateInfo {
        width: 100%;
        margin: 14px 0 0;
    }
    .StageTabCollapes .tab-content .PeopleChatPopup {
        height: auto !important;
        position: inherit;
        border-radius: 0px;
        width: 100%;
        box-shadow: none !important;
        padding: 0;
    }
    .AttendeeInofMob {
        float: left;
        width: 100%;
        .col-md-12 {
            padding: 0 6px;
        }
    }
    .MixhubbHeader {
        width: 100%;
        top: 0;
        position: fixed;
    }
    .StageTabCollapes {
        position: relative;
        width: 100%;
        bottom: 2px;
        z-index: 95;
        margin: 0px auto !important;
        left: 0;
        right: 0;
        display: block !important;
        .tab-content {
            .PeopleChatPopup {
                height: auto !important;
                position: inherit;
                border-radius: 0px;
                width: 100%;
                box-shadow: none !important;
                padding: 0;
                .responsive-tabs-container {
                    .nav-tabs {
                        padding: 8px 10px;
                        height: 72px;
                        justify-content: space-between;
                        li {
                            margin: 0 0 0;
                            .nav {
                                .nav-link {
                                    &.active {
                                        border-radius: 4px;
                                        background: transparent;
                                        color: #1A78E5;
                                        svg {
                                            fill: #1A78E5;
                                        }
                                    }
                                    svg {
                                        display: block;
                                        margin: 0 auto;
                                        width: 26px;
                                        height: 26px;
                                        fill: #000000;
                                    }
                                }
                            }
                        }
                    }
                    .CloseButton {
                        display: none;
                    }
                }
                .StateEventDtl {
                    height: calc(100vh - 190px) !important;
                }
                .StageBackStage {
                    .nav-tabs {
                        display: none;
                        li {
                            .nav-link {
                                padding: 10px 0 !important;
                                &.active {
                                    padding: 10px 0 !important;
                                }
                            }
                        }
                    }
                }
            }
            .ReadMoreDeso {
                background-color: #f0f0f0;
                padding: 12px 2px;
                .AboutTabsFull {
                    margin: 0px 0;
                    overflow-y: auto;
                    height: calc(100vh - 168px);
                    padding: 0 12px;
                    .ScheduleDayEvent {
                        margin: 14px 0;
                        span {
                            background-color: #ffffff;
                            font-size: 14px;
                            padding: 6px 12px;
                            font-weight: 500;
                            color: #1F1B20;
                            border-radius: 4px;
                        }
                    }
                    h4 {
                        color: #1A78E5;
                        font-size: 18px;
                        margin: 0;
                    }
                    p {
                    font-size: 14px;
                    margin: 18px 0 0;
                    color: #1F1B20;
                    }
                    .SectionWatch {
                    width: 100%;
                    text-align: left;
                    margin: 18px 0 2px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .RedButton  {
                        padding: 6px 16px;
                        font-size: 15px;
                        width: 145px;
                    }
                    .PopUpImgSpeaker {
                        .SpkInfoImgs {
                            width: 50px;
                            height: 50px;
                            display: inline-block;
                            margin-right: 10px;
                            img {
                                width: 100%;
                                height: 100%;
                                border-radius: 100px;
                        }
                        }
                        .SpkInfoCount {
                            border: 2px #1A78E5 solid;
                            color: #1A78E5;
                            width: 46px;
                            height: 46px;
                            border-radius: 100px;
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
    .SlideCommonPopup {
        .StateEventDtl {
            .EventInfoStage {
                height: calc(100vh - 190px) !important;
                position: relative;
            }
            .ChatInfos {
                height: calc(100vh - 200px) !important;
            }
        }
    }
    .PeopleChatPopup .nav-tabs li .nav .nav-link .badge {
        top: -7px;
    }
    .MessageTextBox {
        bottom: 12px;
        width: 95%;
    }
    .PollInfos .NowPollCreating {
        display: none;
    }
    #join {
        display: none !important;
    }
    #people {
        display: none !important;
    }
    #aboutInfo {
        display: block !important;
    }
    #visitor {
        display: none !important;
    }
    // .MixhubbHeader .HeaderMainTop .MixhubbMenu ul li .nav-mixhubb .live-icon {
    //     position: initial !important;
    // }
    .PeopleChatPopup {
        .InfoPeopleChat {
            .tab-content {
                .ChatInfos {
                    .SenderMassage {
                        .SenderMessage {
                            p {
                                font-size: 14px;
                            }
                            h4 {
                                font-size: 14px;
                            }
                        }
                    }
                    .align-right {
                        .SenderMessage {
                            .chatPicsIn {
                                width: 50px;
                                height: 50px;
                            }
                            .chatAllin {
                                p {
                                    font-size: 14px;
                                }
                                h4 {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .PollInfos .MhBtnRemove {
        height: calc(100vh - 200px);
    }
    .QnaInfos {
        height: calc(100vh - 192px) !important;
        padding: 12px 20px 6px;
        .QnaInfosPols {
            height: calc(100vh - 295px);
        }
        .MessageTextBox {
            p {
                top: -24px;
                font-size: 14px;
            }
        }
    }
    .FilesInfos {
        height: calc(100vh - 130px);
    }
    .PeopleChatPopup .StateEventDtl .peopleInfos {
        height: calc(100vh - 152px) !important;

    }
    .SlideCommonPopup .StateEventDtl .EventInfoStage .ChatInfos {
        height: calc(100vh - 281px) !important;
        padding: 10px 32px 0;
    }
    .PeopleChatPopup .StateEventDtl .JoinRequestedMx {
        height: calc(100vh - 130px) !important;
    }
    .BoothFootAction {
        position: absolute !important;
    }
    .customer-chat {
        bottom: 8px;
        right: 3px;
        width: 65px;
        height: 65px;
    }
    .MixhubbHeader .HeaderMainTop .MixhubbMenu {
        margin: 0px 0 0;
        //overflow-y: auto;
    }
    .RightTopStyle {
        //height: 38px !important;
        top: 16px !important;
    }
    .VirtuslBgPreview .modal-dialog {
        height: calc(100vh - 58px) !important;
    }
    .ScreenTopAlign {
        position: relative !important;
    }
    .MixhubbLobby {
        .LobbySelImag {
            .BgLobbyFix {
                height: 100% !important;
                overflow-y: initial;
                .LobbyBgWidgetHg {
                    height: 100% !important;
                    margin: 0px 0px 0px !important;
                    padding: 0px 0px 0px !important;
                    //display: inline-flex;
                    .imageFeatureWidget {
                        height: 100% !important;
                        img {
                            height: 100% !important;
                            width: 100%;
                            border-radius: 0px !important;
                        }
                    }
                }
            }
        }
    }
    .QnaInfos {
        .QnaInfosPols {
            .QuestionVote {
                .ShowAnAQue {
                    .QnAuserImg {
                        width: 45px;
                        height: 45px;
                        img {
                            width: 45px;
                            height: 45px;
                            background-color: #ffffff;
                        }
                    }
                    .NameQnAuser {
                        h4 {
                            font-size: 16px;
                        }
                    }
                }
                p {
                    font-size: 16px;
                }
                .QnaVoteCount {
                    .ThumbUpBtn {
                        font-size: 14px;
                        svg {
                            width: 18px;
                            height: 18px;
                        }
                    }
                }
            }
        }
    }
    .edit-expo-booth {
        display: none !important;
    }
    .RoomMeetingPopUp .modal-dialog .modal-content .InviteTableCall {
        height: calc(100vh - 4px) !important;
    }
    .PeopleChatPopup {
        top: 2px;
    }
    .RoomMeetingPopUp .modal-dialog .modal-content .InviteTableCall .invitedTableCell {
        height: calc(100vh - 6px);
    }
    .InviteTableCall {
        .peopleInfos {
            height: calc(100vh - 124px);
            .PeopleAttendList {
                height: calc(100vh - 205px);
            }
        }
    }


    .AttendeeFrame {
        .YoutubeVideo {
            .ControlFrame {
              padding: 0 5px !important;
              .LeftControls {
                .UtButton {
                 button {
                     svg{
                      width: 35px;
                      height: 35px;
                     }
                 }
                }
                .liveSession {
                  span {
                    font-size: 10px;
                    padding: 0 5px;
                    margin-left: 6px;
                  }
               }
               .MuteUnmute {
                   .e-range {
                    width: 55px !important;
                   }
                  &:hover {
                      .VolumeCtrl {
                          height: 32px;
                          width: 55px !important;
                       }
                   }
               }
              }
              .RightControls {
                .UtButton {
                  width: 35px;
                  height: 35px;
                  button {
                  padding: 0px;
                  border: 0;
                  background-color: transparent;
                  svg {
                    background-color: transparent;
                    fill: #fff;
                  }
                  }
                }
              }
            }
          }
        .playBlack {
          .PlayAgain {
            svg {
              width: 70px;
              height: 70px;
            }
          }
        }
      }
      .audience-front-stage-roll {
        aspect-ratio: inherit !important;
      }
      .ChatMixInfoMixhubb {
        position: fixed;
        top: auto;
        right: 5px;
        width: 99%;
        height: calc(100vh - 70px);
        z-index: 99;
        bottom: 2px;
    }
    .AttendeeFrame {
        .fullScreen {
            //position: relative !important;
            overflow: hidden;
            width: 100%;
            aspect-ratio: 16/9;
            iframe {
            width: 100% !important;
            height: 100% !important;
            margin: 0px !important;
            }
            .ControlFrame {
            background-color: #000000 !important;
            padding: 6px 5px!important;
            }
        }
    }
    .audience-front-stage-roll.collapse-out-frontstage.screen-stage-main-div .container-fluid .MainViewBackStage .FlexResizeStep .ChangeWithSize .fullFreeContainer {
        height: 100% !important;
        width: 100% !important;
    }
    .SeatsImgORder {
        span {
            width: 20px;
            height: 20px;
            left: 6px;
            top: 4px;
            font-size: 12px;

            &:nth-child(1) {
                left: 2px !important;
            }

            &:nth-child(2) {
                left: 4px !important;
            }
        }
    }
}
@media(max-width: 1023px) {
    .container-error .CurveLine {
        display: none;
    }
}
@media(min-width:992px) and (max-width:1023px) {
	.MixhubbHeader {
        padding: 10px 0;
        .HeaderMainTop {
            padding: 0 5px;
            .MixhubbLogo {
                max-width: 160px;
                flex: 0 0 160px;
            }
            .MixhubbMenu {
                display: flex;
                // max-width: 60%;
                // flex: 0 0 60%;
                ul {
                    li {
                        margin: 0 16px;
                        font-size: 13px;
                        .nav-mixhubb {
                            .IconStyle {
                                width: 28px;
                                height: 28px;
                            }
                            span {
                                margin-left: 8px;
                            }
                        }
                        
                    }
                }
            }    
            .MixhubbRight {
                display: flex;
                // max-width: 18%;
                // flex: 0 0 18%;
                .headIcon {
                    .chatConvIcon {
                        width: 26px !important;
                        height: 26px !important;
                    }
                }
                .dropdown {
                    .dropdown-toggle {
                        width: 26px;
                        height: 26px;
                    }
                }
            }        
        }
    }
    // .RightTopStyle {
    //     ul {
    //         li {
    //             margin: 0 14px;
    //             a {
    //                 svg {
    //                     width: 24px;
    //                     height: 21px;
    //                 }
    //             }
    //         }
    //     }
    // }

    .SchedulePageEvent {
        .SetEventSchedule {
            .ScheduleDayStage {
                padding: 8px 0;
                padding-right: 10px;
                padding-left: 18px;
                h4 {
                    font-size: 18px;
                }
                .SelectDayStage {
                    .SelectDays {
                        .SelectDropdown {
                            .dropdown {
                                font-size: 14px;
                                height: 38px;
                            }
                        }
                    }
                    .DayScheduleStages {
                        width: 146px;
                        margin-right: 8px;
                    }
                }
            }
        }
    }

    .AllPlanSchedule {
        //padding: 25px 20px 0;
        .ScheduleCalendarInfos {
            .StageFullInfoFix {
                //margin-bottom: 38px;
                //height: 256px;
                .CalenderDateScheduleInof {
                    padding: 40px 12px 32px;
                    .StageNo {
                        padding: 0px 16px;
                    }
                    .DtStageEventDay {
                        h4 {
                            line-height: 34px;
                            font-size: 24px;
                        }
                    }
                    .ScheduleTMFx {
                        margin: 6px 0 16px;
                        p {
                            font-size: 20px;
                        }
                    }
                    .ScheduleStatusOngoing {
                        //margin: 10px 0 0;
                        label {
                            font-size: 15px;
                        }
                    }

                }
                .calenderInfoGoogle {
                    a {

                        svg {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
            .ScheduleInfoWithConts {
                width: calc(100% - 170px);
                position: relative;
                h3 {
                    margin: 0 0 5px;
                    font-size: 18px;
                }
                p {
                    font-size: 14px;
                }
                .Watch-Session {
                    .RedButton {
                        padding: 6px 14px;
                        font-size: 14px;
                    }
                    .BlueinButton {
                        padding: 6px 14px;
                        font-size: 14px;
                    }
                }
            }
            .SpeakerRowList {
                .EventSpeakerExp {
                    padding: 10px 10px;
                    //margin-right: 15px;
                    //min-height: 84px;
                    .ImgSpeakerBg {
                        width: 45px;
                        height: 45px;
                    }
                    .SpeakerNamePos {
                        margin-left: 12px;
                        padding: 3px 0;
                        h4 {
                            font-size: 14px;
                        }
                        p {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
        .RoomMeetingBoard {
            .Available10Seats {
                ul {
                    li {
                        margin: 4px !important;
                    }
                    .SelectSeat {
                        &:first-child {
                           .UserInfoDetail {
                                left: -26px;
                           }
                           .bottomArrow {
                               left: -72px !important;
                           }
                        }
                        &:nth-child(6) {
                            .UserInfoDetail {
                                left: -26px;
                           }
                           .bottomArrow {
                               left: -72px !important;
                           }
                         }
                    }
                }
            }
        }
    }

    .MixhubbStage {
        overflow-y: inherit;
        .StageMixhubbEvent {
            .SetEventInfo {
                padding: 32px 20px 20px;
                .SetupStatus{
                    span {
                        padding: 2px 28px;
                    }
                }
                h3 {
                    font-size: 20px;
                }
                .AboutSetupInfo {
                    background-color: var(--light-gray);
                    padding: 15px 15px;
                    margin: 25px 0 25px;
                    h4 {
                        font-size: 15px;
                    }
                    p {
                        font-size: 14px;
                    }
                }
    
                .ExpoSetupInfo {
                    min-height: 88px;
                }
                .EnterStageBtn {
                    padding: 8px 25px;
                }
            }
        }
    }

    .RoomMeetingBoard {
        .RoomEventLogo {
            width: 90px;
            top: -13px;
            padding: 1px 8px;
        }
    }

    .RoomMeetingBoard {
        .roomChairBlock {
            ul {
                li {
                    margin: 4px;
                    width: 36px;
                    height: 36px;
                }
                .SelectSeat {
                    font-weight: bold;
                    font-size: 14px;
                }
            }
        }
    }
    .RoomMeetingBoard {
        .RoomCountNo {
            p {
                font-size: 14px;
            }
        }
    }

    .MixhubbLobby {
        .LobbySelImag {
            .BgLobbyFix {
                .LobbyBgWidgetHg {
                    .imageFeatureWidget {
                        width: auto;
                        height: auto;
                    }
                }
            }
        }
    }
    
    


    .RegisterPageAll {
        .LoginRegisterPage {
            .MixhubbExpDae {
                h1 {
                    font-size: 22px;
                    margin: 0 0 12px;
                }
            }
        }
    }
    .RegisterPageAll {
        .LoginRegisterPage {
            .MixhubbExpDae {
                p {
                    font-size: 18px;
                }
                .event-timezone {
                    font-size: 14px;
                    margin: 4px 0 0;
                }
            }
        }
    }
    .RegisterPageAll {
        .LoginRegisterPage {
            .EnterEventAction {
                h2 {
                    font-size: 20px;
                    margin: 0 0 25px;
                }
            }
        }
    }
    .RegisterPageAll {
        .LoginRegisterPage {
            .EnterEventAction {
                margin: 30px auto 0;
                width: 270px;
            }
        }
    }
    .RegisterPageAll {
        .LoginRegisterPage {
            .EnterEventAction {
                .SocialLoginType {
                    ul {
                        li {
                            display: inline-block;
                            margin: 0 6px;
                            .img-fluid {
                                cursor: pointer;
                                width: 50px;
                                height: 50px;
                            }
                            svg {
                                width: 50px;
                                height: 50px;
                              }
                        }
                    }
                }
            }
        }
    }
    .EventTypeActonIn {
        .form-group {
            .form-control {
                padding: 12px 28px;
            }
        }
    }
    .EventTypeActonIn {
        .form-group {
            .EnterBtn {
                border-radius: 100px;
                padding: 10px 28px;
            }
        }
    }
    .EventCalSchedules {
        h5 {
            width: 90%;
        }
    }
    .EventCalSchedules .EventCalenderIcon img {
        width: 52px;
        height: 52px;
        margin: 0 6px;
    }

    .timer-countDown {
        .timerBodyCondown {
            width: 272px;
            overflow: hidden;
            div {
                width: 55px !important;
                height: 55px !important;
                .time-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    .time {
                        font-size: 22px;
                        line-height: 30px;
                    }
                }
                .time-wrapper {
                    div {
                        font-size: 9px;
                    }
                }
            }
            span {
                font-size: 50px;
            }
        }
    }
    .RegisterPageAll .LoginRegisterPage .mixhubbLogo {
        height: 104px;
    }
    .StageBounded {
        .VideoBroadcastLive {
            img {
                height: auto !important;
            }   
        }
    }
    .BackStageSessionInfo {
        width: 340px;
    }
    .RegisterPageAll {
        .LeftImgBlock {
            .LoginEventLogo {
                top: 15px;
                left: 15px;
                width: 150px;
            }
        }
    }
    .audience-front-stage-roll.collapse-out-frontstage {
        .container-fluid {
            .SideRightMenu {
                height: calc(100% - 114px) !important;
                ul {
                    li {
                        margin: 10px 0px !important;
                        padding: 8px 8px !important;
                        ul {
                            li {
                                button {
                                    svg {
                                        width: 24px;
                                        height: 24px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        
    }
    .audience-front-stage-roll {
        .container-fluid {
            .SideRightMenu {
                height: calc(100% - 258px);
                ul {
                    li {
                        font-size: 12px !important;
                        margin: 8px 0 !important;
                        padding: 8px 8px !important;
                        span {
                            margin: 0 0 2px;
                            svg {
                                width: 20px;
                                height: 20px;
                            }
                        }
                        .RightTopStyle {
                            ul {
                                li {
                                    button {
                                        svg {
                                            width: 24px;
                                            height: 24px;
                                        }
                                        span {
                                            font-size: 12px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .SelectDayStage {
        .SelectSchedule {
            .PepleSerchHere {
                input {
                    height: 36px !important;
                    padding: 0 32px 0 14px;
                }
                .SerchIcon {
                    top: 6px;
                    svg {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }
    .SelectDropdown .SeleftDDl .dropdown-toggle {
        font-size: 14px;
        height: 36px;
    }
    .SchedulePageEvent .SetEventSchedule .ScheduleDayStage .SelectDayStage .SelectSchedule .PepleSerchHere .SerchIcon svg {
        width: 18px;
        height: 18px;
    }
    .SchedulePageEvent .SetEventSchedule .ScheduleDayStage .ScheduleTagsKey .TagsBtn {
        font-size: 14px;
        height: 36px;
    }
    .audience-front-stage-roll {
        .MainViewBackStage {
            height: calc(100vh - 256px) !important;
            .FlexResizeStep {
                .ChangeWithSize {
                    .fullFreeContainer {
                        width: 100% !important;
                        .alignAllDiv {
                            width: 100% !important;
                        }
                    }
                }
            }
        }
        .go-backstage-btn {
            bottom: 151px !important;
            .RedCommonBtn {
                font-size: 16px;
                height: 35px;
                padding: 10px 14px;
            }
        }
        .BackstageInfoStream {
            .HeadingBstage {
                height: 44px;
            }
        }
    }
    .audience-front-stage-roll.collapse-out-frontstage {
        .MainViewBackStage {
            height: calc(100vh - 114px) !important;
        }
        .go-backstage-btn {
            bottom: 9px !important;
        }
        .BackstageInfoStream {
            .HeadingBstage {
                height: 44px;
            }
        }
    }
    .Stage-Back-Event-Info {
        height: 128px;
    }
}

@media(min-width:992px) and (max-width:1199px) {
    .col-sm-6.col-md-6.col-lg-4 {
        .SetEventInfo {
            color: #000;
            .StageListSession {
                display: block !important;
                .StageSessionMix {
                    width: 100% !important;
                    height: 45px;
                    margin: 0 0 16px;
                    &::after {
                        top: 13px;
                    }
                }
                .EnterStageBtn {
                    width: 100%;
                }
            }
        }
    }
    .TagScheduleHght {
        height: calc(100vh - 166px);
    }
    .ZoneEnterDiscInfo {
        padding: 5px 28px 0px 40px;
    }
}
@media(min-width:768px) and (max-width:1199px) {
    .scheduleTages {
        .TagNameList {
            width: 80%;
        }
        .TagSelect {
            width: 100px;
        }
    }
    .scheduleTages .TagNameList ul .slick-slider .slick-next {
        right: -18px;
    }
    .scheduleTages .TagSelect p {
        font-size: 14px;
    }
}
@media(max-width: 991px) {
    .MixhubbStage .StageMixhubbEvent .SetEventInfo {
        min-height: auto !important;
    }
    .ZoneEnterDiscInfo {
        padding: 5px 0 0 !important;
    }
    .RoomScheduleTable {
        padding: 5px 14px 0 !important;
    }
    .RoomMeetingPopUp .modal-dialog .modal-content .modal-body .content-wrap .RightTopStyle {
        z-index: 992;
    }
    .container-error {
        .ErrorInbox {
            width: 440px;
            padding: 40px 0 40px;
            .LogoWebinar {
                margin-bottom: 0px;
                .maxImg {
                    width: 170px;
                }
            }
            .ErrorInfo {
                padding: 0 30px;
                margin-top: 0px;
                h3 {
                    font-size: 60px;
                }
                strong {
                    font-size: 16px;
                }
                p {
                    font-size: 14px;
                }
            }
        }
    }
}
@media(min-width:768px) and (max-width:991px) {
	.MixhubbHeader {
        padding: 10px 0;
        height: 48px;
        .HeaderMainTop {
            padding: 0 5px;
            .MixhubbLogo {
                max-width: 95px;
                flex: 0 0 95px;
                height: 48px;
                img {
                    height: 30px;
                    width: 100%;
                    object-fit: scale-down;
                }
            }
            .MixhubbMenu {
                display: flex;
                // max-width: 65%;
                // flex: 0 0 65%;
                ul {
                    li {
                        margin: 0 6px;
                        font-size: 10px;
                        .nav-mixhubb {
                            .IconStyle {
                                width: 26px;
                                height: 26px;
                            }
                            span {
                                margin-left: 8px;
                            }
                            .live-icon {
                                .RecordLiveStream {
                                    bottom: -7px;
                                    .liveRecord {
                                        width: 36px;
                                        font-size: 9px;
                                        padding: 3px 5px;
                                        height: 13px;
                                        .LiveIcon {
                                            width: 6px;
                                            height: 6px;
                                        }
                                    }
                                }
                            }
                        }
                        
                    }
                }
            }    
            .MixhubbRight {
                // max-width: 15%;
                // flex: 0 0 15%;
                .ConvertionChat {
                    position: relative;
                    margin-right: 12px;
                    top: -7px;
                    .chatConvIcon {
                        width: 18px !important;
                        height: 18px !important;
                        cursor: pointer;
                    }
                }
                .mixhubbNotification {
                    top: -3px;
                    .IconStyle {
                        width: 23px !important;
                        height: 23px !important;
                    }
                }
                .dropdown {
                    .dropdown-toggle {
                        width: 22px;
                        height: 22px;
                    }
                }
                .headIcon {
                    .chatConvIcon {
                        width: 24px !important;
                        height: 24px !important;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .maxScreenHeight {
        height: calc(100vh - 48px);
        margin-top: 48px;
        .scale-blur-fix {
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            .BlurFullImgs {
                height: 100%;
            }
        }
    }
       .SelectDropdown {
        .SeleftDDl {
            .dropdown-toggle {
                font-size: 14px;
                height: 36px;
                padding-left: 13px;
            }
        }
    }
    .SchedulePageEvent .SetEventSchedule .ScheduleDayStage .ScheduleTagsKey .TagsBtn {
        font-size: 14px;
        height: 36px;
    }
    .PepleSerchHere {
        .SerchHere {
            height: 36px;
            padding: 0 32px 0 14px;
        }
        .SerchIcon {
            top: 6px;
            svg {
                width: 18px;
                height: 18px;
            }
        }
    }
    
    .PeopleChatPopup {
        top: 50px;
        height: calc(100vh - 53px);
        width: 360px;
    }
    .RightTopStyle {
        top: 70px;
    }

    .SchedulePageEvent {
        .SetEventSchedule {
            .ScheduleDayStage {
                width: 90%;
                padding: 8px 0;
                padding-right: 12px;
                padding-left: 22px;
                h4 {
                    font-size: 20px;
                    svg {
                        margin-right: 10px;
                    }
                }
                .SelectDayStage {
                    .SelectDays {
                        .SelectDropdown {
                            .dropdown {
                                font-size: 14px;
                                height: 38px;
                            }
                        }
                    }
                    .DayScheduleStages {
                        width: 168px!important;
                        margin-right: 8px !important;
                    }
                    .SelectSchedule  {
                        margin-right: 0px !important;
                    }
                }
            }
            .RoomSeatSelect {
                .RoomMeetSrch {
                    .RoomsSerchHere {
                        width: 250px;
                    }
                }
            }
        }
    }
    
    .ZoneEnterDiscInfo {
        .EnterZoneBooth {
            .maxHeightBooth {
                h3 {
                    font-size: 18px;
                }
                p {
                    font-size: 14px;
                }
            }
        }
    }
    .AllPlanSchedule {
        //padding: 25px 20px 0;
        .ScheduleCalendarInfos {
            .StageFullInfoFix {
                //margin-bottom: 38px;
                //height: 256px;
                .CalenderDateScheduleInof {
                    padding: 40px 12px 32px;
                    .StageNo {
                        padding: 0px 16px;
                    }
                    .DtStageEventDay {
                        h4 {
                            line-height: 34px;
                            font-size: 24px;
                        }
                    }
                    .ScheduleTMFx {
                        margin: 6px 0 16px;
                        p {
                            font-size: 20px;
                        }
                    }
                    .ScheduleStatusOngoing {
                        //margin: 10px 0 0;
                        label {
                            font-size: 15px;
                        }
                    }

                }
                .calenderInfoGoogle {
                    a {

                        svg {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
            .ScheduleInfoWithConts {
                width: calc(100% - 170px);
                position: relative;
                h3 {
                    margin: 0 0 5px;
                    font-size: 18px;
                }
                p {
                    font-size: 14px;
                }
                .Watch-Session {
                    .RedButton {
                        padding: 6px 14px;
                        font-size: 14px;
                    }
                    .BlueinButton {
                        padding: 6px 14px;
                        font-size: 14px;
                    }
                }
            }
            .SpeakerRowList {
                .EventSpeakerExp {
                    padding: 10px 10px;
                    //margin-right: 15px;
                    //min-height: 84px;
                    .ImgSpeakerBg {
                        width: 45px;
                        height: 45px;
                    }
                    .SpeakerNamePos {
                        margin-left: 12px;
                        padding: 3px 0;
                        h4 {
                            font-size: 14px;
                        }
                        p {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
        .RoomMeetingBoard {
            .Available10Seats {
                ul {
                    li {
                        margin: 4px !important;
                    }
                    .SelectSeat {
                        &:first-child {
                           .UserInfoDetail {
                                left: -26px;
                           }
                           .bottomArrow {
                               left: -72px !important;
                           }
                        }
                        &:nth-child(6) {
                            .UserInfoDetail {
                                left: -26px;
                           }
                           .bottomArrow {
                               left: -72px !important;
                           }
                         }
                    }
                }
            }
        }
    }
    .MixhubbStage {
        overflow-y: inherit;
        .StageMixhubbEvent {
            .SetEventInfo {
                padding: 32px 20px 20px;
                .SetupStatus{
                    span {
                        padding: 2px 28px;
                    }
                }
                h3 {
                    font-size: 20px;
                }
                .AboutSetupInfo {
                    background-color: var(--light-gray);
                    padding: 10px 15px;
                    margin: 15px 0 20px;
                    h4 {
                        font-size: 15px;
                    }
                    p {
                        font-size: 14px;
                    }
                }
    
                .ExpoSetupInfo {
                    min-height: 88px;
                }
                .StageListSession {
                    display: block !important;
                    .StageSessionMix {
                        width: 100% !important;
                        height: 45px;
                        margin: 0 0 16px;
                        &::after {
                            top: 13px;
                        }
                    }
                    .EnterStageBtn {
                        width: 100%;
                    }
                }

            }
        }
    }



    .RoomMeetingBoard {
        .RoomEventLogo {
            width: 90px;
            top: -13px;
            padding: 1px 8px;
        }
    }

    .RoomMeetingBoard {
        .roomChairBlock {
            ul {
                li {
                    margin: 4px;
                    width: 36px;
                    height: 36px;
                }
                .SelectSeat {
                    font-weight: bold;
                    font-size: 14px;
                }
            }
        }
    }
    .RoomMeetingBoard {
        .RoomCountNo {
            margin: 10px 15px 6px;
            p {
                font-size: 16px;
            }
        }
    }

    .AllPlanSchedule::-webkit-scrollbar {
        width: 4px !important;
    }
     .NetwokingProfileQuick {
        margin-top: 20px;
        padding: 10px 12px;
        .ProfilePicInfo {
            width: 48px;
            height: 48px;
            margin-right: 6px;
        }
        .ProfileTxtInfo {
            width: 58%;
            position: relative;
            h4 {
                font-size: 14px;
                font-weight: 500;
                margin: 0 0 2px;
            }
            p {
                font-size: 12px;
            }
        }
        .ReadProfileIcon {
            width: 38px;
            height: 38px;
            padding: 11px !important;
        }
    }
    .RecommendProfiles p {
        margin: 18px 0 0;
        font-size: 18px;
        line-height: 22px;
    }


    .QuickShufflingMatch {
        background-color: var(--light-gray);
        padding: 22px 25px;
        border-radius: 16px;
        margin-top: 30px;
        h2 {
            font-size: 20px;
        }
        p {
            font-size: 14px;
            margin: 0 0 4px;
            font-weight: 400;
        }
        .MatchQuickGIF {
            padding: 22px 28px;
        }
        .RedButton {
            width: 160px;
            margin: 0px auto;
            display: table;
            font-size: 15px;
            font-weight: 700;
            svg {
                width: 20px;
                height: 20px;
            }
        }
    }

    .NetworkIngAllAttendess {
        .InterestTag {
            margin-left: 0;
            margin-top: 18px;
            span {
                margin-left: 0px;
                margin-right: 6px;
            }
            p {
                margin-bottom: 0;
            }
        }
        .FilderToggleBox {
            .FilterToggleInfo {
                width: 355px;
                .InputHere {
                    .form-control {
                        width: 250px;
                        height: 40px;
                    }
                }
            }
        }
        .AttendeesHeader {
            .LeftSideAttendess {
                width: 315px;
                h4 {
                    font-size: 20px;
                }
                .RedButton {
                    width: 110px;
                    font-size: 16px;
                }
            }
        }
    }
    .NetworkIngAllAttendess {
        .RIghtAttendesSearch {
            .NetworkPeople {
                width: 232px;
            }
        }
    }
    .RecommendProfiles {
        padding: 8px 0 0;
        h4 {
            margin: 0 0 0px;
        }
    }
    .MixhubbLobby {
        .LobbySelImag {
            .BgLobbyFix {
                .LobbyBgWidgetHg {
                    .imageFeatureWidget {
                        width: auto;
                        height: auto;
                    }
                }
            }
        }
    }
    .RegisterPageAll {
        display: block;
        height: auto;
        .LeftImgBlock {
            width: 100%;
            height: auto;
            display: block;
            .scale-blur-fix {
                .LoginLeftHg {
                    height: auto;
                    aspect-ratio: 16/9;
                }
            }
        }
        .LoginRegisterPage {
            width: 100%;
            min-width: 100%;
            position: relative;
            overflow: initial;
            padding-bottom: 60px;
            .MixhubbExpDae {
                padding: 24px 20px;
                .SocialMicro {
                    width: 130px;
                    font-size: 20px;
                    margin: 18px auto 0px;
                    svg {
                        width: 24px;
                        height: 24px;
                        margin-right: 6px;
                    }
                }
            }
            .CurrentLanguage {
                top: 26px;
                right: 18px;
                span {
                    padding: 10px 40px 10px 20px;
                    font-size: 16px;
                    svg {
                        width: 28px;
                    }
                    &:before {
                        width: 10px;
                        height: 10px;
                        right: 20px;
                        top: 14px;
                    }
                }
            }
            .EnterEventAction {
                width: 390px;
                h2 {
                    font-size: 32px;
                }
                .EventTypeActonIn {
                    .form-group {
                        .form-control {
                            padding: 8px 28px;
                            height: 60px;
                            font-size: 18px;
                            font-weight: 500;
                        }
                        .EnterBtn {
                            border-radius: 100px;
                            padding: 13px 28px;
                            font-size: 21px;
                        }
                        .OrTxt {
                            span {
                                font-size: 18px;
                            }
                        }
                    }
                }
                .SocialLoginType {
                    ul {
                        li {
                            svg {
                                width: 60px;
                                height: 60px;
                            }
                            button {
                                img {
                                    width: 60px;
                                    height: 60px;
                                }
                                .img-fluid {
                                    width: 60px;
                                    height: 60px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .EventCalSchedules {
        h5 {
            width: 90%;
        }
    }
    .EventCalSchedules .EventCalenderIcon img {
        width: 52px;
        height: 52px;
        margin: 0 6px;
    }



    .timer-countDown {
        .timerBodyCondown {
            width: 480px;
            div {
                .time-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    .time {
                        font-size: 38px;
                        line-height: 44px;
                        font-weight: 600;
                    }
                    div {
                        font-size: 12px;
                    }
                }
            }
        }
    }
    .RegisterPageAll .LoginRegisterPage .mixhubbLogo {
        width: 250px;
    }
    .SchedulePageEvent .SetEventSchedule {
       // margin-top: 75px;
    }
    .StageBounded {
        .VideoBroadcastLive {
            img {
                height: auto !important;
            }   
        }
    }
    .StageRecordingView {
        margin-top: 0px !important;
    }
    .BackStageSessionInfo {
        width: 340px;
    }
    .screen-stage-main-div {
        .MainViewBackStage {
            .alignAllDiv  {
                width: 100% !important;
                .front-stage-stream-scroll {
                    .row {
                        .stage-user-list {
                            .RightSvgIcon {
                                .mh-cursor-pointer {
                                    top: 3px;
                                    left: 3px;
                                    width: 18px;
                                    height: 18px;
                                    padding: 4px !important;
                                }
                            }
                            .list-1, .list-2, .list-3, .list-4, .list-5, .list-6, .list-7, .list-8, .list-9, .list-10, .list-11, .list-12, .list-13, .list-14, .list-15, .list-16 {
                                height: auto;
                                display: inline-flex;
                                width: 120px
                            }
                        }
                    }
                }
            }
           
        }
    }



    .screen-stage-main-div.oraganizer-backstage-dashboard {
        .MainViewBackStage  {
            .alignAllDiv {
                .front-stage-stream-scroll {
                    width: 100% !important;
                    .row {
                        flex-flow: column;
                        .front-stage-share-screen {
                            width: 100% !important;
                            height: auto !important;
                        }
                        .stage-user-list {
                            width: 100% !important;
                            overflow: auto;
                            overflow-y: hidden;
                            display: inline-flex;
                            flex-flow: row nowrap;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 6px 4px;
                            position: relative !important;
                            height: auto !important;
                            margin-left: 6px;
                            .video_footer {
                                .ShortName {
                                    h4 {
                                        width: 45px;
                                        height: 45px;
                                        font-size: 22px;
                                    }
                                }
                                .bottom_footer {
                                    h4 {
                                        font-size: 10px !important;
                                        span {
                                            line-height: 14px !important;
                                        }
                                    }
                                }
                                .BackIconAction {
                                    top: 5px;
                                    right: 3px;
                                    .ActionLive {
                                        width: 18px;
                                        height: 18px;
                                        .widthSvg {
                                            padding: 3px 3px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }




    .screen-stage-main-div.oraganizer-backstage-dashboard.collapse-out-frontstage {
        .MainViewBackStage {
            .alignAllDiv {
                width: 100% !important;
                .front-stage-stream-scroll {
                    width: 100% !important;
                    .row {
                        .stage-user-list {
                            height: auto !important;
                        }
                    }
                }
            }
        }
    }

    .screen-stage-main-div.backstage-dashboard {
        .MainViewBackStage  {
            .alignAllDiv {
                width: 100% !important;
                .front-stage-stream-scroll {
                    width: 100% !important;
                    .row {
                        flex-flow: column;
                        .front-stage-share-screen {
                            width: 100% !important;
                            height: auto !important;
                        }
                        .stage-user-list {
                            width: 100% !important;
                            overflow: auto;
                            overflow-y: hidden;
                            display: inline-flex;
                            flex-flow: row nowrap;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 6px 4px;
                            position: relative !important;
                            height: auto !important;
                            margin-left: 6px;
                            .video_footer {
                                .ShortName {
                                    h4 {
                                        width: 45px;
                                        height: 45px;
                                        font-size: 22px;
                                    }
                                }
                                .bottom_footer {
                                    h4 {
                                        font-size: 10px !important;
                                        span {
                                            line-height: 14px !important;
                                        }
                                    }
                                }
                                .BackIconAction {
                                    top: 5px;
                                    right: 3px;
                                    .ActionLive {
                                        width: 18px;
                                        height: 18px;
                                        .widthSvg {
                                            padding: 3px 3px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .screen-stage-main-div.backstage-dashboard.collapse-out-frontstage {
        .MainViewBackStage {
            .alignAllDiv {
                width: 100% !important;
                .front-stage-stream-scroll {
                    width: 100% !important;
                    .row {
                        .stage-user-list {
                            height: auto !important;
                        }
                    }
                }
            }
        }
    }

    .screen-stage-main-div.organizer-frontend-dashboard {
        .MainViewBackStage  {
            .alignAllDiv {
                .front-stage-stream-scroll {
                    width: 100% !important;
                    .row {
                        flex-flow: column;
                        .front-stage-share-screen {
                            width: 100% !important;
                            height: auto !important;
                        }
                        .stage-user-list {
                            width: 100% !important;
                            overflow: auto;
                            overflow-y: hidden;
                            display: inline-flex;
                            flex-flow: row nowrap;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 6px 4px;
                            position: relative !important;
                            height: auto !important;
                            margin-left: 6px;
                            .video_footer {
                                .ShortName {
                                    h4 {
                                        width: 45px;
                                        height: 45px;
                                        font-size: 22px;
                                    }
                                }
                                .bottom_footer {
                                    h4 {
                                        font-size: 10px !important;
                                        span {
                                            line-height: 14px !important;
                                        }
                                    }
                                }
                                .BackIconAction {
                                    top: 5px;
                                    right: 3px;
                                    .ActionLive {
                                        width: 18px;
                                        height: 18px;
                                        .widthSvg {
                                            padding: 3px 3px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .screen-stage-main-div.frontend-dashboard {
        .MainViewBackStage  {
            .alignAllDiv {
                .front-stage-stream-scroll {
                    width: 100% !important;
                    .row {
                        flex-flow: column;
                        .front-stage-share-screen {
                            width: 100% !important;
                            height: auto !important;
                        }
                        .stage-user-list {
                            width: 100% !important;
                            overflow: auto;
                            overflow-y: hidden;
                            display: inline-flex;
                            flex-flow: row nowrap;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 6px 4px;
                            position: relative !important;
                            height: auto !important;
                            margin-left: 6px;
                            .video_footer {
                                .ShortName {
                                    h4 {
                                        width: 45px;
                                        height: 45px;
                                        font-size: 22px;
                                    }
                                }
                                .bottom_footer {
                                    h4 {
                                        font-size: 10px !important;
                                        span {
                                            line-height: 14px !important;
                                        }
                                    }
                                }
                                .BackIconAction {
                                    top: 5px;
                                    right: 3px;
                                    .ActionLive {
                                        width: 18px;
                                        height: 18px;
                                        .widthSvg {
                                            padding: 3px 3px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .audience-front-stage-roll.screen-stage-main-div {
        .MainViewBackStage  {
            .alignAllDiv {
                .front-stage-stream-scroll {
                    width: 100% !important;
                    .row {
                        flex-flow: column;
                        .front-stage-share-screen {
                            width: 100% !important;
                            height: auto !important;                            
                        }
                        .stage-user-list {
                            width: 100% !important;
                            overflow: auto;
                            overflow-y: hidden;
                            display: inline-flex;
                            flex-flow: row nowrap;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 6px 4px;
                            position: relative !important;
                            height: auto !important;
                            margin-left: 6px;
                            .video_footer {
                                .ShortName {
                                    h4 {
                                        width: 45px;
                                        height: 45px;
                                        font-size: 22px;
                                    }
                                }
                                .bottom_footer {
                                    h4 {
                                        font-size: 10px !important;
                                        span {
                                            line-height: 14px !important;
                                        }
                                    }
                                }
                                .BackIconAction {
                                    top: 5px;
                                    right: 3px;
                                    .ActionLive {
                                        width: 18px;
                                        height: 18px;
                                        .widthSvg {
                                            padding: 3px 3px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .MainViewBackStage {
        .alignAllDiv {
            .front-stage-stream-scroll {
                .localPlayer {
                    div {
                        border-radius: 5px;
                        video {
                            border-radius: 5px;
                        }
                    }
                }
            }
        }
    }

    .audience-front-stage-roll {
        .MainViewBackStage {
            .RemoveAllDiv {
                width: 100% !important;
                .ront-stage-stream-scroll {
                    width: 100% !important;
                }
            }
        }
    }

    .oraganizer-backstage-dashboard {
        .MainViewBackStage {
            height: calc(100vh - 250px) !important;
            .RemoveAllDiv {
                width: 100% !important;
                .front-stage-stream-scroll {
                    width: 100% !important;
                }
            }
        }
    }
    .oraganizer-backstage-dashboard.collapse-out-frontstage {
        .MainViewBackStage {
            height: calc(100vh - 110px) !important;
            .RemoveAllDiv {
                width: 100% !important;
                .front-stage-stream-scroll {
                    width: 100% !important;
                }
            }
        }
    }

    .audience-front-stage-roll {
        .MainViewBackStage {
            .RemoveAllDiv {
                width: 100% !important;
                .front-stage-stream-scroll {
                    width: 100% !important;
                }
            }
        }
    }
    .backstage-dashboard {
        .MainViewBackStage {
            height: calc(100vh - 255px) !important;
            margin: 6px 0 !important;
            .RemoveAllDiv {
                width: 100% !important;
                .front-stage-stream-scroll {
                    width: 100% !important;
                }
            }
        }
    }
    .backstage-dashboard.collapse-out-frontstage {
        .MainViewBackStage {
            height: calc(100vh - 112px) !important;
            margin: 6px 0 !important;
            .RemoveAllDiv {
                width: 100% !important;
                .front-stage-stream-scroll {
                    width: 100% !important;
                }
            }
        }
    }
    .RegisterPageAll {
        .LeftImgBlock {
            .LoginEventLogo {
                top: 12px;
                left: 12px;
                width: 140px;
            }
        }
    }
    .oraganizer-backstage-dashboard {
        .MainViewBackStage {
            height: calc(100vh - 250px) !important;
            .RemoveAllDiv {
                width: 100% !important;
                height: auto !important;
                .front-stage-stream-scroll {
                    width: 97% !important;
                }
            }
        }
    }
    .oraganizer-backstage-dashboard.collapse-out-frontstage {
        .MainViewBackStage {
            height: calc(100vh - 110px) !important;
            .RemoveAllDiv {
                width: 100% !important;
                height: auto !important;
                .front-stage-stream-scroll {
                    width: 97% !important;
                }
            }
        }
    }

    .audience-front-stage-roll {
        .MainViewBackStage {
            .FlexResizeStep {
                .ChangeWithSize {
                    .fullFreeContainer {
                        width: 100%;
                        height: auto !important;
                        .RemoveAllDiv {
                            width: 100% !important;
                            height: auto !important;
                            .front-stage-stream-scroll {
                                width: 97% !important;
                            }
                        }
                    }
                }
            }
            
        }
    }
    .backstage-dashboard {
        .MainViewBackStage {
            height: calc(100vh - 255px) !important;
            margin: 6px 0 !important;
            .RemoveAllDiv {
                width: 100% !important;
                height: auto !important;
                .front-stage-stream-scroll {
                    width: 97% !important;
                }
            }
        }
    }
    .backstage-dashboard.collapse-out-frontstage {
        .MainViewBackStage {
            height: calc(100vh - 112px) !important;
            margin: 6px 0 !important;
            .RemoveAllDiv {
                width: 100% !important;
                height: auto !important;
                .front-stage-stream-scroll {
                    width: 97% !important;
                }
            }
        }
    }
    .AttendeeFrame {
        .fullScreen {
            //position: relative !important;
            overflow: hidden;
            width: 100%;
            aspect-ratio: 16/9;
            iframe {
            width: 100% !important;
            height: 100% !important;
            margin: 0px !important;
            }
            .ControlFrame {
            background-color: #000000 !important;
            padding: 6px 5px!important;
            }
        }
    }
    .backstage-dashboard {
        .MainViewBackStage {
            height: calc(100vh - 255px) !important;
            margin: 6px 0 !important;
            .RemoveAllDiv {
                width: 100% !important;
                height: auto !important;
                .front-stage-stream-scroll {
                    width: 97% !important;
                }
            }
        }
    }
    .frontend-dashboard {
        .MainViewBackStage {
            height: calc(100vh - 52px) !important;
            margin: 6px 0 !important;
            .RemoveAllDiv {
                width: 100% !important;
                height: auto !important;
                .front-stage-stream-scroll {
                    width: 97% !important;
                }
            }
        }
    }
    .audience-front-stage-roll.screen-stage-main-div {
        .container-fluid {
            .MainViewBackStage {
                .FlexResizeStep {
                    .ChangeWithSize {
                        .fullFreeContainer {
                            .RemoveAllDiv {
                                height: auto !important;
                                img {
                                    height: auto !important;
                                    aspect-ratio: 16/9;
                                    object-fit: scale-down;
                                }
                            }
                        }
                    }
                }
               
            }
        }
    }
    .ZoneEnterDiscInfo {
        height: calc(100vh - 152px);
    }
    .ZoneListExpoList {
        height: calc(100vh - 142px);
    }
   
    
    .AllPlanSchedule {
        height: calc(100vh - 225px);
        overflow-y: auto;
        padding: 14px 20px 0 0;
    }
    .TagScheduleHght {
        height: calc(100vh - 155px);
        padding: 14px 20px 0 0px;
    }
    .RoomScheduleTable {
        height: calc(100vh - 148px);
        overflow-y: auto;
        padding: 5px 0px 0 0;
    }
    .TagRoomsInfo {
        height: calc(100vh - 218px);
        overflow-y: auto;
        padding: 5px 0px 0 0;
      }
      .BackstageInfoStream {
        .HeadingBstage {
            .BsMidAction {
                height: 36px !important;
                .BsEnd {
                    .RedCommonBtn {
                        padding: 2px 24px;
                        font-size: 16px;
                        height: 32px;
                    }
                }
                .ActionLive {
                    margin: 0 4px;
                    width: 24px;
                    height: 24px;
                }
                .BsSetting {
                    width: 18px !important;
                    height: 18px !important;
                }
            }
        }
    }
    .TagLengthInfo {
        height: calc(100vh - 204px);
    }
    .TagLengthInfo.ZoneListExpoList {
        height: calc(100vh - 212px);
      }
      .AdvanceFormMicrosite .modal-dialog {
        margin: 33px auto !important;
        max-width: 88%;
    }
    .audience-front-stage-roll {
        .MainViewBackStage {
            height: calc(100vh - 246px) !important;
        }
        .go-backstage-btn {
            bottom: 151px !important;
            .RedCommonBtn {
                font-size: 16px;
                height: 35px;
                padding: 10px 14px;
            }
        }
        .BackstageInfoStream {
            .HeadingBstage {
                height: 44px;
            }
        }
    }
    .audience-front-stage-roll.collapse-out-frontstage {
        .MainViewBackStage {
            height: calc(100vh - 104px) !important;
        }
        .go-backstage-btn {
            bottom: 9px !important;
        }
        .BackstageInfoStream {
            .HeadingBstage {
                height: 44px;
            }
        }
    }
    .Stage-Back-Event-Info {
        height: 128px;
    }
    .RegFooter {
        padding: 14px 0;
        width: 100%;
        min-width: 100%;
        ul {
            li {
                font-size: 14px;
            }
        }
    }
    .SchedulePageEvent .ExpoScheduleInfos {
        width: 92%;
    }
    .AllPlanSchedule {
        padding: 10px 15px 0 0px;
        .ScheduleCalendarInfos {
            .StageFullInfoFix {
                height: auto;
                border-radius: 12px;
                display: block;
                .CalenderDateScheduleInof {
                    width: 100%;
                    display: flex;
                    cursor: cell;
                    justify-content: space-between;
                    padding: 12px 18px 12px;
                    border-radius: 20px 20px 0 0;
                    flex-flow: inherit;
                    margin: 0px 0 0;
                    .StageNo {
                        padding: 2px 16px;
                    }
                    .DtStageEventDay {
                        h4 {
                            line-height: 26px;
                        }
                    }
                    .ScheduleTMFx {
                        margin: 5px 0 0;
                        p {
                            font-size: 16px;
                        }
                    }
                    .ScheduleStatusOngoing {
                        margin: 10px 0 0;
                        display: none;
                        label {
                            font-weight: 600;
                        }
                    }
                    .calenderInfoGoogle {
                        a {
                            svg {
                                width: 18px;
                                height: 18px;
                            }
                        }
                    }
                    .SchTimeDateFix {
                        width: 50%;
                        text-align: left;
                        .DtStageEventDay {
                            h4 {
                                font-weight: 600;
                                strong {
                                    display: inline-block;
                                    margin-left: 6px;
                                    font-weight: 600;
                                }
                            }
                        }
                        .ScheduleTMFx {
                            p {
                                font-weight: 500 !important;
                                color: #8944FC;
                            }
                        }
                        .ScheduleStatusOngoing {
                            display: block !important;
                            .RecordLiveStream {
                                position: inherit !important;
                            }
                            .EventStyle {
                                position: inherit !important;
                                font-weight: 500;
                                padding: 4px 14px;
                                font-size: 13px;
                                margin: 6px 0 0;
                                text-align: center;
                                overflow: initial !important;
                                display: block !important;
                            }
                        }
                    }
                }
            }
            .ScheduleInfoWithConts {
                width: 100%;
                padding: 20px 20px 18px;
                position: relative;
                h3 {
                    margin: 0 0 5px;
                    font-size: 16px;
                    color: #8944FC ;
                }
                p {
                    font-size: 14px;
                }
                .BackAgain {
                    height: auto;
                    bottom: 0;
                    top: auto;
                    width: 100%;
                    text-align: center;
                    justify-content: center;
                    border-radius: 0px 0px 12px 12px;
                    a {
                        width: 100%;
                        text-align: center;
                        padding: 10px 0;
                        display: block;
                    }
                }
               
            }
            .SpeakerRowList {
                //margin: 20px 0 20px;
                .EventSpeakerExp {
                    padding: 12px 10px;
                    //margin-right: 10px;
                    //width: 100%;
                    .SpeakerNamePos {
                        margin-left: 12px;
                    }
                    .SpeakerNamePos {
                        h4 {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
    .ModalCommonInfo .modal-dialog .modal-content .modal-body .MeetingNewSetup .DayMeetingTimeSetup .MobBackstage {
        display: none;
    }
    .SideRightMenu, .BackstageInfoStream {
        display: none !important;
    }
    .StageTabCollapes {
        display: block !important;
    }
    .StageTabCollapes {
        position: fixed;
        width: 98%;
        bottom: 2px;
        z-index: 95;
        margin: 0px auto !important;
        left: 0;
        right: 0;
        .tab-content {
            .PeopleChatPopup {
                height: auto !important;
                position: inherit;
                border-radius: 10px 10px 0px 0px;
                width: 100%;
                box-shadow: none !important;
                padding: 0;
                .responsive-tabs-container {
                    padding: 0 0px;
                    .nav-tabs {
                        padding: 14px 38px 14px;
                        height: 62px;
                        justify-content: space-between;
                        li {
                            margin: 0 0 0;
                            .nav {
                                .nav-link {
                                    padding: 0px 0px;
                                    border-radius: 4px;
                                    font-size: 11px;
                                    font-weight: 400;
                                    color: #000;
                                    background: transparent;
                                    svg {
                                        fill: #000000;
                                    }
                                    &.active {
                                        padding: 0 0;
                                        padding: 0px 0px;
                                        border-radius: 4px;
                                        background: transparent;
                                        color: #1A78E5;
                                        svg {
                                            fill: #1A78E5;
                                        }
                                    }
                                    svg {
                                        display: block;
                                        margin: 0 auto;
                                        width: 26px;
                                        height: 26px; 
                                    }
                                }
                            }
                        }
                    }
                    .CloseButton {
                        display: none;
                    }
                }
                
                .StateEventDtl {
                    height: calc(100vh - 624px) !important;
                    .tab-content {
                        .front-stage-active {
                           .nav {
                                display: none;
                           }
                        }
                    }

                }
                .StageBackStage {
                    .nav-tabs {
                        li {
                            .nav-link {
                                padding: 10px 0 !important;
                                &.active {
                                    padding: 10px 0 !important;
                                }
                            }
                        }
                    }
                }
            }
            .ReadMoreDeso {
                background-color: #f0f0f0;
                padding: 12px 2px;
                .AboutTabsFull {
                    margin: 0px 0;
                    overflow-y: auto;
                    height: calc(100vh - 395px);
                    padding: 0 12px;
                    .ScheduleDayEvent {
                        margin: 14px 0;
                        span {
                            background-color: #ffffff;
                            font-size: 14px;
                            padding: 6px 12px;
                            font-weight: 500;
                            color: #1F1B20;
                            border-radius: 4px;
                        }
                    }
                    h4 {
                        color: #1A78E5;
                        font-size: 18px;
                        margin: 0;
                    }
                    p {
                        font-size: 14px;
                        margin: 18px 0 0;
                        color: #1F1B20;
                        word-break: break-word;
                    }
                    .SectionWatch {
                    width: 100%;
                    text-align: left;
                    margin: 18px 0 2px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .RedButton  {
                        padding: 6px 16px;
                        font-size: 15px;
                        width: 145px;
                    }
                    .PopUpImgSpeaker {
                        .SpkInfoImgs {
                            width: 50px;
                            height: 50px;
                            display: inline-block;
                            margin-right: 10px;
                            img {
                                width: 100%;
                                height: 100%;
                                border-radius: 100px;
                        }
                        }
                        .SpkInfoCount {
                            border: 2px #1A78E5 solid;
                            color: #1A78E5;
                            width: 46px;
                            height: 46px;
                            border-radius: 100px;
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
    .audience-front-stage-roll .MainViewBackStage .DivChangeWidthView .ChangeWidthScroll .flexDicSeps .alignAllDiv .front-stage-share-screen {
        width: 94% !important;
        height: auto;
    }

    .audience-front-stage-roll.collapse-out-frontstage {
        position: absolute;
        height: auto;
        overflow: hidden;
        top: auto;
        aspect-ratio: 16/9;
    }
    .SlideCommonPopup .StateEventDtl .StageBackStage .nav-tabs {
        display: none;
    }
    #people {
        display: none;
    }
    #aboutInfo {
        display: block !important;
    }
    .audience-front-stage-roll.collapse-out-frontstage {
        height: auto;
        overflow-y: hidden!important;
    }
    .StageBounded {
        height: auto!important;
    }
    .audience-front-stage-roll {
        .container-fluid {
            .MainViewBackStage {
                height: calc(100vh - 248px) !important;
                .FlexResizeStep {
                    .ChangeWithSize {
                        height: 100% !important;
                        .fullFreeContainer {
                            height: 100% !important;
                            width: 100% !important;
                            .RemoveAllDiv {
                                width: 100% !important;
                                height: 100% !important;
                                img {
                                    height: auto !important;
                                    aspect-ratio: 16/9;
                                    object-fit: scale-down;
                                }
                                .front-stage-stream-scroll {
                                    height: auto !important;
                                    width: 100% !important;
                                    padding: 0;
                                    .row {
                                        .stage-user-list {
                                            .video_footer {
                                                .ShortName {
                                                    h4 {
                                                        width: 45px;
                                                        height: 45px;
                                                        font-size: 22px;
                                                    }
                                                }
                                                .bottom_footer {
                                                    h4 {
                                                        font-size: 10px !important;
                                                        span {
                                                            line-height: 14px !important;
                                                        }
                                                    }
                                                }
                                                .BackIconAction {
                                                    top: 5px;
                                                    right: 3px;
                                                    .ActionLive {
                                                        width: 18px;
                                                        height: 18px;
                                                        .widthSvg {
                                                            padding: 3px 3px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                
                            }
                        }
                    }
                }
               
            }
        }
    }
    .audience-front-stage-roll.screen-stage-main-div {
        .container-fluid {
            .MainViewBackStage {
                height: calc(100vh - 250px) !important;
                .FlexResizeStep {
                    .ChangeWithSize {
                        height: 100% !important;
                        .fullFreeContainer {
                            height: 100% !important;
                            .RemoveAllDiv {
                                height: 100% !important;
                                img {
                                    height: auto !important;
                                    aspect-ratio: 16/9;
                                    object-fit: scale-down;
                                }
                                .front-stage-stream-scroll {
                                    height: 100% !important;
                                    .row {
                                        .stage-user-list {
                                            width: 100% !important;
                                            overflow: auto;
                                            overflow-y: hidden;
                                            display: inline-flex;
                                            flex-flow: row nowrap;
                                            align-items: center;
                                            justify-content: flex-start;
                                            padding: 0px 4px;
                                            height: auto !important;
                                            margin-left: 6px;
                                            position: absolute !important;
                                            bottom: 12px;
                                            .video_footer {
                                                .ShortName {
                                                    h4 {
                                                        width: 45px;
                                                        height: 45px;
                                                        font-size: 22px;
                                                    }
                                                }
                                                .bottom_footer {
                                                    h4 {
                                                        font-size: 10px !important;
                                                        span {
                                                            line-height: 14px !important;
                                                        }
                                                    }
                                                }
                                                .BackIconAction {
                                                    top: 5px;
                                                    right: 3px;
                                                    .ActionLive {
                                                        width: 18px;
                                                        height: 18px;
                                                        .widthSvg {
                                                            padding: 3px 3px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                
                            }
                        }
                    }
                }
               
            }
        }
    }
    .audience-front-stage-roll.collapse-out-frontstage.screen-stage-main-div {
        .container-fluid {
            .MainViewBackStage {
                //height: calc(100vh - 108px) !important;
                height: auto !important;
                .FlexResizeStep {
                    .ChangeWithSize {
                        height: 100% !important;
                        .fullFreeContainer {
                            height: 100% !important;
                            .RemoveAllDiv {
                                height: 100% !important;
                                img {
                                    height: auto !important;
                                    aspect-ratio: 16/9;
                                    object-fit: scale-down;
                                }
                                .front-stage-stream-scroll {
                                    height: 100% !important;
                                    width: 100% !important;
                                    .row {
                                        .front-stage-share-screen {
                                            width: 100% !important;
                                            height: auto !important;
                                            padding: 4px !important;
                                        }
                                        .mux-player {
                                            padding: 0 0;
                                        }
                                        .stage-user-list {
                                            width: 100% !important;
                                            overflow: auto;
                                            overflow-y: hidden;
                                            display: inline-flex;
                                            flex-flow: row nowrap;
                                            align-items: center;
                                            justify-content: flex-start;
                                            padding: 0px 4px;
                                            height: auto !important;
                                            margin-left: 6px;
                                            position: absolute !important;
                                            bottom: 80px;
                                            .video_footer {
                                                .ShortName {
                                                    h4 {
                                                        width: 45px;
                                                        height: 45px;
                                                        font-size: 22px;
                                                    }
                                                }
                                                .bottom_footer {
                                                    h4 {
                                                        font-size: 10px !important;
                                                        span {
                                                            line-height: 14px !important;
                                                        }
                                                    }
                                                }
                                                .BackIconAction {
                                                    top: 5px;
                                                    right: 3px;
                                                    .ActionLive {
                                                        width: 18px;
                                                        height: 18px;
                                                        .widthSvg {
                                                            padding: 3px 3px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                
                            }
                        }
                    }
                }
               
            }
        }
    }
    .audience-front-stage-roll .BackstageInfoStream .HeadingBstage {
        height: 48px !important;
    }
    .audience-front-stage-roll.collapse-out-frontstage {
        height: auto;
        overflow-y: hidden !important;
        align-items: center;
        justify-content: center;
        display: flex;

        .container-fluid {
            padding: 0 6px;
            .MainViewBackStage {
                height: auto !important;
                margin-top: 0px !important;
                .FlexResizeStep {
                    height: 100% !important;
                    .ChangeWithSize {
                        height: 100% !important;
                        .fullFreeContainer {
                            height: 100% !important;
                            .RemoveAllDiv {
                                height: 100% !important;
                                img {
                                    height: auto !important;
                                    aspect-ratio: 16/9;
                                    object-fit: scale-down;
                                }
                                .front-stage-stream-scroll {
                                    height: 100% !important;
                                    .row {
                                        .stage-user-list {
                                            .video_footer {
                                                .ShortName {
                                                    h4 {
                                                        width: 45px;
                                                        height: 45px;
                                                        font-size: 22px;
                                                    }
                                                }
                                                .bottom_footer {
                                                    h4 {
                                                        font-size: 10px !important;
                                                        span {
                                                            line-height: 14px !important;
                                                        }
                                                    }
                                                }
                                                .BackIconAction {
                                                    top: 5px;
                                                    right: 3px;
                                                    .ActionLive {
                                                        width: 18px;
                                                        height: 18px;
                                                        .widthSvg {
                                                            padding: 3px 3px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                
                            }
                        }
                    }
                }
               
            }
        }
    }
    .audience-front-stage-roll.screen-stage-main-div {
        .MainViewBackStage  {
            .FlexResizeStep {
                .ChangeWithSize {
                    .fullFreeContainer {
                        .alignAllDiv {
                            .front-stage-stream-scroll {
                                width: 100% !important;
                                .row {
                                    flex-flow: column;
                                    .front-stage-share-screen {
                                        width: 100% !important;
                                        height: auto !important;
                                        padding: 0px !important;
                                    }
                                    .stage-user-list {
                                        width: 100% !important;
                                        overflow: auto;
                                        overflow-y: hidden;
                                        display: inline-flex;
                                        flex-flow: row nowrap;
                                        align-items: center;
                                        justify-content: flex-start;
                                        padding: 0px 4px;
                                        height: auto !important;
                                        margin-left: 6px;
                                        position: absolute !important;
                                        bottom: 80px;
                                        .video_footer {
                                            .ShortName {
                                                h4 {
                                                    width: 45px;
                                                    height: 45px;
                                                    font-size: 22px;
                                                }
                                            }
                                            .bottom_footer {
                                                h4 {
                                                    font-size: 10px !important;
                                                    span {
                                                        line-height: 14px !important;
                                                    }
                                                }
                                            }
                                            .BackIconAction {
                                                top: 5px;
                                                right: 3px;
                                                .ActionLive {
                                                    width: 18px;
                                                    height: 18px;
                                                    .widthSvg {
                                                        padding: 3px 3px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .PollQnaBase {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        height: 100% !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            
        }
    }
    .audience-front-stage-roll .MainViewBackStage .FlexResizeStep .SpeakerSizeShrink {
        width: 100% !important;
    }
    .audience-front-stage-roll .StageFootShrink {
        width: 100% !important;
    }
    .audience-front-stage-roll .MainViewBackStage{
        margin: 2px 0 2px !important;
    }
    .PollInfos .PollMhAuto {
        height: calc(100vh - (56.25vw + 45px + 153px)) !important;
        padding: 0 14px 0 !important;
    }
    .QnaInfos .QnaInfosPols {
        height: calc(100vh - (56.25vw + 45px + 238px)) !important;
        padding: 0 24px !important;
        margin-top: 10px;
    }
    .QnaInfos {
        height: calc(100vh - (56.25vw + 45px + 168px)) !important;
        padding: 0px !important;
    }
    .StageTabCollapes .tab-content .ReadMoreDeso {
        background-color: #f0f0f0;
        padding: 0px 2px;
        height: calc(100vh - 142px) !important;
        overflow-y: auto;
        margin: 10px 0 0;
    }

    .go-backstage-btn {
        display: none;
    }
    .SlideCommonPopup .StateEventDtl .EventInfoStage .ChatInfos {
        height: calc(100vh - 716px) !important;
        margin-top: 8px;
    }
    .SlideCommonPopup .StateEventDtl .EventInfoStage .ChatInfos .MessageTextBox {
        bottom: 10px !important;
    }
    .MessageTextBox {
        width: 95%;
    }
    #join {
        display: none;
    }
    .ChatMixInfoMixhubb {
        position: fixed;
        top: auto;
        right: 5px;
        width: 99%;
        height: calc(100vh - 70px);
        z-index: 99;
        bottom: 2px;
    }
    .MobBackstageLive {
        overflow: hidden !important;
        overflow-y: hidden !important;
    }
    // .RegisterPageAdvance .staticPositon .container .row .RegisterWrapper {
    //     display: block !important;
    // }
    // .RegisterPageAdvance .staticPositon .container .row .LeftImgBlock {
    //     width: 100% !important;
    //   }
    //   .RegisterPageAdvance .staticPositon .container .row .RegisterWrapper .LoginRegisterPage {
    //     width: 100% !important;
    //     position: inherit !important;
    //     position: inherit !important;
    //   }
    // body {
    //     overflow: hidden !important;
    // }
    // .RegisterPageAll {
    //     overflow-y: auto;
    //     height: 100vh;
    // }
    // .RegFooter {
    //     display: none;
    // }
}
@media (max-width: 767px) {
	.AllPlanSchedule::-webkit-scrollbar {
        width: 4px !important;
    }
    .SchedulePageEvent .SetEventSchedule .RoomSeatSelect {
        margin: 16px 0 0 !important;
    }
    .scheduleTages {
        margin: 0px 0px 0px;
        .TagNameList {
            ul {
                li {
                    padding: 5px 16px;
                }
            }
        }
    }
    .RightTopStyle {
        position: absolute;
        padding: 2px 0px 2px !important;
        height: 42px;
        top: 15px !important;
        right: 10px;
        border-radius: 100px;
        ul {
            li {
                margin: 0 4px;
                padding: 8px 8px !important;
                border-radius: 100px;
                button {
                     svg {
                         width: 26px;
                         height: 26px;
                     }
                    span {
                        display: none;
                    }
                }
            }
        }
    }
    .MixhubbHeader {
        padding: 10px 0;
        position: fixed;
        z-index: 96;
        width: 100%;
        top: 0;
        left: 0;
        .HeaderMainTop {
            padding: 0 5px;
            .MixhubbMenu {
                display: none;
                padding: 60px 0px 0;
                max-width: 100%;
                flex: 0 0 100%;
                position: fixed;
                background-color: #000;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0px;
                z-index: 99;
                ul {
                    li {
                        margin: 0 0px;
                        font-size: 14px;
                        font-weight: 500;
                        display: block;
                        border-bottom: 1px #464646 solid;
                        padding: 14px 10px;
                        .nav-mixhubb  {
                            .IconStyle {
                                width: 32px;
                                height: 32px;
                            }
                            span {
                                margin-left: 15px;
                            }
                           .live-icon {
                            .RecordLiveStream {
                                top: 50% !important;
                                bottom: auto;
                                right: 0px !important;
                                transform: translateY(-50%);
                                .liveRecord {
                                    font-size: 10px;
                                }
                            }
                           }
                        }
                    }
                }
                &::after {
                    content: '';
                    position: fixed;
                    width: 100%;
                    height: 100%;
                    background-color: #000;
                    left: 0;
                    top: 57px;
                    z-index: -1;
                }

                .MixhubbBottomMenu {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    display: none;
                    .headIcon {
                        width: 100px;
                        background-color: #2A2E34;
                        height: 100px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 12px 10px;
                        flex-direction: column;
                        border-radius: 8px;
                        span {
                            display: block;
                            color: #ffffff;
                            margin-top: 8px;
                            font-size: 14px;
                        }
                        .chatConvIcon {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                            width: 100%;
                            height: 100%;
                            position: relative;
                            .NoticHere {
                                background-color: #eb233c;
                                padding: 0 2px;
                                font-size: 10px;
                                position: absolute;
                                top: 5px;
                                right: 5px;
                                border-radius: 100px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                min-width: 18px !important;
                                border: 2px #fff solid;
                                color: #fff;
                                height: 18px;
                                font-weight: 500;
                                letter-spacing: 0.1px;
                                margin-top: 0px !important;
                            }
                        }
                    }
                }













            }
            .MixhubbRight {
                float: right;
                max-width: 100%;
                align-items: center;
                position: relative;
                align-content: center;
                flex: 0 0 100%;
                height: 45px;
                // .NofifyIco {
                //     position: absolute !important;
                //     right: 0 !important;
                //     margin-right: 0px !important;
                // }
                .ConvertionChat {
                    top: auto;
                }
               .dropdown {
                .dropdown-toggle {
                    width: 28px;
                    height: 28px;
                }
                .dropdown-menu {
                    right: -10px !important;
                    left: auto !important;
                    top: 42px !important;
                }
               }
                .ToggleIcon {
                    position: absolute;
                    display: block;
                    margin: 0;
                    text-indent: -999em;
                    z-index: 993;
                    cursor: pointer;
                    width: 28px;
                    height: 16px;
                    margin-left: 0;
                    left: 0;
                    .menu__bar {
                        display: block;
                        position: absolute;
                        right: 0;
                        width: 20px;
                        height: 2px;
                        transition: top 0.4s ease-out, transform 0.4s ease-out;
                        background-color: #fff;
                        &:nth-child(1) {
                            top: 0;
                        }
                        &:nth-child(2) {
                            top: 7px;
                            width: 24px;
                        }
                        &:nth-child(3) {
                            top: 14px;
                        }
                    }
                }
                .ArrowToggleIcon {
                    .menu__bar {
                        width: 16px;
                        height: 2px;
                        left: 0;
                    }
                    .menu__bar {
                        &:nth-child(1) {
                            top: 4px !important;
                            transform: rotate(-46deg) !important;
                        }
                        &:nth-child(2) {
                            display: none;
                        }
                        &:nth-child(3) {
                            top: 15px !important;
                            transform: rotate(42deg) !important;
                        }
                    }                  
                }
            }
        }
    }
    .EventCalSchedules {
        h5 {
            width: 90%;
        }
    }
    .EventCalSchedules .EventCalenderIcon img {
        width: 52px;
        height: 52px;
        margin: 0 10px;
    }
    .timer-countDown {
        .timerBodyCondown {
            width: 310px;
            overflow: hidden;
            div {
                width: 60px !important;
                height: 60px !important;
                .time-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    .time {
                        font-size: 26px;
                        line-height: 30px;
                    }
                }
            }
            span {
                font-size: 50px;
            }
        }
    }
    .ScheduleCalendarInfos .SpeakerRowList {
        width: 100%;
        display: none;
    }
    .AllPlanSchedule .ScheduleCalendarInfos .ScheduleInfoWithConts p .ReadMoreSec {
        font-size: 16px;
        color: #EC223C;
        font-weight: 600;
        display: block;
        margin: 6px 0 0;
    }
    .StageBounded {
        .VideoBroadcastLive {
            img {
                height: auto !important;
            }   
        }
    }
    .StageRecordingView {
        margin-top: 0px !important;
    }
    .SchedulewMeeting {
        &.show {
            display: flex !important;
            align-items: center;
        }
    }
    .CameraPrevModal {
        &.show {
            display: flex !important;
            align-items: center;
            .modal-dialog {
                width: 95% !important;
            }
        }
    }
    .close_Menu {
        .menu__bar {
            &:nth-child(1) {
                top: 7px !important;
                transform: rotate(45deg) !important;
            }
            &:nth-child(2) {
                display: none !important;
            }
            &:nth-child(3) {
                top: 7px !important;
                transform: rotate(-45deg) !important;
            }
        }
    }
    .maxScreenHeight {
        .scale-blur-fix {
            //height: calc(100vh - 45px);
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
        }
    }
    .BackstageInfoStream {
        .HeadingBstage {
            padding: 6px 12px;
            h3 {
                font-size: 15px;
                position: inherit;
            }
            // .BsMidAction {
            //     right: 0;
            //     left: auto !important;
            //     margin: inherit !important;
            // }
            .collapsDivToggle {
                .CastingToggle {
                    padding: 5px;
                }
            }
            .BsMidAction {
                .BsSetting  {
                    .SettingPopHover {
                        left: auto !important;
                        right: auto !important;
                        &:before {
                            left: 142px !important;
                        }
                    }
                }
            }
        }
    }
    .ModalCommonInfo {
        .modal-dialog {
            .modal-content {
                .ModalClose {
                    bottom: auto !important;
                    top: 10px !important;
                    right: 10px !important;
                    left: auto !important;
                    width: 28px;
                    height: 28px;
                    .CloseModal {
                        svg {
                            fill: #4e4d4d !important;
                        }
                    }
                }
            }
        }
    }
    
    .RoomMeetingPopUp {
        overflow: auto !important;
        overflow-y: auto !important;
        .modal-dialog {
            .modal-content {
                .modal-body {
                    .content-wrap {
                        .content {
                            //overflow-y: auto;
                            //border: 1px #fff solid;
                            .RoomMeetingModalSetup {
                                // overflow-y: auto;
                                position: absolute;
                                z-index: 99;
                                top: 0px;
                                height: 100vh;
                                .whereByshrinkSize {
                                    //border: 1px #f00 solid;
                                    max-width: 100% !important;
                                    max-height: 100% !important;                                 
                                    aspect-ratio: initial !important;
                                    aspect-ratio: inherit !important;
                                    overflow-y: auto;
                                    height: calc(100vh - 0) !important;
                                    whereby-embed {
                                        max-width: 100% !important;
                                        max-height: 100% !important;                                 
                                        aspect-ratio: initial !important;
                                        aspect-ratio: inherit !important;
                                        overflow-y: auto;
                                        height: calc(100vh - 0) !important;
                                    }
                                }
                               
                            }
                        }
                    }
                }
            }
        }
    }
    .customer-chat {
        bottom: 10px;
        right: 3px;
        width: 65px;
        height: 65px;
        z-index: 95;
        display: none;
    }
    .AllPlanSchedule {
        padding: 10px 15px 0 0px;

        .ScheduleCalendarInfos {
            .StageFullInfoFix {
                height: auto;
                .CalenderDateScheduleInof {
                    width: 100%;
                    display: flex;
                    cursor: cell;
                    justify-content: space-between;
                    padding: 12px 18px 12px;
                    border-radius: 20px 20px 0 0;
                    flex-flow: inherit;
                    margin: 0px 0 0;
                    .StageNo {
                        padding: 2px 16px;
                    }
                    .DtStageEventDay {
                        h4 {
                            line-height: 26px;
                        }
                    }
                    .ScheduleTMFx {
                        margin: 5px 0 0;
                        p {
                            font-size: 16px;
                        }
                    }
                    .ScheduleStatusOngoing {
                        margin: 10px 0 0;
                        display: none;
                        label {
                            font-weight: 600;
                        }
                    }
                    .calenderInfoGoogle {
                        a {
                            svg {
                                width: 18px;
                                height: 18px;
                            }
                        }
                    }
                    .SchTimeDateFix {
                        width: 50%;
                        text-align: left;
                        .DtStageEventDay {
                            h4 {
                                font-weight: 600;
                                strong {
                                    display: inline-block;
                                    margin-left: 6px;
                                    font-weight: 600;
                                }
                            }
                        }
                        .ScheduleTMFx {
                            p {
                                font-weight: 500 !important;
                                color: #8944FC;
                            }
                        }
                        .ScheduleStatusOngoing {
                            display: block !important;
                            .RecordLiveStream {
                                position: inherit !important;
                            }
                            .EventStyle {
                                position: inherit !important;
                                font-weight: 500;
                                padding: 4px 14px;
                                font-size: 13px;
                                margin: 6px 0 0;
                                text-align: center;
                                overflow: initial !important;
                                display: block !important;
                            }
                        }
                    }
                }
            }
            .ScheduleInfoWithConts {
                width: 100%;
                padding: 20px 20px 18px;
                position: relative;
                h3 {
                    margin: 0 0 5px;
                    font-size: 16px;
                    color: #8944FC;
                }
                p {
                    font-size: 14px;
                }
                .BackAgain {
                    height: auto;
                    bottom: 0;
                    top: auto;
                    width: 100%;
                    text-align: center;
                    justify-content: center;
                    border-radius: 0px 0px 12px 12px;
                    a {
                        width: 100%;
                        text-align: center;
                        padding: 10px 0;
                        display: block;
                    }
                }
               
            }
            .SpeakerRowList {
                //margin: 20px 0 20px;
                .EventSpeakerExp {
                    padding: 12px 10px;
                    //margin-right: 10px;
                    //width: 100%;
                    .SpeakerNamePos {
                        margin-left: 12px;
                    }
                    .SpeakerNamePos {
                        h4 {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
    .tooltip  {
        display: none;
    }
    .SchedulePageEvent .SetEventSchedule .ScheduleDayStage .DayScheduleStages .SelectDropdown .SeleftDDl .dropdown .dropdown-toggle::before {
        top: 14px;
    }
    .SchedulePageEvent {
        .SetEventSchedule {
            .ScheduleDayStage {
                    display: block !important;
                    border-radius: 10px !important;
                    background-color: transparent;
                    padding-bottom: 0px !important;
                h4 {
                    display: none;
                }
                .SelectDayStage {
                    width: 100%;
                    .DayScheduleStages {
                        width: 100% !important;
                        display: inline-block !important;
                        margin: 0 4px;
                    }
                    .SelectDays  {
                        margin-left: 0px !important;
                    }
                    .SelectAllStages  {
                        margin-right: 0px !important;
                    }
                    .MobNoneSchedule  {
                        display: none !important;
                    }
                }
            }
            .RoomSeatSelect {
                background-color: transparent !important;
                width: 85%;
                margin: 4px 0 0;
                .RoomMeetSrch {
                    margin-top: 0px !important;
                    display: block !important;
                }
            }
        }
       .SetEventSchedule {
            transition: all 0.5s ease;
            -webkit-transition: all 0.5s ease;
            -moz-transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
            height: auto;
            opacity: 1;
        }
        .DropdownMenuDDl {
            transition: all 0.5s ease;
            -webkit-transition: all 0.5s ease;
            -moz-transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
            opacity: 0;
            height: 0px !important;
            margin: 0px 0px 0px !important;
        }
    }
    .ScheduleCalendarInfos {
        .PopUpImgSpeaker {
            display: flex !important;
            margin: 15px 0 8px;
        }
    }
    .MixhubbHeader {
        .HeaderMainTop {
            .MixhubbLogo {
                display: none;
            }
             .MixhubbRight {
                //  .ChatIco {
                //      display: none;
                // }
                
                // .FeedbackIco {
                //     display: none;
                // }
                .headIcon {
                    margin-right: 0px !important;
                    margin-left: 4px !important;
                }
                 .UserProfileMix {
                      //display: none;   
                 }
             }
        }
    }
    .MobileAppFilter {
        display: flex !important;

    }
    .SchedulePageEvent {
        .SetEventSchedule {
            .ScheduleDayStage {
                .SelectDayStage {
                    .DayScheduleStages {
                        .SelectDropdown {
                            .SeleftDDl {
                                .dropdown-toggle {
                                    background-color: #2A2E34;
                                    border-color: #2A2E34;
                                    color: #ffffff;
                                    &::before {
                                        border-top: 2px solid #ffffff;
                                        border-right: 2px solid #ffffff;
                                    }
                                }
                                .show {
                                    .dropdown-toggle {
                                        &:before {
                                            top: 20px;
                                          }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .MixhubbHeader {
        .HeaderMenu {
            .MixhubbLogo {
                display: block !important;
                position: absolute !important;
                z-index: 992;
                width: 100%;
                margin: 0px auto;
                left: 0;
                right: 0;
                top: 12px;
            }
            
            .MixhubbRight {
                .close_Menu {
                    top: 18px !important;
                }
                .UserProfileMix  {
                    display: block !important;
                    position: absolute !important;
                    z-index: 992;
                    right: 0;
                    top: 14px;
                }
            }
        }
    }
    .MixhubbHeader .HeaderMainTop .MixhubbLogo img {
        height: 100%;
        width: 100%;
        object-fit: scale-down;
    }
    .ModalCommonInfo {
        .modal-dialog {
            .modal-content {
                .modal-body {
                    .MeetingNewSetup {
                        .DayMeetingTimeSetup {
                            .MobBackstage {
                                margin: 0px !important;
                                display: none;
                                .btnFullBtn {
                                    margin: 0px !important;
                                }
                            }
                            .form-group {
                                margin: 0px !important;
                            }
                        }
                    }
                }
            }
        }
    }
    .go-backstage-btn {
        display: none;
    }
    .UserEditInfos {
        .modal-dialog {
            .modal-content {
                border-radius: 8px;
            }
        }
        .FullYourProfile {
            .ProfileEditPic {
                .ProfileOverflow {
                    .PicProdileUp {
                        flex: 0 0 109px !important;
                        max-width: 109px !important;
                        border: 0 !important;
                        border-radius: 100px;
                        padding: 2px 1px;
                        margin: 0px auto 12px;
                        background: linear-gradient(30deg,#3367e4 25%,#3367e4 0,#8e3fbd 70%)!important;
                        height: 109px;
                        .minHeightBox {
                            height: 105px !important;
                            min-height: 105px !important;
                            flex: 0 0 105px !important;
                            max-width: 105px !important;
                            background-color: #f1f1f1;
                            border-radius: 100px;
                            border: 0px !important;
                            overflow: initial !important;
                            margin: 0px auto !important;
                            .default-image-selected {
                                .UploadLabel {
                                    .uploadPreviewIcon {
                                        display: none !important;
                                    }
                                    .uploadImgIcon {
                                        right: -12px !important;
                                        top: 62% !important;
                                        transform: translateY(-62%);
                                        .UploadDeleteIco {
                                            width: 30px;
                                            height: 30px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .ChatMixInfoMixhubb {
        z-index: 992;
        width: 98%;
        right: 5px;
        top: 4px;
        height: calc(100vh - 8px);
    }
    .NotifyMixhubbRt .headingTop p {
        right: 44px;
    }
    .ZoneEnterDiscInfo {
        margin: 10px 0 0;
    }
    .ChatMixInfoMixhubb {
        width: 96%;
        height:100% !important;
        right: 0;
        position: fixed;
        top: 0px;
        left: 0;
        z-index: 992;
        margin: 0px auto;
        .PeopleConvrtsList {
            height: calc(100% - 65px);
            .peopleInfos {
                height: 100%;
                .common-event-chat {
                    height: calc(100% - 168px) !important;
                    margin: 12px 0 0;
                }
                .PeopleAttendList {
                    height: calc(100% - 70px) !important;
                }
            }
        }
       .ConverSearchTop {
            height: calc(100% - 58px);
            
            .NoRecoardInfo {
                height: calc(100% - 58px);
            }
        }
    }
    .NotifyMixhubbRt {
        .PeopleConvrtsList {
            height: calc(100% - 65px) !important;
        }
    }
    .ScheduleCalendarInfos {
        padding-right: 3px;
    }
    .NotifyMixhubbRt .headingTop .CloseButton {
        display: block !important;
    }

      .MixhubbHeader .HeaderMainTop .MixhubbLogo {
        height: 48px;
    }
      .UserEditInfos {
        .FullYourProfile {
            .ProfileEditPic {
                .ProfileOverflow {
                    .PicProdileUp {
                        .minHeightBox {
                            .UploaFileStage {
                                .UploadLabel {
                                    margin: 0px !important;
                                    span {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
      }



      .oraganizer-backstage-dashboard {
        .MainViewBackStage {
            height: calc(100vh - 250px) !important;
            .RemoveAllDiv {
                width: 100% !important;
                height: auto !important;
                .front-stage-stream-scroll {
                    width: 97% !important;
                }
            }
        }
    }
    .oraganizer-backstage-dashboard.collapse-out-frontstage {
        .MainViewBackStage {
            height: calc(100vh - 110px) !important;
            .RemoveAllDiv {
                width: 100% !important;
                height: auto !important;
                .front-stage-stream-scroll {
                    width: 97% !important;
                }
            }
        }
    }

    .audience-front-stage-roll {
        .container-fluid {
            .MainViewBackStage {
                height: calc(100vh - 248px) !important;
                .FlexResizeStep {
                    .ChangeWithSize {
                        height: 100% !important;
                        .fullFreeContainer {
                            height: 100% !important;
                            width: 100% !important;
                            .RemoveAllDiv {
                                width: 100% !important;
                                height: 100% !important;
                                img {
                                    height: auto !important;
                                    aspect-ratio: 16/9;
                                    object-fit: scale-down;
                                }
                                .front-stage-stream-scroll {
                                    height: auto !important;
                                    width: 100% !important;
                                    padding: 0;
                                    .row {
                                        .stage-user-list {
                                            .video_footer {
                                                .ShortName {
                                                    h4 {
                                                        width: 45px;
                                                        height: 45px;
                                                        font-size: 22px;
                                                    }
                                                }
                                                .bottom_footer {
                                                    h4 {
                                                        font-size: 10px !important;
                                                        span {
                                                            line-height: 14px !important;
                                                        }
                                                    }
                                                }
                                                .BackIconAction {
                                                    top: 5px;
                                                    right: 3px;
                                                    .ActionLive {
                                                        width: 18px;
                                                        height: 18px;
                                                        .widthSvg {
                                                            padding: 3px 3px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                
                            }
                        }
                    }
                }
               
            }
        }
    }
    .backstage-dashboard {
        .MainViewBackStage {
            height: calc(100vh - 255px) !important;
            margin: 6px 0 !important;
            .RemoveAllDiv {
                width: 100% !important;
                height: auto !important;
                .front-stage-stream-scroll {
                    width: 98% !important;
                }
            }
        }
    }
    .backstage-dashboard.collapse-out-frontstage {
        .container-fluid {
            .MainViewBackStage {
                height: calc(100vh - 116px) !important;
                .FlexResizeStep {
                    .ChangeWithSize {
                        height: 100% !important;
                        .fullFreeContainer {
                            height: auto !important;
                            .RemoveAllDiv {
                                height: auto !important;
                                img {
                                    height: auto !important;
                                    aspect-ratio: 16/9;
                                    object-fit: scale-down;
                                }
                                .front-stage-stream-scroll {
                                    height: 100% !important;
                                    .row {
                                        .stage-user-list {
                                            .video_footer {
                                                .ShortName {
                                                    h4 {
                                                        width: 45px;
                                                        height: 45px;
                                                        font-size: 22px;
                                                    }
                                                }
                                                .bottom_footer {
                                                    h4 {
                                                        font-size: 10px !important;
                                                        span {
                                                            line-height: 14px !important;
                                                        }
                                                    }
                                                }
                                                .BackIconAction {
                                                    top: 5px;
                                                    right: 3px;
                                                    .ActionLive {
                                                        width: 18px;
                                                        height: 18px;
                                                        .widthSvg {
                                                            padding: 3px 3px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                
                            }
                        }
                    }
                }
               
            }
        }
    }
    .screen-stage-main-div.backstage-dashboard.collapse-out-frontstage {
        .container-fluid {
            .MainViewBackStage {
                height: calc(100vh - 108px) !important;
                .FlexResizeStep {
                    .ChangeWithSize {
                        height: 100% !important;
                        .fullFreeContainer {
                            height: auto !important;
                            height: 100% !important;
                            .RemoveAllDiv {
                                height: 100% !important;
                                img {
                                    height: auto !important;
                                    aspect-ratio: 16/9;
                                    object-fit: scale-down;
                                }
                                .front-stage-stream-scroll {
                                    height: 100% !important;
                                    .row {
                                        .stage-user-list {
                                            bottom: 60px !important;
                                            margin: 0px !important;
                                            .video_footer {
                                                .ShortName {
                                                    h4 {
                                                        width: 45px;
                                                        height: 45px;
                                                        font-size: 22px;
                                                    }
                                                }
                                                .bottom_footer {
                                                    h4 {
                                                        font-size: 10px !important;
                                                        span {
                                                            line-height: 14px !important;
                                                        }
                                                    }
                                                }
                                                .BackIconAction {
                                                    top: 5px;
                                                    right: 3px;
                                                    .ActionLive {
                                                        width: 18px;
                                                        height: 18px;
                                                        .widthSvg {
                                                            padding: 3px 3px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                
                            }
                        }
                    }
                }
               
            }
        }
    }
    .screen-stage-main-div.backstage-dashboard {
        .container-fluid {
            .MainViewBackStage {
                height: calc(100vh - 250px) !important;
                .FlexResizeStep {
                    .ChangeWithSize {
                        height: 100% !important;
                        .fullFreeContainer {
                            height: 100% !important;
                            width: 100% !important;
                            .RemoveAllDiv {
                                height: 100% !important;
                                img {
                                    height: auto !important;
                                    aspect-ratio: 16/9;
                                    object-fit: scale-down;
                                }
                                .front-stage-stream-scroll {
                                    height: 100% !important;
                                    .row {
                                        .front-stage-share-screen {
                                            width: 100% !important;
                                        }
                                        .stage-user-list {
                                            width: 100% !important;
                                            overflow: auto;
                                            overflow-y: hidden;
                                            display: inline-flex;
                                            flex-flow: row nowrap;
                                            align-items: center;
                                            justify-content: flex-start;
                                            padding: 0px 4px;
                                            height: auto !important;
                                            margin-left: 6px;
                                            position: absolute !important;
                                            bottom: 12px;
                                            .video_footer {
                                                .ShortName {
                                                    h4 {
                                                        width: 45px;
                                                        height: 45px;
                                                        font-size: 22px;
                                                    }
                                                }
                                                .bottom_footer {
                                                    h4 {
                                                        font-size: 10px !important;
                                                        span {
                                                            line-height: 14px !important;
                                                        }
                                                    }
                                                }
                                                .BackIconAction {
                                                    top: 5px;
                                                    right: 3px;
                                                    .ActionLive {
                                                        width: 18px;
                                                        height: 18px;
                                                        .widthSvg {
                                                            padding: 3px 3px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                
                            }
                        }
                    }
                }
               
            }
        }
    }
    .backstage-dashboard {
        .container-fluid {
            .MainViewBackStage {
                height: calc(100vh - 258px) !important;
                .FlexResizeStep {
                    .ChangeWithSize {
                        height: 100% !important;
                        .fullFreeContainer {
                            height: 100% !important;
                            width: 100% !important;
                            .RemoveAllDiv {
                                width: 100% !important;
                                height: 100% !important;
                                img {
                                    height: auto !important;
                                    aspect-ratio: 16/9;
                                    object-fit: scale-down;
                                }
                                .front-stage-stream-scroll {
                                    height: auto !important;
                                    .row {
                                        .stage-user-list {
                                            .video_footer {
                                                .ShortName {
                                                    h4 {
                                                        width: 45px;
                                                        height: 45px;
                                                        font-size: 22px;
                                                    }
                                                }
                                                .bottom_footer {
                                                    h4 {
                                                        font-size: 10px !important;
                                                        span {
                                                            line-height: 14px !important;
                                                        }
                                                    }
                                                }
                                                .BackIconAction {
                                                    top: 5px;
                                                    right: 3px;
                                                    .ActionLive {
                                                        width: 18px;
                                                        height: 18px;
                                                        .widthSvg {
                                                            padding: 3px 3px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        .JoinBroadcastBtn .btnFullBtn {
                                            font-size: 16px;
                                            height: 42px;
                                            width: 210px;
                                            padding: 8px 0;
                                        }
                                    }
                                }
                                
                            }
                        }
                    }
                }
               
            }
            .join-not-backstage {
                height: calc(100vh - 118px) !important;
            }
        }
    }
    .frontend-dashboard {
        .MainViewBackStage {
            height: calc(100vh - 52px) !important;
            margin: 6px 0 !important;
            .RemoveAllDiv {
                width: 100% !important;
                height: auto !important;
                .front-stage-stream-scroll {
                    width: 97% !important;
                }
            }
        }
    }
    .audience-front-stage-roll.screen-stage-main-div {
        .container-fluid {
            .MainViewBackStage {
                height: calc(100vh - 250px) !important;
                .FlexResizeStep {
                    .ChangeWithSize {
                        height: 100% !important;
                        .fullFreeContainer {
                            height: 100% !important;
                            .RemoveAllDiv {
                                height: 100% !important;
                                img {
                                    height: auto !important;
                                    aspect-ratio: 16/9;
                                    object-fit: scale-down;
                                }
                                .front-stage-stream-scroll {
                                    height: 100% !important;
                                    .row {
                                        .stage-user-list {
                                            width: 100% !important;
                                            overflow: auto;
                                            overflow-y: hidden;
                                            display: inline-flex;
                                            flex-flow: row nowrap;
                                            align-items: center;
                                            justify-content: flex-start;
                                            padding: 0px 4px;
                                            height: auto !important;
                                            margin-left: 6px;
                                            position: absolute !important;
                                            bottom: 12px;
                                            .video_footer {
                                                .ShortName {
                                                    h4 {
                                                        width: 45px;
                                                        height: 45px;
                                                        font-size: 22px;
                                                    }
                                                }
                                                .bottom_footer {
                                                    h4 {
                                                        font-size: 10px !important;
                                                        span {
                                                            line-height: 14px !important;
                                                        }
                                                    }
                                                }
                                                .BackIconAction {
                                                    top: 5px;
                                                    right: 3px;
                                                    .ActionLive {
                                                        width: 18px;
                                                        height: 18px;
                                                        .widthSvg {
                                                            padding: 3px 3px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                
                            }
                        }
                    }
                }
               
            }
        }
    }
    .audience-front-stage-roll.collapse-out-frontstage.screen-stage-main-div {
        .container-fluid {
            .MainViewBackStage {
                //height: calc(100vh - 108px) !important;
                height: auto !important;
                .FlexResizeStep {
                    .ChangeWithSize {
                        height: 100% !important;
                        .fullFreeContainer {
                            height: 100% !important;
                            .RemoveAllDiv {
                                height: 100% !important;
                                img {
                                    height: auto !important;
                                    aspect-ratio: 16/9;
                                    object-fit: scale-down;
                                }
                                .front-stage-stream-scroll {
                                    height: 100% !important;
                                    width: 100% !important;
                                    .row {
                                        .front-stage-share-screen {
                                            width: 100% !important;
                                            height: auto !important;
                                            padding: 4px !important;
                                        }
                                        .mux-player {
                                            padding: 0 0;
                                        }
                                        .stage-user-list {
                                            width: 100% !important;
                                            overflow: auto;
                                            overflow-y: hidden;
                                            display: inline-flex;
                                            flex-flow: row nowrap;
                                            align-items: center;
                                            justify-content: flex-start;
                                            padding: 0px 4px;
                                            height: auto !important;
                                            margin-left: 6px;
                                            position: absolute !important;
                                            bottom: 80px;
                                            .video_footer {
                                                .ShortName {
                                                    h4 {
                                                        width: 45px;
                                                        height: 45px;
                                                        font-size: 22px;
                                                    }
                                                }
                                                .bottom_footer {
                                                    h4 {
                                                        font-size: 10px !important;
                                                        span {
                                                            line-height: 14px !important;
                                                        }
                                                    }
                                                }
                                                .BackIconAction {
                                                    top: 5px;
                                                    right: 3px;
                                                    .ActionLive {
                                                        width: 18px;
                                                        height: 18px;
                                                        .widthSvg {
                                                            padding: 3px 3px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                
                            }
                        }
                    }
                }
               
            }
        }
    }
    .collapsDivToggle {
        top: 12px !important;
    }
    .audience-front-stage-roll .BackstageInfoStream .HeadingBstage {
        height: 48px !important;
    }
    .screen-stage-main-div.oraganizer-backstage-dashboard.collapse-out-frontstage {
        .MainViewBackStage {
            .alignAllDiv {
                width: 100% !important;
                .front-stage-stream-scroll {
                    width: 97% !important;
                    .row {
                        .stage-user-list {
                            height: auto !important;
                        }
                    }
                }
            }
        }
    }

    .screen-stage-main-div.backstage-dashboard {
        .MainViewBackStage  {
            .MainViewBackStage {
                .FlexResizeStep {
                    .ChangeWithSize {
                        .fullFreeContainer {
                            width: 100% !important;
                            .alignAllDiv {
                                width: 100% !important;
                                height: auto !important;
                                .front-stage-stream-scroll {
                                    width: 97% !important;
                                    height: calc(100vh - 260px) !important;
                                    .row {
                                        flex-flow: column;
                                        .front-stage-share-screen {
                                            width: 100% !important;
                                            height: auto !important;
                                        }
                                        .stage-user-list {
                                            width: 100% !important;
                                            overflow: auto;
                                            overflow-y: hidden;
                                            display: inline-flex;
                                            flex-flow: row nowrap;
                                            align-items: center;
                                            justify-content: flex-start;
                                            padding: 6px 4px;
                                            position: absolute !important;
                                            height: auto !important;
                                            margin-left: 6px;
                                            bottom: 0px !important;
                                            .video_footer {
                                                .ShortName {
                                                    h4 {
                                                        width: 45px;
                                                        height: 45px;
                                                        font-size: 22px;
                                                    }
                                                }
                                                .bottom_footer {
                                                    h4 {
                                                        font-size: 10px !important;
                                                        span {
                                                            line-height: 14px !important;
                                                        }
                                                    }
                                                }
                                                .BackIconAction {
                                                    top: 5px;
                                                    right: 3px;
                                                    .ActionLive {
                                                        width: 18px;
                                                        height: 18px;
                                                        .widthSvg {
                                                            padding: 3px 3px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                
            }
            
        }
    }
    
    .audience-front-stage-roll.collapse-out-frontstage {
        height: auto;
        overflow-y: hidden !important;
        .container-fluid {
            padding: 0 6px;
            .MainViewBackStage {
                height: auto !important;
                margin-top: 0px !important;
                .FlexResizeStep {
                    height: 100% !important;
                    .ChangeWithSize {
                        height: 100% !important;
                        .fullFreeContainer {
                            height: 100% !important;
                            .RemoveAllDiv {
                                height: 100% !important;
                                img {
                                    height: auto !important;
                                    aspect-ratio: 16/9;
                                    object-fit: scale-down;
                                }
                                .front-stage-stream-scroll {
                                    height: 100% !important;
                                    .row {
                                        .stage-user-list {
                                            .video_footer {
                                                .ShortName {
                                                    h4 {
                                                        width: 45px;
                                                        height: 45px;
                                                        font-size: 22px;
                                                    }
                                                }
                                                .bottom_footer {
                                                    h4 {
                                                        font-size: 10px !important;
                                                        span {
                                                            line-height: 14px !important;
                                                        }
                                                    }
                                                }
                                                .BackIconAction {
                                                    top: 5px;
                                                    right: 3px;
                                                    .ActionLive {
                                                        width: 18px;
                                                        height: 18px;
                                                        .widthSvg {
                                                            padding: 3px 3px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                
                            }
                        }
                    }
                }
               
            }
        }
    }
    .screen-stage-main-div.backstage-dashboard.collapse-out-frontstage {
        .MainViewBackStage {
            .FlexResizeStep {
                .ChangeWithSize {
                    .fullFreeContainer {
                        width: 100% !important;
                        .alignAllDiv {
                            width: 100% !important;
                            height: auto !important;
                            .front-stage-stream-scroll {
                                width: 97% !important;
                                .row {
                                    .stage-user-list {
                                        height: auto !important;
                                        position: absolute !important;
                                        bottom: 0px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            
        }
    }

    .screen-stage-main-div.organizer-frontend-dashboard {
        .MainViewBackStage  {
            .alignAllDiv {
                .front-stage-stream-scroll {
                    width: 97% !important;
                    .row {
                        flex-flow: column;
                        .front-stage-share-screen {
                            width: 100% !important;
                            height: auto !important;
                        }
                        .stage-user-list {
                            width: 100% !important;
                            overflow: auto;
                            overflow-y: hidden;
                            display: inline-flex;
                            flex-flow: row nowrap;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 6px 4px;
                            position: relative !important;
                            height: auto !important;
                            margin-left: 6px;
                            .video_footer {
                                .ShortName {
                                    h4 {
                                        width: 45px;
                                        height: 45px;
                                        font-size: 22px;
                                    }
                                }
                                .bottom_footer {
                                    h4 {
                                        font-size: 10px !important;
                                        span {
                                            line-height: 14px !important;
                                        }
                                    }
                                }
                                .BackIconAction {
                                    top: 5px;
                                    right: 3px;
                                    .ActionLive {
                                        width: 18px;
                                        height: 18px;
                                        .widthSvg {
                                            padding: 3px 3px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .screen-stage-main-div.frontend-dashboard {
        .MainViewBackStage  {
            .alignAllDiv {
                .front-stage-stream-scroll {
                    width: 97% !important;
                    .row {
                        flex-flow: column;
                        .front-stage-share-screen {
                            width: 100% !important;
                            height: auto !important;
                        }
                        .stage-user-list {
                            width: 100% !important;
                            overflow: auto;
                            overflow-y: hidden;
                            display: inline-flex;
                            flex-flow: row nowrap;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 6px 4px;
                            position: relative !important;
                            height: auto !important;
                            margin-left: 6px;
                            .video_footer {
                                .ShortName {
                                    h4 {
                                        width: 45px;
                                        height: 45px;
                                        font-size: 22px;
                                    }
                                }
                                .bottom_footer {
                                    h4 {
                                        font-size: 10px !important;
                                        span {
                                            line-height: 14px !important;
                                        }
                                    }
                                }
                                .BackIconAction {
                                    top: 5px;
                                    right: 3px;
                                    .ActionLive {
                                        width: 18px;
                                        height: 18px;
                                        .widthSvg {
                                            padding: 3px 3px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .audience-front-stage-roll.screen-stage-main-div {
        .MainViewBackStage  {
            .FlexResizeStep {
                .ChangeWithSize {
                    .fullFreeContainer {
                        .alignAllDiv {
                            .front-stage-stream-scroll {
                                width: 100% !important;
                                .row {
                                    flex-flow: column;
                                    .front-stage-share-screen {
                                        width: 100% !important;
                                        height: auto !important;
                                        padding: 0px !important;
                                    }
                                    .stage-user-list {
                                        width: 100% !important;
                                        overflow: auto;
                                        overflow-y: hidden;
                                        display: inline-flex;
                                        flex-flow: row nowrap;
                                        align-items: center;
                                        justify-content: flex-start;
                                        padding: 0px 4px;
                                        height: auto !important;
                                        margin-left: 6px;
                                        position: absolute !important;
                                        bottom: 80px;
                                        .video_footer {
                                            .ShortName {
                                                h4 {
                                                    width: 45px;
                                                    height: 45px;
                                                    font-size: 22px;
                                                }
                                            }
                                            .bottom_footer {
                                                h4 {
                                                    font-size: 10px !important;
                                                    span {
                                                        line-height: 14px !important;
                                                    }
                                                }
                                            }
                                            .BackIconAction {
                                                top: 5px;
                                                right: 3px;
                                                .ActionLive {
                                                    width: 18px;
                                                    height: 18px;
                                                    .widthSvg {
                                                        padding: 3px 3px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .PollQnaBase {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        height: 100% !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            
        }
    }

    .BackstageInfoStream {
        .HeadingBstage {
            .BsMidAction {
                height: 36px !important;
                .BsEnd {
                    .RedCommonBtn {
                        padding: 2px 24px;
                        font-size: 16px;
                        height: 32px;
                    }
                }
                .ActionLive {
                    margin: 0 4px;
                    width: 24px;
                    height: 24px;
                }
                .BsSetting {
                    width: 18px !important;
                    height: 18px !important;
                }
            }
        }
    }
    .Stage-Back-Event-Info {
        .EventBtmInfo {
            padding: 0px 0 0 !important;
            h2 {
                font-size: 18px !important;
            }
        }
    }
    .SpeakerStageList {
        .EventSpeakerStage {
            width: 94% !important;
        }
    }
    .BackstageInfoStream .streamingUsers .StreamVideoFrame {
        padding-right: 0px;
        padding-left: 0px;
    }
    .RoomMeetingPopUp {
        .modal-dialog {
            .modal-content {
                .modal-body {
                    .content-wrap {
                        .content {
                            .RoomMeetingModalSetup {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                            .shrinkWhereBy {
                                width: 100% !important;
                            }
                        }
                    }
                }
            }
        }
    }
    .AdvanceFormMicrosite {
        .modal-dialog {
            .modal-content {
                .modal-body {
                    .EventTypeAdvamce {
                        .EnterEventAction {
                            width: 90%;
                            margin: 0px auto;
                            .EventTypeActonIn {
                                h4 {
                                    font-size: 18px;
                                    margin: 8px 0 28px;
                                }
                                .form-group {
                                    label {
                                        margin-bottom: 4px;
                                    }
                                    .form-control {
                                        padding: 6px 18px;
                                        height: 45px;
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .EventTypeActonIn .SelectDropdown .dropdown .SelectDdl::after {
        top: 16px;
    }
    .HeaderTopExp {
        height: 78px;
        .DateExperienceHere {
            h3 {
                font-size: 16px;
            }
        }
    }
    .ZoneEnterDiscInfo {
        height: calc(100vh - 138px);
    }
    
    
    
    
    .BackstageInfoStream .HeadingBstage .ActionBcFcHeader {
        display: none;
    }
    .MixhubbHeader {
        .HeaderMainTop {
            .MixhubbMenu {
                .mobMenuExt {
                    display: block !important;
                    li {
                        .nav-mixhubb {
                            .IconStyle {
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
    .MobJoinBackstage {
        display: none !important;
    }
    .AdvanceFormMicrosite {
        .modal-dialog {
            .modal-content {
                .modal-header {
                    .HeaderTopExp {
                        display: none;
                    }
                }
            }
        }
    }
    .LogOUtPopBtn {
        top: 8px;
        right: 9px;
    }
    
    .socialMicroInfo {
        ul {
            li {
                //margin: 0px 8px;
                // svg {
                //     width: 45px;
                //     height: 45px;
                // }
            }
        }
    }
    .sponsorBlock {
        margin: 0 0 12px;
    }
    .scheduleTages {
        .TagNameList {
            ul {
                .slick-slider {
                    .slick-prev {
        
                    }
                }
            }
        }
    }
    .ScheduleCalendarInfos .StageFullInfoFix .CalenderDateScheduleInof .calenderInfoGoogle {
        padding: 8px 12px 8px 12px;
    }
    .audience-front-stage-roll .MainViewBackStage .FlexResizeStep .SpeakerSizeShrink {
        width: 100% !important;
    }
    .audience-front-stage-roll .StageFootShrink {
        width: 100% !important;
    }
    .SideRightMenu, .BackstageInfoStream  {
        display: none !important;
    }
    .MobileStageFtr {
        display: block !important;
    }
    .StageTabCollapes {
        position: fixed;
        width: 98%;
        bottom: 2px;
        z-index: 95;
        margin: 0px auto !important;
        left: 0;
        right: 0;
        .tab-content {
            .PeopleChatPopup {
                height: auto !important;
                position: inherit;
                border-radius: 10px 10px 0px 0px;
                width: 100%;
                box-shadow: none !important;
                padding: 0;
                .responsive-tabs-container {
                    padding: 0 0px;
                    .nav-tabs {
                        padding: 14px 10px 14px;
                        height: 62px;
                        justify-content: space-between;
                        li {
                            margin: 0 0 0;
                            .nav {
                                .nav-link {
                                    padding: 0px 0px;
                                    border-radius: 4px;
                                    font-size: 11px;
                                    font-weight: 400;
                                    color: #000;
                                    background: transparent;
                                    svg {
                                        fill: #000000;
                                    }
                                    &.active {
                                        padding: 0 0;
                                        padding: 0px 0px;
                                        border-radius: 4px;
                                        background: transparent;
                                        color: #1A78E5;
                                        svg {
                                            fill: #1A78E5;
                                        }
                                    }
                                    svg {
                                        display: block;
                                        margin: 0 auto;
                                        width: 26px;
                                        height: 26px; 
                                    }
                                }
                            }
                        }
                    }
                    .CloseButton {
                        display: none;
                    }
                }
                
                .StateEventDtl {
                    height: calc(100vh - 374px) !important;
                    .tab-content {
                        .front-stage-active {
                           .nav {
                                display: none;
                           }
                        }
                    }

                }
                .StageBackStage {
                    .nav-tabs {
                        li {
                            .nav-link {
                                padding: 10px 0 !important;
                                &.active {
                                    padding: 10px 0 !important;
                                }
                            }
                        }
                    }
                }
            }
            .ReadMoreDeso {
                background-color: #f0f0f0;
                padding: 12px 2px;
                .AboutTabsFull {
                    margin: 0px 0;
                    overflow-y: auto;
                    height: calc(100vh - 395px);
                    padding: 0 12px;
                    .ScheduleDayEvent {
                        margin: 14px 0;
                        span {
                            background-color: #ffffff;
                            font-size: 14px;
                            padding: 6px 12px;
                            font-weight: 500;
                            color: #1F1B20;
                            border-radius: 4px;
                        }
                    }
                    h4 {
                        color: #1A78E5;
                        font-size: 18px;
                        margin: 0;
                    }
                    p {
                        font-size: 14px;
                        margin: 18px 0 0;
                        color: #1F1B20;
                        word-break: break-word;
                    }
                    .SectionWatch {
                    width: 100%;
                    text-align: left;
                    margin: 18px 0 2px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .RedButton  {
                        padding: 6px 16px;
                        font-size: 15px;
                        width: 145px;
                    }
                    .PopUpImgSpeaker {
                        .SpkInfoImgs {
                            width: 50px;
                            height: 50px;
                            display: inline-block;
                            margin-right: 10px;
                            img {
                                width: 100%;
                                height: 100%;
                                border-radius: 100px;
                        }
                        }
                        .SpkInfoCount {
                            border: 2px #1A78E5 solid;
                            color: #1A78E5;
                            width: 46px;
                            height: 46px;
                            border-radius: 100px;
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
    .SlideCommonPopup .StateEventDtl .EventInfoStage .ChatInfos {
        height: calc(100vh - 464px) !important;
        margin: 10px 0 0;
        padding: 0px 15px 0 !important;

    }
    .SlideCommonPopup {
        .StateEventDtl {
            .EventInfoStage {
                height: calc(100vh - 395px) !important;
                position: relative;
            }
            .StageBackStage {
                .nav-tabs {
                    display: none;
                }
            }
        }
    }
    .QnaInfos {
        height: calc(100vh - 388px) !important;
        .MessageTextBox {
            width: 95%;
        }
        .QnaInfosPols {
            height: calc(100vh - 505px);
        }
    }
    
    .WelcomeStage {
        .modal-dialog {
            max-width: 94%;
            width: 95% !important;
            .modal-content {
                .modal-body {
                    .MeetingNewSetup {
                        width: 100%;
                    }
                }
            }
        }
    }
    .PollInfos .PollMhAuto {
        height: calc(100vh - (56.25vw + 45px + 70px)) !important;
    }
    .PollInfos {
        position: relative;
        height: calc(100vh - (56.25vw + 45px + 188px));
    }
    .PeopleChatPopup .StateEventDtl .peopleInfos {
        padding: 14px;
        height: calc(100vh - (56.25vw + 45px + 190px)) !important;
    }
    .FilesInfos {
        height: calc(100vh - (56.25vw + 45px + 190px));
        padding: 18px 18px 14px;
        .FilesActionDoc {
            &:last-child {
                margin-bottom: 0px !important;
            }
        }
    }
    .PollInfos .MhBtnRemove {
        height: calc(100vh - 385px);
    }
    ::-webkit-scrollbar {
        width: 4px !important;
    }
    .SchedulePageEvent .SetEventSchedule .ScheduleDayStage .SelectEnterZone .PepleSerchHere .SerchHere {
        height: 42px;
    }
    .MixhubbHeader .HeaderMainTop .MixhubbMenu ul li .nav-mixhubb .live-icon {
        position: initial !important;
    }
    .DeviceCompatibility .modal-dialog .modal-content .DarkBlurBackground .CheckDevice .DeviceCompTable .modal-body .SysInfoCheck .FormGroupCheck .SoundSpeakerCheck {
        display: block;
        width: 150%;
    }
    .SoundSpeaker {
        display: inline-block;
        width: 100%;
    }
    .DeviceCompatibility .modal-dialog .modal-content .DarkBlurBackground .CheckDevice .DeviceCompTable .modal-body .SysInfoCheck .FormGroupCheck .SoundSpeakerCheck .SpeakerGroup
    .mobileSocialPopup {
        display: block !important;
     }
     .DeviceCompatibility .modal-dialog .modal-content .DarkBlurBackground .CheckDevice .DeviceCompTable .modal-body .SysInfoCheck .recomdBtn {
        padding: 8px 12px !important;
     }
    // .DeviceCompatibility, .modal-backdrop {
    //     display: none !important;
    // }
    .desktopSocialPopup {
        display: none !important;
    }
    .ScheduleCalendarInfos .Watch-Session {
        display: block;
        margin-top: 26px;
    }
    .ScheduleCalendarInfos .Watch-Session .RedButton {
        padding: 8px 16px;
    }
    .ScheduleCalendarInfos .Watch-Session .BlueinButton {
        padding: 8px 16px;
    }
    #join {
        display: none;
    }
     #people {
        display: none;
    }
    #visitor {
        display: none;
    }
    .StageTabCollapes {
        display: block !important;
    }
    .MessageTextBox {
        width: calc(100% - 32px) !important;
        right: auto !important;
        margin-left: 16px;
    }
    .SocialIconPopup {
        width: 90% !important;
        margin: 0px auto !important;
        left: 0 !important;
        right: 0 !important;
        display: block !important;
    }
    #aboutInfo {
        display: block !important;
    }
    .FeedBackPopUp .modal-dialog .modal-content .modal-body .FdInnrForm .LeftFBDetail {
        border-radius: 12px 12px 0px 0px !important;
    }
    .FeedBackPopUp {
        padding: 0px !important;
        .modal-dialog  {
            padding: 0 0 !important;
            width: 95%;
            height: auto;
            margin: 10px auto 10px;
        }
    }
    .PollInfos .PollMhAuto .mh-poll-container h4 {
        margin: 4px 0 0px;
    }
    .PollInfos .PollMhAuto .mh-poll-container .BalInfoAllProgress {
        margin-top: 12px;
        margin-bottom: 00px;
    }
    .VirtuslBgPreview {
        .modal-dialog {
            .modal-content {
                padding: 0 14px;
                .modal-header {
                    .TableHeader {
                        padding: 14px 0px;
                    }
                    .ModalClose {
                        height: 24px;
                    }
                }
                
                .modal-body {
                    .EfectFullBg {
                        display: block;
                        .LeftChageBg {
                            width: 100%;
                            margin-bottom: 15px;
                        }
                        .RightAllBg {
                            width: 100%;
                            .noBgBlur {
                                margin-bottom: 12px;
                                p {
                                    margin-bottom: 3px;
                                }
                            }
                            .SelectBgIn {
                                margin-bottom: 12px;
                                ul {
                                    max-height: 150px;
                                }
                            }
                            .RedButton {
                                position: inherit;
                            }
                        }
                        
                    }
                }
            }
        }
    }
    .MixhubbLobby .LobbySelImag .BgLobbyFix .img-fluid {
        border-radius: 6px;
        height: auto !important;
    }
    .expoBoothsMix {
        //overflow: hidden !important;
        height: auto !important;
        //aspect-ratio: 16/9;
    }
    .MixhubbLobby {
        .LobbySelImag {
            .BgLobbyFix {
                display: block !important;
                //height: calc(100vh - 46px);
                //overflow-y: auto;
                height: auto;
                .LobbyBgWidgetHg {
                    height: auto !important;
                    display: block;
                    margin: 4px 0px;
                    padding: 0 8px;
                    .imageFeatureWidget {
                         height: auto !important;
                        .DrageWidgetBox {
                            display: none !important;
                        }
                    }
                }
            }
            .FullVideoFix {
                height: 100% !important;
            }
        }
    }
    .HomeLobby {
        margin: 16px 0 30px;
        //padding: 0 14px;
        display: block !important;
        .eventTimeName {
            padding: 0 14px;
            margin: 0 0 22px;
            .LobbyEvTitle {
                margin: 0px 0 12px;
                h3 {
                    font-size: 18px;
                    color: #fff;
                    font-weight: 400;
                }
            }
            .TimeScheduleCal {
                display: flex;
                align-items: center;
                margin: 15px 0 10px;
                .TimeCalenderMix {
                    display: inline-flex;
                    align-items: center;
                    svg {
                        margin-right: 12px;
                        width: 20px;
                        height: 20px;
                    }
                    p {
                        margin: 0px;
                        font-size: 14px;
                        margin-right: 20px;
                        color: #ffffff;
                        font-weight: 400;
                    }
                    &:last-child {
                        p {
                            margin-right: 0px;
                            color: #ffffff;
                        }
                    } 
                }
            }
        }
        .SocialLobbyType {
            position: relative;
            z-index: 4;
            padding: 0 18px;
            ul {
                margin: 0px;
                padding: 0;
                li {
                    display: inline-block;
                    margin: 0 8px;
                    img {
                        width: 100%;
                        object-fit: scale-down;     
                    }
                    &:first-child {
                        margin-left: 0px;
                    }
                    &:last-child {
                        margin-right: 0px;
                    }
                    .img-fluid {
                        cursor: pointer;
                        width: 50px !important;
                        height: 50px !important;
                    }
                    button {
                        border: 0;
                        background-color: transparent;
                        padding: 0px !important;
                    }
                    a {
                        svg {
                            width: 30px;
                            height: 30px;
                            fill: #ffffff;
                        }
                    }
                }
            }
        }
        .ScheduleSlick {
            padding: 0 0 25px;
            .SectionHeading {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 14px;
                margin: 20px 0 16px;
                h4 {
                    font-size: 16px;
                    margin: 0px;
                    color: #fff;
                    font-weight: 400;
                }
                p {
                    font-size: 13px;
                    color: #1A78E5;
                    font-weight: 500;
                    margin: 0;
                    background-color: rgba(0, 0, 0, 0.5);
                    padding: 4px 12px;
                    border-radius: 100px;
                }
            }
            .AllPlanSchedule {
                height: auto !important;
                padding: 0 0 !important;
                overflow: initial;
                .ScheduleCalendarInfos {
                    padding: 0px !important;
                    .slick-slider {
                        .slick-list {
                            .slick-slide {
                                padding: 0px 10px !important;
                                .StageFullInfoFix {
                                    padding: 0 0 18px;
                                    .ScheduleInfoWithConts {
                                        min-height: 160px;
                                        h3 {
                                            font-weight: 600;
                                            font-size: 16px;
                                        }
                                         .PopUpImgSpeaker {
                                            .SpkInfoImgs {
                                                width: 35px !important;
                                                height: 35px !important;
                                                img {
                                                    width: 100% !important;
                                                    height: 100% !important;
                                                    border-radius: 500px;
                                                    object-fit: cover;
                                                }
                                                .SpkInfoCount {
                                                    width: 35px;
                                                    height: 35px;
                                                    font-size: 14px;
                                                }
                                            }
                                        }
                                        .ScheduleDiscribe {
                                            p {
                                                font-size: 14px;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                                display: -webkit-box;
                                                -webkit-line-clamp: 2;
                                                -webkit-box-orient: vertical;
                                            }
                                        }
                                    }
                                    .BackAgain {
                                        padding: 0 14px;
                                    }
                                    .CalenderDateScheduleInof {
                                        .SchTimeDateFix {
                                            .ScheduleStatusOngoing {
                                                .EventStyle {
                                                    width: 104px;
                                                    padding: 4px 10px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .slick-arrow {
                            bottom: auto !important;
                            top: -30px !important;
                            svg {
                                fill: #fff;
                            }
                            &::after {
                                display: none;
                            }
                            &::before {
                                display: none;
                            }
                        }
                        .slick-prev {
                            left: auto;
                            right: 124px;
                        }
                        .slick-next {
                            right: 100px;
                        }
                    }
                }
            }
        }
    }
    .LobbyQuickLink {
        overflow-x: auto;
        display: flex;
        padding: 0px 0 18px;
        width: 100%;
        ul {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: none;
            li {
                display: inline-flex;
                background: #fff;
                margin: 0 6px;
                border-radius: 8px;
                padding: 10px 12px 8px 12px;
                min-width: 120px;
                line-height: 18px;
                .lobby-nav  {
                    width: 100%;
                    position: relative;
                    span {
                        font-size: 14px;
                        text-transform: uppercase;
                        padding-left: 0px;
                        font-weight: 400;
                    }
                    .IconStyle {
                        width: 44px;
                        height: 44px;
                        svg {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                &:first-child {
                    margin-left: 16px;
                }
                &:last-child {
                    margin-right: 16px;
                }
            }
        }
        &::-webkit-scrollbar-thumb {
            background-color: transparent !important;
        }
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 0px transparent !important;
            background-color: transparent !important;
        }
        &::-webkit-scrollbar {
            background-color: transparent !important;
        }
    }
    .AboutHomeEvent {
        padding: 0 18px;
        margin-top: 20px;
        h4 {
            color: #fff;
            font-size: 16px;
            font-weight: 500;
            margin: 0 0 12px;
        }
        p {
            color: #fff;
            font-size: 14px;
            font-weight: 400;
        }
    }
    .sepratorRow {
        border-bottom: 1px rgb(245 245 245 / 12%) solid;
    }
    .maxScreenHeight .scale-blur-fix .BlurFullImgs {
        height: calc(100vh - 8px);
    }
    .RegFooter {
        position: inherit;
        bottom: 0;
      }
      .RegisterPageAll .LoginRegisterPage P {
        padding-bottom: 0px;
    }
    .ArchivedStageRecord.screen-stage-main-div .container-fluid .MainViewBackStage {
        height: auto !important;
    }
    .ArchivedStageRecord .container-fluid .MainViewBackStage {
        height: auto !important;
    }
    .audience-front-stage-roll .MainViewBackStage{
        margin: 2px 0 2px !important;
    }
    // .RegistrationTabsHome .responsive-tabs-container {
    //     position: initial !important;
    //     top: auto !important;
    // }
    .Toastify .Toastify__toast-container {
        width: 96% !important;
        margin: 0px auto !important;
        left: 0 !important;
        right: 0 !important;
        top: 8px !important;
    }
    .Toastify .Toastify__toast-container .Toastify__toast {
        min-height: 50px !important;
        padding: 4px !important;
    }
    .overflow-hidden {
        overflow: initial !important;
    }
    .BlurFullImgs {
        filter: blur(12px);
        transform: scale(1.1);
        &::after {
          content: '';
          background: rgb(0 0 0 / 40%);
        }
      }
    //   .RegisterPageAll .LoginRegisterPage {
    //       overflow-y: hidden;
    //   }
    .RegistrationTabsHome .responsive-tabs-container ul li a.activeTab {
        background-color: rgb(51, 51, 51) !important;
        color: #ffffff !important;
    }
    .InviteTableCall {
        z-index: 1000;
    }
    .AttendeeFrame {
        .YoutubeVideo {
          .ControlFrame {
            padding: 0 5px !important;
            .LeftControls {
              .UtButton {
               button {
                   svg{
                    width: 35px;
                    height: 35px;
                   }
               }
              }
              .liveSession {
                span {
                  font-size: 10px;
                  padding: 0 5px;
                  margin-left: 6px;
                }
             }
             .MuteUnmute {
                .e-range {
                    width: 45px !important;
                   }
                &:hover {
                    .VolumeCtrl {
                        height: 32px;
                        width: 45px !important;
                     }
                 }
             }
            }
            .RightControls {
              .UtButton {
                width: 35px;
                height: 35px;
                button {
                padding: 0px;
                border: 0;
                background-color: transparent;
                svg {
                  background-color: transparent;
                  fill: #fff;
                }
                }
              }
            }
          }
        }
        .playBlack {
          .PlayAgain {
            svg {
              width: 70px;
              height: 70px;
            }
          }
        }
      }
      .desktopBgImg {
          display: none !important;
      }
      .mobileBgImg {
        display: block !important;
      }
      .UserEditInfos .FullYourProfile .ProfileEditPic .ProfileOverflow .PicProdileUp .minHeightBox .drop-zone-img-preview .dzu-previewStatusContainer .upload-icons {
        right: -12px !important;
        width: 28px;
        height: 28px;
      }



    .PicProdileUp {
        .UpdateOvelayImgVideo {
            display: block;
           .ImgSelecetd {
                width: 105px;
                height: 105px;
                margin: 0px 0 0 1px;
            }
            .ImgActionUp {
                right: -16px !important;
                .EditImgBtn {
                    padding: 5px !important;
                }
            }
            .BrowserImageTxt {
                display: none;
            }
        }
    }
    .CropImgPopup {
        width: 95%;
        .FooterCustom {
            padding: 18px 0;
        }
        .PopupAction {
            .btn {
                width: 135px !important;
                margin: 0 8px;
            }
        }
       header {
        position: initial !important;
       }      
    }
    .PeopleChatPopup .StateEventDtl .StageBackStage .EventInfoStage .PollInfos .PollMhAuto {
        height: calc(100vh - 452px) !important;
    }
    .PeopleChatPopup .StateEventDtl .StageBackStage .EventInfoStage .PollInfos {
        height: calc(100vh - 440px) !important;
      }
}

@media (min-width: 576px) and (max-width: 767px) {
    .maxScreenHeight {
        margin-top: 45px;
        height: calc(100vh - 45px);
    }
	.MixhubbHeader {
        padding: 4px 0;
        height: 45px;
        .HeaderMainTop {
            .MixhubbLogo {
                max-width: 170px;
                flex: 0 0 170px;
                float: left;
                height: 45px
            }
            .MixhubbMenu {
                ul {
                    li {
                        padding: 16px 30px;
                        .nav-mixhubb  {
                            width: 100%;
                            display: block;
                        }
                    }
                }
            }
        }
    }

    .SchedulePageEvent {
        .SetEventSchedule {
            .ScheduleDayStage {
                padding: 0px 0 0px !important;
                margin: 14px 0 0;
                h4 {
                    font-size: 20px;
                    margin: 0 0 12px;
                }
                .SelectDayStage {
                    .SelectDays {
                        .SelectDropdown {
                            .dropdown {
                                font-size: 14px;
                                height: 38px;
                            }
                        }
                    }
                    .DayScheduleStages {
                        //width: 150px;
                        .SelectDropdown {
                            .SeleftDDl {
                                .dropdown-toggle {
                                    height: 42px;
                                }
                            }
                            &:after {
                                width: 11px;
                                height: 11px;
                                right: 17px;
                                top: 11px;
                            }
                        }
                    }
                }
            }
            .RoomSeatSelect {
                display: block;
                border-radius: 10px;
                .RoomMeetSrch {
                    margin: 10px 0 0;
                    display: flex;
                    justify-content: space-between;
                    .RoomsSerchHere {
                        width: 68%;
                    }
                    .MyMeetingHere {
                        width: 135px;
                    }
                }
            }
        }
    }

    .SchedulePageEvent  {
        .RoomScheduleInfos {
            .ScheduleDayStage {
                padding: 16px 0 0 !important;
            }
        }
    }
    
    .AllPlanSchedule {
        padding: 0 0px 0 0px !important;
        height: calc(100vh - 192px) !important;
        transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        .ScheduleCalendarInfos {
            .StageFullInfoFix {
                margin-bottom: 20px;
                border-radius: 12px;
                display: block;
                .CalenderDateScheduleInof {
                    padding: 12px 18px 12px;
                    border-radius: 12px 12px 0 0;
                    .StageNo {
                        padding: 2px 8px;
                        top: auto;
                        left: 0;
                        right: 0;
                        width: 130px;
                        margin: 0px auto;
                        bottom: -10px;
                        font-size: 12px;
                    }
                    .DtStageEventDay {
                        h4 {
                            line-height: 22px;
                            font-size: 18px;
                        }
                    }
                    .ScheduleTMFx {
                        margin: 0px 0 0;
                        p {
                            font-size: 18px;
                            font-weight: 600;
                        }
                    }
                    .ScheduleStatusOngoing {
                        margin: 0px 0 0;
                        label {
                            font-weight: 600;
                            padding: 4px 14px;
                            font-size: 14px;
                        }
                    }
                }
            }
            .ScheduleInfoWithConts {
                h3 {
                    margin: 0 0 5px;
                    font-size: 18px;
                }
                p {
                    font-size: 16px;
                }
            }
            .SpeakerRowList {
                .EventSpeakerExp {
                    padding: 12px 10px;
                    //margin: 0 4px 7px 4px;
                   // width: 48%;
                    .ImgSpeakerBg {
                        width: 40px;
                        height: 40px;
                    }
                    .SpeakerNamePos {
                        margin-left: 10px;
                        h4 {
                            font-size: 14px;
                        }
                        p {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }

    .MenuDDlMaxHeight {
        height: calc(100vh - 262px) !important;
        margin-top: 15px;
        transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    }
    .MixhubbStage {
        overflow-y: inherit;
        .StageMixhubbEvent {
            .SetEventInfo {
                padding: 28px 10px 20px;
                .SetupStatus{
                    span {
                        padding: 2px 28px;
                    }
                }
                h3 {
                    font-size: 18px;
                    line-height: 24px;
                }
                .AboutSetupInfo {
                    background-color: var(--light-gray);
                    padding: 8px 10px;
                    margin: 15px 0 20px;
                    h4 {
                        font-size: 15px;
                    }
                    p {
                        font-size: 13px;
                    }
                }
    
                .ExpoSetupInfo {
                    min-height: 88px;
                }
                .StageListSession {
                    .StageSessionMix {
                        height: 42px;
                        &::after {
                            top: 12px;
                            width: 10px;
                            height: 10px;
                        }
                        .session-list {
                            font-size: 14px;
                        }
                    }
                    .EnterStageBtn {
                        padding: 8px 22px;
                    }
                }
            }
        }
    }

    .NotifyMixhubbRt .headingTop .CloseButton {
        display: block;
    }


    .NetwokingProfileQuick {
        margin-top: 20px;
        padding: 10px 12px;
        .ProfilePicInfo {
            width: 48px;
            height: 48px;
            margin-right: 6px;
        }
        .ProfileTxtInfo {
            width: 75%;
            position: relative;
            h4 {
                font-size: 14px;
                font-weight: 500;
                margin: 0 0 2px;
            }
            p {
                font-size: 12px;
            }
        }
        .ReadProfileIcon {
            width: 38px;
            height: 38px;
            padding: 11px !important;
        }
    }
    
    .RecommendProfiles {
        p {
           margin: 18px 0 0;
           font-size: 16px;
           line-height: 22px;
       }
    }
    .QuickShufflingMatch {
        background-color: var(--light-gray);
        padding: 22px 25px;
        border-radius: 16px;
        margin-top: 30px;
        h2 {
            font-size: 20px;
        }
        p {
            font-size: 14px;
            margin: 0 0 4px;
            font-weight: 400;
        }
        .MatchQuickGIF {
            padding: 22px 28px;
        }
        .RedButton {
            width: 160px;
            margin: 0px auto;
            display: table;
            font-size: 15px;
            font-weight: 700;
            svg {
                width: 20px;
                height: 20px;
            }
        }
    }
    .NetworkIngAllAttendess {
        .InterestTag {
            margin-left: 0;
            margin-top: 18px;
            span {
                margin-left: 0px;
                margin-right: 6px;
            }
            p {
                margin-bottom: 0;
            }
        }
        .FilderToggleBox {
            .FilterToggleInfo {
                width: 355px;
                .InputHere {
                    .form-control {
                        width: 250px;
                        height: 40px;
                    }
                }
            }
        }
        .AttendeesHeader {
            display: block;
            .LeftSideAttendess {
                width: 315px;
                h4 {
                    font-size: 20px;
                }
                .RedButton {
                    width: 110px;
                    font-size: 16px;
                }
            }
        }
    }
    
    .NetworkIngAllAttendess {
    	.RIghtAttendesSearch {
            margin: 20px 0 0;
    		.NetworkPeople {
		        width: 100%;
		    }
    	}
    }
    .NetworkIngAllAttendess {
        .RIghtAttendesSearch {
            .NetworkPeople {
                .PepleSerchHere {
                    .SerchHere {
                        height: 40px;
                    }
                }
            }
        }
    }
    .RecommendProfiles {
        padding: 8px 0 0;
        h4 {
            margin: 0 0 0px;
            font-size: 20px;
        }
    }
    .CameraPrevModal .modal-dialog .modal-content .DarkBlurBackground .JoinTableRoom .JoinPersonTable .modal-body .JoinWithTableLt {
        padding: 0 12px 0 14px;
    }
    .JoinTableRoom .joinNowTableUs .SelectCamraMicro {
        width: 50%;
        padding: 0 16px;
    }
    

    .SchedulePageEvent .SetEventSchedule .ScheduleDayStage .SelectDayStage .MyMeetingHere .MymeetingBtn {
        padding: 10px 22px;
    }





    .RegisterPageAll {
        .LoginRegisterPage {
            .MixhubbExpDae {
                h1 {
                    font-size: 22px;
                    margin: 0 0 12px;
                }
            }
        }
    }
    .RegisterPageAll {
        .LoginRegisterPage {
            .MixhubbExpDae {
                p {
                    font-size: 18px;
                }
                .event-timezone {
                    font-size: 14px;
                    margin: 4px 0 0;
                }
            }
        }
    }
    .RegisterPageAll {
        .LoginRegisterPage {
            .EnterEventAction {
                h2 {
                    font-size: 24px;
                    margin: 0 0 25px;
                }
            }
        }
    }
    .RegisterPageAll {
        .LoginRegisterPage {
            .EnterEventAction {
                margin: 30px auto 0;
            }
        }
    }
    .RegisterPageAll {
        .LoginRegisterPage {
            .EnterEventAction {
                .SocialLoginType {
                    ul {
                        li {
                            display: inline-block;
                            margin: 0 6px;
                            .img-fluid {
                                cursor: pointer;
                                width: 48px;
                                height: 48px;
                            }
                            svg {
                                width: 48px;
                                height: 48px;
                              }
                        }
                    }
                }
            }
        }
    }
    .EventTypeActonIn {
        .form-group {
            .form-control {
                padding: 12px 28px;
            }
        }
    }
    .EventTypeActonIn {
        .form-group {
            .EnterBtn {
                border-radius: 100px;
                padding: 10px 28px;
            }
        }
    }
    .RegisterPageAll {
        overflow-y: auto;
        height: auto;
        .LoginRegisterPage {
            width: 100%;
            min-width: 100%;
            position: relative;
            overflow: initial;
            padding-bottom: 0px;
        }
    }
    .RegisterPageAll {
        .LeftImgBlock {
            width: 100%;
        }
    }




    .timer-countDown {
        .timerBodyCondown {
            width: 248px;
            overflow: hidden;
            div {
                width: 50px !important;
                height: 50px !important;
                .time-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    .time {
                        font-size: 22px;
                        line-height: 30px;
                    }
                }
                .time-wrapper {
                    div {
                        font-size: 9px;
                    }
                }
            }
            span {
                font-size: 50px;
            }
        }
    }
    .EventCalSchedules .EventCalenderIcon img {
        width: 45px;
        height: 45px;
        margin: 0 6px;
    }
    .FullYourProfile .nav-tabs li {
        margin: 0 12px;
    }
    .ProfileMyInterest {
        li {
            display: inline-block;
            width: 50%;
        }
    }
    .SchedulePageEvent .SetEventSchedule .ScheduleDayStage .SelectEnterZone {
        width: 100%;
    }
    .SchedulePageEvent {
        .SetEventSchedule {
            padding: 0px 0 0px;
            margin: 0 0 0;
        }
    }
    .scheduleTages {
        margin: 12px 0px 0px;
        justify-content: flex-start;
    }
    .MobileAppFilter {
        margin: 16px 0 0px;
        width: 100%;
    }
    .MobileAppFilter .SelectSchedule {
        width: 88%;
    }
    .TagLengthInfo {
        height: calc(100vh - 185px);
    }
    .TagLengthInfo.ZoneListExpoList {
        height: calc(100vh - 198px);
      }
    .scheduleTages .TagSelect p {
        font-size: 14px;
    }
    .BackStageSessionInfo {
        width: 340px;
    }
    .MixhubbHeader .HeaderMainTop .MixhubbLogo img {
        height: 30px;
    }

    .screen-stage-main-div {
        .MainViewBackStage {
            .alignAllDiv  {
                width: 100% !important;
                .front-stage-stream-scroll {
                    .row {
                        .stage-user-list {
                            .RightSvgIcon {
                                .mh-cursor-pointer {
                                    top: 3px;
                                    left: 3px;
                                    width: 18px;
                                    height: 18px;
                                    padding: 4px !important;
                                }
                            }
                            .list-1, .list-2, .list-3, .list-4, .list-5, .list-6, .list-7, .list-8, .list-9, .list-10, .list-11, .list-12, .list-13, .list-14, .list-15, .list-16 {
                                height: auto;
                                display: inline-flex;
                                width: 120px
                            }
                        }
                    }
                }
            }
           
        }
    }



    .screen-stage-main-div.oraganizer-backstage-dashboard {
        .MainViewBackStage  {
            .alignAllDiv {
                .front-stage-stream-scroll {
                    width: 97% !important;
                    .row {
                        flex-flow: column;
                        .front-stage-share-screen {
                            width: 100% !important;
                            height: auto !important;
                        }
                        .stage-user-list {
                            width: 100% !important;
                            overflow: auto;
                            overflow-y: hidden;
                            display: inline-flex;
                            flex-flow: row nowrap;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 6px 4px;
                            position: relative !important;
                            height: auto !important;
                            margin-left: 6px;
                            .video_footer {
                                .ShortName {
                                    h4 {
                                        width: 45px;
                                        height: 45px;
                                        font-size: 22px;
                                    }
                                }
                                .bottom_footer {
                                    h4 {
                                        font-size: 10px !important;
                                        span {
                                            line-height: 14px !important;
                                        }
                                    }
                                }
                                .BackIconAction {
                                    top: 5px;
                                    right: 3px;
                                    .ActionLive {
                                        width: 18px;
                                        height: 18px;
                                        .widthSvg {
                                            padding: 3px 3px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }




    .screen-stage-main-div.oraganizer-backstage-dashboard.collapse-out-frontstage {
        .MainViewBackStage {
            .alignAllDiv {
                width: 100% !important;
                .front-stage-stream-scroll {
                    width: 97% !important;
                    .row {
                        .stage-user-list {
                            height: auto !important;
                        }
                    }
                }
            }
        }
    }

    .screen-stage-main-div.backstage-dashboard {
        .MainViewBackStage  {
            .alignAllDiv {
                width: 100% !important;
                .front-stage-stream-scroll {
                    width: 97% !important;
                    .row {
                        flex-flow: column;
                        .front-stage-share-screen {
                            width: 100% !important;
                            height: auto !important;
                            
                        }
                        .stage-user-list {
                            width: 100% !important;
                            overflow: auto;
                            overflow-y: hidden;
                            display: inline-flex;
                            flex-flow: row nowrap;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 6px 4px;
                            position: relative !important;
                            height: auto !important;
                            margin-left: 6px;
                            .video_footer {
                                .ShortName {
                                    h4 {
                                        width: 45px;
                                        height: 45px;
                                        font-size: 22px;
                                    }
                                }
                                .bottom_footer {
                                    h4 {
                                        font-size: 10px !important;
                                        span {
                                            line-height: 14px !important;
                                        }
                                    }
                                }
                                .BackIconAction {
                                    top: 5px;
                                    right: 3px;
                                    .ActionLive {
                                        width: 18px;
                                        height: 18px;
                                        .widthSvg {
                                            padding: 3px 3px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .screen-stage-main-div.backstage-dashboard.collapse-out-frontstage {
        .MainViewBackStage {
            .alignAllDiv {
                width: 100% !important;
                .front-stage-stream-scroll {
                    width: 97% !important;
                    .row {
                        .stage-user-list {
                            height: auto !important;
                        }
                    }
                }
            }
        }
    }

    .screen-stage-main-div.organizer-frontend-dashboard {
        .MainViewBackStage  {
            .alignAllDiv {
                .front-stage-stream-scroll {
                    width: 97% !important;
                    .row {
                        flex-flow: column;
                        .front-stage-share-screen {
                            width: 100% !important;
                            height: auto !important;
                        }
                        .stage-user-list {
                            width: 100% !important;
                            overflow: auto;
                            overflow-y: hidden;
                            display: inline-flex;
                            flex-flow: row nowrap;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 6px 4px;
                            position: relative !important;
                            height: auto !important;
                            margin-left: 6px;
                            .video_footer {
                                .ShortName {
                                    h4 {
                                        width: 45px;
                                        height: 45px;
                                        font-size: 22px;
                                    }
                                }
                                .bottom_footer {
                                    h4 {
                                        font-size: 10px !important;
                                        span {
                                            line-height: 14px !important;
                                        }
                                    }
                                }
                                .BackIconAction {
                                    top: 5px;
                                    right: 3px;
                                    .ActionLive {
                                        width: 18px;
                                        height: 18px;
                                        .widthSvg {
                                            padding: 3px 3px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .screen-stage-main-div.frontend-dashboard {
        .MainViewBackStage  {
            .alignAllDiv {
                .front-stage-stream-scroll {
                    width: 97% !important;
                    .row {
                        flex-flow: column;
                        .front-stage-share-screen {
                            width: 100% !important;
                            height: auto !important;
                        }
                        .stage-user-list {
                            width: 100% !important;
                            overflow: auto;
                            overflow-y: hidden;
                            display: inline-flex;
                            flex-flow: row nowrap;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 6px 4px;
                            position: relative !important;
                            height: auto !important;
                            margin-left: 6px;
                            .video_footer {
                                .ShortName {
                                    h4 {
                                        width: 45px;
                                        height: 45px;
                                        font-size: 22px;
                                    }
                                }
                                .bottom_footer {
                                    h4 {
                                        font-size: 10px !important;
                                        span {
                                            line-height: 14px !important;
                                        }
                                    }
                                }
                                .BackIconAction {
                                    top: 5px;
                                    right: 3px;
                                    .ActionLive {
                                        width: 18px;
                                        height: 18px;
                                        .widthSvg {
                                            padding: 3px 3px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .audience-front-stage-roll.screen-stage-main-div {
        .MainViewBackStage  {
            .alignAllDiv {
                .front-stage-stream-scroll {
                    width: 97% !important;
                    .row {
                        flex-flow: column;
                        .front-stage-share-screen {
                            width: 100% !important;
                            height: auto !important;
                        }
                        .stage-user-list {
                            width: 100% !important;
                            overflow: auto;
                            overflow-y: hidden;
                            display: inline-flex;
                            flex-flow: row nowrap;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 6px 4px;
                            position: relative !important;
                            height: auto !important;
                            margin-left: 6px;
                            .video_footer {
                                .ShortName {
                                    h4 {
                                        width: 45px;
                                        height: 45px;
                                        font-size: 22px;
                                    }
                                }
                                .bottom_footer {
                                    h4 {
                                        font-size: 10px !important;
                                        span {
                                            line-height: 14px !important;
                                        }
                                    }
                                }
                                .BackIconAction {
                                    top: 5px;
                                    right: 3px;
                                    .ActionLive {
                                        width: 18px;
                                        height: 18px;
                                        .widthSvg {
                                            padding: 3px 3px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .MainViewBackStage {
        .alignAllDiv {
            .front-stage-stream-scroll {
                .localPlayer {
                    div {
                        border-radius: 5px;
                        video {
                            border-radius: 5px;
                        }
                    }
                }
            }
        }
    }

    .audience-front-stage-roll {
        .MainViewBackStage {
            .RemoveAllDiv {
                width: 100% !important;
                .ront-stage-stream-scroll {
                    width: 100% !important;
                }
            }
        }
    }

    .oraganizer-backstage-dashboard {
        .MainViewBackStage {
            height: calc(100vh - 250px) !important;
            .RemoveAllDiv {
                width: 100% !important;
                .front-stage-stream-scroll {
                    width: 100% !important;
                }
            }
        }
    }
    .oraganizer-backstage-dashboard.collapse-out-frontstage {
        .MainViewBackStage {
            height: calc(100vh - 110px) !important;
            .RemoveAllDiv {
                width: 100% !important;
                .front-stage-stream-scroll {
                    width: 100% !important;
                }
            }
        }
    }

    .audience-front-stage-roll {
        .MainViewBackStage {
            .RemoveAllDiv {
                width: 100% !important;
                .front-stage-stream-scroll {
                    width: 100% !important;
                }
            }
        }
    }
    .backstage-dashboard {
        .MainViewBackStage {
            height: calc(100vh - 255px) !important;
            margin: 6px 0 !important;
            .RemoveAllDiv {
                width: 100% !important;
                .front-stage-stream-scroll {
                    width: 100% !important;
                }
            }
        }
    }
    .backstage-dashboard.collapse-out-frontstage {
        .MainViewBackStage {
            height: calc(100vh - 112px) !important;
            margin: 6px 0 !important;
            .RemoveAllDiv {
                width: 100% !important;
                .front-stage-stream-scroll {
                    width: 100% !important;
                }
            }
        }
    }
    .FeedBackPopUp {
        .modal-dialog {
            display: block;
            .modal-content {
                .modal-body {
                    .FdInnrForm {
                        height: auto;
                        .LeftFBDetail {
                            max-width: 100%;
                            text-align: center;
                            h1 {
                                margin: 0 0 30px;
                            }
                        }
                        .RightFbDetail {
                            width: 100%;
                            padding: 25px !important;
                            .FbFormMixInfos {
                                .form-group {
                                    .FeedForm {
                                        width: 100%;
                                        .MuiFormControl-root {
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                        .FeedFormSubmit {
                            padding: 24px 0 0;
                            .MuiButtonBase-root {
                                position: initial;
                                float: right;
                            }
                        }
                    }
                }
            }
        }
    }
    .RegisterPageAll .LeftImgBlock .LoginEventLogo {
        top: 8px;
        left: 8px;
        width: 100px;
    }
    .RegisterPageAdvance .container .row .LoginRegisterPage .AdvanceEventDelReg .DetailFixEventMx .registration-timezone div {
        font-size: 14px;
    }
    .scheduleTages {
        padding: 0 2px;
    }
    .SchedulePageEvent .SetEventSchedule .ScheduleDayStage .SelectEnterZone {
        width: 80%;
    }
    .SchedulePageEvent .ExpoScheduleInfos .ScheduleDayStage .SelectEnterZone {
        width: 100% !important;
    }
    .TagScheduleHght {
        height: calc(100vh - 135px) !important;
        padding: 0px 20px 0 0px !important;
    }
    .scheduleTages {
        .TagNameList {
            width: 74%;
        }
        .TagSelect {
            width: 100px;
        }
    }
    .scheduleTages .TagSelect p {
        font-size: 13px;
    }
    .ZoneEnterDiscInfo {
        .EnterZoneBooth {
            .maxHeightBooth {
                p {
                    font-size: 14px;
                }
                h3 {
                    font-size: 20px;
                }
            }
        }
    }
    .RoomScheduleTable {
        height: calc(100vh - 135px);
        overflow-y: auto;
        padding: 5px 0px 0 0;
    }
    .TagRoomsInfo {
        height: calc(100vh - 195px);
        overflow-y: auto;
        padding: 10px 0px 0 0;
    }
    .ZoneListExpoList {
        height: calc(100vh - 142px);
    }
    .PeopleChatPopup {
        height: calc(100vh - 52px);
    }
    .scheduleTages {
        margin: 12px 0px 0px;
    }
    .ZoneEnterDiscInfo .LargeBoothHeight .EnterZoneBooth {
        margin: 8px 0 8px !important;
    }
    .MobBackstage, .go-backstage-btn {
        display: none;
    }
    .ModalCommonInfo .modal-dialog .modal-content .modal-body .MeetingNewSetup .DayMeetingTimeSetup .btnFullBtn {
        margin: 0px 0 0;
    }
    .ModalCommonInfo .modal-dialog .modal-content .modal-body .MeetingNewSetup .DayMeetingTimeSetup .form-group {
        margin: 0px 0 0;
    }
    .SideRightMenu {
        margin-right: 6px;
        ul {
            li {
                padding: 6px 3px !important;
                margin: 4px 0 !important;
                width: 46px;
                button {
                    svg {
                        width: 18px;
                        height: 18px;
                    }
                    span {
                        font-size: 10px;
                        padding: 0px !important;
                    }
                }
                .RightTopStyle {
                    width: auto;
                    ul {
                        li {
                            padding: 0px 0px !important;
                            margin: 0px 0 !important;
                            button {
                                svg {
                                    width: 22px;
                                    height: 22px;
                                }
                                span {
                                    font-size: 10px;
                                    padding: 0px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    
    .audience-front-stage-roll.collapse-out-frontstage {
        .container-fluid {
            .SideRightMenu {
                ul {
                    li {
                        padding: 8px 2px 8px !important;
                        margin: 4px 0 !important;
                        span {
                            font-size: 8px;
                            padding: 0!important;
                            line-height: 12px;
                        }
                        .RightTopStyle {
                            ul {
                                li {
                                    padding: 0px 2px 0px !important;
                                    margin: 0px !important;
                                    .ChatClick {
                                        span {
                                            font-size: 8px;
                                            padding: 0!important;
                                            line-height: 12px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                
            }
        }
    }
    
    .audience-front-stage-roll {
        .container-fluid {
            .SideRightMenu {
                ul {
                    li {
                        padding: 8px 2px 8px !important;
                        margin: 4px 0 !important;
                        span {
                            font-size: 8px;
                            padding: 0!important;
                            line-height: 12px;
                        }
                        .RightTopStyle {
                            ul {
                                li {
                                    padding: 0px 2px 0px !important;
                                    margin: 0px !important;
                                    .ChatClick {
                                        span {
                                            font-size: 8px;
                                            padding: 0!important;
                                            line-height: 12px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                
            }
        }
    }
    .JoinTableRoom .joinNowTableUs .SelectCamraMicro .JoinTableBtn .DevRedButton {
        padding: 8px 8px;
        font-size: 14px;
    }
    .JoinTableRoom .joinNowTableUs .SelectCamraMicro .JoinTableBtn .JoinRedButton {
        padding: 8px 8px;
        font-size: 14px;
    }
    .SelectCamraMicro .CamraMicophoneDDl .form-group .SelectDropdown .dropdown .css-2b097c-container .css-yk16xz-control {
        height: 42px;
    }
    .SelectCamraMicro .CamraMicophoneDDl .form-group .SelectDropdown .dropdown .css-2b097c-container .css-1pahdxg-control {
        height: 42px;
    }
    .SelectCamraMicro .CamraMicophoneDDl .form-group .SelectDropdown .css-1hb7zxy-IndicatorsContainer::after {
        width: 10px;
        height: 10px;
        right: 18px;
        top: 14px;
    }
    .DeviceCompatibility .modal-dialog .modal-content .DarkBlurBackground .CheckDevice .DeviceCompTable .modal-body .HeaderCheckSystem h4 {
        font-size: 18px;
    }
    .RegFooter {
        width: 47%;
        min-width: 47%;
    }
}
@media (max-width: 575px) {
    .CameraPrevModal {
        .modal-dialog {
            display: block;
            margin-top: 30px;
            .modal-content {
                .DarkBlurBackground {
                    .JoinTableRoom {
                        .JoinPersonTable {
                            .modal-body {
                                .joinNowTableUs {
                                    display: block;
                                    .JoinWithTableLt {
                                        width: 100%;
                                        height: 270px;
                                        margin: 0 0 20px;
                                    }
                                    .SelectCamraMicro {
                                        width: 100%;
                                        margin-top: 20px ​!important;
                                        padding: 0 12px;
                                    }
                                }
                                .JoinWithTableLt {
                                    padding: 0 12px;
                                }
                                .ModalClose {
                                    bottom: -70px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .ModalCommonInfo {
        .modal-dialog {
            max-width: 96%;
            height: auto;
            margin: 10px auto 10px !important;
            display: block;
        }
    }
    .RegisterPageAll .LeftImgBlock .LoginEventLogo .EventLogoFix {
        width: 100%;
    }
    .RegisterPageAll .LoginRegisterPage .mixhubbLogo {
        width: 46.84vw;
        margin: 82px auto 15px;
        height: 90px;
    }
    
    .ScheduleEvInfo {
        margin-top: 12px !important;
    }
    
    .TagScheduleHght.MenuDDlMaxHeight {
        height: calc(100vh - 202px) !important;
        margin-top: 0px;
    }
    .MenuDDlMaxHeight {
        height: calc(100vh - 262px) !important;
        margin-top: 15px;
        transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    }
    
    .SchedulePageEvent .SetEventSchedule {
        padding: 0px 0;
        margin: 0 0 0px;
        transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    }
    .RegisterPageAll .LeftImgBlock .scale-blur-fix .LoginLeftHg {
        height: auto;
        aspect-ratio: 16/9;
    }
    .MobileAppFilter .InnerActionDotFill .BgCommRd {
        width: 38px;
        height: 38px;
    }
    .MobileAppFilter {
        .InnerActionDotFill {
            .BgCommRd {
                width: 42px;
                height: 42px;
                svg {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
    .ScheduleCalendarInfos .PopUpImgSpeaker .SpkInfoImgs {
        width: 45px;
        height: 45px;
        margin-right: 12px;
    }
    .ScheduleCalendarInfos .PopUpImgSpeaker .SpkInfoCount {
        width: 44px;
        height: 44px;
    }
    .ReadMorePopUp .modal-dialog .modal-content .modal-body .ReadMoreDeso .SectionWatch .PopUpImgSpeaker .SpkInfoImgs {
        width: 40px;
        height: 40px;
        margin-right: 10px;
    }
    .ReadMorePopUp .modal-dialog .modal-content .modal-body .ReadMoreDeso .SectionWatch .PopUpImgSpeaker .SpkInfoCount {
        width: 40px;
        height: 40px;
        font-size: 14px;
    }
    .SpeakerGpList .modal-dialog {
        max-width: 100% !important;
    }
    .SpeakerGpList {
        .modal-dialog {
            display: flex !important;
            .modal-content {
                height: calc(100vh - 115px);
            }
        }
    }
    .SpeakerGpList .modal-dialog .modal-content .modal-body .SpeakerList .SpeakerListSection {
        height: calc(100vh - 210px) !important;
    }
    
    .BackstageInfoStream {
        .HeadingBstage {
            padding: 6px 12px;
            h3 {
                font-size: 15px;
                padding-left: 35px;
            }
            // .BsMidAction {
            //     right: 0;
            //     left: auto !important;
            //     margin: inherit !important;
            // }
        }
    }
    .collapsDivToggle {
        right: auto;
        left: 12px;
        width: 24px;
        height: 24px;
        top: 11px;
        .CastingToggle {
            padding: 0;
            &:after {
                width: 8px;
                height: 8px;
                right: 7px;
            }
        }
    }
    .WelcomeStage .modal-dialog .modal-content .modal-body .MeetingNewSetup .DayMeetingTimeSetup .btnFullBtn {
        margin: 0 0 0 !important;
    }
    .YoutProfilePopUp .FullYourProfile {
        padding-left: 0px;
    }
    .YoutProfilePopUp {
        .modal-dialog {
            max-width: 96% !important;
            margin: 6px auto 10px !important;
            .modal-content {
                height: calc(100vh - 108px) !important;
                margin: 0px 0 0;
                background-color: #F1F3F4;
                .modal-header {
                    padding: 16px 20px 0;
                    display: none;
                }
                .modal-body {
                    padding: 0px;
                    .FullYourProfile {
                        .responsive-tabs-container {
                            justify-content: space-between;
                            border-bottom: 1px #C3C3C3 solid;
                            padding: 12px 0 14px;
                            margin: 0 0 24px;
                            .nav-tabs {
                                margin: 0px !important;
                                li {
                                    .nav {
                                        .nav-link {
                                            padding: 6px 16px !important;
                                            font-size: 14px !important;
                                        }
                                    }
                                }
                            }
                        }
                        .InfoInterestEdit {
                            padding: 0 16px;
                        }
                        .ProfileEditPic {
                            padding: 0 0px !important;
                            .ProfileOverflow {
                                padding: 0 16px !important;
                                height: calc(100vh - 290px) !important;
                            }
                        }
                        .responsive-tabs-container {
                            border: 0px !important;
                        }
                    }
                }
                .ModalClose {
                    top: 4px !important;
                    right: 6px !important;
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
    .ProfileOverflow {
        height: calc(100vh - 258px) !important;
    }
    .InfoInterestEdit .ProfileBasicDetails .form-group .EditInfoDetails .UpdateOvelayImgVodeo .minHeightBox {
        height: 154px !important;
        min-height: 154px !important;
        flex: 0 0 272px !important;
        max-width: 272px !important;
    }
    .InfoInterestEdit .ProfileBasicDetails .form-group .EditInfoDetails label {
        font-size: 12px;
        font-weight: 440;
    }
    .edit-expo-booth {
        bottom: 8px;
        right: 8px;
        position: absolute;
        .btn-info {
            padding: 8px 10px;
            font-size: 12px;
        }
    }
    .AreYouSure {
        .modal-dialog {
            height: 100vh !important;
            display: flex;
            width: 98%;
            .modal-content {
                .DarkBlurBackground {
                    .SureTableLeave {
                        .FullContainer {
                            .ActionBtnMain {
                                display: inline-block;
                                margin: 0 4px;
                                width: 45%;
                            }
                        }
                    }
                }
            }
        }
    }
    .EditInfoDetails {
        .UpdateOvelayImgVodeo {
            .minHeightBox {
                .UploaderFiles {
                    .UploadLabel {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        br {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .FooterButton {
        .EditSaveNext {
            bottom: 15px;
            .SaveNext {
                width: 90% !important;
            }
        }
    }
    .BoothFootAction {
        bottom: 8px;
        position: absolute;
        .ActionBtnBottom {
            .btn-info {
                margin: 0 6px;
                padding: 5px 10px;
                svg {
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }
    .FullYourProfile {
        .nav-tabs li {
            .nav {
                .nav-link {
                    outline: none;
                    box-shadow: none;
                    &.active {
                        padding: 8px 10px !important;
                    }
                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                    &:hover {
                        outline: none;
                        box-shadow: none;
                    }
                }
            }
        }
    }
    .StageMixhubbEvent {
            .MainViewBackStage {
                display: flex !important;
                align-items: center;
                justify-content: center;
        }
    }
    .oraganizer-backstage-dashboard {
        .MainViewBackStage {
            .RemoveAllDiv {
                width: 100% !important;
                height: auto !important;
            }
        }
    }
    .organizer-frontend-dashboard {
        .MainViewBackStage {
            .RemoveAllDiv {
                width: 100% !important;
                height: auto !important;
            }
        }
    }
    .CreatePollModal .modal-dialog .modal-content .modal-body .MeetingNewSetup {
        height: initial !important;
        margin: 20px 0 20px !important;
        max-height: calc(100vh - 156px) !important;
    }
    .ThankyouPopUp .modal-dialog .modal-content .modal-body .ThankyouNow {
        height: 220px;
    }
    .FeedBackPopUp .modal-dialog .modal-content .modal-body {
        //height: calc(100vh - 52px);
        //overflow-y: auto;
    }
    .scheduleTages {
        display: block;
        .TagNameList {
            width: 100%;
        }
        .TagSelect {
            width: 100px;
        }
    }
    .scheduleTages .TagSelect p {
        font-size: 13px;
    }
    .ZoneEnterDiscInfo {
        .EnterZoneBooth {
            .maxHeightBooth {
                p {
                    font-size: 13px;
                }
                h3 {
                    font-size: 18px;
                }
            }
        }
    }
    .AllPlanSchedule .ScheduleCalendarInfos .ScheduleInfoWithConts {
        padding: 20px 14px 18px;
    }
    .ZoneListExpoList {
        height: calc(100vh - 134px);
    }
    .AreYouSure {
        .modal-dialog {
            .modal-content {
                .DarkBlurBackground {
                    .SureTableLeave {
                        .LeagePgHeader {
                            border-radius: 10px 10px 0 0 !important;
                            padding: 12px 0;
                            h4 {
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
        }
    }
    .AreYouSure .modal-dialog .modal-content .DarkBlurBackground .SureTableLeave {
        border-radius: 10px !important;
    }
    .MobileAppFilter {
        width: 88%;
    }
    .MobileAppFilter .SelectSchedule {
        width: 86%;
    }
    .RegFooter {
        width: 100%;
        min-width: 100%;
    }
    .RegisterPageAll .LoginRegisterPage .EnterEventAction {
        padding-bottom: 0px !important;
    }
    .LoginRegisterPage {
        padding-bottom: 0px !important;
    }
    .RegFooter {
        padding: 12px 0 12px;
        margin-top: 22px;
    }
    .TimeZoneFile .EditInfoDetails {
        padding: 0 20px;
    }
    .DeviceCompatibility {
        .modal-dialog {
            .modal-content {
                .DarkBlurBackground {
                    .CheckDevice {
                        .DeviceCompTable {
                            .modal-body {
                                .SysInfoCheck {
                                    .FormGroupCheck {
                                        .SysInfosDevice {
                                            font-size: 14px;
                                        }
                                    }
                                    .BrowseCompatibility {
                                        padding-bottom: 60px !important;
                                        .SysInfosDevice {
                                            font-size: 14px;
                                            p {
                                                font-size: 14px;
                                                small {
                                                    font-size: 11px;
                                                }
                                            }
                                        }
                                    }
                                    .recomdBtn {
                                        bottom: 16px !important;
                                        width: 90%;
                                        left: 0;
                                        margin: 0px auto;
                                        right: 0 !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .languageChng .modal-dialog {
        height: 100% !important;
        margin: 10px auto 10px !important;
        display: flex !important;
    }
    .AttendeeFrame {
        .fullScreen {
            //position: relative !important;
            overflow: hidden;
            width: 100%;
            aspect-ratio: 16/9;
            iframe {
            width: 100% !important;
            height: 100% !important;
            margin: 0px !important;
            }
            .ControlFrame {
            background-color: #000000 !important;
            padding: 6px 5px!important;
            }
        }
    }
    .ReadMorePopUp .modal-dialog .modal-content .modal-body .ReadMoreDeso .SectionWatch .BackAgain {
        margin-top: 20px;
    }
    .container-error {
        .ErrorInbox {
            width: 90%;
        }
    }
    .MobBackstageLive {
        overflow: hidden !important;
        overflow-y: hidden !important;
    }
}
@media (min-width: 480px) and (max-width: 575px) {
	.maxScreenHeight {
        margin-top: 45px;
        height: calc(100vh - 45px);
    }
    .MixhubbHeader {
        padding: 4px 0;
        height: 45px;
        .HeaderMainTop {
            .MixhubbLogo {
                max-width: 170px;
                flex: 0 0 170px;
                float: left;
            }
            .MixhubbMenu {
                ul {
                    li {
                        padding: 16px 30px;
                        .nav-mixhubb  {
                            width: 100%;
                            display: block;
                        }
                    }
                }
            }
            
        }
    }

    .SchedulePageEvent {
        .SetEventSchedule {
            margin-top: 14px !important;
            .ScheduleDayStage {
                padding: 0 0 !important;
                margin: 0px !important;
                
                .SelectDayStage {
                    .SelectDays {
                        .SelectDropdown {
                            .dropdown {
                                font-size: 14px;
                                height: 36px;
                            }
                        }
                    }
                    .DayScheduleStages {
                        width: 48% !important;
                        .SelectDays {
                            margin-right: 10px;
                        }
                        .SelectDropdown {
                            .SeleftDDl {
                                .dropdown-toggle {
                                    height: 42px;
                                }
                            }
                            &:after {
                                width: 11px;
                                height: 11px;
                                right: 17px;
                                top: 11px;
                            }
                        }
                    }
                }
            }
            .RoomSeatSelect {
                display: block;
                border-radius: 10px;
                .RoomMeetSrch {
                    margin: 10px 0 0;
                    display: flex;
                    justify-content: space-between;
                    .RoomsSerchHere {
                        width: 75%;
                    }
                    .MyMeetingHere {
                        margin-right: 10px;
                        .MymeetingBtn {
                            padding: 10px 16px;
                            width: 135px;
                        }
                    }
                }
            }
        }
    }



    .AllPlanSchedule {
        padding: 10px 15px 0 0px;
    
        .ScheduleCalendarInfos {
            .StageFullInfoFix {
                margin-bottom: 18px;
                display: block;
                border-radius: 12px;
                .CalenderDateScheduleInof {
                    display: flex;
                    cursor: cell;
                    justify-content: space-between;
                    padding: 12px 28px 18px;
                    border-radius: 12px 12px 0 0;
                    .StageNo {
                        padding: 2px 16px;
                        top: auto;
                        left: 0;
                        right: 0;
                        width: 140px;
                        margin: 0px auto;
                        bottom: -10px;
                    }
                    .DtStageEventDay {
                        h4 {
                            line-height: 26px;
                            font-size: 18px;
                        }
                    }
                    .ScheduleTMFx {
                        margin: 5px 0 0;
                        p {
                            font-size: 16px;
                            font-weight: 600;
                        }
                    }
                    .ScheduleStatusOngoing {
                        margin: 10px 0 0;
                        label {
                            font-weight: 600;
                            padding: 4px 14px;
                            font-size: 14px;
                        }
                    }
                }
            }
            .ScheduleInfoWithConts {
                h3 {
                    margin: 0 0 5px;
                    font-size: 16px;
                }
                // p {
                //     font-size: 12px;
                // }
            }
            .SpeakerRowList {
                .EventSpeakerExp {
                    padding: 12px 10px;
                    //margin-right: 10px;
                    .SpeakerNamePos {
                        margin-left: 12px;
                    }
                    .SpeakerNamePos {
                        h4 {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
    .MixhubbStage {
        overflow-y: inherit;
        .StageMixhubbEvent {
            // padding: 35px 0 20px;
            .SetEventInfo {
                padding: 28px 10px 20px;
                .SetupStatus{
                    span {
                        padding: 2px 28px;
                    }
                }
                h3 {
                    font-size: 18px;
                    line-height: 24px;
                }
                .AboutSetupInfo {
                    background-color: var(--light-gray);
                    padding: 10px 10px;
                    margin: 15px 0 22px;
                    h4 {
                        font-size: 15px;
                    }
                    p {
                        font-size: 14px;
                    }
                }
    
                .ExpoSetupInfo {
                    min-height: 88px;
                }
                .EnterStageBtn {
                    font-size: 15px;
                }
            }
        }
    }






    .NetwokingProfileQuick {
        margin-top: 20px;
        padding: 10px 12px;
        .ProfilePicInfo {
            width: 48px;
            height: 48px;
            margin-right: 6px;
        }
        .ProfileTxtInfo {
            width: 75%;
            position: relative;
            h4 {
                font-size: 14px;
                font-weight: 500;
                margin: 0 0 2px;
            }
            p {
                font-size: 12px;
            }
        }
        .ReadProfileIcon {
            width: 38px;
            height: 38px;
            padding: 11px !important;
        }
    }
    
    .RecommendProfiles {
        p {
           margin: 18px 0 0;
           font-size: 16px;
           line-height: 22px;
       }
    }


    .QuickShufflingMatch {
        background-color: var(--light-gray);
        padding: 22px 25px;
        border-radius: 16px;
        margin-top: 30px;
        h2 {
            font-size: 20px;
        }
        p {
            font-size: 14px;
            margin: 0 0 4px;
            font-weight: 400;
        }
        .MatchQuickGIF {
            padding: 22px 28px;
        }
        .RedButton {
            width: 160px;
            margin: 0px auto;
            display: table;
            font-size: 15px;
            font-weight: 700;
            svg {
                width: 20px;
                height: 20px;
            }
        }
    }
   

    .NetworkIngAllAttendess {
        .InterestTag {
            margin-left: 0;
            margin-top: 18px;
            span {
                margin-left: 0px;
                margin-right: 6px;
            }
            p {
                margin-bottom: 0;
            }
        }
        .FilderToggleBox {
            .FilterToggleInfo {
                width: 355px;
                .InputHere {
                    .form-control {
                        width: 250px;
                        height: 40px;
                    }
                }
            }
        }
        .AttendeesHeader {
            display: block;
            .LeftSideAttendess {
                width: 315px;
                h4 {
                    font-size: 20px;
                }
                .RedButton {
                    width: 110px;
                    font-size: 16px;
                }
            }
        }
    }
    
    .NetworkIngAllAttendess {
    	.RIghtAttendesSearch {
            margin: 20px 0 0;
    		.NetworkPeople {
		        width: 100%;
		    }
    	}
    }
    .NetworkIngAllAttendess {
        .RIghtAttendesSearch {
            .NetworkPeople {
                .PepleSerchHere {
                    .SerchHere {
                        height: 40px;
                    }
                }
            }
        }
    }
    .RecommendProfiles {
        padding: 8px 0 0;
        h4 {
            margin: 0 0 0px;
            font-size: 20px;
        }
    }
    



  
    .MixhubbStage {
        .backstage-dashboard {
            .MainViewBackStage {
                width: 100% !important;
                height: calc(100vh - 286px);
                margin: 0 0 11px;
            }
        }
    }






    .RegisterPageAll {
        .LoginRegisterPage {
            .MixhubbExpDae {
                h1 {
                    font-size: 32px;
                    margin: 0 0 12px;
                }
            }
        }
    }
    .RegisterPageAll {
        .LoginRegisterPage {
            .MixhubbExpDae {
                p {
                    font-size: 22px;
                }
                .event-timezone {
                    font-size: 16px;
                    margin: 4px 0 0;
                }
            }
        }
    }
    .RegisterPageAll {
        .LoginRegisterPage {
            .EnterEventAction {
                h2 {
                    font-size: 28px;
                    margin: 0 0 25px;
                }
            }
        }
    }
    .RegisterPageAll {
        .LoginRegisterPage {
            .EnterEventAction {
                margin: 30px auto 0;
                width: 75%;
            }
        }
    }
    .RegisterPageAll {
        .LoginRegisterPage {
            .EnterEventAction {
                .SocialLoginType {
                    ul {
                        li {
                            display: inline-block;
                            margin: 0 12px;
                            .img-fluid {
                                cursor: pointer;
                                width: 50px;
                                height: 50px;
                            }
                            svg {
                                width: 50px;
                                height: 50px;
                              }
                        }
                    }
                }
            }
        }
    }
    .EventTypeActonIn {
        .form-group {
            .form-control {
                padding: 18px 28px;
            }
        }
    }
    .EventTypeActonIn {
        .form-group {
            .EnterBtn {
                border-radius: 100px;
                padding: 14px 28px;
            }
        }
    }
    .RegisterPageAll {
        .LeftImgBlock {
            width: 53%;
        }
    }

    .RegisterPageAll {
        display: block;
        height: auto;
        .LeftImgBlock {
            display: block;
            height: auto;
            width: 100%;
            .LoginLeftHg {
                height: 50vh;
            }
        }
        .LoginRegisterPage {
            width: 100%;
            min-width: 100%;
            position: relative !important;
            overflow: initial;
        }
    }
    .maxScreenHeight {
        .scale-blur-fix {
            height: calc(100vh - 45px);
            margin-top: 45px;
        }
    }
    .MixhubbHeader .HeaderMainTop .MixhubbLogo img {
        height: 30px;
    }
    .FullYourProfile {
        .nav-tabs {
            display: flex;
            align-items: center;
            justify-content: space-around;
            li {
                margin: 0 0;
                .nav {
                    .nav-link {
                        padding: 8px 18px !important;
                        font-size: 14px !important;
                        &.active {
                            padding: 8px 18px !important;
                        }
                    }
                }
            }
        }
    }
    .FeedBackPopUp {
        .modal-dialog {
            display: block;
            .modal-content {
                .modal-body {
                    .FdInnrForm {
                        height: auto;
                        .LeftFBDetail {
                            max-width: 100%;
                            text-align: center;
                            h1 {
                                font-size: 28px;
                                line-height: 42px;
                                margin: 0 0 30px;
                            }
                            .FBIcoInfo {
                                width: 70%;
                            }
                        }
                        .RightFbDetail {
                            width: 100%;
                            padding: 25px !important;
                            .FbFormMixInfos {
                                .form-group {
                                    .FeedForm {
                                        width: 100%;
                                        .MuiFormControl-root {
                                            width: 100%;
                                        }
                                        .area-check {
                                            margin-right: 15px;
                                            input {
                                                width: 16px;
                                                height: 16px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .FeedFormSubmit {
                            padding: 24px 0 0;
                            .MuiButtonBase-root {
                                position: initial;
                                float: right;
                            }
                        }
                    }
                }
            }
        }
    }
    .MixhubbLobby {
        .LobbySelImag {
            .BgLobbyFix {
                .LobbyBgWidgetHg {
                    .imageFeatureWidget {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }

    .MixhubbStage .backstage-dashboard .BackstageInfoStream {
        width: 100%;
        right: 0px;
        bottom: 4px;
    }
    .screen-stage-main-div {
        .MainViewBackStage {
            .alignAllDiv  {
                width: 100% !important;
                .front-stage-stream-scroll {
                    .row {
                        .stage-user-list {
                            .RightSvgIcon {
                                .mh-cursor-pointer {
                                    top: 3px;
                                    left: 3px;
                                    width: 18px;
                                    height: 18px;
                                    padding: 4px !important;
                                }
                            }
                            .list-1, .list-2, .list-3, .list-4, .list-5, .list-6, .list-7, .list-8, .list-9, .list-10, .list-11, .list-12, .list-13, .list-14, .list-15, .list-16 {
                                height: auto;
                                // display: inline-flex;
                                // width: 120px
                            }
                        }
                    }
                }
            }
           
        }
    }

    .screen-stage-main-div.oraganizer-backstage-dashboard {
        .MainViewBackStage  {
            .alignAllDiv {
                .front-stage-stream-scroll {
                    width: 97% !important;
                    .row {
                        flex-flow: column;
                        .front-stage-share-screen {
                            width: 100% !important;
                            height: auto !important;
                        }
                        .stage-user-list {
                            width: 100% !important;
                            overflow: auto;
                            overflow-y: hidden;
                            display: inline-flex;
                            flex-flow: row nowrap;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 6px 4px;
                            position: relative !important;
                            height: auto !important;
                            margin-left: 6px;
                            .video_footer {
                                .ShortName {
                                    h4 {
                                        width: 45px;
                                        height: 45px;
                                        font-size: 22px;
                                    }
                                }
                                .bottom_footer {
                                    h4 {
                                        font-size: 10px !important;
                                        span {
                                            line-height: 14px !important;
                                        }
                                    }
                                }
                                .BackIconAction {
                                    top: 5px;
                                    right: 3px;
                                    .ActionLive {
                                        width: 18px;
                                        height: 18px;
                                        .widthSvg {
                                            padding: 3px 3px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .screen-stage-main-div.oraganizer-backstage-dashboard.collapse-out-frontstage {
        .MainViewBackStage {
            .alignAllDiv {
                width: 100% !important;
                .front-stage-stream-scroll {
                    width: 97% !important;
                    .row {
                        .stage-user-list {
                            height: auto !important;
                        }
                    }
                }
            }
        }
    }

    .screen-stage-main-div.backstage-dashboard {
        .MainViewBackStage  {
            .alignAllDiv {
                width: 100% !important;
                .front-stage-stream-scroll {
                    width: 97% !important;
                    .row {
                        flex-flow: column;
                        .front-stage-share-screen {
                            width: 100% !important;
                            height: auto !important;
                        }
                        .stage-user-list {
                            width: 100% !important;
                            overflow: auto;
                            overflow-y: hidden;
                            display: inline-flex;
                            flex-flow: row nowrap;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 6px 4px;
                            position: relative !important;
                            height: auto !important;
                            margin-left: 6px;
                            .video_footer {
                                .ShortName {
                                    h4 {
                                        width: 45px;
                                        height: 45px;
                                        font-size: 22px;
                                    }
                                }
                                .bottom_footer {
                                    h4 {
                                        font-size: 10px !important;
                                        span {
                                            line-height: 14px !important;
                                        }
                                    }
                                }
                                .BackIconAction {
                                    top: 5px;
                                    right: 3px;
                                    .ActionLive {
                                        width: 18px;
                                        height: 18px;
                                        .widthSvg {
                                            padding: 3px 3px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .screen-stage-main-div.backstage-dashboard.collapse-out-frontstage {
        .MainViewBackStage {
            .alignAllDiv {
                width: 100% !important;
                .front-stage-stream-scroll {
                    width: 97% !important;
                    .row {
                        .stage-user-list {
                            height: auto !important;
                        }
                    }
                }
            }
        }
    }

    .screen-stage-main-div.organizer-frontend-dashboard {
        .MainViewBackStage  {
            .alignAllDiv {
                .front-stage-stream-scroll {
                    width: 97% !important;
                    .row {
                        flex-flow: column;
                        .front-stage-share-screen {
                            width: 100% !important;
                            height: auto !important;
                        }
                        .stage-user-list {
                            width: 100% !important;
                            overflow: auto;
                            overflow-y: hidden;
                            display: inline-flex;
                            flex-flow: row nowrap;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 6px 4px;
                            position: relative !important;
                            height: auto !important;
                            margin-left: 6px;
                            .video_footer {
                                .ShortName {
                                    h4 {
                                        width: 45px;
                                        height: 45px;
                                        font-size: 22px;
                                    }
                                }
                                .bottom_footer {
                                    h4 {
                                        font-size: 10px !important;
                                        span {
                                            line-height: 14px !important;
                                        }
                                    }
                                }
                                .BackIconAction {
                                    top: 5px;
                                    right: 3px;
                                    .ActionLive {
                                        width: 18px;
                                        height: 18px;
                                        .widthSvg {
                                            padding: 3px 3px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .screen-stage-main-div.frontend-dashboard {
        .MainViewBackStage  {
            .alignAllDiv {
                .front-stage-stream-scroll {
                    width: 97% !important;
                    .row {
                        flex-flow: column;
                        .front-stage-share-screen {
                            width: 100% !important;
                            height: auto !important;
                        }
                        .stage-user-list {
                            width: 100% !important;
                            overflow: auto;
                            overflow-y: hidden;
                            display: inline-flex;
                            flex-flow: row nowrap;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 6px 4px;
                            position: relative !important;
                            height: auto !important;
                            margin-left: 6px;
                            .video_footer {
                                .ShortName {
                                    h4 {
                                        width: 45px;
                                        height: 45px;
                                        font-size: 22px;
                                    }
                                }
                                .bottom_footer {
                                    h4 {
                                        font-size: 10px !important;
                                        span {
                                            line-height: 14px !important;
                                        }
                                    }
                                }
                                .BackIconAction {
                                    top: 5px;
                                    right: 3px;
                                    .ActionLive {
                                        width: 18px;
                                        height: 18px;
                                        .widthSvg {
                                            padding: 3px 3px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .audience-front-stage-roll.screen-stage-main-div {
        .MainViewBackStage  {
            .alignAllDiv {
                .front-stage-stream-scroll {
                    width: 97% !important;
                    .row {
                        //flex-flow: column;
                        // .front-stage-share-screen {
                        //     width: 100% !important;
                        //     height: auto !important;
                        // }
                        .stage-user-list {
                            // width: 100% !important;
                            // overflow: auto;
                            // overflow-y: hidden;
                            // display: inline-flex;
                            // flex-flow: row nowrap;
                            // align-items: center;
                            // justify-content: flex-start;
                            // padding: 6px 4px;
                            // position: relative !important;
                            // height: auto !important;
                            // margin-left: 6px;
                            .video_footer {
                                .ShortName {
                                    h4 {
                                        width: 45px;
                                        height: 45px;
                                        font-size: 22px;
                                    }
                                }
                                .bottom_footer {
                                    h4 {
                                        font-size: 10px !important;
                                        span {
                                            line-height: 14px !important;
                                        }
                                    }
                                }
                                .BackIconAction {
                                    top: 5px;
                                    right: 3px;
                                    .ActionLive {
                                        width: 18px;
                                        height: 18px;
                                        .widthSvg {
                                            padding: 3px 3px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .MainViewBackStage {
        .alignAllDiv {
            .front-stage-stream-scroll {
                .localPlayer {
                    div {
                        border-radius: 5px;
                        video {
                            border-radius: 5px;
                        }
                    }
                }
            }
        }
    }
    .oraganizer-backstage-dashboard {
        .MainViewBackStage {
            height: calc(100vh - 250px) !important;
            .RemoveAllDiv {
                width: 100% !important;
                height: auto !important;
                .front-stage-stream-scroll {
                    width: 97% !important;
                }
            }
        }
    }
    .oraganizer-backstage-dashboard.collapse-out-frontstage {
        .MainViewBackStage {
            height: calc(100vh - 110px) !important;
            .RemoveAllDiv {
                width: 100% !important;
                height: auto !important;
                .front-stage-stream-scroll {
                    width: 97% !important;
                }
            }
        }
    }

    .audience-front-stage-roll {
        .MainViewBackStage {
            .RemoveAllDiv {
                width: 100% !important;
                height: auto !important;
                .front-stage-stream-scroll {
                    width: 97% !important;
                }
            }
        }
    }
    .backstage-dashboard {
        .MainViewBackStage {
            height: calc(100vh - 255px) !important;
            margin: 6px 0 !important;
            .RemoveAllDiv {
                width: 100% !important;
                height: auto !important;
                .front-stage-stream-scroll {
                    width: 97% !important;
                }
            }
        }
    }
    .backstage-dashboard.collapse-out-frontstage {
        .MainViewBackStage {
            height: calc(100vh - 112px) !important;
            margin: 6px 0 !important;
            .RemoveAllDiv {
                width: 100% !important;
                height: auto !important;
                .front-stage-stream-scroll {
                    width: 97% !important;
                }
            }
        }
    }
    .backstage-dashboard {
        .MainViewBackStage {
            height: calc(100vh - 255px) !important;
            margin: 6px 0 !important;
            .RemoveAllDiv {
                width: 100% !important;
                height: auto !important;
                .front-stage-stream-scroll {
                    width: 97% !important;
                }
            }
        }
    }
    .frontend-dashboard {
        .MainViewBackStage {
            height: calc(100vh - 52px) !important;
            margin: 6px 0 !important;
            .RemoveAllDiv {
                width: 100% !important;
                height: auto !important;
                .front-stage-stream-scroll {
                    width: 97% !important;
                }
            }
        }
    }
    .BackstageInfoStream .HeadingBstage .collapsDivToggle .CastingToggle {
        padding: 4px;
    }
    .collapsDivToggle {
        top: 15px !important;
    }
    .RegisterPageAll .LeftImgBlock .LoginEventLogo {
        top: 8px;
        left: 8px;
        width: 140px;
    }
    .scheduleTages {
        padding: 0 20px;
    }
    .SchedulePageEvent .SetEventSchedule .ScheduleDayStage .SelectEnterZone {
        width: 80%;
    }
    .AllPlanSchedule {
        padding: 0px 0px 0px 0 !important;
        height: calc(100vh - 204px) !important;
        transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    }
    .scheduleTages {
        .TagSelect {
            width: 122px;
            margin-right: 5px;
            margin: 0 0 8px;
            p {
                font-size: 14px;
            }
        }
    }
    .TagLengthInfo {
        height: calc(100vh - 214px);
    }
    .TagLengthInfo.ZoneListExpoList {
        height: calc(100vh - 212px);
      }
    .scheduleTages {
        margin: 12px 0px 0px;
    }
    .MobileAppFilter {
        margin: 16px 0 0px;
    }
    .TagScheduleHght {
        height: calc(100vh - 126px) !important;
        margin-top: 5px;
    }
    .RoomScheduleTable {
        height: calc(100vh - 145px);
        overflow-y: auto;
        padding: 5px 0px 0 0;
    }
    .TagRoomsInfo {
        height: calc(100vh - 210px);
        overflow-y: auto;
        padding: 10px 0px 0 0;
    }
    .PeopleChatPopup {
        height: calc(100vh - 52px);
    }
    .ZoneEnterDiscInfo .LargeBoothHeight .EnterZoneBooth {
        margin: 8px 0 8px !important;
    }
    .SchedulePageEvent .ExpoScheduleInfos .ScheduleDayStage .SelectEnterZone .PepleSerchHere .SerchHere {
        height: 44px !important;
        padding: 0 12px 0 20px !important;
    }
    .SchedulePageEvent .ExpoScheduleInfos .ScheduleDayStage .SelectEnterZone {
        width: 85% !important;
    }
    .SideRightMenu {
        margin-right: 6px;
        ul {
            li {
                padding: 6px 3px !important;
                margin: 4px 0 !important;
                width: 40px;
                button {
                    svg {
                        width: 16px;
                        height: 16px;
                    }
                    span {
                        font-size: 8px;
                        padding: 0px !important;
                    }
                }
                .RightTopStyle {
                    width: auto;
                    ul {
                        li {
                            padding: 0px 0px !important;
                            margin: 0px 0 !important;
                            button {
                                svg {
                                    width: 20px;
                                    height: 20px;
                                }
                                span {
                                    font-size: 9px;
                                    padding: 0px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    
    .audience-front-stage-roll.collapse-out-frontstage {
        .container-fluid {
            .SideRightMenu {
                ul {
                    li {
                        padding: 6px 2px 6px !important;
                        margin: 4px 0 !important;
                        span {
                            font-size: 9px;
                            padding: 0!important;
                            line-height: 12px;
                        }
                        .RightTopStyle {
                            ul {
                                li {
                                    padding: 0px 2px 0px !important;
                                    margin: 0px !important;
                                    .ChatClick {
                                        span {
                                            font-size: 9px;
                                            padding: 0!important;
                                            line-height: 12px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                
            }
        }
    }
    
    .audience-front-stage-roll {
        .container-fluid {
            .SideRightMenu {
                ul {
                    li {
                        padding: 6px 2px 6px !important;
                        margin: 4px 0 !important;
                        span {
                            font-size: 8px;
                            padding: 0!important;
                            line-height: 12px;
                        }
                        .RightTopStyle {
                            ul {
                                li {
                                    padding: 0px 2px 0px !important;
                                    margin: 0px !important;
                                    .ChatClick {
                                        span {
                                            font-size: 8px;
                                            padding: 0!important;
                                            line-height: 12px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                
            }
        }
    }
    .DeviceCompatibility .modal-dialog .modal-content .DarkBlurBackground .CheckDevice .DeviceCompTable .modal-body .HeaderCheckSystem h4 {
        font-size: 18px;
    }
}
@media (max-width: 479px) {
    .MixhubbHeader .HeaderMainTop .MixhubbRight .headIcon .chatConvIcon .NoticHere {
        border: 1px #fff solid;
        font-weight: 600;
    }
    .audience-front-stage-roll {
        position: fixed;
        //height: calc(100vh - 45px) !important;
        overflow: hidden;
        margin-top: 48px;
    }
    .audience-front-stage-roll.collapse-out-frontstage {
        aspect-ratio: 16/9;
        align-items: center;
        justify-content: center;
        display: flex;
    }
    .AllPlanSchedule::-webkit-scrollbar {
        width: 4px !important;
    }
	.maxScreenHeight {
        margin-top: 45px;
        height: calc(100vh - 45px);
        //overflow-y: auto;
    }
    .MixhubbHeader .HeaderMainTop .MixhubbMenu::after {
        top: 60px !important;
    }
	.MixhubbHeader {
        padding: 6px 0;
        height: 45px;
        .HeaderMainTop {
            .MixhubbLogo {
                max-width: 135px;
                flex: 0 0 135px;
                float: left;
                height: 35px;
                padding: 0 0;
            }
            .MixhubbMenu {
                width: 100%;
                ul {
                    li {
                        padding: 12px 20px;
                        font-size: 14px !important;
                        .nav-mixhubb  {
                            width: 100%;
                            display: block;
                        }
                    }
                }
                .mobMenuExt {
                    li {
                        padding: 17px 20px;
                        font-size: 14px !important
                    }
                }
            }
            .MixhubbRight {
                .ConvertionChat {
                    top: -3px;
                    .chatConvIcon {
                        width: 18px !important;
                        height: 18px !important;
                        cursor: pointer;
                    }
                }
                .mixhubbNotification {
                    .IconStyle {
                        width: 26px !important;
                        height: 26px !important;

                    }
                }
                .dropdown {
                    .dropdown-toggle {
                        width: 25px;
                        height: 25px;
                    }
                }
            }
        }
    }


    .SchedulePageEvent {
        .SetEventSchedule {
            margin-top: 14px;
            .ScheduleDayStage {
                margin: 0px !important;
                padding: 0px 0px !important;
                display: block;
                h4 {
                    font-size: 18px;
                }
                .SelectDayStage {
                    width: 100%;
                    margin: 0px 0 0;
                    .SelectDays {
                        .SelectDropdown {
                           
                            .dropdown {
                                font-size: 14px;
                                height: 36px;
                            }
                        }
                    }
                    .DayScheduleStages {
                        width: 48% !important;
                        .SelectDays {
                            margin-right: 10px;
                        }
                        .SelectDropdown {
                            .SeleftDDl {
                                .dropdown {
                                    .dropdown-toggle {
                                        height: 36px;
                                        font-size: 14px;
                                        padding: 5px 34px 5px 16px !important;
                                        &:before {
                                            top: 10px !important;
                                        }
                                    }
                                }
                                .show {
                                    .dropdown-toggle { 
                                        &:before {
                                            top: 16px !important;
                                        }
                                    }
                                }
                            }
                            &:after {
                                width: 11px;
                                height: 11px;
                                right: 17px;
                                top: 11px;
                            }
                        }
                    }
                }
            }
            .RoomSeatSelect {
                display: block;
                border-radius: 10px;
                .RoomMeetSrch {
                    margin: 10px 0 0;
                    display: flex;
                    justify-content: space-between;
                    .RoomsSerchHere {
                        width: 100%;
                    }
                    .MyMeetingHere {
                        margin-right: 0px;
                        .MymeetingBtn {
                            padding: 7px 16px;
                            width: 130px;
                        }
                    }
                }
            }
        }
    }

    .AllPlanSchedule {
        //padding: 5px 0px 0px 0;
        overflow-x: hidden;
        .ScheduleCalendarInfos {
            .StageFullInfoFix {
                margin-bottom: 16px;
                border-radius: 12px;
                display: block;
                .CalenderDateScheduleInof {
                    display: flex;
                    cursor: cell;
                    justify-content: space-between;
                    padding: 12px 15px 18px;
                    border-radius: 12px 12px 0 0;
                    .StageNo {
                        padding: 2px 8px;
                        top: auto;
                        left: 0;
                        right: 0;
                        width: 130px;
                        margin: 0px auto;
                        bottom: -10px;
                        font-size: 12px;
                    }
                    .DtStageEventDay {
                        h4 {
                            line-height: 22px;
                            font-size: 16px;
                        }
                    }
                    .ScheduleTMFx {
                        margin: 5px 0 0;
                        p {
                            font-size: 16px;
                            font-weight: 600;
                        }
                    }
                    .ScheduleStatusOngoing {
                        margin: 10px 0 0;
                        label {
                            font-weight: 500;
                            padding: 4px 12px;
                            font-size: 12px;
                        }
                    }
                }
            }
            .ScheduleInfoWithConts {
                h3 {
                    margin: 0 0 5px;
                    font-size: 15px;
                }
                p {
                    font-size: 14px;
                }
                .BackAgain {
                    a {
                        img {
                            width: 15px !important;
                        }
                    }
                }
            }
            .SpeakerRowList {
                .EventSpeakerExp {
                    padding: 12px 10px;
                    //margin-right: 10px;
                    .SpeakerNamePos {
                        margin-left: 12px;
                    }
                }
            }
        }
    }
    .MixhubbStage {
        //overflow-y: inherit;
        .StageMixhubbEvent {
            // padding: 35px 0 20px;
            .SetEventInfo {
                padding: 28px 10px 16px;
                border-radius: 15px;
                margin: 12px 0 18px;
                .SetupStatus{
                    left: 20px;
                    span {
                        padding: 0px 20px;
                        font-size: 14px;
                    }
                }
                h3 {
                    font-size: 18px;
                    line-height: 24px;
                }
                .AboutSetupInfo {
                    background-color: var(--light-gray);
                    padding: 10px 10px;
                    margin: 15px 0 16px;
                    h4 {
                        font-size: 15px;
                    }
                    p {
                        font-size: 13px;
                    }
                }
                .StageListSession {
                    display: block !important;
                    .StageSessionMix {
                        width: 100%;
                        height: 40px;
                        margin: 0 0 12px;
                        .session-list {
                            padding: 0 12px;
                            padding-right: 30px;
                            font-size: 14px;
                        }
                        &::after {
                            width: 10px;
                            height: 10px;
                            right: 18px;
                            top: 14px;
                        }
                    }
                }
                .ExpoSetupInfo {
                    min-height: auto;
                }
                .EnterStageBtn {
                    font-size: 14px;
                    padding: 8px 12px;
                    width: 100%;
                }
            }
        }
    }

    .NetwokingProfileQuick {
        margin-top: 20px;
        padding: 10px 12px;
        .ProfilePicInfo {
            width: 48px;
            height: 48px;
            margin-right: 6px;
        }
        .ProfileTxtInfo {
            width: 65%;
            position: relative;
            h4 {
                font-size: 14px;
                font-weight: 500;
                margin: 0 0 2px;
            }
            p {
                font-size: 12px;
            }
        }
        .ReadProfileIcon {
            width: 38px;
            height: 38px;
            padding: 11px !important;
        }
    }
    
    .RecommendProfiles {
        p {
           margin: 18px 0 0;
           font-size: 16px;
           line-height: 22px;
       }
    }


    .QuickShufflingMatch {
        background-color: var(--light-gray);
        padding: 22px 25px;
        border-radius: 16px;
        margin-top: 30px;
        h2 {
            font-size: 20px;
        }
        p {
            font-size: 14px;
            margin: 0 0 4px;
            font-weight: 400;
        }
        .MatchQuickGIF {
            padding: 22px 28px;
        }
        .RedButton {
            width: 148px;
            margin: 0px auto;
            display: table;
            font-size: 15px;
            font-weight: 700;
            padding: 8px 10px;
            svg {
                width: 20px;
                height: 20px;
            }
        }
    }
   

    .NetworkIngAllAttendess {
        .InterestTag {
            margin-left: 0;
            margin-top: 18px;
            span {
                margin: 0 8px 6px 0;
            }
            p {
                margin-bottom: 0;
            }
        }
        .FilderToggleBox {
            .FilterToggleInfo {
                display: block;
                width: 100%;
                h4 {
                    font-size: 15px;
                }
                .InputHere {
                    margin: 10px 0 0;
                    .form-control {
                        width: 100%;
                        height: 40px;
                        border-radius: 10px;
                    }
                }
            }
        }
        .AttendeesHeader {
            display: block;
            .LeftSideAttendess {
                width: 280px;
                h4 {
                    font-size: 20px;
                }
                .RedButton {
                    width: 100px;
                    font-size: 16px;
                    padding: 5px 16px;
                }
            }
        }
    }
    
    .NetworkIngAllAttendess {
    	.RIghtAttendesSearch {
            margin: 20px 0 0;
    		.NetworkPeople {
		        width: 100%;
		    }
    	}
    }
    .NetworkIngAllAttendess {
        .RIghtAttendesSearch {
            .NetworkPeople {
                .PepleSerchHere {
                    .SerchHere {
                        height: 40px;
                    }
                }
            }
        }
    }
    .RecommendProfiles {
        padding: 8px 0 0;
        h4 {
            margin: 0 0 0px;
            font-size: 20px;
        }
    }
    .MixhubbLobby {
        .LobbySelImag {
            .BgLobbyFix {
                .LobbyBgWidgetHg {
                    .imageFeatureWidget {
                        width: 100%;
                        height: 100%;
                        img {
                            width: 100%;
                            height: 100%;
                            aspect-ratio: 16/9;
                            border-radius: 4px;
                        }
                    }
                }
            }
        }
    }

    .BackStageSessionInfo {
        height: calc(100vh - 55px) !important;
    }

    .BackstageInfoStream {
        .HeadingBstage {
            padding: 8px 12px;
            .BsMidAction {
                .ActionLive {
                    margin: 0 4px;
                    width: 25px;
                    height: 25px;
                }
                .StartStop {
                    width: auto;
                    height: auto;
                    margin-right: 0;
                    position: relative;
                }
                .shareScreenMob {
                    display: none;
                }
            }
        }
    }
    .CameraPrevModal .modal-dialog .modal-content .DarkBlurBackground .JoinTableRoom .JoinPersonTable .modal-body .joinNowTableUs .JoinWithTableLt {
        height: 220px;
    }
    .CameraPrevModal .modal-dialog .modal-content .DarkBlurBackground .JoinTableRoom .JoinPersonTable .modal-body .joinNowTableUs {
        padding: 24px 5px 30px;
    }



    .RegisterPageAll {
        display: block;
        height: auto;
        .LeftImgBlock {
            display: block;
            height: auto;
            width: 100%;
            .LoginLeftHg {
                height: 50vh;
            }
        }
        .LoginRegisterPage {
            width: 100%;
            min-width: 100%;
            padding-bottom: 0px !important;
            position: relative !important;
            overflow: initial;
        }
    }
    .AttendeeInofMob {
        width: 100%;
        float: left;
        .col-md-12 {
            padding: 0;
        }
    }
    .Screenaudience-front-stage-rollTopAlign {
        top: 0;
        width: 99% !important;
    }
    .StageTabCollapes {
        position: fixed;
        width: 100%;
        bottom: 0px;
        z-index: 95;
        margin: 0px auto !important;
        left: 0;
        right: 0;
        .tab-content {
            .PeopleChatPopup {
                height: auto !important;
                position: inherit;
                width: 100%;
                box-shadow: none !important;
                padding: 0;
                .responsive-tabs-container {
                    .nav-tabs {
                        padding: 10px 20px 10px;
                        li {
                            margin: 0 0 0;
                            .nav {
                                .nav-link {
                                    //font-size: 14px;
                                    //font-weight: 400;
                                    .badge {
                                        top: -7px;
                                        width: 8px;
                                        height: 8px;
                                    }
                                }
                            }
                        }
                    }
                    .CloseButton {
                        display: none;
                    }
                }
                
                .StateEventDtl {
                    //height: calc((100vh - 118px) - (56.25vw + 45px + 62px)) !important;
                    height: calc(100vh - 440px) !important;
                }
                .StageBackStage {
                    .nav-tabs {
                        li {
                            .nav-link {
                                padding: 10px 0 !important;
                                &.active {
                                    padding: 10px 0 !important;
                                }
                            }
                        }
                    }
                }
            }
            .ReadMoreDeso {
                background-color: #f0f0f0;
                padding: 0px 2px;
                height: calc(100vh - (56.25vw + 45px + 200px)) !important;
                overflow-y: auto;
                margin: 10px 0;
                .AboutTabsFull {
                    margin: 0px 0;
                    overflow-y: auto;
                    height: auto;
                    padding: 0 12px;
                    .ScheduleDayEvent {
                        margin: 14px 0;
                        span {
                            background-color: #ffffff;
                            font-size: 14px;
                            padding: 6px 12px;
                            font-weight: 500;
                            color: #1F1B20;
                            border-radius: 4px;
                        }
                    }
                    h4 {
                        color: #1A78E5;
                        font-size: 18px;
                        margin: 0;
                    }
                    p {
                    font-size: 14px;
                    margin: 18px 0 0;
                    color: #1F1B20;
                    }
                    .SectionWatch {
                    width: 100%;
                    text-align: left;
                    margin: 18px 0 2px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .RedButton  {
                        padding: 6px 16px;
                        font-size: 15px;
                        width: 145px;
                    }
                    .PopUpImgSpeaker {
                        .SpkInfoImgs {
                            width: 50px;
                            height: 50px;
                            display: inline-block;
                            margin-right: 10px;
                            img {
                                width: 100%;
                                height: 100%;
                                border-radius: 100px;
                        }
                        }
                        .SpkInfoCount {
                            border: 2px #1A78E5 solid;
                            color: #1A78E5;
                            width: 46px;
                            height: 46px;
                            border-radius: 100px;
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }

    .PeopleChatPopup {
        width: 96%;
        left: 4px;
        top: 4px;
        margin: 0px auto;
    }
    
    .RoomMeetingBoard {
        .RoomCountNo {
            margin: 18px 0px 0px;
        }
    }
    .SchedulePageEvent {
        .SetEventSchedule {
            .ScheduleDayStage {
                .SelectEnterZone {
                    width: 100%;
                    .PepleSerchHere {
                        //margin-top: 20px;
                        .SerchHere {
                            height: 40px;
                            padding: 0 12px 0 35px;
                        }
                        .SerchIcon {
                            svg {
                                width: 18px;
                                height: 18px;
                            }
                        }
                    }
                }
            }
        }
    }
    .SchedulePageEvent .ExpoScheduleInfos .ScheduleDayStage .SelectEnterZone .PepleSerchHere .SerchHere {
        height: 44px !important;
        padding: 0 12px 0 20px !important;
    }
    .SchedulePageEvent .ExpoScheduleInfos .ScheduleDayStage .SelectEnterZone {
        width: 85% !important;
    }
    .StageBounded {
        //height: calc(100vh - 108px) !important;
        height: auto !important;
    }
    .PollInfos {
        .PollMhAuto {
            height: calc(100vh - (56.25vw + 45px + 194px)) !important;
        }
    }
    .QnaInfos {
        height: calc(100vh - (56.25vw + 45px + 186px)) !important;
        .QnaInfosPols {
            height: calc(100vh - (56.25vw + 45px + 284px)) !important;
        }
    }
    header {
        position: fixed !important;
    }
    .PeopleChatPopup {
        height: 100% !important;
        top: auto !important;
        bottom: 0px !important;
        position: fixed;
        .StateEventDtl {
            //height: calc(100vh - 242px);
            .ChatInfos {
                height: calc(100vh - (56.25vw + 312px)) !important;
                padding-bottom: 0px !important;
            }
        }
    }
    .EventChatAccords {
        .InfoPeopleChat {
            height: calc(100% - 46px) !important;
            .tab-content {
                height: 100%;
                .tab-pane {
                    height: 100%;
                }
            }
            .ChatInfos {
                height: calc(100% - 88px) !important;
                margin: 0px !important;
            }
        }
    }

    .SlideCommonPopup {
        height: calc(100% - 6px) !important;
        .StateEventDtl {
            //height: calc(100vh - 140px) !important;
            //height: 44vh !important;
            .EventInfoStage {
                height: calc(100vh - 445px) !important;
                //height: calc(100vh - (56.25vw + 45px + 180px)) !important;
                    position: relative;
                .ChatInfos {
                   height: calc(100vh - 522px) !important;;
                    //height: calc(100vh - (56.25vw + 45px + 272px)) !important;
                    .MessageTextBox {
                        bottom: 8px !important;
                    }
                }
            }
        }
    }
    .MixhubbLobby .LobbySelImag .BgLobbyFix .img-fluid {
        height: auto !important;
        width: 100% !important;
    }
    .DataNotFound {
        .DataIcon {
            font-size: 14px;
            width: 100% !important;
            height: 100% !important;
            div {
                cursor: auto !important;
                width: 100px !important;
                height: 100px !important;
            }
        }
    }

    .BackStageSessionInfo {
        width: 97.7%;
    }
    .mixhubbNotification .IconStyle .NoticHere {
        border: 1px #fff solid;
        min-width: 15px !important;
        height: 15px;
        font-size: 9px;
        top: -2px;
        font-weight: 600;
    }
    .MixhubbHeader .HeaderMainTop .MixhubbRight .ConvertionChat .chatConvIcon .NoticHere {
        border: 1px #fff solid;
        min-width: 15px !important;
        height: 15px;
        font-size: 9px;
        font-weight: 600;
    }
    .BackstateOverview {
        .AttenddeScript {
            padding:  0 10px;
            h1 {
                font-size: 24px;
                margin: 0 0 8px;
                line-height: 34px;
            }
            h5 {
                font-size: 15px;
            }
        }
    }
    .WelcomeStage {
        .modal-dialog {
            .modal-content {
                .modal-body {
                    padding-bottom: 0px !important;
                    .MeetingNewSetup {
                        .DayMeetingTimeSetup {
                            .btnFullBtn {
                                margin: 0 0 0 !important;
                            }
                        }
                    }
                }
            }
        }
    }
    .CameraPrevModal .modal-dialog .modal-content .DarkBlurBackground .JoinTableRoom .JoinPersonTable .modal-body .joinNowTableUsGranted {
        padding: 22px 15px;
    }
    .BackstageInfoStream .HeadingBstage .BsMidAction .ActionLive .StartEndRecordBtn {
        padding: 0px 3px;
    }
    // .MixhubbStage .backstage-dashboard .BackstageInfoStream {
    //     width: calc(100% - 8px);
    //     right: 4px;
    //     bottom: 4px;
    // }
    .screen-stage-main-div {
        .MainViewBackStage {
            .alignAllDiv  {
                width: 100% !important;
                .front-stage-stream-scroll {
                    .row {
                        .stage-user-list {
                            .RightSvgIcon {
                                .mh-cursor-pointer {
                                    top: 3px;
                                    left: 3px;
                                    width: 18px;
                                    height: 18px;
                                    padding: 4px !important;
                                }
                            }
                            .list-1, .list-2, .list-3, .list-4, .list-5, .list-6, .list-7, .list-8, .list-9, .list-10, .list-11, .list-12, .list-13, .list-14, .list-15, .list-16 {
                                height: auto;
                                // display: inline-flex;
                                // width: 120px
                            }
                        }
                    }
                }
            }
           
        }
    }

    .screen-stage-main-div.oraganizer-backstage-dashboard {
        .MainViewBackStage  {
            .alignAllDiv {
                .front-stage-stream-scroll {
                    width: 97% !important;
                    .row {
                        flex-flow: column;
                        .front-stage-share-screen {
                            width: 100% !important;
                            height: auto !important;
                        }
                        .stage-user-list {
                            width: 100% !important;
                            overflow: auto;
                            overflow-y: hidden;
                            display: inline-flex;
                            flex-flow: row nowrap;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 6px 4px;
                            position: relative !important;
                            height: auto !important;
                            margin-left: 6px;
                            .video_footer {
                                .ShortName {
                                    h4 {
                                        width: 45px;
                                        height: 45px;
                                        font-size: 22px;
                                    }
                                }
                                .bottom_footer {
                                    h4 {
                                        font-size: 10px !important;
                                        span {
                                            line-height: 14px !important;
                                        }
                                    }
                                }
                                .BackIconAction {
                                    top: 5px;
                                    right: 3px;
                                    .ActionLive {
                                        width: 18px;
                                        height: 18px;
                                        .widthSvg {
                                            padding: 3px 3px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    
    .audience-front-stage-roll .MainViewBackStage .alignAllDiv .front-stage-stream-scroll .row .video_footer {
        border-radius: 4px !important;
      }
    .MainViewBackStage {
        .alignAllDiv {
            .front-stage-stream-scroll {
                .localPlayer {
                    div {
                        border-radius: 5px;
                        video {
                            border-radius: 5px;
                        }
                    }
                }
            }
        }
    }
   

    .BackstageInfoStream .HeadingBstage .collapsDivToggle .CastingToggle {
        padding: 4px;
    }
    .collapsDivToggle {
        top: 12px !important;
    }
    .InfoInterestEdit .ProfileBasicDetails .form-group .EditInfoDetails .SelectImgs {
        height: auto;
        aspect-ratio: 16/9;
    }
    .ExpoCoversChats .PeopleChatPopup .StateEventDtl {
        height: calc(100vh - 178px) !important;
    }
    .ExpoCoversChats .PeopleChatPopup .StateEventDtl .ChatInfos {
        height: calc(100vh - 248px) !important;
    }

    .live-recording {
        top: auto;
        bottom: -50px;
        left: 74px;
        right: 0;
        margin: 0px auto;
    }
    .liveRecord {
        top: auto;
        bottom: -50px;
        left: 0;
        right: 114px;
        margin: 0px auto;
    }
    .UpdateOvelayImgVodeo .minHeightBox {
        height: 154px !important;
        min-height: 154px !important;
        max-width: 272px !important;
    }
    .FeedBackPopUp {
        //margin: 44px 0 0;
        overflow-y: scroll;
        .modal-dialog {
            display: block;
            
            .modal-content {
                .modal-header {
                    .ModalCloseBtn {
                        top: 3px !important;
                        .MuiButton-label {
                            height: 32px;
                            width: 32px;
                        }

                    }
                }
                .modal-body {
                    .FdInnrForm {
                        height: auto;
                        .LeftFBDetail {
                            max-width: 100%;
                            text-align: center;
                            padding: 33px 0px;
                            h1 {
                                font-size: 20px;
                                line-height: 40px;
                                font-weight: bold;
                                margin: 0 0 30px;
                                padding: 0 6px;
                            }
                            .FBIcoInfo {
                                width: 55%;
                            }
                        }
                        .RightFbDetail {
                            width: 100%;
                            padding: 25px !important;
                            .HeadingFb {
                                h4 {
                                    font-size: 18px;
                                    font-weight: 600;
                                }
                            }
                            .FbFormMixInfos {
                                height: 240px;
                                .form-group {
                                    .FeedForm {
                                        width: 100%;
                                        .MuiFormControl-root {
                                            width: 100%;
                                        }
                                        .area-check {
                                            margin-right: 15px;
                                            input {
                                                width: 16px;
                                                height: 16px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .FeedFormSubmit {
                            padding: 24px 0 0;
                            .MuiButtonBase-root {
                                position: initial;
                                float: right;
                            }
                        }
                    }
                }
            }
        }
    }
    .ZoneEnterDiscInfo {
        .MidBoothHeight {
          .maxHeightBooth {
            height: auto !important;
          }
        }
        .SmallBoothHeight {
          .maxHeightBooth {
            height:  auto !important;
          }
        }
      }
      .audience-front-stage-roll {
        .MainViewBackStage {
            .FlexResizeStep {
                .ChangeWithSize {
                    .fullFreeContainer {
                        .alignAllDiv {
                            .front-stage-stream-scroll {
                                .row {
                                    .PollQnaBase {
                                        .ScreenCast {
                                            .row {
                                                .QuestionVote {
                                                   // height: 65px;
                                                    .UpdateVoteStream {
                                                        .QnAuserImg {
                                                            width: 42px;
                                                            height: 42px;
                                                            left: 15px;
                                                            top: 10px;
                                                        }
                                                        .ShowAnAQue {
                                                           padding-left: 75px;
                                                           .NameQnAuser {
                                                             h4 {
                                                                font-size: 12px; 
                                                             }
                                                           }
                                                           .QnaVoteCount {
                                                            align-items: center;
                                                               svg {
                                                                width: 14px;
                                                                height: 14px;
                                                               }
                                                           }
                                                        }
                                                        .ShowQueHere {
                                                           padding-left: 75px;
                                                           p {
                                                             font-size: 12px;
                                                           }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            
        }
    }
    .audience-front-stage-roll {
        .MainViewBackStage {
            .FlexResizeStep {
                .ChangeWithSize {
                    .fullFreeContainer {
                        .alignAllDiv {
                            .front-stage-stream-scroll {
                                .row {
                                    .PollQnaBase {
                                        //bottom: auto !important;
                                        .ScreenCast {
                                            h4 {
                                                font-size: 12px;
                                                padding: 6px 8px;
                                            }
                                            .OptionPoll {
                                                .row {
                                                    flex-flow: wrap !important;
                                                    .PollActionTab {
                                                        padding: 4px 8px;
                                                        font-size: 12px;
                                                        margin: 0 0 8px;
                                                        overflow: hidden;
                                                        text-overflow: ellipsis;
                                                        display: -webkit-box;
                                                        -webkit-line-clamp: 1;
                                                        -webkit-box-orient: vertical;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            
        }
    }
    .MixhubbHeader {
        .HeaderMainTop {
            .MixhubbRight {
                .headIcon {
                    margin-right: 10px;
                    .chatConvIcon {
                        width: 28px !important;
                        height: 28px !important;
                    }
                }
                
            }
        }
    }
      .BackstageInfoStream {
        .streamingUsers {
            .StreamVideoFrame {
                .back-stage-stream-scroll {
                    .video_footer {
                        .ShortName {
                            height: 80%;
                            h4 {
                                font-size: 22px;
                                width: 50px;
                                height: 50px;
                            }
                          }
                      }
                  }
              }
          }
      }
      .audience-front-stage-roll .MainViewBackStage .DivChangeWidthView {
        width: 100%;
        height: auto;
      }
      .backstage-dashboard.collapse-out-frontstage {
        .container-fluid {
            .MainViewBackStage {
                .ExpendNext {
                    bottom: 5px;
                    right: 5px;
                    padding: 3px 26px 3px 10px;
                    span {
                        font-size: 11px;
                        &:before {
                            width: 8px;
                            height: 8px;
                            border-top: 1px solid #fff;
                            border-right: 1px solid #fff;
                            right: 10px;
                            top: 10px;
                        }
                    }
                }
            }
        }
      }
      .backstage-dashboard {
        .container-fluid {
            .MainViewBackStage {
                .ExpendNext {
                    bottom: 5px;
                    right: 5px;
                    padding: 3px 26px 3px 10px;
                    span {
                        font-size: 11px;
                        &:before {
                            width: 8px;
                            height: 8px;
                            border-top: 1px solid #fff;
                            border-right: 1px solid #fff;
                            right: 10px;
                            top: 10px;
                        }
                    }
                }
            }
        }
      }
      .screen-stage-main-div.backstage-dashboard {
        .container-fluid {
            .MainViewBackStage {
                .ExpendNext {
                    right: 8px;
                    padding: 3px 26px 3px 10px;
                    span {
                        font-size: 11px;
                        &:before {
                            width: 8px;
                            height: 8px;
                            border-top: 1px solid #fff;
                            border-right: 1px solid #fff;
                            right: 10px;
                            top: 10px;
                        }
                    }
                }
            }
        }
      }
      .screen-stage-main-div.backstage-dashboard.collapse-out-frontstage {
        .container-fluid {
            .MainViewBackStage {
                .ExpendNext {
                    bottom: 5px;
                    right: 5px;
                    padding: 3px 26px 3px 10px;
                    span {
                        font-size: 11px;
                        &:before {
                            width: 8px;
                            height: 8px;
                            border-top: 1px solid #fff;
                            border-right: 1px solid #fff;
                            right: 10px;
                            top: 10px;
                        }
                    }
                }
            }
        }
      }
      .backstage-dashboard.collapse-out-frontstage {
        .MainViewBackStage {
            .alignAllDiv {
                .front-stage-stream-scroll {
                    .row {
                        .PollQnaBase {
                            .ScreenCast {
                                h4 {
                                    font-size: 14px;
                                    padding: 8px 12px;
                                }
                                .OptionPoll {
                                    .row {
                                        flex-flow: wrap !important;
                                        .PollActionTab {
                                            padding: 4px 8px;
                                            font-size: 14px;
                                            margin: 0 0 8px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
      }
      
      .backstage-dashboard {
          .container-fluid {
            .MainViewBackStage {
                .alignAllDiv {
                    .front-stage-stream-scroll {
                        .row {
                            .PollQnaBase {
                                .ScreenCast {
                                    h4 {
                                        font-size: 14px;
                                        padding: 8px 12px;
                                    }
                                    .OptionPoll {
                                        .row {
                                            flex-flow: wrap !important;
                                            .PollActionTab {
                                                padding: 4px 8px;
                                                font-size: 14px;
                                                margin: 0 0 8px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
          }
        
      }

      .backstage-dashboard.collapse-out-frontstage {
        .MainViewBackStage {
            .alignAllDiv {
                .front-stage-stream-scroll {
                    .row {
                        .PollQnaBase {
                            .ScreenCast {
                                .row {
                                    .QuestionVote {
                                        //height: 78px;
                                        .UpdateVoteStream {
                                            .QnAuserImg {
                                                width: 50px;
                                                height: 50px;
                                            }
                                            .ShowAnAQue {
                                                padding-left: 80px;
                                                .NameQnAuser {
                                                    h4 {
                                                        font-size: 13px;
                                                    }
                                                }
                                            }
                                            .ShowQueHere {
                                                padding-left: 80px;
                                                p {
                                                    font-size: 13px;
                                                }

                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
      }
      .backstage-dashboard {
        .MainViewBackStage {
            .alignAllDiv {
                .front-stage-stream-scroll {
                    .row {
                        .PollQnaBase {
                            .ScreenCast {
                                .row {
                                    .QuestionVote {
                                        //height: 78px;
                                        .UpdateVoteStream {
                                            .QnAuserImg {
                                                width: 50px;
                                                height: 50px;
                                            }
                                            .ShowAnAQue {
                                                padding-left: 80px;
                                                .NameQnAuser {
                                                    h4 {
                                                        font-size: 13px;
                                                    }
                                                }
                                            }
                                            .ShowQueHere {
                                                padding-left: 80px;
                                                p {
                                                    font-size: 13px;
                                                }

                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
      }
      .CameraPrevModal {
        .modal-dialog {
            .modal-content {
                .DarkBlurBackground {
                    .JoinTableRoom {
                        .JoinPersonTable {
                            .modal-body {
                                .joinNowTableUsLoader {
                                    padding: 20px 20px;
                                    height: 270px;
                                    .loader {
                                        width: 100% !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
      }
      .backstage-dashboard.collapse-out-frontstage .MainViewBackStage .alignAllDiv .front-stage-stream-scroll .row .PollQnaBase {
          //bottom: auto !important;
      }
      .JoinTableRoom {
        .joinTableHeader {
            border-radius: 8px 8px 0 0;
        }
        .JoinPersonTable {
            border-radius: 8px;
        }
      }
      .backstage-dashboard .container-fluid .MainViewBackStage .alignAllDiv .front-stage-stream-scroll .row .PollQnaBase {
          //bottom: auto !important;
      }
      .BackstageInfoStream {
        .streamingUsers {
            .StreamVideoFrame {
                .back-stage-stream-scroll {
                    .video_footer {
                        .bottom_footer {
                            padding: 4px 8px;
                            .NameExpFull {
                                h4 {
                                    font-size: 13px;
                                }
                            }
                        }
                    }
                }
            }
        }
      }
      .beginTimer-div {
        position: fixed;
        top: 45px;
        font-size: 16px;
    }
    .RecordLiveStream {
        top: -36px;
    }
    .SchedulewMeeting {
        .modal-dialog {
            .modal-content {
                border-radius: 10px !important;
                .modal-header {
                    padding: 14px 18px !important;
                    .modal-title {
                        h4 {
                            font-size: 18px !important;
                            padding-right: 35px !important;
                            line-height: 24px !important;
                        }
                    }
                }
            }
        }
    }
    .ZoneEnterDiscInfo {
        .MidBoothHeight {
          .maxHeightBooth {
            height: auto !important;
          }
        }
        .SmallBoothHeight {
          .maxHeightBooth {
            height: auto !important;
          }
        }
      }

    .ModalCommonInfo .modal-dialog .modal-content .modal-body .MeetingNewSetup .DayMeetingTimeSetup .MobBackstage {
        display: none;
    }
    .audience-front-stage-roll .container-fluid .go-backstage-btn {
        display: none;
    }
    #join {
        display: none;
    }
    #visitor {
        display: none;
    }
    .SelectDropdown .SeleftDDl .schedule-dropdown {
        transform: initial !important;
    }
    .ScheduleDayStage .SelectDayStage .DayScheduleStages .SelectDropdown .SeleftDDl .schedule-dropdown {
        top: 38px;
    }
    .ScheduleCalendarInfos {
        .Watch-Session {
            display: block;
            .RedButton {
                padding: 8px 16px;
            }
            .BlueinButton {
                padding: 8px 16px;
            }
        }
    }
    .PepleSerchHere .SerchIcon {
        top: 8px;
    }
    .MixhubbHeader .HeaderMainTop .MixhubbMenu .MixhubbBottomMenu .headIcon {
        margin: 12px 6px !important;
    }
    .ReadMorePopUp .modal-dialog .modal-content .modal-body .ReadMoreDeso .SectionWatch {
        display: block !important;
    }
    .ReadMorePopUp .modal-dialog .modal-content .modal-body .ReadMoreDeso .SectionWatch .RedButton {
        width: 100%;
        margin: 15px 0 0;
    }
    .RegisterPageAdvance .container .row .LoginRegisterPage .AdvanceEventDelReg .DetailFixEventMx .registration-timezone div {
        font-size: 12px;
    }
    .BackstageInfoStream .streamingUsers .StreamVideoFrame .back-stage-stream-scroll .slick-slider {
        width: 97.5%;
    }   
    .scheduleTages {
        .TagSelect {
            width: 120px;
            margin-right: 8px;
            p {
                font-size: 14px;
            }
        }
    }
    .scheduleTages {
        padding: 0 20px;
    }
    .SchedulePageEvent .SetEventSchedule .ScheduleDayStage .SelectEnterZone {
        width: 78%;
    }
    .TagLengthInfo {
        height: calc(100vh - 214px);
        -webkit-overflow-scrolling: touch;
    }
    .TagLengthInfo.ZoneListExpoList {
        height: calc(100vh - 216px);
      }
    .scheduleTages .TagNameList {
        width: 96% !important;
        margin: 6px 0 0;
    }
    .scheduleTages {
        margin: 12px 0px 0px;
    }
    .MobileAppFilter {
        margin: 16px 0 0px;
    }
    .AllPlanSchedule {
        padding: 0px 0px 0px 0 !important;
        height: calc(100vh - 198px) !important;
        transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    }
    .TagScheduleHght {
        height: calc(100vh - 124px) !important;
        margin-top: 12px !important;
    }
    .ScheduleCalendarInfos .PopUpImgSpeaker .SpkInfoImgs {
        width: 35px !important;
        height: 35px !important;
        margin-right: 10px !important;
    }
    .ScheduleCalendarInfos .PopUpImgSpeaker .SpkInfoCount {
        width: 35px !important;
        height: 35px !important;
        font-size: 14px !important;
    }
    .RoomScheduleTable {
        height: calc(100vh - 140px);
        overflow-y: auto;
        padding: 5px 0px 0 0;
    }
    .TagRoomsInfo {
        height: calc(100vh - 198px);
        overflow-y: auto;
        padding: 10px 0px 0 0;
    }
    .MenuDDlMaxHeight {
        padding: 0px 0px 0px 0 !important;
        height: calc(100vh - 262px) !important;
        transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    }
    .SideRightMenu {
        margin-right: 6px;
        ul {
            li {
                padding: 6px 3px !important;
                margin: 4px 0 !important;
                width: 35px;
                button {
                    svg {
                        width: 14px;
                        height: 14px;
                    }
                    span {
                        font-size: 8px;
                        padding: 0px !important;
                    }
                }
                .RightTopStyle {
                    width: auto;
                    ul {
                        li {
                            padding: 0px 0px !important;
                            margin: 0px 0 !important;
                            button {
                                svg {
                                    width: 18px;
                                    height: 18px;
                                }
                                span {
                                    font-size: 8px;
                                    padding: 0px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    
    .audience-front-stage-roll.collapse-out-frontstage {
        .container-fluid {
            .SideRightMenu {
                ul {
                    li {
                        padding: 6px 2px 2px !important;
                        margin: 4px 0!important;
                        span {
                            font-size: 8px;
                            padding: 0!important;
                            line-height: 12px;
                        }
                        .RightTopStyle {
                            ul {
                                li {
                                    padding: 0px 2px 0px !important;
                                    margin: 0px !important;
                                    .ChatClick {
                                        span {
                                            font-size: 8px;
                                            padding: 0!important;
                                            line-height: 12px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                
            }
        }
    }
    
    .audience-front-stage-roll {
        .container-fluid {
            .SideRightMenu {
                ul {
                    li {
                        padding: 6px 2px 2px !important;
                        margin: 4px 0!important;
                        span {
                            font-size: 8px;
                            padding: 0!important;
                            line-height: 12px;
                        }
                        .RightTopStyle {
                            ul {
                                li {
                                    padding: 0px 2px 0px !important;
                                    margin: 0px !important;
                                    .ChatClick {
                                        span {
                                            font-size: 8px;
                                            padding: 0!important;
                                            line-height: 12px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                
            }
        }
    }

    .backstage-dashboard.collapse-out-frontstage {
        .container-fluid {
            .SideRightMenu {
                ul {
                    li {
                        padding: 6px 2px 2px !important;
                        margin: 4px 0!important;
                        span {
                            font-size: 8px;
                            padding: 0!important;
                            line-height: 12px;
                        }
                        .RightTopStyle {
                            ul {
                                li {
                                    padding: 0px 2px 0px !important;
                                    margin: 0px !important;
                                    .ChatClick {
                                        span {
                                            font-size: 8px;
                                            padding: 0!important;
                                            line-height: 12px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                
            }
        }
    }
    
    .backstage-dashboard {
        .container-fluid {
            .SideRightMenu {
                ul {
                    li {
                        padding: 6px 2px 2px !important;
                        margin: 4px 0!important;
                        span {
                            font-size: 8px;
                            padding: 0!important;
                            line-height: 12px;
                        }
                        .RightTopStyle {
                            ul {
                                li {
                                    padding: 0px 2px 0px !important;
                                    margin: 0px !important;
                                    .ChatClick {
                                        span {
                                            font-size: 8px;
                                            padding: 0!important;
                                            line-height: 12px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                
            }
        }
    }
    .SchedulePageEvent .SetEventSchedule .RoomSeatSelect {
        width: 70% !important;
    }
    .RoomScheduleTable {
        margin: 10px 0 0 !important;
    }
    .ZoneEnterDiscInfo .EnterZoneBooth {
        margin: 8px 0 8px !important;
    }
    .screen-stage-main-div.backstage-dashboard.collapse-out-frontstage .container-fluid .MainViewBackStage {
        height: calc(100vh - 116px) !important;
    }
    .screen-stage-main-div.backstage-dashboard .container-fluid .MainViewBackStage {
        height: calc(100vh - 258px) !important;
    }
    .MixhubbHeader {
        .HeaderMainTop {
            .MixhubbMenu {
                .MixhubbBottomMenu {
                    .headIcon {
                        width: 85px;
                        height: 85px;
                        .chatConvIcon {
                            svg {
                                width: 35px;
                                height: 35px;
                            }
                            span {
                                margin-top: 2px !important;
                                font-size: 12px !important;
                            }
                        }
                    }
                }
            }
        }
    }
    .scheduleTages .TagNameList ul li .check-group label {
        font-weight: 400;
        font-size: 14px;
    }
    .scheduleTages .TagNameList ul .slick-slider .slick-prev {
        top: 18px;
    }
    .scheduleTages .TagNameList ul .slick-slider .slick-next {
        top: 18px;
    }
    .ZoneEnterDiscInfo .EnterZoneBooth .maxHeightBooth h3 {
        font-size: 15px !important;
    }
    .RegisterPageAll .LoginRegisterPage .MixhubbExpDae .SocialMicro {
        width: 90px;
        padding: 4px 0;
        svg {
            width: 16px;
            height: 16px;
        }
    }
    .audience-front-stage-roll .container-fluid .MainViewBackStage .alignAllDiv .front-stage-stream-scroll .row .video-js {
        position: absolute;
        top: 0px;
        width: 100%;
        left: 0;
        right: 0;
        margin: 0px auto;
        height: 100% !important;
        padding: 0;
    }
    .customer-chat {
        bottom: 6px;
        right: 4px;
        width: 62px;
        height: 62px;
    }
    .MessageTextBox {
        //width: 79% !important;
        right: auto !important;
        margin-left: 16px;
        bottom: 8px;
    }
    .ContryNoCode .DialCodePin .TimeZoneProfile .TimeZoneActive {
        width: 280px;
    }
    .ContryNoCode .DialCodePin .TimeZoneProfile .TimeZoneActive .ZoneOptionBox {
        height: 200px;
    }
    .ContryNoCode .DialCodePin .TimeZoneProfile .TimeZoneActive .ZoneSearchHere .SearchBarInput {
        height: 34px;
    }
    .languageChng {
        width: 90%;
        margin: 0px auto;
        left: 0px;
        right: 0px;
    }
    .MessageTextBox .MsgEmojis .EmojiIconsIn .EmojiMainIco ul li {
        width: 54px;
        height: 54px;
        font-size: 28px;
    }
    .RoomMeetingPopUp .modal-dialog .modal-content .InviteTableCall .invitedTableCell {
        height: calc(100vh - 18px);
    }
    .InviteTableCall .peopleInfos .PeopleAttendList {
        height: calc(100vh - 228px);
    }
    .languageChng .modal-dialog .modal-content .modal-body {
        padding: 48px 20px !important;
    }
    .languageChng {
        .modal-dialog {
            .modal-content {
                .modal-header {
                    .modal-title {
                        h4 {
                            font-size: 18px;
                            color: #4285F4;
                        }
                    }
                    .ResetLanguage {
                        svg {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
        }
    }
    .edit-expo-booth {
        display: none !important;
    }
    .ErrorInbox {
        width: 90%;
    }
    .container-error {
        .ErrorInbox {
            .ErrorInfo {
                margin-top: 20px;
                padding: 0 18px;
                h3 {
                    font-size: 50px;
                }
            }
            .LogoWebinar {
                .maxImg {
                    width: 160px;
                }
            }
        }
    }
}
@media(min-width:376px) and (max-width:479px) {
	.SchedulePageEvent {
        .SetEventSchedule {
            .RoomSeatSelect {
                .RoomMeetSrch {
                    .MyMeetingHere {
                        .MymeetingBtn {
                            padding: 7px 0px;
                            width: 100px;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
    .SchedulePageEvent {
        .SetEventSchedule {
            .ScheduleDayStage {
                .SelectDayStage {
                    .SelectDays {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
    .SchedulePageEvent {
        .SetEventSchedule {
            .ScheduleDayStage {
                padding-right: 12px;
                padding-left: 12px;
            }
            .RoomSeatSelect {
                padding-right: 40px;
                padding-left: 6px;
            }
        }
    }
    .RecommendProfiles p {
        font-size: 14.5px;
    }

    .RegisterPageAll {
        .LoginRegisterPage {
            .MixhubbExpDae {
                h1 {
                    font-size: 28px;
                }
                p {
                    font-size: 20px;
                }
                .event-timezone {
                    font-size: 15px;
                    margin: 4px 0 0;
                }
            }
        }
    }

    .RegisterPageAll {
        .LoginRegisterPage {
            .EnterEventAction {
                h2 {
                    font-weight: 600;
                    font-size: 26px;
                }
                .SocialLoginType {
                    ul {
                        li {
                            .img-fluid {
                                cursor: pointer;
                                width: 50px;
                                height: 50px;
                            }
                            svg {
                                width: 50px;
                                height: 50px;
                              }
                        }
                    }
                }
            }
        }
    }
    .RegisterPageAll {
        .LeftImgBlock {
            .LoginLeftHg {
                height: 42vh;
            }
        }
    }
    .PepleSerchHere .SerchHere {
        height: 40px;
    }
    .SchedulePageEvent {
        .SetEventSchedule {
            .RoomSeatSelect {
                .RoomMeetSrch {
                    .MyMeetingHere {
                        .MymeetingBtn {
                            padding: 8px 11px;
                            width: 110px;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
    .SchedulePageEvent {
        .SetEventSchedule {
            .RoomSeatSelect {
                .RoomMeetSrch {
                    .RoomsSerchHere {
                        width: 100%;
                    }
                }
            }
        }
    }
    .ZoneEnterDiscInfo {
        .EnterZoneBooth {
            h3 {
                font-size: 22px;
                
            }
            p {
                font-size: 14px;
            }
        }
    }

    .RegisterPageAll .LeftImgBlock .LoginEventLogo .EventLogoFix {
        width: 100%;
    }
    .RegisterPageAll .LoginRegisterPage .mixhubbLogo {
        width: 62.84vw;
    }
    .FullYourProfile {
        .nav-tabs {
            display: flex;
            align-items: center;
            justify-content: space-around;
            li {
                margin: 0 0;
                .nav {
                    .nav-link {
                        padding: 8px 14px !important;
                        font-size: 12px !important;
                        &.active {
                            padding: 8px 14px !important;
                        }
                    }
                }
            }
        }
    }
    .RegisterPageAll .LeftImgBlock .LoginEventLogo {
        top: 8px;
        left: 8px;
        width: 110px;
    }
    .scheduleTages {
        .TagNameList {
            ul {
                li {
                    padding: 6px 12px !important;
                    color: #237be2;
                    font-weight: 400;
                    font-size: 12px;
                }
            }
        }
    }
    .MobileAppFilter {
        width: 86% !important;
    }
    .MobileAppFilter .SelectSchedule {
        width: 82%;
    }
    .DeviceCompatibility .modal-dialog .modal-content .DarkBlurBackground .CheckDevice .DeviceCompTable .modal-body .HeaderCheckSystem h4 {
        font-size: 16px;
    }
   
    .socialMicroInfo {
        ul {
            display: flex;
            align-items: center;
            justify-content: space-between;
            li {
                margin: 0px 0px;
            }
        }
    }
    .socialMicroInfo .SocialCopyLink {width: 100%;}
}
@media (max-width: 375px) {
	.AllPlanSchedule {
        .ScheduleCalendarInfos {
            .StageFullInfoFix {
                .CalenderDateScheduleInof {
                    padding: 14px 12px 14px;
                }
            }
        }
    }
    .AllPlanSchedule {
        .ScheduleCalendarInfos {
            .StageFullInfoFix {
                .CalenderDateScheduleInof {
                    .DtStageEventDay {
                        h4 {
                            line-height: 22px;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
    .AllPlanSchedule {
        .ScheduleCalendarInfos {
            .StageFullInfoFix {
                .CalenderDateScheduleInof {
                    .ScheduleTMFx {
                        p {
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }
    .AllPlanSchedule {
        .ScheduleCalendarInfos {
            .ScheduleInfoWithConts {
                position: relative;
                h3 {
                    font-size: 15px;
                }
            }
        }
    }
    .SchedulePageEvent {
        .SetEventSchedule {
            .ScheduleDayStage {
                padding-right: 0px;
                padding-left: 0px;
            }
        }
    }
    .SchedulePageEvent {
        .SetEventSchedule {
            .RoomSeatSelect {
                .RoomMeetSrch {
                    .MyMeetingHere {
                        .MymeetingBtn {
                            padding: 8px 11px;
                            width: 110px;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
    .SchedulePageEvent {
        .SetEventSchedule {
            .ScheduleDayStage {
                .SelectDayStage {
                    .SelectDays {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
    .RecommendProfiles p {
        font-size: 14.5px;
    }
    .RegisterPageAll {
        .LoginRegisterPage {
            .MixhubbExpDae {
                h1 {
                    font-size: 26px;
                    margin: 0 0 10px;
                }
                p {
                    font-size: 20px;
                }
                .event-timezone {
                    font-size: 15px;
                    margin: 4px 0 0;
                }
            }
            .MixhubbExpDae {
                //background-color: #eaeaea;
                padding: 25px 20px;
            }
            .EnterEventAction {
                width: 82%;
            }
        }
    }

    .RegisterPageAll {
        .LoginRegisterPage {
            .EnterEventAction {
                h2 {
                    font-weight: 600;
                    font-size: 22px;
                }
                .SocialLoginType {
                    ul {
                        li {
                            .img-fluid {
                                cursor: pointer;
                                width: 50px;
                                height: 50px;
                            }
                            svg {
                                width: 50px;
                                height: 50px;
                              }
                        }
                    }
                }
            }
        }
    }
    .EventTypeActonIn {
        .form-group {
            .form-control {
                padding: 14px 28px;
            }
        }
    }
    .RegisterPageAll {
        .LeftImgBlock {
            .LoginLeftHg {
                height: 42vh;
            }
        }
    }

    .FullYourProfile {
        .nav-tabs {
            display: flex;
            align-items: center;
            justify-content: space-around;
            li {
                margin: 0 0;
                .nav {
                    .nav-link {
                        padding: 6px 10px !important;
                        font-size: 12px !important;
                        &.active {
                            padding: 8px 10px !important;
                        }
                    }
                }
            }
        }
    }
    .YoutProfilePopUp .modal-dialog .modal-content .modal-header .modal-title h4 {
        font-size: 17px;
    }
    .PepleSerchHere {
        .SerchHere {
            height: 40px;
        }
        .PepleSerchHere .SerchIcon {
            top: 8px;
        }
    }
    .ZoneEnterDiscInfo .EnterZoneBooth h3 {
        font-size: 20px;
    }
    .RegisterPageAll .LoginRegisterPage .mixhubbLogo {
        width: 70% !important;
    }
    .RegisterPageAll .LeftImgBlock .LoginEventLogo {
        top: 8px;
        left: 8px;
        width: 110px;
    }
    .scheduleTages .TagNameList ul li {
        padding: 5px 12px !important;
        color: #237be2;
        font-weight: 400;
        font-size: 13px;
    }
    .scheduleTages {
        padding: 0 10px !important;
    }
    .MobileAppFilter {
        width: 84% !important;
    }
    .MobileAppFilter .SelectSchedule {
        width: 82%;
    }
    .DeviceCompatibility .modal-dialog .modal-content .DarkBlurBackground .CheckDevice .DeviceCompTable .modal-body .HeaderCheckSystem h4 {
        font-size: 16px;
    }
    .socialMicroInfo {
        ul {
            display: flex;
            align-items: center;
            justify-content: space-between;
            li {
                margin: 0px 0px;
            }
        }
    }
    .socialMicroInfo .SocialCopyLink {width: 100%;}

    // .StageTabCollapes .tab-content .PeopleChatPopup .StateEventDtl {
    //     height: calc(100vh - 366px) !important;
    // }
    // .SlideCommonPopup .StateEventDtl .EventInfoStage .ChatInfos {
    //     height: calc(100vh - 400px) !important;
    // }
    // .SlideCommonPopup .StateEventDtl .EventInfoStage {
    //     height: calc(100vh - 390px) !important;
    //     position: relative;
    // }
    // .PollInfos .MhBtnRemove {
    //     height: calc(100vh - 380px) !important;
    //     margin-top: 10px;
        
    // }
    // .StageTabCollapes .tab-content .ReadMoreDeso {
    //     height: calc(100vh - 375px);
    // }
    // .BackstateOverview .AttenddeScript h5 {
    //     font-size: 14px;
    // }
    // .QnaInfos .QnaInfosPols {
    //     height: calc(100vh - 455px) !important;
    // }
    // .QnaInfos {
    //     height: calc(100vh - 365px) !important;
    // }
    // .FilesInfos {
    //     height: calc(100vh - 368px);
    // }
    // .PeopleChatPopup .StateEventDtl .ChatInfos {
    //     height: calc(100vh - 442px) !important;
    //     padding-bottom: 0px !important;
    // }
    // .MessageTextBox {
    //     bottom: 8px;
    // }
    // .PeopleChatPopup .StateEventDtl .peopleInfos {
    //     padding: 14px;
    //     height: calc(100vh - 372px) !important;
    // }
}


@media(max-height: 480px) {
    .MixhubbBottomMenu {
        display: none !important;
    }
    .MixhubbHeader .HeaderMainTop .MixhubbMenu {
        margin: 0px 0 0;
    }
    // .MixhubbHeader .HeaderMainTop .MixhubbMenu {
    //     overflow-y: auto;
    // }
    #join {
        display: none !important;
    }
    #visitor {
        display: none !important;
    }
    .audience-front-stage-roll {
        .container-fluid {
            .MainViewBackStage {
                height: calc(100vh - 48px) !important;
                .FlexResizeStep {
                    .ChangeWithSize {
                        .fullFreeContainer {
                            width: auto !important;
                            height: 100% !important;
                            .RemoveAllDiv {
                                height: 100% !important;
                               .front-stage-stream-scroll {
                                    width: 100% !important;
                                    height: 100% !important;
                                }
                            }
                        }
                    }
                }
            }
            .SideRightMenu {
                display: flex !important;
                top: 48px;
                height: calc(100% - 96px) !important;
                ul {
                    li {
                        padding: 6px 2px 2px !important;
                        margin: 4px 0!important;
                        span {
                            font-size: 8px;
                            padding: 0!important;
                            line-height: 12px;
                        }
                        .RightTopStyle {
                            ul {
                                li {
                                    padding: 0px 2px 0px !important;
                                    margin: 0px !important;
                                    .ChatClick {
                                        span {
                                            font-size: 8px;
                                            padding: 0!important;
                                            line-height: 12px;
                                        }
                                    }
                                    
                                }
                            }
                        }
                    }
                }
                
            }
        }
    }

    .audience-front-stage-roll.collapse-out-frontstage {
        .container-fluid {
            .MainViewBackStage {
               // height: 100vh !important;
            }
            .SideRightMenu {
                display: none !important;
                height: calc(100% - 96px) !important;
                top: 48px;
                ul {
                    li {
                        padding: 6px 2px 2px !important;
                        margin: 4px 0!important;
                       
                        span {
                            font-size: 9px;
                            padding: 0!important;
                            line-height: 12px;
                        }
                        .RightTopStyle {
                            ul {
                                li {
                                    padding: 0px 2px 0px !important;
                                    margin: 0px !important;
                                    .ChatClick {
                                        span {
                                            font-size: 9px;
                                            padding: 0!important;
                                            line-height: 12px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                
            }
        }
    }
    .audience-front-stage-roll.screen-stage-main-div .container-fluid .MainViewBackStage {
        height: calc(100vh - 48px) !important;
    }
    .audience-front-stage-roll.collapse-out-frontstage.screen-stage-main-div .container-fluid .MainViewBackStage {
        height: calc(100vh - 48px) !important;
        margin: 0px !important;
    }
    .audience-front-stage-roll.collapse-out-frontstage{
        .container-fluid {
            .MainViewBackStage {
                //height: 100vh !important;
                .FlexResizeStep {
                    .ChangeWithSize {
                        height: 100% !important;
                        .fullFreeContainer {
                            //height: auto !important;
                            .RemoveAllDiv {
                                height: 100% !important;
                                width: auto;
                                img {
                                    height: auto !important;
                                    aspect-ratio: 16/9;
                                    object-fit: scale-down;
                                }
                                .front-stage-stream-scroll {
                                    height: 100% !important;
                                    .row {
                                        .stage-user-list {
                                            .video_footer {
                                                .ShortName {
                                                    h4 {
                                                        width: 45px;
                                                        height: 45px;
                                                        font-size: 22px;
                                                    }
                                                }
                                                .bottom_footer {
                                                    h4 {
                                                        font-size: 10px !important;
                                                        span {
                                                            line-height: 14px !important;
                                                        }
                                                    }
                                                }
                                                .BackIconAction {
                                                    top: 5px;
                                                    right: 3px;
                                                    .ActionLive {
                                                        width: 18px;
                                                        height: 18px;
                                                        .widthSvg {
                                                            padding: 3px 3px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                
                            }
                        }
                    }
                }
            }
        }
    }
    .container-error {
        position: initial;
        overflow-y: auto;
        padding: 20px 0;
    }
}
@media screen and (max-width: 991px) and (orientation: landscape) {
    body {
        overflow-y: auto !important;
    }
    .maxScreenHeight {
        height: calc(100vh - 48px);
        overflow-y: auto !important;
        margin-top: 48px;
        overflow-x: hidden !important;
        .scale-blur-fix {
            height: 100vh;
            position: fixed !important;
            width: 100%;
        }
    }
    .maxScreenHeight .scale-blur-fix .BlurFullImgs {
        width: 100%;
        height: calc(100vh - 0px);
    }
    .CameraPrevModal.show {
        display: block !important;
        align-items: center;
        .modal-dialog {
            width: 95% !important;
        }
    }
    .CameraPrevModal .modal-dialog .modal-content .DarkBlurBackground .JoinTableRoom .JoinPersonTable .modal-body .joinNowTableUsGranted {
        padding: 22px 20px;
    }
    .CameraPrevModal {
        .modal-dialog {
            .modal-content {
                .DarkBlurBackground {
                    .JoinTableRoom {
                        .JoinPersonTable {
                            .modal-body {
                                .joinNowTableUsGranted {
                                    p {
                                        font-size: 15px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .MixhubbLobby {
        .LobbySelImag {
            .BgLobbyFix {
                .LobbyBgWidgetHg {
                    .imageFeatureWidget {
                        width: auto !important;
                        height: 100% !important;
                    }
                }
            }
        }
    }
    .SchedulePageEvent {
        .SetEventSchedule {
            .ScheduleDayStage {
                padding: 10px 14px 10px 22px !important;
            }
        }
    }
    .SchedulePageEvent {
        .SetEventSchedule {
            .ScheduleDayStage {
                h4 {
                    font-size: 18px;
                }
            }
        }
    }
    .BackstageInfoStream {
        .HeadingBstage {
            padding: 8px 12px;
            h3 {
                font-size: 14px;
                font-weight: 600;
            }
            .BsMidAction {
                
                .ActionLive {
                    margin: 0 4px;
                    width: 28px;
                    height: 28px;
                }
                .BsEnd {
                    .RedCommonBtn {
                        padding: 4px 20px;
                    }
                }
            }
        }
    }
    .BackstageInfoStream {
        .streamingUsers {
            .StreamVideoFrame {
                .back-stage-stream-scroll {
                    .localPlayer {
                        width: 90px !important;
                        margin: 0 0px;
                        height: 70px !important;
                        position: relative !important;
                    }
                }
            }
        }
    }
    .MixhubbStage {
        .backstage-dashboard {
            .MainViewBackStage {
                width: calc(100% - 250px) !important;
                height: calc(100vh - 126px);
                margin: 0 auto;
                height: auto !important;
                float: none;
            }
        }
    }
    .CameraPrevModal {
        .modal-dialog {
            height: auto !important;
        }
    }
    .ModalCommonInfo{
        .modal-dialog {
            height: auto !important;
        }
    }
    .MixhubbStage {
        .backstage-dashboard {
            padding: 0 !important;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    
    .BackstateOverview {
        height: calc(100vh - 45px);
    }
    .BackstateOverview h1 {
        margin: 0 0 0px;
    }
    .StageBounded {
        height: calc(100vh - 108px) !important;
        position: relative !important;
    }
    .StageBounded {
        .VideoBroadcastLive {
            img {
                height: 100% !important;
            }   
        }
    }
    .RegisterPageAll {
        .LeftImgBlock {
            .LoginEventLogo {
                .EventLogoFix {
                    width: 100%;
                }
            }
        }
    }
    .audience-front-stage-roll.collapse-out-frontstage {
        height: auto;
        overflow-y: auto !important;
    }
    .MainViewBackStage .front-stage-stream-scroll .row .stage-user-list .video_footer .RightSvgIcon .mh-cursor-pointer {
        padding: 6px;
        width: 25px;
        height: 25px;
        top: 8px;
        left: 8px;
    }

    .RegisterPageAll .LeftImgBlock .LoginEventLogo {
        top: 8px;
        left: 8px;
        width: 100px;
    }
    .RecordLiveStream {
        left: 12px;
        top: -35px;
    }
    .portrait-content {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: #fff;
        width: 100%;
        height: 100%;
        height: 100vh;
        z-index: 99999999;
        .portrait-center {
            position: absolute;
            top: 50%;
            left: 0;
            margin: 0 auto;
            right: 0;
            transform: translateY(-50%);
            text-align: center;
            img {
                margin: 0 auto 10px;
                width: 200px;
                text-align: center;
            }
            p {
                font-size: 16px;
                color: #000;
                text-transform: uppercase;
                text-align: center;
                font-weight: 400;
            }
        }
    }
    .audience-front-stage-roll .BackstageInfoStream .HeadingBstage {
        height: 45px !important;
    }
    .audience-front-stage-roll.collapse-out-frontstage .BackstageInfoStream .HeadingBstage {
        height: 45px !important;
    }
    .collapsDivToggle {
        width: 26px;
        height: 26px;
    }
    .go-backstage-btn {
        display: none;
    }
    .StageTabCollapes .MobileStageFtr {
        display: block !important;
    }
    .BackstageInfoStream {
        width: 99%;
        position: fixed;
        bottom: 4px !important;
        left: 0;
        right: 0;
        margin: 0px auto;
        z-index: 999;
        display: none;
        .streamingUsers {
            .StreamVideoFrame {
                height: calc(100vh - 100px) !important;
                .back-stage-stream-scroll {
                    .Stage-Back-Event-Info {
                        height: calc(100vh - 116px) !important;
                        .row {
                           .SpeakerStageList {
                                margin: 15px 0 0;
                               .EventSpeakerStage {
                                   width: 46% !important;
                               }
                           } 
                        }
                    }
                }
            }
        }
    }

    .audience-front-stage-roll {
        .container-fluid {
            .MainViewBackStage {
                height: calc(100vh - 88px) !important;
                margin-top: 0px;
                .FlexResizeStep {
                    .ChangeWithSize {
                        .fullFreeContainer {
                            width: auto !important;
                            height: 100% !important;
                            .RemoveAllDiv {
                                height: 100% !important;
                               .front-stage-stream-scroll {
                                    width: 100% !important;
                                    height: 100% !important;
                                }
                            }
                        }
                    }
                }
            }
            .SideRightMenu {
                display: flex !important;
                top: 48px;
                height: calc(100% - 96px) !important;
                ul {
                    li {
                        padding: 6px 2px 2px !important;
                        margin: 4px 0!important;
                        span {
                            font-size: 8px;
                            padding: 0!important;
                            line-height: 12px;
                        }
                        .RightTopStyle {
                            ul {
                                li {
                                    padding: 0px 2px 0px !important;
                                    margin: 0px !important;
                                    .ChatClick {
                                        span {
                                            font-size: 8px;
                                            padding: 0!important;
                                            line-height: 12px;
                                        }
                                    }
                                    
                                }
                            }
                        }
                    }
                }
                
            }
        }
    }

    .audience-front-stage-roll.collapse-out-frontstage {
        .container-fluid {
            .MainViewBackStage {
                height: calc(100vh - 88px) !important;
            }
            .SideRightMenu {
                display: none !important;
                height: calc(100% - 96px) !important;
                top: 48px;
                ul {
                    li {
                        padding: 6px 2px 2px !important;
                        margin: 4px 0!important;
                       
                        span {
                            font-size: 9px;
                            padding: 0!important;
                            line-height: 12px;
                        }
                        .RightTopStyle {
                            ul {
                                li {
                                    padding: 0px 2px 0px !important;
                                    margin: 0px !important;
                                    .ChatClick {
                                        span {
                                            font-size: 9px;
                                            padding: 0!important;
                                            line-height: 12px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                
            }
        }
    }
    .audience-front-stage-roll.screen-stage-main-div .container-fluid .MainViewBackStage {
        height: calc(100vh - 88px) !important;
    }
    .audience-front-stage-roll.collapse-out-frontstage.screen-stage-main-div .container-fluid .MainViewBackStage {
        height: calc(100vh - 88px) !important;
        margin: 0px !important;
    }
    .audience-front-stage-roll.collapse-out-frontstage{
        .container-fluid {
            .MainViewBackStage {
                height: calc(100vh - 88px) !important;
                .FlexResizeStep {
                    .ChangeWithSize {
                        height: 100% !important;
                        .fullFreeContainer {
                            //height: auto !important;
                            .RemoveAllDiv {
                                height: 100% !important;
                                img {
                                    height: auto !important;
                                    aspect-ratio: 16/9;
                                    object-fit: scale-down;
                                }
                                .front-stage-stream-scroll {
                                    height: 100% !important;
                                    .row {
                                        .stage-user-list {
                                            .video_footer {
                                                .ShortName {
                                                    h4 {
                                                        width: 45px;
                                                        height: 45px;
                                                        font-size: 22px;
                                                    }
                                                }
                                                .bottom_footer {
                                                    h4 {
                                                        font-size: 10px !important;
                                                        span {
                                                            line-height: 14px !important;
                                                        }
                                                    }
                                                }
                                                .BackIconAction {
                                                    top: 5px;
                                                    right: 3px;
                                                    .ActionLive {
                                                        width: 18px;
                                                        height: 18px;
                                                        .widthSvg {
                                                            padding: 3px 3px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                
                            }
                        }
                    }
                }
               
            }
        }
    }
   
    .MobJoinBackstage {
        display: none !important;
    }
    .PollInfos .PollMhAuto {
        height: calc(100vh - 128px);
    }
    .PollInfos .PollMhAuto .mh-poll-container .PollReqBar .PollCreateInfo {
        width: 100%;
        margin: 14px 0 0;
    }
    .StageTabCollapes .tab-content .PeopleChatPopup {
        height: auto !important;
        position: inherit;
        border-radius: 0px;
        width: 100%;
        box-shadow: none !important;
        padding: 0;
    }
    .AttendeeInofMob {
        float: left;
        width: 100%;
        .col-md-12 {
            padding: 0 6px;
        }
    }
    .MixhubbHeader {
        width: 100%;
        top: 0;
        position: fixed;
    }
    .StageTabCollapes {
        position: relative;
        width: 100%;
        bottom: 2px;
        z-index: 95;
        margin: 0px auto !important;
        left: 0;
        right: 0;
        display: block !important;
        .tab-content {
            .PeopleChatPopup {
                height: auto !important;
                position: inherit;
                border-radius: 0px;
                width: 100%;
                box-shadow: none !important;
                padding: 0;
                .responsive-tabs-container {
                    .nav-tabs {
                        padding: 15px 10px 15px;
                        li {
                            margin: 0 0 0;
                        }
                    }
                    .CloseButton {
                        display: none;
                    }
                }
                .StateEventDtl {
                    height: calc(100vh - 122px) !important;
                }
                .StageBackStage {
                    .nav-tabs {
                        display: none;
                        li {
                            .nav-link {
                                padding: 10px 0 !important;
                                &.active {
                                    padding: 10px 0 !important;
                                }
                            }
                        }
                    }
                }
            }
            .ReadMoreDeso {
                .AboutTabsFull {
                    margin: 0px 0;
                    overflow-y: auto;
                    height: calc(100vh - 150px);
                    padding: 0 12px;
                    .ScheduleDayEvent {
                        margin: 14px 0;
                        span {
                            background-color: #ffffff;
                            font-size: 14px;
                            padding: 6px 12px;
                            font-weight: 500;
                            color: #1F1B20;
                            border-radius: 4px;
                        }
                    }
                    h4 {
                        color: #1A78E5;
                        font-size: 18px;
                        margin: 0;
                    }
                    p {
                    font-size: 14px;
                    margin: 18px 0 0;
                    color: #1F1B20;
                    }
                    .SectionWatch {
                    width: 100%;
                    text-align: left;
                    margin: 18px 0 2px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .RedButton  {
                        padding: 6px 16px;
                        font-size: 15px;
                        width: 145px;
                    }
                    .PopUpImgSpeaker {
                        .SpkInfoImgs {
                            width: 50px;
                            height: 50px;
                            display: inline-block;
                            margin-right: 10px;
                            img {
                                width: 100%;
                                height: 100%;
                                border-radius: 100px;
                        }
                        }
                        .SpkInfoCount {
                            border: 2px #1A78E5 solid;
                            color: #1A78E5;
                            width: 46px;
                            height: 46px;
                            border-radius: 100px;
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
    .SlideCommonPopup {
        .StateEventDtl {
            .EventInfoStage {
                height: calc(100vh - 130px) !important;
                position: relative;
            }
            .ChatInfos {
                height: calc(100vh - 200px) !important;
            }
        }
    }
    .MessageTextBox {
        bottom: 12px;
    }
    .PollInfos .NowPollCreating {
        display: none;
    }
    #join {
        display: none !important;
    }

    #visitor {
        display: none !important;
    }
    // .MixhubbHeader .HeaderMainTop .MixhubbMenu ul li .nav-mixhubb .live-icon {
    //     position: initial !important;
    // }
    .PollInfos .MhBtnRemove {
        height: calc(100vh - 130px);
    }
    .QnaInfos {
        height: calc(100vh - 128px) !important;
        padding: 6px 20px;
        .QnaInfosPols {
            height: calc(100vh - 218px);
        }
        .MessageTextBox {
            p {
                top: -24px;
                font-size: 14px;
            }
        }
    }
    .FilesInfos {
        height: calc(100vh - 130px);
    }
    .PeopleChatPopup .StateEventDtl .peopleInfos {
        height: calc(100vh - 152px) !important;

    }
    .SlideCommonPopup .StateEventDtl .EventInfoStage .ChatInfos {
        height: calc(100vh - 208px) !important;
        padding: 10px 15px 0;
    }
    .PeopleChatPopup .StateEventDtl .JoinRequestedMx {
        height: calc(100vh - 130px) !important;
    }
    .BoothFootAction {
        position: absolute !important;
    }
    .customer-chat {
        bottom: 8px;
        right: 3px;
        width: 65px;
        height: 65px;
    }
    .MixhubbHeader .HeaderMainTop .MixhubbMenu {
        margin: 0px 0 0;
        //overflow-y: auto;
    }
    .RightTopStyle {
        //height: 38px !important;
        top: 16px !important;
    }
    .VirtuslBgPreview .modal-dialog {
        height: calc(100vh - 58px) !important;
    }
    .ScreenTopAlign {
        position: relative !important;
    }
    .MixhubbLobby {
        .LobbySelImag {
            .BgLobbyFix {
                height: 100% !important;
                overflow-y: initial;
                .LobbyBgWidgetHg {
                    height: 100% !important;
                    margin: 0px 0px 0px !important;
                    padding: 0px 0px 0px !important;
                    //display: inline-flex;
                    .imageFeatureWidget {
                        height: 100% !important;
                        img {
                            height: 100% !important;
                            width: 100%;
                            border-radius: 0px !important;
                        }
                    }
                }
            }
        }
    }

    .edit-expo-booth {
        display: none !important;
    }
    .RoomMeetingPopUp .modal-dialog .modal-content .InviteTableCall {
        height: calc(100vh - 4px) !important;
    }
    .PeopleChatPopup {
        top: 2px;
    }
    .RoomMeetingPopUp .modal-dialog .modal-content .InviteTableCall .invitedTableCell {
        height: calc(100vh - 6px);
    }
    .InviteTableCall {
        .peopleInfos {
            height: calc(100vh - 124px);
            .PeopleAttendList {
                height: calc(100vh - 205px);
            }
        }
    }


    .AttendeeFrame {
        .YoutubeVideo {
            .ControlFrame {
              padding: 0 5px !important;
              .LeftControls {
                .UtButton {
                 button {
                     svg{
                      width: 35px;
                      height: 35px;
                     }
                 }
                }
                .liveSession {
                  span {
                    font-size: 10px;
                    padding: 0 5px;
                    margin-left: 6px;
                  }
               }
               .MuteUnmute {
                   .e-range {
                    width: 55px !important;
                   }
                  &:hover {
                      .VolumeCtrl {
                          height: 32px;
                          width: 55px !important;
                       }
                   }
               }
              }
              .RightControls {
                .UtButton {
                  width: 35px;
                  height: 35px;
                  button {
                  padding: 0px;
                  border: 0;
                  background-color: transparent;
                  svg {
                    background-color: transparent;
                    fill: #fff;
                  }
                  }
                }
              }
            }
          }
        .playBlack {
          .PlayAgain {
            svg {
              width: 70px;
              height: 70px;
            }
          }
        }
      }
      .audience-front-stage-roll {
        aspect-ratio: inherit !important;
      }     
}
